import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import * as Styled from './style'
import { TopBarDetails } from './components/TopBarDetails'
import { RouteInfo } from './components/RouteInfo'
import { Destinations } from './components/Destinations'
import { Card } from './components/Card'
import { DeleteModal } from './components/DeleteModal'
import fetchOrderBump from '../../../../services/orderBump'
import { toast } from 'react-toastify'
import { Button } from './components/Button'
import * as uuid from 'uuid'
import { useEffect } from 'react'
import { uploadFile } from './utils/uploadFile'
import { useTranslation } from 'react-i18next'

const DEFAULT_STOP = {
  tempId: uuid.v4(),
  place: '',
  price: 0,
  timeInMinutes: 0,
  image: null,
  deleted: false
}

export function OrderBumpDetails() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [going, setGoing] = useState({ from: '', to: '' })
  const [distance, setDistance] = useState(0)

  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  const [stops, setStops] = useState([DEFAULT_STOP])

  console.log('stops', stops)

  const addNewStop = () => {
    setStops((oldState) => [
      ...oldState,
      { ...DEFAULT_STOP, tempId: uuid.v4() }
    ])
  }

  const saveOrderBump = async () => {
    const formattedStop = []

    if (going.from === '' || going.to === '' || distance === 0) {
      return toast.error(t('toast.thereAreNotFulfilledFields'))
    }

    let hasError = false

    for (let i = 0; i < stops.length; i++) {
      const stopInfos = stops[i]
      console.log(stopInfos)

      if (
        !stopInfos.timeInMinutes ||
        !stopInfos.place ||
        !stopInfos.price ||
        !stopInfos.image
      ) {
        toast.error(`Existem campos não preenchidos na parada ${i + 1}`)
        hasError = true
        break
      }

      const [error, image] = stopInfos.image?.id
        ? [null, stopInfos.image]
        : await uploadFile(stopInfos.image)

      if (error !== null) {
        toast.error(error)
        hasError = true
        break
      }

      formattedStop.push({
        ...stopInfos,
        price: stopInfos.price,
        image
      })
    }

    if (hasError) return
    console.log(formattedStop)

    const payload = {
      id,
      startingPoint: going.from,
      arrivingPoint: going.to,
      distanceInKm: distance,
      stops: formattedStop
    }
    console.log(payload)
    const request = id
      ? fetchOrderBump.updateOrderBump
      : fetchOrderBump.createOrderBump

    try {
      const response = await toast.promise(request(payload), {
        pending: `${id ? 'Editando' : 'Criando'} order bump...`,
        success: `${id ? 'Editado' : 'Criado'} com sucesso!`,
        error: `Ocorreu um erro ao ${id ? 'editar' : 'salvar'}!`
      })

      navigate(-1)
    } catch (error) {
      console.log('Erro ao salvar', error)
    }
  }

  const getOrderBump = async () => {
    const request = fetchOrderBump.getOrderBumpById

    try {
      const response = await toast.promise(request(id), {
        pending: 'Carregando order bump...',
        error: 'Não foi possível obter as informações'
      })

      if (response.status === 200) {
        const formattedStops = response.data.stops.map((stop) => ({
          ...stop,
          price: stop.price
        }))

        setStops(formattedStops)
        setDistance(response.data.distanceInKm)
        setGoing({
          from: response.data.startingPoint,
          to: response.data.arrivingPoint
        })
      }
    } catch (error) {
      console.log('Erro ao receber informaçoes', error)
    }
  }

  const removeOrderBump = async () => {
    const request = fetchOrderBump.removeOrderBumpById

    try {
      const response = await toast.promise(request(id), {
        pending: 'Excluindo order Bump...',
        error: 'Não foi possível excluir essa order bump'
      })

      if (response.status === 200) {
        navigate(-1)
      }
    } catch (error) {
      console.log('Erro ao apagar order bump', error)
    }
  }

  const removeStop = (idToDelete) => {
    if (stops.length === 1) {
      return toast.warning('É necessário no minimo uma parada registrada')
    }

    console.log('id pra apagar', idToDelete)
    const filteredList = stops
      .map((parada) => {
        if (parada.id && parada.id === idToDelete) {
          return { ...parada, deleted: true }
        }
        if (parada.tempId && parada.tempId === idToDelete) {
          return false
        }
        return parada
      })
      .filter((stop) => stop)

    setStops([...filteredList])
  }

  useEffect(() => {
    if (id) {
      getOrderBump()
    }
  }, [id])

  return (
    <>
      <Styled.Container isOpen={open}>
        <TopBarDetails onSave={saveOrderBump} setOpen={setOpen} isOpen={open} />
        <RouteInfo rotanum={id} />
        <Destinations
          going={going}
          setGoing={setGoing}
          distance={distance}
          setDistance={setDistance}
        />
        {stops
          .filter((stop) => !stop.deleted)
          .map((stop, index) => (
            <Card
              key={stop?.tempId ?? stop.id}
              stop={stop}
              position={index}
              onChange={(newStop) => {
                const newStops = [...stops]
                newStops[index] = { ...stop, ...newStop }
                setStops(newStops)
              }}
              removeStop={removeStop}
            />
          ))}

        <Button addNewStop={addNewStop} />
      </Styled.Container>
      <DeleteModal
        isOpen={open}
        setOpen={setOpen}
        removeOrderBump={removeOrderBump}
      />
    </>
  )
}
