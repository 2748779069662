import { useEffect, useRef, useState } from 'react'
import * as Styled from './styles'
import { useIsInViewport } from '../../../../../../hooks/useIsInViewport'
import { stagger, useAnimate } from 'framer-motion'

export const Card = ({ OurFleet, dark = false }) => {
  const listRef = useRef(null)
  const [alreadyVisibleList, setAlreadyVisibleList] = useState(false)
  const isInViewPort = useIsInViewport(listRef)
  const [scope, animate] = useAnimate()

  const staggerMenuItems = stagger(0.1, { startDelay: 0.4 })

  useEffect(() => {
    if (alreadyVisibleList) return

    if (isInViewPort) setAlreadyVisibleList(true)

    animate(
      '.fleet-item',
      isInViewPort
        ? { opacity: 1, transform: 'translateY(0)' }
        : { opacity: 0, transform: 'translateY(30px)' },
      {
        duration: 0.2,
        delay: isInViewPort ? staggerMenuItems : 0
      }
    )
  }, [isInViewPort, alreadyVisibleList])

  return (
    <Styled.Container dark={dark} ref={listRef}>
      <div ref={scope}>
        {OurFleet.map((item, index) => (
          <Styled.CardContainer key={index} className="fleet-item">
            <img src={item.img} alt={item.alt} />
            <div>
              <h2>{item.title}</h2>
              {item.desc}
            </div>
          </Styled.CardContainer>
        ))}
      </div>
    </Styled.Container>
  )
}
