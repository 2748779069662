import { Keyboard, Mail } from 'lucide-react'

import * as Styled from './styles'
import { TextInput } from '@mantine/core'
import fetchBooking from '../../../../services/booking'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export const CancelRide = () => {
  const [data, setData] = useState({
    id: '',
    passengerEmail: ''
  })
  const onCancel = () => {
    if (data.id === '' || data.passengerEmail === '') {
      toast.error(t('toast.fulfillAllFields'))
      return
    }
    fetchBooking
      .passengerCancel(data)
      .then(() => {
        setData({
          id: '',
          passengerEmail: ''
        })
        toast.success(t('toast.transferCancelSuccess'))
      })
      .catch((err) => {
        console.log(err, 'err')
        toast.error(t('toast.transferCancelError'))
      })
  }
  const { t } = useTranslation()
  return (
    <Styled.TransferContainer>
      <Styled.OneTransfer>
        {/* <SelectComponent
          data={partida}
          title="E-mail"
          placeholder={t('enterEmail')}
          icon={partidaIcon}
        /> */}
        <TextInput
          icon={<Mail size="2.4rem" color="#27c7ff" />}
          placeholder={t('enterEmail')}
          label="E-mail"
          value={data.passengerEmail}
          onChange={(e) => {
            setData({
              ...data,
              passengerEmail: e.target.value
            })
          }}
        />
        <div>
          <TextInput
            icon={<Keyboard size="2.4rem" color="#27c7ff" />}
            placeholder={t('enterTransferId')}
            label="ID Transfer"
            value={data.id}
            onChange={(e) => {
              setData({
                ...data,
                id: e.target.value
              })
            }}
          />
          <span>{t('checkEmailForBooking')}</span>
        </div>
        {/* <SelectComponent
          data={partida}
          title="Order number"
          placeholder="Digite o seu order number"
          icon={chegadaIcon}
        /> */}
      </Styled.OneTransfer>
      <Styled.BtnContainer>
        <Styled.BtnPrimary onClick={onCancel}>{t('cancel')}</Styled.BtnPrimary>
      </Styled.BtnContainer>
    </Styled.TransferContainer>
  )
}
