import * as Styled from './styles'

import passenger from './imgs/lucide_user-2- blue branding.svg'
import briefcase from './imgs/icon_briefcase.svg'
import wifi from './imgs/lucide_wifi- blue branding.svg'
import { useTransferContext } from '../../../../../../contexts/TransferContext'
import { useTranslation } from 'react-i18next'

export const CarCard = ({ car, setOpened, opened }) => {
  const { going } = useTransferContext()

  const distanceValue = parseFloat(going.distance)
  const { t } = useTranslation()
  return (
    <Styled.Container>
      <>
        <Styled.CarContainer key={car.title}>
          <Styled.ImageCar src={car.img} />
          <Styled.Title>{car.title}</Styled.Title>
          <Styled.OptionsContainer>
            {car.passengers && (
              <Styled.Passenger>
                <img src={passenger} />
                <p>{car.passengers}</p>
              </Styled.Passenger>
            )}
            {car.briefcase && (
              <Styled.Briefcase>
                <img src={briefcase} />
                <p>{car.briefcase}</p>
              </Styled.Briefcase>
            )}
            {car.wifi && <Styled.Wifi src={wifi} />}
          </Styled.OptionsContainer>
        </Styled.CarContainer>
        <Styled.PriceContainer
          style={
            setOpened
              ? undefined
              : {
                  alignSelf: 'flex-start'
                }
          }
        >
          <h1>
            €{' '}
            {going.totalPrice
              ? (car.price * distanceValue).toFixed(2)
              : car.price}
          </h1>
          <p>IVA incl.</p>
          {!!setOpened && (
            <Styled.ChoiceButton
              onClick={() => {
                setOpened(!opened)
              }}
            >
              {t('choose')}
            </Styled.ChoiceButton>
          )}
        </Styled.PriceContainer>
      </>
    </Styled.Container>
  )
}
