import { BottomBanner } from './components/BottomBanner'
// import { BudgetRequest } from './budget-request'
// import { UserReviews } from './components/UserReviews'
import { EventCarousel } from './components/EventCarousel'
import { ExploreOurFleet } from './components/ExploreOurFleet'
import { OurFleet } from '../Home/components/OurFleet'
import { Navbar } from '../../components/Navbar'
import { GoogleComments } from '../Home/components/GoogleComments'
import Footer from '../../components/Footer'

import { Separator, Wrapper, EasyContent } from './styles'
import { BudgetRequest } from './components/BudgetRequest'
import { useEffect, useState } from 'react'

export const Events = () => {
  const [transparentNavbar, setTransparentNavbar] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      const { scrollY: top } = window
      const isTop = top < 300
      setTransparentNavbar(isTop)
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [transparentNavbar])

  return (
    <>
      <Navbar transparent={transparentNavbar} />
      <Wrapper>
        <EventCarousel />

        <EasyContent>
          <GoogleComments />
          <OurFleet />
          <BudgetRequest />
          <BottomBanner />
        </EasyContent>

        <Separator />
      </Wrapper>
      <Footer />
    </>
  )
}
