import { Input } from '@mantine/core'
import * as Styled from './styles'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import User from './imgs/lucide_user-2- blue branding.svg'
import Mail from './imgs/lucide_mail- blue branding.svg'
import Xis from './imgs/xis.webp'
import { useTranslation } from 'react-i18next'
import { useTransferContext } from '../../../../contexts/TransferContext'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

export const Transfer2 = () => {
  const { going, setGoing } = useTransferContext()

  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleSubmit = () => {
    if (
      !going.passengerName ||
      !going.passengerEmail ||
      !going.passengerPhoneNumber
    ) {
      return toast.error(t('toast.fulfillAllFields'))
    }

    navigate('/checkout3')
  }

  return (
    <Styled.Container>
      <h1>Transfer</h1>
      <Input.Wrapper id="input-demo" label={t('form.name')}>
        <Input
          icon={<img src={User} style={{ width: '22px' }} />}
          id="input-demo"
          placeholder={t('form.fillInYourName')}
          size={'lg'}
          onChange={(e) =>
            setGoing({ ...going, passengerName: e.target.value })
          }
          value={going.passengerName}
          rightSection={
            going.passengerName && (
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => setGoing({ ...going, passengerName: '' })}
                width={22.55}
                height={22.55}
                src={Xis}
              />
            )
          }
        />
      </Input.Wrapper>

      <Input.Wrapper id="input-to" label={t('form.email')}>
        <Input
          icon={<img src={Mail} style={{ width: '22px' }} />}
          id="input-demo"
          placeholder={t('form.fillInYourBestEmail')}
          size={'lg'}
          onChange={(e) =>
            setGoing({ ...going, passengerEmail: e.target.value })
          }
          value={going.passengerEmail}
          rightSection={
            going.passengerEmail && (
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => setGoing({ ...going, passengerEmail: '' })}
                width={22.55}
                height={22.55}
                src={Xis}
              />
            )
          }
        />
      </Input.Wrapper>

      <Input.Wrapper
        id="input-demo"
        label={t('form.phone')}
        style={{ marginTop: '10px' }}
      >
        <PhoneInput
          country={'pt'}
          required
          onChange={(e) => setGoing({ ...going, passengerPhoneNumber: e })}
          value={going.passengerPhoneNumber}
          inputStyle={{
            width: '100%',
            height: '4.8rem',
            borderRadius: '8.45738px',
            fontSize: '1.4rem'
          }}
        />
      </Input.Wrapper>

      <Styled.ButtonContainer>
        <Styled.SaveButton onClick={handleSubmit}>
          {t('form.continue')}
        </Styled.SaveButton>
      </Styled.ButtonContainer>
    </Styled.Container>
  )
}
