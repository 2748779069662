import * as React from 'react'

import { cn } from '../../utils/cn'

import styles from './styles.module.css'

const Input = React.forwardRef(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(styles.base, className)}
      ref={ref}
      {...props}
    />
  )
})
Input.displayName = 'Input'

export { Input }
