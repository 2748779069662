export const options = [
  {
    option: 'Hoje',
    first: true,
    getValue() {
      const startDate = new Date()
      startDate.setHours(0)

      const endDate = new Date()
      endDate.setHours(23)

      return { startDate, endDate }
    }
  },
  {
    option: 'Amanhã',
    getValue() {
      const today = new Date()

      const startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDay() + 1
      )
      startDate.setHours(0)

      const endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDay() + 1
      )
      endDate.setHours(23)

      return { startDate, endDate }
    }
  },
  {
    option: 'Últimos 7 dias',
    getValue() {
      const today = new Date()
      const startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDay() - 7
      )
      startDate.setHours(0)

      const endDate = new Date()
      endDate.setHours(23)

      return { startDate, endDate }
    }
  },
  {
    option: 'Últimos 30 dias',
    getValue() {
      const today = new Date()
      const startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDay() - 30
      )
      startDate.setHours(0)

      const endDate = new Date()
      endDate.setHours(23)

      return { startDate, endDate }
    }
  },
  {
    option: 'Período',
    value: 'CUSTOM_PERIOD',
    last: true,
    getValue() {}
  }
]
