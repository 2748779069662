import { ActionIcon, Menu } from '@mantine/core'
import * as Styled from './styles'
import { useTranslation } from 'react-i18next'
import { useRef, useState } from 'react'
import useClickOutside from '../../../../../../hooks/useClickOutside'
// import People from './imgs/people.webp'

export const Passengers = ({
  img,
  imgAlt,
  setPassengers,
  passengers,
  maxValue
}) => {
  const { allPassangers } = passengers

  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const menuDropdownRef = useRef(null)

  const domNode = useClickOutside(() => setOpen(false), menuDropdownRef, [])

  const IncreaseValueAdult = () => {
    if (allPassangers >= maxValue) {
      return
    } else {
      setPassengers({ ...passengers, allPassangers: allPassangers + 1 })
    }
  }

  const DecreaseValueAdult = () => {
    if (allPassangers < 1) {
      return
    } else {
      setPassengers({ ...passengers, allPassangers: allPassangers - 1 })
    }
  }

  return (
    <Styled.Container>
      <Styled.IconContainer>
        {typeof img === 'string' ? <Styled.Icon src={img} alt={imgAlt} /> : img}
      </Styled.IconContainer>
      <Styled.TextContainer>
        <Styled.Label>{t('reserveComponent.Passengers')}</Styled.Label>
        <Menu
          shadow="md"
          width={200}
          opened={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        >
          <Menu.Target ref={domNode}>
            <Styled.Text
              style={passengers.allPassangers ? { fontWeight: 600 } : undefined}
            >
              {passengers.allPassangers
                ? passengers.allPassangers
                : !open && t('reserveComponent.howManyPeople')}
            </Styled.Text>
          </Menu.Target>

          {open && (
            <Menu.Dropdown ref={menuDropdownRef}>
              <Menu.Label>
                <Styled.CounterContainer>
                  <Styled.CounterLeft>
                    <Styled.CounterTextContainer>
                      <label>{t('quantity')}</label>
                    </Styled.CounterTextContainer>
                  </Styled.CounterLeft>
                  <Styled.ButtonContainer>
                    <ActionIcon
                      className="btn-minus"
                      size={42}
                      variant="transparent"
                      onClick={DecreaseValueAdult}
                    >
                      –
                    </ActionIcon>
                    <strong>{allPassangers}</strong>
                    <ActionIcon
                      className="btn-plus"
                      size={42}
                      variant="transparent"
                      onClick={IncreaseValueAdult}
                    >
                      +
                    </ActionIcon>
                  </Styled.ButtonContainer>
                </Styled.CounterContainer>
              </Menu.Label>
            </Menu.Dropdown>
          )}
        </Menu>
      </Styled.TextContainer>
    </Styled.Container>
  )
}
