import {
  Button,
  Divider,
  Flex,
  LoadingOverlay,
  MediaQuery
  // , Pagination
} from '@mantine/core'
import * as Styled from './styles'
import { ReactComponent as Direction } from './imgs/direction.svg'
import { ReactComponent as Pin } from './imgs/mapPin.svg'
import { ReactComponent as Canceled } from './imgs/canceled.svg'
import { ReactComponent as Accepted } from './imgs/accepted.svg'
import Chevron from './imgs/right.webp'
import { useEffect, useRef } from 'react'
import fetchBooking from '../../../../../../services/booking'
import { useState } from 'react'
import {
  formatDateAndTime,
  formatDayOfWeek,
  formatMonth,
  formatTime
} from '../../../../../../utils/formattedDate'
import { AcceptedModal } from '../AcceptedModal'
import { RejectModal } from '../RejectModal'
import { useAuth } from '../../../../../../contexts/useAuth'
import { useClipboard } from '@mantine/hooks'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ClipLoader } from 'react-spinners'
import useIsElementVisible from '../../../../../../hooks/useIsElementVisible'
import { Copy } from 'lucide-react'
import { useTranslation } from 'react-i18next'

const PendingCards = ({ search, status, type }) => {
  const [transfer, setTransfer] = useState([])
  const [loading, setLoading] = useState(true)
  const [acceptedModal, setAcceptedModal] = useState(false)
  const [rejectModal, setRejectModal] = useState(false)
  const [transferContent, setTransferContent] = useState()
  const [refreshBookings, setRefreshBookings] = useState(false)
  const [visibleCards, setVisibleCards] = useState(10)
  const lastRef = useRef(null)
  const clipboard = useClipboard({ timeout: 500 })

  const isLastVisible = useIsElementVisible(lastRef.current)

  const { t } = useTranslation()

  const NAME_DECIDER = {
    PENDING: 'Pendente',
    ACCEPTED: 'Aceito',
    DONE: 'Realizado',
    CANCELED: 'Cancelado'
  }

  const COLOR_DECIDER = {
    PENDING: '#27c7ff',
    ACCEPTED: '#48d9a4',
    DONE: '#48d9a4',
    CANCELED: '#f8294e'
  }

  const TYPE_DECIDER = {
    Tours: { hasTour: true, hasHostel: false, hasEvent: false },
    Partnership: { hasHostel: true },
    Eventos: { hasEvent: true, hasHostel: false, hasTour: false },
    Transfer: { hasTour: false, hasHostel: false, hasEvent: false }
  }
  console.log(type, 'type')
  useEffect(() => {
    if (isLastVisible) {
      setVisibleCards(visibleCards + 10)
    }
  }, [isLastVisible])

  const navigate = useNavigate()

  useEffect(() => {
    if (!isLastVisible) {
      setLoading(true)
    }
    const filteredSearch = { ...search }

    for (const key in filteredSearch) {
      if (filteredSearch[key].trim() === '') {
        delete filteredSearch[key]
      }
    }

    const payload = {
      // page: 0,
      deleted: false,
      size: visibleCards,
      ...TYPE_DECIDER[type],
      status,
      ...filteredSearch
    }
    if (filteredSearch.id) {
      fetchBooking
        .getBookingId(filteredSearch.id)
        .then((res) => {
          setTransfer([res.data])
          setLoading(false)
        })
        .catch((err) => {
          console.log(err)
        })
      return
    }
    fetchBooking
      .getAllBooking(payload)
      .then((res) => {
        setTransfer(res.data.content)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [refreshBookings, search, visibleCards, type, status])

  return transfer ? (
    <>
      <Styled.CardContainer>
        {loading ? (
          <div
            style={{
              width: '100%',
              height: '40rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <ClipLoader size={50} color="#27c7ff" />
          </div>
        ) : (
          transfer?.map((transfer) => {
            const totalPrice = transfer.totalPrice / 100
            const totalDriverCommission =
              (transfer?.totalDriverCommission ?? 0) / 100
            const netValue =
              (transfer.totalPrice - (transfer?.totalDriverCommission ?? 0)) /
              100

            return (
              <Styled.Container
                color={COLOR_DECIDER[status]}
                key={transfer.id}
                onClick={() =>
                  navigate(`/dashboard/details/${transfer.id}`, {
                    replace: true
                  })
                }
              >
                <Styled.StatusContainer>
                  <Styled.StatusCard color={COLOR_DECIDER[status]}>
                    {NAME_DECIDER[status]}
                  </Styled.StatusCard>
                  <Styled.TimeDescription>
                    {formatDateAndTime(transfer.transferDate)}
                  </Styled.TimeDescription>
                  <Styled.PriceMobile>
                    € {(transfer.totalPrice / 100).toLocaleString('pt-PT')}
                  </Styled.PriceMobile>
                  <Styled.DayTitle>
                    {formatDayOfWeek(transfer.transferDate)},{' '}
                    {formatDateAndTime(transfer.transferDate)}
                  </Styled.DayTitle>
                  <Styled.IdWrapper
                    onClick={(e) => {
                      e.stopPropagation()
                      try {
                        clipboard.copy(transfer.id)
                        toast.success(t('toast.copiedID'))
                      } catch {
                        toast.error(t('errorCopiedID'))
                      }
                    }}
                  >
                    <strong>ID: </strong>
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        fontSize: '1.2rem'
                      }}
                    >
                      {transfer.id}
                      <Copy width={16} height={16} cursor="pointer" />
                    </span>
                  </Styled.IdWrapper>
                </Styled.StatusContainer>
                <Styled.Wrapper>
                  <Styled.InfoWrapper>
                    <Styled.AddressWrapper>
                      <Styled.DepartureWrapper>
                        <Styled.DepartureTitle>
                          <img src="/pin.svg" alt="Pin icon" /> Partida
                        </Styled.DepartureTitle>
                        <Styled.DepartureDescription>
                          {transfer.startingPlace}
                        </Styled.DepartureDescription>
                      </Styled.DepartureWrapper>

                      <div>
                        <Styled.KmTitle style={{ marginBottom: 10 }}>
                          {transfer.distanceInKm ?? 0}km
                        </Styled.KmTitle>

                        <Styled.DirectionButton>
                          <Direction />
                        </Styled.DirectionButton>
                      </div>

                      <Styled.DepartureWrapper>
                        <Styled.DepartureTitle>
                          <img src="/pin.svg" alt="Pin icon" /> Chegada
                        </Styled.DepartureTitle>
                        <Styled.DepartureDescription>
                          {transfer.arrivalPlace}
                        </Styled.DepartureDescription>
                      </Styled.DepartureWrapper>
                    </Styled.AddressWrapper>
                  </Styled.InfoWrapper>
                  <Styled.ValuesWrapper>
                    <MediaQuery
                      query="(max-width: 1024px)"
                      styles={{ display: 'none' }}
                    >
                      <Styled.PriceTitle>
                        <Styled.DepartureTitle>
                          Valor a Cobrar
                        </Styled.DepartureTitle>
                        €{totalPrice.toLocaleString('pt-PT')}
                      </Styled.PriceTitle>
                    </MediaQuery>
                    <VerticalSeparator />

                    <MediaQuery
                      query="(max-width: 1024px)"
                      styles={{ display: 'none' }}
                    >
                      <Styled.PriceTitle>
                        <Styled.DepartureTitle>
                          Valor EasyTransfer
                        </Styled.DepartureTitle>
                        €{totalDriverCommission.toLocaleString('pt-PT')}
                      </Styled.PriceTitle>
                    </MediaQuery>
                    <VerticalSeparator />

                    <MediaQuery
                      query="(max-width: 1024px)"
                      styles={{ display: 'none' }}
                    >
                      <Styled.PriceTitle>
                        <Styled.DepartureTitle>
                          Valor Líquido
                        </Styled.DepartureTitle>
                        €{netValue.toLocaleString('pt-PT')}
                      </Styled.PriceTitle>
                    </MediaQuery>
                  </Styled.ValuesWrapper>
                  {/* <Divider
                    className="divider"
                    style={{ margin: '0 25px' }}
                    orientation="vertical"
                  /> */}
                  <Styled.ButtonNormal>
                    <Styled.ButtonsWrapper style={{ cursor: 'pointer' }}>
                      <img src="/next.svg" alt="Right Chevron" />
                    </Styled.ButtonsWrapper>
                  </Styled.ButtonNormal>
                </Styled.Wrapper>
                <Styled.IconsAndNumbersWrapper>
                  {transfer.backpacks > 0 && (
                    <Styled.IconAndNumber>
                      <img
                        src="/briefcase.svg"
                        style={{ height: '24px' }}
                        alt="Briefcase icon"
                      />
                      <span>{transfer.backpacks}</span>
                    </Styled.IconAndNumber>
                  )}
                  {transfer.numberOfPassengers > 0 && (
                    <Styled.IconAndNumber>
                      <img
                        src="/users.svg"
                        style={{ height: '24px' }}
                        alt="Passenger icon"
                      />
                      <span>{transfer.numberOfPassengers}</span>
                    </Styled.IconAndNumber>
                  )}
                  {transfer.surfboards > 0 && (
                    <Styled.IconAndNumber>
                      <img
                        src="/surf.svg"
                        style={{ height: '24px' }}
                        alt="Shape icon"
                      />

                      <span>{transfer.surfboards}</span>
                    </Styled.IconAndNumber>
                  )}
                  {transfer.babySeats > 0 && (
                    <Styled.IconAndNumber>
                      <img
                        src="/car-seat.svg"
                        style={{ height: '24px' }}
                        alt="Carseat icon"
                      />
                      <span>{transfer.babySeats}</span>
                    </Styled.IconAndNumber>
                  )}
                  {transfer.animals > 0 && (
                    <Styled.IconAndNumber>
                      <img
                        src="/paw.svg"
                        style={{ height: '24px' }}
                        alt="Paw icon"
                      />
                      <span>{transfer.animals}</span>
                    </Styled.IconAndNumber>
                  )}
                </Styled.IconsAndNumbersWrapper>
                <Styled.ButtonMobile>
                  <Styled.ButtonsWrapper style={{ cursor: 'pointer' }}>
                    <img src="/next.svg" alt="Right Chevron" />
                  </Styled.ButtonsWrapper>
                </Styled.ButtonMobile>
              </Styled.Container>
            )
          })
        )}
        {!!transfer.length && <div ref={lastRef}></div>}
      </Styled.CardContainer>
    </>
  ) : (
    <div>Nenhum registro</div>
  )
}

function VerticalSeparator() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2"
      height="46"
      viewBox="0 0 2 46"
      fill="none"
    >
      <path d="M1 0V46" stroke="#27C7FF" />
    </svg>
  )
}

export default PendingCards
