import styled, { css } from 'styled-components'

const wrapperModifiers = {
  sidebarOpen: () => css`
    span:first-child {
      transform: rotate(45deg) translate(0.48rem, 0.48rem);
    }

    span:nth-child(2) {
      width: 0%;
      opacity: 0;
    }

    span:last-child {
      transform: rotate(-45deg) translate(0.48rem, -0.48rem);
    }
  `
}

export const Wrapper = styled.button`
  ${({ isSidebarOpen }) => css`
    background-color: transparent;
    border: none;
    max-height: 2.4rem;
    max-width: 2.4rem;
    height: 2.4rem;
    width: 2.4rem;
    outline: none;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      transform: scale(1.2);
    }

    span {
      position: relative;
      display: block;
      width: 100%;
      height: 0.25rem;
      margin-bottom: 0.4rem;
      background-color: #404040;
      border-radius: 0.5rem;
      transition: all 0.3s cubic-bezier(1, 0.13, 0.35, 1.09);

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    ${isSidebarOpen && wrapperModifiers.sidebarOpen}
  `}
`
