import React, { useState, lazy, Suspense, useEffect } from 'react'
import * as Styled from './styles'

import { NewTransferModal } from './components/NewTransferModal'
import debounce from '../../../../utils/debounce'
import { NewTransferButton } from './components/NewTransferButton'
import { useStyles } from './hooks/useStyles'
import { useFilter } from './hooks/useFilter'
import { TransferOptions } from './components/TransferOptions'
import { StatusFilterInput } from './components/StatusFilterInput'
import { SearchInput } from './components/SearchInput'
import { LowCostList } from './components/LowCostList'
import { useSessionState } from '../../../../hooks/useSessionState'
import { NewLowCostModal } from './components/NewLowCostModal'
import { AcceptedCards } from './components/AcceptedCards'
import { RejectCards } from './components/RejectCards'
import { AccomplishedCards } from './components/AccomplishedCards'
import PendingCards from './components/PendingCards'

// import { AcceptedCards } from './components/AcceptedCards'
// import { RejectCards } from './components/RejectCards'
// import { AccomplishedCards } from './components/AccomplishedCards'

export const TransferAdm = () => {
  const { filter, filterOptions, changeFilter } = useFilter()

  const { classes } = useStyles()
  const [activeTransferOption, setActiveTransferOption] = useSessionState({
    name: 'activeTransferOption',
    defaultValue: 0
  })

  const [search, setSearch] = useState('')
  const [newTransfer, setNewTransfer] = useState(false)

  const handleChange = (value, name) => {
    debounce(() => setSearch({ ...search, [name]: value }), 500)
  }
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])
  const menuItems = [{ label: 'Transfer', onClick: () => setNewTransfer(true) }]

  return (
    <Styled.Container>
      <Styled.HeaderContainer>
        <Styled.Title>Transfers</Styled.Title>
        {/* <NewTransferButton menuItems={menuItems} /> */}
      </Styled.HeaderContainer>

      <Styled.ButtonsWrapper>
        <div className={classes.links}>
          <TransferOptions
            activeOption={activeTransferOption}
            onChangeOption={setActiveTransferOption}
          />

          <Styled.FiltersWrapper>
            <SearchInput
              propName="places"
              onChange={handleChange}
              placeholder="Pesquisar por zona"
            />
            <SearchInput
              propName="id"
              onChange={handleChange}
              placeholder="Pesquisar por id"
            />

            <StatusFilterInput
              selected={filter.value}
              options={filterOptions}
              onChange={changeFilter}
            />
          </Styled.FiltersWrapper>
        </div>
      </Styled.ButtonsWrapper>

      <ActiveTransferList
        filter={filter}
        activeOptionIndex={activeTransferOption}
        search={search}
      />

      {/* <NewTransferModal
        opened={newTransfer}
        onClose={() => setNewTransfer(false)}
      /> */}
    </Styled.Container>
  )
}

const TAB_OPTIONS = ['Transfer', 'Tours', 'Low Cost', 'Partnership', 'Eventos']

function ActiveTransferList({ activeOptionIndex, filter, search }) {
  console.log(filter, 'filter')
  if (TAB_OPTIONS[activeOptionIndex] === 'Low Cost') {
    return <LowCostList status={filter.value} search={search} />
  } else if (TAB_OPTIONS[activeOptionIndex] !== 'Low Cost') {
    return (
      <PendingCards
        search={search}
        status={filter.value}
        type={TAB_OPTIONS[activeOptionIndex]}
      />
    )
  }
}
