import styled from 'styled-components'

import { Button as UIButton } from '../../../../components/Button'
import {
  Carousel as UICarousel,
  CarouselContent as UICarouselContent,
  CarouselItem as UICarouselItem
} from '../../../../components/Carousel'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  > h2 {
    text-align: center;
    font-weight: bold;
    font-size: 3rem;
    line-height: 3.6rem;
    letter-spacing: -0.025em;

    span {
      color: #27c7ff;
    }
  }

  > div:first-of-type {
    display: none;

    /* @media (min-width: 768px) {
    } */

    @media (min-width: 1024px) {
      display: grid;
      grid-template-columns: repeat(5, minmax(0, 1fr));
      gap: 1.6rem;
    }
  }
`

export const Carousel = styled(UICarousel)`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  overflow: hidden;
  margin-top: 2.4rem;

  @media (min-width: 1024px) {
    display: none;
  }
`

export const CarouselContent = styled(UICarouselContent)`
  gap: 1.6rem;
  padding-left: 1.2rem;
`

export const CarouselItem = styled(UICarouselItem)`
  > div > div > div:first-of-type {
    width: 60%;
    max-width: 28rem;
    min-width: 20rem;

    img {
      object-fit: cover;
    }
  }
`

export const Button = styled(UIButton)`
  margin: 0 auto;
  font-display: flex;
  max-width: 25rem;
  gap: 0.8rem;
  border-radius: 1.2rem;
  background-color: #27c7ff;
  padding: 0.8rem 2.4rem;
  color: #27272a;

  :hover {
    background-color: #27c7ff;
    opacity: 0.8;
  }

  span {
    font-size: 1.6rem;
    font-weight: 600;
  }

  svg {
    width: 2.4rem;
    height: 2.4rem;
  }
`
