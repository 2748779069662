import React, { useEffect, useState } from 'react'
import { CalendarDays, X } from 'lucide-react'
import { Checkbox } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import * as Styled from './styles'

const FilterSection = ({
  title,
  filter,
  options,
  type,
  onCheckboxChange,
  activeFilters
}) => {
  const [dateOn, setDateOn] = useState(false)
  const [temporaryFilters, setTemporaryFilters] = useState(
    activeFilters[filter] || {}
  )

  const handleCheckboxChange = (item) => {
    if (type === 'solo') {
      setTemporaryFilters({ [item.value]: item.value })
      onCheckboxChange([item.value])
    } else if (type === 'normal') {
      const updatedFilters = {
        ...temporaryFilters,
        [item.value]: item.value
      }
      const selectedOptions = Object.keys(updatedFilters)
      setTemporaryFilters(updatedFilters)
      onCheckboxChange(selectedOptions)
    } else if (type === 'date') {
      let transferDateStartAt = null
      let transferDateEndAt = null

      if (item.label === 'Hoje') {
        const today = new Date()
        transferDateStartAt = today
        transferDateEndAt = today
      } else if (item.label === 'Ontem') {
        const yesterday = new Date()
        yesterday.setDate(yesterday.getDate() - 1)
        transferDateStartAt = yesterday
        transferDateEndAt = yesterday
      }

      if (transferDateStartAt && transferDateEndAt) {
        setTemporaryFilters([
          transferDateStartAt,
          transferDateEndAt,
          item.label
        ])
        onCheckboxChange([transferDateStartAt, transferDateEndAt, item.label])
      } else {
        const { [item.label.toLowerCase()]: _, ...restFilters } =
          temporaryFilters
        setTemporaryFilters(restFilters)
        onCheckboxChange(restFilters)
      }
    }
  }

  console.log(temporaryFilters, 'temporaryFilters!!')

  return (
    <Styled.Wrapper>
      <Styled.ContentTitle>{title}</Styled.ContentTitle>
      <Styled.OptionsWrapper>
        {options.map((option) => (
          <Checkbox
            key={option.label}
            size="lg"
            label={option.label}
            color="yellow"
            checked={
              temporaryFilters && temporaryFilters[option.label.toLowerCase()]
            }
            onChange={() => handleCheckboxChange(option)}
          />
        ))}

        {type === 'date' && (
          <Checkbox
            size="lg"
            label="Personalizado"
            color="yellow"
            onChange={() => setDateOn(!dateOn)}
          />
        )}
        {dateOn && type === 'date' && (
          <DatePickerInput
            type="range"
            placeholder="Pick dates range"
            valueFormat="DD/MM/YYYY"
            icon={<CalendarDays color="#27c7ff" />}
          />
        )}
      </Styled.OptionsWrapper>
    </Styled.Wrapper>
  )
}

export const TableFilters = ({
  isActive,
  filters,
  onClose,
  setSelectFilters,
  selectFilters
}) => {
  const [temporaryFilters, setTemporaryFilters] = useState(selectFilters)

  const handleCheckboxChange = (filterKey, temporaryValues) => {
    setTemporaryFilters({
      ...temporaryFilters,
      [filterKey]: temporaryValues
    })
  }

  console.log(temporaryFilters, 'temporaryFilters')

  const handleClearFilters = () => {
    const clearFilter = {
      size: 8,
      page: 0,
      status: 'DONE'
    }
    setSelectFilters(clearFilter)
    setTemporaryFilters({
      ...clearFilter,
      cleared: true
    })
    onClose()
  }

  const handleApplyFilters = () => {
    setSelectFilters(temporaryFilters)
    onClose()
  }

  useEffect(() => {
    if (isActive)
      setTemporaryFilters((oldState) => ({ ...oldState, cleared: false }))
  }, [isActive])

  return (
    <>
      <Styled.Container active={isActive}>
        <Styled.Header>
          <Styled.Title>Filtros</Styled.Title>
          <Styled.Close onClick={() => onClose()}>
            <X />
          </Styled.Close>
        </Styled.Header>
        <Styled.Content key={temporaryFilters.cleared}>
          {filters.map((filter) => (
            <FilterSection
              key={filter.title}
              {...filter}
              onCheckboxChange={(temporaryValues) =>
                handleCheckboxChange(filter.filter, temporaryValues)
              }
              activeFilters={selectFilters}
            />
          ))}
        </Styled.Content>
        <Styled.ButtonsWrapper>
          <Styled.SecondaryButton onClick={handleClearFilters}>
            LIMPAR FILTROS
          </Styled.SecondaryButton>
          <Styled.PrimaryButton onClick={handleApplyFilters}>
            APLICAR FILTROS
          </Styled.PrimaryButton>
        </Styled.ButtonsWrapper>
      </Styled.Container>
    </>
  )
}
