import { ReactComponent as Passenger } from './imgs/passenger.svg'
import { ReactComponent as User } from './imgs/user.svg'
import { ReactComponent as Email } from './imgs/mail.svg'
import { ReactComponent as Phone } from './imgs/phone.svg'

import * as Styled from './styles'

export const DetailsPassenger = ({ transfer }) => (
  <Styled.CardContainer>
    <Styled.Container key={transfer?.id}>
      <Styled.Wrapper>
        <Styled.InfoWrapper>
          <Styled.PaymentTitle>
            <Passenger />
            Passageiro principal
          </Styled.PaymentTitle>
        </Styled.InfoWrapper>
        <Styled.PassengerInfoContainer>
          <Styled.PassengerInputs>
            <Styled.PaymentInfo>
              <Styled.PaymentInfoTitle>
                <User />
                Nome
              </Styled.PaymentInfoTitle>
              <Styled.PaymentInfoDesc>
                {transfer?.passengerName}
              </Styled.PaymentInfoDesc>
            </Styled.PaymentInfo>
            <Styled.PaymentInfo>
              <Styled.PaymentInfoTitle>
                <Email />E - mail
              </Styled.PaymentInfoTitle>
              <Styled.PaymentInfoDesc>
                {transfer?.passengerEmail}
              </Styled.PaymentInfoDesc>
            </Styled.PaymentInfo>
            <Styled.PaymentInfo>
              <Styled.PaymentInfoTitle>
                <Phone />
                Telemóvel
              </Styled.PaymentInfoTitle>
              <Styled.PaymentInfoDesc>
                <a href={`tel:${transfer?.passengerPhoneNumber}`}>
                  {transfer?.passengerPhoneNumber}
                </a>
              </Styled.PaymentInfoDesc>
            </Styled.PaymentInfo>
          </Styled.PassengerInputs>
        </Styled.PassengerInfoContainer>
      </Styled.Wrapper>
    </Styled.Container>
  </Styled.CardContainer>
)
