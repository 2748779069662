import {
  BackgroundShape1Icon,
  BackgroundShape2Icon,
  EncontreOs,
  HeroTourSection,
  HeroTourSectionInner,
  HeroTourSectionParent,
  ImagePortugalAndSpainMapTIcon,
  MelhoresPasseios,
  Paragraph,
  SectionMap,
  Span,
  SpanParent
} from './styles'

import BackgroundShape1 from '../../../../assets/backgroundshape1.svg'
import BackgroundShape2 from '../../../../assets/backgroundshape2.svg'
import Map from '../../../../assets//map-portugal-and-spain-pins.svg'
import { useTranslation } from 'react-i18next'

export default function Hero() {
  const { t } = useTranslation()
  return (
    <HeroTourSectionParent id="MapTours">
      <HeroTourSection>
        <HeroTourSectionInner>
          <SpanParent>
            <Span>
              <EncontreOs
                dangerouslySetInnerHTML={{ __html: t('tours.findBestTours') }}
              />
            </Span>
            <Paragraph>{t('tours.authenticRoutes')}</Paragraph>
          </SpanParent>
        </HeroTourSectionInner>
      </HeroTourSection>

      <SectionMap>
        <BackgroundShape1Icon alt="" src={BackgroundShape1} />
        <BackgroundShape2Icon alt="" src={BackgroundShape2} />

        <ImagePortugalAndSpainMapTIcon
          id="mapPortugalAndSpainPins"
          alt=""
          src={Map}
        />
      </SectionMap>
    </HeroTourSectionParent>
  )
}
