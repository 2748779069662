import api from './api'

const fetchObj = {
  createPayment: (payload) => {
    const res = api.post('/transfer', payload)

    return res
  },
  changeStatus: (id, status) => {
    const res = api.patch(`/transfer/change-status/${id}/${status}`)

    return res
  },
  transfersPerUser: (params) => {
    const res = api.get(`/transfer`, {
      params: {
        ...params
      }
    })

    return res
  },

  getCounters: (params) => {
    const res = api.get('/transfer/counters', { params })
    return res
  }
}

export default fetchObj
