import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 94vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const Status = styled.div`
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--yellow-2-support, #DFF7FF);
  color: var(--yellow-3-support, #1D95BF);
  text-align: center;
  font-family: Neometric;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 23.4px */
  letter-spacing: 0.18px;
`

export const TransferId = styled.h1`
  color: var(--yellow-brand-primary, #27c7ff);
  font-family: Neometric;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.32px;
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.h1`
  display: flex;
  gap: 10px;
  font-family: Neometric;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.01em;
  text-align: left;
`

export const PrimaryButton = styled.button`
  display: flex;
  height: 48px;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  border-radius: 16px;
  background: #27c7ff;
  color: var(--white-grayscale, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;
  text-transform: uppercase;
`
export const SecondaryButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 35px;
  width: 150px;
  border-radius: 12px;

  background-color: transparent;

  border: 2px solid var(--yellow-brand-primary, #27c7ff);
  color: var(--yellow-brand-primary, #27c7ff);
  text-align: center;

  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;
  text-transform: uppercase;
`

export const DetailsContainer = styled.div`
  display: flex;
  gap: 20px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  button {
    /* width: 100%; */
    height: 48px;
    padding: 8px 16px 8px 8px;
    border-radius: 32px;
    border: 1px;
    gap: 8px;
    border: 1px solid #e6e6e6;
    font-family: Neometric;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    color: #404040;
  }

  div {
    justify-content: flex-start;
  }

  @media (max-width: 1024px) {
    & {
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      width: 92vw;
    }
    &::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const Count = styled.div`
  color: var(--yellow-3-support, #1D95BF);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
  letter-spacing: 0.12px;
  display: flex;
  height: 18px;
  padding: 8px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: var(--yellow-2-support, #DFF7FF);
`
