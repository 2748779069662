import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;

  margin-bottom: 210px;

  h1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;

    color: #0d0d0d;
  }

  @media (max-width: 768px) {
    h1 {
      color: var(--black-brand-primary, #0d0d0d);
      text-align: center;
      font-family: Neometric;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
`
