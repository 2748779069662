import { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import { Navbar } from '../../../../components/Navbar'
import Footer from '../../../../components/Footer'
import PhoneInput from 'react-phone-input-2'
import { Checkbox, useMantineTheme } from '@mantine/core'
import CustomDropzone from './Arquives/CustomDropzone'
import classes from './Arquives/DropzoneButton.module.css'
import classesAlternative from './Arquives/DropzoneButtonAlternative.module.css'
import { AddPassenger } from './AddPassenger'
import { toast } from 'react-toastify'
import fetchFile from '../../../../services/file'
import fetchAccount from '../../../../services/account'
import { convertDateFormat } from '../../../../utils/formattedDate'
import { useTranslation } from 'react-i18next'

const Avatar = styled.img``

const H = styled.h3`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: 0.01em;
  line-height: 110%;
  font-weight: 900;
  font-family: inherit;
  @media screen and (max-width: 420px) {
    font-weight: 800;
    font-family: Neometric;
    font-size: 2%.4rem;
    align-self: stretch;
    width: auto;
  }
`
const PreenchaComSeus = styled.span``
const OsPrximosPassos = styled.span`
  font-weight: 500;
`
const H5Txt = styled.span`
  width: 100%;
`
const H1 = styled.h5`
  margin: 0;
  width: 531px;
  position: relative;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 120%;
  display: flex;
  align-items: center;
  font-family: Poppins;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`
const H3Parent = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  @media screen and (max-width: 960px) {
    padding-top: 24px;
    flex: unset;
    align-self: stretch;
  }
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
  }
`
const ImgIcon = styled.img`
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  @media screen and (max-width: 420px) {
    width: 300px;
  }
`
const Sectionhero = styled.section`
  align-self: stretch;
  margin-top: 8rem;
  background: linear-gradient(95.8deg, #27c7ff, rgba(255, 255, 255, 0));
  height: 278px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 156px;
  box-sizing: border-box;
  gap: 10px;
  text-align: left;
  font-size: 32px;
  color: #222;
  font-family: Neometric;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
  @media screen and (max-width: 600px) {
    padding-left: 16px;
    padding-top: 10px;
    padding-right: 16px;
    box-sizing: border-box;
    height: 100%;
  }
`
const H2 = styled.h4`
  margin: 0;
  flex: 1;
  position: relative;
  font-size: 18px;
  letter-spacing: 0.01em;
  line-height: 120%;
  font-weight: 800;
  font-family: Neometric;
  color: #222;
  text-align: left;
`
const H4Wrapper = styled.div`
  align-self: stretch;
  border-bottom: 0.5px solid #bfbfbf;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 0px;
`
const Upload = styled.input`
  width: 150px;
  position: relative;
  height: 150px;
`
const P = styled.p`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 120%;
  font-weight: 500;
  font-family: Poppins;
  color: #222;
  text-align: center;
`
const Imgicon = styled.img`
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
`
const H3 = styled.h6`
  margin: 0;
  position: relative;
  font-size: 12px;
  font-weight: 400;
  font-family: Poppins;
  color: #222;
  text-align: left;
  @media screen and (max-width: 420px) {
    flex: 1;
  }
`
const ImgiconParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`
const Imgicon1 = styled.img`
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
`
const List = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 4px;
  }
`
const PParent = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px;
  gap: 16px;
`
const UploadParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 16px;
  gap: 10px;
`
const NomeCompleto = styled.b`
  font-size: 14px;
  font-family: Neometric;
  color: #222;
`
const Span = styled.span`
  font-size: 16px;
  font-weight: 500;
  font-family: Neometric;
  color: #f8294e;
`
const EMail = styled.label`
  cursor: pointer;
  position: relative;
  text-align: left;
`
const Label = styled.label`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`
const Input = styled.input`
  border: 0.5px solid #bfbfbf;
  outline: none;
  font-family: Poppins;
  font-size: 14px;
  background-color: transparent;
  align-self: stretch;
  border-radius: 6.09px;
  box-sizing: border-box;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12.189754486083984px 8px;
  color: #a2a2a2;
`
const InputForms = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`
const Span1 = styled.span`
  font-size: 16px;
  font-family: Neometric;
  color: #f8294e;
`
const Input1 = styled.input`
  border: 0.7px solid #bfbfbf;
  outline: none;
  font-family: Poppins;
  font-size: 14px;
  background-color: transparent;
  align-self: stretch;
  border-radius: 8.46px;
  box-sizing: border-box;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 16px 4px;
  color: #a2a2a2;
`
const RowInputFormsframe = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`
const Sim = styled.option``
const Select = styled.select`
  align-self: stretch;
  border-radius: 6.09px;
  background-color: transparent;
  border: 0.5px solid #bfbfbf;
  box-sizing: border-box;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12.189754486083984px 8px;
  font-family: Poppins;
  font-size: 14px;
  color: #a2a2a2;
`
const ImgIcon1 = styled.img`
  width: 45px;
  position: relative;
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
`
const H4 = styled.h5`
  width: 100%;
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: 1.4rem;
  line-height: 120%;
  font-weight: 600;
  font-family: Poppins;
  color: #222;
  text-align: left;
`
const H5 = styled.h6`
  margin: 0;
  width: 100%;
  position: relative;
  font-size: 12px;
  line-height: 130%;
  font-weight: 400;
  font-family: Poppins;
  color: #222;
  text-align: left;
  display: inline-block;
`
const H5Parent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`
const ImgParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  @media screen and (max-width: 420px) {
    flex: 1;
  }
`
const Div = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
  width: 70%;
`
const DivWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
    flex: unset;
  }
`
const Upload1 = styled.input`
  width: 512px;
  border-radius: 16px;
  border: 2px dashed #bfbfbf;
  box-sizing: border-box;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 60px;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
    height: auto;
  }
`
const Imgicon2 = styled.img`
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
`
const ImgiconWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 4px 0px 0px;
`
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  @media screen and (max-width: 960px) {
    align-self: stretch;
    width: auto;
    flex-direction: column;
  }
  @media screen and (max-width: 430px) {
    align-self: stretch;
    width: auto;
    flex-direction: column;
    padding-bottom: 24px;
    box-sizing: border-box;
  }
`
const RowInputFormsframe1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 430px) {
    align-self: stretch;
    width: auto;
  }
`
const Div1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`
const H6 = styled.h4`
  margin: 0;
  position: relative;
  font-size: 18px;
  letter-spacing: 0.01em;
  line-height: 120%;
  font-weight: 800;
  font-family: Neometric;
  color: #222;
  text-align: left;
`
const Modelo = styled.b`
  font-family: Neometric;
  color: #222;
`
const Span2 = styled.span`
  font-family: Neometric;
  color: #f8294e;
`
const EMail1 = styled.label`
  cursor: pointer;
  position: relative;
  font-size: 14px;
  text-align: left;
`
const Upload2 = styled.input`
  width: 264px;
  border-radius: 16px;
  border: 2px dashed #bfbfbf;
  box-sizing: border-box;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 60px;
  @media screen and (max-width: 420px) {
    min-width: 250px;
  }
`
const FotoDoInterior = styled.b`
  font-family: Neometric;
  color: #404040;
`
const H7 = styled.h5`
  margin: 0;
  align-self: stretch;
  width: 218px;
  position: relative;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 120%;
  text-align: left;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`
const H5Group = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 140px;
`
const H8 = styled.h6`
  margin: 0;
  position: relative;
  font-size: 12px;
  letter-spacing: 0.01em;
  line-height: 120%;
  font-weight: 400;
  font-family: Poppins;
  color: #000;
  text-align: left;
`
const FrameParent = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  @media screen and (max-width: 430px) {
    align-self: stretch;
    width: 140px;
  }
`
const Container1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  @media screen and (max-width: 600px) {
    width: 100%;
    flex-direction: column;
  }
`
const Upload3 = styled.input`
  width: 263px;
  border-radius: 16px;
  border: 2px dashed #bfbfbf;
  box-sizing: border-box;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 60px;
  @media screen and (max-width: 430px) {
    min-width: 250px;
  }
`
const H9 = styled.h5`
  margin: 0;
  align-self: stretch;
  width: 197px;
  position: relative;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 120%;
  text-align: left;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`
const ContainerParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 0px;
  gap: 24px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
  @media screen and (max-width: 420px) {
    flex-direction: column;
  }
`
const Lucideusers2Icon = styled.img`
  width: 32px;
  position: relative;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
`
const Passageiros = styled.b`
  align-self: stretch;
  flex: 1;
  position: relative;
  font-size: 16px;
  display: flex;
  font-family: Neometric;
  color: #404040;
  text-align: left;
  align-items: center;
`
const Label1 = styled.label`
  cursor: pointer;
  height: 3.8rem;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8.68px;
`
const Count = styled.select`
  align-self: stretch;
  border-radius: 8px;
  background-color: transparent;
  border: 0.5px solid #bfbfbf;
  box-sizing: border-box;
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12.189754486083984px 8px;
  font-family: Neometric;
  font-weight: 500;
  font-size: 16px;
  color: #404040;
`
const LabelParent = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`
const Bagagem = styled.p`
  margin: 0;
`
const BagagemDespachada = styled.label`
  cursor: pointer;
  flex: 1;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  font-family: Neometric;
  color: #404040;
  text-align: left;
`
const Count1 = styled.select`
  align-self: stretch;
  border-radius: 6.09px;
  background-color: transparent;
  border: 0.5px solid #bfbfbf;
  box-sizing: border-box;
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12.189754486083984px 8px;
  font-family: Neometric;
  font-weight: 500;
  font-size: 16px;
  color: #404040;
`
const LabelGroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`
const Label2 = styled.label`
  cursor: pointer;
  align-self: stretch;
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8.68px;
`
const FrameContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`
const EscolhaOsDias = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-family: Neometric;
  color: #222;
`
const H10 = styled.h5`
  margin: 0;
  position: relative;
  letter-spacing: 0.01em;
  line-height: 120%;
  text-align: left;
  @media screen and (max-width: 960px) {
    align-self: stretch;
    width: auto;
  }
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
  }
`
// const Checkbox = styled.input`
//   margin: 0;
//   width: 18px;
//   position: relative;
//   height: 18px;
// `
const Label3 = styled.label`
  cursor: pointer;
  position: relative;
  font-size: 1.4rem;
  letter-spacing: 0.01em;
  line-height: 120%;
  font-family: Poppins;
  color: #000;
  text-align: left;
`
const ChecboxeComLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  .mantine-Checkbox-inner {
    width: 20px;
    height: 20px;
  }

  .mantine-Checkbox-body {
    align-items: center;

    label {
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;

      letter-spacing: 0.01em;
      margin-bottom: 10px;

      color: #000000;
    }
  }

  .mantine-Checkbox-input {
    display: flex;
    align-items: flex-start;
    padding: 1.44609px;
    gap: 7.23px;

    width: 20px;
    height: 20px;
    border-radius: 5.78435px;
    cursor: pointer;
  }

  .mantine-Checkbox-input:checked {
    background: #27c7ff;
    border-color: #27c7ff;

    svg {
      width: 11.41px;
    }
  }

  @media only screen and (max-width: 944px) {
    .mantine-Checkbox-body {
      label {
        font-size: 1.4rem;
      }
    }
  }
`
const Div2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 0px;
  gap: 16px;
`
const TurnoComMelhores = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 12px;
  line-height: 100%;
  font-weight: 500;
  font-family: Poppins;
  color: #1D95BF;
  text-align: left;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`
const Div3 = styled.div`
  border-radius: 4px;
  background-color: #DFF7FF;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 4px;
  gap: 8px;
`
const H11 = styled.h4`
  margin: 0;
  position: relative;
  font-size: 18px;
  letter-spacing: 0.01em;
  line-height: 120%;
  font-weight: 800;
  font-family: Neometric;
  color: #222;
  text-align: left;
  @media screen and (max-width: 420px) {
    flex: 1;
  }
`
const LucideuploadIcon = styled.img`
  width: 48px;
  position: relative;
  height: 48px;
  overflow: hidden;
  flex-shrink: 0;
`
const SolteAquiSeu = styled.div`
  position: relative;
  font-size: 14px;
  font-family: Neometric;
  color: #404040;
  text-align: left;
`
const Upload4 = styled.div`
  flex: 1;
  border-radius: 16px;
  border: 2px dashed #bfbfbf;
  box-sizing: border-box;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 60px;
  gap: 7px;
  @media screen and (max-width: 420px) {
    min-width: 250px;
    flex: unset;
    align-self: stretch;
  }
`
const H12 = styled.h5`
  margin: 0;
  position: relative;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 120%;
  text-align: left;
`
const LucidealertCircleIcon = styled.img`
  width: 18px;
  position: relative;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
`
const FrenteEVerso = styled.div`
  align-self: stretch;
  width: 89px;
  position: relative;
  font-size: 12px;
  letter-spacing: 0.01em;
  line-height: 120%;
  font-weight: 600;
  font-family: Poppins;
  color: #f8294e;
  text-align: left;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`
const LucidealertCircleParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
`
const Container2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
  @media screen and (max-width: 430px) {
    align-self: stretch;
    flex-direction: column;
    flex: unset;
  }
`
const ContainerGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 0px 0px;
  gap: 24px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
  @media screen and (max-width: 430px) {
    flex-direction: column;
  }
`
const H5DeConduo = styled.div`
  position: relative;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 120%;
  text-align: left;
`
const Container3 = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
  @media screen and (max-width: 420px) {
    flex-direction: column;
    flex: unset;
    align-self: stretch;
  }
`
const Container4 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
  @media screen and (max-width: 420px) {
    flex-direction: column;
    flex: unset;
    align-self: stretch;
  }
`
const TvdeTaxiRnaat = styled.span`
  text-transform: uppercase;
`
const H13 = styled.h5`
  margin: 0;
  width: 217px;
  position: relative;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 120%;
  text-align: left;
  display: inline-block;
  flex-shrink: 0;
`
const Container5 = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`
const ContainerWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 0px 0px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
  @media screen and (max-width: 430px) {
    flex-direction: column !important;
  }
`
const Checkbox1 = styled.input`
  margin: 0;
  width: 24px;
  position: relative;
  height: 24px;
  accent-color: #27c7ff;
  @media screen and (max-width: 600px) {
    width: 32px;
    height: 32px;
  }
`
const ConcordoComOs = styled.span`
  font-size: 1.6rem;
  font-weight: 500;
  font-family: Poppins;
  line-height: 130%;
  color: #000;
`
const Span3 = styled.span`
  font-size: 18px;
  font-family: Poppins;
  color: #f8294e;
`
const Label4 = styled.label`
  cursor: pointer;
  position: relative;
  letter-spacing: 0.01em;
  line-height: 120%;
  text-align: left;
`
const ChecboxeComLabel1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  @media screen and (max-width: 960px) {
    flex: 1;
  }
  @media screen and (max-width: 420px) {
    flex: 1;
  }
`
const Div4 = styled.div`
  align-self: stretch;
  border-top: 0.5px solid #bfbfbf;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 0px;
`
const ConheaAFrota = styled.b`
  position: relative;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 120%;
  text-transform: uppercase;
  font-family: Roboto;
  color: #fff;
  text-align: center;
`
const Button = styled.button`
  cursor: pointer;
  border: none;
  padding: 16px 24px 16px 32px;
  background-color: #27c7ff;
  width: 360px;
  border-radius: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px 18px 19.69px rgba(0, 0, 0, 0.05);
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 16px;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
  }
`
const ButtonWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const ImgIcon2 = styled.img`
  width: 64px;
  position: relative;
  height: 64px;
  overflow: hidden;
  flex-shrink: 0;
`
const H14 = styled.h5`
  margin: 0;
  position: relative;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 120%;
  font-weight: 600;
  font-family: Poppins;
  color: #222;
  text-align: left;
`
const EMail2 = styled.span`
  text-decoration: underline;
`
const FiqueAtentoAoSeuEMail = styled.b`
  font-family: Poppins;
`
const FiqueAtentoAoSeuEMail1 = styled.span`
  font-family: Poppins;
`
const H15 = styled.h5`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 120%;
  color: #222;
  text-align: center;
`
const H5Parent1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`
const Container6 = styled.div`
  width: 1128px;
  border-radius: 24px;
  background-color: #d5f6ea;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 24px 32px;
  box-sizing: border-box;
  gap: 24px;
  @media screen and (max-width: 960px) {
    align-self: stretch;
    width: auto;
  }
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
    height: auto;
    padding: 16px 16px 24px;
    box-sizing: border-box;
    min-width: 300px;
  }
`
const FormsDriverContent = styled.div`
  margin: 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 156px;
  gap: 40px;
  @media screen and (max-width: 960px) {
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 420px) {
    padding: 16px;
    box-sizing: border-box;
  }
`
const SectionheroParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`
const SitePrincipalCadastroDeMRoot = styled.div`
  width: 100%;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const NumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  height: 4.8rem;

  .mantine-NumberInput-input {
    padding: 16px;
    border: 0.5px solid rgba(56, 40, 14, 0.3);
    border-radius: 6px;
    height: 4.8rem;
    font-size: 1.4rem;
  }

  .btn-plus {
    position: absolute;
    right: 0.5rem;
    align-items: center;
  }
  .btn-plus-checked {
    position: absolute;
    right: 0.5rem;
    background: rgba(162, 162, 162, 0.4);
    border: 0.5px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    width: 22.55px !important;
    height: 22.55px !important;
    min-width: 22.55px !important;
    min-height: 22.55px !important;
    align-items: center;
    font-size: 22px;
    color: #0d0d0d;
  }
  .btn-minus {
    position: absolute;
    left: 0.5rem;
    z-index: 99;
    align-items: flex-end;
  }
`

const INITIAL_VALUES = {
  name: '',
  email: '',
  phoneNumber: '',
  taxNumber: '',
  vehicle: {
    passengerCapacity: 0,
    luggageCapacity: 0,
    handLuggageCapacity: 0,
    backpackCapacity: 0,
    model: '',
    licensePlate: '',
    endOfInsurance: '',
    color: '',
    manufacturer: 'Abarth',
    isOwner: true
  },
  files: [],
  profilePicture: {
    url: ''
  },
  workingDays: [],
  workingShifts: []
}

export const FormDriverRecruitment = () => {
  const [data, setData] = useState(INITIAL_VALUES)
  const [success, setSuccess] = useState(false)
  const [termsAndConditions, setTermsAndConditions] = useState(false)

  const theme = useMantineTheme()
  const openRef = useRef(null)

  const { t } = useTranslation()

  const handleFileDelete = (fileToDelete, identifier) => {
    setData((prevData) => ({
      ...prevData,
      files: prevData?.files?.filter((file) => file !== fileToDelete) || []
    }))
  }

  const handleFileDeleteCustom = (identifier) => {
    const fileToDelete = data.files?.find(
      (file) => file.identifier === identifier
    )

    setData((prevData) => ({
      ...prevData,
      files: prevData?.files?.filter((file) => file !== fileToDelete) || []
    }))
  }

  const readFileAsBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.readAsDataURL(file)
    })
  }

  const handleFileUpload = async (newFiles, setData, prevData, identifier) => {
    try {
      const filesData = await Promise.all(
        newFiles.map(async (newFile) => {
          const base64Data = await readFileAsBase64(newFile)
          const base64Parts = base64Data.split(',')

          if (base64Parts.length === 2) {
            return {
              title: identifier,
              fileName: newFile.name,
              fileExtension: newFile.type.split('/')[1],
              url: base64Data,
              base64: base64Parts[1],
              identifier
            }
          }
        })
      )

      const existingFiles = prevData.files.filter(
        (file) => file.identifier !== identifier
      )
      const newFilesData = filesData.filter(Boolean)
      setData({
        ...prevData,
        files: [...existingFiles, ...newFilesData]
      })
    } catch (error) {
      console.error('Erro ao ler os arquivos como base64', error)
    }
  }
  const onSave = () => {
    if (!termsAndConditions) {
      toast.error(t('toast.termsAndConditionsRule'))
      return
    }

    data.files.map((item) => {
      if (
        !item.identifier === 'carta-conducao' ||
        !item.identifier === 'morada' ||
        !item.identifier === 'selfie' ||
        !item.identifier === 'cartao-cidadao' ||
        !item.identifier === 'licenca' ||
        !item.identifier === 'seguro-carro' ||
        !item.identifier === 'comprovativo-iban' ||
        !item.identifier === 'foto-interior-veiculo' ||
        !item.identifier === 'foto-exterior-do-veiculo' ||
        !item.identifier === 'presentationVideo'
      ) {
        toast.error(t('toast.missingFiles'))
        return
      }
    })

    if (
      !data.name ||
      !data.email ||
      !data.phoneNumber ||
      !data.taxNumber ||
      !data.profilePicture.url ||
      !data.vehicle.manufacturer ||
      !data.vehicle.color ||
      !data.vehicle.endOfInsurance ||
      !data.vehicle.model ||
      data.workingDays.length === 0 ||
      data.workingShifts.length === 0
    ) {
      toast.error(t('toast.fulfillAllFields'))
      return
    }

    const filesPromises = []
    if (
      data?.profilePicture &&
      data?.profilePicture?.base64 &&
      !data?.profilePicture?.id
    ) {
      const profilePictureData = {
        title: data?.profilePicture?.title,
        base64: data?.profilePicture?.base64,
        fileName: data?.profilePicture?.fileName,
        fileExtension: data?.profilePicture?.fileExtension
      }
      const createProfilePicturePromise = fetchFile
        .create(profilePictureData)
        .then((res) => {
          data.profilePicture.id = res?.data?.id
        })
        .catch((err) => console.log(err))

      filesPromises.push(createProfilePicturePromise)
    }

    if (data.files && data.files.length) {
      data.files.forEach((file, index) => {
        if (file.base64 && !file.id) {
          const fileData = {
            title: file.title,
            base64: file.base64,
            fileName: file.fileName,
            fileExtension: file.fileExtension
          }
          const createFilePromise = fetchFile
            .create(fileData)
            .then((res) => {
              data.files[index].id = res.data.id
              console.log(file.identifier, 'identifier', res.data)

              // Verificar o identifier do arquivo
              if (file.identifier === 'presentationVideo') {
                setData({
                  ...data,
                  presentationVideo: res.data
                })
              } else if (file.identifier === 'foto-exterior-veiculo') {
                setData({
                  ...data,
                  vehicle: {
                    ...data.vehicle,
                    externalPhoto: res.data
                  }
                })
              } else if (file.identifier === 'foto-interior-veiculo') {
                setData({
                  ...data,
                  vehicle: {
                    ...data.vehicle,
                    internalPhoto: res.data
                  }
                })
              }
              return res.data
            })
            .catch((err) => console.log(err))
          console.log(createFilePromise, 'createFilePromise')
          filesPromises.push(createFilePromise)
        }
      })
    }

    if (
      data?.vehicle?.externalPhoto &&
      data?.vehicle?.externalPhoto?.base64 &&
      !data?.vehicle?.externalPhoto?.id
    ) {
      const profilePictureData = {
        title: data.vehicle?.externalPhoto.title,
        base64: data.vehicle?.externalPhoto.base64,
        fileName: data.vehicle?.externalPhoto.fileName,
        fileExtension: data.vehicle?.externalPhoto.fileExtension
      }
      const createProfilePicturePromise = fetchFile
        .create(profilePictureData)
        .then((res) => {
          data.vehicle.externalPhoto.id = res.data.id
        })
        .catch((err) => console.log(err))

      filesPromises.push(createProfilePicturePromise)
    }
    if (
      data?.vehicle?.internalPhoto &&
      data?.vehicle?.internalPhoto?.base64 &&
      !data?.vehicle?.internalPhoto?.id
    ) {
      const profilePictureData = {
        title: data.vehicle?.internalPhoto.title,
        base64: data.vehicle?.internalPhoto.base64,
        fileName: data.vehicle?.internalPhoto.fileName,
        fileExtension: data.vehicle?.internalPhoto.fileExtension
      }
      const createProfilePicturePromise = fetchFile
        .create(profilePictureData)
        .then((res) => {
          data.vehicle.internalPhoto.id = res.data.id
        })
        .catch((err) => console.log(err))

      filesPromises.push(createProfilePicturePromise)
    }
    const allFilesPromise = Promise.all(filesPromises)

    allFilesPromise
      .then((res) => {
        console.log(res, 'res!!')
        let files = data.files
          .filter((obj) => {
            return (
              obj.identifier !== 'presentationVideo' &&
              obj.identifier !== 'foto-interior-veiculo' &&
              obj.identifier !== 'foto-exterior-do-veiculo'
            )
          })
          .map((obj) => {
            let newObj = { ...obj }
            delete newObj.base64
            delete newObj.url
            return newObj
          })

        const payload = {
          active: false,
          name: data.name,
          email: data.email,
          password: '123456',
          dateOfBirth: '2024-12-12T00:00:00.000Z',
          phoneNumber: data.phoneNumber,
          role: 'USER',
          taxNumber: data.taxNumber,
          presentationVideo: data.presentationVideo
            ? data.presentationVideo
            : res.filter((item) => item.title === 'presentationVideo')[0],
          vehicle: {
            ...data.vehicle,
            externalPhoto: data.vehicle.externalPhoto
              ? data.vehicle.externalPhoto
              : res.filter((item) => item.title === 'foto-exterior-veiculo')[0],
            internalPhoto: data.vehicle.internalPhoto
              ? data.vehicle.internalPhoto
              : res.filter((item) => item.title === 'foto-interior-veiculo')[0],
            providesInternet: false,
            surfboardCapacity: 0,
            animalsCapacity: 0,
            childseatCapacity: 0,
            babyseatCapacity: 0,
            boosterseatCapacity: 0,
            providesAirConditioning: false,
            manufacturingYear: '0000-00-00T00:00:00.000Z',
            isOwner: Boolean(data.vehicle.isOwner),
            endOfInsurance: data.vehicle.endOfInsurance + 'T00:00:00Z',
            category: {
              id: '8a6f05b1-217c-4880-86d6-7fb86a2fd4e8'
            }
          },
          profilePicture: data.profilePicture,
          files: files,
          workingDays: data.workingDays.includes('ALL')
            ? ['ALL']
            : data.workingDays,
          workingShifts: data.workingDays.includes('ALL')
            ? ['ALL']
            : data.workingShifts
        }
        delete payload?.profilePicture?.url
        delete payload?.profilePicture?.base64
        delete payload?.vehicle?.externalPhoto?.url
        delete payload?.vehicle?.externalPhoto?.base64
        delete payload?.vehicle?.internalPhoto?.url
        delete payload?.vehicle?.internalPhoto?.base64
        if (data) {
          fetchAccount
            .createAccount(payload)
            .then((res) => {
              setSuccess(true)

              setData(INITIAL_VALUES)
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          console.log('error')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const photoData = e.target.result

        setData((prevData) => ({
          ...prevData,
          profilePicture: {
            // title: 'string',
            title: file.name,
            fileName: file.name,
            fileExtension: file.type.split('/')[1],
            // fileKey: 'string',
            url: photoData,
            base64: photoData.split(',')[1]
          }
        }))
      }
      console.log(file, 'FILE')
      reader.readAsDataURL(file)
    }
  }

  const fileInputRef = useRef(null)

  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const avatarSize = 150

  const imgStyle = {
    width: avatarSize,
    height: avatarSize,
    borderRadius: '100%',
    cursor: 'pointer'
  }

  const handleCheckboxChange = (event, field) => {
    if (event.target.checked) {
      setData((oldState) => ({
        ...oldState,
        [field]: [...oldState[field], event.target.value]
      }))
    } else {
      setData((oldState) => ({
        ...oldState,
        [field]: oldState[field].filter((item) => item !== event.target.value)
      }))
    }
  }

  console.log(data, 'DATA')

  return (
    <SitePrincipalCadastroDeMRoot>
      <Navbar />
      <SectionheroParent>
        <Sectionhero>
          <H3Parent>
            <H>{t('formCompany.registrationForm')}</H>
            <H1>
              <H5Txt>
                <OsPrximosPassos
                  dangerouslySetInnerHTML={{
                    __html: t('formCompany.fillWithYourDataAndDocuments')
                  }}
                />
              </H5Txt>
            </H1>
          </H3Parent>
          <ImgIcon alt="" src="/header-driver-form.png" />
        </Sectionhero>
        <FormsDriverContent>
          <Div1>
            <H4Wrapper>
              <H2>{t('formCompany.aboutYou')}</H2>
            </H4Wrapper>
            <UploadParent>
              {data.profilePicture.url ? (
                <img
                  onClick={handleAvatarClick}
                  src={data?.profilePicture?.url}
                  alt="User"
                  style={imgStyle}
                />
              ) : (
                <Avatar
                  src="/avatar.svg"
                  size={avatarSize}
                  onClick={handleAvatarClick}
                  style={{ cursor: 'pointer' }}
                />
              )}
              <input
                type="file"
                accept="profilePicture/*"
                onChange={handlePhotoUpload}
                style={{ display: 'none' }}
                ref={fileInputRef}
              />
              <PParent>
                <P>{t('formDriver.followInstructions')}</P>
                <List>
                  <ImgiconParent>
                    <Imgicon alt="" src="/lucide_check-circle--blue.svg" />
                    <H3>{t('formDriver.maintainPosture')}</H3>
                  </ImgiconParent>
                  <ImgiconParent>
                    <Imgicon1 alt="" src="/lucide_check-circle--blue.svg" />
                    <H3>{t('formDriver.smile')}</H3>
                  </ImgiconParent>
                  <ImgiconParent>
                    <Imgicon1 alt="" src="/lucide_check-circle--blue.svg" />
                    <H3>{t('formDriver.whiteOrBlackShirt')}</H3>
                  </ImgiconParent>
                  <ImgiconParent>
                    <Imgicon1 alt="" src="/lucide_check-circle--blue.svg" />
                    <H3>{t('formDriver.whiteOrBlackBackground')}</H3>
                  </ImgiconParent>
                </List>
              </PParent>
            </UploadParent>
            <RowInputFormsframe>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucideuser2.svg" />
                  <EMail htmlFor="NameInput">
                    <NomeCompleto>{t('formCompany.fullName')}</NomeCompleto>
                    <Span>*</Span>
                  </EMail>
                </Label>
                <Input
                  id="NameInput"
                  onChange={(e) => {
                    setData((oldstate) => ({
                      ...oldstate,
                      name: e.target.value
                    }))
                  }}
                  value={data.name}
                  placeholder={t('formCompany.enterFullName')}
                  type="text"
                />
              </InputForms>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucidemail.svg" />
                  <EMail htmlFor="MailInput">
                    <NomeCompleto>E-mail</NomeCompleto>
                    <Span1>*</Span1>
                  </EMail>
                </Label>
                <Input
                  id="MailInput"
                  onChange={(e) => {
                    setData((oldstate) => ({
                      ...oldstate,
                      email: e.target.value
                    }))
                  }}
                  value={data.email}
                  placeholder={t('formCompany.enterBestEmail')}
                  type="email"
                />
              </InputForms>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucidephone.svg" />
                  <EMail htmlFor="InputPhone2">
                    <NomeCompleto>
                      {t('formCompany.phoneWhatsApp')}
                    </NomeCompleto>
                    <Span1>*</Span1>
                  </EMail>
                </Label>
                <PhoneInput
                  country={'pt'}
                  required
                  onChange={(e) => {
                    setData((oldstate) => ({
                      ...oldstate,
                      phoneNumber: e
                    }))
                  }}
                  value={data.phoneNumber}
                  inputStyle={{
                    width: '100%',
                    height: '48px',
                    borderRadius: '8.45738px',
                    fontSize: '16px'
                  }}
                />
              </InputForms>
            </RowInputFormsframe>
            <RowInputFormsframe>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucideusersquare.svg" />
                  <EMail htmlFor="InputNIf">
                    <NomeCompleto>NIF</NomeCompleto>
                    <Span1>*</Span1>
                  </EMail>
                </Label>
                <Input
                  onChange={(e) => {
                    setData((oldstate) => ({
                      ...oldstate,
                      taxNumber: e.target.value
                    }))
                  }}
                  value={data.taxNumber}
                  id="InputNIf"
                  placeholder="0000000"
                />
              </InputForms>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucideusersquare.svg" />
                  <EMail htmlFor="LicenseInput">
                    <NomeCompleto>
                      {t('formDriver.doYouHaveLicense')}
                    </NomeCompleto>
                    <Span1>*</Span1>
                  </EMail>
                </Label>
                <Select
                  onChange={(e) =>
                    setData({
                      ...data,
                      hasLicense: e
                    })
                  }
                  value={data.hasLicense}
                  required={true}
                  id="LicenseInput"
                >
                  <Sim value={true}>Sim</Sim>
                  <Sim value={false}>Não</Sim>
                </Select>
              </InputForms>
            </RowInputFormsframe>
            <RowInputFormsframe1>
              <Container>
                <DivWrapper>
                  <Div>
                    <ImgParent>
                      <ImgIcon1 alt="" src="/img6.svg" />
                      <H5Parent>
                        <H4>{t('formDriver.weNeedAVideo')}</H4>
                        <H5>{t('formDriver.sendPresentation')}</H5>
                      </H5Parent>
                    </ImgParent>
                  </Div>
                </DivWrapper>
                <CustomDropzone
                  // openRef={openRef}
                  onDrop={(newFiles) => {
                    handleFileUpload(
                      newFiles,
                      setData,
                      data,
                      'presentationVideo'
                    )
                  }}
                  identifier="presentationVideo"
                  files={data.files}
                  theme={theme}
                  classes={classes}
                  handleDelete={handleFileDelete}
                  name="Carta de condução (Frente e Versos)"
                  maxFiles={2}
                  maxSize={15 * 1024 ** 2}
                  object
                  
                  // disabled={disabled}
                  // data={data}
                  // setData={setData}
                />
              </Container>
            </RowInputFormsframe1>
          </Div1>
          <Div1>
            <H4Wrapper>
              <H6>{t('formCompany.yourVehicle')}</H6>
            </H4Wrapper>
            <RowInputFormsframe>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucidecar.svg" />
                  <EMail htmlFor="MarkCarInput">
                    <NomeCompleto>{t('formDriver.brand')}</NomeCompleto>
                    <Span1>*</Span1>
                  </EMail>
                </Label>
                <Select
                  onChange={(e) => {
                    setData({
                      ...data,
                      vehicle: {
                        ...data.vehicle,
                        manufacturer: e.target.value
                      }
                    })
                  }}
                  value={data.vehicle.manufacturer}
                  required={true}
                  autoFocus={true}
                  id="MarkCarInput"
                >
                  <Sim value="Abarth">Abarth</Sim>
                  <Sim value="Acura">Acura</Sim>
                  <Sim value="Aiways">Aiways</Sim>
                  <Sim value="Aixam">Aixam</Sim>
                  <Sim value="Alfa Romeo">Alfa Romeo</Sim>
                  <Sim value="Alpina">Alpina</Sim>
                  <Sim value="Alpine">Alpine</Sim>
                  <Sim value="Aston Martin">Aston Martin</Sim>
                  <Sim value="Audi">Audi</Sim>
                  <Sim value="Austin">Austin</Sim>
                  <Sim value="Austin Healey">Austin Healey</Sim>
                  <Sim value="Austin Morris">Austin Morris</Sim>
                  <Sim value="Autobiachi">Autobiachi</Sim>
                  <Sim value="Bedford">Bedford</Sim>
                  <Sim value="Bellier">Bellier</Sim>
                  <Sim value="Bentley">Bentley</Sim>
                  <Sim value="BMW">BMW</Sim>
                  <Sim value="Bugatti">Bugatti</Sim>
                  <Sim value="Buick">Buick</Sim>
                  <Sim value="BYD">BYD</Sim>
                  <Sim value="Cadillac">Cadillac</Sim>
                  <Sim value="Casalini">Casalini</Sim>
                  <Sim value="Caterham">Caterham</Sim>
                  <Sim value="Chatenet">Chatenet</Sim>
                  <Sim value="Chevrolet">Chevrolet</Sim>
                  <Sim value="Chrysler">Chrysler</Sim>
                  <Sim value="Citroën">Citroën</Sim>
                  <Sim value="Cupra">Cupra</Sim>
                  <Sim value="Dacia">Dacia</Sim>
                  <Sim value="Daewoo">Daewoo</Sim>
                  <Sim value="Daihatsu">Daihatsu</Sim>
                  <Sim value="Datsun">Datsun</Sim>
                  <Sim value="Devinci">Devinci</Sim>
                  <Sim value="Dodge">Dodge</Sim>
                  <Sim value="DS">DS</Sim>
                  <Sim value="e.GO">e.GO</Sim>
                  <Sim value="Ferrari">Ferrari</Sim>
                  <Sim value="Fiat">Fiat</Sim>
                  <Sim value="Fisker">Fisker</Sim>
                  <Sim value="Ford">Ford</Sim>
                  <Sim value="GMC">GMC</Sim>
                  <Sim value="Hillman">Hillman</Sim>
                  <Sim value="Honda">Honda</Sim>
                  <Sim value="Hummer">Hummer</Sim>
                  <Sim value="Hyundai">Hyundai</Sim>
                  <Sim value="Isuzu">Isuzu</Sim>
                  <Sim value="Iveco">Iveco</Sim>
                  <Sim value="Jaguar">Jaguar</Sim>
                  <Sim value="Jeep">Jeep</Sim>
                  <Sim value="JaguarSport">JaguarSport</Sim>
                  <Sim value="Jiayuan">Jiayuan</Sim>
                  <Sim value="JDM">JDM</Sim>
                  <Sim value="Kaiser">Kaiser</Sim>
                  <Sim value="Kia">Kia</Sim>
                  <Sim value="Lada">Lada</Sim>
                  <Sim value="Lamborghini">Lamborghini</Sim>
                  <Sim value="Lancia">Lancia</Sim>
                  <Sim value="Land Rover">Land Rover</Sim>
                  <Sim value="Ligier">Ligier</Sim>
                  <Sim value="Lexus">Lexus</Sim>
                  <Sim value="Lotus">Lotus</Sim>
                  <Sim value="Man">Man</Sim>
                  <Sim value="Lincoln">Lincoln</Sim>
                  <Sim value="Maserati">Maserati</Sim>
                  <Sim value="Maybach">Maybach</Sim>
                  <Sim value="Mazda">Mazda</Sim>
                  <Sim value="Mclaren">Mclaren</Sim>
                  <Sim value="Mercedes-Benz">Mercedes-Benz</Sim>
                  <Sim value="MG">MG</Sim>
                  <Sim value="Microcar">Microcar</Sim>
                  <Sim value="MINI">MINI</Sim>
                  <Sim value="Mitsubishi">Mitsubishi</Sim>
                  <Sim value="Morgan">Morgan</Sim>
                  <Sim value="Morris">Morris</Sim>
                  <Sim value="Nissan">Nissan</Sim>
                  <Sim value="NSU">NSU</Sim>
                  <Sim value="Opel">Opel</Sim>
                  <Sim value="Outra não listada">Outra não listada</Sim>
                  <Sim value="Peugeot">Peugeot</Sim>
                  <Sim value="Polestar">Polestar</Sim>
                  <Sim value="Porsche">Porsche</Sim>
                  <Sim value="Pontiac">Pontiac</Sim>
                  <Sim value="Renault">Renault</Sim>
                  <Sim value="Riley">Riley</Sim>
                  <Sim value="Rolls Royce">Rolls Royce</Sim>
                  <Sim value="Rover">Rover</Sim>
                  <Sim value="Saab">Saab</Sim>
                  <Sim value="Seat">Seat</Sim>
                  <Sim value="Shelby">Shelby</Sim>
                  <Sim value="Skoda">Skoda</Sim>
                  <Sim value="Smart">Smart</Sim>
                  <Sim value="SsangYong">SsangYong</Sim>
                  <Sim value="Subaru">Subaru</Sim>
                  <Sim value="Suzuki">Suzuki</Sim>
                  <Sim value="Tata">Tata</Sim>
                  <Sim value="Tesla">Tesla</Sim>
                  <Sim value="Toyota">Toyota</Sim>
                  <Sim value="Triumph">Triumph</Sim>
                  <Sim value="UMM">UMM</Sim>
                  <Sim value="VW">VW</Sim>
                  <Sim value="Volvo">Volvo</Sim>
                </Select>
              </InputForms>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucidecar.svg" />
                  <EMail1 htmlFor="ModelCardInput">
                    <Modelo>{t('formDriver.model')}</Modelo>
                    <Span2>*</Span2>
                  </EMail1>
                </Label>
                <Input
                  id="ModelCardInput"
                  placeholder={t('formDriver.model')}
                  type="text"
                  onChange={(e) => {
                    setData((oldstate) => ({
                      ...oldstate,
                      vehicle: {
                        ...data.vehicle,
                        model: e.target.value
                      }
                    }))
                  }}
                  value={data.vehicle.model}
                />
              </InputForms>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucidecar.svg" />
                  <EMail1 htmlFor="PlacaInput">
                    <Modelo>{t('formDriver.licensePlate')}</Modelo>
                    <Span2>*</Span2>
                  </EMail1>
                </Label>
                <Input
                  id="PlacaInput"
                  onChange={(e) => {
                    setData((oldstate) => ({
                      ...oldstate,
                      vehicle: {
                        ...data.vehicle,
                        licensePlate: e.target.value
                      }
                    }))
                  }}
                  value={data.vehicle.licensePlate}
                  placeholder={t('formDriver.licensePlate')}
                  type="text"
                />
              </InputForms>
            </RowInputFormsframe>
            <RowInputFormsframe>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucidecar.svg" />
                  <EMail1 htmlFor="insurencyInput">
                    <Modelo>{t('formDriver.insuranceExpiration')} </Modelo>
                    <Span2>*</Span2>
                  </EMail1>
                </Label>
                <Input
                  onChange={(e) => {
                    setData({
                      ...data,
                      vehicle: {
                        ...data.vehicle,
                        endOfInsurance: e.target.value
                      }
                    })
                  }}
                  value={data.vehicle.endOfInsurance}
                  id="insurencyInput"
                  placeholder="00/00/0000"
                  type="date"
                />
              </InputForms>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucidecar.svg" />
                  <EMail1 htmlFor="OwnCarInput">
                    <Modelo>{t('formDriver.ownVehicle')}</Modelo>
                    <Span2>*</Span2>
                  </EMail1>
                </Label>
                <Select
                  defaultValue={true}
                  onChange={(e) => {
                    console.log(e.target.value)
                    setData({
                      ...data,
                      vehicle: {
                        ...data.vehicle,
                        isOwner: e.target.value
                      }
                    })
                  }}
                  value={data.vehicle.isOwner}
                  required={true}
                  id="OwnCarInput"
                >
                  <Sim value={true}>Sim</Sim>
                  <Sim value={false}>Não</Sim>
                </Select>
              </InputForms>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucidecar.svg" />
                  <EMail1 htmlFor="ColorInput">
                    <Modelo>{t('formDriver.color')}</Modelo>
                    <Span2>*</Span2>
                  </EMail1>
                </Label>
                <Input
                  id="ColorInput"
                  placeholder={t('formDriver.color')}
                  type="text"
                  onChange={(e) => {
                    setData((oldstate) => ({
                      ...oldstate,
                      vehicle: {
                        ...data.vehicle,
                        color: e.target.value
                      }
                    }))
                  }}
                  value={data.color}
                />
              </InputForms>
            </RowInputFormsframe>
            <ContainerParent>
              <Container1>
                <CustomDropzone
                  openRef={openRef}
                  onDrop={(newFiles) => {
                    handleFileUpload(
                      newFiles,
                      setData,
                      data,
                      'foto-interior-veiculo'
                    )
                  }}
                  identifier="foto-interior-veiculo"
                  files={data.files}
                  theme={theme}
                  classes={classes}
                  // handleDelete={handleFileDelete}
                  name="Carta de condução (Frente e Versos)"
                  maxFiles={1}
                  customDelete

                  // disabled={disabled}
                  // data={data}
                  // setData={setData}
                />
                <FrameParent>
                  <H5Group>
                    <H7>
                      <H5Txt>
                        <FotoDoInterior>
                          {t('formDriver.interiorPhoto')}
                        </FotoDoInterior>
                        <Span2>*</Span2>
                      </H5Txt>
                    </H7>
                    {data.files.find(
                      (item) => item.identifier === 'foto-interior-veiculo'
                    ) && (
                      <Imgicon2
                        alt=""
                        src="/imgicon.svg"
                        onClick={() => {
                          handleFileDeleteCustom('foto-interior-veiculo')
                        }}
                      />
                    )}
                  </H5Group>
                  <H8>{t('formCompany.acceptedFileTypes')}</H8>
                </FrameParent>
              </Container1>
              <Container1>
                <CustomDropzone
                  openRef={openRef}
                  onDrop={(newFiles) => {
                    handleFileUpload(
                      newFiles,
                      setData,
                      data,
                      'foto-exterior-do-veiculo'
                    )
                  }}
                  identifier="foto-exterior-do-veiculo"
                  files={data.files}
                  theme={theme}
                  classes={classes}
                  customDelete
                  // handleDelete={handleFileDelete}
                  name="Carta de condução (Frente e Versos)"
                  maxFiles={1}
                  // disabled={disabled}
                  // data={data}
                  // setData={setData}
                />
                <FrameParent>
                  <H5Group>
                    <H9>
                      <H5Txt>
                        <FotoDoInterior>
                          {t('formDriver.exteriorPhoto')}
                        </FotoDoInterior>
                        <Span2>*</Span2>
                      </H5Txt>
                    </H9>
                    {data.files.find(
                      (item) => item.identifier === 'foto-exterior-do-veiculo'
                    ) && (
                      <Imgicon2
                        alt=""
                        src="/imgicon.svg"
                        onClick={() => {
                          handleFileDeleteCustom('foto-exterior-do-veiculo')
                        }}
                      />
                    )}
                  </H5Group>
                  <H8>{t('formCompany.acceptedFileTypes')}</H8>
                </FrameParent>
              </Container1>
            </ContainerParent>
            <FrameContainer>
              <LabelParent>
                <Label1>
                  <Lucideusers2Icon alt="" src="/lucideusers2.svg" />
                  <Passageiros>{t('formDriver.passengers')}</Passageiros>
                </Label1>
                <NumberContainer>
                  <AddPassenger
                    responsive
                    setValue={(value) => {
                      setData((prevData) => ({
                        ...prevData,
                        vehicle: {
                          ...prevData.vehicle,
                          passengerCapacity: value
                        }
                      }))
                    }}
                    value={data?.vehicle?.passengerCapacity}
                    name="backPack"
                    width="100%"
                  />
                </NumberContainer>
              </LabelParent>
              <LabelGroup>
                <Label1>
                  <Lucideusers2Icon alt="" src="/lucidebriefcase.svg" />
                  <BagagemDespachada htmlFor="Luggage1">
                    <Bagagem>{t('formDriver.checkedLuggage')}</Bagagem>
                  </BagagemDespachada>
                </Label1>
                <NumberContainer>
                  <AddPassenger
                    responsive
                    setValue={(value) => {
                      setData((prevData) => ({
                        ...prevData,
                        vehicle: {
                          ...prevData.vehicle,
                          luggageCapacity: value
                        }
                      }))
                    }}
                    value={data?.vehicle?.luggageCapacity}
                    name="luggageCapacity"
                    width="100%"
                  />
                </NumberContainer>
              </LabelGroup>
              <LabelGroup>
                <Label2>
                  <Lucideusers2Icon alt="" src="/lucideluggage.svg" />
                  <BagagemDespachada htmlFor="Luggage2">
                    {t('formDriver.carryOnLuggage')}
                  </BagagemDespachada>
                </Label2>
                <NumberContainer>
                  <AddPassenger
                    responsive
                    setValue={(value) => {
                      setData((prevData) => ({
                        ...prevData,
                        vehicle: {
                          ...prevData.vehicle,
                          handLuggageCapacity: value
                        }
                      }))
                    }}
                    value={data?.vehicle?.handLuggageCapacity}
                    name="backPack"
                    width="100%"
                  />
                </NumberContainer>
              </LabelGroup>
              <LabelGroup>
                <Label2>
                  <Lucideusers2Icon alt="" src="/lucidebackpack.svg" />
                  <BagagemDespachada htmlFor="Luggage3">
                    {t('formDriver.backpack')}
                  </BagagemDespachada>
                </Label2>
                <NumberContainer>
                  <AddPassenger
                    responsive
                    setValue={(value) => {
                      setData((prevData) => ({
                        ...prevData,
                        vehicle: {
                          ...prevData.vehicle,
                          backpackCapacity: value
                        }
                      }))
                    }}
                    value={data?.vehicle?.backpackCapacity}
                    name="backPack"
                    width="100%"
                  />
                </NumberContainer>
              </LabelGroup>
            </FrameContainer>
          </Div1>
          <Div1>
            <H4Wrapper>
              <H6>{t('formDriver.yourWorkSchedule')}</H6>
            </H4Wrapper>
            <Div2>
              <H10>
                <EscolhaOsDias>{t('formDriver.chooseDays')}</EscolhaOsDias>
                <Span1>*</Span1>
              </H10>
              <RowInputFormsframe>
                <ChecboxeComLabel>
                  <Checkbox
                    id="Check1"
                    type="checkbox"
                    value="ALL"
                    onChange={(event) =>
                      handleCheckboxChange(event, 'workingDays')
                    }
                  />
                  <Label3 htmlFor="Check1">{t('formDriver.allDays')}</Label3>
                </ChecboxeComLabel>
                <ChecboxeComLabel>
                  <Checkbox
                    id="Check2"
                    type="checkbox"
                    value="MONDAY"
                    checked={
                      data.workingDays.includes('MONDAY') ||
                      data.workingDays.includes('ALL')
                    }
                    onChange={(event) =>
                      handleCheckboxChange(event, 'workingDays')
                    }
                  />
                  <Label3 htmlFor="Check2">{t('formDriver.monday')}</Label3>
                </ChecboxeComLabel>

                <ChecboxeComLabel>
                  <Checkbox
                    id="Check4"
                    type="checkbox"
                    value="TUESDAY"
                    checked={
                      data.workingDays.includes('TUESDAY') ||
                      data.workingDays.includes('ALL')
                    }
                    onChange={(event) =>
                      handleCheckboxChange(event, 'workingDays')
                    }
                  />
                  <Label3 htmlFor="Check4">{t('formDriver.tuesday')}</Label3>
                </ChecboxeComLabel>
                <ChecboxeComLabel>
                  <Checkbox
                    id="Check5"
                    type="checkbox"
                    value="WEDNESDAY"
                    checked={
                      data.workingDays.includes('WEDNESDAY') ||
                      data.workingDays.includes('ALL')
                    }
                    onChange={(event) =>
                      handleCheckboxChange(event, 'workingDays')
                    }
                  />
                  <Label3 htmlFor="Check5">{t('formDriver.wednesday')}</Label3>
                </ChecboxeComLabel>
                <ChecboxeComLabel>
                  <Checkbox
                    id="Check6"
                    type="checkbox"
                    value="THURSDAY"
                    checked={
                      data.workingDays.includes('THURSDAY') ||
                      data.workingDays.includes('ALL')
                    }
                    onChange={(event) =>
                      handleCheckboxChange(event, 'workingDays')
                    }
                  />
                  <Label3 htmlFor="Check6">{t('formDriver.thursday')}</Label3>
                </ChecboxeComLabel>
                <ChecboxeComLabel>
                  <Checkbox
                    id="Check7"
                    type="checkbox"
                    value="FRIDAY"
                    checked={
                      data.workingDays.includes('FRIDAY') ||
                      data.workingDays.includes('ALL')
                    }
                    onChange={(event) =>
                      handleCheckboxChange(event, 'workingDays')
                    }
                  />
                  <Label3 htmlFor="Check7">{t('formDriver.friday')}</Label3>
                </ChecboxeComLabel>
                <ChecboxeComLabel>
                  <Checkbox
                    id="Check8"
                    type="checkbox"
                    value="SATURDAY"
                    checked={
                      data.workingDays.includes('SATURDAY') ||
                      data.workingDays.includes('ALL')
                    }
                    onChange={(event) =>
                      handleCheckboxChange(event, 'workingDays')
                    }
                  />
                  <Label3 htmlFor="Check8">{t('formDriver.saturday')}</Label3>
                </ChecboxeComLabel>
                <ChecboxeComLabel>
                  <Checkbox
                    id="Check9"
                    type="checkbox"
                    value="SUNDAY"
                    checked={
                      data.workingDays.includes('SUNDAY') ||
                      data.workingDays.includes('ALL')
                    }
                    onChange={(event) =>
                      handleCheckboxChange(event, 'workingDays')
                    }
                  />
                  <Label3 htmlFor="Check9">{t('formDriver.sunday')}</Label3>
                </ChecboxeComLabel>
              </RowInputFormsframe>
            </Div2>
            <Div2>
              <H10>
                <EscolhaOsDias>{t('formDriver.bestDayShift')}</EscolhaOsDias>
                <Span1>*</Span1>
              </H10>
              <RowInputFormsframe>
                <ChecboxeComLabel>
                  <Checkbox
                    id="All"
                    type="checkbox"
                    value="ALL"
                    checked={data.workingShifts.includes('ALL')}
                    onChange={(event) =>
                      handleCheckboxChange(event, 'workingShifts')
                    }
                  />
                  <Label3 htmlFor="All">{t('formDriver.all')}</Label3>
                </ChecboxeComLabel>
                <ChecboxeComLabel>
                  <Checkbox
                    id="MORNING"
                    type="checkbox"
                    value="MORNING"
                    checked={
                      data.workingShifts.includes('MORNING') ||
                      data.workingShifts.includes('ALL')
                    }
                    onChange={(event) =>
                      handleCheckboxChange(event, 'workingShifts')
                    }
                  />
                  <Label3 htmlFor="MORNING">{t('formDriver.morning')}</Label3>
                </ChecboxeComLabel>
                <ChecboxeComLabel>
                  <Checkbox
                    id="AFTERNOON"
                    type="checkbox"
                    value="AFTERNOON"
                    checked={
                      data.workingShifts.includes('AFTERNOON') ||
                      data.workingShifts.includes('ALL')
                    }
                    onChange={(event) =>
                      handleCheckboxChange(event, 'workingShifts')
                    }
                  />
                  <Label3 htmlFor="AFTERNOON">
                    {t('formDriver.afternoon')}
                  </Label3>
                </ChecboxeComLabel>
                <ChecboxeComLabel>
                  <Checkbox
                    id="NIGHT"
                    type="checkbox"
                    value="NIGHT"
                    checked={
                      data.workingShifts.includes('NIGHT') ||
                      data.workingShifts.includes('ALL')
                    }
                    onChange={(event) =>
                      handleCheckboxChange(event, 'workingShifts')
                    }
                  />
                  <Label3 htmlFor="NIGHT">{t('formDriver.evening')}</Label3>
                  <Div3>
                    <Imgicon alt="" src="/lucidetrendingup.svg" />
                    <TurnoComMelhores>
                      {t('formDriver.bestTripsShift')}
                    </TurnoComMelhores>
                  </Div3>
                </ChecboxeComLabel>
              </RowInputFormsframe>
            </Div2>
          </Div1>
          <Div1>
            <H4Wrapper>
              <H11>{t('formCompany.documents')}</H11>
            </H4Wrapper>
            <ContainerGroup>
              <Container2>
                <CustomDropzone
                  openRef={openRef}
                  onDrop={(newFiles) => {
                    handleFileUpload(newFiles, setData, data, 'carta-conducao')
                  }}
                  identifier="carta-conducao"
                  files={data.files}
                  theme={theme}
                  classes={classesAlternative}
                  customDelete
                  // handleDelete={handleFileDelete}
                  name="Carta de condução (Frente e Versos)"
                  maxFiles={2}
                  // disabled={disabled}
                  // data={data}
                  // setData={setData}
                />
                <FrameParent>
                  <H5Group>
                    <H12>
                      <FotoDoInterior>
                        {t('formDriver.drivingLicense')}
                      </FotoDoInterior>
                      <Span2>*</Span2>
                    </H12>
                    {data.files.find(
                      (item) => item.identifier === 'carta-conducao'
                    ) && (
                      <Imgicon2
                        alt=""
                        src="/imgicon.svg"
                        onClick={() => {
                          handleFileDeleteCustom('carta-conducao')
                        }}
                      />
                    )}
                  </H5Group>
                  <LucidealertCircleParent>
                    <LucidealertCircleIcon
                      alt=""
                      src="/lucidealertcircle.svg"
                    />
                    <FrenteEVerso>{t('formDriver.frontAndBack')}</FrenteEVerso>
                  </LucidealertCircleParent>
                  <H8>{t('formCompany.acceptedDocumentTypes')}</H8>
                  <H8>{t('formCompany.maxSize')}</H8>
                </FrameParent>
              </Container2>
              <Container2>
                <CustomDropzone
                  openRef={openRef}
                  onDrop={(newFiles) => {
                    handleFileUpload(newFiles, setData, data, 'morada')
                  }}
                  identifier="morada"
                  files={data.files}
                  theme={theme}
                  classes={classesAlternative}
                  customDelete
                  // handleDelete={handleFileDelete}
                  name="Carta de condução (Frente e Versos)"
                  maxFiles={1}
                  // disabled={disabled}
                  // data={data}
                  // setData={setData}
                />
                <FrameParent>
                  <H5Group>
                    <H12>
                      <FotoDoInterior>
                        {t('formDriver.proofOfAddress')}
                      </FotoDoInterior>
                      <Span2>*</Span2>
                    </H12>
                    {data.files.find(
                      (item) => item.identifier === 'morada'
                    ) && (
                      <Imgicon2
                        alt=""
                        src="/imgicon.svg"
                        onClick={() => {
                          handleFileDeleteCustom('morada')
                        }}
                      />
                    )}
                  </H5Group>
                  <H8>{t('formCompany.acceptedDocumentTypes')}</H8>
                  <H8>{t('formCompany.maxSize')}</H8>
                </FrameParent>
              </Container2>
            </ContainerGroup>
            <ContainerGroup>
              <Container3>
                <CustomDropzone
                  openRef={openRef}
                  onDrop={(newFiles) => {
                    handleFileUpload(newFiles, setData, data, 'selfie')
                  }}
                  identifier="selfie"
                  files={data.files}
                  theme={theme}
                  classes={classesAlternative}
                  customDelete
                  // handleDelete={handleFileDelete}
                  name="Carta de condução (Frente e Versos)"
                  maxFiles={1}
                  // disabled={disabled}
                  // data={data}
                  // setData={setData}
                />
                <FrameParent>
                  <H5Group>
                    <H5DeConduo>
                      <Bagagem>
                        <FotoDoInterior>
                          {t('formDriver.selfieWithDrivingLicense')}
                        </FotoDoInterior>
                      </Bagagem>
                    </H5DeConduo>
                    {data.files.find(
                      (item) => item.identifier === 'selfie'
                    ) && (
                      <Imgicon2
                        alt=""
                        src="/imgicon.svg"
                        onClick={() => {
                          handleFileDeleteCustom('selfie')
                        }}
                      />
                    )}
                  </H5Group>
                  <H8>{t('formCompany.acceptedDocumentTypes')}</H8>
                  <H8>{t('formCompany.maxSize')}</H8>
                </FrameParent>
              </Container3>
              <Container4>
                <CustomDropzone
                  openRef={openRef}
                  onDrop={(newFiles) => {
                    handleFileUpload(newFiles, setData, data, 'cartao-cidadao')
                  }}
                  identifier="cartao-cidadao"
                  files={data.files}
                  theme={theme}
                  classes={classesAlternative}
                  customDelete
                  // handleDelete={handleFileDelete}
                  name="Carta de condução (Frente e Versos)"
                  maxFiles={1}
                  // disabled={disabled}
                  // data={data}
                  // setData={setData}
                />
                <FrameParent>
                  <H5Group>
                    <H12>
                      <FotoDoInterior>{t('formDriver.IDCard')}</FotoDoInterior>
                      <Span2>*</Span2>
                    </H12>
                    {data.files.find(
                      (item) => item.identifier === 'cartao-cidadao'
                    ) && (
                      <Imgicon2
                        alt=""
                        src="/imgicon.svg"
                        onClick={() => {
                          handleFileDeleteCustom('cartao-cidadao')
                        }}
                      />
                    )}
                  </H5Group>
                  <LucidealertCircleParent>
                    <LucidealertCircleIcon
                      alt=""
                      src="/lucidealertcircle.svg"
                    />
                    <FrenteEVerso>{t('formDriver.frontAndBack')}</FrenteEVerso>
                  </LucidealertCircleParent>
                  <H8>{t('formCompany.acceptedDocumentTypes')}</H8>
                  <H8>{t('formCompany.maxSize')}</H8>
                </FrameParent>
              </Container4>
            </ContainerGroup>
            <ContainerGroup>
              <Container4>
                <CustomDropzone
                  openRef={openRef}
                  onDrop={(newFiles) => {
                    handleFileUpload(newFiles, setData, data, 'licenca')
                  }}
                  identifier="licenca"
                  files={data.files}
                  theme={theme}
                  classes={classesAlternative}
                  customDelete
                  // handleDelete={handleFileDelete}
                  name="Carta de condução (Frente e Versos)"
                  maxFiles={1}
                  // disabled={disabled}
                  // data={data}
                  // setData={setData}
                />
                <FrameParent>
                  <H5Group>
                    <H13>
                      <FotoDoInterior>
                        <TvdeTaxiRnaat>
                          {t('formDriver.TVDELicense')}
                        </TvdeTaxiRnaat>
                      </FotoDoInterior>
                      <Span2>*</Span2>
                    </H13>
                    {data.files.find(
                      (item) => item.identifier === 'licenca'
                    ) && (
                      <Imgicon2
                        alt=""
                        src="/imgicon.svg"
                        onClick={() => {
                          handleFileDeleteCustom('licenca')
                        }}
                      />
                    )}
                  </H5Group>
                  <H8>{t('formCompany.acceptedDocumentTypes')}</H8>
                  <H8>{t('formCompany.maxSize')}</H8>
                </FrameParent>
              </Container4>
              <Container4>
                <CustomDropzone
                  openRef={openRef}
                  onDrop={(newFiles) => {
                    handleFileUpload(
                      newFiles,
                      setData,
                      data,
                      'comprovativo-iban'
                    )
                  }}
                  identifier="comprovativo-iban"
                  files={data.files}
                  theme={theme}
                  classes={classesAlternative}
                  customDelete
                  // handleDelete={handleFileDelete}
                  name="Carta de condução (Frente e Versos)"
                  maxFiles={1}
                  // disabled={disabled}
                  // data={data}
                  // setData={setData}
                />
                <FrameParent>
                  <H5Group>
                    <H12>
                      <FotoDoInterior>
                        {t('formCompany.ibanProof')}
                      </FotoDoInterior>
                      <Span2>*</Span2>
                    </H12>
                    {data.files.find(
                      (item) => item.identifier === 'comprovativo-iban'
                    ) && (
                      <Imgicon2
                        alt=""
                        src="/imgicon.svg"
                        onClick={() => {
                          handleFileDeleteCustom('comprovativo-iban')
                        }}
                      />
                    )}
                  </H5Group>
                  <H8>{t('formCompany.acceptedDocumentTypes')}</H8>
                  <H8>{t('formCompany.maxSize')}</H8>
                </FrameParent>
              </Container4>
            </ContainerGroup>
            <ContainerWrapper>
              <Container5>
                <CustomDropzone
                  openRef={openRef}
                  onDrop={(newFiles) => {
                    handleFileUpload(newFiles, setData, data, 'seguro-carro')
                  }}
                  identifier="seguro-carro"
                  files={data.files}
                  theme={theme}
                  classes={classesAlternative}
                  customDelete
                  // handleDelete={handleFileDelete}
                  name="Carta de condução (Frente e Versos)"
                  maxFiles={1}
                  // disabled={disabled}
                  // data={data}
                  // setData={setData}
                />
                <FrameParent>
                  <H5Group>
                    <H12>
                      <FotoDoInterior>
                        {t('formDriver.insuranceDocument')}
                      </FotoDoInterior>
                      <Span2>*</Span2>
                    </H12>
                    {data.files.find(
                      (item) => item.identifier === 'seguro-carro'
                    ) && (
                      <Imgicon2
                        alt=""
                        src="/imgicon.svg"
                        onClick={() => {
                          handleFileDeleteCustom('seguro-carro')
                        }}
                      />
                    )}
                  </H5Group>
                  <H8>{t('formCompany.acceptedDocumentTypes')}</H8>
                  <H8>{t('formCompany.maxSize')}</H8>
                </FrameParent>
              </Container5>
            </ContainerWrapper>
          </Div1>
          <Div4>
            <ChecboxeComLabel1>
              <Checkbox1
                onChange={(e) => setTermsAndConditions(e)}
                value={termsAndConditions}
                type="checkbox"
              />
              <Label4>
                <ConcordoComOs>{t('formCompany.agreeTerms')}</ConcordoComOs>
                <Span3>*</Span3>
              </Label4>
            </ChecboxeComLabel1>
          </Div4>
          <ButtonWrapper>
            <Button onClick={() => onSave()}>
              <ConheaAFrota>{t('formCompany.signup')}</ConheaAFrota>
              <Lucideusers2Icon alt="" src="/lucidearrowupright.svg" />
            </Button>
          </ButtonWrapper>
          {success && (
            <Container6>
              <ImgIcon2 alt="" src="/iconcheck.svg" />
              <H5Parent1>
                <H14>Sua inscrição foi enviada com sucesso!</H14>
                <H15>
                  <FiqueAtentoAoSeuEMail>
                    {`Fique atento ao seu `}
                    <EMail2>e-mail</EMail2>
                  </FiqueAtentoAoSeuEMail>
                  <FiqueAtentoAoSeuEMail1>
                    , pois receberá novas instruções para os próximos passos da
                    sua inscrição connosco!
                  </FiqueAtentoAoSeuEMail1>
                </H15>
              </H5Parent1>
            </Container6>
          )}
        </FormsDriverContent>
      </SectionheroParent>
      <Footer />
    </SitePrincipalCadastroDeMRoot>
  )
}
