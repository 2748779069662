import styled from 'styled-components'

export const TopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  flex-wrap: wrap;
`
export const OrderBumpLabel = styled.div`
  font-family: Neometric;
  font-weight: 600;
  font-size: 32px;
  line-height: 38.4px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  flex-wrap: wrap;

  @media (max-width: 570px) {
    gap: 12px;
    margin-bottom: 10px;
  }
`

export const ActionDeleteButton = styled.button`
  display: flex;
  width: 220px;
  height: 48px;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border: 2px solid #27c7ff;
  border-radius: 16px;
  background: #fff;

  color: #27c7ff;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.16px;
  text-transform: uppercase;
`

export const ActionSaveButton = styled.button`
  display: flex;
  width: 220px;
  height: 48px;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border-radius: 16px;
  background: #27c7ff;

  font-family: Roboto;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  color: #fff;
`
