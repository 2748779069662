import styled, { css } from 'styled-components'
export const Container = styled.div`
  ${({ theme }) => css``}
`
export const Options = styled.div`
  display: flex;
  height: 48px;
  align-items: flex-start;
  border-radius: 8px;
  border: 0.5px solid var(--grey-3-grayscale, #bfbfbf);
`

export const Option = styled.div`
  ${({ select, first, last }) => css`
    display: flex;
    width: 110px;
    padding: 12.19px 8px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    cursor: pointer;

    color: ${select
      ? 'var(--black-brand-primary, #222)'
      : 'var(--grey-2-grayscale, #808080)'};
    background-color: ${select ? '#27c7ff' : ''};
    border-radius: ${first ? '8px 0px 0px 8px' : last ? '0px 8px 8px 0px' : ''};
    font-family: Neometric;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-right: ${last ? '' : '0.5px solid #BFBFBF'};
  `}
`
