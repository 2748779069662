import * as Styled from './styles'

export const Card = ({ item }) => {
  return (
    <Styled.Container>
      {item.map((item) => (
        <Styled.CardContainer key={item.title}>
          <Styled.ImageContainer>{item.img}</Styled.ImageContainer>
          <Styled.TextContainer>
            <h1>{item.title}</h1>
            <p>{item.desc}</p>
          </Styled.TextContainer>
        </Styled.CardContainer>
      ))}
    </Styled.Container>
  )
}
