import React, { useEffect, useRef } from 'react'
import { useIsInViewport } from '../../../../../../hooks/useIsInViewport'
import { PAGE_SIZE, useChatStore } from '../../context/ChatContext'
import { useInvertScroll } from './hooks/useInvertScroll'

import { MessageBubble } from './MessageBubble'
import { Loader } from '@mantine/core'

import * as Styled from './styled'

export function ChatMessages() {
  const listRef = useInvertScroll()

  const messages = useChatStore((state) => state.messages)
  const isGettingMessages = useChatStore((state) => state.isGettingMessages)

  const reversedMessages = [...messages].reverse()

  if (!isGettingMessages && reversedMessages.length === 0) {
    return (
      <Styled.Container>
        <Styled.InfoWrapper>
          <Styled.InfoText>
            Seja o primeiro a enviar uma mensagem!
          </Styled.InfoText>
        </Styled.InfoWrapper>
      </Styled.Container>
    )
  }

  return (
    <Styled.Container ref={listRef} style={{ transform: 'rotate(180deg)' }}>
      {reversedMessages.map((message, index) => (
        <MessageBubble
          key={message?.id ?? index}
          nextSenderId={reversedMessages[index + 1]?.sender?.id}
          sender={message?.sender}
          message={{
            content: message?.message,
            sentAt: message?.createdAt
          }}
        />
      ))}

      {isGettingMessages && (
        <Styled.InfoWrapper>
          <Loader color="yellow" size="md" />
        </Styled.InfoWrapper>
      )}

      {reversedMessages.length >= PAGE_SIZE && <TopOfContainer />}
    </Styled.Container>
  )
}

function TopOfContainer() {
  const loadMoreMessages = useChatStore((state) => state.loadMoreMessages)

  const topRef = useRef(null)
  const topReached = useIsInViewport(topRef)
  console.log('topReached', topReached)

  useEffect(() => {
    if (topReached) loadMoreMessages()
  }, [topReached])

  return <div ref={topRef} style={{ height: '0' }} />
}
