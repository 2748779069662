import React from 'react'
import * as Styled from './style'

export function Button({ addNewStop }) {
  return (
    <Styled.ButtonsContainer>
      <Styled.AddStopButton onClick={addNewStop}>
        Adicionar
      </Styled.AddStopButton>
    </Styled.ButtonsContainer>
  )
}
