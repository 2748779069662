import styled from 'styled-components'

export const CarouselContainer = styled.div`
  width: clamp(240px, 100%, 35vw);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1160px) {
    width: 100%;
    max-width: 600px;
  }

  > .controls {
    display: none;
  }
  .mantine-Carousel-indicators {
    border: 1px solid red;
  }
  > .indicators {
    border: 1px solid red;
  }

  .indicator {
    border: 1px solid red;
  }
`

export const SelectedImage = styled.img`
  width: 100%;
`

export const CurrentImageIndicatorContainer = styled.div`
  display: flex;
  gap: clamp(4px, 0.21vw, 0.21vw);
  align-items: center;
  justify-content: center;

  div {
    cursor: pointer;
    border: 1px solid gray;
    height: clamp(8px, 0.42vw, 0.42vw);
    width: clamp(8px, 0.42vw, 0.42vw);
    border-radius: 50%;

    ${({ currentImage }) => `
      :nth-child(${currentImage + 1}) {
        background: #27c7ff;
        border: 1px solid #27c7ff;
      }
    `}
  }
`
