import styled from 'styled-components'

export const ContainerCards = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;

  width: 100%;

  margin-bottom: 5rem;

  @media screen and (max-width: 768px) {
    gap: 5rem;
    padding: 0;
  }
`

export const Card = styled.div`
  width: 100%;
  max-width: 320px;
  height: 200px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;

  padding: 16px;

  border-radius: 24px;
  border: 1px solid #d6d6d6;
  background-color: #fff;
  box-shadow: 0px 16px 30px 0px rgba(0, 0, 0, 0.1);

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`

export const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: -15%;

  width: 56px;
  height: 56px;

  border-radius: 50%;
  background-color: #27c7ff;
`

export const CardTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #222222;

  margin-bottom: 17px;
`

export const CardDescription = styled.p`
  font-size: 14px;
  line-height: 120%; /* 16.8px */
  text-align: center;

  color: #404040;
`
