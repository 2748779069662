import styled, { css } from 'styled-components'

export const Wrapper = styled.main`
  ${({ withoutOverflow }) => css`
    width: 100vw;
    max-width: 100vw;
    ${!withoutOverflow
      ? css`
          overflow-x: hidden;
        `
      : css`
          overflow-x: clip;
        `}
  `}
`
