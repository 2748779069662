import * as Styled from './styles'
import { useTranslation } from 'react-i18next'
import { resources } from './resources'
import { Card } from './components/Card'
import { motion, AnimatePresence } from 'framer-motion'
import { useState } from 'react'

export function Schedule() {
  const { t } = useTranslation()
  const [isMobile, setIsMobile] = useState(() => window.innerWidth < 700)

  return (
    <Styled.Container>
      <AnimatePresence>
        {isMobile ? (
          <motion.div>
            <Styled.Title>{t('whyScheduleWithUs')}</Styled.Title>
          </motion.div>
        ) : (
          <motion.div
            initial={{ x: '100%' }}
            whileInView={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <Styled.Title>{t('whyScheduleWithUs')}</Styled.Title>
          </motion.div>
        )}
      </AnimatePresence>

      <Card resources={resources(t)} />
    </Styled.Container>
  )
}
