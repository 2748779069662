import React from 'react'
import { useChatStore } from '../../context/ChatContext'

import * as Styled from './styles'
import { useMediaQuery } from '../../../../../../hooks/useMediaQuery'

const ROLES_MAP = {
  USER: 'Motorista',
  ADMIN: 'Administrador',
  HOTEL_USER: 'Usuário de hostel',
  COMPANY_USER: 'Usuário parceiro',
  COMPANY_ADMIN: 'Administrador parceiro'
}

export function ChatHeader() {
  const userChat = useChatStore((state) => state.userChat)
  const isMobile = useMediaQuery('(max-width: 500px)')

  return (
    <Styled.Container>
      <Styled.ProfileImage src={userChat?.profilePicture?.url} />

      <div>
        <Styled.Label>Nome</Styled.Label>
        <Styled.Value style={{ fontWeight: 'bold' }}>
          {userChat?.name}
        </Styled.Value>
      </div>

      <div>
        <Styled.Label>ID</Styled.Label>
        <Styled.Value>{userChat?.id?.split('-')[0]}</Styled.Value>
      </div>
      {!isMobile && (
        <>
          <div>
            <Styled.Label>Tipo de Usuário</Styled.Label>
            <Styled.Value>{ROLES_MAP[userChat?.role] ?? '-'}</Styled.Value>
          </div>

          <div>
            <Styled.Label>Categoria</Styled.Label>
            <Styled.Value>
              {userChat?.vehicle?.category?.name ?? '-'}
            </Styled.Value>
          </div>
        </>
      )}
    </Styled.Container>
  )
}
