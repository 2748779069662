import React, { useState } from 'react'
import * as Styled from './styles'

import { DetailsTransfer } from './components/DetailsTransfer'
import { DetailsPayment } from './components/DetailsPayment'
import { DetailsPassenger } from './components/DetailsPassenger'
import { SendTransferModal } from './components/SendTransferModal'
import Status from './components/Status'
import { LoadingOverlay } from '@mantine/core'
import { useParams, useNavigate } from 'react-router'
import { useEffect } from 'react'
import fetchBooking from '../../../../services/booking'
import { toast } from 'react-toastify'
import { DetailsDriver } from './components/DetailsDriver'
import { useTranslation } from 'react-i18next'

export const TransferDetailsHostel = () => {
  const [reportTransferModal, setReportTransferModal] = useState()
  const [rejectModal, setRejectModal] = useState()
  const [transfer, setTransfer] = useState()
  const [next, setNext] = useState(false)
  const [loading, setLoading] = useState(true)

  const { id } = useParams()
  const navigate = useNavigate()

  const { t } = useTranslation()

  useEffect(() => {
    if (id) {
      fetchBooking
        .getBookingId(id)
        .then((res) => {
          setTransfer(res.data)
          setLoading(false)
        })
        .catch((err) => {
          console.log(err, 'err')
        })
    }
  }, [id])

  const statusMapper = {
    PENDING: 'Pendente',
    APPROVED: 'Enviado para motorista',
    ACCEPTED: 'Aceito pelo motorista',
    REFUSED: 'Cancelado',
    DONE: 'Realizado'
  }

  const handleReportTransfer = (reasonToReport) => {
    const payload = {
      reasonToReport
    }
    fetchBooking
      .reportTransfer(id, payload)
      .then((res) => {
        setNext(true)
      })
      .catch((err) => {
        console.log(err, 'err')
        if (
          err?.response?.data?.message ===
          'Transfers only can be canceled 3 hours before transferDate.'
        ) {
          toast.error(
            'Os transfers só podem ser cancelados 3 horas antes da data do transfer!'
          )
        }
      })
  }

  const handleConfirmTransfer = () => {
    fetchBooking
      .finishTransfer(id)
      .then((res) => {
        setNext(true)
        toast.success(t('toast.successMadeTransfer'))
        navigate('/dashboard/transfer')
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  return (
    <>
      <Styled.Container>
        <LoadingOverlay overlayOpacity={0.5} visible={loading} />
        <Styled.HeaderContainer>
          <Styled.Title>
            Transfers{' '}
            <Styled.TransferId>
              #
              {transfer?.id.length > 8
                ? transfer?.id.slice(0, 8) + '...'
                : transfer?.id}
            </Styled.TransferId>
          </Styled.Title>
          <Styled.ButtonsWrapper>
            <Styled.PrimaryButton onClick={() => setReportTransferModal(true)}>
              REPORTAR
            </Styled.PrimaryButton>
          </Styled.ButtonsWrapper>
        </Styled.HeaderContainer>
        {transfer?.status && <Status status={statusMapper[transfer?.status]} />}
        <Styled.DetailsContainer>
          <DetailsTransfer transfer={transfer} />
          <div className="right-wrapper">
            <Styled.PaymentPassengerWrapper>
              <DetailsPassenger transfer={transfer} />
              <DetailsPayment
                transfer={transfer}
                setSendTransferModal={setReportTransferModal}
              />
            </Styled.PaymentPassengerWrapper>
            {transfer?.driver && <DetailsDriver transfer={transfer} />}
          </div>
        </Styled.DetailsContainer>
      </Styled.Container>
      <SendTransferModal
        opened={reportTransferModal}
        onClose={() => setReportTransferModal(false)}
        onSuccess={(reasonToCancel) => {
          handleReportTransfer(reasonToCancel)
        }}
      />
    </>
  )
}
