import React, { useEffect, useState } from 'react'
import { ChartCard } from '../ChartCard'

import { ReactComponent as CarIcon } from '../../icons/car.svg'
import { ReactComponent as HeartHandshakeIcon } from '../../icons/heart-handshake.svg'

import fetchBooking from '../../../../../../services/booking'

import * as Styled from './styles'

const formatCurrency = (cents) => {
  const currency = cents > 0 ? cents / 100 : 0

  return Intl.NumberFormat('PT', {
    currency: 'EUR',
    style: 'currency'
  }).format(currency)
}

export function Counters({ filter }) {
  const [counters, setCounter] = useState(null)

  useEffect(() => {
    fetchBooking
      .getBookingDashboardCounters({
        transferDateStartAt: filter?.startDate,
        transferDateEndAt: filter?.endDate
      })
      .then(({ status, data }) => {
        if (status === 200) setCounter(data)
      })
  }, [filter])

  return (
    <>
      <ChartCard title="Ticket médio por motorista" Icon={CarIcon} width={250}>
        <Styled.Title>
          {formatCurrency(counters?.averageTicketByDriver ?? 0)}
        </Styled.Title>
      </ChartCard>

      <ChartCard title="Motoristas ativos" Icon={CarIcon} width={250}>
        <Styled.Title>{counters?.activeDrivers ?? 0}</Styled.Title>
      </ChartCard>

      <ChartCard
        title="Ticket médio por partner"
        Icon={HeartHandshakeIcon}
        width={250}
      >
        <Styled.Title>{counters?.averageTicketByHostel ?? 0}</Styled.Title>
      </ChartCard>

      <ChartCard
        title="Partner(s) novo(s)"
        Icon={HeartHandshakeIcon}
        width={250}
      >
        <Styled.Title>{counters?.newHostels ?? 0}</Styled.Title>
      </ChartCard>
    </>
  )
}
