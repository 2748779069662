import {
  Button,
  Divider,
  Flex,
  LoadingOverlay,
  MediaQuery
  // , Pagination
} from '@mantine/core'
import * as Styled from './styles'
import { ReactComponent as Direction } from './imgs/direction.svg'
import { ReactComponent as Pin } from './imgs/mapPin.svg'
import { ReactComponent as Car } from './imgs/car.svg'
import { ReactComponent as Passenger } from './imgs/passenger.svg'
import { ReactComponent as Surf } from './imgs/surf.svg'
import { ReactComponent as Pet } from './imgs/pet.svg'
import { ReactComponent as HandBaggage } from './imgs/handBaggage.svg'
import { ReactComponent as ChildChair } from './imgs/child-chair.svg'

import Chevron from './imgs/right.webp'
import { useEffect } from 'react'
import fetchBooking from '../../../../../../services/booking'
import { useState } from 'react'
import {
  formatDateAndTime,
  formatDayOfWeek,
  formatMonth,
  formatTime
} from '../../../../../../utils/formattedDate'
import { RejectModal } from '../RejectModal'
import { useAuth } from '../../../../../../contexts/useAuth'
import { useClipboard } from '@mantine/hooks'
import { Backpack, Briefcase, Luggage, Users2 } from 'lucide-react'

export const DetailsTransfer = ({ transfer }) => {
  const [acceptedModal, setAcceptedModal] = useState(false)
  const [rejectModal, setRejectModal] = useState(false)
  const [transferContent, setTransferContent] = useState()
  const [refreshBookings, setRefreshBookings] = useState(false)

  const clipboard = useClipboard({ timeout: 500 })

  const { user } = useAuth()

  const handleAcceptTransfer = (value, driver, setDriver) => {
    const payload = {
      id: value.id,
      driver: {
        id: driver ? driver : user.id
      }
    }

    if (user.id) {
      fetchBooking
        .updateBooking(payload)
        .then((res) => {
          if (res) {
            setAcceptedModal(false)
            setRefreshBookings(!refreshBookings)
            if (driver) {
              setDriver()
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  // const handleRejectTransfer = (value) => {
  //   const payload = {
  //     id: value.id,
  //     driver: {
  //       id: user.id
  //     }
  //   }

  //   if (user.id) {
  //     fetchBooking
  //       .updateBooking(payload)
  //       .then((res) => {
  //         console.log(res)
  //       })
  //       .catch((err) => {
  //         console.log(err)
  //       })
  //   }
  // }

  return (
    <>
      <Styled.CardContainer>
        <Styled.Container key={transfer?.id}>
          <Styled.Wrapper>
            <Styled.InfoWrapper>
              <Styled.TransferTitle>
                <Car />
                Detalhes do transfer
              </Styled.TransferTitle>
            </Styled.InfoWrapper>
            <Styled.TimeAndStatusWrapper>
              <Styled.StatusCard>
                {formatDayOfWeek(transfer?.transferDate)},{' '}
                {new Date(transfer?.transferDate)
                  .getDate()
                  .toString()
                  .padStart(2, '0')}
                /{formatMonth(transfer?.transferDate)} -{' '}
                {formatTime(transfer?.transferDate)}
              </Styled.StatusCard>
            </Styled.TimeAndStatusWrapper>

            <Styled.ValuesWrapper>
              <MediaQuery
                query="(max-width: 1024px)"
                styles={{ display: 'none' }}
              >
                <Styled.PriceTitle>
                  <Styled.DepartureTitle>Distância</Styled.DepartureTitle>
                  {transfer?.distanceInKm}km
                </Styled.PriceTitle>
              </MediaQuery>
            </Styled.ValuesWrapper>
            <Styled.InfoWrapper>
              <Styled.AddressWrapper>
                <Styled.DepartureWrapper>
                  <Styled.DepartureTitle>
                    <Pin /> Partida
                  </Styled.DepartureTitle>
                  <Styled.DepartureDescription>
                    {transfer?.startingPlace}
                  </Styled.DepartureDescription>
                </Styled.DepartureWrapper>
                <Styled.DirectionButton>
                  <Direction />
                </Styled.DirectionButton>
                <Styled.DepartureWrapper>
                  <Styled.DepartureTitle>
                    <Pin /> Chegada
                  </Styled.DepartureTitle>
                  <Styled.DepartureDescription>
                    {transfer?.arrivalPlace}
                  </Styled.DepartureDescription>
                </Styled.DepartureWrapper>
              </Styled.AddressWrapper>
              <Styled.DistanceMobile>
                {transfer?.distanceInKm}km
              </Styled.DistanceMobile>
              <Styled.IconsAndNumbersWrapper>
                {transfer?.backpacks > 0 && (
                  <Styled.IconAndNumber>
                    <Styled.IconsBackground>
                      <Backpack color="#27c7ff" />
                    </Styled.IconsBackground>
                    <span>
                      <strong>{transfer?.backpacks}</strong> Mochila
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer?.checkedLuggages > 0 && (
                  <Styled.IconAndNumber>
                    <Styled.IconsBackground>
                      <Luggage color="#27c7ff" />
                    </Styled.IconsBackground>
                    <span>
                      <strong>{transfer?.checkedLuggages}</strong> Bagagem
                      despachada
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer?.handLuggages > 0 && (
                  <Styled.IconAndNumber>
                    <Styled.IconsBackground>
                      <Briefcase color="#27c7ff" />
                    </Styled.IconsBackground>
                    <span>
                      <strong>{transfer?.handLuggages}</strong> Bagagem de mão
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer?.numberOfPassengers > 0 && (
                  <Styled.IconAndNumber>
                    <Styled.IconsBackground>
                      <Users2 color="#27c7ff" />
                    </Styled.IconsBackground>
                    <span>
                      <strong>{transfer?.numberOfPassengers} </strong>
                      Passageiro(s)
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer?.surfboards > 0 && (
                  <Styled.IconAndNumber>
                    <Surf />
                    <span>
                      <strong>{transfer?.surfboards} </strong>Prancha(s) de surf
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer?.babySeats > 0 && (
                  <Styled.IconAndNumber>
                    <ChildChair />
                    <span>
                      <strong>{transfer?.babySeats} </strong>Cadeira de bebê
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer?.childSeats > 0 && (
                  <Styled.IconAndNumber>
                    <ChildChair />
                    <span>
                      <strong>{transfer?.childSeats} </strong>Cadeira de bebê
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer?.boosterSeats > 0 && (
                  <Styled.IconAndNumber>
                    <ChildChair />
                    <span>
                      <strong>{transfer?.boosterSeats} </strong>Cadeira de bebê
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer?.animals > 0 && (
                  <Styled.IconAndNumber>
                    <Pet />
                    <span>
                      <strong>{transfer?.animals}</strong> Pet
                    </span>
                  </Styled.IconAndNumber>
                )}
              </Styled.IconsAndNumbersWrapper>
            </Styled.InfoWrapper>
          </Styled.Wrapper>
        </Styled.Container>

        <RejectModal
          opened={rejectModal}
          onClose={() => setRejectModal(false)}
        />
      </Styled.CardContainer>
    </>
  )
}
