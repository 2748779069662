import * as Styled from './styles'
import { ReactComponent as CloseIcon } from '../imgs/CloseIcon.svg'
import { useEffect, useState } from 'react'
import { Checkbox, Input } from '@mantine/core'
import {
  Car,
  Keyboard,
  ClipboardList,
  BadgeEuro,
  Backpack,
  Luggage,
  Briefcase
} from 'lucide-react'
import { useRef } from 'react'
import { formatErrorMessage, generatePayload, validateData } from './utils'
import { toast } from 'react-toastify'
import { INITIAL_STATE, MODELS_OPTIONS, MODEL_DECIDER } from './constants'
import { moneyMask } from './masks'
import { AxiosError } from 'axios'
import fetchCarCategory from '../../../../../../services/carCategory'
import fetchFile from '../../../../../../services/file'
import Xis from '../imgs/xis.webp'
import { AddPassenger } from '../../../SettingsAdm/components/Car/components/AddPassenger'
import { Arquives } from './Arquives'

export const CreateCategoryModal = ({
  onClose,
  onSuccess,
  initialData = undefined
}) => {
  const [data, setData] = useState({ ...INITIAL_STATE, ...(initialData || {}) })

  const [step, setStep] = useState(1)

  const [loading, setLoading] = useState(false)

  console.log(data, 'DATA')

  const overlayRef = useRef(null)

  const submitFunction = initialData
    ? fetchCarCategory.update
    : fetchCarCategory.create

  const handleChange = (field, value) => {
    setData({ ...data, [field]: value })
  }

  const onClickOverlay = (e) => {
    if (overlayRef === null) return
    if (e.target !== overlayRef.current) return
    if (!onClose) return
    onClose()
  }

  const handleSendFiles = () => {
    setLoading(true)
    const toastId = toast.loading(
      `${initialData ? 'Editando' : 'Criando'} categoria...`
    )
    const filesPromises = []

    if (data.files && data.files.length) {
      data.files.forEach((file, index) => {
        if (file.base64 && !file.id) {
          const fileData = {
            title: file.title,
            base64: file.base64,
            fileName: file.fileName,
            fileExtension: file.fileExtension
          }
          const createFilePromise = fetchFile
            .create(fileData)
            .then((res) => {
              if (file.identifier === 'foto-categoria') {
                setData({
                  ...data,
                  file: res.data
                })
                return data, res.data
              }
            })
            .catch((err) => console.log(err))

          filesPromises.push(createFilePromise)
        }
      })
    }

    const allFilesPromise = Promise.all(filesPromises)

    allFilesPromise
      .then((res) => {
        handleCreateCategory(toastId, data.files[0].id ? data.files[0] : res[0])
      })
      .catch((err) => {
        console.log(err)
        toast.update(toastId, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          newestOnTop: false,
          closeOnClick: true,
          rtl: false,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true,
          theme: 'light',
          type: 'error',
          closeButton: true,
          isLoading: false,
          render: `Erro ao ${
            initialData ? 'editar' : 'criar'
          } devido a erros nos arquivos`
        })
      })
  }

  const handleCreateCategory = async (toastId, file) => {
    const payload = generatePayload(data, file)
    try {
      const response = await submitFunction(payload)
      toast.update(toastId, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: 'light',
        type: 'success',
        closeButton: true,
        isLoading: false,
        render: `Categoria ${initialData ? 'editada' : 'criada'} com sucesso!`
      })
      onSuccess(response.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      const message =
        err instanceof AxiosError
          ? formatErrorMessage(err.response.data.message, !!initialData)
          : formatErrorMessage(err.message, !!initialData)
      toast.update(toastId, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: 'light',
        type: 'error',
        closeButton: true,
        isLoading: false,
        render: message
      })
      setLoading(false)
    }
  }

  useEffect(() => {
    const escapeModal = ({ key }) => {
      if (key === 'Escape' && onClose) onClose()
    }

    window.document.addEventListener('keydown', escapeModal)

    return () => {
      window.document.removeEventListener('keydown', escapeModal)
    }
  }, [onClose])

  return (
    <Styled.ModalOverlay ref={overlayRef} onClick={onClickOverlay}>
      {step === 1 && (
        <Styled.ModalContainer>
          <Styled.ModalHeader>
            <h2>Categoria</h2>
            <Styled.CloseButton>
              <CloseIcon
                onClick={() => {
                  onClose()
                }}
              />
            </Styled.CloseButton>
          </Styled.ModalHeader>
          <Styled.ModalContent>
            <Input.Wrapper id="input-demo" label="Nome da categoria">
              <Input
                icon={<Keyboard />}
                id="input-demo"
                placeholder="Categoria"
                size={'lg'}
                onChange={(e) => handleChange('name', e.target.value)}
                value={data.name}
                rightSection={
                  data.name && (
                    <img
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleChange('name', '')}
                      width={22.55}
                      height={22.55}
                      src={Xis}
                    />
                  )
                }
              />
            </Input.Wrapper>
            <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
              {/* <Input.Wrapper
              id="input-demo"
              label="Ordem da categoria"
              style={{ flex: 1, width: '100%' }}
            >
              <Input
                icon={<ClipboardList />}
                classNames={{
                  input: typeof data.order !== 'number' ? 'empty' : undefined
                }}
                id="input-demo"
                component="select"
                size={'lg'}
                onChange={(e) => handleChange('order', e.target.value)}
                value={data.order}
                rightSection={
                  typeof data.order === 'number' && (
                    <img
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleChange('appliedTo', '')}
                      width={22.55}
                      height={22.55}
                      src={Xis}
                    />
                  )
                }
              >
                <option value="">Selecione</option>
                <option value="0">1</option>
                <option value="1">2</option>
                <option value="2">3</option>
              </Input>
            </Input.Wrapper> */}
              <Input.Wrapper
                id="input-demo"
                label="Valor por km"
                style={{ flex: 1, width: '100%' }}
              >
                <Input
                  icon={<BadgeEuro />}
                  id="input-demo"
                  placeholder="0,00"
                  size={'lg'}
                  onChange={(e) =>
                    handleChange('kmPerLiter', moneyMask(e.target.value))
                  }
                  value={data.kmPerLiter.formattedValue}
                  rightSection={
                    data.kmPerLiter.value && (
                      <img
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          handleChange('kmPerLiter', {
                            value: '',
                            formattedValue: ''
                          })
                        }
                        width={22.55}
                        height={22.55}
                        src={Xis}
                      />
                    )
                  }
                />
              </Input.Wrapper>
            </div>
            <label style={{ display: 'block', marginTop: '1rem' }}>
              Ícone da categoria
            </label>
            <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
              <Styled.ModelImageWrapper>
                <img src={MODEL_DECIDER[data.imageIdentifier]} />
              </Styled.ModelImageWrapper>
              <div style={{ flex: 1, width: '100%' }}>
                <Input.Wrapper
                  id="input-demo"
                  style={{ flex: 1, width: '100%' }}
                >
                  <Input
                    icon={<Car />}
                    classNames={{
                      input: !data.imageIdentifier ? 'empty' : undefined
                    }}
                    id="input-demo"
                    component="select"
                    size={'lg'}
                    onChange={(e) =>
                      handleChange('imageIdentifier', e.target.value)
                    }
                    value={data.imageIdentifier}
                    rightSection={
                      data.imageIdentifier && (
                        <img
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleChange('imageIdentifier', '')}
                          width={22.55}
                          height={22.55}
                          src={Xis}
                        />
                      )
                    }
                  >
                    <option value="">Selecione</option>
                    {MODELS_OPTIONS.map(({ label, value }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </Input>
                </Input.Wrapper>
              </div>
            </div>
          </Styled.ModalContent>
          <Styled.ModalButton
            disabled={!data.name || !data.kmPerLiter || loading}
            onClick={() => setStep(2)}
          >
            CONTINUAR
          </Styled.ModalButton>
        </Styled.ModalContainer>
      )}
      {step === 2 && (
        <Styled.ModalContainer
          style={{
            alignItems: 'center'
          }}
        >
          <Styled.ModalHeader>
            <h2>Categoria</h2>
            <Styled.CloseButton>
              <CloseIcon
                onClick={() => {
                  onClose()
                }}
              />
            </Styled.CloseButton>
          </Styled.ModalHeader>
          <Styled.ModalContent>
            <Input.Wrapper
              style={{
                position: 'relative'
              }}
              id="input-demo"
              label="Imagem da categoria"
            >
              <Arquives data={data} setData={setData} />
            </Input.Wrapper>
            <Styled.OptionsContainer>
              <Styled.NumberContainer>
                <AddPassenger
                  responsive
                  label="Passageiros"
                  setValue={(value) => {
                    setData((prevData) => ({
                      ...prevData,
                      passengerCapacity: value
                    }))
                  }}
                  value={data?.passengerCapacity}
                  name="backPack"
                  width="100%"
                />
              </Styled.NumberContainer>
              <Styled.NumberContainer>
                <AddPassenger
                  responsive
                  label="Bagagem Despachada"
                  setValue={(value) => {
                    setData((prevData) => ({
                      ...prevData,
                      luggageCapacity: value
                    }))
                  }}
                  value={data?.luggageCapacity}
                  Icon={Briefcase}
                  name="briefcase"
                  width="100%"
                />
              </Styled.NumberContainer>
              <Styled.NumberContainer>
                <AddPassenger
                  responsive
                  label="Bagagem de mão"
                  setValue={(value) => {
                    setData((prevData) => ({
                      ...prevData,
                      handLuggageCapacity: value
                    }))
                  }}
                  value={data?.handLuggageCapacity}
                  Icon={Luggage}
                  name="luggage"
                  width="100%"
                />
              </Styled.NumberContainer>
              <Styled.NumberContainer>
                <AddPassenger
                  responsive
                  label="Mochila"
                  setValue={(value) => {
                    setData((prevData) => ({
                      ...prevData,
                      backpackCapacity: value
                    }))
                  }}
                  value={data?.backpackCapacity}
                  Icon={Backpack}
                  name="backPack"
                  width="100%"
                />
              </Styled.NumberContainer>

              <Styled.CheckboxAditionalWrapper>
                <label>Ar Condicionado</label>
                <Checkbox
                  size="lg"
                  checked={data?.providesAirConditioning}
                  onChange={() =>
                    setData((prevData) => ({
                      ...prevData,
                      providesAirConditioning:
                        !prevData?.providesAirConditioning
                    }))
                  }
                  label="Sim"
                />
              </Styled.CheckboxAditionalWrapper>
              <Styled.CheckboxAditionalWrapper>
                <label>Wifi</label>
                <Checkbox
                  size="lg"
                  checked={data?.providesInternet}
                  onChange={() =>
                    setData((prevData) => ({
                      ...prevData,
                      providesInternet: !prevData?.providesInternet
                    }))
                  }
                  label="Sim"
                />
              </Styled.CheckboxAditionalWrapper>
            </Styled.OptionsContainer>
          </Styled.ModalContent>
          <div
            style={{
              display: 'flex',
              gap: '16px'
            }}
          >
            <Styled.ModalButton disabled={loading} onClick={() => setStep(1)}>
              VOLTAR
            </Styled.ModalButton>
            <Styled.ModalButton
              disabled={
                // !validateData(data) ||
                loading
              }
              onClick={handleSendFiles}
            >
              SALVAR
            </Styled.ModalButton>
          </div>
        </Styled.ModalContainer>
      )}
    </Styled.ModalOverlay>
  )
}
