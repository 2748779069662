import styled, { css } from 'styled-components'

export const CardContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;

    padding: 10.6484px 10.6484px 21.2967px;
    width: 220px;
    height: 200px;
    box-shadow: ${theme.shadows.minimal};
    border-radius: ${theme.border.radius.medium};

    div {
      display: flex;
      flex-direction: column;
      gap: 7.1px;
    }

    h2 {
      font-weight: 600;
      font-size: 1.6rem;
      letter-spacing: 0.01em;
      color: #0d0d0d;
    }

    p {
      font-weight: 500;
      font-size: 1.4rem;
      color: ${theme.colors.gray};
    }

    img {
      /* max-width: 218.97px; */
      height: 100%;
      width: 70%;
      max-width: 16rem;
      object-fit: contain;
      margin: auto;

      @media screen and (max-width: 768px) {
      width: 90%;
      }
    }

    @media (max-width: 1290px) {
      width: 25%;
    }

    @media screen and (max-width: 768px) {
      width: 40%;

      :last-of-type {
        display: none;
      }
    }

    /* @media (max-width: 640px) {
      &:nth-child(3) {
        img {
          width: 120% !important;
          min-width: 12rem;
        }
      }

      &:nth-child(4) {
        img {
          width: 50% !important;
          min-width: 12rem;
        }
      }

      img {
        width: 80%;
        max-width: 24rem;
      }
    } */

    @media (max-width: 350px) {
      width: 80%;

      /* img {
        /* max-width: 218.97px !important; */
        /* width: 80%; */
        /* width: unset; */

        /* margin: auto; */
      } */
    }
  `}
`

const containerModifiers = {
  dark: () => css`
    ${CardContainer} {
      background: linear-gradient(180deg, #333333 0%, rgba(51, 51, 51, 0) 100%);
      border: 0.820368px solid rgba(255, 255, 255, 0.69);
      filter: drop-shadow(0px 18.0481px 19.6888px rgba(0, 0, 0, 0.05));

      h2 {
        color: #ffffff;
        font-weight: 500;
      }

      p {
        color: #a7a7a7;
      }
    }
  `
}

export const Container = styled.div`
  ${({ dark }) => css`
    display: flex;

    > div {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    &:last-child {
      gap: 0;
    }

    /* @media (max-width: 1100px) {
    flex-wrap: wrap;
    justify-content: center;
  } */
    @media (max-width: 1290px) {
      > div {
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    @media (max-width: 550px) {
      > div {
        gap: 2rem;
      }
    }

    ${dark && containerModifiers.dark}
  `}
`
