function getFriendlyDate(dateStr) {
  if (!dateStr) return ''

  const date = new Date(dateStr)
  const formattedDate = date.toLocaleDateString()

  const hour = date.getHours().toString().padStart(2, '0')
  const minute = date.getMinutes().toString().padStart(2, '0')

  return `${formattedDate} - ${hour}:${minute}`
}

export const DateFormatter = {
  getFriendlyDate
}
