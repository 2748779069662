import React, { useEffect, useState } from 'react'
import { Dropzone } from '@mantine/dropzone'
import { Group, Input, Text, rem } from '@mantine/core'
import { IconDownload, IconX } from '@tabler/icons-react'
import { Car, CopyCheck, FileText, Trash2, Upload } from 'lucide-react'
import './index.css'
import fetchFile from '../../../../../../../../services/file'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const CustomDropzone = ({
  openRef,
  onDrop,
  identifier,
  files,
  theme,
  classes,
  handleDelete,
  name,
  maxFiles,
  disabled = false,
  setData,
  data
}) => {
  const [arquives, setArquives] = useState([])
  const [disableClick, setDisableClick] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    setArquives(
      data.files?.filter(
        (file) => (file.identifier || file.title) === identifier
      )
    )
    if (arquives) {
      setDisableClick(arquives?.length > 0 ? true : false)
    }
  }, [data, identifier, arquives?.length])
  const isDropzoneSelected = files?.some(
    (item) => (item.identifier || item.title) === identifier
  )

  const onDeleteClick = () => {
    const fileToDelete = files?.filter(
      (file) => (file.identifier || file.title) === identifier
    )
    console.log(fileToDelete, 'FILES', files)
    handleDelete(fileToDelete)
  }

  const onDownload = (event) => {
    event.stopPropagation()

    if (!arquives) return

    arquives.forEach(async (arquive) => {
      const data = await fetch(arquive.url)
      const blob = await data.blob()

      const objectUrl = URL.createObjectURL(blob)

      const link = document.createElement('a')

      link.setAttribute('href', objectUrl)
      link.setAttribute('download', arquive.fileName)
      link.style.display = 'none'

      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)
    })
  }

  const onChangeStatus = (id, status) => {
    fetchFile
      .changeStatus(id, status)
      .then((res) => {
        if (res.data) {
          toast.success(t('toast.successStatusFile'))

          setData((oldState) => {
            const fileIndex = oldState.files.findIndex((file) => file.id === id)

            const newFiles = [...oldState.files]

            newFiles[fileIndex] = {
              ...newFiles[fileIndex],
              status: status
            }

            return {
              ...oldState,
              files: newFiles
            }
          })
        }
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  return (
    <div className={classes.container}>
      <div className={classes.wrapper} onClick={onDownload}>
        <Dropzone
          openRef={openRef}
          onDrop={onDrop}
          className={`${classes.dropzone} ${
            isDropzoneSelected ? classes.selected : ''
          }`}
          radius="md"
          multiple={maxFiles > 1}
          maxFiles={maxFiles}
          disabled={disabled || disableClick}
        >
          <div
            style={{
              pointerEvents: 'none',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <Group justify="center">
              <Dropzone.Accept>
                <IconDownload
                  style={{ width: rem(50), height: rem(50) }}
                  color={theme.colors.blue[6]}
                  stroke={1.5}
                />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX
                  style={{ width: rem(50), height: rem(50) }}
                  color={theme.colors.red[6]}
                  stroke={1.5}
                />
              </Dropzone.Reject>
              {files?.filter(
                (item) => (item.identifier || item.title) === identifier
              ).length > 0 ? (
                <FileText color="#27c7ff" width={48} height={48} />
              ) : (
                <Dropzone.Idle>
                  <Upload color="#27c7ff" width={48} height={48} />
                </Dropzone.Idle>
              )}
            </Group>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {files?.filter(
                (item) => (item.identifier || item.title) === identifier
              ).length > 0 ? (
                files
                  ?.filter(
                    (item) => (item.identifier || item.title) === identifier
                  )
                  .map((file) => (
                    <Text
                      ta="center"
                      fw={700}
                      fz="lg"
                      mt="xl"
                      key={file.fileName}
                    >
                      <span>{file.fileName}</span>
                    </Text>
                  ))
              ) : (
                <Text ta="center" fw={700} fz="lg" mt="xl">
                  <Dropzone.Accept>Drop data here</Dropzone.Accept>
                  <Dropzone.Reject>Pdf file less than 30mb</Dropzone.Reject>
                  <Dropzone.Idle>Solte aqui seu arquivo</Dropzone.Idle>
                </Text>
              )}
            </div>
            <Text ta="center" fz="sm" mt="xs" c="dimmed"></Text>
          </div>
        </Dropzone>
      </div>
      <div>
        <div className={classes.info_wrapper}>
          <label className={classes.label}>{name}</label>
          {files?.filter(
            (item) => (item.identifier || item.title) === identifier
          ).length > 0 ? (
            <Trash2
              style={{ cursor: 'pointer' }}
              color="#27c7ff"
              width={24}
              height={24}
              onClick={onDeleteClick}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default CustomDropzone
