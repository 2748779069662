import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  overflow-y: auto;
  padding: 24px;

  @media (max-width: 500px) {
    max-height: 400px;
  }
`

export const InfoWrapper = styled.div`
  background: #DFF7FF;
  border-radius: 12px;
  padding: 5px 12px;

  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
`

export const InfoText = styled.p`
  font-size: 14px;
  font-weight: 300;
`

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: ${({ sentByMe }) => (sentByMe ? 'row-reverse' : 'row')};
  transform: rotate(180deg);

  margin-bottom: ${({ isSameAsBefore }) => (isSameAsBefore ? 4 : 8)}px;
`

export const MessageContentWrapper = styled.div`
  display: flex;
  flex-direction: ${({ sentByMe }) => (sentByMe ? 'row-reverse' : 'row')};
  justify-content: ${({ sentByMe }) => (sentByMe ? 'flex-start' : 'flex-end')};
  gap: 12px;

  max-width: calc(50% - 30px);
  @media (max-width: 500px) {
    max-width: 100%;
  }
`

export const UserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  width: 60px;
`

export const UserName = styled.div`
  text-align: right;
  color: #27c7ff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Neometric;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  max-width: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const ProfileImg = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 35px;

  object-fit: cover;
`

export const BubbleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  padding: 10px 15px;
  height: fit-content;

  border-radius: ${({ sentByMe }) =>
    sentByMe ? '16px 0px 16px 16px' : '0px 16px 16px 16px'};
  background: ${({ sentByMe }) => (sentByMe ? '#DFF7FF' : '#f7f7f7')};
`

export const Message = styled.p`
  color: #404040;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Neometric;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;
  white-space: pre-line;
  @media (max-width: 500px) {
    line-break: anywhere;
  }
`

export const SendDate = styled.p`
  color: #1D95BF;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
`
