import * as Styled from './styles'
import fetchTransfer from '../../../../../../services/transfer'
import { useAuth } from '../../../../../../contexts/useAuth'
import { HistoricItem } from './HistoricItem'
import { PaymentDetailsModal } from '../PaymentDetailsModal'
import { RegisterHistoricItem } from './RegisterHistoricItem'
import { DETAILS_MODAL_INITIAL_STATE } from './constants'
import { useEffect, useRef, useState } from 'react'
import { PAYMENT_STATUS, PAYMENT_TYPES } from '../../constants'
import { ClipLoader } from 'react-spinners'
import { paymentCardTypeDecider } from './utils'

export const DriverHistoric = ({ driver, updateDriver }) => {
  const [items, setItems] = useState([])
  const [detailsModal, setDetailsModal] = useState(DETAILS_MODAL_INITIAL_STATE)
  const [loadingState, setLoadingState] = useState({
    creatingTransfer: false,
    loadingTransfers: false
  })
  const { user, ROLE } = useAuth()

  const historicItemsRef = useRef(null)

  useEffect(() => {
    ;(async () => {
      setLoadingState((oldState) => ({ ...oldState, loadingTransfers: true }))
      const response = await fetchTransfer.transfersPerUser({
        ...(ROLE !== 'HOTEL_USER'
          ? { userId: driver.id }
          : ROLE === 'COMPANY_ADMIN'
          ? { companyId: user.company.id }
          : { hostelId: user.hostel ? user.hostel.id : user.id }),
        size: 9999
      })
      if (response.data && response.data?.content) {
        const items = response.data.content.map((transfer) => {
          const transfers =
            Array.isArray(transfer.bookingTransfers) &&
            transfer.bookingTransfers.length > 0
              ? transfer.bookingTransfers.map(
                  (bookingTransfer) => bookingTransfer.booking
                )
              : undefined
          return {
            id: transfer.id,
            type: paymentCardTypeDecider(transfer.type, transfer.status, ROLE),
            value: transfer.amount / 100,
            message: transfer.message,
            paidAt: transfer.paidAt || new Date().toISOString(),
            user: user || transfer.createdBy,
            transfers
          }
        })
        setItems(items)
      }
      setLoadingState((oldState) => ({ ...oldState, loadingTransfers: false }))
    })()
  }, [driver.id, user, ROLE])
  useEffect(() => {
    if (historicItemsRef) {
      const elementScrollHeight = historicItemsRef.current.scrollHeight
      historicItemsRef.current.scrollTo({
        top: elementScrollHeight,
        behavior: 'smooth'
      })
    }
  }, [items, historicItemsRef])
  const onAddItem = (newItem) => {
    updateDriver()
    const newItems = [newItem, ...items]
    setItems(newItems)
  }
  // const sortedItems = items.sort(
  //   (a, b) => new Date(b.paidAt) - new Date(a.paidAt)
  // )
  const reversedItems = [...items].reverse()
  return (
    <>
      <Styled.Wrapper>
        <Styled.Historic>
          <div ref={historicItemsRef}>
            <h2>Histórico de pagamentos</h2>
            {!loadingState.loadingTransfers && items.length === 0 && (
              <p>Nenhum pagamento encontrado</p>
            )}
            <div>
              {loadingState.loadingTransfers && (
                <div
                  style={{
                    width: '100%',
                    height: '40rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start'
                  }}
                >
                  <ClipLoader size={50} color="#27c7ff" />
                </div>
              )}
              {!loadingState.loadingTransfers &&
                reversedItems.map((item) => (
                  <HistoricItem
                    key={item.id}
                    invertedUsers={[
                      'USER',
                      'HOTEL_USER',
                      'COMPANY_ADMIN',
                      'COMPANY_USER'
                    ].includes(ROLE)}
                    onOpenDetails={() =>
                      setDetailsModal({
                        isOpen: true,
                        data: item
                      })
                    }
                    driver={driver}
                    {...item}
                  />
                ))}
            </div>
          </div>
        </Styled.Historic>
        {ROLE !== 'HOTEL_USER' && ROLE !== 'COMPANY_ADMIN' && (
          <RegisterHistoricItem
            disabled={ROLE === 'USER' || ROLE === 'COMPANY_USER'}
            onAddItem={onAddItem}
            loadingState={loadingState}
            setLoadingState={setLoadingState}
            driver={driver}
          />
        )}
      </Styled.Wrapper>
      {detailsModal.isOpen && (
        <PaymentDetailsModal
          invertedUsers={[
            'USER',
            'HOTEL_USER',
            'COMPANY_ADMIN',
            'COMPANY_USER'
          ].includes(ROLE)}
          onClose={() => setDetailsModal(DETAILS_MODAL_INITIAL_STATE)}
          onSuccess={() => console.log('success')}
          driver={driver}
          data={detailsModal.data}
        />
      )}
    </>
  )
}
