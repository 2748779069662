const INITIAL_STATE = {
  coupon: '',
  discountPercentage: '',
  validFrom: null,
  validTo: null,
  appliedTo: '',
  paymentMethods: [],
  useLimitOption: 'LIMITLESS',
  useAmount: 1
}

const PAYMENT_METHODS = [
  { label: 'Cartão de crédito/débito', value: 'CREDIT_CARD' },
  { label: 'MBWay', value: 'MBWAY' },
  { label: 'Multibanco', value: 'MBMULTI' },
  { label: 'Dinheiro', value: 'CASH' }
]

const LIMIT_OPTIONS = [
  { label: 'Sem limite', value: 'LIMITLESS' },
  { label: 'Uma vez', value: 'ONCE' },
  { label: 'Personalizado', value: 'CUSTOM' }
]

export { INITIAL_STATE, LIMIT_OPTIONS, PAYMENT_METHODS }
