import { CarCard } from './components/CarCard'
import * as Styled from './styles'

import { CarItem } from './CarItem'
import { useTransferContext } from '../../../../contexts/TransferContext'

export const ChoicedCar = ({ setOpened, opened }) => {
  const { carSelected } = useTransferContext()

  return (
    <Styled.Container>
      <CarCard
        setOpened={setOpened}
        opened={opened}
        car={carSelected ? [carSelected] : CarItem}
      />
    </Styled.Container>
  )
}
