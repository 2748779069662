import { useTranslation } from 'react-i18next'
import { Wrapper, Badge } from './styles'
import {
  LucideUser,
  LucidePiggyBank,
  LucideShieldCheck,
  LucideCalendarClock
} from 'lucide-react'

export const FeaturesCards = () => {
  const { t } = useTranslation()
  const CardsData = [
    {
      icon: <LucideUser />,
      title: t('events.haveFun'),
      description: t('events.dontWorryAboutParking')
    },
    {
      icon: <LucidePiggyBank />,
      title: t('events.savings'),
      description: t('events.shareTransfer')
    },
    {
      icon: <LucideShieldCheck />,
      title: t('events.safety'),
      description: t('events.certifiedDriver')
    },
    {
      icon: <LucideCalendarClock />,
      title: t('events.24/7Availability'),
      description: t('events.24/7Availability')
    }
  ]

  return (
    <>
      {CardsData.map((card, index) => {
        return (
          <Wrapper key={index}>
            <Badge>{card.icon}</Badge>

            <div>
              <h4>{card.title}</h4>
              <p>{card.description}</p>
            </div>
          </Wrapper>
        )
      })}
    </>
  )
}
