import styled from 'styled-components'

export const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 4vh 0;
  margin-bottom: 10vh;

  @media (max-width: 840px) {
    flex-direction: column;
  }
`

export const BackgroundShape = styled.div`
  height: clamp(30vh, 50vh, 50vh);
  width: clamp(25vw, 35vw, 35vw);
  border-top-right-radius: clamp(16px, 0.833vw, 0.833vw);
  border-bottom-right-radius: clamp(16px, 0.833vw, 0.833vw);
  position: absolute;
  top: 6vh;
  left: 0;
  z-index: -1;

  @media (max-width: 840px) {
    svg {
      height: 70%;
      width: auto;
    }
  }

  @media (max-width: 500px) {
    height: 450px;
    width: 80%;

    svg {
      width: 100%;
    }
  }

  @media (max-width: 370px) {
    height: 50%;
  }
`

export const MainImage = styled.img`
  width: auto;

  @media (max-width: 768px) {
  }

  @media (max-width: 840px) {
    width: 70%;
  }

  @media (max-width: 500px) {
    margin-top: 30px;

    width: clamp(350px, 350px, 350px);
    height: clamp(350px, 350px, 350px);
  }
`

export const TextContainer = styled.div`
  width: clamp(50%, 30vw, 30vw);

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: clamp(24px, 1.25vw, 1.25vw);

  align-self: center;
  justify-self: center;

  h1 {
    font-family: 'Neometric';
    font-weight: 600;
    font-size: clamp(32px, 1.667vw, 1.667vw);
    line-height: 120%; /* 38.4px */
    letter-spacing: 0.32px;
    color: var(--black-brand-primary, #222);

    strong {
      color: #27c7ff;
    }
  }

  h2 {
    font-size: clamp(16px, 0.833vw, 0.833vw);
    font-family: 'Poppins';
    font-weight: 600;
    color: var(--black-brand-primary, #222);
  }

  p {
    font-family: 'Poppins';
    font-size: clamp(16px, 0.833vw, 0.833vw);
  }

  button {
    background: #27c7ff;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: clamp(16px, 0.833vw, 0.833vw);
    border-radius: clamp(16px, 0.833vw, 0.833vw);
    padding: clamp(16px, 0.833vw, 0.833vw) clamp(32px, 1.667vw, 1.667vw);
    margin: 0 auto;
    font-family: 'Roboto';
    font-size: clamp(16px, 0.833vw, 0.833vw);
    box-shadow: 0 0 clamp(8px, 0.42vw, 0.42vw) lightgrey;
  }

  @media (max-width: 768px) {
    padding: 0px 16px;

    width: 100%;

    h1 {
      color: var(--black-brand-primary, #222);
      font-family: 'Neometric';
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 28.8px */
      letter-spacing: 0.24px;
    }

    strong {
      color: var(--yellow-brand-primary, #27c7ff);
      font-family: 'Neometric';
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      letter-spacing: 0.24px;
    }

    h2 {
      color: var(--black-brand-primary, #222);
      font-family: 'Poppins';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    p {
      color: var(--black-brand-primary, #222);
      font-family: 'Poppins';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%; /* 17.6px */
    }
  }
`
export const Button = styled.button`
  display: flex;
  width: 264px;
  height: 56px;
  padding: 16px 24px 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border-radius: 16px;
  background: var(--white-grayscale, #fff);
  box-shadow: 0px 18.048px 19.689px 0px rgba(0, 0, 0, 0.05),
    0px 4px 8px 0px rgba(0, 0, 0, 0.1);

  color: var(--yellow-brand-primary, #27c7ff);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;
  text-transform: uppercase;

  transition: ease-in-out 300ms;

  :hover {
    background-color: #fff;
    color: #27c7ff;
    border: solid 1px #fff;
  }
`
