import styled, { css } from 'styled-components'
export const Container = styled.div`
  border-radius: 16px;
  border: ${({ color }) => `1px solid ${color}`};

  padding: 1.4rem;
  flex: 1;
  min-width: 100%;
  width: fit-content;

  @media (max-width: 1024px) {
    & {
      padding: 8px;
    }
  }
`

export const StatusCard = styled.div`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background-color: ${({ color }) => color};
  color: var(--white-grayscale, #fff);
  font-family: Neometric;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: 0.14px;

  @media (max-width: 1024px) {
    & {
      display: none;
    }
  }
`

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const Comission = styled.span`
  color: var(--grey-1-grayscale, #404040);
  font-family: Neometric;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: 0.14px;
  width: 30%;

  strong {
    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.18px;
  }
`

export const IconsAndComission = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.4rem;

  @media (max-width: 1300px) {
    & {
      gap: 15px;
    }
    .divider {
      margin-right: 15px;
    }
  }
  @media (max-width: 1024px) {
    & {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }
  }
`

export const TimeWrapper = styled.div`
  border: 1px solid #27c7ff;
  padding: 8px;
  border-radius: 8px;
  width: 7.8rem;
  height: 99px;

  @media (max-width: 1024px) {
    & {
      display: none;
    }
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column-reverse;
  align-items: center;

  /* @media (max-width: 768px) {
    flex-direction: row;
  } */
`

export const RejectButton = styled.div`
  button {
    border-radius: 16px;
    background-color: #f8294e;
    width: 100%;
    height: 5rem;
    font-family: Roboto;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.01em;

    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  button:hover {
    background-color: #f8294e;
  }

  @media (max-width: 1024px) {
    button {
      display: flex;
      padding: 16px 24px 16px 32px;
      justify-content: space-between;
      align-items: center;
    }
  }
`

export const AcceptedButton = styled.div`
  button {
    border-radius: 16px;
    background-color: #48d9a4;
    width: 100%;
    height: 5rem;
    font-family: Roboto;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.01em;

    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  button:hover {
    background-color: #48d9a4;
  }
`

export const DayTitle = styled.p`
  font-family: 'Poppins' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */
  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #000000;
  @media (max-width: 1024px) {
    & {
      display: none;
    }
  }
`

export const MonthTitle = styled.p`
  font-family: Neometric;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0px;
  color: #27c7ff;
`

export const YearTitle = styled.p`
  font-family: Neometric;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 1px;
  color: #404040;
`

export const TimeTitle = styled.p`
  font-family: Neometric;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0px;
  color: #27c7ff;
`

export const IdWrapper = styled.p`
  display: flex;
  align-items: center;
  gap: 2px;
  strong {
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.01em;
  }

  span {
    p {
      font-family: Inter;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0.01em;
      max-width: 20rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

export const AddressWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 6px;
`

export const DepartureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const KmTitle = styled.p`
  font-family: Neometric;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 19px;
  color: #27c7ff;
  display: flex;
  align-items: flex-end;
  gap: 5px;

  svg {
    width: 2rem;
    height: 2rem;
  }
`

export const DepartureTitle = styled.p`
  font-family: Neometric;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #27c7ff;
  display: flex;
  align-items: flex-end;
  gap: 5px;

  svg {
    width: 2rem;
    height: 2rem;
  }
`

export const DirectionButton = styled.button`
  max-width: 4rem;
  width: 100%;
  height: 2.8rem;
  border-radius: 8px;
  background-color: #DFF7FF;

  svg {
    width: 100%;
  }
`

export const PriceTitle = styled.p`
  font-family: Neometric;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.01em;
  color: #404040;
  margin-bottom: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: max-content;
`

export const PriceMobile = styled.p`
  font-family: Neometric;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.01em;
  color: #404040;
  margin-left: 30px;
  margin-bottom: auto;
  display: none;
`

export const DepartureDescription = styled.p`
  font-family: Neometric;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.01em;
  color: #404040;
  width: 250px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  width: 100%;
`

export const IconsAndNumbersWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  gap: 24px;
`

export const IconAndNumber = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    color: #a0a0a0;
  }
`

export const TimeDescription = styled.div`
  display: none;

  @media (max-width: 1024px) {
    & {
      display: flex;
      padding: 6px 8px;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: var(--yellow-brand-primary, #27c7ff);
      color: var(--white-grayscale, #fff);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  @media (max-width: 768px) {
    & {
      color: var(--white-grayscale, #fff);
      leading-trim: both;
      text-edge: cap;
      font-family: Neometric;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`

export const ValuesWrapper = styled.div`
  border-radius: 8px;
  background: var(--yellow-2-support, #DFF7FF);
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: center;
  gap: 1.4rem;

  @media (max-width: 768px) {
    background: var(--yellow-3-support, #E9F9FF);
  }
`

export const DistanceMobile = styled.div`
  font-family: Neometric;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.01em;
  color: #404040;
  margin-bottom: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: max-content;

  @media (max-width: 1024px) {
    & {
      color: #27c7ff !important;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      letter-spacing: 0.16px;
      width: 100%;
    }
  }
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
`
export const DistanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  span {
    color: var(--yellow-3-support, #1D95BF);
    font-family: Neometric;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 21.6px */
    letter-spacing: 0.18px;
  }
`
export const ChevronButton = styled.button`
  /* display: flex; */
  padding: 8.39px;
  align-items: flex-start;
  gap: 8.39px;
  border-radius: 31.464px;
  background: var(--grey-4-grayscale, #f7f7f7);
`
export const ButtonNormal = styled.div`
  @media (max-width: 1024px) {
    & {
      display: none;
    }
  }
`

export const ButtonMobile = styled.div`
  @media (min-width: 1024px) {
    & {
      display: none;
    }
  }
`
