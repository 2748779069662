import * as React from 'react'

import { cn } from '../../utils/cn'

import styles from './styles.module.css'

const Textarea = React.forwardRef(({ className, ...props }, ref) => {
  return (
    <textarea className={cn(styles.base, className)} ref={ref} {...props} />
  )
})
Textarea.displayName = 'Textarea'

export { Textarea }
