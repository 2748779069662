import * as S from './styles'
import { useTranslation } from 'react-i18next'
import PortugalMap from './imgs/portugal_map.svg'
import ArrowIcon from './imgs/arrow.svg'
import { ReactComponent as OrangeShape } from './imgs/Rectangle116.svg'

export const GreatExperience = () => {
  const { t } = useTranslation()

  return (
    <S.Container>
      <S.BackgroundShape>
        <OrangeShape />
      </S.BackgroundShape>
      <S.MainImage src={PortugalMap} />

      <S.TextContainer>
        <h1>
          {t('greatExperienceTitle')}{' '}
          <strong>{t('greatExperienceTitleHighlight')}</strong>
        </h1>
        <h2>{t('greatExperienceSubTitle')}</h2>
        <p>{t('greatExperienceTextContent')}</p>
        <a href="#transfer">
          <S.Button>
            {t('greatExperienceButtonText')}
            <img src={ArrowIcon} />
          </S.Button>
        </a>
      </S.TextContainer>
    </S.Container>
  )
}
