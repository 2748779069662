import { Flex, Input, MediaQuery } from '@mantine/core'
import * as Styled from './styles'

export const BankAccount = ({ data, setData, disabled }) => {
  const handleInputChange = (field, value) => {
    setData((prevValues) => ({
      ...prevValues,
      [field]: value
    }))
  }

  return (
    <Styled.Container>
      <Flex gap="lg" direction="column">
        <Input.Wrapper label="País">
          <Input
            // icon={<Send />}
            onChange={(event) =>
              handleInputChange('bankAccountCountry', event.target.value)
            }
            value={data?.bankAccountCountry}
            placeholder="Insira seu país"
            size={'lg'}
            disabled={disabled}
          />
        </Input.Wrapper>
        <Input.Wrapper label="Banco">
          <Input
            // icon={<Send />}
            onChange={(event) => handleInputChange('bank', event.target.value)}
            value={data?.bank}
            placeholder="Selecione seu banco"
            size={'lg'}
            disabled={disabled}
          />
        </Input.Wrapper>
        <Input.Wrapper label="Conta">
          <Input
            // icon={<Send />}
            onChange={(event) =>
              handleInputChange('bankAccount', event.target.value)
            }
            value={data?.bankAccount}
            placeholder="Insira o numero da sua conta"
            size={'lg'}
            disabled={disabled}
          />
        </Input.Wrapper>
        <MediaQuery
          query="(max-width: 768px)"
          styles={{ flexDirection: 'column' }}
        >
          <Flex gap="md" justify="space-between">
            <Input.Wrapper label="IBAN / NIB">
              <Input
                // icon={<Send />}
                onChange={(event) =>
                  handleInputChange('ibanNib', event.target.value)
                }
                value={data?.ibanNib}
                placeholder="0000000000000"
                size={'lg'}
                disabled={disabled}
              />
            </Input.Wrapper>
            <Input.Wrapper label="SWIFT / BIC">
              <Input
                // icon={<Send />}
                onChange={(event) =>
                  handleInputChange('swiftBic', event.target.value)
                }
                value={data?.swiftBic}
                placeholder="0000000000000"
                size={'lg'}
                disabled={disabled}
              />
            </Input.Wrapper>
          </Flex>
        </MediaQuery>
      </Flex>
    </Styled.Container>
  )
}
