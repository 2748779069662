import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  gap: 30px;

  .mantine-InputWrapper-root {
    width: 100%;
  }
  .mantine-Input-input {
    display: flex;
    padding: 12.19px 8px;
    width: 100%;
    border-radius: 6.095px;
    color: #a2a2a2;
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 54px;
  }

  .mantine-InputWrapper-label {
    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
  }

  @media (max-width: 768px) {
    & {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    div {
      width: 100%;
    }
  }
`

export const ConfirmButton = styled.button`
  display: flex;
  height: 48px;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 16px;
  background: var(--yellow-brand-primary, #27c7ff);
  color: white;
`
