import * as Styled from './styles'
import { ReactComponent as DriverImage } from './imgs/DriverImage.svg'
import { ReactComponent as CloseIcon } from './imgs/CloseIcon.svg'
import { Checkbox, Textarea } from '@mantine/core'
import { useState } from 'react'

export const RejectModal = ({ opened, onClose, onFinish, next }) => {
  const [message, setMessage] = useState('')
  const [motiveSelected, setMotiveSelected] = useState('')
  return (
    <>
      <Styled.ModalOverlay opened={opened} onClick={onClose} />
      <Styled.ModalContainer opened={opened}>
        <Styled.ModalHeader>
          <Styled.ModalTitle>Cancelamento</Styled.ModalTitle>
          <Styled.CloseButton>
            <CloseIcon onClick={onClose} />
          </Styled.CloseButton>
        </Styled.ModalHeader>
        {!next ? (
          <Styled.ModalContent>
            <Styled.Observation>
              OBS: Cancelamento só será aceito com 3 horas de antecedência.
            </Styled.Observation>
            <Styled.Motive>
              <Styled.MotiveTitle>
                Informe o motivo de cancelamento do transfer:
              </Styled.MotiveTitle>
              <Styled.Options>
                <Checkbox
                  size="lg"
                  // styles={{ label: { color: 'black' } }}
                  color="orange-primary"
                  checked={motiveSelected === 'Problemas com o automóvel'}
                  onChange={() =>
                    setMotiveSelected('Problemas com o automóvel')
                  }
                />
                <div>
                  <p>Problemas com o automóvel</p>
                </div>
              </Styled.Options>
              <Styled.Options>
                <Checkbox
                  size="lg"
                  // styles={{ label: { color: 'black' } }}
                  color="orange-primary"
                  checked={motiveSelected === 'Problemas de saúde'}
                  onChange={() => setMotiveSelected('Problemas de saúde')}
                />
                <div>
                  <p>Problemas de saúde</p>
                </div>
              </Styled.Options>
              <Styled.Options>
                <Checkbox
                  size="lg"
                  // styles={{ label: { color: 'black' } }}
                  color="orange-primary"
                  checked={motiveSelected === 'Imprevisto qualquer'}
                  onChange={() => setMotiveSelected('Imprevisto qualquer')}
                />
                <div>
                  <p>Imprevisto qualquer</p>
                </div>
              </Styled.Options>
              <Textarea
                label="Mensagem (Opcional)"
                placeholder="Descreva o motivo do cancelamento"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                size="xl"
                minRows={4}
                styles={{
                  label: {
                    color: '#222',
                    fontFamily: 'Neometric',
                    fontSize: '1.4rem',
                    fontWeight: 600,
                    marginBottom: '0.4rem'
                  },
                  input: {
                    borderColor: 'red',
                    borderRadius: '0.8rem',
                    border: '0.508px solid #BFBFBF',
                    padding: '0.8rem',
                    fontSize: '1.4rem',
                    color: '#404040',
                    fontFamily: 'Neometric',
                    ':focus': {
                      borderColor: '#27c7ff'
                    }
                  }
                }}
              />
            </Styled.Motive>
            <Styled.ModalButton
              onClick={() => onFinish(motiveSelected, message)}
            >
              ENVIAR
            </Styled.ModalButton>
          </Styled.ModalContent>
        ) : (
          <Styled.ModalContent>
            <DriverImage />
            <Styled.ModalTitle>
              Sua solicitação de cancelamento foi enviada para a administração.
            </Styled.ModalTitle>
          </Styled.ModalContent>
        )}
      </Styled.ModalContainer>
    </>
  )
}
