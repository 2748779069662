import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 220px auto 218px;
  gap: 16px;
  z-index: 20000;
  position: relative;

  button {
    border: 0 !important;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
  }

  .mantine-Carousel-controls {
    gap: 95rem;
    justify-content: center;
  }

  margin-top: 15rem;

  @media only screen and (max-width: 768px) {
    > img {
      width: 14rem;
    }

    > span {
      font-size: 1.4rem;
    }
  }
`

export const TitleContainer = styled.h1`
  margin-bottom: 7rem;
  font-size: 16px;
`
