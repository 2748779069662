import api from './api'

const fetchObj = {
  createAccount: (payload) => {
    const res = api.post('/account', payload)

    return res
  },

  updateAccount: (payload) => {
    const res = api.put('/account', payload)

    return res
  },
  getAllAccount: (payload) => {
    const res = api.get('/account', { params: payload })

    return res
  },
  getFinancialCounters: (params) => {
    const res = api.get('/account/financial-counters', { params })

    return res
  },
  getById: (id) => {
    const res = api.get(`/account/${id}`)

    return res
  },

  status: (id, active) => {
    const res = api.patch(`/account/toggle-activation/${id}/${active}`)

    return res
  },

  delete: (id) => {
    const res = api.delete(`/account/${id}`)

    return res
  }
}

export default fetchObj
