import { PAYMENT_STATUS, PAYMENT_TYPES } from '../../constants'
import { CARD_TYPE_TO_PAYMENT_TYPE } from './constants'

export const validateHistoricData = (data) => {
  return !(Number(data.amount.value.replace('.', '').replace(',', '.')) > 0)
}

export const generateCardData = (data) => {
  return {
    value: Number(data.amount.value.replace('.', '').replace(',', '.')),
    message: data.message || undefined,
    paidAt: data.date ? data.date.toISOString() : new Date().toISOString(),
    type: data.type,
    user: data.user
  }
}

export const generatePayload = (data) => {
  return {
    amount: Number(
      Number(data.amount.value.replace('.', '').replace(',', '.'))
        .toFixed(2)
        .replace('.', '')
    ),
    message: data.message || undefined,
    type: CARD_TYPE_TO_PAYMENT_TYPE[data.type],
    status: data.type === 'charge' ? 'PENDING' : 'PAID',
    user: {
      id: data.driver.id
    },
    paidAt: data.date ? data.date.toISOString() : new Date().toISOString()
  }
}

export const paymentCardTypeDecider = (type, status, role) => {
  const typeResult =
    type === PAYMENT_TYPES.PAYMENT_TO_DRIVER
      ? 'outcome'
      : type === PAYMENT_TYPES.PAYMENT_TO_MANAGEMENT &&
        status === PAYMENT_STATUS.PAID
      ? 'income'
      : 'charge'
  if ((role === 'USER' || role === 'COMPANY_USER') && typeResult === 'income')
    return 'outcome'
  if ((role === 'USER' || role === 'COMPANY_USER') && typeResult === 'outcome')
    return 'income'
  return typeResult
}
