import styled, { css } from 'styled-components'
export const Container = styled.div`
  ${({ theme }) => css`
    padding: clamp(16px, 0.833vw, 0.833vw);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .mantine-Popover-dropdown {
      width: 346px !important;
      height: 244.61px !important;

      background: #ffffff !important;
      box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.07) !important;
      border-radius: 0px 22px 22px 22px !important;
    }

    .mantine-DateTimePicker-month {
      width: 100%;
      text-align: center;
    }

    .mantine-DateTimePicker-timeWrapper {
      position: absolute;
      top: 1.5rem;
      right: 5rem;

      .mantine-TimeInput-root {
        margin-right: 0 !important;
        /* padding-right: 1rem !important; */
      }
      .mantine-TimeInput-input {
        border: 0.1rem solid #27c7ff;
        padding-right: 1rem;
        border-right: none;
        border-radius: 0.4rem 0 0 0.4rem;
      }

      .mantine-UnstyledButton-root {
        border: 0.1rem solid #27c7ff;
        border-left: none;
        border-radius: 0 0.4rem 0.4rem 0;
      }
    }

    .mantine-CalendarHeader-calendarHeaderLevel {
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 17px;
      /* identical to box height, or 106% */

      color: #38280e;
    }

    .mantine-DateTimePicker-calendarHeaderControlIcon {
      width: 2rem;
      height: 2rem;
    }

    .mantine-TimeInput-input {
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 17px;
      /* identical to box height, or 106% */

      color: #38280e;
    }

    .mantine-WeekdaysRow-weekday {
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 700;
      font-size: 9.86317px;
      line-height: 12px;
      /* identical to box height */

      color: #38280e;
    }

    .mantine-InputWrapper-root {
      height: 49.83px;
    }

    .mantine-Select-input {
      font-family: 'Neometric';
      font-style: normal;
      font-weight: 400;
      font-size: 14.7782px;
      line-height: 18px;
      color: #8a8a8a;
    }

    .mantine-Select-label h2 {
      height: 22px;
      font-family: 'Neometric';
      font-style: normal;
      font-weight: 600;
      font-size: 18.1885px;
      line-height: 22px;
      color: #0d0d0d;
    }

    .mantine-Select-dropdown {
      background: #ffffff;
      box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.07);
      border-radius: 0px 16px 16px 16px;
      border: 0;
    }

    .mantine-DateTimePicker-input {
      border: 0;
    }
    .mantine-DateTimePicker-input[data-with-icon] {
      padding: 0;
    }
    .mantine-DateTimePicker-icon {
      padding-right: 0;
      position: relative;
    }
    .mantine-DateTimePicker-label {
      font-family: 'Neometric';
      font-style: normal;
      font-weight: 600;
      font-size: 18.1885px;
      line-height: 22px;
      color: #0d0d0d;

      padding-left: 3rem;
    }

    .mantine-DateTimePicker-input span,
    .mantine-DateTimePicker-input {
      font-family: 'Neometric';
      font-style: normal;
      font-weight: 400;
      font-size: 14.7782px;
      line-height: 18px;
      /* identical to box height */

      color: #8a8a8a;
    }

    .mantine-DateTimePicker-wrapper {
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }

    .mantine-Popover-dropdown {
      background: #ffffff;
      box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.07);
      border-radius: 0px 22px 22px 22px;
      border: 0;
      display: flex;
    }

    .mantine-Input-input::placeholder {
      color: #8a8a8a;
    }

    .mantine-DateTimePicker-monthLevel {
      display: flex;
      gap: 20px;
      flex-direction: column;
    }
    .mantine-MonthLevelGroup-monthLevelGroup {
      margin: 2rem auto;
    }

    .mantine-SegmentedControl-root {
      display: flex;
      border-radius: 3px 3px 0 0;
      z-index: 10;
      align-items: center;
      padding: 0;
    }

    .mantine-SegmentedControl-label {
      padding: 8px 16px;
      color: white;
      font-size: 12px;
    }

    .mantine-SegmentedControl-control {
      background-color: ${theme.colors.primary};
    }

    .mantine-SegmentedControl-label[data-active] {
      background-color: white;
      transition: ease-in-out 300ms;
      color: black;
    }

    @media only screen and (max-width: 768px) {
      margin-bottom: 12rem;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }
  `}
`

export const TransferContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    display: flex;
    justify-content: center;
    background-color: ${theme.colors.white};
    border-radius: 22px;
    padding: 0.5rem 0.5rem 0.5rem 3rem;
    align-items: center;
    box-shadow: ${theme.shadows.minimal};
    z-index: 10;
  `}
`

export const OneTransfer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  width: 32rem;
`

export const BtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  margin-left: 3rem;
`

export const BtnPrimary = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.primary};
    padding: 30px 40px;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 600;
    box-shadow: ${theme.shadows.minimal};
  `}
`
export const BtnSecondary = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    background-color: ${theme.colors.white};
    padding: 30px 30px;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 600;
    border: 0.1px solid rgba(56, 40, 14, 0.3);
    box-shadow: ${theme.shadows.minimal};
  `}
`

export const OptionsContainer = styled.div`
  display: flex;
  position: static;
  z-index: 10;
  align-items: center;
  padding-bottom: clamp(16px, 0.833vw, 0.833vw);

  .mantine-SegmentedControl-label {
    font-family: 'Neometric';
    font-style: normal;
    font-weight: 400;
    font-size: 18.1885px;
    line-height: 22px;
    color: #ffffff;
  }

  .mantine-SegmentedControl-indicator {
    visibility: hidden;
  }

  .mantine-SegmentedControl-root {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px 3px 0 0;
    z-index: 10;

    background-color: transparent;
    flex-wrap: wrap;
    /* max-width: 100%; */
    /* overflow: auto; */
    gap: 0.4rem;
  }

  .mantine-SegmentedControl-control {
    border-radius: 0.8rem;
    height: 3.2rem;
    width: fit-content;

    .mantine-SegmentedControl-label {
      color: white;
      font-size: 1.4rem;
      padding: 0 1.6rem;
      height: 3.2rem;
      border-radius: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mantine-SegmentedControl-label[data-active] {
    background-color: white;
    transition: ease-in-out 300ms;
    color: black;

    border: 0.1rem solid #27c7ff;
    border-radius: 0.8rem;
    overflow: hidden;
    font-weight: 600;
  }

  @media only screen and (max-width: 768px) {
    bottom: 2rem;
    width: 90%;

    /* .mantine-SegmentedControl-root {
      padding-left: calc(10% + 0.4rem);
    } */

    .mantine-SegmentedControl-control {
      /* width: 9rem; */
      /* max-width: 9rem; */
      /* min-width: 9rem; */
      height: 2.6rem;
      max-width: fit-content;
      .mantine-SegmentedControl-label {
        display: block;
        font-size: 1.2rem;
        /* padding: 0; */
        height: 2.6rem;
        padding: 0 0.8rem;
      }
    }
  }

  @media only screen and (max-width: 650px) {
    .mantine-SegmentedControl-root {
      width: 90vw;
      flex-wrap: nowrap;
      overflow-x: auto;
      justify-content: flex-start;
    }
  }
`

export const SelectedOption = styled.h2`
  padding: 0 1rem;
  color: white;
`

export const PrimaryOption = styled.h2`
  padding: 0 1rem;
  background-color: white;
  border-radius: 3px 0 0 0;
  height: 100%;
  display: flex;
  align-items: center;
`
