import React from 'react'
import ChatStoreProvider from './context/ChatContext'

import { ChatContainer } from './components'
import { ChatsList } from './components/ChatsList'

import * as Styled from './styles'

function ChatPage() {
  return (
    <>
      <Styled.Container>
        <Styled.Header>
          <Styled.Title>Chat</Styled.Title>
        </Styled.Header>

        <Styled.ContentWrapper>
          <ChatContainer />
          <ChatsList />
        </Styled.ContentWrapper>
      </Styled.Container>
    </>
  )
}

export function Chat() {
  return (
    <ChatStoreProvider>
      <ChatPage />
    </ChatStoreProvider>
  )
}
