import styled from 'styled-components'

import {
  Carousel as UICarousel,
  CarouselContent as UICarouselContent,
  CarouselItem as UICarouselItem,
  CarouselNext as UICarouselNext,
  CarouselPrevious as UICarouselPrevious
} from '../../../../components/Carousel'

export const Carousel = styled(UICarousel)``

export const CarouselContent = styled(UICarouselContent)`
  padding-left: 1.2rem;
`

export const CarouselItem = styled(UICarouselItem)`
  > div:first-of-type {
    width: 100%;
    height: 45.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #09090b;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: contain;

    > div:first-of-type {
      width: 100%;
      height: 100%;
      max-width: 144rem;
      padding: 0 4rem;

      > div:first-of-type {
        margin-bottom: 4rem;
        margin-top: 18rem;
        display: flex;
        justify-content: space-between;
      }

      /* > div:last-of-type {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
        color: #ffffff;

        h1 {
          font-weight: 600;
          font-size: 2.4rem;
          line-height: 3.2rem;
        }

        > span {
          font-weight: 600;
          font-size: 1.6rem;
        }

        > div {
          display: flex;
          align-items: center;
          gap: 0.4rem;

          span:first-of-type {
            font-weight: 600;
            font-size: 2rem;
            line-height: 2.8rem;
          }

          span:last-of-type {
            margin-top: auto;
            font-size: 1.4rem;
            line-height: 2rem;
          }
        }
      } */
    }

    > div:last-of-type {
      margin-top: -5.6rem;
      height: 12.8rem;
      width: 100%;
      background-color: transparent;

      > div {
        /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.06); */
        background-image: linear-gradient(to top, #fff 0%, transparent 70%);
        height: 102%;
        width: 100%;
      }
    }
  }

  > div:last-of-type {
    margin: 0 auto;
    margin-top: 3.2rem;
    max-width: 144rem;
    padding: 0 4rem;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4rem;

      > div:first-of-type {
        display: flex;
        flex-direction: column;
        gap: 4.8rem;

        > div:first-of-type {
          h2 {
            font-weight: 600;
            color: #27c7ff;
            font-size: 3rem;
            line-height: 3.6rem;
            margin-bottom: 0.8rem;
          }

          strong {
            display: block;
            margin-bottom: 1.6rem;
            font-size: 2.4rem;
            font-weight: 600;
            color: #222222;
          }

          p {
            overflow-wrap: break-word;
            font-size: 1.4rem;
            line-height: 2rem;
            color: #404040;
          }
        }

        > div:last-of-type {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          gap: 2.4rem;
        }
      }

      > div:last-of-type {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        width: 100%;
      }
    }
  }

  @media (min-width: 1024px) {
    > div:first-of-type {
      > div:first-of-type {
        padding: 0 16rem;

        > div:last-of-type {
          gap: 1.2rem;

          h1 {
            font-size: 3.6rem;
            line-height: 4rem;
          }

          > span {
            font-size: 2.4rem;
            line-height: 3.2rem;
          }

          > div {
            span:first-of-type {
              font-size: 3.6rem;
              line-height: 4rem;
            }

            span:last-of-type {
              font-size: 2.4rem;
              line-height: 3.2rem;
            }
          }
        }
      }
    }

    > div:last-of-type {
      padding: 0 16rem;

      > div {
        flex-direction: row;
        align-items: flex-start;
        gap: 2.4rem;

        > div:first-of-type {
          width: 66.666667%;
        }

        > div:last-of-type {
          display: none;
          width: auto;
          flex: 1 1 0%;
        }
      }
    }
  }
`

export const StickyParent = styled.div`
  position: absolute;
  top: 18rem;
  bottom: 0;
  right: 3.2rem;
  height: 100%;
  display: flex;
  width: fit-content;
  padding-bottom: 3.2rem;
  pointer-events: none;

  > div {
    position: sticky;
    top: 11.2rem;
    margin-top: 5.2rem;
    height: fit-content;
    pointer-events: all;

    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  @media screen and (max-width: 1023px) {
    display: none;
  }
`

export const CarouselNext = styled(UICarouselNext)`
  position: static;
`

export const CarouselPrevious = styled(UICarouselPrevious)`
  position: static;
`
