import FirstImage from './imgs/image1.webp'
import SecondImage from './imgs/image2.webp'
import ThirdImage from './imgs/image3.webp'

export const toursList = [
  {
    image: FirstImage,
    title: 'Lisbon Contemporary Architecture',
    rate: 4.5,
    duration: '4 horas',
    price: 40
  },
  {
    image: SecondImage,
    title: 'Lisbon Contemporary Architecture',
    rate: 4.5,
    duration: '4 horas',
    price: 40
  },
  {
    image: ThirdImage,
    title: 'Lisbon Contemporary Architecture',
    rate: 4.5,
    duration: '4 horas',
    price: 40
  }
]
