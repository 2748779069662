import { ArrowDownLeft, ArrowUpRight, HelpingHand } from 'lucide-react'
import { PAYMENT_TYPES } from '../../constants'

export const iconColorDecider = {
  income: '#48D9A4',
  outcome: '#F8294E',
  charge: '#404040'
}
export const iconBackgroundDecider = {
  income: '#d5f6ea',
  outcome: '#fdced6',
  charge: '#bfbfbf'
}
export const typeLabelDecider = {
  income: 'Recebeu',
  outcome: 'Pagou',
  charge: 'Cobrou'
}
export const iconDecider = {
  income: <ArrowDownLeft size="1.8rem" color={iconColorDecider.income} />,
  outcome: <ArrowUpRight size="1.8rem" color={iconColorDecider.outcome} />,
  charge: <HelpingHand size="1.8rem" color={iconColorDecider.charge} />
}
export const DETAILS_MODAL_INITIAL_STATE = {
  isOpen: false,
  data: undefined
}
export const CARD_TYPE_TO_PAYMENT_TYPE = {
  income: PAYMENT_TYPES.PAYMENT_TO_MANAGEMENT,
  outcome: PAYMENT_TYPES.PAYMENT_TO_DRIVER,
  charge: PAYMENT_TYPES.PAYMENT_TO_MANAGEMENT
}
