import * as Styled from './styles'
import FirstIcon from '../imgs/icon-1.webp'
import SecondIcon from '../imgs/icon-2.webp'
import ThirdIcon from '../imgs/icon-3.webp'
import FourthIcon from '../imgs/icon-4.webp'
import FifthIcon from '../imgs/icon-5.webp'
import SixthIcon from '../imgs/icon-6.webp'
import SeventhIcon from '../imgs/icon-7.webp'
import EighthIcon from '../imgs/icon-8.webp'

const images = [
  FirstIcon,
  SecondIcon,
  ThirdIcon,
  FourthIcon,
  FifthIcon,
  SixthIcon,
  SeventhIcon,
  EighthIcon
]

export const TrustCarousel = () => {
  // const getStars = (rating) => {
  //   const stars = new Array(rating).fill(start)
  //   return stars.map((star, index) => <img key={index} src={star} alt="star" />)
  // }

  // const items = images.map((item, index) => (
  //   <Styled.CardContainer className="item" data-value={index} key={index}>
  //     <Styled.Header>
  //       <img src={photoUser} alt="user" />
  //       <Styled.TextContainer>
  //         <h1>{item.name}</h1>
  //         <span>{item.date}</span>
  //       </Styled.TextContainer>
  //       <Styled.ImageGoogle src={googleImage} alt="google" />
  //     </Styled.Header>
  //     <div>{getStars(item.rating)}</div>
  //     <p>{item.description}</p>
  //   </Styled.CardContainer>
  // ))

  return (
    <Styled.Container>
      <Styled.InnerContainer>
        <Styled.Content>
          {images.map((item, index) => (
            <Styled.CardContainer data-value={index} key={index}>
              <img src={item} alt="user" />
            </Styled.CardContainer>
          ))}
          {images.map((item, index) => (
            <Styled.CardContainer data-value={index} key={index}>
              <img src={item} alt="user" />
            </Styled.CardContainer>
          ))}
        </Styled.Content>
      </Styled.InnerContainer>
    </Styled.Container>
  )
}
