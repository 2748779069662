import styled from 'styled-components'

export const DestinationContainer = styled.div`
  display: flex;
  max-width: 840px;
  align-items: flex-start;
  gap: 16px;
`
export const LocationContainer = styled.div`
  flex: 1;

  label {
    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
  }

  input[type='text'] {
    height: 54px;
    width: 100%;
    padding: 12.19px 35px;
    align-items: center;

    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    border-radius: 8px;
    border: 0.508px solid var(--grey-3-grayscale, #bfbfbf);
  }
`
export const DistanceContainer = styled.div`
  display: flex;
  padding-bottom: 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`

export const DistanceLabel = styled.h1`
  color: var(--yellow-3-support, #1D95BF);
  font-family: Neometric;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.18px;
`

export const ArrowContainer = styled.div`
  display: flex;
  width: 54px;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 8px;
  background: var(--yellow-2-support, #DFF7FF);
`
