import { useState } from 'react'
import * as Styled from '../../styles'
import { ChevronDown } from 'lucide-react'
import { Link, useLocation } from 'react-router-dom'

export const ExpandableLink = ({ title, subItems = [] }) => {
  const [showSubItems, setShowSubItems] = useState(false)

  const { pathname } = useLocation()
  return (
    <Styled.ExpandableContainer
      onMouseEnter={() => setShowSubItems(true)}
      onMouseLeave={() => setShowSubItems(false)}
    >
      <Styled.Link>
        {title}
        <ChevronDown size={14} />
      </Styled.Link>

      {showSubItems && (
        <Styled.ExpandableItemsWrapper dark={pathname === '/corporate'}>
          <div>
            <ul>
              {subItems.map(({ title, to, blank }) =>
                blank ? (
                  <li key={title}>
                    <a
                      href={to}
                      // target="_blank"
                      style={{ textDecoration: 'none', color: 'inherit' }}
                      rel="noreferrer"
                    >
                      {title}
                    </a>
                  </li>
                ) : (
                  <li key={title}>
                    <Link
                      to={to}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      {title}
                    </Link>
                  </li>
                )
              )}
            </ul>
          </div>
        </Styled.ExpandableItemsWrapper>
      )}
    </Styled.ExpandableContainer>
  )
}
