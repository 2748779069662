import { BadgeEuro, LineChart } from 'lucide-react'
import * as Styled from './styles'
import { formatCurrency } from '../../../../../../utils/formatCurrency'

export const PartnerCounters = ({
  options,
  setValue,
  value,
  total = 0,
  netTotal = 0,
  doneBookings = 0
}) => {
  return (
    <Styled.Container>
      <Styled.Options>
        <Styled.Option>
          <Styled.Header>
            <Styled.OptionTitle>TOTAL</Styled.OptionTitle>
            <BadgeEuro color="#BFBFBF" />
          </Styled.Header>
          <Styled.OptionContent>
            <Styled.OptionValue>{formatCurrency(total)}</Styled.OptionValue>
            <Styled.OptionUpdate>Últimos 3 dias</Styled.OptionUpdate>
          </Styled.OptionContent>
        </Styled.Option>
        <Styled.Option>
          <Styled.Header>
            <Styled.OptionTitle>TOTAL LÍQUIDO PARTNER</Styled.OptionTitle>
            <BadgeEuro color="#BFBFBF" />
          </Styled.Header>
          <Styled.OptionContent>
            <Styled.OptionValue>{formatCurrency(netTotal)}</Styled.OptionValue>
            <Styled.OptionUpdate>Últimos 3 dias</Styled.OptionUpdate>
          </Styled.OptionContent>
        </Styled.Option>
        <Styled.Option>
          <Styled.Header>
            <Styled.OptionTitle>TRANSFERS REALIZADOS</Styled.OptionTitle>
            <LineChart color="#BFBFBF" />
          </Styled.Header>
          <Styled.OptionContent>
            <Styled.OptionValue>{doneBookings}</Styled.OptionValue>
            <Styled.OptionUpdate>Últimos 3 dias</Styled.OptionUpdate>
          </Styled.OptionContent>
        </Styled.Option>
      </Styled.Options>
    </Styled.Container>
  )
}
