import fetchFile from '../../../../../services/file'

export const uploadFile = async (image) => {
  try {
    const response = await fetchFile.create(image)
    console.log('response uploadfile', response)
    if (response.status === 200 || response.status === 201) {
      const uploadedFile = response.data

      return [null, uploadedFile]
    }

    return ['Não foi possível salvar a imagem']
  } catch (error) {
    return [error.toString()]
  }
}
