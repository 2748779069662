import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  ChevronContainer,
  Container,
  MainText,
  Question,
  QuestionContent,
  QuestionsWrapper,
  SubText,
  TextWrapper,
  ImageContainer,
  TextAndImageContainer
} from './styles'
import { Accordion, createStyles } from '@mantine/core'
import { ReactComponent as SumSymbol } from '../../../../assets/sum_symbol.svg'
import { ReactComponent as Rectangle } from './imgs/Rectangle12316.svg'
import image from './imgs/Motorista-FAQ copiar2.webp'
import { stagger, useAnimate } from 'framer-motion'
import { useIsInViewport } from '../../../../hooks/useIsInViewport'

export default function Faq({ accordionData }) {
  const listRef = React.useRef(null)
  const [alreadyVisibleList, setAlreadyVisibleList] = React.useState(false)

  const { t } = useTranslation()
  const [scope, animate] = useAnimate()
  const isInViewPort = useIsInViewport(listRef)

  const staggerMenuItems = stagger(0.1, { startDelay: 0.3 })

  React.useEffect(() => {
    if (alreadyVisibleList) return

    if (isInViewPort) setAlreadyVisibleList(true)

    animate(
      '.mantine-Accordion-item',
      isInViewPort
        ? { opacity: 1, transform: 'translateX(0)' }
        : { opacity: 0, transform: 'translateX(30px)' },
      {
        duration: 0.2,
        delay: isInViewPort ? staggerMenuItems : 0
      }
    )
  }, [isInViewPort, alreadyVisibleList])

  const useStyles = createStyles(() => ({
    chevron: {
      // '&[data-rotate]': {
      //   transform: 'rotate(-90deg)'
      // },
      backgroundColor: '#27c7ff',
      marginRight: 0,
      width: 'auto',
      padding: '8px',
      borderRadius: ' 0 6px 6px 0'
    },
    label: {
      padding: 0
    },
    control: {
      paddingRight: 0
    },
    item: {
      borderRadius: '6px',
      border: '0.5px solid #2b2b2b',
      borderTop: '0.5px solid #2b2b2b !important',
      ':first-of-type': {
        borderRadius: '6px'
      },
      ':last-of-type': {
        borderRadius: '6px'
      }
    },
    root: {
      gap: '10px',
      display: 'flex',
      flexDirection: 'column'
    }
  }))

  const { classes } = useStyles()

  const items = accordionData.map((item) => (
    <Accordion.Item value={item.title} key={item.title}>
      <Accordion.Control>
        <Question>{item.title}</Question>
      </Accordion.Control>
      <Accordion.Panel>
        <QuestionContent>{item.content}</QuestionContent>
      </Accordion.Panel>
    </Accordion.Item>
  ))

  return (
    <Container ref={scope}>
      <TextAndImageContainer>
        <TextWrapper>
          <MainText>{t('faq.title')}</MainText>
          <SubText>{t('faq.subtitle')}</SubText>
        </TextWrapper>
        <ImageContainer>
          <img src={image} alt="foto" />
          <Rectangle />
        </ImageContainer>
      </TextAndImageContainer>

      <QuestionsWrapper ref={listRef} className="accordion">
        <Accordion
          classNames={classes}
          className={classes.root}
          chevron={
            <ChevronContainer>
              <SumSymbol />
            </ChevronContainer>
          }
          chevronPosition="right"
          variant="contained"
          disableChevronRotation
        >
          {items}
        </Accordion>
      </QuestionsWrapper>
    </Container>
  )
}
