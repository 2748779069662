import {
  Avatar,
  Badge,
  Table,
  Group,
  Text,
  ActionIcon,
  Anchor,
  rem,
  Checkbox
} from '@mantine/core'
import { IconPencil, IconTrash } from '@tabler/icons-react'
import * as Styled from './styles'
import { Copy, Pen, Trash2 } from 'lucide-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useClipboard } from '@mantine/hooks'
import { toast } from 'react-toastify'
import { useAuth } from '../../contexts/useAuth'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { useTranslation } from 'react-i18next'

const jobColors = {
  engineer: 'blue',
  manager: 'cyan',
  designer: 'pink'
}

export function DataTable({
  data,
  setDelete,
  selection,
  setSelection,
  partnership,
  type
}) {
  const params = useParams()
  const { ROLE } = useAuth()
  const toggleRow = (id) =>
    setSelection((current) =>
      current.includes(id)
        ? current.filter((item) => item !== id)
        : [...current, id]
    )
  const toggleAll = () =>
    setSelection((current) =>
      current?.length === data?.length ? [] : data.map((item) => item.id)
    )

  const { t } = useTranslation()

  const clipboard = useClipboard({ timeout: 500 })
  const isMobile = useMediaQuery('(max-width: 500px)')

  const navigate = useNavigate()

  const rows = data?.map((item) => {
    const selected = selection?.includes(item.id)

    const handleRowClick = (event) => {
      const isCheckbox = event.target.type === 'checkbox'
      const isActionIcon =
        event.target.closest('.action-icon') ||
        event.target.closest('.action-icon-svg')

      if (isCheckbox || isActionIcon) {
        return
      }
      if (ROLE === 'COMPANY_ADMIN' || ROLE === 'HOTEL_USER') {
        return
      }
      if (type === 'driver') {
        navigate(`driver/${item.id}`)
      } else if (type === 'enterprise') {
        navigate(`empresa/${item.id}`)
      } else if (type === 'driver-enterprise') {
        navigate(`/dashboard/frota/empresa/${params.id}/motorista/${item.id}`)
      } else if (type === 'user-enterprise') {
        navigate(`/dashboard/frota/empresa/${params.id}/usuario/${item.id}`)
      } else if (type === 'partnership') {
        navigate(`/dashboard/partnership/${params.id}/account/${item.id}`)
      } else {
        navigate(`${item.id}`)
      }
    }

    return (
      <tr
        key={item.name}
        className={selected ? 'selected' : ''}
        style={{ cursor: 'pointer' }}
        onClick={(event) => handleRowClick(event)}
      >
        <td>
          <Checkbox
            color="yellow"
            checked={selection?.includes(item.id)}
            onChange={() => toggleRow(item.id)}
          />
        </td>
        <td>
          <Group gap="sm">
            <Avatar
              size={32}
              src={partnership ? item?.image?.url : item?.profilePicture?.url}
              radius={30}
            />
            <Text fz="sm" fw={500}>
              {item.name}
            </Text>
          </Group>
        </td>

        <td>
          <Text fz="sm">
            {item.id.length > 8 ? item.id.slice(0, 8) + '...' : item.id}{' '}
            <Copy
              size="16"
              style={{ cursor: 'pointer' }}
              alt="Copy id"
              onClick={(e) => {
                e.stopPropagation()
                try {
                  clipboard.copy(item.id)
                  toast.success(t('toast.copiedID'))
                } catch {
                  toast.error(t('errorCopiedID'))
                }
              }}
            />
          </Text>
        </td>
        <td>
          <Text fz="sm">{item.deleted ? 'Inativo' : 'Ativo'}</Text>
        </td>
        {item.role === 'USER' && (
          <td>
            <Anchor component="button" size="sm">
              {item?.vehicle?.category?.name}
            </Anchor>
          </td>
        )}
        {!isMobile && (
          <>
            <td>
              <Anchor component="button" size="sm">
                {item.email}
              </Anchor>
            </td>
            <td>
              <Text fz="sm">{item.phoneNumber}</Text>
            </td>
          </>
        )}
        {ROLE !== 'COMPANY_ADMIN' && ROLE !== 'HOTEL_USER' && (
          <td>
            <Group gap={0} justify="flex-end">
              <ActionIcon
                variant="subtle"
                color="gray"
                className="action-icon"
                onClick={() => {
                  if (type === 'driver') {
                    navigate(`driver/${item.id}`)
                  } else if (type === 'enterprise') {
                    navigate(`empresa/${item.id}`)
                  } else if (type === 'driver-enterprise') {
                    navigate(`motorista/${item.id}`)
                  } else if (type === 'user-enterprise') {
                    navigate(`usuario/${item.id}`)
                  } else if (type === 'partnership') {
                    navigate(`account/${item.id}`)
                  } else {
                    navigate(`${item.id}`)
                  }
                }}
              >
                <Pen width={24} height={24} color="#27c7ff" />
              </ActionIcon>
              {}
              <ActionIcon
                onClick={() => setDelete(item)}
                className="action-icon"
                variant="subtle"
                color="red"
              >
                <Trash2 width={24} height={24} color="#27c7ff" />
              </ActionIcon>
            </Group>
          </td>
        )}
      </tr>
    )
  })

  const dataTableRole = data?.some((item) => item.role === 'USER')

  return (
    <Styled.Container>
      <Table verticalSpacing="sm">
        <thead>
          <tr>
            <th>
              <Checkbox
                onChange={toggleAll}
                checked={selection?.length === data?.length}
                indeterminate={
                  selection?.length > 0 && selection?.length !== data?.length
                }
                color="yellow"
              />
            </th>
            <th>Nome</th>
            <th>ID</th>
            <th>Status</th>
            {dataTableRole && <th>Categoria</th>}
            {!isMobile && (
              <>
                <th>Email</th>
                <th>Phone</th>
              </>
            )}
            {ROLE !== 'COMPANY_ADMIN' && ROLE !== 'HOTEL_USER' && (
              <th>Ações</th>
            )}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </Styled.Container>
  )
}
