import * as Styled from './styles'
import { ReactComponent as DriverImage } from './imgs/DriverImage.svg'
import { ReactComponent as CloseIcon } from './imgs/CloseIcon.svg'
import { useAuth } from '../../../../../../contexts/useAuth'
import { SelectComponent } from '../../../../../../components/ReserveComponent/components/SelectComponent'
import { useEffect, useState } from 'react'
import fetchAccount from '../../../../../../services/account'
import { Checkbox, Input } from '@mantine/core'
import Search from '../../../../../../assets/Search.webp'

const REPORT_OPTIONS = [
  {
    label: 'Hóspede não realizou o transfer',
    value: 'NOT_REALIZED_BY_PASSENGER'
  },
  {
    label: 'Motorista não realizou o transfer',
    value: 'NOT_REALIZED_BY_DRIVER'
  },
  { label: 'Erro no aplicativo', value: 'APP_ERROR' }
]

export const SendTransferModal = ({ opened, onClose, onSuccess }) => {
  const [selectedOption, setSelectedOption] = useState('')
  return (
    <>
      <Styled.ModalOverlay
        opened={opened}
        onClick={() => {
          onClose()
        }}
      />
      <Styled.ModalContainer opened={opened}>
        <Styled.ModalHeader>
          <h2>Reportar</h2>
          <Styled.CloseButton>
            <CloseIcon
              onClick={() => {
                onClose()
              }}
            />
          </Styled.CloseButton>
        </Styled.ModalHeader>
        <Styled.ModalContent>
          <strong>Reporte algum acontecimento:</strong>
          <ul>
            {REPORT_OPTIONS.map((reportOption) => (
              <li key={reportOption.value}>
                <Checkbox
                  size="lg"
                  checked={selectedOption === reportOption.value}
                  // styles={{ label: { color: 'black' } }}
                  color="orange-primary"
                  onChange={() =>
                    setSelectedOption((oldState) =>
                      oldState === reportOption.value ? '' : reportOption.value
                    )
                  }
                  label={reportOption.label}
                />
              </li>
            ))}
          </ul>
        </Styled.ModalContent>
        <Styled.ModalButton
          disabled={!selectedOption}
          onClick={() => onSuccess(selectedOption)}
        >
          ENVIAR
        </Styled.ModalButton>
      </Styled.ModalContainer>
    </>
  )
}
