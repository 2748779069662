import {
  Card,
  CardDescription,
  CardTitle,
  ContainerCards,
  ContainerIcon
} from './styles'

export default function CardThings({ data }) {
  return (
    <ContainerCards>
      {data.map((item, index) => {
        const Icon = item.icon

        return (
          <Card key={index}>
            <ContainerIcon>{Icon}</ContainerIcon>

            <CardTitle>{item?.title}</CardTitle>

            <CardDescription>{item?.description}</CardDescription>
          </Card>
        )
      })}
    </ContainerCards>
  )
}
