import { Flex, Input } from '@mantine/core'
import * as Styled from './styles'

export const HouseHold = ({ data, setData, disabled }) => {
  const handleInputChange = (field, value) => {
    setData((prevValues) => ({
      ...prevValues,
      address: {
        ...prevValues.address,
        [field]: value
      }
    }))
  }

  return (
    <Styled.Container>
      <Flex gap="lg" direction="column">
        <Input.Wrapper label="Morada">
          <Input
            // icon={<Send />}
            onChange={(event) =>
              handleInputChange('street', event.target.value)
            }
            value={data?.address?.street}
            placeholder="Insira sua morada"
            size={'lg'}
            disabled={disabled}
          />
        </Input.Wrapper>
        <Input.Wrapper label="Província">
          <Input
            // icon={<Send />}
            onChange={(event) => handleInputChange('state', event.target.value)}
            value={data?.address?.state}
            placeholder="Insira sua província"
            size={'lg'}
            disabled={disabled}
          />
        </Input.Wrapper>
        <Input.Wrapper label="Código postal">
          <Input
            // icon={<Send />}
            onChange={(event) =>
              handleInputChange('postalCode', event.target.value)
            }
            value={data?.address?.postalCode}
            placeholder="0000-000"
            size={'lg'}
            disabled={disabled}
          />
        </Input.Wrapper>
      </Flex>
    </Styled.Container>
  )
}
