import styled from 'styled-components'

export const Container = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 0.5px solid #bfbfbf;
  width: fit-content;

  width: ${({ width }) => width}px;
`

export const Content = styled.div``

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  margin-bottom: 8px;
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  padding: 2px;

  border-radius: 8px;
  background: var(--yellow-brand-primary, #27c7ff);

  & svg {
    transform: scale(0.8);
  }
`

export const Title = styled.p`
  color: #404040;
  font-family: Neometric;
  font-size: 18px;
  font-weight: 700;
`
