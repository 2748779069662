export const column = [
  {
    title: 'ID Transfer'
  },
  {
    title: 'Data / Hora'
  },
  {
    title: 'Partida → Chegada'
  },
  {
    title: 'Categoria'
  },
  {
    title: 'Valor cobrado'
  },
  {
    title: 'Items'
  },
  {
    title: 'Ações'
  }
]
