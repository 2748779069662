import { ShieldCheck } from 'lucide-react'

import offerImg from '../../imgs/oque-nos-oferecemos.webp'

import * as Styled from './styles'
import { useTranslation } from 'react-i18next'

export const NumbersSection = () => {
  const { t } = useTranslation()
  return (
    <Styled.Wrapper>
      <ShieldCheck />

      <h2
        dangerouslySetInnerHTML={{
          __html: t('corporate.convenientSafePremiumTransport')
        }}
      />
      <p>{t('corporate.enjoySmoothLuxuriousTravel')}</p>

      <img src={offerImg} alt="" />

      <div>
        <div>
          <span>+102</span>
          <div>
            <h5>{t('corporate.drivers')}</h5>
            <p>{t('corporate.driversTrainedSelectedCertified')}</p>
          </div>
        </div>

        <div>
          <span>+387</span>
          <div>
            <h5>{t('corporate.events')}</h5>
            <p>{t('corporate.successfulEventTravel')}</p>
          </div>
        </div>

        <div>
          <span>+99%</span>
          <div>
            <h5>{t('corporate.satisfaction')}</h5>
            <p>{t('corporate.happyCustomers')}</p>
          </div>
        </div>
      </div>
    </Styled.Wrapper>
  )
}
