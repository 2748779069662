import { createContext, useContext, useState, useMemo } from 'react'

export const TourContext = createContext({})

export function TourProvider({ children }) {
  const [data, setData] = useState([])
  const [citySelected, setCitySelected] = useState(null)
  const [selected, setSelected] = useState(null)
  console.log(selected, 'SELECTED')
  const tours = useMemo(() => {
    return selected?.attributes?.tours?.data
  }, [selected])

  return (
    <TourContext.Provider
      value={{
        data,
        setData,
        citySelected,
        setCitySelected,
        selected,
        setSelected,
        tours
      }}
    >
      {children}
    </TourContext.Provider>
  )
}

export function useTour() {
  const context = useContext(TourContext)

  return context
}
