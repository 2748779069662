import * as React from 'react'

import {
  ChevronContainer,
  Container,
  Question,
  QuestionContent,
  QuestionsWrapper
} from './styles'
import { Accordion, createStyles } from '@mantine/core'
import { ReactComponent as SumSymbol } from '../../../../assets/sum_symbol.svg'
import { stagger, useAnimate } from 'framer-motion'
import { useIsInViewport } from '../../../../hooks/useIsInViewport'
import { useTranslation } from 'react-i18next'

export default function Faq() {
  const listRef = React.useRef(null)
  const [alreadyVisibleList, setAlreadyVisibleList] = React.useState(false)

  const [scope, animate] = useAnimate()
  const isInViewPort = useIsInViewport(listRef)

  const { t } = useTranslation()

  const staggerMenuItems = stagger(0.1, { startDelay: 0.3 })

  React.useEffect(() => {
    if (alreadyVisibleList) return

    if (isInViewPort) setAlreadyVisibleList(true)

    animate(
      '.mantine-Accordion-item',
      isInViewPort
        ? { opacity: 1, transform: 'translateX(0)' }
        : { opacity: 0, transform: 'translateX(30px)' },
      {
        duration: 0.2,
        delay: isInViewPort ? staggerMenuItems : 0
      }
    )
  }, [isInViewPort, alreadyVisibleList])

  const accordionData = [
    {
      title: t('termsAndConditions.informationAndDataProcessedAndLegalBasis'),
      content: t('termsAndConditions.dataProcessingOperations'),
      index: 1
    },
    {
      title: t('termsAndConditions.creditCardAndPersonalInformationProtection'),
      content: t('termsAndConditions.dataCollected'),
      index: 2
    },
    {
      title: t('termsAndConditions.exerciseOfDataSubjectRights'),
      content: t('termsAndConditions.userRights'),
      index: 3
    },
    {
      title: t('termsAndConditions.exerciseOfDataSubjectRights_1'),
      content: t('termsAndConditions.privacyPolicyChanges'),
      index: 4
    },
    {
      title: t('termsAndConditions.exerciseOfDataSubjectRights_2'),
      content: t('termsAndConditions.applicableLawAndJurisdiction'),
      index: 5
    }
  ]

  const useStyles = createStyles(() => ({
    chevron: {
      // '&[data-rotate]': {
      //   transform: 'rotate(-90deg)'
      // },
      backgroundColor: '#27c7ff',
      marginRight: 0,
      width: 'auto',
      padding: '8px',
      borderRadius: ' 0 6px 6px 0'
    },
    label: {
      padding: 0
    },
    control: {
      paddingRight: 0
    },
    item: {
      borderRadius: '6px',
      border: '0.5px solid #38280E4D',
      borderTop: '0.5px solid #38280E4D !important',
      ':first-of-type': {
        borderRadius: '6px'
      },
      ':last-of-type': {
        borderRadius: '6px'
      }
    },
    root: {
      gap: '10px',
      display: 'flex',
      flexDirection: 'column'
    },
    panel: {
      padding: '1.6rem 2rem 1.6rem'
    }
  }))

  const { classes } = useStyles()

  const items = accordionData.map((item) => (
    <Accordion.Item value={item.title} key={item.title}>
      <Accordion.Control>
        <Question>{item.title}</Question>
      </Accordion.Control>
      <Accordion.Panel>
        <QuestionContent dangerouslySetInnerHTML={{ __html: item.content }} />
      </Accordion.Panel>
    </Accordion.Item>
  ))

  return (
    <Container ref={scope}>
      <QuestionsWrapper ref={listRef} className="accordion">
        <Accordion
          classNames={classes}
          className={classes.root}
          chevron={
            <ChevronContainer>
              <SumSymbol />
            </ChevronContainer>
          }
          chevronPosition="right"
          variant="contained"
          disableChevronRotation
        >
          {items}
        </Accordion>
      </QuestionsWrapper>
    </Container>
  )
}
