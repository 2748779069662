import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;

  width: 85%;
  margin: 2rem auto;
`
export const ImageCar = styled.img`
  width: 70%;
  max-width: 180px;
`

export const Title = styled.h1``

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const Passenger = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  img {
    width: 16.29px;
  }

  p {
    background-color: rgba(226, 226, 226, 0.4);
    border: 0.509009px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 4.07207px 6.10811px rgba(0, 0, 0, 0.07);
    border-radius: 69px;
    width: 22px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding-top: 2px;
  }
`

export const Briefcase = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  img {
    width: 24.43px;
  }
  p {
    background-color: rgba(226, 226, 226, 0.4);
    border: 0.509009px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 4.07207px 6.10811px rgba(0, 0, 0, 0.07);
    border-radius: 69px;
    width: 22px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding-top: 2px;
  }
`

export const Wifi = styled.img``

export const CarContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.05em;

      color: #38280e;
    }
  }
`

export const ChoiceButton = styled.button`
  font-family: 'Neometric';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  cursor: pointer;

  text-align: center;
  text-transform: uppercase;

  color: #ffffff;

  background: #27c7ff;
  box-shadow: 0px 4px 8px rgba(223, 247, 255, 0.5);
  border-radius: 8px;
  padding: 12px 32px;
`

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 1rem;

  h1 {
    font-family: 'Neometric';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;

    color: #38280e;
  }

  p {
    font-family: 'Neometric';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    text-align: center;

    color: #a0a0a0;
  }
`

export const CardContainer = styled.div`
  position: relative;
  display: flex;
  width: 40%;
  min-width: 370px;
  justify-content: space-between;

  box-shadow: 0px 18.0481px 19.6888px rgba(0, 0, 0, 0.05);
  border: 0.820368px solid rgba(39, 199, 255, 0.2);
  background: #ffff;
  padding: 3rem 2rem;
  border-radius: 16.4074px;

  height: 280px;

  @media only screen and (max-width: 894px) {
    width: 90%;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    min-width: 0;
    padding: 1.6rem;
  }

  @media only screen and (max-width: 490px) {
    height: 200px;

    ${ImageCar} {
      min-width: 10rem;
      max-width: 12rem;
    }

    ${Title} {
      font-size: 1.4rem;
      font-weight: 500;
    }

    ${ChoiceButton} {
      padding: 0;
      width: 12rem;
      height: 4.8rem;
      font-size: 1.4rem;
      border-radius: 0.8rem;
    }

    ${OptionsContainer} {
      gap: 0.8rem;

      ${Passenger} {
        img {
          width: 1.4rem;
        }

        p {
          font-size: 1.2rem;
          width: 2rem;
          height: 2rem;
        }
      }

      ${Briefcase} {
        img {
          width: 2rem;
        }

        p {
          font-size: 1.2rem;
          width: 2rem;
          height: 2rem;
        }
      }

      > img {
        width: 1.8rem;
      }
    }

    ${CarContainer} {
      > div {
        gap: 0.8rem;
      }
    }
  }

  @media only screen and (max-width: 320px) {
    height: 180px;

    ${ChoiceButton} {
      height: 4.4rem;
      width: 9rem;
      font-size: 1.2rem;
    }
  }
`

export const ImageTooltip = styled.img`
  position: absolute;
  right: 3rem;
  bottom: 1.5rem;
`
