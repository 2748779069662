import React, { useState } from 'react'
import * as Styled from './styles'
import { TopBar } from './components/TopBar'
import { TransferBar } from './components/TransferBar'
import { useEffect } from 'react'
import fetchOrderBump from '../../../../services/orderBump'
import { toast } from 'react-toastify'
import { LoadingOverlay } from '@mantine/core'
import { useTranslation } from 'react-i18next'

export function OrderBump() {
  const [loading, setLoading] = useState(false)
  const [orderBumpList, setOrderBumpList] = useState([])

  const { t } = useTranslation()

  const getOrderBumpList = async () => {
    try {
      setLoading(true)
      const response = await fetchOrderBump.getAllOrderBump()
      if (response.status === 200) {
        setOrderBumpList(response.data.content)
      }
    } catch (error) {
      toast.error(t('toast.notPossibleToLoadList'))
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getOrderBumpList()
  }, [])

  return (
    <>
      <LoadingOverlay overlayOpacity={0.5} visible={loading} />
      <Styled.Container>
        <TopBar />
        {orderBumpList.length === 0 && (
          <div>Nenhum order bump encontrado no momento</div>
        )}
        {orderBumpList.map((order, index) => (
          <TransferBar transferInfo={order} key={order.id} index={index} />
        ))}
      </Styled.Container>
    </>
  )
}
