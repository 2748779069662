import * as Styled from './styles'
import { ActionIcon, Group, NumberInput } from '@mantine/core'
import MinusBtn from './imgs/minus-btn.webp'
import PlusBtn from './imgs/plus-btn.webp'

export const AddPassenger = ({
  label,
  setValue,
  value,
  context,
  disabledIncreaseAction = false,
  responsive = false,
  disabled = false,
  name,
  Icon,
  width
}) => {
  const IncreaseValue = () => {
    const newValue = value + 1
    setValue(newValue)
  }

  const DecreaseValue = () => {
    if (value <= 0) {
      return
    } else {
      const newValue = value - 1
      setValue(newValue)
    }
  }

  return (
    <>
      {label && (
        <Styled.LabelAndIconWrapper>
          <label>{label}</label>
          {Icon && <Icon width={32} height={32} />}
        </Styled.LabelAndIconWrapper>
      )}
      <Group
        spacing={5}
        style={{
          justifyContent: 'space-between',
          position: 'relative',
          width: responsive ? '100%' : '149.3px'
        }}
      >
        <ActionIcon
          className="btn-minus"
          size={60}
          disabled={disabled}
          variant="transparent"
          style={{
            background: 'transparent',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: 'none'
          }}
          onClick={DecreaseValue}
        >
          <img src={MinusBtn} />
        </ActionIcon>

        <NumberInput
          hideControls
          value={value}
          // onChange={(val) => setValue(val)}
          disabled={disabled}
          max={10}
          min={0}
          step={1}
          styles={{ input: { width: `${width}`, textAlign: 'center' } }}
          style={{ width: `${width}` }}
        />

        {!disabledIncreaseAction && (
          <ActionIcon
            className="btn-plus"
            size={60}
            // disabled={disabledIncreaseAction}
            variant="transparent"
            disabled={disabled}
            style={{
              background: 'transparent',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 'none'
            }}
            onClick={IncreaseValue}
          >
            <img src={PlusBtn} />
          </ActionIcon>
        )}
      </Group>
    </>
  )
}
