import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 1.6rem;
  border-radius: 1.6rem;
  border: 0.2rem solid #f7f7f7;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 28rem;
  min-height: 22rem;

  > div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      width: 2.8rem;
      height: 2.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #DFF7FF;
      border-radius: 0.8rem;

      color: #222222;
      font-family: Neometric;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    > div {
      position: relative;

      > button {
        font-size: 0;
        background: none;
      }

      > div {
        position: absolute;
        top: 3.2rem;
        right: 0;
        width: 12rem;
        padding: 1.4rem 0;
        border-radius: 0.8rem;
        background: #ffffff;
        box-shadow: 0px 31.01px 33.83px 0px rgba(0, 0, 0, 0.07),
          0px -1.41px 22.553px 0px rgba(0, 0, 0, 0.05);

        display: flex;
        flex-direction: column;
        gap: 0.4rem;

        button {
          width: 100%;
          padding: 0.8rem 0;
          color: #222222;
          font-family: Neometric;
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          background-color: #ffffff;
          transition: background-color 0.2s ease-in-out;

          :hover {
            font-weight: 500;
            background-color: #DFF7FF;
          }
        }
      }
    }
  }

  > div:nth-child(2) {
    margin: 0 auto;
    img {
      width: 9rem;
    }
  }

  > div:nth-child(3) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    strong {
      color: #222222;
      font-family: Neometric;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    span {
      color: #222222;
      font-family: Roboto;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      padding: 0.4rem 0.8rem;
      border-radius: 0.8rem;
      background-color: #DFF7FF;
    }
  }
`
