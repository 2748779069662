import { CalendarCheck2, MapPin, Ticket } from 'lucide-react'

export const cardData = (t) => [
  {
    icon: <MapPin width={34} height={34} color="#fff" />,
    title: t('tours.bestTours'),
    description: t('tours.rigorousSelectionProcess')
  },
  {
    icon: <CalendarCheck2 width={34} height={34} color="#fff" />,
    title: t('tours.quickBooking'),
    description: t('tours.keepItSimple')
  },
  {
    icon: <Ticket width={34} height={34} color="#fff" />,
    title: t('tours.freeCancellation'),
    description: t('tours.fullRefund')
  }
]
