import { forwardRef } from 'react'
import * as Styled from './styles'
import { ShieldCheck, UserRoundCheck } from 'lucide-react'
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line no-empty-pattern
const Component = ({}, ref) => {
  const handleScrollToBudgetRef = () => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const { t } = useTranslation()

  return (
    <Styled.Wrapper ref={ref}>
      <div>
        <div>
          <h1>{t('corporate.corporate')}</h1>

          <p
            dangerouslySetInnerHTML={{
              __html: t('corporate.transportServices')
            }}
          />

          <Styled.Button onClick={handleScrollToBudgetRef}>
            {t('corporate.quote')}
          </Styled.Button>

          <div>
            <Styled.Badge>
              <ShieldCheck />

              <Styled.Separator orientation="vertical" />

              <div>
                <span>{t('corporate.safetyAlways')}</span>
              </div>
            </Styled.Badge>

            <Styled.Badge>
              <UserRoundCheck className="size-7" />

              <Styled.Separator orientation="vertical" />

              <div>
                <span>{t('corporate.professionalDrivers')}</span>
              </div>
            </Styled.Badge>
          </div>
        </div>
      </div>
    </Styled.Wrapper>
  )
}

export const TopBanner = forwardRef(Component)
