import React from 'react'
import ReactApexChart from 'react-apexcharts'

import { ReactComponent as BadgeEuroIcon } from '../../icons/badge-euro.svg'
import { ChartCard } from '../ChartCard'

import { CATEGORIES, COLORS } from './constants'

const DEFAULT_DATA = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

export function EarnsPerPeriodChart({ data }) {
  return (
    <ChartCard title="Ganhos por período" Icon={BadgeEuroIcon}>
      <ReactApexChart
        height={170}
        type="area"
        series={[
          {
            name: 'Receita',
            data: data ?? DEFAULT_DATA
          }
        ]}
        options={{
          colors: COLORS,
          xaxis: { categories: CATEGORIES },
          dataLabels: { enabled: false },
          chart: { toolbar: { show: false } },
          stroke: { width: 2.5, curve: 'smooth' }
        }}
      />
    </ChartCard>
  )
}
