import styled, { css } from 'styled-components'
export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `}
`
export const Title = styled.h1`
  color: var(--black-brand-primary, #222);
  font-family: Neometric;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const SubTitle = styled.label`
  color: var(--grey-1-grayscale, #404040);
  font-family: Neometric;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const InputWrapper = styled.div`
  label {
    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
  }

  .mantine-Select-wrapper {
    max-width: 32rem;
  }

  #select {
    gap: 16px;
    height: 54px;
    padding: 15px 45px;
    vertical-align: 'center';
    display: flex;
    align-items: center;

    flex: 1;

    border-radius: 8px;
    border: 0.508px solid var(--grey-3-grayscale, #bfbfbf);

    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  p {
    margin-top: 0.8rem;
    font: 400 1.4rem 'Roboto', sans-serif;
    color: #222222;
  }
`
