import { Input } from '@mantine/core'
import * as Styled from './styles'
import { Banknote } from 'lucide-react'

export const Commission = ({ data, setData, disabled, enterprise }) => {
  const handleInputChange = (field, value) => {
    setData((prevValues) => ({
      ...prevValues,
      [field]: value
    }))
  }

  return (
    <Styled.Container>
      <Styled.Title>
        Insira um valor que represente a porcentagem de
        {enterprise
          ? 'lucro líquido'
          : `comissão para o
        motorista`}
      </Styled.Title>
      <Styled.SubTitle>Porcentagem {enterprise && 'de ganhos'}</Styled.SubTitle>
      <Styled.InputWrapper>
        <Input.Wrapper>
          <Input
            onChange={(event) =>
              handleInputChange(
                'commissionPercentage',
                Number(event.target.value)
              )
            }
            value={data?.commissionPercentage}
            icon={<Banknote />}
            disabled={disabled}
          />
        </Input.Wrapper>
      </Styled.InputWrapper>
      {enterprise ? (
        <>
          <Styled.Title>
            Insira um valor que represente a porcentagem em IVA
          </Styled.Title>
          <Styled.SubTitle>Porcentagem de IVA</Styled.SubTitle>
          <Styled.InputWrapper>
            <Input.Wrapper>
              <Input
                onChange={(event) => {
                  if (enterprise) {
                    handleInputChange(
                      'ivaPercentage',
                      Number(event.target.value)
                    )
                  } else {
                    handleInputChange(
                      'limitTransfersPerDay',
                      Number(event.target.value)
                    )
                  }
                }}
                value={data?.limitTransfersPerDay || data?.ivaPercentage}
                icon={<Banknote />}
                disabled={disabled}
              />
            </Input.Wrapper>
          </Styled.InputWrapper>
        </>
      ) : (
        <>
          <Styled.SubTitle>Quantidade de transfers diários</Styled.SubTitle>
          <Styled.InputWrapper>
            <Input.Wrapper>
              <Input
                onChange={(event) =>
                  handleInputChange(
                    'limitTransfersPerDay',
                    Number(event.target.value)
                  )
                }
                value={data?.limitTransfersPerDay}
                icon={<Banknote />}
                disabled={disabled}
              />
            </Input.Wrapper>
          </Styled.InputWrapper>
          <Styled.SubTitle>Porcentagem de IVA</Styled.SubTitle>
          <Styled.InputWrapper>
            <Input.Wrapper>
              <Input
                onChange={(event) => {
                  handleInputChange('ivaPercentage', Number(event.target.value))
                }}
                value={data?.ivaPercentage}
                icon={<Banknote />}
                disabled={disabled}
              />
            </Input.Wrapper>
          </Styled.InputWrapper>
        </>
      )}
    </Styled.Container>
  )
}
