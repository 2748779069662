import { Fragment, useEffect, useState } from 'react'
import { DatePicker, TimeInput } from '@mantine/dates'
import * as Styled from './styles'

import { ReactComponent as ChevronRight } from '../../../../assets/chevron-right.svg'
import Pencil from './imgs/lucide_pencil- blue branding.svg'
import OrangeBar from './imgs/icon_line_points- blue branding.svg'
import Briefcase from './imgs/icon_briefcase.svg'
import Passenger from './imgs/lucide_users-2- blue branding.svg'
import Shape from './imgs/icon_surfboard- blue branding.svg'
import Carseat from './imgs/icon_car_baby_seat- blue branding.svg'
import Paw from './imgs/icon_pet- blue branding.svg'
import Arrow from './imgs/icon_chevron right.svg'
import Clock from './imgs/lucide_clock- blue branding.svg'
import X from './imgs/x.webp'
import { Popover } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useTransferContext } from '../../../../contexts/TransferContext'
import { useMediaQuery } from '../../../../hooks/useMediaQuery'
import dayjs from 'dayjs'
import { formatCurrency } from '../../../../utils/formatCurrency'
// import VisaIcon from './imgs/visa.webp'
import { OutPortal } from 'react-reverse-portal'
import { useRef } from 'react'
import { Link } from 'react-router-dom'
import { Checked } from '../Checked'

const SHARE_RIDE_DISCOUNT = 0.25
const REFOUNT_TAX = 4.97
const NIGHT_TAX = 9
const ANIMALS_TAX = 10
const CHILDSEATS_TAX = 3
const SURFBOARDS_TAX = 3

export const OrderSummary = ({ portalNode, reservation = false }) => {
  const { t } = useTranslation()
  const { going, setGoing, returnTravel, setReturnTravel } =
    useTransferContext()

  const [, setEditing] = useState(false)
  const [hasReturnNightTax, setHasReturnNightTax] = useState(false)
  const expandableMatch = useMediaQuery('(max-width: 944px)')

  const hasGoingNightTax =
    dayjs(going.dateTime).get('hours') > 19 ||
    (dayjs(going.dateTime).get('hours') >= 19 &&
      dayjs(going.dateTime).get('minutes') >= 30) ||
    dayjs(going.dateTime).get('hours') < 8 ||
    (dayjs(going.dateTime).get('hours') == 8 &&
      dayjs(going.dateTime).get('minutes') == 0)

  const nightTax =
    hasGoingNightTax && hasReturnNightTax
      ? NIGHT_TAX * 2
      : hasGoingNightTax || hasReturnNightTax
      ? NIGHT_TAX
      : 0

  const goingAnimalsTax = going.animals
    ? going.animalsQuantity * ANIMALS_TAX
    : 0
  const returnAnimalsTax =
    returnTravel.active && returnTravel.animals
      ? returnTravel.animalsQuantity * ANIMALS_TAX
      : 0

  const goingChildSeatsTax = going.childseat
    ? (going.childSeat + going.boosterSeat + going.babyChair) * CHILDSEATS_TAX
    : 0
  const returnChildSeatsTax =
    returnTravel.active && returnTravel.childseat
      ? (returnTravel.childSeat +
          returnTravel.boosterSeat +
          returnTravel.babyChair) *
        CHILDSEATS_TAX
      : 0

  const goingSurfboardsTax = going.surf
    ? going.surfQuantity * SURFBOARDS_TAX
    : 0
  const returnSurfboardsTax =
    returnTravel.active && returnTravel.surf
      ? returnTravel.surfQuantity * SURFBOARDS_TAX
      : 0
  const orderBumpsTax = going.bookingStops
    ? going.bookingStops.reduce((total, item) => total + item.price, 0)
    : 0

  const [selectedTimeGoing, setSelectedTimeGoing] = useState(
    going.dateTime
      ? new Date(going.dateTime).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit'
        })
      : ''
  )

  const [selectedDateReturn, setSelectedDateReturn] = useState(
    returnTravel.active && !!returnTravel.dateTime
      ? returnTravel.dateTime
      : new Date(dayjs(going.dateTime).add(1, 'hours'))
  )

  const [selectedTimeReturn, setSelectedTimeReturn] = useState(
    returnTravel.active && returnTravel.dateTime
      ? new Date(returnTravel.dateTime).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit'
        })
      : `${String(dayjs(going.dateTime).add(1, 'hours').get('hours')).padStart(
          2,
          '0'
        )}:${String(
          dayjs(going.dateTime).add(1, 'hours').get('minutes')
        ).padStart(2, '0')}`
  )

  const [tax, setTax] = useState(0)
  const [shareRideDiscount, setShareRideDiscount] = useState(0)
  const [isExpandedOrderSummary, setIsExpandedOrderSummary] = useState(false)
  const contentRef = useRef(null)
  const containerRef = useRef(null)

  const handleEditClick = () => {
    setEditing(true)
  }

  const getAbbreviatedWeekday = (date) => {
    const weekday = date.toLocaleString('default', { weekday: 'short' })
    return weekday.substring(0, 3).toUpperCase()
  }

  useEffect(() => {
    if (going.totalPrice) {
      const taxCalculate = returnTravel.active
        ? (going.totalPrice + returnTravel.totalPrice) * 0.06
        : going.totalPrice * 0.06
      setTax(taxCalculate)

      if (going.shareRide) {
        const shareRideDiscountCalculate = returnTravel.active
          ? (going.totalPrice + returnTravel.totalPrice) * SHARE_RIDE_DISCOUNT
          : going.totalPrice * SHARE_RIDE_DISCOUNT
        setShareRideDiscount(shareRideDiscountCalculate)
      } else {
        setShareRideDiscount(0)
      }
    }
  }, [
    going.totalPrice,
    returnTravel.active,
    returnTravel.totalPrice,
    going.shareRide
  ])

  useEffect(() => {
    if (selectedTimeGoing) {
      console.log({ going, iso: going.dateTime.toISOString() })
      console.log('new date', {
        date: new Date(
          dayjs(going.dateTime).format('YYYY-MM-DDTHH:mm:ss').split('T')[0] +
            `T${selectedTimeGoing}:00`
        )
      })

      setGoing({
        ...going,
        dateTime: new Date(
          dayjs(going.dateTime).format('YYYY-MM-DDTHH:mm:ss').split('T')[0] +
            `T${selectedTimeGoing}:00`
        )
      })
    }
  }, [selectedTimeGoing])

  useEffect(() => {
    if (!!selectedDateReturn && returnTravel.active) {
      const dateTime = new Date(
        dayjs(selectedDateReturn).format('YYYY-MM-DDTHH:mm:ss').split('T')[0] +
          `T${selectedTimeReturn}:00`
      )

      setReturnTravel({
        ...returnTravel,
        dateTime
      })

      setHasReturnNightTax(
        returnTravel.active &&
          (dayjs(dateTime).get('hours') > 19 ||
            (dayjs(dateTime).get('hours') >= 19 &&
              dayjs(dateTime).get('minutes') >= 30) ||
            dayjs(dateTime).get('hours') < 8 ||
            (dayjs(dateTime).get('hours') == 8 &&
              dayjs(dateTime).get('minutes') == 0))
      )
    }
  }, [selectedDateReturn, selectedTimeReturn, returnTravel.active])

  const handleToggleExpandSummary = () => {
    setIsExpandedOrderSummary((oldState) => !oldState)
  }

  return (
    <Styled.Container
      expanded={(expandableMatch && isExpandedOrderSummary) || reservation}
      ref={containerRef}
    >
      {reservation && <Checked />}
      {expandableMatch && !reservation && (
        <button onClick={handleToggleExpandSummary}>
          <ChevronRight />
          {isExpandedOrderSummary ? 'MINIMIZAR' : 'MOSTRAR DETALHES'}
        </button>
      )}
      <h2>{t('orderSummary.title')}</h2>
      <div>
        <div
          className="content-wrapper"
          ref={contentRef}
          style={
            !reservation && !isExpandedOrderSummary && expandableMatch
              ? { display: 'none' }
              : undefined
          }
        >
          {going && (
            <>
              <Styled.FormRow style={{ marginTop: '2.4rem' }}>
                <Styled.StrongSpan>
                  {t('orderSummary.orderNumberLabel')}:
                </Styled.StrongSpan>
                <Styled.NormalSpan>034938429</Styled.NormalSpan>
              </Styled.FormRow>
              <OutPortal node={portalNode} />
              {going.from && going.to && (
                <Styled.ContentWrapper>
                  <Styled.RouteWrapper>
                    <Styled.OrientationWrapper>
                      <img src={Arrow} alt="Arrow icon" />
                      <span>{t('orderSummary.outboundLabel')}</span>
                    </Styled.OrientationWrapper>
                    <Styled.HeaderWrapper>
                      <Popover position="bottom" shadow="md">
                        <Popover.Dropdown>
                          <Styled.CalendarWrapper>
                            <DatePicker
                              value={going.dateTime}
                              onChange={(value) => {
                                if (
                                  dayjs(value.toISOString()).get('date') ===
                                    dayjs().add(8, 'hours').get('date') &&
                                  dayjs(value.toISOString()).get('month') ===
                                    dayjs().add(8, 'hours').get('month') &&
                                  dayjs(value.toISOString()).get('year') ===
                                    dayjs().add(8, 'hours').get('year')
                                ) {
                                  const hour = String(
                                    dayjs().add(8, 'hours').get('hours')
                                  ).padStart(2, '0')
                                  const minute = String(
                                    dayjs().add(8, 'hours').get('minutes')
                                  ).padStart(2, '0')

                                  setSelectedTimeGoing(`${hour}:${minute}`)
                                }

                                if (
                                  selectedDateReturn &&
                                  dayjs(
                                    selectedDateReturn.toISOString()
                                  ).isBefore(dayjs(value.toISOString()))
                                ) {
                                  setSelectedDateReturn(
                                    new Date(dayjs(value).add(1, 'hour'))
                                  )

                                  if (selectedTimeGoing) {
                                    if (selectedTimeGoing.startsWith('23')) {
                                      setSelectedDateReturn(
                                        new Date(dayjs(value).add(1, 'days'))
                                      )
                                    }

                                    const hours = Number(
                                      selectedTimeGoing.split(':')[0]
                                    )
                                    const minutes = Number(
                                      selectedTimeGoing.split(':')[1]
                                    )

                                    setSelectedTimeReturn(
                                      `${String(
                                        hours < 23 ? hours + 1 : 0
                                      ).padStart(2, '0')}:${String(
                                        minutes < 59 ? minutes : 0
                                      ).padStart(2, '0')}`
                                    )
                                  }
                                }

                                setGoing({ ...going, dateTime: value })
                              }}
                              minDate={new Date(dayjs().add(8, 'hours'))}
                            />
                            <TimeInput
                              label={t('orderSummary.timeLabel')}
                              icon={<img src={Clock} alt="Clock icon" />}
                              value={selectedTimeGoing}
                              onChange={(e) =>
                                setSelectedTimeGoing(e.target.value)
                              }
                              onBlur={() => {
                                if (
                                  dayjs(going.dateTime.toISOString()).get(
                                    'date'
                                  ) === dayjs().add(8, 'hours').get('date') &&
                                  dayjs(going.dateTime.toISOString()).get(
                                    'month'
                                  ) === dayjs().add(8, 'hours').get('month') &&
                                  dayjs(going.dateTime.toISOString()).get(
                                    'year'
                                  ) === dayjs().add(8, 'hours').get('year')
                                ) {
                                  const valueHour = Number(
                                    selectedTimeGoing.split(':')[0]
                                  )
                                  const valueMinute = Number(
                                    selectedTimeGoing.split(':')[1]
                                  )

                                  const hour = dayjs()
                                    .add(8, 'hours')
                                    .get('hours')

                                  const minute = dayjs()
                                    .add(8, 'hours')
                                    .get('minutes')

                                  if (
                                    valueHour < hour ||
                                    (valueHour === hour && valueMinute < minute)
                                  ) {
                                    setSelectedTimeGoing(
                                      `${String(hour).padStart(
                                        2,
                                        '0'
                                      )}:${String(minute).padStart(2, '0')}`
                                    )

                                    return
                                  }

                                  setSelectedTimeGoing(selectedTimeGoing)
                                }
                                setSelectedTimeGoing(selectedTimeGoing)
                              }}
                              rightSection={
                                selectedTimeGoing ? (
                                  <Styled.IconX
                                    onClick={() => setSelectedTimeGoing('')}
                                    src={X}
                                    alt="X icon"
                                  />
                                ) : (
                                  ''
                                )
                              }
                            />
                          </Styled.CalendarWrapper>
                        </Popover.Dropdown>
                        <Styled.DateAndHour>
                          {getAbbreviatedWeekday(going.dateTime)},{' '}
                          {going.dateTime.toLocaleDateString()} -{' '}
                          {selectedTimeGoing}
                        </Styled.DateAndHour>
                        {!reservation && (
                          <Popover.Target>
                            <Styled.EditContainer>
                              <span onClick={handleEditClick}>
                                {t('orderSummary.editLabel')}
                              </span>
                              <img src={Pencil} alt="Edit icon" />
                            </Styled.EditContainer>
                          </Popover.Target>
                        )}
                      </Popover>
                    </Styled.HeaderWrapper>
                    <Styled.RoutesWrapper>
                      <img
                        src={OrangeBar}
                        className="orange-bar"
                        alt="Orange bar"
                      />
                      <div className="routes">
                        <Styled.FormRow>
                          <Styled.RouteStrongSpan>
                            {going.from}
                          </Styled.RouteStrongSpan>
                          <Styled.RoutePriceSpan style={{ width: '70px' }}>
                            €{' '}
                            {formatCurrency(going.totalPrice).replace('€', '')}
                          </Styled.RoutePriceSpan>
                        </Styled.FormRow>
                        <Styled.FormRow>
                          <Styled.RouteNormalSpan>
                            {going.from}
                          </Styled.RouteNormalSpan>
                        </Styled.FormRow>
                        {going.passengerStops.length > 0 &&
                          going.passengerStops.map((stop, idx) => (
                            <Fragment key={idx}>
                              <Styled.FormRow style={{ marginTop: '24px' }}>
                                <Styled.RouteStrongSpan
                                  style={{ fontSize: '1.2rem' }}
                                >
                                  {stop.from}
                                </Styled.RouteStrongSpan>
                              </Styled.FormRow>
                              <Styled.FormRow>
                                <Styled.RouteNormalSpan
                                  style={{ fontSize: '1.2rem' }}
                                >
                                  {t('reserveComponent.Stop')}
                                </Styled.RouteNormalSpan>
                              </Styled.FormRow>
                            </Fragment>
                          ))}
                        <Styled.FormRow style={{ marginTop: '4px' }}>
                          <Styled.RouteStrongSpan style={{ marginTop: '30px' }}>
                            {going.to}
                          </Styled.RouteStrongSpan>
                        </Styled.FormRow>
                        <Styled.FormRow>
                          <Styled.RouteNormalSpan>
                            {going.to}
                          </Styled.RouteNormalSpan>
                        </Styled.FormRow>
                      </div>
                    </Styled.RoutesWrapper>
                    <Styled.IconsAndNumbersWrapper>
                      {(going.checkedLuggage > 0 ||
                        going.backPack > 0 ||
                        going.handLuggage > 0) && (
                        <Styled.IconAndNumber>
                          <img
                            src={Briefcase}
                            style={{ height: '24px' }}
                            alt="Briefcase icon"
                          />
                          <span>
                            {going.checkedLuggage +
                              going.backPack +
                              going.handLuggage}
                          </span>
                        </Styled.IconAndNumber>
                      )}
                      {going.allPassangers > 0 && (
                        <Styled.IconAndNumber>
                          <img
                            src={Passenger}
                            style={{ height: '24px' }}
                            alt="Passenger icon"
                          />
                          <span>{going.allPassangers}</span>
                        </Styled.IconAndNumber>
                      )}
                      {going.surfQuantity > 0 && (
                        <Styled.IconAndNumber>
                          <img
                            src={Shape}
                            style={{ height: '24px' }}
                            alt="Shape icon"
                          />
                          <span>{going.surfQuantity}</span>
                        </Styled.IconAndNumber>
                      )}
                      {(going.babyChair > 0 ||
                        going.boosterSeat > 0 ||
                        going.childSeat > 0) && (
                        <Styled.IconAndNumber>
                          <img
                            src={Carseat}
                            style={{ height: '24px' }}
                            alt="Carseat icon"
                          />
                          <span>
                            {going.babyChair +
                              going.boosterSeat +
                              going.childSeat}
                          </span>
                        </Styled.IconAndNumber>
                      )}

                      {going.animalsQuantity > 0 && (
                        <Styled.IconAndNumber>
                          <img
                            src={Paw}
                            style={{ height: '24px' }}
                            alt="Paw icon"
                          />
                          <span>{going.animalsQuantity}</span>
                        </Styled.IconAndNumber>
                      )}
                    </Styled.IconsAndNumbersWrapper>
                  </Styled.RouteWrapper>
                </Styled.ContentWrapper>
              )}

              <Styled.Divider />
            </>
          )}

          {returnTravel.active && returnTravel.from && returnTravel.to && (
            <Styled.ContentWrapper>
              <Styled.RouteWrapper>
                <Styled.OrientationWrapper style={{ marginTop: '20px' }}>
                  <img src={Arrow} alt="Arrow icon" />
                  <span>{t('orderSummary.returnLabel')}</span>
                </Styled.OrientationWrapper>
                <Styled.HeaderWrapper>
                  <Popover position="bottom" shadow="md">
                    <Popover.Dropdown>
                      <Styled.CalendarWrapper>
                        <DatePicker
                          value={selectedDateReturn}
                          onChange={(value) => {
                            if (
                              dayjs(value.toISOString()).get('date') ===
                                dayjs(going.dateTime.toISOString()).get(
                                  'date'
                                ) &&
                              dayjs(value.toISOString()).get('month') ===
                                dayjs(going.dateTime.toISOString()).get(
                                  'month'
                                ) &&
                              dayjs(value.toISOString()).get('year') ===
                                dayjs(going.dateTime.toISOString()).get('year')
                            ) {
                              const hour = Number(
                                selectedTimeGoing.split(':')[0]
                              )

                              const minute = Number(
                                selectedTimeGoing.split(':')[1]
                              )

                              setSelectedTimeReturn(
                                `${String(hour < 23 ? hour + 1 : 0).padStart(
                                  2,
                                  '0'
                                )}:${String(minute < 59 ? minute : 0).padStart(
                                  2,
                                  '0'
                                )}`
                              )
                            }

                            setSelectedDateReturn(value)
                          }}
                          minDate={
                            new Date(dayjs(going.dateTime.toISOString()))
                          }
                        />
                        <TimeInput
                          label={t('orderSummary.timeLabel')}
                          icon={<img src={Clock} alt="Clock icon" />}
                          value={selectedTimeReturn}
                          onChange={(e) =>
                            setSelectedTimeReturn(e.target.value)
                          }
                          onBlur={() => {
                            if (
                              dayjs(selectedDateReturn.toISOString()).get(
                                'date'
                              ) === dayjs(going.dateTime).get('date') &&
                              dayjs(selectedDateReturn.toISOString()).get(
                                'month'
                              ) === dayjs(going.dateTime).get('month') &&
                              dayjs(selectedDateReturn.toISOString()).get(
                                'year'
                              ) === dayjs(going.dateTime).get('year')
                            ) {
                              const valueHour = Number(
                                selectedTimeReturn.split(':')[0]
                              )
                              const valueMinute = Number(
                                selectedTimeReturn.split(':')[1]
                              )

                              const hour = Number(
                                selectedTimeGoing.split(':')[0]
                              )

                              const minute = Number(
                                selectedTimeGoing.split(':')[1]
                              )

                              if (
                                valueHour < hour ||
                                (valueHour === hour && valueMinute < minute)
                              ) {
                                setSelectedTimeReturn(
                                  `${String(hour).padStart(2, '0')}:${String(
                                    minute
                                  ).padStart(2, '0')}`
                                )

                                return
                              }

                              setSelectedTimeReturn(selectedTimeReturn)
                            }
                            setSelectedTimeReturn(selectedTimeReturn)
                          }}
                          rightSection={
                            selectedTimeReturn ? (
                              <Styled.IconX
                                onClick={() => setSelectedTimeReturn('')}
                                src={X}
                                alt="X icon"
                              />
                            ) : (
                              ''
                            )
                          }
                        />
                      </Styled.CalendarWrapper>
                    </Popover.Dropdown>
                    <Styled.DateAndHour>
                      {getAbbreviatedWeekday(selectedDateReturn)},{' '}
                      {selectedDateReturn.toLocaleDateString()} -{' '}
                      {selectedTimeReturn}
                    </Styled.DateAndHour>
                    {!reservation && (
                      <Popover.Target>
                        <Styled.EditContainer>
                          <span onClick={handleEditClick}>
                            {t('orderSummary.editLabel')}
                          </span>
                          <img src={Pencil} alt="Edit icon" />
                        </Styled.EditContainer>
                      </Popover.Target>
                    )}
                  </Popover>
                </Styled.HeaderWrapper>
                <Styled.RoutesWrapper>
                  <img
                    src={OrangeBar}
                    className="orange-bar"
                    alt="Orange bar"
                  />
                  <div className="routes">
                    <Styled.FormRow style={{ justifyContent: 'space-between' }}>
                      <Styled.RouteStrongSpan>
                        {returnTravel.from}
                      </Styled.RouteStrongSpan>
                      <Styled.RoutePriceSpan style={{ width: '62px' }}>
                        €{' '}
                        {formatCurrency(returnTravel.totalPrice).replace(
                          '€',
                          ''
                        )}
                      </Styled.RoutePriceSpan>
                    </Styled.FormRow>
                    <Styled.FormRow>
                      <Styled.RouteNormalSpan>
                        {returnTravel.from}
                      </Styled.RouteNormalSpan>
                    </Styled.FormRow>
                    <Styled.FormRow style={{ marginTop: '4px' }}>
                      <Styled.RouteStrongSpan
                        style={{ marginTop: '30px', maxWidth: '240px' }}
                      >
                        {returnTravel.to}
                      </Styled.RouteStrongSpan>
                    </Styled.FormRow>
                    <Styled.FormRow>
                      <Styled.RouteNormalSpan>
                        {returnTravel.to}
                      </Styled.RouteNormalSpan>
                    </Styled.FormRow>
                  </div>
                </Styled.RoutesWrapper>
                <Styled.IconsAndNumbersWrapper>
                  {(returnTravel.checkedLuggage > 0 ||
                    returnTravel.backPack > 0 ||
                    returnTravel.handLuggage > 0) && (
                    <Styled.IconAndNumber>
                      <img
                        src={Briefcase}
                        style={{ height: '24px' }}
                        alt="Briefcase icon"
                      />
                      <span>
                        {returnTravel.checkedLuggage +
                          returnTravel.backPack +
                          returnTravel.handLuggage}
                      </span>
                    </Styled.IconAndNumber>
                  )}
                  {returnTravel.allPassangers > 0 && (
                    <Styled.IconAndNumber>
                      <img
                        src={Passenger}
                        style={{ height: '24px' }}
                        alt="Passenger icon"
                      />
                      <span>{returnTravel.allPassangers}</span>
                    </Styled.IconAndNumber>
                  )}
                  {returnTravel.surfQuantity > 0 && (
                    <Styled.IconAndNumber>
                      <img
                        src={Shape}
                        style={{ height: '24px' }}
                        alt="Shape icon"
                      />
                      <span>{returnTravel.surfQuantity}</span>
                    </Styled.IconAndNumber>
                  )}
                  {(returnTravel.babyChair > 0 ||
                    returnTravel.boosterSeat > 0 ||
                    returnTravel.childSeat > 0) && (
                    <Styled.IconAndNumber>
                      <img
                        src={Carseat}
                        style={{ height: '24px' }}
                        alt="Carseat icon"
                      />
                      <span>
                        {returnTravel.babyChair +
                          returnTravel.boosterSeat +
                          returnTravel.childSeat}
                      </span>
                    </Styled.IconAndNumber>
                  )}

                  {returnTravel.animalsQuantity > 0 && (
                    <Styled.IconAndNumber>
                      <img
                        src={Paw}
                        style={{ height: '24px' }}
                        alt="Paw icon"
                      />
                      <span>{returnTravel.animalsQuantity}</span>
                    </Styled.IconAndNumber>
                  )}
                </Styled.IconsAndNumbersWrapper>
              </Styled.RouteWrapper>
            </Styled.ContentWrapper>
          )}
        </div>
        {going.totalPrice > 0 && (
          <>
            <div
              style={
                !reservation && !isExpandedOrderSummary && expandableMatch
                  ? { display: 'none' }
                  : undefined
              }
            >
              <Styled.BillWrapper>
                <h2>{t('orderSummary.billTitle')}</h2>
                <Styled.BillRow>
                  <span className="title">
                    {t('orderSummary.itemDescription')}
                  </span>
                  <span className="title">{t('orderSummary.price')}</span>
                </Styled.BillRow>
                <Styled.BillRow>
                  <Styled.NormalBillSpan>
                    {t('orderSummary.transferItem')}
                  </Styled.NormalBillSpan>
                  <Styled.PriceSpan>
                    €{' '}
                    {formatCurrency(
                      going.totalPrice +
                        (returnTravel.active ? returnTravel.totalPrice : 0)
                    ).replace('€', '')}
                  </Styled.PriceSpan>
                </Styled.BillRow>
                <Styled.BillRow>
                  <Styled.NormalBillSpan>
                    {t('orderSummary.tax6Item')}
                  </Styled.NormalBillSpan>
                  <Styled.PriceSpan>
                    € {formatCurrency(tax).replace('€', '')}
                  </Styled.PriceSpan>
                </Styled.BillRow>
                {(goingAnimalsTax > 0 || returnAnimalsTax > 0) && (
                  <Styled.BillRow>
                    <Styled.NormalBillSpan>
                      Taxa Para Animais
                    </Styled.NormalBillSpan>
                    <Styled.PriceSpan>
                      €{' '}
                      {formatCurrency(
                        goingAnimalsTax + returnAnimalsTax
                      ).replace('€', '')}
                    </Styled.PriceSpan>
                  </Styled.BillRow>
                )}
                {(goingSurfboardsTax > 0 || returnSurfboardsTax > 0) && (
                  <Styled.BillRow>
                    <Styled.NormalBillSpan>
                      Taxa Para Pranchas de Surf
                    </Styled.NormalBillSpan>
                    <Styled.PriceSpan>
                      €{' '}
                      {formatCurrency(
                        goingSurfboardsTax + returnSurfboardsTax
                      ).replace('€', '')}
                    </Styled.PriceSpan>
                  </Styled.BillRow>
                )}
                {(goingChildSeatsTax > 0 || returnChildSeatsTax > 0) && (
                  <Styled.BillRow>
                    <Styled.NormalBillSpan>
                      Taxa Para Child Seats
                    </Styled.NormalBillSpan>
                    <Styled.PriceSpan>
                      €{' '}
                      {formatCurrency(
                        goingChildSeatsTax + returnChildSeatsTax
                      ).replace('€', '')}
                    </Styled.PriceSpan>
                  </Styled.BillRow>
                )}
                {(hasGoingNightTax || hasReturnNightTax) && (
                  <Styled.BillRow>
                    <Styled.NormalBillSpan>
                      {t('orderSummary.nightTax')}
                    </Styled.NormalBillSpan>
                    <Styled.PriceSpan>
                      € {formatCurrency(nightTax).replace('€', '')}
                    </Styled.PriceSpan>
                  </Styled.BillRow>
                )}
                {going.bookingStops.length > 0 && (
                  <Styled.BillRow>
                    <Styled.NormalBillSpan>Paradas</Styled.NormalBillSpan>
                    <Styled.PriceSpan>
                      € {formatCurrency(orderBumpsTax).replace('€', '')}
                    </Styled.PriceSpan>
                  </Styled.BillRow>
                )}
                {going.discountAmount > 0 && (
                  <Styled.BillRow>
                    <Styled.NormalBillSpan>Desconto</Styled.NormalBillSpan>
                    <Styled.PriceSpan>
                      - €{' '}
                      {formatCurrency(
                        going.discountAmount + returnTravel.discountAmount
                      ).replace('€', '')}
                    </Styled.PriceSpan>
                  </Styled.BillRow>
                )}
                {/* {shareRideDiscount > 0 && (
                  <Styled.BillRow>
                    <Styled.NormalBillSpan>Share Ride</Styled.NormalBillSpan>
                    <Styled.PriceSpan>
                      - € {formatCurrency(shareRideDiscount).replace('€', '')}
                    </Styled.PriceSpan>
                  </Styled.BillRow>
                )} */}

                <Styled.BillRow>
                  <Styled.NormalBillSpan>
                    {t('orderSummary.refundTaxItem')}
                  </Styled.NormalBillSpan>
                  <Styled.PriceSpan>
                    €{' '}
                    {going.reimbursement
                      ? formatCurrency(REFOUNT_TAX).replace('€', '')
                      : formatCurrency(0).replace('€', '')}
                  </Styled.PriceSpan>
                </Styled.BillRow>
                <Styled.BillRow>
                  <Styled.NormalBillSpan>
                    {t('orderSummary.total')}
                  </Styled.NormalBillSpan>
                  <Styled.PriceSpan>
                    €{' '}
                    {formatCurrency(
                      going.totalPrice -
                        going.discountAmount +
                        (returnTravel.active
                          ? returnTravel.totalPrice -
                            returnTravel.discountAmount
                          : 0) -
                        // shareRideDiscount +
                        nightTax +
                        goingAnimalsTax +
                        returnAnimalsTax +
                        goingSurfboardsTax +
                        returnSurfboardsTax +
                        goingChildSeatsTax +
                        returnChildSeatsTax +
                        orderBumpsTax +
                        (going.reimbursement ? REFOUNT_TAX : 0) +
                        tax
                    ).replace('€', '')}
                  </Styled.PriceSpan>
                </Styled.BillRow>
              </Styled.BillWrapper>
              <Styled.Divider />
            </div>
            <Styled.Footer
              style={
                !reservation && !isExpandedOrderSummary && expandableMatch
                  ? { marginTop: '-4.4rem' }
                  : undefined
              }
            >
              <h5>
                €{' '}
                {formatCurrency(
                  going.totalPrice -
                    going.discountAmount +
                    (returnTravel.active
                      ? returnTravel.totalPrice - returnTravel.discountAmount
                      : 0) -
                    // shareRideDiscount +
                    nightTax +
                    goingAnimalsTax +
                    returnAnimalsTax +
                    goingSurfboardsTax +
                    returnSurfboardsTax +
                    goingChildSeatsTax +
                    returnChildSeatsTax +
                    orderBumpsTax +
                    (going.reimbursement ? REFOUNT_TAX : 0) +
                    tax
                ).replace('€', '')}
              </h5>
              <div>
                <p>{t('checkoutTour.Total price')}</p>
                <span>{t('checkoutTour.Taxes & fees included')}</span>
              </div>
            </Styled.Footer>
          </>
        )}
      </div>
      {reservation && <Link to="/">VOLTAR PARA HOME</Link>}
    </Styled.Container>
  )
}
