import car1 from './components/CarCard/imgs/car.webp'

export const CarItem = [
  {
    img: car1,
    title: 'Standart',
    briefcase: 3,
    wifi: true,
    passengers: 4,
    price: '177.00'
  }
]
