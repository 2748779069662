import React, { useEffect, useState } from 'react'
import { Anchor, Group } from '@mantine/core'
import { useMemo } from 'react'

import { useStyles } from '../../hooks/useStyles'
import * as Styled from '../../styles'
import fetchBooking from '../../../../../../services/booking'

export function TransferOptions({ activeOption, onChangeOption }) {
  const { classes, cx } = useStyles()
  const [counter, setCounter] = useState()

  useEffect(() => {
    fetchBooking
      .getTabCounters()
      .then((res) => {
        setCounter(res.data)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }, [])

  const mainLinks = useMemo(
    () => [
      { label: 'Transfer', count: counter?.pendingBookings },
      { label: 'Tours', count: counter?.pendingTourBookings },
      { label: 'Low Cost', count: counter?.pendingLowCostBookings },
      { label: 'Partnership', count: counter?.pendingPartnerBookings },
      { label: 'Eventos', count: counter?.pendingEventsBookings }
    ],
    [counter]
  )

  return (
    <Group spacing={0} position="right" className={classes.mainLinks}>
      {mainLinks.map((item, index) => (
        <Anchor
          href={null}
          key={item.label}
          className={cx(classes.mainLink, {
            [classes.mainLinkActive]: index == activeOption
          })}
          onClick={(e) => {
            e.stopPropagation()
            onChangeOption(index)
          }}
        >
          <Styled.Count className={index == activeOption && 'activeCount'}>
            {item.count}
          </Styled.Count>
          {item.label}
        </Anchor>
      ))}
    </Group>
  )
}
