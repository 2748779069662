import styled from 'styled-components'

import {
  Card as UICard,
  CardHeader as UICardHeader,
  CardContent as UICardContent
} from '../../../../../../components/Card'

export const Card = styled(UICard)`
  background-color: #27c7ff;
  color: #ffffff;
`

export const CardHeader = styled(UICardHeader)`
  display: block;

  h3 {
    font-weight: 600;
    font-size: 2rem; /* 20px */
    line-height: 2rem;
  }

  span {
    font-size: 1.4rem;
    line-height: 2rem;
    color: #fff9e5;
  }
`

export const CardContent = styled(UICardContent)`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  > div:first-of-type {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    svg {
      width: 2.4rem;
      height: 2.4rem;
      color: #18181b;
    }

    span {
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 600;
    }
  }

  > div:last-of-type {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.8rem;

    svg {
      width: 2.4rem;
      height: 2.4rem;
      color: #18181b;
    }

    span {
      font-size: 1.4rem;
      line-height: 2rem;
      font-weight: 600;
    }
  }
`
