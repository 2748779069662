import styled from 'styled-components'

export const Container = styled.div`
  width: 400px;
  height: calc(100vh - 80px - 24px - 80px);

  border-radius: 16px;
  border: 0.5px solid #bfbfbf;

  padding: 16px 0;

  @media (max-width: 500px) {
    width: 100%;
    height: 100%;
    max-height: 300px;
  }
`

export const InputWrapper = styled.div`
  margin-bottom: 12px;
  padding: 0 16px;

  #input-demo {
    display: flex;
    padding: 0px 8x;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 0.5px solid var(--grey-3-grayscale, #bfbfbf);
    height: 40px;
    color: #a2a2a2;
    font-family: Neometric;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  #select-filter {
    height: 40px;
    border-radius: 8px;
    border: 0.508px solid var(--grey-3-grayscale, #bfbfbf);
    color: var(--grey-2-grayscale, #808080);
    font-family: Neometric;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .mantine-Input-wrapper {
    margin-top: 0;
  }
  .activeCount {
    display: flex;
    height: 18px;
    padding: 8px 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: var(--yellow-brand-primary, #27c7ff);
    color: var(--white-grayscale, #fff);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 14.4px */
    letter-spacing: 0.12px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  height: calc(100% - 56px);

  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
`
