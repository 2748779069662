import styled from 'styled-components'

import {
  Card as UICard,
  CardContent as UICardContent
} from '../../../../../../components/Card'

// rounded-xl border-none bg-gradient-to-t from-zinc-700 to-zinc-300 p-0
export const Card = styled(UICard)`
  border-radius: 1.2rem;
  border: none;
  background-image: linear-gradient(to top, #3f3f46, #d4d4d8);
  padding: 0;
`

export const CardContent = styled(UICardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;

  > div:first-of-type {
    padding: 2.4rem;
    padding-bottom: 0;

    img {
      height: 12.8rem;
      width: 100%;

      object-fit: contain;
    }
  }

  > div:last-of-type {
    display: flex;
    width: 100%;
    flex-direction: column;
    border-bottom-left-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;
    background-color: #18181b;
    text-align: center;
    padding: 0.8rem;

    span:first-of-type {
      font-size: 1.6rem;
      font-weight: 600;
      color: #f3f4f6;
    }

    span:last-of-type {
      color: #9ca3af;
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
`
