import api from './api'

const fetchMessage = {
  send: (payload) => api.post(`/message`, payload),
  getMessages: (params, abortSignal) =>
    api.get('/message/get-from-chat', { params, signal: abortSignal }),
  getUnviewedCount: () => api.get('/message/get-not-viewed-count'),
  getPrevious: (params, abortSignal) =>
    api.get('/message/get-previous-chats', { params, signal: abortSignal })
}

export default fetchMessage
