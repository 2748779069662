import { useRef, useState } from 'react'
import { Text, Group, Button, rem, useMantineTheme } from '@mantine/core'
import { Dropzone, MIME_TYPES } from '@mantine/dropzone'
import classes from './DropzoneButton.module.css'
import CustomDropzone from './CustomDropzone'
import fetchFile from '../../../../../../../services/file'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export const Arquives = ({ data, setData, disabled }) => {
  const theme = useMantineTheme()
  const openRef = useRef(null)

  const { t } = useTranslation()

  const handleFileDelete = (filesToDelete) => {
    if (!Array.isArray(filesToDelete)) {
      filesToDelete = [filesToDelete]
    }

    filesToDelete.forEach((fileToDelete) => {
      if (fileToDelete.id) {
        fetchFile
          .delete(fileToDelete.id)
          .then((res) => {
            if (res.data) {
              toast.success(t('toast.successDeleteFile'))
            }
          })
          .catch((err) => {
            console.error('Erro ao deletar o arquivo:', err)
          })
      }
      setData((prevData) => ({
        ...prevData,
        files: prevData?.files?.filter((file) => file !== fileToDelete) || []
      }))
    })
  }

  const readFileAsBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.readAsDataURL(file)
    })
  }

  const handleFileUpload = async (newFiles, setData, prevData, identifier) => {
    try {
      const filesData = await Promise.all(
        newFiles.map(async (newFile) => {
          const base64Data = await readFileAsBase64(newFile)
          const base64Parts = base64Data.split(',')

          if (base64Parts.length === 2) {
            return {
              title: identifier,
              fileName: newFile.name,
              fileExtension: newFile.type.split('/')[1],
              url: base64Data,
              base64: base64Parts[1],
              identifier
            }
          }
        })
      )

      const existingFiles = prevData.files.filter(
        (file) => file.identifier !== identifier
      )
      const newFilesData = filesData.filter(Boolean)

      setData({
        ...prevData,
        files: [...existingFiles, ...newFilesData]
      })
    } catch (error) {
      console.error('Erro ao ler os arquivos como base64', error)
    }
  }

  return (
    <div>
      <CustomDropzone
        openRef={openRef}
        onDrop={(newFiles) => {
          handleFileUpload(newFiles, setData, data, 'foto-categoria')
        }}
        identifier="foto-categoria"
        files={data.files}
        theme={theme}
        classes={classes}
        handleDelete={handleFileDelete}
        maxFiles={2}
        disabled={disabled}
        data={data}
        setData={setData}
      />
    </div>
  )
}
