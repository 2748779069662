import * as Styled from './styles'
import { Navbar } from '../../components/Navbar'
import Footer from '../../components/Footer'
import { useTranslation } from 'react-i18next'
import { Fragment } from 'react'

const DATA = (t) => [
  {
    title: t('policies.protection.title'),
    content: t('policies.protection.content')
  },
  {
    title: t('policies.objective.title'),
    content: t('policies.objective.content')
  },
  {
    title: t('policies.data.title'),
    content: t('policies.data.content')
  },
  {
    title: t('policies.cookies.title'),
    content: t('policies.cookies.content')
  },
  {
    title: t('policies.updates.title'),
    content: t('policies.updates.content')
  }
]

export const Policies = () => {
  const { t } = useTranslation()
  const TRANSLATED_DATA = DATA(t)
  return (
    <>
      <Navbar />
      <Styled.Container>
        <Styled.ContentContainer>
          <Styled.Title>
            <h1>{t('policies.title')}</h1>
          </Styled.Title>

          <Styled.InfoContainer>
            {TRANSLATED_DATA.map((info) => (
              <Fragment key={info.title}>
                <Styled.SubTitle>{info.title}</Styled.SubTitle>
                <Styled.Information>{info.content}</Styled.Information>
              </Fragment>
            ))}
            <strong>{t('policies.updatedAt')}</strong>
          </Styled.InfoContainer>
        </Styled.ContentContainer>
      </Styled.Container>
      <Footer />
    </>
  )
}
