import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  width: 100%;
  align-items: flex-start;
`
export const Title = styled.h1`
  font-family: Neometric;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.01em;
  text-align: left;
`
export const ConfirmButton = styled.button`
  display: flex;
  width: 220px;
  height: 48px;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: #27c7ff;
  color: var(--white-grayscale, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;
  text-transform: uppercase;
  border: 2px solid var(--yellow-brand-primary, #27c7ff);

  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
export const Content = styled.div`
  width: 100%;
  margin-top: -5rem;

  .mantine-Tabs-tabsList {
    width: fit-content;

    button {
      border-bottom-width: 0.2rem;
      color: #404040;
      font-family: Neometric;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 500;
      line-height: 130%; /* 20.8px */
      letter-spacing: 0.16px;
      width: 11.5rem;

      &[data-active='true'] {
        color: #27c7ff;
      }
    }
  }

  /* > div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1.6rem;
  } */

  .pagination {
    justify-content: center;
    margin-top: 16px;
  }
`

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;

  #input-demo {
    display: flex;
    padding: 0px 8x;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 0.5px solid var(--grey-3-grayscale, #bfbfbf);
    height: 40px;

    color: #a2a2a2;
    font-family: Neometric;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  #select-filter {
    height: 40px;

    width: 12rem;
    border-radius: 8px;
    border: 0.508px solid var(--grey-3-grayscale, #bfbfbf);
    color: var(--grey-2-grayscale, #808080);
    font-family: Neometric;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .mantine-Input-wrapper {
    margin-top: 0;
  }
  .activeCount {
    display: flex;
    height: 18px;
    padding: 8px 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: var(--yellow-brand-primary, #27c7ff);
    color: var(--white-grayscale, #fff);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 14.4px */
    letter-spacing: 0.12px;
  }
`

export const InputsWrapper = styled.div`
  margin-top: 1.6rem;

  > p {
    color: #222222;
    font-family: Neometric;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  > div {
    width: 30rem;
    margin-top: 1.4rem;
  }

  label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #0d0d0d;
    margin: 1rem 0;

    &.solo {
      display: block;
      margin-top: 2rem;
    }
  }

  .mantine-Input-icon svg {
    stroke: #bfbfbf;
  }

  select {
    cursor: pointer;
  }

  input:not(.mantine-TimeInput-input):not(.mantine-Radio-radio),
  select,
  .mantine-InputWrapper-root .mantine-Input-wrapper > button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12.1898px 8px;
    padding-left: 3.2rem;
    gap: 112.77px;

    width: 100%;
    height: 54px;

    border: 0.704782px solid rgba(56, 40, 14, 0.3);
    border-radius: 8.45738px;

    margin-bottom: 10px;

    font-family: 'Neometric';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #0d0d0d;

    &:placeholder {
      color: #a2a2a2;
    }

    &:focus {
      border-color: #27c7ff;
    }

    button {
      .mantine-ActionIcon-root .btn-minus .mantine-47hpvh {
        height: 22px;
        width: 22px;
      }
    }

    &.empty {
      color: #a2a2a2;
    }

    option {
      color: #0d0d0d;
    }
  }
`
