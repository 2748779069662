import { useEffect, useRef, useState } from 'react'

import fetchAccount from '../../../../../../services/account'

import debounce from '../../../../../../utils/debounce'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../../contexts/useAuth'
import { Polling, listOfChatsTimeout } from '../../utils/polling'
import { useTranslation } from 'react-i18next'

let controller = new AbortController()
let abortSignal = controller.signal

export function useChatsList() {
  const { user } = useAuth()

  const pollingTimeoutRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [chatsAndUsers, setChatsAndUsers] = useState([])

  const { t } = useTranslation()

  async function searchForUser(name = '') {
    if (!name) {
      setChatsAndUsers([])
      controller = new AbortController()
      abortSignal = controller.signal

      Polling.listOfChats({
        setData: setChatsAndUsers,
        setLoading,
        abortSignal,
        pollingTimeoutRef
      })

      return
    }

    controller.abort()
    clearTimeout(listOfChatsTimeout)

    debounce(async () => {
      setLoading(true)
      setChatsAndUsers([])

      try {
        const { data, status } = await fetchAccount.getAllAccount({
          name,
          role: user.role !== 'ADMIN' ? 'ADMIN' : undefined
        })

        if (status === 200)
          setChatsAndUsers(data?.content.map((user) => ({ user })))
      } catch (error) {
        toast.error(t('toast.errorToGetMessages'))
      } finally {
        setLoading(false)
      }
    }, 600)
  }

  function dispose() {
    controller.abort()
    clearTimeout(listOfChatsTimeout)
  }

  useState(() => {
    Polling.listOfChats({
      setData: setChatsAndUsers,
      setLoading,
      abortSignal
    })

    return () => {
      clearTimeout(listOfChatsTimeout)
    }
  }, [])

  return {
    loading,
    chatsAndUsers,
    searchForUser,
    dispose
  }
}
