import styled from 'styled-components'
import { LinksGroup } from './NavbarLinksGroup'
export const Container = styled.div`
  .active {
    background-color: #DFF7FF;
  }

  .mantine-Menu-dropdown {
    width: 100% !important;
    bottom: 55px;
    top: auto !important;
  }

  .mantine-Menu-itemLabel label {
    font-family: 'Neometric';
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;

    max-width: 152;
    text-overflow: 'ellipsis';
    overflow: 'hidden';
    white-space: 'nowrap';
  }

  .mantine-Navbar-root {
    width: max-content;
  }

  @media (max-width: 1024px) {
    & {
      /* display: none; */
      position: absolute;
      z-index: 1003;
    }
  }
`

export const LogOutButton = styled.button`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  width: 230px;
  border-radius: 8px;
  background: var(--yellow-2-support, #DFF7FF);
  justify-content: center;

  color: var(--yellow-3-support, #1D95BF);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;
  text-transform: uppercase;
`

export const LogoutWrapper = styled.div`
  display: none;

  @media (max-width: 500px) {
    display: block;
    margin-bottom: 10px;
  }
`
