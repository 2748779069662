import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  StickyParent
} from './styles'
import { LateralCards } from './components/LateralCards'
import { FeaturesCards } from './components/FeaturesCards'
import { ScheduleNow } from './components/ScheduleNow'
import { CarouselSkeleton } from './components/CarouselSkeleton'
import { api } from '../../api'
import { useQuery } from '@tanstack/react-query'
import { format, parseISO } from 'date-fns'
import pt from 'date-fns/locale/pt'
import { useTranslation } from 'react-i18next'

import { useState } from 'react'

export const EventCarousel = () => {
  const [currEventIdx, setCurrEventIdx] = useState(0)
  const language = localStorage.getItem('language')

  const { t } = useTranslation()

  const { data: eventsList, isLoading: isEventListLoading } = useQuery({
    queryKey: ['get-events', language],
    queryFn: async () => {
      const events = await api.get(
        `/events?populate=mainImage&${language ? `locale=${language}` : ''}`
      )

      return events.data
    },
    refetchOnWindowFocus: false
  })

  const { data: comission, isLoading: isComissionLoading } = useQuery({
    queryKey: ['get-comission'],
    queryFn: async () => {
      const { data } = await api.get('/comission')

      return data
    },
    refetchOnWindowFocus: false
  })

  const tourComission = comission?.data?.attributes?.tourComission ?? 0

  if (isEventListLoading || isComissionLoading) {
    return <CarouselSkeleton />
  }

  return (
    <section style={{ position: 'relative', paddingBottom: '15rem' }}>
      <Carousel
        onScrollNext={() => setCurrEventIdx((oldState) => oldState + 1)}
        onScrollPrev={() => setCurrEventIdx((oldState) => oldState - 1)}
        watchDrag={false}
      >
        <CarouselContent>
          {eventsList?.data.map((event) => {
            const eventDate = parseISO(event.attributes.date)
            const localFormattedEventDate = format(eventDate, 'EEE, dd/MM', {
              locale: pt
            })
            const capitalizeFormattedEventDate =
              localFormattedEventDate.charAt(0).toUpperCase() +
              localFormattedEventDate.slice(1)

            const backgroundImageStyle = {
              backgroundImage: `url(${event.attributes.mainImage.data?.attributes.url})`
            }

            return (
              <>
                <CarouselItem key={event.id}>
                  <div style={backgroundImageStyle}>
                    <div>
                      {/* <HeaderPlaceholder textColor='white' /> */}

                      <div>
                        <CarouselPrevious />
                        <CarouselNext />
                      </div>

                      {/* <div>
                        <h1>{event.attributes.name}</h1>

                        <span>{capitalizeFormattedEventDate}</span>

                        <div>
                          <span>€ {event.attributes.pricePerPerson}</span>
                          <span>/{t('events.perPerson')}</span>
                        </div>
                      </div> */}
                    </div>

                    <div>
                      <div />
                    </div>
                  </div>

                  <div>
                    <div>
                      <div>
                        <div>
                          <h2>{event.attributes.name}</h2>
                          <strong>{capitalizeFormattedEventDate}</strong>
                          <p>{event.attributes.description}</p>
                        </div>

                        <div>
                          <FeaturesCards />
                        </div>
                      </div>
                      <div>
                        <ScheduleNow
                          eventPrice={
                            // event.attributes.pricePerPerson + tourComission
                            event.attributes.pricePerPerson
                          }
                          minNPassengers={event.attributes.minNPassengers}
                          maxNPassengers={event.attributes.maxNPassengers}
                          nameEvent={event.attributes.name}
                          strapiEventId={String(event.id)}
                          fullEvent={event}
                        />

                        <LateralCards />
                      </div>
                    </div>
                  </div>
                </CarouselItem>
              </>
            )
          })}
        </CarouselContent>
      </Carousel>
      <StickyParent>
        <div>
          {!isEventListLoading && (
            <ScheduleNow
              eventPrice={
                // event.attributes.pricePerPerson + tourComission
                eventsList.data[currEventIdx]?.attributes.pricePerPerson
              }
              minNPassengers={
                eventsList.data[currEventIdx]?.attributes.minNPassengers
              }
              maxNPassengers={
                eventsList.data[currEventIdx]?.attributes.maxNPassengers
              }
              nameEvent={eventsList.data[currEventIdx]?.attributes.name}
              strapiEventId={String(eventsList.data[currEventIdx]?.id)}
              fullEvent={eventsList.data[currEventIdx]}
            />
          )}
          <LateralCards />
        </div>
      </StickyParent>
    </section>
  )
}
