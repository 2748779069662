import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const H = styled.h3`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: 0.01em;
  line-height: 100%;
  font-weight: 800;
  font-family: inherit;
  @media screen and (max-width: 960px) {
    font-size: 24px;
  }
  @media screen and (max-width: 599px) {
    font-size: 24px;
  }
`
const LucidelistChecksIcon = styled.img`
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
`
const Img = styled.div`
  border-radius: 16px;
  background-color: #DFF7FF;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
`
const H1 = styled.h5`
  margin: 0;
  width: auto;
  position: relative;
  font-size: inherit;
  letter-spacing: 0.01em;
  line-height: 110%;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
`
const Img01Parent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
  @media screen and (max-width: 599px) {
    flex: unset;
    align-self: stretch;
  }
`
const RowFrame1171275209Child = styled.img`
  width: 60px;
  position: relative;
  max-height: 100%;
  @media screen and (max-width: 960px) {
    width: 60px;
    transform: rotate(90deg);
  }
`
const RowFrame1171275209Item = styled.img`
  width: 60px;
  position: relative;
  max-height: 100%;
  @media screen and (max-width: 960px) {
    transform: rotate(90deg);
  }
`
const TreinamentoEProtocolo = styled.p`
  margin: 0;
`
const H5DeSeguranaContainer = styled.h5`
  margin: 0;
  width: 231px;
  position: relative;
  font-size: inherit;
  letter-spacing: 0.01em;
  line-height: 110%;
  font-weight: 700;
  font-family: inherit;
  display: inline-block;
`
const RowFrame = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;
  font-size: 16px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    gap: 24px;
  }
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
`
const H3ParentRoot = styled.div`
  align-self: stretch;
  border-radius: 24px;
  background-color: #27c7ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  gap: 24px;
  margin-top: -28px;
  text-align: center;
  font-size: 32px;
  color: #fff;
  font-family: Neometric;
  z-index: 2;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`

const ProcessDivContainer = () => {
  const { t } = useTranslation()
  return (
    <H3ParentRoot>
      <H>{t('qualityProcess')}</H>
      <RowFrame>
        <Img01Parent>
          <Img>
            <LucidelistChecksIcon alt="" src="/lucidelistchecks.svg" />
          </Img>
          <H1>{t('interviewCandidates')}</H1>
        </Img01Parent>
        <RowFrame1171275209Child alt="" src="/vector-60.svg" />
        <Img01Parent>
          <Img>
            <LucidelistChecksIcon alt="" src="/lucidesearch.svg" />
          </Img>
          <H1>{t('documentAndVehicleVerification')}</H1>
        </Img01Parent>
        <RowFrame1171275209Item alt="" src="/vector-60.svg" />
        <Img01Parent>
          <Img>
            <LucidelistChecksIcon alt="" src="/lucidealertcircle.svg" />
          </Img>
          <H5DeSeguranaContainer>
            <TreinamentoEProtocolo>
              {t('trainingAndSafetyProtocol')}
            </TreinamentoEProtocolo>
          </H5DeSeguranaContainer>
        </Img01Parent>
        <RowFrame1171275209Item alt="" src="/vector-60.svg" />
        <Img01Parent>
          <Img>
            <LucidelistChecksIcon alt="" src="/lucidecheckcircle2.svg" />
          </Img>
          <H1>{t('readyForFirstTrip')}</H1>
        </Img01Parent>
      </RowFrame>
    </H3ParentRoot>
  )
}

export default ProcessDivContainer
