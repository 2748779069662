import * as Styled from './styles'
import GoogleImage from '../../../../assets/google_image.svg'
import CardCarousel from './components/Card'
import { useTranslation } from 'react-i18next'

export const GoogleComments = () => {
  const { t } = useTranslation()
  return (
    <Styled.Container>
      <img src={GoogleImage} alt="Ilustração de avaliações do google" />
      <span>
        {t('basedOn')} <strong>243 avaliações</strong>
      </span>
      <CardCarousel />
    </Styled.Container>
  )
}
