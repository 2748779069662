import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  ContentColumn,
  ColumnLinkItem,
  ColumnNotLinkItem,
  ColumnTitle,
  Contact,
  Container,
  Content,
  Copyright,
  ImagesColumn,
  MediaIcon,
  MediasWrapper,
  NotItemsSection,
  Separator,
  ContainerColumn
} from './styles'
import { ReactComponent as EasyTransferLogo } from './imgs/EasyTransfer - logo full white.svg'
import WhiteFrame from './imgs/white_frame.webp'
import { ReactComponent as FacebookLogo } from './imgs/facebook_logo.svg'
import { ReactComponent as InstagramLogo } from './imgs/instagram_logo.svg'
import { ReactComponent as LinkedInLogo } from './imgs/linkedin_logo.svg'
import { Link } from 'react-router-dom'
import { ComplaintsBookLink } from './components/ComplaintsBookLink'

export default function Footer({ dark = false }) {
  const { t } = useTranslation()

  const year = new Date().getFullYear()

  return (
    <Container dark={dark}>
      <Content>
        <ImagesColumn>
          <Link to="/">
            <EasyTransferLogo />
          </Link>
          <a
            href="https://www.livroreclamacoes.pt/inicio"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ComplaintsBookLink />
          </a>
        </ImagesColumn>
        <ContainerColumn>
          <ContentColumn>
            <ColumnTitle>{t('footer.navigation')}</ColumnTitle>
            <ColumnLinkItem href="#">{t('footer.home')}</ColumnLinkItem>
            <ColumnLinkItem href="#">{t('footer.services')}</ColumnLinkItem>
            <ColumnLinkItem href="#">{t('footer.fleet')}</ColumnLinkItem>
            <ColumnLinkItem href="#">{t('footer.clients')}</ColumnLinkItem>
          </ContentColumn>
          <ContentColumn>
            <ColumnTitle>{t('footer.usefulLinks')}</ColumnTitle>
            <ColumnLinkItem href="/termsconditions">
              {t('footer.termsConditions')}
            </ColumnLinkItem>
            <ColumnLinkItem href="/refund">
              {t('reembolso.title')}
            </ColumnLinkItem>
            <ColumnLinkItem href="/policies">
              {t('footer.privacyPolicy')}
            </ColumnLinkItem>
            <ColumnLinkItem href="#">
              {t('footer.paymentMethods')}
            </ColumnLinkItem>
            {/* <ColumnLinkItem href="#">{t('footer.blog')}</ColumnLinkItem> */}
          </ContentColumn>
          <ContentColumn>
            <ColumnTitle>{t('footer.support')}</ColumnTitle>
            <ColumnNotLinkItem>{t('footer.phone')}:</ColumnNotLinkItem>
            <NotItemsSection>
              <Contact href="tel:+351963650278">+351 963 650 278</Contact>
              <Contact href="tel:+351927392732">+351 927 392 732</Contact>
            </NotItemsSection>
            <ColumnNotLinkItem>{t('footer.email')}:</ColumnNotLinkItem>
            <NotItemsSection>
              <Contact href="mailto:geral@easytransferericeira.com">
                geral@easytransferericeira.com
              </Contact>
            </NotItemsSection>
            <MediasWrapper>
              <MediaIcon
                href="https://www.facebook.com/EasyTransferEriceira"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookLogo />
              </MediaIcon>
              {/* <MediaIcon>
              <TwitterLogo />
            </MediaIcon> */}
              <MediaIcon
                href="https://www.instagram.com/easytransferericeira/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramLogo />
              </MediaIcon>
              <MediaIcon href="#">
                <LinkedInLogo />
              </MediaIcon>
            </MediasWrapper>
          </ContentColumn>
        </ContainerColumn>
      </Content>
      <Separator />
      <Copyright>
        Copyright © 2020 - {year} EasyTransfer. All rights reserved.
      </Copyright>
    </Container>
  )
}
