import { useState } from 'react'
import FirstImage from '../../imgs/carousel-first.webp'
import * as S from './styles'

const images = [FirstImage]

export const ImagesCarousel = () => {
  const [currentImage, setCurrentImage] = useState(0)

  return (
    <S.CarouselContainer>
      <S.SelectedImage src={images[currentImage]} />
    </S.CarouselContainer>
  )
}
