import React, { useState } from 'react'
import * as Styled from './styles'
import { ReactComponent as SideVector } from '../imgs/Vector.svg'
import { ReactComponent as LucideArrow } from '../imgs/lucide_arrow-right2.svg'
import { ReactComponent as LucideRoutes } from '../imgs/lucide_route.svg'
import { ReactComponent as LucideMapPin } from '../imgs/lucide_map-pin.svg'
import { ReactComponent as NextButtonIcon } from '../imgs/Next.svg'
import { useNavigate } from 'react-router-dom'
export function TransferBar({ transferInfo, index }) {
  const navigate = useNavigate()
  const position = index + 1
  const ammountOfStops = transferInfo?.stops.length

  return (
    <Styled.TransferContainer
      onClick={() => navigate(`/dashboard/order-bump/${transferInfo.id}`)}
    >
      <Styled.RoutesInfo>
        <Styled.RouteNum>
          <Styled.RouteLabel>Rota</Styled.RouteLabel>
          <Styled.RouteNumLabel>
            {position.toString().padStart(2, '0')}
          </Styled.RouteNumLabel>
        </Styled.RouteNum>
        <SideVector id="sidevector" />
        <Styled.StartingPoint>
          <Styled.FromToContainer>
            <LucideMapPin />
            <Styled.StartText>Partida</Styled.StartText>
          </Styled.FromToContainer>
          <Styled.StartingLocation>
            {transferInfo.startingPoint}
          </Styled.StartingLocation>
        </Styled.StartingPoint>
        <Styled.RouteDistanceContainer>
          <Styled.RouteNumDistance>
            {transferInfo.distanceInKm}Km
          </Styled.RouteNumDistance>
          <Styled.ArrowIconContainer>
            <LucideArrow />
          </Styled.ArrowIconContainer>
        </Styled.RouteDistanceContainer>
        <Styled.ArrivingPoint>
          <Styled.FromToContainer>
            <LucideMapPin />
            <Styled.ArrivingText>Chegada</Styled.ArrivingText>
          </Styled.FromToContainer>
          <Styled.ArrivingLocation>
            {transferInfo.arrivingPoint}
          </Styled.ArrivingLocation>
        </Styled.ArrivingPoint>
        <SideVector id="sidevector" />
      </Styled.RoutesInfo>

      <Styled.StopsContainer>
        <Styled.StopsHeaderInfo>
          <Styled.StopsLabel>
            <LucideRoutes />
            <Styled.StopsText>Quantidade de Paradas</Styled.StopsText>
          </Styled.StopsLabel>
          <Styled.RouteStopsQty>
            {ammountOfStops === 1
              ? `${ammountOfStops} Parada`
              : `${ammountOfStops} Paradas`}
          </Styled.RouteStopsQty>
        </Styled.StopsHeaderInfo>
      </Styled.StopsContainer>
      <Styled.NextButtonContainer>
        <NextButtonIcon />
      </Styled.NextButtonContainer>
    </Styled.TransferContainer>
  )
}
