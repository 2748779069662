import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  height: 95vh;
  overflow: auto;

  .visible {
    opacity: 1;
    visibility: visible;
  }
  .hidden {
    opacity: 0;
    visibility: hidden;
  }

  @media (max-width: 1024px) {
    display: block;
    overflow: auto;
  }
`

export const Background = styled.img`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const CardContainer = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
`

export const CardFixContainer = styled.div`
  display: block;

  @media (max-width: 1024px) {
    display: none;
  }
`
