import {
  UnstyledButton,
  Group,
  Avatar,
  Text,
  createStyles
} from '@mantine/core'

import { ReactComponent as BubleIcon } from './icons/lucide_messages-square.svg'
import { useEffect, useState } from 'react'
import fetchMessage from '../../../../../../../services/chat'

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,

    width: 230,
    margin: '0 10px',

    padding: theme.spacing.md,

    backgroundColor: '#404040',
    transition: 'all ease-in-out 200ms',
    borderRadius: 8,

    '&:hover': {
      backgroundColor: '#303030'
    }
  },
  title: {
    fontFamily: 'Neometric',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '28px',
    letterSpacing: '0em',
    color: '#FFF'
  },
  messageCountContainer: {
    position: 'absolute',
    top: -8,
    left: '75%',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    minWidth: 22,
    height: 22,
    borderRadius: 24,

    padding: '0 5px',
    backgroundColor: '#F8294E'
  },
  messageCountText: {
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#FFF'
  }
}))

export function ChatButton() {
  const { classes } = useStyles()

  return (
    <UnstyledButton className={classes.container}>
      <BubleIcon />

      <Text className={classes.title} weight={500}>
        Chat
      </Text>

      <MessageCount />
    </UnstyledButton>
  )
}

// POLLING CONTROLLER
let pollingTimeout
async function pollingMessages(setState) {
  const { data, status } = await fetchMessage.getUnviewedCount()

  if (status === 200) {
    setState(data.notViewedMessagesCount)

    pollingTimeout = setTimeout(() => {
      pollingMessages(setState)
    }, 8000)
  }
}

export function MessageCount() {
  const { classes } = useStyles()
  const [unviewedMessages, setUnviewedMessages] = useState(0)

  const count = unviewedMessages >= 100 ? '99+' : unviewedMessages

  useEffect(() => {
    pollingMessages(setUnviewedMessages)

    return () => {
      clearTimeout(pollingTimeout)
    }
  }, [])

  if (unviewedMessages === 0) return <></>

  return (
    <UnstyledButton className={classes.messageCountContainer}>
      <Text className={classes.messageCountText} weight={500}>
        {count}
      </Text>
    </UnstyledButton>
  )
}
