import React, { useState } from 'react'
import * as Styled from './styles'
import { useChatStore } from '../../context/ChatContext'

import { useAuth } from '../../../../../../contexts/useAuth'

export function ChatInput() {
  const addNewMessage = useChatStore((state) => state.addNewMessage)
  const [message, setMessage] = useState('')

  function sendMessage() {
    if (!message) return

    addNewMessage(message)
    setMessage('')
  }

  function captureKeyPress(event) {
    const pressCtrlOrCommand = event.metaKey || event.ctrlKey

    if (pressCtrlOrCommand && event.key === 'Enter') {
      sendMessage()
    }
  }

  return (
    <Styled.Container>
      <textarea
        cols="1"
        rows="5"
        value={message}
        onChange={({ target }) => setMessage(target.value)}
        onKeyDown={captureKeyPress}
      />

      <Styled.SendButton onClick={sendMessage}>
        <Styled.SendText>Enviar</Styled.SendText>
      </Styled.SendButton>
    </Styled.Container>
  )
}
