import { motion } from 'framer-motion'
import styled from 'styled-components'

export const CarouselWrapper = styled.section`
  padding: clamp(16px, 0.833vw, 0.833vw);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: clamp(16px, 0.833vw, 0.833vw);
  justify-content: center;
  align-items: center;
  position: static;

  color: #0d0d0d;
  .mantine-Carousel-container {
    align-items: flex-end !important;
  }

  @media (max-width: 1290px) {
    flex-direction: column-reverse;
    gap: clamp(32px, 1.66vw, 1.66vw);
  }

  margin-top: 10vh;

  margin-bottom: 150px;

  @media (max-width: 768px) {
    margin-bottom: 64px;
  }
`

export const BackgroundShape = styled.div`
  height: clamp(300px, 50vh, 50vh);

  width: 50%;
  border-top-left-radius: clamp(16px, 0.833vw, 0.833vw);
  border-bottom-left-radius: clamp(16px, 0.833vw, 0.833vw);

  position: absolute;
  top: -2vh;
  right: 0;
  z-index: -1;

  @media (max-width: 1290px) {
    top: 0;
    height: clamp(240px, 24vw, 24vw);
    width: 60vw;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`

export const CarouselContainer = styled(motion.div)`
  width: 45vw;
  overflow: visible;

  @media (max-width: 1290px) {
    width: 30rem !important;
  }

  @media (max-width: 860px) {
    width: clamp(240px, 60vw, 100%);
  }

  @media (max-width: 400px) {
    width: 100%;
  }

  button {
    border: none;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: clamp(16px, 0.833vw, 0.833vw);
  width: clamp(240px, 25vw, 100%);

  @media (max-width: 1290px) {
    width: clamp(240px, 60vw, 100%);
  }

  @media (max-width: 400px) {
    width: 100%;
  }

  h3 {
    color: #27c7ff;
    font-family: 'Neometric', Neometric;
    font-size: clamp(16px, 0.833vw, 0.833vw);
  }

  h2 {
    color: #222222;
    font-family: 'Neometric', Neometric;
    font-size: clamp(32px, 1.667vw, 1.667vw);
  }

  div {
    display: flex;
    align-items: center;
    gap: clamp(8px, 0.42vw, 0.42vw);

    svg {
      flex-shrink: 0;
      width: clamp(16px, 0.833vw, 0.833vw);
      height: clamp(16px, 0.833vw, 0.833vw);
      color: #27c7ff;
    }

    span {
      color: #404040;
      font-family: 'Poppins', Poppins;
      font-size: clamp(16px, 0.833vw, 0.833vw);
    }
  }

  button {
    display: flex;
    width: 264px;
    height: 56px;
    padding: 16px 24px 16px 32px;
    justify-content: center;
    align-items: center;
    gap: 16px;

    border-radius: 16px;
    background: var(--yellow-brand-primary, #27c7ff);
    box-shadow: 0px 18.048px 19.689px 0px rgba(0, 0, 0, 0.05),
      0px 4px 8px 0px rgba(0, 0, 0, 0.1);

    color: #fff;
    text-align: center;
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
    letter-spacing: 0.16px;
    text-transform: uppercase;

    transition: ease-in-out 300ms;

    :hover {
      background-color: #fff;
      color: #27c7ff;
      border: solid 1px #fff;
    }
    @media (max-width: 1290px) {
      /* margin-right: 0; */
      align-self: center;
    }

    svg {
      width: clamp(16px, 0.833vw, 0.833vw);
      height: clamp(16px, 0.833vw, 0.833vw);
    }
  }

  @media (max-width: 768px) {
    margin-top: 24px;
    width: 100%;
  }
`

export const OrangeContainer = styled(motion.div)`
  background: #27c7ff;
  height: 503px;
  width: 55%;
  border-radius: 0px 32px 32px 0px;
  margin: 125px 0 231px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1022px) {
    width: 98%;

    justify-content: flex-end;
    padding-bottom: 4.2rem;
  }
`

export const FirstTitle = styled.h1`
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 120%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
`
export const SecondTitle = styled.h1`
  font-weight: 600;
  font-size: 4.8rem;
  line-height: 57.6px;
  margin-top: -28px;
`
export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`

export const ListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const ListItemText = styled.span`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 19px;
`

export const ScheduleButton = styled.button`
  background: #ffffff;
  cursor: pointer;
  width: 219px;
  height: 64px;
  border-radius: 16px;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 32px;
  gap: 16px;

  transition: ease-in-out 300ms;

  &:hover {
    background-color: #27c7ff;
    color: white;
    border: solid 1px #fff;
    span {
      color: white;
    }

    path {
      stroke: #fff;
    }
  }
`

export const TextWrapper = styled.div`
  width: 551px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 44.68px;

  @media only screen and (max-width: 1022px) {
    width: 96%;
    gap: 0;

    ${FirstTitle} {
      font-size: 1.8rem;
      margin-bottom: 3.2rem;
    }

    ${SecondTitle} {
      font-size: 3.6rem;
      margin-bottom: 2.4rem;
      line-height: 3.6rem;
    }

    ${ListWrapper} {
      margin-bottom: 2.4rem;
    }

    ${ListItemText} {
      font-size: 1.2rem;
    }

    ${ScheduleButton} {
      width: 100%;
    }
  }
`

export const ButtonLabel = styled.span`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

  font-family: 'Roboto', sans-serif;

  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
`
export const Image = styled.img`
  width: clamp(276px, 20vw, 20vw);
  height: clamp(276px, 20vw, 20vw);
  border-radius: 14px;
  overflow: hidden;

  background: rgba(255, 255, 255, 0);
`

export const ImageTitleContainer = styled.div`
  position: absolute;
  bottom: 10%;
  width: clamp(200px, 20vw, 20vw);
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const ImageTitle = styled.h3`
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: white;
`

export const EmphasisTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  gap: 8px;

  width: 9.8rem;
  height: 3.5rem;

  background: #27c7ff;
  border-radius: 8px;

  position: absolute;
  top: 16px;
  left: 16px;

  span {
    font-family: 'Neometric';
    font-weight: 500;
    font-size: 14px;
    line-height: 16.8px;

    display: flex;
    align-items: center;
    letter-spacing: 1%;

    color: #ffffff;
  }

  @media only screen and (max-width: 1022px) {
    width: 6.4rem;
    height: 2.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4rem;
    padding: 0;

    span {
      font-size: 1rem;
    }
  }
`
