import * as Styled from './styles'
import { Eye, User } from 'lucide-react'
import { formatCurrency } from '../../../../../../utils/formatCurrency'
import { typeLabelDecider, iconDecider } from './constants'
import dayjs from 'dayjs'
import { useAuth } from '../../../../../../contexts/useAuth'
import easyTransfer from '../../assets/easy.png'

export const HistoricItem = ({
  user,
  driver,
  type,
  value,
  transfers,
  invertedUsers,
  message = undefined,
  onOpenDetails,
  paidAt
}) => {
  const {
    ROLE,
    user: { hostel, company }
  } = useAuth()
  const reducedMessage = message ? message.substring(0, 36) : undefined
  const messageHasEllipsis = message && message.length > reducedMessage.length
  const receivedFromDriver =
    ROLE === 'ADMIN' && type === 'income' && user.id === driver.id
  const paidToManagement =
    (ROLE === 'COMPANY_USER' || ROLE === 'USER') &&
    type === 'outcome' &&
    user.id === driver.id

  console.log(
    user.id,
    driver.id,
    'TESTE',
    paidToManagement,
    ROLE === 'COMPANY_USER',
    ROLE === 'USER',
    type,
    user.id === driver.id
  )

  return (
    <Styled.HistoricItemWrapper
      invertedUsers={
        (type !== 'charge' && invertedUsers) ||
        ((ROLE === 'HOTEL_USER' || ROLE === 'COMPANY_ADMIN') && invertedUsers)
      }
      type={type}
    >
      <div>
        <Styled.AccountInfo
          style={
            invertedUsers && !(paidToManagement || receivedFromDriver)
              ? ROLE === 'HOTEL_USER' || ROLE === 'COMPANY_ADMIN'
                ? { paddingRight: 0 }
                : undefined
              : { paddingRight: 0 }
          }
        >
          <div>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {(['ADMIN', 'HOTEL_USER', 'COMPANY_ADMIN'].includes(ROLE) ||
                (paidToManagement &&
                  (ROLE === 'USER' || ROLE === 'COMPANY_USER'))) &&
              user.profilePicture ? (
                <img
                  src={
                    receivedFromDriver ||
                    paidToManagement ||
                    ROLE === 'HOTEL_USER' ||
                    ROLE === 'COMPANY_ADMIN'
                      ? easyTransfer
                      : user.profilePicture.url
                  }
                />
              ) : (
                <User size="3rem" />
              )}
            </span>
            <div>
              <strong>
                {receivedFromDriver ||
                paidToManagement ||
                ROLE === 'HOTEL_USER' ||
                ROLE === 'COMPANY_ADMIN'
                  ? 'EasyTransfer'
                  : user.name}
              </strong>
              {user.role === 'ADMIN' ||
              ROLE === 'HOTEL_USER' ||
              ROLE === 'COMPANY_ADMIN' ||
              receivedFromDriver ||
              paidToManagement ? (
                <span>admin</span>
              ) : (
                <p>#{user.id}</p>
              )}
            </div>
          </div>
        </Styled.AccountInfo>
        <Styled.HistoricItemValue>
          <Styled.HistoricItemIcon>{iconDecider[type]}</Styled.HistoricItemIcon>
          <p>
            € <p>{formatCurrency(value).split('€')[1]}</p>
          </p>
        </Styled.HistoricItemValue>
        <Styled.AccountInfo
          style={
            (ROLE === 'USER' || ROLE === 'COMPANY_USER') && type !== 'charge'
              ? { paddingRight: 0 }
              : undefined
          }
        >
          <div>
            <span
              style={
                driver.profilePicture
                  ? { display: 'block' }
                  : {
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }
              }
            >
              {driver.profilePicture ? (
                <img src={driver.profilePicture.url} />
              ) : (
                <User size="3rem" />
              )}
            </span>
            <div>
              <strong>
                {ROLE === 'HOTEL_USER' && hostel
                  ? hostel.name
                  : ROLE === 'COMPANY_ADMIN' && company
                  ? company.name
                  : driver.name}
              </strong>
              <p>
                #
                {ROLE === 'HOTEL_USER' && hostel
                  ? hostel.id
                  : ROLE === 'COMPANY_ADMIN' && company
                  ? company.name
                  : driver.id}
              </p>
            </div>
          </div>
        </Styled.AccountInfo>
      </div>
      <div>
        <p>
          {dayjs(paidAt).format('DD/MM/YYYY, HH:mm')} -{' '}
          <span>
            <strong>{`${typeLabelDecider[type]}${
              // descomentar depois
              // (transfers?.length === 0 || !transfers) &&
              // type === 'outcome' &&
              // ROLE === 'ADMIN'
              //   ? ' extra'
              //   : ''
              ''
            }`}</strong>{' '}
            {message && (
              <>
                por{' '}
                <span>
                  &quot;{reducedMessage + (messageHasEllipsis ? '...' : '')}
                  &quot;
                </span>
              </>
            )}
            {!message && !!transfers && transfers.length > 0 && (
              <>
                por <strong>{transfers.length}</strong> transfer(s) realizado(s)
              </>
            )}
          </span>
        </p>
        <button type="button" onClick={onOpenDetails}>
          <Eye size="1.8rem" color="#27c7ff" />
        </button>
      </div>
    </Styled.HistoricItemWrapper>
  )
}
