import styled, { css } from 'styled-components'
export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `}
`
export const Title = styled.h1`
  color: var(--black-brand-primary, #222);
  font-family: Neometric;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const SubTitle = styled.label`
  color: var(--grey-1-grayscale, #404040);
  font-family: Neometric;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const InputWrapper = styled.div`
  .mantine-InputWrapper-root {
    width: 100%;
  }
  .mantine-Input-input {
    display: flex;
    width: 100%;
    border-radius: 6.095px;
    color: #a2a2a2;
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 54px;
  }

  .mantine-Input-icon {
    width: 4rem;
  }

  .mantine-Input-input[data-with-icon] {
    padding-left: 4rem;
  }

  .mantine-InputWrapper-label {
    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
  }
`
