import { ReactComponent as Icon } from './imgs/icon.svg'
import { ReactComponent as Icon1 } from './imgs/icon2.svg'
import { ReactComponent as Icon2 } from './imgs/icon3.svg'

export const item = (t) => [
  {
    img: <Icon />,
    title: t('checkoutTour.Motoristas profissionais'),
    desc: t(
      'checkoutTour.Conduzimos com profissionalismo, o seu destino é o nosso compromisso!'
    )
  },
  {
    img: <Icon1 />,
    title: t('item.reliableService'),
    desc: t('item.reliableServiceDescription')
  },
  {
    img: <Icon2 />,
    title: t('item.customized'),
    desc: t('item.customizedDescription')
  }
]
