import * as Styled from './styles'
import { ReactComponent as CloseIcon } from './imgs/CloseIcon.svg'
import { useEffect, useState } from 'react'
import { useRef } from 'react'
import { formatErrorMessage, generatePayload, validateData } from './utils'
import { toast } from 'react-toastify'
import { moneyMask } from './masks'
import { AxiosError } from 'axios'
import fetchCarCategory from '../../services/carCategory'
import fetchFile from '../../services/file'
import Status from './components/Status'
import { useNavigate } from 'react-router-dom'
import {
  GOING_INITIAL_STATE,
  useTransferContext
} from '../../contexts/TransferContext'
import { useTranslation } from 'react-i18next'

export const ModalConfirmTransfer = ({ onClose, data, setData }) => {
  const navigate = useNavigate()
  const overlayRef = useRef(null)

  const { t } = useTranslation()

  const onClickOverlay = (e) => {
    if (overlayRef === null) return
    if (e.target !== overlayRef.current) return
    if (!onClose) return
    onClose()
  }

  return (
    <Styled.ModalOverlay ref={overlayRef} onClick={onClickOverlay}>
      <Styled.ModalContainer
        style={{
          alignItems: 'center'
        }}
      >
        <Styled.ModalHeader>
          <h2>{t('checkoutVerify.bookingConfirmation')}</h2>
          <Styled.CloseButton>
            <CloseIcon
              onClick={() => {
                onClose()
              }}
            />
          </Styled.CloseButton>
        </Styled.ModalHeader>
        <Styled.ModalContent>
          <Styled.TextContainer
            dangerouslySetInnerHTML={{
              __html: t('checkoutVerify.reservationConfirmationMessage')
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              marginTop: '16px'
            }}
          >
            <Status
              paymentMethod={data.paymentMethod}
              status={data.paymentStatus}
              type="payment"
              paymentRedirect={() => {
                if (data.paymentRedirect) {
                  window.location.href = data.paymentRedirect
                } else if (data.redirectUrl) {
                  window.location.href = data.redirectUrl
                }
              }}
            />
            <Status status={data.status} type="transfer" />
          </div>
        </Styled.ModalContent>
        <div
          style={{
            display: 'flex',
            gap: '16px',
            marginBottom: '16px'
          }}
        >
          <Styled.ModalButton
            onClick={() => {
              setData(GOING_INITIAL_STATE)
              navigate('/')
            }}
          >
            {t('checkoutVerify.backToHomePage')}
          </Styled.ModalButton>
        </div>
      </Styled.ModalContainer>
    </Styled.ModalOverlay>
  )
}
