import styled from 'styled-components'

export const Container = styled.div`
  width: 70%;
  margin: 150px auto;

  display: flex;
  justify-content: space-between;

  gap: 24px;

  transition: ease-in-out 300ms;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 0px;
  }

  @media (max-width: 768px) {
    gap: 12px;
  }

  @media (max-width: 900px) {
    margin: 64px auto;
    width: 100%;
    padding: 0px 16px;
  }

  @media (max-width: 360px) {
    padding: 0px 16px;
  }
`

export const TextAndImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: 1380px) {
    width: 500px;
  }

  @media (max-width: 900px) {
    width: 400px;
  }

  @media (max-width: 768px) {
    width: 350px;
  }

  @media (max-width: 600px) {
    width: 350px;
  }

  @media (max-width: 360px) {
    width: 100%;
  }
`

export const ImageContainer = styled.div`
  position: relative;

  min-width: 550px;

  img {
    width: 100%;
    height: 100%;
  }

  svg {
    width: 342.5px;
    height: 410px;

    z-index: -99;
    position: absolute;
    right: 79.5px;
    bottom: 65.766px;
  }

  @media only screen and (max-width: 1160px) {
    min-width: 400px;

    svg {
      width: 300px;
      height: 340px;

      bottom: 45px;
      right: 49px;
    }
  }

  @media only screen and (max-width: 900px) {
    min-width: 375px;

    svg {
      width: 280px;
      height: 315px;

      bottom: 45px;
      right: 49px;
    }
  }

  @media only screen and (max-width: 768px) {
    min-width: 325px;
    padding-top: 50px;
    svg {
      height: 293.623px;
      width: 203.514px;

      bottom: 30px;
      right: 39px;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 0;
    svg {
      height: 293.623px;
      width: 203.514px;

      bottom: 30px;
      right: 70px;
    }
  }

  @media (max-width: 600px) {
    min-width: 350px;
  }
  @media (max-width: 360px) {
    min-width: 100%;
    margin-top: 20px;

    svg {
      height: 263px;
      right: 30px;
      bottom: 19px;
    }
  }

  @media (min-width: 1920px) {
    svg {
      right: 150px;
      height: 500px;
    }
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-top: 50px;

  gap: 8px;

  width: 50%;

  @media (max-width: 1380px) {
    width: 538px;
  }

  @media (max-width: 1160px) {
    width: auto;
    margin-top: 100px;
  }

  @media (max-width: 970px) {
    margin-top: 110px;
  }

  @media (max-width: 768px) {
    width: 350px;
    margin-top: 100px;
  }

  @media (max-width: 600px) {
    margin-top: 0px;
    width: 100%;
  }

  @media (max-width: 360px) {
    width: 100%;
  }
`

export const QuestionAndButtonWrapper = styled.div`
  width: 669px;
  padding: 0px 0px 0px 16px;
  height: 40px;
  border: 0.5px solid rgba(56, 40, 14, 0.3);
  border-radius: 6px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1160px) {
    width: 538px;
  }
`

export const Question = styled.span`
  font-weight: 500;
  font-size: 1.6rem;
  color: #0d0d0d;

  /* @media only screen and (max-width: 768px) { */
  /* font-size: 1.4rem; */
  /* } */
`
export const QuestionContentWrapper = styled.div`
  width: 669px;
  padding: 0px 0px 0px 16px;
  height: 40px;

  border-radius: 6px;

  display: flex;
  align-items: center;
`

export const QuestionContent = styled.span`
  font-weight: 300;
  font-size: 1.4rem;
  color: rgb(107, 107, 107);
`

export const Button = styled.button`
  cursor: pointer;
  color: #fff;
  background: #27c7ff;
  width: 50px;
  height: 50px;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const MainText = styled.p`
  color: var(--black-brand-primary, #222);
  font-family: Neometric;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media only screen and (max-width: 768px) {
    color: var(--black-brand-primary, #222);
    /* text-align: center; */
    font-family: Neometric;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`

export const SubText = styled.p`
  color: var(--black-brand-primary, #222);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media only screen and (max-width: 768px) {
    font-size: 1.6rem;
    color: var(--black-brand-primary, #222);
    /* text-align: center; */
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`
export const ChevronContainer = styled.div`
  padding: 5px;
`
