import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as S from './styles'
import { useTranslation } from 'react-i18next'

import { ReactComponent as ChevronRight } from '../../assets/chevron-right.svg'
import { ReactComponent as ArrowUpRight } from '../../assets/arrow_up_right.svg'
import { useMobileSidebar } from '../../hooks/useMobileSidebar'
import useClickOutside from '../../hooks/useClickOutside'
import { useEffect, useState } from 'react'
import debounce from '../../utils/debounce'
import { Image, Menu, createStyles } from '@mantine/core'

import portugal from '../Navbar/images/portugal.webp'
import alemanha from '../Navbar/images/alemanha.webp'
import estadosUnidos from '../Navbar/images/estados-unidos.webp'
import franca from '../Navbar/images/franca.webp'
import nederland from '../Navbar/images/nederland.webp'
import { ExpandableItem } from './components/ExpandableItem'

const data = [
  { title: 'Portuguese', image: portugal, language: 'pt', country: 'Portugal' },
  { title: 'Dutch', image: nederland, language: 'nl', country: 'Netherlands' },
  { title: 'Deutsch', image: alemanha, language: 'de', country: 'Germany' },
  {
    title: 'English',
    image: estadosUnidos,
    language: 'en',
    country: 'United States'
  },
  { title: 'France', image: franca, language: 'fr', country: 'France' }
]

const useStyles = createStyles(() => ({
  darkDropdown: {
    backgroundColor: 'black',
    border: '1px solid #1f1e1e',
    '[data-hovered]': {
      backgroundColor: '#0D0D0D'
    }
  }
}))

export const MobileSidebar = () => {
  const [selected, setSelected] = useState(() => {
    const language = localStorage.getItem('language')
    if (!language) {
      return data[0]
    }
    return data.find((item) => item.language === language)
  })
  const [, setLanguage] = useState()
  const { t, i18n } = useTranslation()
  const { pathname } = useLocation()
  const { isSidebarOpen, setIsSidebarOpen } = useMobileSidebar()
  const domNode = useClickOutside(() => setIsSidebarOpen(false), null, [
    'navToggle'
  ])
  const { classes } = useStyles()

  const isDarkSidebar = pathname === '/corporate'

  const changeLanguage = (event) => {
    i18n.changeLanguage(event)
    localStorage.setItem('language', event)
  }

  const items = data.map((item) => (
    <Menu.Item
      icon={
        <Image
          src={item.image}
          width={30}
          height={20}
          alt={`Bandeira do ${item.country}`}
        />
      }
      style={!isDarkSidebar ? undefined : { color: 'white' }}
      onClick={() => {
        setSelected(item)
        localStorage.setItem('languageSelected', JSON.stringify(item))
        changeLanguage(item.language.replace(/"/g, ''))
        setLanguage(item.language.replace(/"/g, ''))
      }}
      key={item.title}
    >
      <option
        value={item.language}
        style={{ fontSize: '1.2rem' }}
        onClick={() => {
          changeLanguage(item.language.replace(/"/g, ''))
          setLanguage(item.language.replace(/"/g, ''))
        }}
      >
        {item.title}
      </option>
    </Menu.Item>
  ))

  const LINKS = [
    { url: '/', label: t('navbar.start') },
    {
      expandable: true,
      label: t('navbar.services'),
      activeWhen: ['/corporate', '/eventos', '/tour'],
      customHeight: 18,
      subItems: [
        { url: '/', label: t('navbar.transfer'), disableActive: true },
        { url: '/tour', label: t('navbar.tours') },
        { url: '/eventos', label: t('navbar.events') },
        { url: '/corporate', label: t('navbar.corporate') },
        {
          url: 'https://easytransfer.services/casamentos/',
          label: t('navbar.weddings'),
          blank: true
        },
        {
          url: 'https://easytransfer.services/escola/',
          label: t('navbar.school'),
          blank: true
        },
        {
          url: 'https://easytransfer.services/motorista-particular/',
          label: t('navbar.privateDriver'),
          blank: true
        }
        // { url: '/fleet', label: t('navbar.fleet') },
      ]
    },
    { url: '/fleet', label: t('navbar.fleet') },
    {
      url: '#about',
      label: t('navbar.aboutUs'),
      internalPageLink: true,
      visibleIn: ['/']
    },

    {
      expandable: true,
      customHeight: 5,
      label: t('navbar.workWithUs'),
      activeWhen: [
        '/trabalhe-conosco-motorista',
        '/trabalhe-conosco-motorista/formulario',
        '/trabalhe-conosco-empresa',
        '/trabalhe-conosco-empresa/formulario'
      ],
      subItems: [
        {
          url: '/trabalhe-conosco-motorista',
          label: t('navbar.beDriver'),
          activeWhen: ['/trabalhe-conosco-motorista/formulario']
        },
        {
          url: '/trabalhe-conosco-empresa',
          label: t('navbar.bePartner'),
          activeWhen: ['/trabalhe-conosco-empresa/formulario']
        }
      ]
    },
    { url: '#faq', label: 'FAQ', internalPageLink: true, visibleIn: ['/'] }
    // { url: '#', label: 'Blog', internalPageLink: true, visibleIn: ['/'] }
    // { url: '/reembolso', label: t('navbar.reimbursement') }
  ]

  const navigate = useNavigate()

  return (
    <S.Container dark={isDarkSidebar} active={isSidebarOpen} ref={domNode}>
      <nav>
        <ul>
          {LINKS.map(
            ({
              url,
              label,
              activeWhen,
              internalPageLink,
              visibleIn,
              expandable,
              customHeight,
              subItems
            }) => (
              // (!visibleIn || (visibleIn && visibleIn.includes(pathname))) && (
              <S.NavItem
                key={url}
                active={
                  activeWhen ? activeWhen.includes(pathname) : pathname === url
                }
                dark={isDarkSidebar}
              >
                {expandable ? (
                  <ExpandableItem
                    label={label}
                    subItems={subItems}
                    height={customHeight}
                  />
                ) : internalPageLink ? (
                  <a
                    onClick={
                      pathname === '/'
                        ? () => setIsSidebarOpen(false)
                        : (e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            setIsSidebarOpen(false)
                            navigate(`/${url}`)
                          }
                    }
                    href={url}
                    style={{ textDecoration: 'none' }}
                    // onClick={() => setIsSidebarOpen(false)}
                  >
                    <p>{label}</p>
                    <ChevronRight />
                  </a>
                ) : (
                  <Link to={url} style={{ textDecoration: 'none' }}>
                    <p>{label}</p>
                    <ChevronRight />
                  </Link>
                )}
              </S.NavItem>
            )
            // )
          )}
        </ul>
      </nav>

      <S.LanguageSelectionWrapper>
        <Menu width="target" position="top">
          <Menu.Target>
            <S.LanguageSelectionButton>
              <span>{selected.title}</span>
              <ChevronRight />
            </S.LanguageSelectionButton>
          </Menu.Target>
          <Menu.Dropdown
            className={!isDarkSidebar ? undefined : classes.darkDropdown}
          >
            {items}
          </Menu.Dropdown>
        </Menu>
      </S.LanguageSelectionWrapper>

      <S.BookNowButton>
        {t('navbar.book now')}
        <ArrowUpRight />
      </S.BookNowButton>
    </S.Container>
  )
}
