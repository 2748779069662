import api from './api'

const fetchObj = {
  getPunishment: (params) => {
    const res = api.get('/punishment', {
      params
    })

    return res
  },

  getPunishmentById: (id) => {
    const res = api.get(`/punishment/${id}`)

    return res
  },

  createPunishment: (payload) => {
    const res = api.post('/punishment', payload)

    return res
  },

  updatePunishment: (id, payload) => {
    const res = api.put(`/punishment/${id}`, payload)

    return res
  },

  deletePunishment: (id) => {
    const res = api.delete(`/punishment/${id}`)

    return res
  }
}

export default fetchObj
