import { useMobileSidebar } from '../../../../hooks/useMobileSidebar'
import * as S from './styles'

export const SidebarToggle = () => {
  const { isSidebarOpen, setIsSidebarOpen } = useMobileSidebar()

  function handleSidebar() {
    setIsSidebarOpen(!isSidebarOpen)
  }

  return (
    <S.Wrapper
      onClick={handleSidebar}
      isSidebarOpen={isSidebarOpen}
      className="navToggle"
    >
      <span />
      <span />
      <span />
    </S.Wrapper>
  )
}
