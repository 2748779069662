import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* left: -30px; */
  margin-bottom: 30px;
  padding-right: 2rem;
  /* background: red; */

  img {
    width: 40.44px;
  }

  .mantine-Button-root {
    padding: 2rem 4rem;
  }

  .mantine-Button-inner {
    position: absolute;
    bottom: 0;
    right: 2rem;

    height: 3.8rem;
    width: 3.8rem;

    border-radius: 5.778px;

    img {
      height: 28.889px;
      width: 40.444px;

      border-radius: 5.778px;
    }
  }
  .mantine-Button-root:hover {
    background-color: transparent;
  }
  .mantine-Stepper-root {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 0px; */
    gap: 17.33px;

    max-width: 824.22px;
    width: 100%;
    height: 44px;
    position: relative;

    overflow: hidden;
  }

  .mantine-Stepper-stepBody {
    margin-left: 0 !important;
  }

  .mantine-1iugybl mantine-Stepper-stepWrapper {
    width: 38px;
    height: 38px;
  }

  .mantine-egbnkr mantine-Stepper-stepIcon {
    width: 27.44px;
    height: 27.44px;
  }

  .mantine-Stepper-stepIcon[data-completed] {
    width: 27.44px;
    height: 27.44px;
    background-color: #27c7ff;
    border-color: #27c7ff;
  }

  .mantine-Stepper-separator {
    background-color: #27c7ff;
    border: 1.44444px solid #27c7ff;
    width: 41.89px;
  }

  .mantine-Stepper-stepLabel {
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;

    letter-spacing: 0.02em;

    color: #404040;
  }

  .mantine-Stepper-step[data-progress] {
    div {
      color: #404040;
    }
  }
  .mantine-Stepper-step[data-completed] {
    div {
      color: #404040;
    }
  }

  .mantine-Stepper-stepDescription {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

    color: #404040;
  }

  .mantine-Stepper-stepIcon[data-progress] {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 17.3333px;
    line-height: 120%;
    padding-top: 4px;

    background-color: #27c7ff;
    border-color: #27c7ff;
    color: white !important;
  }
  .mantine-Stepper-stepIcon[data-completed] div {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 17.3333px;
    line-height: 120%;
    padding-top: 4px;

    background-color: #27c7ff;
    border-color: #27c7ff;
    color: white !important;
  }

  .mantine-Stepper-stepIcon {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 17.3333px;
    line-height: 120%;

    border: 0;
    background: #f7f7f7;
    color: #bfbfbf;
  }

  .mantine-Stepper-stepCompletedIcon {
    transform: scale(0.5) !important;
  }
  .mantine-Stepper-step {
    gap: 8px;
  }

  .mantine-Stepper-steps {
    position: absolute;
    left: 4vw;
    margin-left: -4vw;
    overflow: auto;
    /* left: 0; */
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 1.6rem;

    .mantine-Stepper-root {
      overflow: auto;
    }

    .mantine-Stepper-steps {
      left: 1vw;
      margin-left: 0;
    }

    .mantine-Stepper-step {
      .mantine-Stepper-stepIcon {
        width: 2.7rem;
        height: 2.7rem;
      }

      .mantine-Stepper-stepBody {
        div:first-of-type {
          font-size: 0.8rem;
        }

        div:last-of-type {
          font-size: 1.2rem;
          margin-top: 0;
        }
      }
    }

    .mantine-Stepper-separator {
      max-width: 1.6rem;
    }
  }

  @media only screen and (max-width: 350px) {
    .mantine-Button-root {
      padding-bottom: 1rem;
    }
    .mantine-Button-inner {
      right: 3rem;
      width: 2.8rem;
      height: 2.8rem;

      img {
        width: 2.8rem;
        height: 2.8rem;
      }
    }
    .mantine-Stepper-root {
      margin-left: -2rem;
      /* overflow: auto; */
    }
    .mantine-Stepper-stepWrapper {
      .mantine-Stepper-stepIcon {
        width: 2.4rem;
        min-width: 2.4rem;
        height: 2.4rem;
        min-height: 2.4rem;
        font-size: 1.2rem;
        padding: 0;
      }
    }
  }
`
