import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/pt-br'
import { ReactComponent as CouponIcon } from '../imgs/coupon-icon.svg'
import { ReactComponent as ChevronIcon } from '../imgs/chevron-icon.svg'
import * as Styled from './styles'

dayjs.locale('pt-br')
dayjs.extend(relativeTime)

export const CouponCard = ({ couponItem }) => {
  const navigate = useNavigate()

  const { id, coupon, discountPercentage, validFrom, validTo, active } =
    couponItem

  const formattedFrom = dayjs(validFrom).format('DD/MM/YYYY')
  const formattedTo = dayjs(validTo).format('DD/MM/YYYY')
  const remainingTime = dayjs().to(dayjs(validTo)).substring(3)

  const goToDetails = () => {
    navigate(`/dashboard/coupons/${id}`)
  }

  return (
    <Styled.Container
      onClick={goToDetails}
      className={active ? 'active' : undefined}
    >
      <CouponIcon />
      <span>
        <span className={active ? 'active' : undefined} />
        {active ? 'Ativo' : 'Inativo'}
      </span>
      <Styled.CouponDetails>
        <div>
          <strong>Código promocional</strong>
          <p>{coupon}</p>
          <strong>Valor/%</strong>
          <p>{discountPercentage}%</p>
          <strong>Período de validade</strong>
          <p>
            {formattedFrom} - {formattedTo}
          </p>
          {active && <span>Restando {remainingTime} para acabar</span>}
        </div>
        <button>
          <ChevronIcon />
        </button>
      </Styled.CouponDetails>
    </Styled.Container>
  )
}
