import * as Styled from './styles'
import { Fleet } from './Fleet'
import { Card } from './components/Card'
import { ReactComponent as ArrowUp } from './imgs/arrow-up.svg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { motion, AnimatePresence } from 'framer-motion'
import { useState } from 'react'

export function OurFleet({ dark = false }) {
  const { t } = useTranslation()
  const [isMobile, setIsMobile] = useState(() => window.innerWidth < 700)

  return (
    <Styled.Container dark={dark}>
      <AnimatePresence>
        {isMobile ? (
          <motion.div>
            <Styled.Title>{t('fleet.exploreFleet')}</Styled.Title>
          </motion.div>
        ) : (
          <motion.div
            initial={{ x: '100%' }}
            whileInView={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <Styled.Title>{t('fleet.exploreFleet')}</Styled.Title>
          </motion.div>
        )}
      </AnimatePresence>
      <Card OurFleet={Fleet(t)} dark={dark} />
      <Link to="/fleet" style={{ textDecoration: 'none' }}>
        <Styled.BtnOurFleet
          initial={{ scale: 0, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          transition={{
            delay: 0.4,
            type: 'spring',
            bounce: 0.3,
            bounceDamping: 0.3,
            bounceStiffness: 0.3,
            duration: 0.2
          }}
          viewport={{ once: true }}
        >
          <span>{t('fleet.btnExploreFleet')}</span>
          <ArrowUp />
        </Styled.BtnOurFleet>
      </Link>
    </Styled.Container>
  )
}
