import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 94vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`
export const Title = styled.h1`
  font-family: Neometric;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.01em;
  text-align: left;
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  button {
    /* width: 100%; */
    height: 48px;
    padding: 8px 16px 8px 8px;
    border-radius: 32px;
    border: 1px;
    gap: 8px;
    border: 1px solid #e6e6e6;
    font-family: Neometric;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    color: #404040;
  }

  div {
    justify-content: flex-start;
  }

  @media (max-width: 1024px) {
    & {
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      width: 92vw;
    }
    &::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }
  }
`

export const ButtonsWrapper = styled.div`
  #input-demo {
    display: flex;
    width: 160px;
    height: 40px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    border: 0.508px solid var(--grey-3-grayscale, #bfbfbf);
  }
  #input-select {
    display: flex;
    width: 138px;
    height: 40px;
    align-items: center;
    gap: 16px;
    font-size: 1.2rem;
    align-self: stretch;
    border-radius: 8px;
    border: 0.508px solid var(--grey-3-grayscale, #bfbfbf);
  }
  #select-filter {
    display: flex;
    height: 31.25px;
    border-radius: 8px;
    border: 0.508px solid var(--grey-3-grayscale, #bfbfbf);
  }
  .mantine-Input-wrapper {
    margin-top: 0;
  }
  .activeCount {
    display: flex;
    height: 18px;
    padding: 8px 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: var(--yellow-brand-primary, #27c7ff);
    color: var(--white-grayscale, #fff);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 14.4px */
    letter-spacing: 0.12px;
  }
`

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const Count = styled.div`
  color: var(--yellow-3-support, #1D95BF);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
  letter-spacing: 0.12px;
  display: flex;
  height: 18px;
  padding: 8px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: var(--yellow-2-support, #DFF7FF);
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const ConfirmButton = styled.button`
  display: flex;
  width: 220px;
  height: 48px;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: #27c7ff;
  color: var(--white-grayscale, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;
  text-transform: uppercase;
  border: 2px solid var(--yellow-brand-primary, #27c7ff);
`
