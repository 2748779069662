import * as Styled from './styles'
import GoogleImage from './imgs/google.png'
import EasyTransferWriting from '../../../../assets/EasyTransfer.svg'
import CardCarousel from './components/Card'
import { useTranslation } from 'react-i18next'

const mockedTotalReviews = 260

export function GoogleComments({ dark = false }) {
  const { t } = useTranslation()
  return (
    <Styled.Container dark={dark}>
      <img src={GoogleImage} alt="Ilustração de avaliações do google" />
      <span>
        {t('basedOn')}{' '}
        <strong>
          {mockedTotalReviews} {t('reviews')}
        </strong>
      </span>
      <CardCarousel dark={dark} />
      {/* <Styled.EasyTransferImage src={EasyTransferWriting} alt="EasyTransfer" /> */}
    </Styled.Container>
  )
}
