import styled from 'styled-components'

export const BookingTourCard = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  width: 360px;

  margin: 0;
  padding: 24px;

  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0px -1.4px 22.55px rgba(0, 0, 0, 0.05),
    0px 31px 33.83px rgba(0, 0, 0, 0.07);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    p {
      font-family: Neometric;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }

    .discount {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      font-family: 'Poppins', sans-serif !important;
      font-weight: 700;
      font-size: 14px;
      color: #1D95BF;

      padding: 8px 16px;

      border-radius: 8px;
      background-color: #DFF7FF;
    }
  }

  .price {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: center;

    margin-top: 16px;

    small {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: 0.01em;
      text-align: center;
    }

    span {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
    }
  }

  .btn-schedule {
    width: 100%;
    height: 48px;

    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    text-align: center;

    border-radius: 16px;
    background-color: #27c7ff;
  }

  .info-people {
    display: flex;
    align-items: center;

    p {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      text-align: left;

      margin-left: 8px;

      span {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
      }
    }
  }

  .input-date {
    display: flex;

    width: 100%;
  }

  .inputs {
    display: flex;
    gap: 12px;

    width: 100%;
  }
`
