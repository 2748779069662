import { CouponCard } from './components/CardCoupon'
import { ChoiceCar } from './components/ChoiceCar'
import { RouteCard } from './components/RouteCard'
import { OrderSummary } from './components/OrderSummary'
import { createHtmlPortalNode, InPortal } from 'react-reverse-portal'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import * as Styled from './styles'

const portalNode = createHtmlPortalNode()

export const RightContainer = ({
  setShowCars,
  showCars,
  carSelected,
  showCouponOnly = false,
  hideOnColumn = false
}) => {
  const columnMatch = useMediaQuery('(max-width: 944px)')
  return (
    <Styled.RightContainer
      showCouponOnly={showCouponOnly}
      hide={hideOnColumn && columnMatch}
    >
      {columnMatch ? (
        <InPortal node={portalNode}>
          <ChoiceCar
            setOpened={setShowCars}
            opened={showCars}
            selectedCar={carSelected}
          />
        </InPortal>
      ) : (
        <ChoiceCar
          setOpened={setShowCars}
          opened={showCars}
          selectedCar={carSelected}
        />
      )}
      {showCouponOnly && <CouponCard />}
      <OrderSummary portalNode={portalNode} />
      <RouteCard />
    </Styled.RightContainer>
  )
}
