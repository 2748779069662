import {
  Button,
  Divider,
  Flex,
  LoadingOverlay,
  MediaQuery
  // , Pagination
} from '@mantine/core'
import * as Styled from './styles'
import { useEffect } from 'react'
import { ReactComponent as Passenger } from './imgs/passenger.svg'
import { ReactComponent as Avatar } from './imgs/avatar.svg'
import { ReactComponent as User } from './imgs/user.svg'
import { ReactComponent as Email } from './imgs/mail.svg'
import { ReactComponent as Phone } from './imgs/phone.svg'
import fetchBooking from '../../../../../../services/booking'
import { useState } from 'react'
import {
  formatDateAndTime,
  formatDayOfWeek,
  formatMonth,
  formatTime
} from '../../../../../../utils/formattedDate'
import { RejectModal } from '../RejectModal'
import { useAuth } from '../../../../../../contexts/useAuth'
import { useClipboard } from '@mantine/hooks'

export const DetailsPassenger = ({ transfer }) => {
  const [acceptedModal, setAcceptedModal] = useState(false)
  const [rejectModal, setRejectModal] = useState(false)
  const [transferContent, setTransferContent] = useState()
  const [refreshBookings, setRefreshBookings] = useState(false)

  const clipboard = useClipboard({ timeout: 500 })

  const { user } = useAuth()

  const handleAcceptTransfer = (value, driver, setDriver) => {
    const payload = {
      id: value.id,
      driver: {
        id: driver ? driver : user.id
      }
    }

    if (user.id) {
      fetchBooking
        .updateBooking(payload)
        .then((res) => {
          if (res) {
            setAcceptedModal(false)
            setRefreshBookings(!refreshBookings)
            if (driver) {
              setDriver()
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  // const handleRejectTransfer = (value) => {
  //   const payload = {
  //     id: value.id,
  //     driver: {
  //       id: user.id
  //     }
  //   }

  //   if (user.id) {
  //     fetchBooking
  //       .updateBooking(payload)
  //       .then((res) => {
  //         console.log(res)
  //       })
  //       .catch((err) => {
  //         console.log(err)
  //       })
  //   }
  // }

  return (
    <>
      <Styled.CardContainer>
        <Styled.Container key={transfer?.id}>
          <Styled.Wrapper>
            <Styled.InfoWrapper>
              <Styled.PaymentTitle>
                <Passenger />
                Passageiro principal
              </Styled.PaymentTitle>
            </Styled.InfoWrapper>
            <Styled.PassengerInfoContainer>
              <Styled.PassengerInputs>
                <Styled.PaymentInfo>
                  <Styled.PaymentInfoTitle>
                    <User />
                    Nome
                  </Styled.PaymentInfoTitle>
                  <Styled.PaymentInfoDesc>
                    {transfer?.passengerName}
                  </Styled.PaymentInfoDesc>
                </Styled.PaymentInfo>
                <Styled.PaymentInfo>
                  <Styled.PaymentInfoTitle>
                    <Email />E - mail
                  </Styled.PaymentInfoTitle>
                  <Styled.PaymentInfoDesc>
                    {transfer?.passengerEmail}
                  </Styled.PaymentInfoDesc>
                </Styled.PaymentInfo>
                <Styled.PaymentInfo>
                  <Styled.PaymentInfoTitle>
                    <Phone />
                    Telemóvel
                  </Styled.PaymentInfoTitle>
                  <Styled.PaymentInfoDesc>
                    <a href={`tel:${transfer?.passengerPhoneNumber}`}>
                      {transfer?.passengerPhoneNumber}
                    </a>
                  </Styled.PaymentInfoDesc>
                </Styled.PaymentInfo>
              </Styled.PassengerInputs>
            </Styled.PassengerInfoContainer>
          </Styled.Wrapper>
        </Styled.Container>

        <RejectModal
          opened={rejectModal}
          onClose={() => setRejectModal(false)}
        />
      </Styled.CardContainer>
    </>
  )
}
