import styled, { css } from 'styled-components'
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: flex-start;
`
export const Title = styled.h1`
  font-family: Neometric;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.01em;
  text-align: left;
`
export const ConfirmButton = styled.button`
  display: flex;
  width: 220px;
  height: 48px;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  border: 2px solid var(--yellow-brand-primary, #27c7ff);
  background-color: transparent;

  color: var(--yellow-brand-primary, #27c7ff);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;
  text-transform: uppercase;

  @media (max-width: 500px) {
    width: 100%;
  }
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 1.6rem;
  }
`
export const Content = styled.div`
  width: 100%;

  .pagination {
    justify-content: center;
    margin-top: 16px;
  }
`
export const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
  gap: 10px;

  #input-demo {
    display: flex;
    padding: 0px 8x;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    border: 0.5px solid var(--grey-3-grayscale, #bfbfbf);
    height: 48px;
    color: #a2a2a2;
    font-family: Neometric;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  #select-filter {
    height: 48px;
    border-radius: 8px;
    border: 0.508px solid var(--grey-3-grayscale, #bfbfbf);
    color: var(--grey-2-grayscale, #808080);
    font-family: Neometric;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .mantine-Input-wrapper {
    margin-top: 0;
  }
  .activeCount {
    display: flex;
    height: 18px;
    padding: 8px 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: var(--yellow-brand-primary, #27c7ff);
    color: var(--white-grayscale, #fff);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 14.4px */
    letter-spacing: 0.12px;
  }
`
