import React, { useEffect, useRef } from 'react'

import { SearchInput } from '../../../LowCost/components/SearchInput'
import { ChatItem } from '../ChatItem'
import { useChatsList } from './useChatsList'

import * as Styled from './styled'

// Detecta strict mode
const isStrict = (function () {
  return !this
})()

export function ChatsList() {
  const { loading, chatsAndUsers, searchForUser, dispose } = useChatsList()

  useEffect(() => {
    return () => {
      if (!isStrict) dispose()
    }
  }, [])

  return (
    <Styled.Container>
      <Styled.InputWrapper>
        <SearchInput
          propName="accounts"
          onChange={searchForUser}
          placeholder="Pesquisar por nome ou ID"
        />
      </Styled.InputWrapper>

      <Styled.ContentWrapper>
        {loading && (
          <div style={{ padding: '0 16px', fontSize: 12, color: '#888' }}>
            Buscando...
          </div>
        )}

        {!loading && chatsAndUsers.length === 0 && (
          <div style={{ padding: '0 16px', fontSize: 12, color: '#888' }}>
            Você ainda não possui uma conversa. Utilize a busca acima para
            encontrar um usuário...
          </div>
        )}

        {chatsAndUsers.map((item) => (
          <ChatItem key={item.id} item={item} />
        ))}
      </Styled.ContentWrapper>
    </Styled.Container>
  )
}
