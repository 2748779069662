export const validateData = (data) => {
  return Object.entries(data).every(([key, value]) => {
    if (key === 'deleted') return true
    return value instanceof Array ? value.length > 0 : !!value
  })
}

export const generatePayload = (data) => {
  return {
    id: data.id,
    coupon: data.coupon,
    discountPercentage: data.discountPercentage,
    appliedTo: data.appliedTo,
    validFrom: data.validFrom.toISOString(),
    validTo: data.validTo.toISOString(),
    paymentMethodsAllowed: data.paymentMethods,
    ...(data.useLimitOption !== 'LIMITLESS' && {
      usageLimitPerUser: data.useLimitOption === 'ONCE' ? 1 : data.useAmount
    })
  }
}

export const formatErrorMessage = (message, isEditing) => {
  switch (message) {
    case 'Fields: "coupon" already registered.':
      return 'Este cupom já foi cadastrado!'
    default:
      return `Erro ao ${isEditing ? 'editar' : 'criar'} cupom!`
  }
}
