import styled, { css } from 'styled-components'
import {
  Card as UICard,
  CardContent as UICardContent
} from '../../components/Card'

export const Card = styled(UICard)`
  ${({ isDarkTheme }) => css`
    min-height: 23rem;
    border-radius: 0.8rem;
    border: none;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
      0 4px 6px -4px rgb(0 0 0 / 0.1);

    margin-left: 0.8rem;
    margin-right: 0.8rem;

    ${isDarkTheme &&
    css`
      box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
        0 4px 6px -4px rgb(0 0 0 / 0.1);
      background-color: #27272a;
    `}
  `}
`

export const CardContent = styled(UICardContent)`
  ${({ isDarkTheme }) => css`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    min-height: 23rem;
    padding: 1.6rem;

    > div:first-of-type {
      display: flex;
      align-items: center;
      gap: 1.2rem;

      > div:first-of-type {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9999px;
        background-color: #7c3aed;
        font-size: 2.4rem;
        line-height: 3.2rem;
        color: #ffffff;
        width: 4rem;
        min-width: 4rem;
        height: 4rem;
        min-height: 4rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        span {
          /* margin-bottom: auto; */
        }
      }

      > div:last-of-type {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > span:first-of-type {
          font-size: 1.6rem;
          font-weight: bold;
        }

        > span:last-of-type {
          font-size: 1.4rem;
          line-height: 2rem;
          color: #9ca3af;
        }

        ${isDarkTheme &&
        css`
          > span:first-of-type {
            color: #f3f4f6;
          }

          > span:last-of-type {
            color: #d1d5db;
          }
        `}
      }

      img {
        margin-left: auto;
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    > div:last-of-type {
      display: inline-flex;
      gap: 0.1rem;

      svg {
        width: 2rem;
        height: 2rem;
        fill: #27c7ff;
        color: #27c7ff;
      }
    }

    > p {
      margin-top: 0.6rem;
      font-size: 1.4rem;
      line-height: 2rem;

      ${isDarkTheme &&
      css`
        color: #d1d5db;
      `}
    }
  `}
`
