import { useEffect, useState } from 'react'

export function useVisibleSection(sections) {
  const [visibleSectionId, setVisibleSectionId] = useState()

  const isSectionVisible = (section) => {
    const { top, bottom, left, right } = section.getBoundingClientRect()
    const viewPort = {
      top: 0,
      left: 0,
      bottom: window.innerHeight,
      right: window.innerWidth
    }

    return (
      top <= viewPort.bottom &&
      bottom >= viewPort.top &&
      left <= viewPort.right &&
      right >= viewPort.left
    )
  }

  const checkVisibility = () => {
    for (const { id } of sections) {
      const section = document.getElementById(id)
      if (section && isSectionVisible(section)) {
        setVisibleSectionId(id)
        return
      }
    }
  }

  useEffect(() => {
    const scrollListener = () => checkVisibility()
    if (sections) {
      window.addEventListener('scroll', scrollListener)
    }

    checkVisibility()

    return () => window.removeEventListener('scroll', scrollListener)
  }, [sections])

  return visibleSectionId
}
