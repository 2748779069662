import { useState } from 'react'
import {
  Group,
  Box,
  Collapse,
  ThemeIcon,
  Text,
  UnstyledButton,
  createStyles,
  rem
} from '@mantine/core'
import {
  IconCalendarStats,
  IconChevronLeft,
  IconChevronRight
} from '@tabler/icons-react'

const useStyles = createStyles((theme) => ({
  control: {
    fontWeight: 500,
    fontFamily: 'Neometric',
    fontSize: '18px',
    lineHeight: '23px',
    letterSpacing: '0.01em',
    display: 'block',
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black
    }
  },

  link: {
    fontFamily: 'Neometric',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '23px',
    letterSpacing: '0.01em',
    display: 'block',
    textDecoration: 'none',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    paddingLeft: rem(31),
    marginLeft: rem(30),
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    borderLeft: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === 'dark' ? theme.white : theme.black
    }
  },

  chevron: {
    transition: 'transform 200ms ease'
  },
  icons: {
    width: '40.34px',
    height: '40.34px',
    backgroundColor: '#DFF7FF',
    color: '#27c7ff'
  }
}))

export function LinksGroup({
  icon: Icon,
  label,
  initiallyOpened,
  links,
  onOptionClick,
  onClick,
  activePage,
  to
}) {
  const { classes, theme } = useStyles()
  const hasLinks = Array.isArray(links)
  const [opened, setOpened] = useState(initiallyOpened || false)
  const ChevronIcon = theme.dir === 'ltr' ? IconChevronRight : IconChevronLeft
  const items = (hasLinks ? links : []).map((link) => (
    <Text
      component="a"
      className={classes.link}
      href={link.link}
      key={link.label}
      onClick={(event) => event.preventDefault()}
    >
      {link.label}
    </Text>
  ))

  const handleOptionClick = (option) => {
    onOptionClick(option)
  }

  console.log(onClick, 'teste')

  return (
    <>
      <UnstyledButton
        onClick={() => setOpened((o) => !o)}
        className={`${classes.control} ${
          window.location.pathname.includes(onClick)
            ? 'active'
            : window.location.pathname.includes('details') &&
              onClick === 'transfer'
            ? 'active'
            : ''
        }`}
      >
        <Group
          position="apart"
          spacing={0}
          onClick={() => handleOptionClick(onClick)}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <ThemeIcon variant="light" className={classes.icons}>
              <Icon size="27px" />
            </ThemeIcon>
            <Box ml="md">{label}</Box>
          </Box>
          {hasLinks && (
            <ChevronIcon
              className={classes.chevron}
              size="1rem"
              stroke={1.5}
              style={{
                transform: opened
                  ? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)`
                  : 'none'
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  )
}

const mockdata = {
  label: 'Releases',
  icon: IconCalendarStats,
  links: [
    { label: 'Upcoming releases', link: '/' },
    { label: 'Previous releases', link: '/' },
    { label: 'Releases schedule', link: '/' }
  ]
}

export function NavbarLinksGroup() {
  return (
    <Box
      sx={(theme) => ({
        minHeight: rem(220),
        padding: theme.spacing.md,
        backgroundColor:
          theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white
      })}
    >
      <LinksGroup {...mockdata} />
    </Box>
  )
}
