import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  width: 100%;
  height: 80px;

  border-radius: 16px 16px 0px 0px;
  background: #f7f7f7;
  padding: 0 16px;
`

export const ProfileImage = styled.img`
  height: 50px;
  width: 50px;

  border-radius: 50px;
  background-color: #f7f7f7;

  object-fit: cover;
`

export const Label = styled.p`
  font-family: Roboto;
  color: #27c7ff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
`

export const Value = styled.p`
  font-family: Neometric;
  color: #404040;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
`
