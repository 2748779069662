import styled, { css } from 'styled-components'

const navItemModifiers = {
  active: () => css`
    > a,
    > button {
      font-weight: 700;
      color: #27c7ff;

      svg {
        stroke: #27c7ff;
      }
    }
  `,

  dark: () => css`
    a,
    button {
      color: #ffffff;

      svg {
        stroke: #ffffff;
      }
    }

    & + li {
      border-top-color: #404040;
    }
  `
}

export const NavItem = styled.li`
  ${({ active, dark }) => css`
    padding: 2.4rem 0;

    a,
    button {
      color: #404040;
      font-family: Neometric;
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: 0.14px;
      background: transparent;
      cursor: pointer;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {
        stroke: #404040;
      }
    }

    & + li {
      border-top: 1px solid #e9ecef;
    }

    ${dark && navItemModifiers.dark}
    ${active && navItemModifiers.active}
  `}
`

export const LanguageSelectionWrapper = styled.div`
  margin: auto 0 1rem;
`

export const LanguageSelectionButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  padding: 0.8rem 0;
  border-bottom: 1px solid #e9ecef;

  span {
    color: #27c7ff;
    font-family: Neometric;
    font-size: 1.4rem;
    font-weight: 500;
  }

  svg {
    stroke: #404040;
    transform: rotate(90deg);
  }
`

export const BookNowButton = styled.button`
  width: 100%;
  height: 5rem;
  padding: 1.4rem;
  background: #27c7ff;
  border-radius: 0.8rem;
  color: #0d0d0d;
  font-family: Roboto;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;

  svg {
    max-width: 1.2rem;
    max-height: 1.2rem;
    width: 100%;
    height: 100%;
    margin-top: -0.2rem;
  }
`

const containerModifiers = {
  active: () => css`
    transform: translateX(0);
  `,

  dark: () => css`
    background-color: #1b1b1b;
    border-top-color: #404040;
    border-right-color: #404040;

    ${LanguageSelectionButton} {
      border-bottom-color: #404040;

      svg {
        stroke: #ffffff;
      }
    }
  `
}

export const Container = styled.aside`
  ${({ active, dark }) => css`
    position: fixed;
    z-index: 99999999999999999;
    top: 8rem;
    height: calc(100dvh - 8rem);
    background: #ffffff;
    width: 30rem;
    padding: 1.6rem;
    border-right: 1px solid #f7f7f7;
    border-top: 1px solid #e9ecef;
    transform: translateX(-30rem);
    transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    ul {
      list-style: none;
      margin-bottom: 2rem;
    }

    ${active && containerModifiers.active}
    ${dark && containerModifiers.dark}
  `}
`

const expandedOptionsWrapperModifiers = {
  expanded: () => css`
    max-height: 200rem;
  `
}

export const ExpandedOptionsWrapper = styled.div`
  ${({ isExpanded }) => css`
    max-height: 0;
    width: 100%;
    overflow: hidden;
    padding-left: 0.8rem;
    transition: max-height 0.6s ease-in-out;

    a {
      margin-top: 0.8rem;
    }

    ${isExpanded && expandedOptionsWrapperModifiers.expanded}
  `}
`
