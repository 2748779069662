export const couponMask = (value) => {
  return value
    .replace(/\s/gi, '')
    .replace(/[^\w\s]/gi, '')
    .toUpperCase()
}

export const percentageMask = (value) => {
  const updatedValue = value.replace(/[^\d]/g, '')
  if (updatedValue) {
    return Number(updatedValue) <= 0
      ? 1
      : Number(updatedValue) > 100
      ? 100
      : Number(updatedValue)
  }
  return updatedValue
}
