import * as Styled from './styles'
import { ReactComponent as DriverImage } from './imgs/DriverImage.svg'
import { ReactComponent as CloseIcon } from './imgs/CloseIcon.svg'
import { Radio } from '@mantine/core'
import { useState } from 'react'

export const RegisterModal = ({ opened, onClose, onSuccess }) => {
  const [enterprise, setEnterprise] = useState()

  return (
    <>
      <Styled.ModalOverlay opened={opened} onClick={onClose} />
      <Styled.ModalContainer opened={opened}>
        <Styled.ModalHeader>
          <Styled.ModalTitle>Registrar</Styled.ModalTitle>
          <Styled.CloseButton>
            <CloseIcon onClick={onClose} />
          </Styled.CloseButton>
        </Styled.ModalHeader>
        <Styled.ModalContent>
          <Styled.ModalDescription>
            Escolha qual das opções você quer criar um registro{' '}
          </Styled.ModalDescription>
          <Styled.OptionsWrapper>
            <Styled.Option active={enterprise === 'autonomo'}>
              <Radio
                label="Motorista autônomo"
                value={enterprise}
                checked={enterprise === 'autonomo'}
                onChange={(e) => setEnterprise('autonomo')}
              />
            </Styled.Option>
            <Styled.Option active={enterprise === 'empresa'}>
              <Radio
                label="Empresa"
                value={enterprise}
                checked={enterprise === 'empresa'}
                onChange={(e) => setEnterprise('empresa')}
              />
            </Styled.Option>
          </Styled.OptionsWrapper>
        </Styled.ModalContent>
        <Styled.ModalButton onClick={() => onSuccess(enterprise)}>
          AVANÇAR
        </Styled.ModalButton>
      </Styled.ModalContainer>
    </>
  )
}
