import * as Styled from './styles'
import { DateTimePicker } from './components/DateTimePicker'
import { Passengers } from './components/Passengers'
import { useMediaQuery } from '../../../../hooks/useMediaQuery'
import { useEffect, useState } from 'react'
import fetchTour from '../../../../services/tourStrapi'
import { SearchField } from './components/SearchField'
import { useTransferContext } from '../../../../contexts/TransferContext'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { MapPin, CalendarDays, Users2 } from 'lucide-react'
import { formatCurrency } from '../../../../utils/formatCurrency'
export const Tours = () => {
  const {
    tour,
    setTour,
    setReturnTravel,
    returnTravel,
    // calculateRoute,
    isLoaded
  } = useTransferContext()

  const mobileMatch = useMediaQuery('(max-width: 634px)')
  const [toursOptions, setToursOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const navigate = useNavigate()

  const [goingDate, setGoingDate] = useState(() => {
    if (!tour.dateTime) return undefined
    return new Date(
      `${tour.dateTime
        .toLocaleDateString()
        .split('/')
        .reverse()
        .join('-')}T00:00:00`
    )
  })
  const [goingTime, setGoingTime] = useState(() => {
    if (!tour.dateTime) return '00:00'
    return `${String(tour.dateTime.getHours()).padStart(2, '0')}:${String(
      tour.dateTime.getMinutes()
    ).padStart(2, '0')}`
  })

  useEffect(() => {
    setLoading(true)
    const fetchTours = async () => {
      const params = {
        populate: 'mainImage',
        size: 999
      }
      const res = await fetchTour.getAll(params)
      setLoading(false)
      const toursMap = res.data.data.map((tour) => {
        return {
          value: tour.id,
          label: tour.attributes.name,
          ...tour.attributes
        }
      })
      if (!tour.name) {
        setTour({
          ...tour,
          ...toursMap[0]
        })
      }
      setToursOptions(toursMap)
    }

    fetchTours()
  }, [])

  const handleReserv = () => {
    if (
      tour.arrivalLocation === '' ||
      tour.departureLocation === '' ||
      tour.allPassangers === 0 ||
      !goingDate
    ) {
      return toast.error(t('toast.fulfilAllInformations'))
    }

    if (tour.allPassangers < tour.minNPassengers) {
      toast.error(`
      ${t('toast.passangersQuantityError')} ${tour.minNPassengers}
      `)
      return
    }
    const minDate = dayjs().add(8, 'hours')

    const fullDate = `${goingDate.toISOString().split('T')[0]}T${goingTime}:00`

    if (dayjs(fullDate).isBefore(dayjs())) {
      toast.error(t('toast.transferDateRangeError'))

      return
    }

    if (dayjs(fullDate).isBefore(minDate)) {
       toast.error(t('toast.transferHourRangeError'))

      return
    }

    setTour({ ...tour, dateTime: new Date(fullDate) })

    navigate('/checkout/tour')
  }
  useEffect(() => {
    setTour({
      ...tour,
      totalPrice: tour.providerPrice * tour.allPassangers
    })
  }, [tour.allPassangers])

  return (
    <>
      {loading ? (
        <div
          style={{
            width: '100%',
            height: '20rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <ClipLoader size={50} color="#27c7ff" />
        </div>
      ) : (
        <Styled.TransferContainer>
          <Styled.LeftContainer>
            {!tour?.name ? (
              <strong
                style={{
                  font: '600 1.4rem Poppins, sans-serif',
                  display: 'block',
                  margin: 'auto'
                }}
              >
                Selecione um tour
              </strong>
            ) : (
              <img
                src={
                  mobileMatch
                    ? tour?.mainImage?.data?.attributes?.url
                    : tour?.mainImage?.data?.attributes?.url
                }
              />
            )}
            {/* {tour?.name && mobileMatch && <h3>{tour?.name}</h3>} */}
          </Styled.LeftContainer>
          <Styled.OneTransferContainer>
            <Styled.OneTransfer style={{ zIndex: 2 }}>
              <SearchField
                icon={
                  <MapPin
                    size="2.4rem"
                    color="#27c7ff"
                    style={{ alignSelf: 'flex-end', marginBottom: '4%' }}
                  />
                }
                label={t('selectTour')}
                placeholder="Enter your destinations"
                onChange={(value) => setTour({ ...tour, ...value })}
                options={toursOptions}
                initialValue={tour?.label ?? ''}
                // setIsAirport={setIsAirport}
              />
            </Styled.OneTransfer>

            <Styled.OneTransfer>
              <DateTimePicker
                setPassengers={setTour}
                passengers={tour}
                img={<CalendarDays size="2.4rem" color="#27c7ff" />}
                imgAlt={'Ícone de calendário'}
                date={goingDate}
                time={goingTime}
                setDate={setGoingDate}
                setTime={setGoingTime}
                maxDate={tour?.availabilityEndAt}
                // isAirport={isAirport}
              />
            </Styled.OneTransfer>
            <Styled.OneTransfer style={{ zIndex: 1 }}>
              <Passengers
                setPassengers={setTour}
                passengers={tour}
                img={<Users2 size="2.4rem" color="#27c7ff" />}
                imgAlt={'Ícone de passageiros'}
                maxValue={tour?.maxNPassengers}
              />
            </Styled.OneTransfer>

            <p className="warn">
              {t('maxLimit')} {tour?.maxNPassengers} {t('people')}
            </p>
          </Styled.OneTransferContainer>

          <Styled.BtnContainer>
            <div>
              <p>{tour?.name}</p>
              <strong>
                {!tour?.name
                  ? 0
                  : tour.allPassangers <= 1
                  ? formatCurrency(tour.providerPrice).replace('€', '')
                  : formatCurrency(tour.totalPrice).replace('€', '')}
                €
              </strong>
            </div>
            <Styled.BtnPrimary disabled={!tour?.name} onClick={handleReserv}>
              {t('reserveComponent.reserve')}
            </Styled.BtnPrimary>
          </Styled.BtnContainer>
        </Styled.TransferContainer>
      )}
    </>
  )
}
