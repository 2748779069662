import { Header } from './Header'
import { Steppers } from './components/Steppers'
import { Transfer2 } from './components/Transfer2'
import * as Styled from './styles'
import { ChoiceCars } from './components/ChoiceCars'
import { useState } from 'react'
import { useTransferContext } from '../../contexts/TransferContext'
import { RightContainer } from './RightContainer'
import Footer from '../../components/Footer'
import { Navbar } from '../../components/Navbar'
import { NavbarCheckout } from '../../components/NavbarCheckout'

export const Checkout2 = () => {
  const [showCars, setShowCars] = useState()

  const { carSelected, setCarSelected } = useTransferContext()

  return (
    <>
      <div style={{ maxWidth: 988, margin: '0 auto' }}>
        <NavbarCheckout />
      </div>
      <Styled.Container>
        <Steppers stepper={1} />
        <Styled.ChoiceCarsContainer showCars={showCars}>
          <ChoiceCars
            setSelectedCar={setCarSelected}
            setShowCars={setShowCars}
          />
        </Styled.ChoiceCarsContainer>

        <Styled.ContentContainer>
          <Styled.MiddleContainerRow showCars={showCars}>
            <Transfer2 />
            {/* <InitialTransfer /> */}
            {/* <BookingDetailsChoiceCars /> */}
            <RightContainer
              carSelected={carSelected}
              setShowCars={setShowCars}
              showCars={showCars}
            />
          </Styled.MiddleContainerRow>
        </Styled.ContentContainer>
      </Styled.Container>
      <Footer />
    </>
  )
}
