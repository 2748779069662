import { Switch } from '@mantine/core'
import * as Styled from './styles'

export const SwitchAccounts = ({ active, setActive, switchStatus }) => {
  return (
    <Styled.Container>
      <labe>{active ? 'Ativo' : 'Inativo'}</labe>
      <Switch
        checked={active}
        onChange={(e) => {
          setActive(e.currentTarget.checked)
          switchStatus(e.currentTarget.checked)
        }}
        size="xl"
      />
    </Styled.Container>
  )
}
