import React, { useState } from 'react'
import * as Styled from './styles'

import { DetailsTransfer } from './components/DetailsTransfer'
import { DetailsPayment } from './components/DetailsPayment'
import { DetailsPassenger } from './components/DetailsPassenger'
import { SendTransferModal } from './components/SendTransferModal'
import Status from './components/Status'
import { useParams, useNavigate } from 'react-router'
import { useEffect } from 'react'
import fetchBooking from '../../../../services/booking'
import { RejectModal } from './components/RejectModal'
import { toast } from 'react-toastify'
import { DetailsDriver } from './components/DetailsDriver'
import { useTranslation } from 'react-i18next'

export const FinancialDetails = () => {
  const [sendTransferModal, setSendTransferModal] = useState()
  const [rejectModal, setRejectModal] = useState()
  const [next, setNext] = useState(false)
  const [transfer, setTransfer] = useState()

  const { id } = useParams()
  const navigate = useNavigate()

  const { t } = useTranslation()

  const handleCancelTransfer = () => {
    fetchBooking
      .cancelTransfer(id)
      .then((res) => {
        toast.success(t('toast.transferCancelSuccess'))
        setRejectModal(false)
        navigate('/dashboard/transfer')
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  useEffect(() => {
    fetchBooking
      .getBookingId(id)
      .then((res) => {
        setTransfer(res.data.content)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }, [])

  return (
    <>
      <Styled.Container>
        <Styled.HeaderContainer>
          <Styled.Title>
            Transfers <Styled.TransferId>#{transfer?.id}</Styled.TransferId>
          </Styled.Title>
        </Styled.HeaderContainer>
        {transfer?.status === 'PENDING' ? (
          <Status status="Pendente" />
        ) : transfer?.status === 'DONE' ? (
          <Status status="Realizado" />
        ) : (
          <Status status="Enviado para motorista" />
        )}
        <Styled.DetailsContainer>
          <DetailsTransfer transfer={transfer} />
          <DetailsPayment transfer={transfer} />
        </Styled.DetailsContainer>
        <Styled.DetailsContainer>
          <DetailsPassenger transfer={transfer} />
          <DetailsDriver transfer={transfer} />
        </Styled.DetailsContainer>
      </Styled.Container>
      <SendTransferModal
        booking={transfer}
        opened={sendTransferModal}
        onClose={() => setSendTransferModal(false)}
        onSuccess={() => setSendTransferModal(false)}
      />
      <RejectModal
        opened={rejectModal}
        onClose={() => {
          setRejectModal(false)
        }}
        onFinish={handleCancelTransfer}
      />
    </>
  )
}
