import { useEffect, useState } from 'react'
import { DatePicker, TimeInput } from '@mantine/dates'
import * as Styled from './styles'

import { ReactComponent as ChevronRight } from '../../../../assets/chevron-right.svg'
import Pencil from './imgs/pencil.webp'
import OrangeBar from './imgs/orange-bar.webp'
import Briefcase from './imgs/briefcase.webp'
import Passenger from './imgs/passenger.webp'
import Shape from './imgs/shape.webp'
import Carseat from './imgs/carseat.webp'
import Paw from './imgs/paw.webp'
import Arrow from './imgs/arrow.webp'
import Clock from './imgs/clock.webp'
import X from './imgs/x.webp'
import { Popover } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useTransferContext } from '../../../../contexts/TransferContext'
import { useMediaQuery } from '../../../../hooks/useMediaQuery'
import dayjs from 'dayjs'
import { formatCurrency } from '../../../../utils/formatCurrency'
// import VisaIcon from './imgs/visa.webp'
import { OutPortal } from 'react-reverse-portal'
import { useRef } from 'react'
import { Link } from 'react-router-dom'
import { Checked } from '../Checked'

const SHARE_RIDE_DISCOUNT = 0.25
const REFOUNT_TAX = 4.97
const NIGHT_TAX = 9
const ANIMALS_TAX = 10
const CHILDSEATS_TAX = 3
const SURFBOARDS_TAX = 3

export const OrderSummary = ({ portalNode, reservation = false }) => {
  const { t } = useTranslation()
  const { lowCost, setLowCost, returnTravel, setReturnTravel } =
    useTransferContext()

  const [, setEditing] = useState(false)
  const [hasReturnNightTax, setHasReturnNightTax] = useState(false)
  const expandableMatch = useMediaQuery('(max-width: 944px)')

  const hasGoingNightTax =
    dayjs(lowCost.dateTime).get('hours') > 19 ||
    (dayjs(lowCost.dateTime).get('hours') >= 19 &&
      dayjs(lowCost.dateTime).get('minutes') >= 30) ||
    dayjs(lowCost.dateTime).get('hours') < 8 ||
    (dayjs(lowCost.dateTime).get('hours') == 8 &&
      dayjs(lowCost.dateTime).get('minutes') == 0)

  const nightTax =
    hasGoingNightTax && hasReturnNightTax
      ? NIGHT_TAX * 2
      : hasGoingNightTax || hasReturnNightTax
      ? NIGHT_TAX
      : 0

  const lowCostAnimalsTax = lowCost.animals
    ? lowCost.animalsQuantity * ANIMALS_TAX
    : 0
  const returnAnimalsTax =
    returnTravel.active && returnTravel.animals
      ? returnTravel.animalsQuantity * ANIMALS_TAX
      : 0

  const lowCostChildSeatsTax = lowCost.childseat
    ? (lowCost.childSeat + lowCost.boosterSeat + lowCost.babyChair) *
      CHILDSEATS_TAX
    : 0
  const returnChildSeatsTax =
    returnTravel.active && returnTravel.childseat
      ? (returnTravel.childSeat +
          returnTravel.boosterSeat +
          returnTravel.babyChair) *
        CHILDSEATS_TAX
      : 0

  const lowCostSurfboardsTax = lowCost.surf
    ? lowCost.surfQuantity * SURFBOARDS_TAX
    : 0
  const returnSurfboardsTax =
    returnTravel.active && returnTravel.surf
      ? returnTravel.surfQuantity * SURFBOARDS_TAX
      : 0
  const orderBumpsTax = lowCost.bookingStops
    ? lowCost.bookingStops.reduce((total, item) => total + item.price, 0)
    : 0

  const [selectedTimeGoing, setSelectedTimeGoing] = useState(
    lowCost.dateTime
      ? new Date(lowCost.dateTime).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit'
        })
      : ''
  )

  const [selectedDateReturn, setSelectedDateReturn] = useState(
    returnTravel.active && !!returnTravel.dateTime
      ? returnTravel.dateTime
      : new Date(dayjs(lowCost.dateTime).add(1, 'hours'))
  )

  const [selectedTimeReturn, setSelectedTimeReturn] = useState(
    returnTravel.active && returnTravel.dateTime
      ? new Date(returnTravel.dateTime).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit'
        })
      : `${String(
          dayjs(lowCost.dateTime).add(1, 'hours').get('hours')
        ).padStart(2, '0')}:${String(
          dayjs(lowCost.dateTime).add(1, 'hours').get('minutes')
        ).padStart(2, '0')}`
  )

  const [tax, setTax] = useState(0)
  const [shareRideDiscount, setShareRideDiscount] = useState(0)
  const [isExpandedOrderSummary, setIsExpandedOrderSummary] = useState(false)
  const contentRef = useRef(null)
  const containerRef = useRef(null)

  const handleEditClick = () => {
    setEditing(true)
  }

  const getAbbreviatedWeekday = (date) => {
    const weekday = date.toLocaleString('default', { weekday: 'short' })
    return weekday.substring(0, 3).toUpperCase()
  }

  useEffect(() => {
    if (lowCost.totalPrice) {
      const taxCalculate = returnTravel.active
        ? (lowCost.totalPrice + returnTravel.totalPrice) * 0.06
        : lowCost.totalPrice * 0.06
      setTax(taxCalculate)

      if (lowCost.shareRide) {
        const shareRideDiscountCalculate = returnTravel.active
          ? (lowCost.totalPrice + returnTravel.totalPrice) * SHARE_RIDE_DISCOUNT
          : lowCost.totalPrice * SHARE_RIDE_DISCOUNT
        setShareRideDiscount(shareRideDiscountCalculate)
      } else {
        setShareRideDiscount(0)
      }
    }
  }, [
    lowCost.totalPrice,
    returnTravel.active,
    returnTravel.totalPrice,
    lowCost.shareRide
  ])

  useEffect(() => {
    if (selectedTimeGoing) {
      console.log({ lowCost, iso: lowCost.dateTime.toISOString() })
      console.log('new date', {
        date: new Date(
          dayjs(lowCost.dateTime).format('YYYY-MM-DDTHH:mm:ss').split('T')[0] +
            `T${selectedTimeGoing}:00`
        )
      })

      setLowCost({
        ...lowCost,
        dateTime: new Date(
          dayjs(lowCost.dateTime).format('YYYY-MM-DDTHH:mm:ss').split('T')[0] +
            `T${selectedTimeGoing}:00`
        )
      })
    }
  }, [selectedTimeGoing])

  useEffect(() => {
    if (!!selectedDateReturn && returnTravel.active) {
      const dateTime = new Date(
        dayjs(selectedDateReturn).format('YYYY-MM-DDTHH:mm:ss').split('T')[0] +
          `T${selectedTimeReturn}:00`
      )

      setReturnTravel({
        ...returnTravel,
        dateTime
      })

      setHasReturnNightTax(
        returnTravel.active &&
          (dayjs(dateTime).get('hours') > 19 ||
            (dayjs(dateTime).get('hours') >= 19 &&
              dayjs(dateTime).get('minutes') >= 30) ||
            dayjs(dateTime).get('hours') < 8 ||
            (dayjs(dateTime).get('hours') == 8 &&
              dayjs(dateTime).get('minutes') == 0))
      )
    }
  }, [selectedDateReturn, selectedTimeReturn, returnTravel.active])

  const handleToggleExpandSummary = () => {
    setIsExpandedOrderSummary((oldState) => !oldState)
  }

  return (
    <Styled.Container
      expanded={(expandableMatch && isExpandedOrderSummary) || reservation}
      ref={containerRef}
    >
      {reservation && <Checked />}
      {expandableMatch && !reservation && (
        <button onClick={handleToggleExpandSummary}>
          <ChevronRight />
          {isExpandedOrderSummary ? 'MINIMIZAR' : 'MOSTRAR DETALHES'}
        </button>
      )}
      <h2>{t('orderSummary.title')}</h2>
      <div>
        <div
          className="content-wrapper"
          ref={contentRef}
          style={
            !reservation && !isExpandedOrderSummary && expandableMatch
              ? { display: 'none' }
              : undefined
          }
        >
          {lowCost && (
            <>
              <Styled.FormRow style={{ marginTop: '2.4rem' }}>
                <Styled.StrongSpan>
                  {t('orderSummary.orderNumberLabel')}:
                </Styled.StrongSpan>
                <Styled.NormalSpan>034938429</Styled.NormalSpan>
              </Styled.FormRow>
              <OutPortal node={portalNode} />
              {lowCost.from && lowCost.to && (
                <Styled.ContentWrapper>
                  <Styled.RouteWrapper>
                    <Styled.OrientationWrapper>
                      <img src={Arrow} alt="Arrow icon" />
                      <span>{t('orderSummary.outboundLabel')}</span>
                    </Styled.OrientationWrapper>

                    <Styled.RoutesWrapper>
                      <img
                        src={OrangeBar}
                        className="orange-bar"
                        alt="Orange bar"
                      />
                      <div className="routes">
                        <Styled.FormRow>
                          <Styled.RouteStrongSpan>
                            {lowCost.from}
                          </Styled.RouteStrongSpan>
                          <Styled.RoutePriceSpan style={{ width: '70px' }}>
                            €{' '}
                            {formatCurrency(lowCost.totalPrice).replace(
                              '€',
                              ''
                            )}
                          </Styled.RoutePriceSpan>
                        </Styled.FormRow>
                        <Styled.FormRow>
                          <Styled.RouteNormalSpan>
                            {lowCost.from}
                          </Styled.RouteNormalSpan>
                        </Styled.FormRow>
                        <Styled.FormRow style={{ marginTop: '4px' }}>
                          <Styled.RouteStrongSpan style={{ marginTop: '30px' }}>
                            {lowCost.to}
                          </Styled.RouteStrongSpan>
                        </Styled.FormRow>
                        <Styled.FormRow>
                          <Styled.RouteNormalSpan>
                            {lowCost.to}
                          </Styled.RouteNormalSpan>
                        </Styled.FormRow>
                      </div>
                    </Styled.RoutesWrapper>
                    <Styled.IconsAndNumbersWrapper>
                      {(lowCost.checkedLuggage > 0 ||
                        lowCost.backPack > 0 ||
                        lowCost.handLuggage > 0) && (
                        <Styled.IconAndNumber>
                          <img
                            src={Briefcase}
                            style={{ height: '24px' }}
                            alt="Briefcase icon"
                          />
                          <span>
                            {lowCost.checkedLuggage +
                              lowCost.backPack +
                              lowCost.handLuggage}
                          </span>
                        </Styled.IconAndNumber>
                      )}
                      {lowCost.allPassangers > 0 && (
                        <Styled.IconAndNumber>
                          <img
                            src={Passenger}
                            style={{ height: '17px' }}
                            alt="Passenger icon"
                          />
                          <span>{lowCost.allPassangers}</span>
                        </Styled.IconAndNumber>
                      )}
                      {lowCost.surfQuantity > 0 && (
                        <Styled.IconAndNumber>
                          <img
                            src={Shape}
                            style={{ height: '24px' }}
                            alt="Shape icon"
                          />
                          <span>{lowCost.surfQuantity}</span>
                        </Styled.IconAndNumber>
                      )}
                      {(lowCost.babyChair > 0 ||
                        lowCost.boosterSeat > 0 ||
                        lowCost.childSeat > 0) && (
                        <Styled.IconAndNumber>
                          <img
                            src={Carseat}
                            style={{ height: '24px' }}
                            alt="Carseat icon"
                          />
                          <span>
                            {lowCost.babyChair +
                              lowCost.boosterSeat +
                              lowCost.childSeat}
                          </span>
                        </Styled.IconAndNumber>
                      )}

                      {lowCost.animalsQuantity > 0 && (
                        <Styled.IconAndNumber>
                          <img
                            src={Paw}
                            style={{ height: '24px' }}
                            alt="Paw icon"
                          />
                          <span>{lowCost.animalsQuantity}</span>
                        </Styled.IconAndNumber>
                      )}
                    </Styled.IconsAndNumbersWrapper>
                  </Styled.RouteWrapper>
                </Styled.ContentWrapper>
              )}
              <Styled.Divider />
            </>
          )}
        </div>
        {lowCost.totalPrice > 0 && (
          <>
            <div
              style={
                !reservation && !isExpandedOrderSummary && expandableMatch
                  ? { display: 'none' }
                  : undefined
              }
            >
              <Styled.BillWrapper>
                <h2>{t('orderSummary.billTitle')}</h2>
                <Styled.BillRow>
                  <span className="title">
                    {t('orderSummary.itemDescription')}
                  </span>
                  <span className="title">{t('orderSummary.price')}</span>
                </Styled.BillRow>
                <Styled.BillRow>
                  <Styled.NormalBillSpan>
                    {t('orderSummary.transferItem')}
                  </Styled.NormalBillSpan>
                  <Styled.PriceSpan>
                    €{' '}
                    {formatCurrency(
                      lowCost.totalPrice +
                        (returnTravel.active ? returnTravel.totalPrice : 0)
                    ).replace('€', '')}
                  </Styled.PriceSpan>
                </Styled.BillRow>
                <Styled.BillRow>
                  <Styled.NormalBillSpan>
                    {t('orderSummary.tax6Item')}
                  </Styled.NormalBillSpan>
                  <Styled.PriceSpan>
                    € {formatCurrency(tax).replace('€', '')}
                  </Styled.PriceSpan>
                </Styled.BillRow>
                {(lowCostAnimalsTax > 0 || returnAnimalsTax > 0) && (
                  <Styled.BillRow>
                    <Styled.NormalBillSpan>
                      Taxa Para Animais
                    </Styled.NormalBillSpan>
                    <Styled.PriceSpan>
                      €{' '}
                      {formatCurrency(
                        lowCostAnimalsTax + returnAnimalsTax
                      ).replace('€', '')}
                    </Styled.PriceSpan>
                  </Styled.BillRow>
                )}
                {(lowCostSurfboardsTax > 0 || returnSurfboardsTax > 0) && (
                  <Styled.BillRow>
                    <Styled.NormalBillSpan>
                      Taxa Para Pranchas de Surf
                    </Styled.NormalBillSpan>
                    <Styled.PriceSpan>
                      €{' '}
                      {formatCurrency(
                        lowCostSurfboardsTax + returnSurfboardsTax
                      ).replace('€', '')}
                    </Styled.PriceSpan>
                  </Styled.BillRow>
                )}
                {(lowCostChildSeatsTax > 0 || returnChildSeatsTax > 0) && (
                  <Styled.BillRow>
                    <Styled.NormalBillSpan>
                      Taxa Para Child Seats
                    </Styled.NormalBillSpan>
                    <Styled.PriceSpan>
                      €{' '}
                      {formatCurrency(
                        lowCostChildSeatsTax + returnChildSeatsTax
                      ).replace('€', '')}
                    </Styled.PriceSpan>
                  </Styled.BillRow>
                )}
                {(hasGoingNightTax || hasReturnNightTax) && (
                  <Styled.BillRow>
                    <Styled.NormalBillSpan>
                      {t('orderSummary.nightTax')}
                    </Styled.NormalBillSpan>
                    <Styled.PriceSpan>
                      € {formatCurrency(nightTax).replace('€', '')}
                    </Styled.PriceSpan>
                  </Styled.BillRow>
                )}
                {lowCost.bookingStops.length > 0 && (
                  <Styled.BillRow>
                    <Styled.NormalBillSpan>Paradas</Styled.NormalBillSpan>
                    <Styled.PriceSpan>
                      € {formatCurrency(orderBumpsTax).replace('€', '')}
                    </Styled.PriceSpan>
                  </Styled.BillRow>
                )}
                {/* {shareRideDiscount > 0 && (
                  <Styled.BillRow>
                    <Styled.NormalBillSpan>Share Ride</Styled.NormalBillSpan>
                    <Styled.PriceSpan>
                      - € {formatCurrency(shareRideDiscount).replace('€', '')}
                    </Styled.PriceSpan>
                  </Styled.BillRow>
                )} */}

                <Styled.BillRow>
                  <Styled.NormalBillSpan>
                    {t('orderSummary.refundTaxItem')}
                  </Styled.NormalBillSpan>
                  <Styled.PriceSpan>
                    €{' '}
                    {lowCost.reimbursement
                      ? formatCurrency(REFOUNT_TAX).replace('€', '')
                      : formatCurrency(0).replace('€', '')}
                  </Styled.PriceSpan>
                </Styled.BillRow>
                <Styled.BillRow>
                  <Styled.NormalBillSpan>
                    {t('orderSummary.total')}
                  </Styled.NormalBillSpan>
                  <Styled.PriceSpan>
                    €{' '}
                    {formatCurrency(
                      lowCost.totalPrice -
                        // shareRideDiscount +
                        nightTax +
                        lowCostAnimalsTax +
                        returnAnimalsTax +
                        lowCostSurfboardsTax +
                        returnSurfboardsTax +
                        lowCostChildSeatsTax +
                        returnChildSeatsTax +
                        orderBumpsTax +
                        (lowCost.reimbursement ? REFOUNT_TAX : 0) +
                        tax
                    ).replace('€', '')}
                  </Styled.PriceSpan>
                </Styled.BillRow>
              </Styled.BillWrapper>
              <Styled.Divider />
            </div>
            <Styled.Footer
              style={
                !reservation && !isExpandedOrderSummary && expandableMatch
                  ? { marginTop: '-4.4rem' }
                  : undefined
              }
            >
              <h5>
                €{' '}
                {formatCurrency(
                  lowCost.totalPrice -
                    // shareRideDiscount +
                    nightTax +
                    lowCostAnimalsTax +
                    returnAnimalsTax +
                    lowCostSurfboardsTax +
                    returnSurfboardsTax +
                    lowCostChildSeatsTax +
                    returnChildSeatsTax +
                    orderBumpsTax +
                    (lowCost.reimbursement ? REFOUNT_TAX : 0) +
                    tax
                ).replace('€', '')}
              </h5>
              <div>
                <p>{t('checkoutTour.Total price')}</p>
                <span>{t('checkoutTour.Taxes & fees included')}</span>
              </div>
            </Styled.Footer>
          </>
        )}
      </div>
      {reservation && <Link to="/">VOLTAR PARA HOME</Link>}
    </Styled.Container>
  )
}
