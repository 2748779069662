export const columnBooking = [
  {
    title: 'ID Transfer'
  },
  {
    title: 'Data / Hora'
  },
  {
    title: 'Status'
  },
  {
    title: 'Categoria'
  },
  {
    title: 'Valor cobrado'
  },
  {
    title: 'Método de pagamento'
  },
  {
    title: 'Ações'
  }
]
export const columnPartnership = [
  {
    title: 'Nome'
  },
  {
    title: 'ID'
  },
  {
    title: 'Categoria'
  },
  {
    title: 'Total'
  },
  {
    title: 'Total Líquido'
  },
  {
    title: 'Método de pagamento'
  },
  {
    title: 'Ações'
  }
]
export const columnDriver = [
  {
    title: 'Nome'
  },
  {
    title: 'ID Motorista'
  },
  {
    title: 'Categoria'
  },
  {
    title: 'Total'
  },
  {
    title: 'Total Líquido'
  },
  {
    title: 'Carteira de Crédito'
  },
  {
    title: 'Ações'
  }
]
