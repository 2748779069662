import api from './api'

const fetchObj = {
  getAllBooking: () => {
    const res = api.get('/google/avaliations')

    return res
  }
}

export default fetchObj
