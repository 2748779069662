import React, { useEffect, useState } from 'react'
import { useChatStore } from '../../context/ChatContext'

import { ReactComponent as BubleIcon } from './icons/lucide_messages-square.svg'
import * as Styled from './styles'

export function ChatItem({ item }) {
  const { user, notViewedMessagesCount } = item
  const [messagesCount, setMessagesCount] = useState(
    notViewedMessagesCount ?? 0
  )

  const isMessage = notViewedMessagesCount !== undefined

  const openChat = useChatStore((state) => state.openChat)
  const closeChat = useChatStore((state) => state.closeChat)
  const openedChatId = useChatStore((state) => state.openedChatId)

  const selected = openedChatId === user?.id

  function selectThisChat() {
    if (selected) return closeChat()

    setMessagesCount(0)
    closeChat()
    openChat(user)
  }

  useEffect(() => {
    setMessagesCount(notViewedMessagesCount)
  }, [notViewedMessagesCount])

  return (
    <Styled.Container onClick={selectThisChat} selected={selected}>
      <Styled.ContentWrapper>
        <Styled.ProfileImage src={user?.profilePicture?.url} />

        <div>
          <Styled.Name>{user?.name}</Styled.Name>
          <Styled.Id>ID: {user?.id?.split('-')[0]}</Styled.Id>
          {isMessage && messagesCount > 0 && (
            <Styled.MessageCount>{messagesCount} mensagens</Styled.MessageCount>
          )}
        </div>
      </Styled.ContentWrapper>

      {isMessage && <BubleIcon />}
    </Styled.Container>
  )
}
