import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Alert } from './imgs/alert-circle.svg'
import { ReactComponent as Cancel } from './imgs/cancel-circle.svg'
import { ReactComponent as Check } from './imgs/check-circle.svg'
import { ReactComponent as Send } from './imgs/send.svg'

// Define um objeto de mapeamento para os estados e suas propriedades correspondentes
const statusMap = {
  Pendente: {
    background: 'var(--yellow-2-support, #DFF7FF)',
    color: 'var(--yellow-3-support, #1D95BF)',
    icon: <Alert />,
    message: 'Pendente'
  },
  'Aceito pelo motorista': {
    background: 'var(--green-2-support, #D5F6EA)',
    color: 'var(--green-3-support, #48D9A4)',
    icon: <Check />,
    message: 'Aceito pelo motorista'
  },
  Realizado: {
    background: 'var(--blue-2-support, #D5F6EA)',
    color: 'var(--blue-3-support, #48D9A4)',
    icon: <Check />,
    message: 'Realizado'
  },
  Cancelado: {
    background: 'var(--red-2-support, #FDCED6)',
    color: 'var(--red-3-support, #F8294E)',
    icon: <Cancel />,
    message: 'Cancelado'
  },
  'Enviado para motorista': {
    background: 'var(--yellow-2-support, #DFF7FF)',
    color: 'var(--yellow-3-support, #1D95BF)',
    icon: <Send />,
    message: 'Enviado para motorista(s)'
  }
}

// Styled component para o status
const StyledStatus = styled.div`
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: flex-end;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: ${(props) => statusMap[props.status].background};
  color: ${(props) => statusMap[props.status].color};
  text-align: center;
  font-family: Neometric;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 23.4px */
  letter-spacing: 0.18px;
`

// Componente Status
const Status = ({ status }) => {
  return (
    <StyledStatus status={status}>
      {statusMap[status].icon} <span>{statusMap[status].message}</span>
    </StyledStatus>
  )
}

export default Status
