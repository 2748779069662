import React, { useEffect, useState } from 'react'
import {
  createStyles,
  Header,
  Container,
  Anchor,
  Group,
  rem
} from '@mantine/core'
import * as Styled from './styles'
// import { useDisclosure } from '@mantine/hooks'
import { PersonalData } from './components/PersonalData'
import { HouseHold } from './components/HouseHold'
import { BankAccount } from './components/BankAccount'
import { useMemo } from 'react'
import { Commission } from './components/Commission'
import { Arquives } from './components/Arquives'
import fetchHostel from '../../../../services/hostel'
import fetchFile from '../../../../services/file'
import { Car } from './components/Car'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { Acess } from './components/Acess'
import { Users } from './components/Users'
import { Customization } from './components/Customization'
import { SwitchAccounts } from '../../../../components/SwitchAccounts'

const HEADER_HEIGHT = rem(84)

const useStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
    maxWidth: '100%'
  },

  // burger: {
  //   [theme.fn.largerThan('sm')]: {
  //     display: 'none'
  //   }
  // },

  links: {
    paddingTop: theme.spacing.lg,
    height: HEADER_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 0,
    textDecoration: 'none',
    gap: '16px'

    // [theme.fn.smallerThan('sm')]: {
    //   display: 'none'
    // }
  },

  mainLinks: {
    marginRight: `calc(${theme.spacing.sm} * -1)`
  },

  mainLink: {
    color: '#404040',
    padding: `${rem(7)} ${theme.spacing.sm}`,
    transition: 'border-color 100ms ease, color 100ms ease',
    borderBottom: '1px solid var(--grey-3-grayscale, #BFBFBF)',
    textDecoration: 'none',
    fontFamily: 'Neometric',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '130%',
    letterSpacing: '0.16px',

    '&:hover': {
      textDecoration: 'none'
    }
  },

  secondaryLink: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
    fontSize: theme.fontSizes.xs,
    textTransform: 'uppercase',
    transition: 'color 100ms ease'

    // '&:hover': {
    //   color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    //   textDecoration: 'none'
    // }
  },

  mainLinkActive: {
    color: '#27c7ff',
    borderBottomColor: '4px solid var(--yellow-brand-primary, #27c7ff)',
    fontFamily: 'Neometric',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '130%',
    letterSpacing: '0.16px'
  }
}))

const INITIAL_VALUES = {
  name: '',
  email: '',
  commissionPercentage: 0,
  bankAccountCountry: '',
  bank: '',
  ibanNib: '',
  swiftBic: '',
  bankAccount: '',
  files: []
}

export function PartnershipMerge() {
  const [data, setData] = useState(INITIAL_VALUES)
  const [enableEdit, setEnableEdit] = useState()
  const [isActive, setIsActive] = useState()

  const params = useParams()
  const navigate = useNavigate()

  const mainLinks = useMemo(
    () =>
      [
        { label: 'Dados pessoais', link: '/personal-data' },
        { label: 'Morada', link: '/household' },
        { label: 'Conta bancária', link: '/bank-account' },
        { label: 'Documentos', link: '/arquive' },
        { label: 'Porcentagem', link: '/commission' },
        { label: 'Usuários', link: '/acess' },
        { label: 'Personalização', link: '/acess' }
      ].filter((item) => !(!params.id && item.label === 'Usuários')),
    []
  )

  const { classes, cx } = useStyles()
  const [active, setActive] = useState(0)

  const mainItems = mainLinks.map((item, index) => (
    <Anchor
      href={item.link}
      key={item.label}
      className={cx(classes.mainLink, {
        [classes.mainLinkActive]: index === active
      })}
      onClick={(event) => {
        event.preventDefault()
        setActive(index)
      }}
    >
      {item.label}
    </Anchor>
  ))

  useEffect(() => {
    if (params.id) {
      fetchHostel
        .getByIdAll(params.id)
        .then((res) => {
          setIsActive(res.data.active)
          setData({
            ...res.data,
            files: res.data.files ? res.data.files : []
          })
        })
        .catch((err) => console.log(err, 'err'))
    }
  }, [])

  const switchStatus = (isActive) => {
    if (params.id) {
      fetchHostel
        .status(params.id, isActive)
        .then((res) => {
          toast.success(
            `Você ${isActive ? 'ativou' : 'desativou'} a conta com sucesso!`
          )
        })
        .catch((err) => console.log(err, 'err'))
    }
  }

  const handleCreateProfile = () => {
    const toastId = toast.loading('Criando Conta...')
    if (params.id) {
      const filesPromises = []
      if (data?.image && data?.image?.base64 && !data?.image?.id) {
        const profilePictureData = {
          title: data?.image?.title,
          base64: data?.image?.base64,
          fileName: data?.image?.fileName,
          fileExtension: data?.image?.fileExtension
        }
        const createProfilePicturePromise = fetchFile
          .create(profilePictureData)
          .then((res) => {
            data.image.id = res?.data?.id
          })
          .catch((err) => console.log(err))

        filesPromises.push(createProfilePicturePromise)
      }
      if (
        data?.vehicle?.file &&
        data?.vehicle?.file?.base64 &&
        !data?.vehicle?.file?.id
      ) {
        const profilePictureData = {
          title: data.vehicle?.file.title,
          base64: data.vehicle?.file.base64,
          fileName: data.vehicle?.file.fileName,
          fileExtension: data.vehicle?.file.fileExtension
        }
        const createProfilePicturePromise = fetchFile
          .create(profilePictureData)
          .then((res) => {
            data.vehicle.file.id = res.data.id
          })
          .catch((err) => console.log(err))

        filesPromises.push(createProfilePicturePromise)
      }

      if (data.files && data.files.length) {
        data.files.forEach((file, index) => {
          if (file.base64 && !file.id) {
            const fileData = {
              title: file.title,
              base64: file.base64,
              fileName: file.fileName,
              fileExtension: file.fileExtension
            }
            const createFilePromise = fetchFile
              .create(fileData)
              .then((res) => {
                data.files[index].id = res.data.id
              })
              .catch((err) => console.log(err))

            filesPromises.push(createFilePromise)
          }
        })
      }
      const allFilesPromise = Promise.all(filesPromises)

      allFilesPromise
        .then(() => {
          const payload = {
            ...data
          }

          if (data) {
            fetchHostel
              .update(payload)
              .then((res) => {
                if (res) {
                  toast.update(toastId, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    newestOnTop: false,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: true,
                    draggable: true,
                    pauseOnHover: true,
                    theme: 'light',
                    type: 'success',
                    closeButton: true,
                    isLoading: false,
                    render: 'Conta editada com sucesso!'
                  })
                }
                navigate('/dashboard/partnership')
              })
              .catch((err) => {
                console.log(err)
                toast.update(toastId, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  newestOnTop: false,
                  closeOnClick: true,
                  rtl: false,
                  pauseOnFocusLoss: true,
                  draggable: true,
                  pauseOnHover: true,
                  theme: 'light',
                  type: 'error',
                  closeButton: true,
                  isLoading: false,
                  render: 'Erro ao editar conta'
                })
              })
          }
        })
        .catch((err) => {
          console.log(err)
          toast.update(toastId, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            newestOnTop: false,
            closeOnClick: true,
            rtl: false,
            pauseOnFocusLoss: true,
            draggable: true,
            pauseOnHover: true,
            theme: 'light',
            type: 'error',
            closeButton: true,
            isLoading: false,
            render: 'Erro ao editar partnership devido a erros nos arquivos'
          })
        })
    } else {
      const filesPromises = []
      if (data?.image && data?.image?.base64 && !data?.image?.id) {
        const profilePictureData = {
          title: data?.image?.title,
          base64: data?.image?.base64,
          fileName: data?.image?.fileName,
          fileExtension: data?.image?.fileExtension
        }
        const createProfilePicturePromise = fetchFile
          .create(profilePictureData)
          .then((res) => {
            data.image.id = res?.data?.id
          })
          .catch((err) => console.log(err))

        filesPromises.push(createProfilePicturePromise)
      }

      if (data.files && data.files.length) {
        data.files.forEach((file, index) => {
          if (file.base64 && !file.id) {
            const fileData = {
              title: file.title,
              base64: file.base64,
              fileName: file.fileName,
              fileExtension: file.fileExtension
            }
            const createFilePromise = fetchFile
              .create(fileData)
              .then((res) => {
                data.files[index].id = res.data.id
              })
              .catch((err) => console.log(err))

            filesPromises.push(createFilePromise)
          }
        })
      }
      const payload = {
        ...data
        // role: 'HOTEL_USER',
        // address: {
        //   country: 'Brasil',
        //   state: 'SP',
        //   city: 'Sumaré',
        //   district: 'teste',
        //   street: 'rua teste',
        //   number: '3',
        //   postalCode: '4324233',
        //   complement: 'complemento teste',
        //   referencePoint: 'teste',
        //   latitude: 55343,
        //   longitude: 5439885
        // }
      }

      if (data) {
        fetchHostel
          .create(payload)
          .then((res) => {
            if (res) {
              toast.update(toastId, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                theme: 'light',
                type: 'success',
                closeButton: true,
                isLoading: false,
                render: 'Conta criado com sucesso!'
              })
            }
            setData(INITIAL_VALUES)
            navigate('/dashboard/partnership')
          })
          .catch((err) => {
            console.log(err)
            toast.update(toastId, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              newestOnTop: false,
              closeOnClick: true,
              rtl: false,
              pauseOnFocusLoss: true,
              draggable: true,
              pauseOnHover: true,
              theme: 'light',
              type: 'error',
              closeButton: true,
              isLoading: false,
              render: 'Erro ao criar conta'
            })
          })
      } else {
        toast.update(toastId, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          newestOnTop: false,
          closeOnClick: true,
          rtl: false,
          pauseOnFocusLoss: true,
          draggable: true,
          pauseOnHover: true,
          theme: 'light',
          type: 'error',
          closeButton: true,
          isLoading: false,
          render: 'Preencha os dados'
        })
      }
    }
  }

  return (
    <Styled.Container>
      <Styled.HeaderContainer>
        <Styled.Title>Partnership</Styled.Title>
        <Styled.ButtonsContainer>
          {params.id && (
            <>
              <SwitchAccounts
                active={isActive}
                setActive={setIsActive}
                switchStatus={switchStatus}
              />
              <Styled.EditButton onClick={() => setEnableEdit(!enableEdit)}>
                Editar
              </Styled.EditButton>
              {enableEdit && (
                <Styled.ConfirmButton onClick={() => handleCreateProfile()}>
                  Salvar
                </Styled.ConfirmButton>
              )}
            </>
          )}

          {!params.id && (
            <Styled.ConfirmButton onClick={() => handleCreateProfile()}>
              Cadastrar
            </Styled.ConfirmButton>
          )}
        </Styled.ButtonsContainer>
      </Styled.HeaderContainer>
      <Header style={{ borderBottom: 'transparent' }}>
        <Container className={classes.inner}>
          <div className={classes.links}>
            <Group spacing={0} position="right" className={classes.mainLinks}>
              {mainItems}
            </Group>
          </div>
        </Container>
      </Header>
      {active === 0 && (
        <PersonalData
          data={data}
          setData={setData}
          disabled={params.id ? !enableEdit : false}
        />
      )}
      {active === 1 && (
        <HouseHold
          data={data}
          setData={setData}
          disabled={params.id ? !enableEdit : false}
        />
      )}
      {active === 2 && (
        <BankAccount
          data={data}
          setData={setData}
          disabled={params.id ? !enableEdit : false}
        />
      )}
      {active === 3 && (
        <Arquives
          data={data}
          setData={setData}
          disabled={params.id ? !enableEdit : false}
        />
      )}
      {active === 4 && (
        <Commission
          data={data}
          setData={setData}
          disabled={params.id ? !enableEdit : false}
        />
      )}
      {params.id && (
        <>
          {active === 5 && (
            <Users
              data={data}
              setData={setData}
              disabled={params.id ? !enableEdit : false}
            />
          )}
        </>
      )}
      {params.id
        ? active === 6 && (
            <Customization
              data={data}
              setData={setData}
              disabled={!enableEdit}
            />
          )
        : active === 5 && (
            <Customization data={data} setData={setData} disabled={false} />
          )}
    </Styled.Container>
  )
}
