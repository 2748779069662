import { Navbar } from '../../components/Navbar'
import { Header } from './Header'
import { Transfer } from './components/Transfer'
import * as Styled from './styles'
import { Steppers } from './components/Steppers'
import { ChoiceCars } from './components/ChoiceCars'
import { useEffect, useState } from 'react'
import { useTransferContext } from '../../contexts/TransferContext'
import Footer from '../../components/Footer'
import { RightContainer } from './RightContainer'
import { NavbarCheckout } from '../../components/NavbarCheckout'
import fetchCategory from '../../services/carCategory'
export const Checkout = () => {
  return (
    <>
      <div style={{ maxWidth: 988, margin: '0 auto' }}>
        <NavbarCheckout />
      </div>
      <Styled.Container>
        <Steppers stepper={1} />
        <Styled.ContentContainer>
          <Styled.MiddleContainerRow>
            <Transfer />
            <RightContainer />
          </Styled.MiddleContainerRow>
        </Styled.ContentContainer>
      </Styled.Container>
      <Footer />
    </>
  )
}
