import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

export const Title = styled.h1`
  color: var(--black-brand-primary, #222);
  font-family: Neometric;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (max-width: 768px) {
    color: var(--black-brand-primary, #0d0d0d);
    text-align: center;
    font-family: Neometric;
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    align-self: stretch;
    /* max-width: 22rem; */
  }
`

export const BtnOurFleet = styled(motion.button)`
  ${({ theme }) => css`
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    font-weight: 700;
    font-size: 1.6rem;

    width: 285px;
    height: 64px;
    background-color: ${theme.colors.primary};
    border-radius: ${theme.border.radius.medium};
    padding: 16px 24px 16px 32px;
    box-shadow: ${theme.shadows.default};

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    transition: ease-in-out 300ms;
    border: 1px solid ${theme.colors.primary};

    a {
      color: ${theme.colors.title};
    }

    svg {
      stroke: #ffffff;
      width: 1.4rem;
      height: 1.4rem;
    }

    &:hover {
      background-color: #fff;
      color: #27c7ff;
      border: solid 1px #27c7ff;

      svg {
        stroke: #27c7ff;
      }

      path {
        fill: #27c7ff;
      }
    }

    @media only screen and (max-width: 425px) {
      padding: 0;
      width: 80vw;
      margin: 0 auto;
      font-size: 1.8rem;
    }
  `}
`

const containerModifiers = {
  dark: () => css`
    ${Title} {
      color: #27c7ff;
    }

    ${BtnOurFleet} {
      color: #222222;
      svg {
        stroke: #222222;
      }
    }
  `
}

export const Container = styled.div`
  ${({ dark }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;

    ${dark && containerModifiers.dark}
  `}
`
