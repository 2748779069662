import * as S from './styles'
import { ReactComponent as CheckedIcon } from '../../../../assets/checked_icon.svg'
import { ReactComponent as Arrow } from '../../../../assets/arrow_up_right.svg'
// import HotTag from '../../../../assets/tag_hot.svg'
import CarrouselLisbon from './imgs/carrousel_lisbon.svg'
import CarrouselEriceira from './imgs/carrousel_ericeira.svg'
import { Carousel } from '@mantine/carousel'
import { useTranslation } from 'react-i18next'
// import IconWorld from '../../../../assets/IconWorld.svg'

export default function Tours() {
  const { t } = useTranslation()

  return (
    <S.CarouselWrapper style={{ position: 'relative' }}>
      <S.Container>
        <S.OrangeContainer
          initial={{ x: '-100%', opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          // exit={{ x: '-100%' }}
          viewport={{ once: true }}
          transition={{
            type: 'spring',
            duration: 1,
            bounce: 0.1,
            bounceDamping: 0.1,
            bounceStiffness: 0.1,
            delay: 0.3
          }}
          // viewport={{ once: true }}
        >
          <S.TextWrapper>
            <S.FirstTitle>{t('goBeyondTheSights')}</S.FirstTitle>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <S.SecondTitle>{t('ourImmersiveTours')}</S.SecondTitle>
              {/* <img src={IconWorld} /> */}
            </div>
            <S.ListWrapper>
              <S.ListItemWrapper>
                <CheckedIcon />
                <S.ListItemText>{t('authenticLocalExperience')}</S.ListItemText>
              </S.ListItemWrapper>
              <S.ListItemWrapper>
                <CheckedIcon />
                <S.ListItemText>
                  {t('professionalExperiencedGuides')}
                </S.ListItemText>
              </S.ListItemWrapper>
              <S.ListItemWrapper>
                <CheckedIcon />
                <S.ListItemText>
                  {t('flexibleCustomItineraries')}
                </S.ListItemText>
              </S.ListItemWrapper>
            </S.ListWrapper>
            <S.ScheduleButton>
              <S.ButtonLabel>{t('seeMore')}</S.ButtonLabel>
              <Arrow />
            </S.ScheduleButton>
          </S.TextWrapper>
        </S.OrangeContainer>
      </S.Container>
      <S.CarouselContainer
        initial={{ x: '-60px', opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        // exit={{ x: '-100%' }}
        viewport={{ once: true }}
        transition={{
          duration: 0.3,
          delay: 0.8
        }}
      >
        <Carousel
          // w={700}
          align="start"
          slideSize="50%"
          slidesToScroll={1}
          slideGap={40}
          style={{ position: 'absolute', left: '45%', top: '5%' }}
          styles={{
            control: {
              '&[data-inactive]': {
                opacity: 0,
                cursor: 'default'
              }
            }
          }}
          controlSize={50}
          loop
        >
          <Carousel.Slide>
            {/* <S.HotTag src={HotTag} /> */}
            <S.EmphasisTag>
              <span>{t('highlight')}</span>
            </S.EmphasisTag>
            <S.Image
              // className="d-block w-100"
              src={CarrouselLisbon}
              alt="First slide"
            />
            <S.ImageTitle>Lisboa</S.ImageTitle>
          </Carousel.Slide>
          <Carousel.Slide>
            {/* <S.PopularTag>POPULAR</S.PopularTag> */}
            <S.EmphasisTag>
              <span>{t('highlight')}</span>
            </S.EmphasisTag>
            <S.Image
              // className="d-block w-100"
              src={CarrouselEriceira}
              alt="Second slide"
            />

            <S.ImageTitle>Ericeira</S.ImageTitle>
          </Carousel.Slide>
        </Carousel>
      </S.CarouselContainer>
    </S.CarouselWrapper>
  )
}
