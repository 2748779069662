import { Header } from './Header'
import { Steppers } from './components/Steppers'
import { Transfer2 } from './components/Transfer2'
import * as Styled from './styles'

import { RightContainer } from './RightContainer'
import Footer from '../../components/Footer'
import { NavbarCheckout } from '../../components/NavbarCheckout'

export const Checkout2 = () => {
  return (
    <>
      <div style={{ maxWidth: 988, margin: '0 auto' }}>
        <NavbarCheckout />
      </div>
      <Styled.Container>
        <Steppers stepper={1} />

        <Styled.ContentContainer>
          <Styled.MiddleContainerRow>
            <Transfer2 />
            <RightContainer />
          </Styled.MiddleContainerRow>
        </Styled.ContentContainer>
      </Styled.Container>
      <Footer />
    </>
  )
}
