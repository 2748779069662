import styled from 'styled-components'

export const Container = styled.button.attrs({ type: 'button' })`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0 16px;
  padding: 8px 12px;
  border-radius: 16px;
  background: ${({ selected }) => (selected ? '#DFF7FF' : '#f7f7f7')};

  height: 66px;

  transition: all 0.2s;

  &:hover {
    transform: scale(1.035);
  }

  &:active {
    transform: scale(0.95);
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`

export const ProfileImage = styled.img`
  height: 40px;
  width: 40px;

  border-radius: 40px;
  background-color: #f7f7f7;

  object-fit: cover;
`

export const Name = styled.p`
  font-family: Neometric;
  text-align: left;
  color: #404040;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
`

export const Id = styled.p`
  font-family: Neometric;
  text-align: left;
  color: #404040;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
`

export const MessageCount = styled.p`
  font-family: Roboto;
  text-align: left;
  color: #27c7ff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
