import { NavbarCheckout } from '../../components/NavbarCheckout'
import { Header } from './Header'
import { ChoiceCar } from './components/ChoiceCar'
import { CouponCard } from './components/CardCoupon'
import { Transfer3 } from './components/Transfer3'
import { RouteCard } from './components/RouteCard'
import { OrderSummary } from './components/OrderSummary'
import * as Styled from './styles'
import { Steppers } from './components/Steppers'
import { ChoiceCars } from './components/ChoiceCars'
import { useState } from 'react'

export const Checkout6 = () => {
  const [showCars, setShowCars] = useState()
  const [selectedCar, setSelectedCar] = useState()

  return (
    <Styled.Container>
      <NavbarCheckout />
      <Header />
      <Steppers />
      <Styled.ChoiceCarsContainer showCars={showCars}>
        <ChoiceCars setSelectedCar={setSelectedCar} setShowCars={setShowCars} />
      </Styled.ChoiceCarsContainer>
      <Styled.ContentContainer>
        <Styled.MiddleContainerRow showCars={showCars}>
          <Transfer3 />
          <Styled.RightContainer>
            <ChoiceCar
              setOpened={setShowCars}
              opened={showCars}
              selectedCar={selectedCar}
            />
            <CouponCard />
            <OrderSummary />
            <RouteCard />
          </Styled.RightContainer>
        </Styled.MiddleContainerRow>
      </Styled.ContentContainer>
    </Styled.Container>
  )
}
