import { Table, Group, Text, Anchor } from '@mantine/core'
import dayjs from 'dayjs'
import * as Styled from './styles'

export function DataTable({ data }) {
  const formatDate = (date) => dayjs(date).format('DD/MM/YYYY - HH:mm')
  const formatCurrency = (value) =>
    Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(
      value
    )

  const rows = data?.map((item) => {
    return (
      <tr key={item.name} style={{ cursor: 'pointer' }}>
        <td>
          <Text fz="sm" fw={500}>
            {item.id}
          </Text>
        </td>

        <td>
          <Text fz="sm">{formatDate(item.transferDate)}</Text>
        </td>
        <td>
          <Text fz="sm">{formatCurrency(item.totalPrice)}</Text>
        </td>
        <td>
          <Text fz="sm">{formatCurrency(200)}</Text>
        </td>
        <td>
          <Text fz="sm">Economico</Text>
        </td>
      </tr>
    )
  })

  return (
    <Styled.Container>
      <Table verticalSpacing="sm">
        <thead>
          <tr>
            <th>ID transfer</th>
            <th>Data</th>
            <th>Valor cobrado</th>
            <th>Valor do desconto</th>
            <th>Categoria</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </Styled.Container>
  )
}
