import { TRANSLATE } from './consts'

export function handleParseError(error) {
  switch (error) {
    case 'Your card was declined.':
      return 'Cartão de crédito foi recusado!'
    case 'Your card has insufficient funds.':
      return 'Cartão de crédito não tem saldo suficiente!'
    case 'Your card has expired.':
      return 'Cartão de crédito expirado!'
    case "Your card's security code is incorrect.":
      return 'Seu código de segurança está incorreto!'
    case 'An error occurred while processing your card. Try again in a little bit.':
      return 'Erro de processamento. tente novamente em alguns instantes.'
    default:
      return 'Erro interno!'
  }
}

export function replaceHTMLEntities(string) {
  const translateRegex =
    /&(Agrave|Aacute|Acirc|Atilde|Auml|Aring|AElig|Ccedil|Egrave|Eacute|Ecirc|Euml|Igrave|Iacute|Icirc|Iuml|ETH|Ntilde|Ograve|Oacute|Ocirc|Otilde|Ouml|Oslash|Ugrave|Uacute|Ucirc|Uuml|Yacute|THORN|szlig|agrave|aacute|acirc|atilde|auml|aring|aelig|ccedil|egrave|eacute|ecirc|euml|igrave|iacute|icirc|iuml|eth|ntilde|ograve|oacute|ocirc|otilde|ouml|oslash|ugrave|uacute|ucirc|uuml|yacute|thorn|yuml);/g

  const translator = ($0, $1) => {
    return TRANSLATE[$1]
  }

  const replacedString = string.replace(translateRegex, translator)

  return replacedString
}
