import {
  Button,
  Divider,
  Flex,
  LoadingOverlay,
  MediaQuery
  // , Pagination
} from '@mantine/core'
import * as Styled from './styles'
import { useEffect } from 'react'
import { ReactComponent as MultibancoLogo } from './imgs/multibanco-logo.svg'
import { ReactComponent as CreditCard } from './imgs/credit-card.svg'
import { ReactComponent as MbWayLogo } from './imgs/mb_way_logo.svg'
import { ReactComponent as CashLogo } from './imgs/cash-icon.svg'
import fetchBooking from '../../../../../../services/booking'
import { useState } from 'react'
import {
  formatDateAndTime,
  formatDayOfWeek,
  formatMonth,
  formatTime
} from '../../../../../../utils/formattedDate'
import { RejectModal } from '../RejectModal'
import { useAuth } from '../../../../../../contexts/useAuth'
import { useClipboard } from '@mantine/hooks'
import StatusPayment from '../StatusPayment'
import { useParams } from 'react-router-dom'

export const DetailsPayment = ({ transfer }) => {
  const [loading, setLoading] = useState(true)
  const [acceptedModal, setAcceptedModal] = useState(false)
  const [rejectModal, setRejectModal] = useState(false)
  const [transferContent, setTransferContent] = useState()
  const [refreshBookings, setRefreshBookings] = useState(false)

  const clipboard = useClipboard({ timeout: 500 })

  const { user } = useAuth()
  const { id } = useParams()

  console.log(transfer, 'id')

  return (
    <>
      <Styled.CardContainer>
        <Styled.Container key={transfer?.id}>
          <Styled.Wrapper>
            <Styled.InfoWrapper>
              <Styled.PaymentTitle>
                <CreditCard />
                Pagamento
              </Styled.PaymentTitle>
            </Styled.InfoWrapper>
            <Styled.ValuesWrapper>
              <MediaQuery
                query="(max-width: 1024px)"
                styles={{ display: 'none' }}
              >
                <Styled.PriceTitle>
                  <Styled.DepartureTitle>VALOR A COBRAR</Styled.DepartureTitle>€
                  {(transfer?.totalPrice / 100).toLocaleString('pt-PT')}
                </Styled.PriceTitle>
              </MediaQuery>
            </Styled.ValuesWrapper>
            <Styled.ValuesWrapper>
              <MediaQuery
                query="(max-width: 1024px)"
                styles={{ display: 'none' }}
              >
                <Styled.PriceTitle>
                  <Styled.DepartureTitle>
                    VALOR EASYTRANSFER
                  </Styled.DepartureTitle>
                  €
                  {(
                    transfer?.totalPrice / 100 -
                    transfer?.totalDriverCommission / 100
                  ).toLocaleString('pt-PT')}
                </Styled.PriceTitle>
              </MediaQuery>
            </Styled.ValuesWrapper>
            <Styled.ValuesWrapper>
              <MediaQuery
                query="(max-width: 1024px)"
                styles={{ display: 'none' }}
              >
                <Styled.PriceTitle>
                  <Styled.DepartureTitle>
                    VALOR LÍQUIDO MOTORISTA
                  </Styled.DepartureTitle>
                  €
                  {(transfer?.totalDriverCommission / 100).toLocaleString(
                    'pt-PT'
                  )}
                </Styled.PriceTitle>
              </MediaQuery>
            </Styled.ValuesWrapper>
            <Styled.PaymentDetails>
              <Styled.PaymentType>
                {transfer?.paymentMethod === 'MBMULTI' ? (
                  <MultibancoLogo width={30} height={33} />
                ) : transfer?.paymentMethod === 'CREDIT_CARD' ? (
                  <CreditCard />
                ) : transfer?.paymentMethod === 'MBWAY' ? (
                  <MbWayLogo />
                ) : (
                  <CashLogo stroke={'#27c7ff'} />
                )}
              </Styled.PaymentType>
              <Styled.PaymentInfo>
                <Styled.PaymentInfoTitle>
                  Método de pagamento:
                </Styled.PaymentInfoTitle>
                <Styled.PaymentInfoDesc>
                  {transfer?.paymentMethod === 'CREDIT_CARD'
                    ? 'Cartão de credito'
                    : transfer?.paymentMethod}
                </Styled.PaymentInfoDesc>
                <Styled.PaymentInfoTitle>Status:</Styled.PaymentInfoTitle>
                {transfer?.paymentStatus === 'PENDING' ? (
                  <StatusPayment status="Pendente" />
                ) : (
                  <StatusPayment status="Aprovado" />
                )}
                {/* <Styled.PaymentHour>10/00/00 - 10:00</Styled.PaymentHour> */}
              </Styled.PaymentInfo>
            </Styled.PaymentDetails>
          </Styled.Wrapper>
        </Styled.Container>

        <RejectModal
          opened={rejectModal}
          onClose={() => setRejectModal(false)}
        />
      </Styled.CardContainer>
    </>
  )
}
