import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Styled from './styles'
import { Button, Stepper } from '@mantine/core'
import GoBack from './img/goBack.svg'
import { useNavigate, useLocation } from 'react-router'
import { useMediaQuery } from '../../../../hooks/useMediaQuery'

export const Steppers = ({ stepper = 0 }) => {
  const { t } = useTranslation()
  // eslint-disable-next-line no-unused-vars
  const [active, setActive] = useState(stepper)
  const mobileMatch = useMediaQuery('(max-width: 540px)')

  const navigate = useNavigate()

  const { pathname } = useLocation()

  const handleClickBack = () => {
    if (pathname === '/checkout4') {
      navigate('/')

      return
    }

    navigate(-1)
  }

  return (
    <Styled.Container>
      <Button
        onClick={handleClickBack}
        variant="subtle"
        leftIcon={<img src={GoBack} alt="Go Back" />}
      />
      <Stepper
        active={mobileMatch ? (pathname === '/checkout4' ? 1 : 0) : active}
        // onStepClick={setActive}
      >
        {((mobileMatch && ['/checkout5'].some((path) => path === pathname)) ||
          !mobileMatch) && (
          <Stepper.Step
            icon={1}
            label={t('stepper.step1')}
            description={'Evento'}
          />
        )}
        {((mobileMatch &&
          ['/checkout5', '/checkout', '/checkout2'].some(
            (path) => path === pathname
          )) ||
          !mobileMatch) && (
          <Stepper.Step
            icon={2}
            label={t('stepper.step2')}
            description={t('checkoutTour.contactData')}
          />
        )}
        {((mobileMatch &&
          ['/checkout', '/checkout2', '/checkout3', '/checkout4'].some(
            (path) => path === pathname
          )) ||
          !mobileMatch) && (
          <Stepper.Step
            icon={3}
            label={t('stepper.step3')}
            description={t('stepper.step3Description')}
          />
        )}
        {((mobileMatch &&
          ['/checkou2', '/checkout3', '/checkout4'].some(
            (path) => path === pathname
          )) ||
          !mobileMatch) && (
          <Stepper.Step
            icon={4}
            label={t('stepper.step4')}
            description={t('stepper.step4Description')}
          />
        )}
      </Stepper>
    </Styled.Container>
  )
}
