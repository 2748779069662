import styled from 'styled-components'

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ opened }) => (opened ? 'block' : 'none')};
  z-index: 100;
`
export const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${({ opened }) => (opened ? 'block' : 'none')};
`

export const ModalContainer = styled.div`
  position: fixed;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: stretch;
  z-index: 200;
  max-width: 600px;
  width: 100%;
  z-index: 101;

  gap: 10px;
  border-radius: 22px;
  background: #fff;
  box-shadow: 0px 22px 24px 0px rgba(0, 0, 0, 0.07),
    0px -1px 16px 0px rgba(0, 0, 0, 0.05);
  display: ${({ opened }) => (opened ? 'flex' : 'none')};
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  margin-bottom: 40px;

  .mantine-InputWrapper-root {
    width: 100%;
  }
  .mantine-Input-input {
    display: flex;
    width: 100%;
    border-radius: 6.095px;
    color: #a2a2a2;
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 54px;
  }

  .mantine-InputWrapper-label {
    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`
export const ModalContentSelect = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  margin-bottom: 10px;
`
export const ModalTitle = styled.p`
  color: var(--grey-1-grayscale, #404040);
  text-align: center;
  font-family: Neometric;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  letter-spacing: 0.14px;
  align-self: stretch;
`
export const ModalTitleSelect = styled.p`
  color: var(--grey-1-grayscale, #404040);
  text-align: center;
  font-family: Neometric;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  letter-spacing: 0.14px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;

  .mantine-Select-root {
    width: 100%;
  }

  .mantine-Select-input {
    text-align: center;
  }
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Title = styled.h1`
  color: var(--black-brand-primary, #222);
  font-family: Neometric;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
  letter-spacing: 0.24px;
`

export const CloseButton = styled.button`
  width: 32px;
  height: 32px;
  background-color: transparent;
`
export const Container = styled.div`
  h1 {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;

    letter-spacing: 0.01em;

    color: #0d0d0d;
  }

  h2 {
    color: var(--grey-1-grayscale, #404040);

    /* txt reg  - 16px */
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.16px;
  }

  @media only screen and (max-width: 944px) {
    max-width: 720px;
    min-width: 0;
    width: 100%;
    padding: 1.6rem;

    h1 {
      font-size: 2rem;
    }
  }

  @media only screen and (max-width: 450px) {
    .mantine-Input-input {
      font-size: 1.4rem;
      height: 4.8rem;
      &::placeholder {
        font-size: 1.4rem;
      }
    }
  }

  label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #0d0d0d;
    margin: 10px 0;
  }

  input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12.1898px 8px;
    gap: 112.77px;

    width: 100%;
    height: 54px;

    border: 0.704782px solid rgba(56, 40, 14, 0.3);
    border-radius: 8.45738px;

    margin-bottom: 10px;

    font-family: 'Neometric';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #0d0d0d;

    &:placeholder {
      color: #a2a2a2;
    }

    &:focus {
      border-color: #27c7ff;
    }
  }

  button {
    mantine-ActionIcon-root btn-minus mantine-47hpvh {
      height: 22px;
      width: 22px;
    }
  }

  .mantine-Tabs-panel {
    > .mantine-Checkbox-root {
      margin-top: 1.2rem;
      .mantine-Checkbox-body {
        display: flex;
        align-items: center;
      }
      .mantine-Checkbox-label {
        color: var(--black-brand-primary, #0d0d0d);
        font-family: Neometric;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 19.2px */
        letter-spacing: 0.16px;
      }
      .mantine-Checkbox-inner {
        width: 20px;
        height: 20px;
      }
      .mantine-Checkbox-input {
        width: 20px;
        height: 20px;
        border-radius: 0.4rem;
        padding: 0;
      }
    }
  }
`

export const CardNumber = styled.div`
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 8px;

  margin: 10px 0;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

    color: #a2a2a2;
  }
`

export const NumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .mantine-NumberInput-input {
    padding: 16px;
    border: 0.5px solid rgba(56, 40, 14, 0.3);
    border-radius: 6px;
  }

  .btn-plus {
    position: absolute;
    right: 0.5rem;
    align-items: center;
  }
  .btn-plus-checked {
    position: absolute;
    right: 0.5rem;
    background: rgba(162, 162, 162, 0.4);
    border: 0.5px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    width: 22.55px !important;
    height: 22.55px !important;
    min-width: 22.55px !important;
    min-height: 22.55px !important;
    align-items: center;
    font-size: 22px;
    color: #0d0d0d;
  }
  .btn-minus {
    position: absolute;
    left: 0.5rem;
    z-index: 99;
    align-items: flex-end;
  }
`
export const AditionalItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .mantine-NumberInput-input {
    padding: 16px;
    border: 0.5px solid rgba(56, 40, 14, 0.3);
    border-radius: 6px;
  }

  .btn-plus {
    position: absolute;
    right: 0.5rem;
    align-items: flex-end !important;

    /* align-items: center; */
  }
  .btn-plus-checked {
    position: absolute;
    right: 0.5rem;
    background: rgba(162, 162, 162, 0.4);
    border: 0.5px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    width: 22.55px !important;
    height: 22.55px !important;
    min-width: 22.55px !important;
    min-height: 22.55px !important;
    /* align-items: center; */
    font-size: 22px;
    color: #0d0d0d;
  }
  .btn-minus {
    position: absolute;
    left: 0.5rem;
    z-index: 99;
    align-items: flex-end !important;
  }
`

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3rem;

  margin-top: 20px;

  @media only screen and (max-width: 366px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1.6rem;
    > div {
      width: 100%;
    }
  }
`

export const CheckboxOptionsContainer = styled.div`
  margin-top: 10px;

  .mantine-Checkbox-body {
    align-items: center;

    label {
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;

      letter-spacing: 0.01em;
      margin-bottom: 10px;

      color: #000000;
    }
  }

  .mantine-Checkbox-input {
    display: flex;
    align-items: flex-start;
    padding: 1.44609px;
    gap: 7.23px;

    width: 20px;
    height: 20px;
    border-radius: 5.78435px;
    cursor: pointer;
  }

  .mantine-Checkbox-input:checked {
    background: #27c7ff;
    border-color: #27c7ff;

    svg {
      width: 11.41px;
    }
  }

  @media only screen and (max-width: 944px) {
    .mantine-Checkbox-body {
      label {
        font-size: 1.4rem;
      }
    }
  }
`

export const ChildCardsWrapper = styled.div`
  margin-top: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 17.35px;

  width: 100%;

  @media only screen and (max-width: 650px) {
    justify-content: flex-start;
    overflow: auto;
  }
`

export const ChildCardContainer = styled.div`
  .mantine-Checkbox-input {
    width: 16.63px;
    height: 16.63px;
    border-radius: 16.63px;

    position: absolute;
    top: 17px;
    left: 5px;
  }
`

export const ChildCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding: 8px 0px;
  gap: 2.89px;

  width: 166.3px;
  height: 180.3px;
  background: #ffffff;
  border: 0.723044px solid #bfbfbf;
  border-radius: 11.5687px;
`

export const ChildCardText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5.06px;

  width: 124px;
  height: 67.62px;

  h1 {
    width: 180px;
    height: 17px;
    margin-bottom: 0;

    font-weight: 600;
    font-size: 14px;
    line-height: 120%;

    letter-spacing: 0.01em;

    color: #38280e;
    text-align: center;
  }

  span {
    font-weight: 300;
    font-size: 10px;
    line-height: 120%;

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #808080;
  }
`

export const ChildCardImg = styled.img`
  height: 80.98px;
`

export const NumberSmallContainer = styled.div`
  .mantine-NumberInput-input {
    width: 85.82px;
    height: auto;
    padding: 10px 7.80189px 10px 8.24794px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 54.99px;

    border: 0.343664px solid #bfbfbf;
    border-radius: 4.12397px;

    font-weight: 500;
    font-size: 10.1226px;
    line-height: 12px;

    color: #a2a2a2;
  }

  .btn-plus {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5.49863px;
    gap: 6.87px;
    font-size: 16px;

    width: 20px;
    height: 20px;

    background: rgba(162, 162, 162, 0.4);
    border: 0.343664px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 2.74932px 4.12397px rgba(0, 0, 0, 0.07);
    border-radius: 10.9973px;

    position: absolute;
    right: 0.5rem;
    top: 0.75rem;
  }
  .btn-plus-checked {
    position: absolute;
    right: 0.5rem;
    background: rgba(162, 162, 162, 0.4);
    border: 0.5px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    width: 22.55px !important;
    height: 22.55px !important;
    min-width: 22.55px !important;
    min-height: 22.55px !important;
    align-items: center;
    font-size: 22px;
    color: #0d0d0d;
  }
  .btn-minus {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5.49863px;
    gap: 6.87px;
    font-size: 16px;

    width: 20px;
    height: 20px;

    background: rgba(162, 162, 162, 0.4);
    border: 0.343664px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 2.74932px 4.12397px rgba(0, 0, 0, 0.07);
    border-radius: 10.9973px;
    position: absolute;
    left: 0.5rem;
    top: 0.75rem;
    z-index: 99;
  }
`

export const CheckedItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 16px 0px;
  gap: 17px;

  height: 125px;
  margin-top: 20px;

  .mantine-Checkbox-body {
    align-items: center;

    label {
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;

      letter-spacing: 0.01em;

      color: #000000;
    }
  }

  .mantine-Checkbox-input {
    display: flex;
    align-items: flex-start;
    padding: 1.44609px;
    gap: 7.23px;

    width: 20px;
    height: 20px;
    border-radius: 5.78435px;
    cursor: pointer;
  }

  .mantine-Checkbox-input:checked {
    background: #27c7ff;
    border-color: #27c7ff;

    svg {
      width: 11.41px;
    }
  }

  @media only screen and (max-width: 400px) {
    .mantine-Checkbox-body {
      label {
        font-size: 1.4rem;
      }
    }
  }
`

export const CheckedItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 4px;

  height: fit-content;

  background: #DFF7FF;
  border-radius: 8px;

  label {
    margin: 0;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 17px;
`

export const JumpButton = styled.button`
  font-family: 'Roboto' !important;
  padding: 23.0611px 17.2958px;
  border: 2px solid #27c7ff;
  border-radius: 16px;
  filter: drop-shadow(0px 4px 8px rgba(223, 247, 255, 0.5));
  background-color: transparent;
  width: 216.2px;
  height: 53.59px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 700;
  font-size: 16px;
  line-height: 120%;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #27c7ff;
`

export const SaveButton = styled.button`
  width: 100%;
  height: 53.59px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 17.2958px 23.0611px;

  background: #27c7ff;
  border-radius: 16px;

  font-family: 'Roboto' !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-transform: uppercase;

  color: #ffffff;
`

export const SecondaryButton = styled.button`
  display: flex;
  width: 228.449px;
  height: 48px;
  padding: 17.296px 23.061px;
  justify-content: center;
  align-items: center;
  gap: 17.296px;
  flex-shrink: 0;
  border-radius: 11.531px;
  border: 1.441px solid var(--yellow-brand-primary, #27c7ff);
  background-color: transparent;

  color: var(--yellow-brand-primary, #27c7ff);
  text-align: center;
  font-family: Roboto;
  font-size: 14.413px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 17.296px */
  letter-spacing: 0.144px;
  text-transform: uppercase;
`

export const ModalButton = styled.button`
  display: flex;
  width: 228.449px;
  height: 48px;
  padding: 17.296px 23.061px;
  justify-content: center;
  align-items: center;
  gap: 17.296px;
  flex-shrink: 0;
  border-radius: 11.531px;
  background: #27c7ff;

  color: var(--white-grayscale, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;
  text-transform: uppercase;
`

export const SurfAndAnimalsWrapper = styled.div`
  width: 100%;

  @media only screen and (max-width: 944px) {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1.6rem;
    justify-content: space-evenly;

    ${CheckboxOptionsContainer} {
      flex: 1;
      width: 100%;
      min-width: 15.8rem;

      .mantine-Checkbox-label {
        font-size: 1.4rem;
      }

      .mantine-InputWrapper-root {
        width: 100% !important;
      }
    }
  }

  @media only screen and (max-width: 372px) {
    gap: 1rem;
    justify-content: flex-start;

    ${CheckboxOptionsContainer} {
      margin: 0;
    }
  }
`

export const StopsCarouselWrapper = styled.div`
  margin-bottom: 2.4rem;
  strong {
    color: #222222;
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: 0.16px;
    margin-top: 3.2rem;
    margin-bottom: 1.6rem;
    display: block;
  }

  .mantine-Carousel-controls {
    button {
      &:first-of-type {
        margin-left: -2.4rem;
      }

      &:last-of-type {
        margin-right: -2.4rem;
      }

      &[data-inactive] {
        visibility: hidden;
        pointer-events: none;
      }
    }
  }

  @media only screen and (max-width: 470px) {
    .mantine-Carousel-container {
      margin-right: calc(8px * -1);
    }

    .mantine-Carousel-slide {
      padding-right: 8px;
    }
  }
`

export const OptionsWrapper = styled.div`
  display: flex;
  gap: 16px;
`

export const Option = styled.div`
  display: flex;
  padding: 16px 24px 16px 16px;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: ${({ active }) =>
    active ? '1px solid #27c7ff' : '1px solid #bfbfbf'};

  background-color: ${({ active }) => (active ? '#DFF7FF' : 'white')};

  .mantine-Radio-root {
    cursor: pointer;
  }

  .mantine-Radio-body {
    display: flex;
    align-items: center;
  }

  .mantine-Radio-inner {
    width: 2rem;
    height: 2rem;
    cursor: pointer;

    > input {
      padding: initial;
      width: 2rem;
      height: 2rem;
      background-color: #ffffff;
      border-color: #bfbfbf;
      cursor: pointer;
    }

    svg {
      width: 1.4rem;
      height: 1.4rem;

      top: calc(50% - 1.4rem / 2);
      left: calc(50% - 1.4rem / 2);

      color: #27c7ff;
      cursor: pointer;
    }
  }

  .mantine-Radio-label {
    margin: 0;
    line-height: 1;
    cursor: pointer;

    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`
export const ItensWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SubTitle = styled.h2`
  color: #404040;
  font-size: 12px;
`

export const Description = styled.span``
