import * as Styled from './styles'

export const VehicleCard = ({
  vehicleImgSrc,
  vehicleName,
  vehicleSeatsQtd
}) => {
  return (
    <Styled.Card>
      <Styled.CardContent>
        <div>
          <img src={vehicleImgSrc} alt={vehicleName} />
        </div>

        <div>
          <span>{vehicleName}</span>
          <span>{vehicleSeatsQtd} pessoas</span>
        </div>
      </Styled.CardContent>
    </Styled.Card>
  )
}
