import styled from 'styled-components'

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100dvh;

  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;

  padding: 1.6rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c5c5c5;
    border-radius: 1rem;
  }

  scrollbar-width: thin;
  scrollbar-color: #c5c5c5 transparent;
`

export const ModalContainer = styled.div`
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  overflow-x: hidden;

  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-end;
  z-index: 200;

  gap: 10px;
  border-radius: 22px;
  width: 54rem;
  background: #fff;
  box-shadow: 0px 22px 24px 0px rgba(0, 0, 0, 0.07),
    0px -1px 16px 0px rgba(0, 0, 0, 0.05);
  display: flex;
`

export const ModalContent = styled.div`
  width: 100%;

  strong {
    display: block;
    color: var(--black-brand-primary, #222);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.34px;
    line-height: normal;
    margin: 0.8rem 0 1.6rem;
  }

  label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #0d0d0d;
    margin: 1rem 0;

    &.solo {
      display: block;
      margin-top: 2rem;
    }
  }

  .mantine-Input-icon svg {
    stroke: #bfbfbf;
  }

  select {
    cursor: pointer;
  }

  .mantine-NumberInput-input {
    padding: 0 !important;
  }

  input:not(.mantine-TimeInput-input):not(.mantine-Radio-radio):not(
      .mantine-Checkbox-input
    ),
  select,
  .mantine-InputWrapper-root .mantine-Input-wrapper > button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    padding: 12.1898px 8px;
    padding-left: 3.2rem;
    gap: 112.77px;

    width: 100%;
    height: 54px;

    border: 0.704782px solid rgba(56, 40, 14, 0.3);
    border-radius: 8.45738px;

    margin-bottom: 10px;

    font-family: 'Neometric';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #0d0d0d;

    &:placeholder {
      color: #a2a2a2;
    }

    &:focus {
      border-color: #27c7ff;
    }

    button {
      .mantine-ActionIcon-root .btn-minus .mantine-47hpvh {
        height: 22px;
        width: 22px;
      }
    }

    &.empty {
      color: #a2a2a2;
    }

    option {
      color: #0d0d0d;
    }
  }
`

export const ModalButton = styled.button`
  display: flex;
  height: 56px;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 16px;
  background: var(--yellow-brand-primary, #27c7ff);
  color: var(--white-grayscale, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  letter-spacing: 0.2px;
  text-transform: uppercase;
  width: 22.8rem;
  margin: 2.4rem auto 0;

  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    color: var(--black-brand-primary, #222);
    font-family: Neometric;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.24px;
  }
`

export const CloseButton = styled.button`
  width: 32px;
  height: 32px;
  background-color: transparent;
`

export const ModelImageWrapper = styled.div`
  flex: 1;
  width: 100%;
  border-radius: 0.8rem;
  border: 0.1rem solid #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15rem;

  img {
    width: 10rem;
  }
`

export const OptionsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 3rem;
  flex-wrap: wrap;
  margin-top: 20px;
`
export const NumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 29%;

  .mantine-NumberInput-input {
    padding: 16px;
    border: 0.5px solid rgba(56, 40, 14, 0.3);
    border-radius: 6px;
  }

  .btn-plus {
    position: absolute;
    right: 0.5rem;
    align-items: center;
  }
  .btn-plus-checked {
    position: absolute;
    right: 0.5rem;
    background: rgba(162, 162, 162, 0.4);
    border: 0.5px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    width: 22.55px !important;
    height: 22.55px !important;
    min-width: 22.55px !important;
    min-height: 22.55px !important;
    align-items: center;
    font-size: 22px;
    color: #0d0d0d;
  }
  .btn-minus {
    position: absolute;
    left: 0.5rem;
    z-index: 99;
    align-items: flex-end;
  }
`

export const CheckboxOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 10px;

  .mantine-Checkbox-body {
    align-items: center;
    gap: 10px;

    label {
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;

      letter-spacing: 0.01em;

      color: #000000;
    }
  }

  .mantine-Checkbox-input {
    display: flex;
    align-items: flex-start;
    padding: 1.44609px;
    gap: 7.23px;

    width: 20px;
    height: 20px;
    border-radius: 5.78435px;
    cursor: pointer;
  }

  .mantine-Checkbox-input:checked {
    background: #27c7ff;
    border-color: #27c7ff;

    svg {
      width: 11.41px;
    }
  }

  @media only screen and (max-width: 944px) {
    .mantine-Checkbox-body {
      label {
        font-size: 1.4rem;
      }
    }
  }
`

export const SurfAndAnimalsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 6rem;

  @media only screen and (max-width: 944px) {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1.6rem;
    justify-content: space-evenly;

    ${CheckboxOptionsContainer} {
      flex: 1;
      width: 100%;
      min-width: 15.8rem;

      .mantine-Checkbox-label {
        font-size: 1.4rem;
      }

      .mantine-InputWrapper-root {
        width: 100% !important;
      }
    }
  }

  @media only screen and (max-width: 372px) {
    gap: 1rem;
    justify-content: flex-start;

    ${CheckboxOptionsContainer} {
      margin: 0;
    }
  }
`
export const ChildCardsWrapper = styled.div`
  margin-top: 10px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 17.35px;

  width: 100%;

  @media only screen and (max-width: 650px) {
    justify-content: flex-start;
    overflow: auto;
  }
`

export const ChildCardContainer = styled.div`
  .mantine-Checkbox-input {
    width: 16.63px;
    height: 16.63px;
    border-radius: 16.63px;

    position: absolute;
    top: 17px;
    left: 5px;
  }
`
export const ChildCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding: 8px 0px;
  gap: 2.89px;

  width: 166.3px;
  height: 166.3px;
  background: #ffffff;
  border: 0.723044px solid #bfbfbf;
  border-radius: 11.5687px;
`

export const ChildCardText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5.06px;

  width: 124px;
  height: 67.62px;

  h1 {
    width: 180px;
    height: 17px;
    margin-bottom: 0;

    font-weight: 600;
    font-size: 14px;
    line-height: 120%;

    letter-spacing: 0.01em;

    color: #38280e;
    text-align: center;
  }

  span {
    font-weight: 300;
    font-size: 10px;
    line-height: 120%;

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #808080;
  }
`

export const ChildCardImg = styled.img`
  height: 80.98px;
`
export const NumberSmallContainer = styled.div`
  .mantine-NumberInput-input {
    width: 85.82px;
    height: 28.5px;
    padding: 8.24794px 7.80189px 8.24794px 8.24794px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 54.99px;

    border: 0.343664px solid #bfbfbf;
    border-radius: 4.12397px;

    font-weight: 500;
    font-size: 10.1226px;
    line-height: 12px;

    color: #a2a2a2;
  }

  .btn-plus {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5.49863px;
    gap: 6.87px;

    width: 11px;
    height: 11px;

    background: rgba(162, 162, 162, 0.4);
    border: 0.343664px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 2.74932px 4.12397px rgba(0, 0, 0, 0.07);
    border-radius: 10.9973px;

    position: absolute;
    right: 0.5rem;
    top: 0.75rem;
  }
  .btn-plus-checked {
    position: absolute;
    right: 0.5rem;
    background: rgba(162, 162, 162, 0.4);
    border: 0.5px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    width: 22.55px !important;
    height: 22.55px !important;
    min-width: 22.55px !important;
    min-height: 22.55px !important;
    align-items: center;
    font-size: 22px;
    color: #0d0d0d;
  }
  .btn-minus {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5.49863px;
    gap: 6.87px;

    width: 11px;
    height: 11px;

    background: rgba(162, 162, 162, 0.4);
    border: 0.343664px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 2.74932px 4.12397px rgba(0, 0, 0, 0.07);
    border-radius: 10.9973px;
    position: absolute;
    left: 0.5rem;
    top: 0.75rem;
    z-index: 99;
  }
`
export const CheckboxAditionalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  label {
    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
    letter-spacing: 0.16px;
  }

  .mantine-Checkbox-label {
    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 19.2px */
    letter-spacing: 0.16px;
  }

  .mantine-Checkbox-body {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .mantine-Checkbox-input:checked {
    background: #27c7ff;
    border-color: #27c7ff;

    svg {
      width: 11.41px;
    }
  }
`
