import styled from 'styled-components'

export const Container = styled.div`
  .button {
    display: flex;
    align-items: center;
    transition: background-color 150ms ease, border-color 150ms ease;
    border-radius: 0.8rem;
    padding: 0.8rem;
    border: 0.1rem solid #bfbfbf;
    background-color: #ffffff;
    width: fit-content;

    &[data-checked] {
      border-color: #27c7ff;
    }

    > .mantine-Checkbox-root {
      margin-right: 1.4rem;
      .mantine-Checkbox-body {
        display: flex;
        align-items: center;
      }
      .mantine-Checkbox-label {
        color: var(--black-brand-primary, #0d0d0d);
        font-family: Neometric;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 19.2px */
        letter-spacing: 0.16px;
      }
      .mantine-Checkbox-inner {
        width: 20px;
        height: 20px;
      }
      .mantine-Checkbox-input {
        width: 20px;
        height: 20px;
        border-radius: 0.4rem;
        padding: 0;
      }
    }

    img {
      border-radius: 0.8rem;
    }

    .body {
      margin-left: 1.4rem;
      display: flex;
      flex-direction: column;
      gap: 0.4rem;

      h1 {
        line-height: 1;
        font-size: 1.8rem;
        font-weight: 700;
        margin: 0;
      }

      .time {
        color: #404040;
        font-size: 1.2rem;
        font-weight: 400;
      }

      .price {
        color: #404040;
        font-size: 1.6rem;
        font-weight: 400;

        span {
          font-weight: 600;
        }
      }
    }
  }

  @media only screen and (max-width: 470px) {
    .button {
      > .mantine-Checkbox-root {
        margin-right: 0.8rem;
        .mantine-Checkbox-inner {
          width: 16px;
          height: 16px;
        }
        .mantine-Checkbox-input {
          width: 16px;
          height: 16px;
        }
      }

      .mantine-Image-root {
        width: 6rem !important;

        .mantine-Image-image {
          width: 6rem !important;
          height: 6rem !important;
        }
      }

      .body {
        margin-left: 0.8rem;
        h1 {
          font-size: 1.4rem;
        }

        .price {
          font-size: 1.4rem;
        }
      }
    }
  }

  @media only screen and (max-width: 360px) {
    .button {
      .mantine-Image-root {
        width: 4.6rem !important;

        .mantine-Image-image {
          width: 4.6rem !important;
          height: 4.6rem !important;
        }
      }

      .body {
        h1 {
          font-size: 1.2rem;
        }

        .time {
          font-size: 1rem;
        }

        .price {
          font-size: 1.2rem;
        }
      }
    }
  }
`
