import modelo1 from '../imgs/modelo1.svg'
import modelo2 from '../imgs/modelo2.svg'
import modelo3 from '../imgs/modelo3.svg'
import modelo4 from '../imgs/modelo4.svg'
import modelo5 from '../imgs/modelo5.svg'

const MODELS_OPTIONS = [
  {
    value: 'MODELO1',
    label: 'Modelo 1'
  },
  {
    value: 'MODELO2',
    label: 'Modelo 2'
  },
  {
    value: 'MODELO3',
    label: 'Modelo 3'
  },
  {
    value: 'MODELO4',
    label: 'Modelo 4'
  },
  {
    value: 'MODELO5',
    label: 'Modelo 5'
  }
]

const MODEL_DECIDER = {
  MODELO1: modelo1,
  MODELO2: modelo2,
  MODELO3: modelo3,
  MODELO4: modelo4,
  MODELO5: modelo5
}

const INITIAL_STATE = {
  name: '',
  kmPerLiter: {
    value: '',
    formattedValue: ''
  },
  passengerCapacity: 0,
  luggageCapacity: 0,
  handLuggageCapacity: 0,
  backpackCapacity: 0,
  providesAirConditioning: false,
  providesInternet: false,
  imageIdentifier: 'MODELO1',
  files: []
}

export { INITIAL_STATE, MODELS_OPTIONS, MODEL_DECIDER }
