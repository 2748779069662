import { Outlet } from 'react-router-dom'
import { MobileSidebar } from '../MobileSidebar'
import * as S from './styles'
import { useMediaQuery } from '../../hooks/useMediaQuery'

const OVERFLOW_ALLOWED_LIST = ['/tour/', '/eventos']

export const DefaultPage = () => {
  const mobileMatch = useMediaQuery('(max-width: 768px)')

  return (
    <>
      {mobileMatch && <MobileSidebar />}
      <S.Wrapper
        withoutOverflow={OVERFLOW_ALLOWED_LIST.some((i) =>
          window.location.pathname.includes(i)
        )}
      >
        <Outlet />
      </S.Wrapper>
    </>
  )
}
