import { Input } from '@mantine/core'
import * as Styled from './styles'
import { Banknote } from 'lucide-react'

export const Commission = ({ data, setData, disabled }) => {
  const handleInputChange = (field, value) => {
    setData((prevValues) => ({
      ...prevValues,
      [field]: value
    }))
  }

  return (
    <Styled.Container>
      <Styled.Title>
        Insira um valor que represente a porcentagem de lucro líquido
      </Styled.Title>
      <Styled.SubTitle>Porcentagem</Styled.SubTitle>
      <Styled.InputWrapper>
        <Input.Wrapper>
          <Input
            onChange={(event) =>
              handleInputChange(
                'commissionPercentage',
                Number(event.target.value)
              )
            }
            value={data?.commissionPercentage}
            icon={<Banknote />}
            disabled={disabled}
          />
        </Input.Wrapper>
      </Styled.InputWrapper>
      <Styled.Title>
        Insira um valor que represente a porcentagem em IVA
      </Styled.Title>
      <Styled.SubTitle>Porcentagem de IVA</Styled.SubTitle>
      <Styled.InputWrapper>
        <Input.Wrapper>
          <Input
            onChange={(event) =>
              handleInputChange('ivaPercentage', Number(event.target.value))
            }
            value={data?.ivaPercentage}
            icon={<Banknote />}
            disabled={disabled}
          />
        </Input.Wrapper>
      </Styled.InputWrapper>
    </Styled.Container>
  )
}
