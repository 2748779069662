import busImg from '../../imgs/vehicles/bus.svg'
import sedanExecutiveImg from '../../imgs/vehicles/sedan-executive.svg'
import vanExecutiveImg from '../../imgs/vehicles/van-executive.svg'
import limousineImg from '../../imgs/vehicles/limousine.svg'

export const vehicles = (t) => [
  {
    imgSrc: sedanExecutiveImg,
    name: t('events.executiveSedan'),
    value: 'SedanExecutivo'
  },
  {
    imgSrc: vanExecutiveImg,
    name: t('events.executiveVan'),
    value: 'VanExecutiva'
  },
  {
    imgSrc: limousineImg,
    name: t('corporate.limousine'),
    value: 'Limousine'
  },
  {
    imgSrc: busImg,
    name: t('events.bus'),
    value: 'Autocarro'
  }
]
