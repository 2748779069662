import * as Styled from './styles'
import { Input } from '@mantine/core'
import { DateTimePicker } from './components/DateTimePicker'
import { Passengers } from './components/Passengers'
import { useTransferContext } from '../../../../contexts/TransferContext'
import { Autocomplete } from '@react-google-maps/api'
import { useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { SearchField } from './components/SearchField'
import {
  MinusCircle,
  PlusCircle,
  Home,
  MapPin,
  CalendarDays,
  Users2
} from 'lucide-react'

export const Transfer = () => {
  const {
    going,
    setGoing,
    setReturnTravel,
    returnTravel,
    // calculateRoute,
    isLoaded
  } = useTransferContext()

  const [isAirport, setIsAirport] = useState(false)
  const [goingDate, setGoingDate] = useState(() => {
    if (!going.dateTime) return undefined
    return new Date(
      `${going.dateTime
        .toLocaleDateString()
        .split('/')
        .reverse()
        .join('-')}T00:00:00`
    )
  })
  const [goingTime, setGoingTime] = useState(() => {
    if (!going.dateTime) return '00:00'
    return `${String(going.dateTime.getHours()).padStart(2, '0')}:${String(
      going.dateTime.getMinutes()
    ).padStart(2, '0')}`
  })

  const { t } = useTranslation()

  /**@type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef()

  /**@type React.MutableRefObject<HTMLInputElement> */
  const destinationRef = useRef()

  const navigate = useNavigate()

  if (!isLoaded) {
    return <div>...Loading</div>
  }

  const handleReserv = () => {
    if (
      originRef?.current?.value === '' ||
      destinationRef?.current?.value === '' ||
      going.from === '' ||
      going.to === '' ||
      going.allPassangers === 0 ||
      !goingDate
    ) {
      return toast.error(t('toast.fulfilAllInformations'))
    }

    const minDate = dayjs().add(8, 'hours')

    const fullDate = `${goingDate.toISOString().split('T')[0]}T${goingTime}:00`

    if (dayjs(fullDate).isBefore(dayjs())) {
      toast.error(t('toast.transferDateRangeError'))

      return
    }

    if (dayjs(fullDate).isBefore(minDate)) {
       toast.error(t('toast.transferHourRangeError'))

      return
    }

    setGoing({ ...going, dateTime: new Date(fullDate) })

    setReturnTravel({
      ...returnTravel,
      active: true,
      from: going.to,
      to: going.from,
      allPassangers: going.allPassangers
    })

    navigate('/checkout')
  }

  const handleAddStop = () => {
    setGoing({
      ...going,
      passengerStops: [...going.passengerStops, { from: '' }]
    })
  }

  const handleRemoveStop = () => {
    setGoing({ ...going, passengerStops: [] })
  }
  console.log(going, 'GOING', going.passengerStops)

  return (
    <Styled.TransferContainer>
      <Styled.LeftContainer>
        <Styled.ChoiceContainer>
          <Styled.OneTransferContainer>
            <SearchField
              icon={
                <Home
                  size="2.4rem"
                  color="#27c7ff"
                  style={{ alignSelf: 'flex-end', marginBottom: '3%' }}
                />
              }
              label={t('reserveComponent.Departure')}
              placeholder={t('reserveComponent.enterAMatch')}
              onChange={(value) => setGoing({ ...going, from: value })}
              initialValue={going.from || ''}
              setIsAirport={setIsAirport}
            />
            <SearchField
              icon={
                <MapPin
                  size="2.4rem"
                  color="#27c7ff"
                  style={{ alignSelf: 'flex-end', marginBottom: '3%' }}
                />
              }
              label={t('reserveComponent.Arrival')}
              placeholder={t('reserveComponent.enterAnArrival')}
              onChange={(value) => setGoing({ ...going, to: value })}
              initialValue={going.to || ''}
            />
          </Styled.OneTransferContainer>
          <Styled.OneTransferContainer>
            <Styled.OneTransfer>
              {/* <img src={datahoraIcon} alt="Ícone de calendário" />
              <DateTimePicker
                clearable
                label={t('reserveComponent.DateAndTime')}
                placeholder={t('reserveComponent.whenItWillStart')}
                value={going.dateTime}
                onChange={(e) => setGoing({ ...going, dateTime: e })}
                minDate={new Date()}
              /> */}
              <DateTimePicker
                setPassengers={setGoing}
                passengers={going}
                img={<CalendarDays size="2.4rem" color="#27c7ff" />}
                imgAlt={'Ícone de calendário'}
                date={goingDate}
                time={goingTime}
                setDate={setGoingDate}
                setTime={setGoingTime}
                isAirport={isAirport}
              />
            </Styled.OneTransfer>
            <Styled.OneTransfer>
              <Passengers
                setPassengers={setGoing}
                passengers={going}
                img={<Users2 size="2.4rem" color="#27c7ff" />}
                imgAlt={'Ícone de passageiros'}
              />
            </Styled.OneTransfer>
          </Styled.OneTransferContainer>
          <Styled.OneTransferContainer
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: going.passengerStops.length > 0 ? '2.4rem' : '0'
            }}
          >
            {going.passengerStops.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.8rem'
                }}
              >
                {going.passengerStops.map((_, idx) => (
                  <SearchField
                    key={idx}
                    icon={<MapPin size="2.4rem" color="#27c7ff" />}
                    label={t('reserveComponent.Stop')}
                    placeholder={t('reserveComponent.enterAMatch')}
                    onChange={(value) => {
                      const newStops = going.passengerStops.map((i) => i)
                      newStops[idx] = { from: value }
                      setGoing({ ...going, passengerStops: newStops })
                    }}
                    initialValue={going.passengerStops[idx]?.from || ''}
                  />
                ))}
              </div>
            )}
            <Styled.StopButton
              onClick={
                going.passengerStops.length === 0
                  ? handleAddStop
                  : handleRemoveStop
              }
            >
              {going.passengerStops.length === 0 ? (
                <PlusCircle />
              ) : (
                <MinusCircle />
              )}
              {going.passengerStops.length === 0
                ? t('addStop')
                : t('removeStop')}
            </Styled.StopButton>
          </Styled.OneTransferContainer>
        </Styled.ChoiceContainer>

        {/* <Styled.AddDestiny>
          <img src={PlusIcon} alt="Ícone de adicionar" />
          <span>Adicionar um destino</span>
        </Styled.AddDestiny> */}
        {/* <Checkbox
          size="lg"
          checked={returnTravel.active}
          // styles={{ label: { color: 'black' } }}
          color="orange-primary"
          onChange={() =>
            setReturnTravel({ ...returnTravel, active: !returnTravel.active })
          }
          label={t('reserveComponent.addReturn')}
        /> */}
      </Styled.LeftContainer>
      <Styled.BtnContainer>
        <Styled.BtnPrimary onClick={handleReserv}>
          {t('reserveComponent.reserve')}
        </Styled.BtnPrimary>
        {/* <Styled.BtnSecondary>Add Return</Styled.BtnSecondary> */}
      </Styled.BtnContainer>
    </Styled.TransferContainer>
  )
}
