import styled from 'styled-components'

export const ContainerPayment = styled.div`
  margin: 10rem 0;
`

export const Container = styled.div`
  width: 695px;
  height: 235.74px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 64px;
  gap: 48px;
  background: #fff;
  border: 1px solid rgba(39, 199, 255, 0.2);
  box-shadow: 0px 22px 24px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  position: relative;
  margin: 0 auto 200px;

  @media only screen and (max-width: 768px) {
    box-shadow: none;
    border: none;

    width: 96%;
    height: auto;
    padding: 0;
  }
`

export const Title = styled.h1`
  color: #0d0d0d;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  width: max-content;

  @media (max-width: 768px) {
    color: var(--black-brand-primary, #0d0d0d);
    text-align: center;
    font-family: Neometric;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 22rem;
  }
`

export const ImagesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  @media only screen and (max-width: 768px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`
