import styled, { css } from 'styled-components'

export const CardContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    gap: 7px;

    padding: 10.6484px 10.6484px 21.2967px;
    width: 220px;
    height: 200px;
    box-shadow: ${theme.shadows.minimal};
    border-radius: ${theme.border.radius.medium};

    div {
      display: flex;
      flex-direction: column;
      gap: 7.1px;
    }

    h2 {
      font-weight: 600;
      font-size: 21.6333px;
      line-height: 120%;
      letter-spacing: 0.01em;
      color: #0d0d0d;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
      color: ${theme.colors.gray};
    }

    img {
      max-width: 218.97px;
      margin: 0 auto;
    }

    @media (max-width: 1290px) {
      width: 25%;
    }

    @media (max-width: 768px) {
      width: 40%;
    }
    @media (max-width: 500px) {
      width: 80%;
    }
  `}
`

const containerModifiers = {
  dark: () => css`
    ${CardContainer} {
      background: linear-gradient(180deg, #333333 0%, rgba(51, 51, 51, 0) 100%);
      border: 0.820368px solid rgba(255, 255, 255, 0.69);
      filter: drop-shadow(0px 18.0481px 19.6888px rgba(0, 0, 0, 0.05));

      h2 {
        color: #ffffff;
        font-weight: 500;
      }

      p {
        color: #a7a7a7;
      }
    }
  `
}

export const Container = styled.div`
  ${({ dark }) => css`
    display: flex;

    > div {
      display: flex;
      align-items: center;
      gap: 37px;
    }

    &:last-child {
      gap: 0;
    }

    /* @media (max-width: 1100px) {
    flex-wrap: wrap;
    justify-content: center;
  } */
    @media (max-width: 1290px) {
      div {
        flex-wrap: wrap;
        justify-content: center;
      }
    }

    ${dark && containerModifiers.dark}
  `}
`
