import { useTranslation } from 'react-i18next'
import { Card, CardContent, CardHeader } from './styles'
import {
  LucideBadgePercent,
  LucidePhoneCall,
  LucideMap,
  LucideShieldCheck
} from 'lucide-react'

export const Schedule = () => {
  const { t } = useTranslation()
  return (
    <Card>
      <CardHeader>{t('events.whyBookWithUs')}</CardHeader>

      <CardContent>
        <div>
          <LucideBadgePercent />
          <span>{t('events.bestPriceGuaranteed')}</span>
        </div>

        <div>
          <LucidePhoneCall />
          <span>{t('events.customerSupport')}</span>
        </div>

        <div>
          <LucideMap />
          <span>{t('events.sightseeing')}</span>
        </div>

        <div>
          <LucideShieldCheck />
          <span>{t('events.safeTransfer')}</span>
        </div>
      </CardContent>
    </Card>
  )
}
