import styled from 'styled-components'
export const Container = styled.section`
  display: flex;
  gap: clamp(16px, 0.833vw, 0.833vw);
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  background-position: 100%;
  z-index: 0;
  position: relative;
  margin-top: 8rem;
  padding: 0 12vw;
  padding-top: 4vh;

  @media (max-width: 1160px) {
    padding: 0 clamp(16px, 0.833vw, 0.833vw);
    padding-top: 4vh;
    flex-direction: column-reverse;
  }
`

export const TextSectionContainer = styled.div`
  width: clamp(200px, 100%, 30vw);
  display: flex;
  flex-direction: column;
  gap: clamp(24px, 1.25vw, 1.25vw);

  @media (max-width: 1160px) {
    width: 100%;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: clamp(16px, 0.833vw, 0.833vw);
  z-index: 99;

  h1 {
    font-family: 'Neometric';
    font-style: normal;
    font-weight: 800;
    font-size: 4.8rem;

    .Typewriter {
      z-index: 1000;
      position: relative;
    }

    > span {
      position: relative;

      svg {
        position: absolute;
        width: 130%;
        height: 150%;
        left: -20%;
        top: 50%;
        transform: translateY(-50%);
        fill: #27c7ff;
        z-index: 5;
      }

      > span {
        z-index: 1000;
        position: relative;
      }
    }
  }
  p {
    font-size: clamp(16px, 0.833vw, 0.833vw);
    font-weight: 500;
  }

  @media (max-width: 1160px) {
    h1,
    p {
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    h1 {
      width: fit-content;
      align-self: center;
      text-align: left;
    }

    p {
      height: fit-content;
      width: fit-content;
      align-self: center;
    }
  }
`

export const TagsContainer = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  gap: clamp(8px, 0.833vw, 0.833vw);

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding: clamp(8px, 0.42vw, 0.42vw);
    gap: clamp(8px, 0.42vw, 0.42vw);
    box-shadow: 0 0 4px lightgray;
    background: white;
    border-radius: clamp(12px, 0.625vw, 0.625vw);

    div.icon-container {
      all: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.4rem;
      height: 2.4rem;

      svg {
        height: 100%;
        width: 100%;
      }
    }

    span {
      background: #27c7ff;
      width: 2px;
      height: 100%;
    }

    p {
      display: table-cell;
      vertical-align: middle;
      font: 400 1.1rem 'Roboto', sans-serif !important;
      padding: clamp(4px, 0.21vw, 0.21vw) 0;
      letter-spacing: 0.5px;
    }
  }

  @media (max-width: 1160px) {
    width: 67.297rem;
    margin: 0 auto;
    justify-content: flex-start;

    > div {
      flex: 1;
      justify-content: flex-start;

      p {
        flex: 1;
        word-wrap: break-word;
      }
    }
  }

  @media (max-width: 768px) {
    width: 90%;
    max-width: 61rem;
    flex-wrap: nowrap;
  }

  @media (max-width: 460px) {
    > div {
      .icon-container {
        width: 1.8rem !important;
        height: 1.8rem !important;
      }
    }
  }

  @media (max-width: 430px) {
    > div {
      padding: 0.6rem 0.4rem;
      border-radius: 0.4rem;
      gap: 0.4rem;
    }
  }

  @media (max-width: 370px) {
    > div p {
      font-size: clamp(0.8rem, 2.4vw, 1rem) !important;
    }
  }
`
