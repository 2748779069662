import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enTranslations from './locales/en.json'
import ptTranslations from './locales/pt.json'
import nlTranslations from './locales/nl.json'
import frTranslations from './locales/fr.json'
import deTranslations from './locales/de.json'

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslations },
    pt: { translation: ptTranslations },
    nl: { translation: nlTranslations },
    fr: { translation: frTranslations },
    de: { translation: deTranslations }
  },
  lng: 'pt', // Idioma padrão
  fallbackLng: 'pt', // Idioma de fallback caso a tradução não esteja disponível
  interpolation: { escapeValue: false }
})

export default i18n
