import * as Styled from './styles'
import { ReactComponent as DriverImage } from './imgs/DriverImage.svg'
import { ReactComponent as CloseIcon } from './imgs/CloseIcon.svg'

export const RejectModal = ({ opened, onClose, onFinish }) => (
  <>
    <Styled.ModalOverlay opened={opened} onClick={onClose} />
    <Styled.ModalContainer opened={opened}>
      <Styled.ModalHeader>
        <Styled.ModalTitle>Excluir</Styled.ModalTitle>
        <Styled.CloseButton>
          <CloseIcon onClick={onClose} />
        </Styled.CloseButton>
      </Styled.ModalHeader>
      <Styled.ModalContent>
        <DriverImage />
        <Styled.ModalText>
          Você tem certeza que deseja excluir este transfer?
        </Styled.ModalText>
      </Styled.ModalContent>
      <Styled.ModalButton onClick={onFinish}>Sim, confirmar</Styled.ModalButton>
    </Styled.ModalContainer>
  </>
)
