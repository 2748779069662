import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { BookingTypes } from '../../useFetchBooking'
import * as Styled from './styles'

const options = [
  {
    option: 'Disponíveis',
    value: BookingTypes.AvailablesToLowCost,
    first: true
  },
  {
    option: 'Ativos',
    value: BookingTypes.LowCost,
    last: true
  }
]

const _SwitchOptions = ({ onChange }, ref) => {
  const switchRef = useRef()

  useImperativeHandle(ref, () => ({
    next() {
      setValue(options[1])
      onChange(options[1])
    },

    previous() {
      setValue(options[0])
      onChange(options[0])
    }
  }))

  const [value, setValue] = useState(options[0])

  const handleChangeOption = (option) => {
    if (option) {
      setValue(option)
      onChange(option)
    }
  }

  return (
    <Styled.Container ref={switchRef}>
      <Styled.Options>
        {options.map((item, index) => (
          <Styled.Option
            onClick={() => handleChangeOption(item)}
            key={index}
            first={item.first}
            last={item.last}
            select={item.option === value.option}
          >
            {item.option}
          </Styled.Option>
        ))}
      </Styled.Options>
    </Styled.Container>
  )
}

export const SwitchOptions = forwardRef(_SwitchOptions)
