import * as Styled from './styles'
import { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Image, Text, Button, Group, Rating } from '@mantine/core'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import { toursList } from './toursList'
import prev from './imgs/prev_button.webp'
import next from './imgs/next_button.webp'

export const Tours = () => {
  const { t } = useTranslation()
  const carousel = useRef(null)
  const [activeIndex, setActiveIndex] = useState(0)

  console.log('ToursList: ', toursList)

  const items = toursList.map((tour, index) => (
    <Card
      shadow="sm"
      padding="lg"
      radius="md"
      withBorder
      data-value={index}
      key={index}
    >
      <Card.Section>
        <Image src={tour.image} height={201.25} alt="Norway" />
      </Card.Section>

      <Group position="apart" mt="md" mb="xs">
        <Text weight={500} className="title">
          {tour.title}
        </Text>
      </Group>

      <Group position="left" style={{ margin: '10px 0 10px -2px' }}>
        <Rating value={tour.rate} fractions={2} readOnly size="xl" />
        <span className="rate-value">{tour.rate}</span>
      </Group>

      <Styled.VerticalWrapper>
        <Styled.StrongSpan>{t('duration')}:</Styled.StrongSpan>
        <Styled.NormalSpan>{tour.duration}</Styled.NormalSpan>
      </Styled.VerticalWrapper>

      <Group>
        <Styled.Price>€ {tour.price}/tour</Styled.Price>
        <Button variant="light" color="blue" fullWidth mt="md" radius="md">
          {t('seeMore')}
        </Button>
      </Group>
    </Card>
  ))

  const handleSlideChanged = (e) => {
    setActiveIndex(e.item)
  }

  const slidePrev = (e) => {
    e.preventDefault()
    carousel.current.slidePrev()
  }

  const slideNext = (e) => {
    e.preventDefault()
    carousel.current.slideNext()
  }

  const responsive = {
    425: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 }
  }

  return (
    <Styled.Container>
      <Styled.ConfirmationMessage>
        {t('suggestions')}
      </Styled.ConfirmationMessage>
      <AliceCarousel
        mouseTracking
        disableDotsControls
        disableButtonsControls
        responsive={responsive}
        items={items}
        ref={carousel}
        activeIndex={activeIndex}
        onSlideChanged={handleSlideChanged}
      />
      <nav className="card-carousel-nav">
        {items.map((item, i) => (
          <span
            key={i}
            className={activeIndex === i ? 'active' : ''}
            onClick={() => carousel.current.slideTo(i)}
          />
        ))}
      </nav>
      <Styled.CarrouselBtnWrapper>
        <Styled.BtnPrev src={prev} onClick={slidePrev} />
        <Styled.BtnNext src={next} onClick={slideNext} />
      </Styled.CarrouselBtnWrapper>
    </Styled.Container>
  )
}
