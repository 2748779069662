import { useEffect, useRef } from 'react'

export default function useClickOutside(handler, linkedRef, linkedClassNames) {
  const domNode = useRef()

  useEffect(() => {
    const maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        if (
          !linkedClassNames ||
          (typeof event.target.className === 'string' &&
            !event.target.className.startsWith('ant-picker') &&
            linkedClassNames.every(
              (className) =>
                !event.target.className.includes(className) &&
                !event.target.parentElement.className.includes(className)
            ))
        ) {
          if (
            linkedRef &&
            linkedRef.current &&
            event.target !== linkedRef.current
          ) {
            handler()
          } else {
            !linkedRef && handler()
          }
        }
      }
    }

    document.addEventListener('mousedown', maybeHandler)

    return () => {
      document.removeEventListener('mousedown', maybeHandler)
    }
  }, [linkedRef, handler])

  return domNode
}
