import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { format, parseISO } from 'date-fns'

import fetchTour from '../tours'

import { Pagination, Rating } from '@mantine/core'
import { Carousel } from '@mantine/carousel'

import * as Styled from '../styles'

import { Navbar } from '../../../components/Navbar'
import BookingTour from './components/BookingTour'

import {
  CardDetails,
  ContainerRating,
  Detail,
  DetailTextDescription,
  DetailTextTitle,
  Details,
  HeaderSection,
  Hero,
  HeroParent,
  IconSection,
  Info,
  Mapa,
  NameTour,
  NavbarTur,
  OrderingReviews,
  Questions,
  ReviewCard,
  RightSidebar,
  Select,
  SubHeading,
  TitleSection,
  TitleTab,
  WhyBookinWithUs,
  StickyRightSidebar,
  StickyParent
} from './styles'

import {
  BadgePercent,
  CalendarDays,
  CheckCircle2,
  ChevronDown,
  Clock,
  FileText,
  FlagTriangleRight,
  Image,
  Mail,
  Map,
  MapPin,
  MessagesSquare,
  Phone,
  PhoneCall,
  PlaneLanding,
  PlaneTakeoff,
  Settings2,
  ShieldCheck,
  User2,
  Users2,
  XSquare
} from 'lucide-react'
import { useVisibleSection } from './useVisibleSection'
import { ScheduleNow } from '../../Events/components/EventCarousel/components/ScheduleNow'
import { LateralCards } from '../../Events/components/EventCarousel/components/LateralCards'
import Footer from '../../../components/Footer'
import { useTranslation } from 'react-i18next'

const menuItems = (t) => [
  {
    id: 'details-section',
    title: t('tours.details')
  },
  {
    id: 'itinerary-section',
    title: t('tours.itinerary')
  },
  {
    id: 'map-section',
    title: t('tours.map')
  },
  {
    id: 'photo-section',
    title: t('tours.photos')
  },
  {
    id: 'reviews-section',
    title: t('tours.reviews')
  }
]

export const TourLocal = () => {
  const { city } = useParams()
  const location = useLocation()
  const { state: tour } = location || {}
  const [state, setState] = useState()

  console.log(tour, 'tour')

  const language = localStorage.getItem('language')

  useEffect(() => {
    getTour()
  }, [language])

  const hasMap =
    !!state?.attributes?.mapEmbed &&
    !!state?.attributes?.mapEmbed.startsWith('<iframe')

  const [imagesData, setImagesData] = useState([])
  const [reviews, setReviews] = useState([])
  const [transparentNavbar, setTransparentNavbar] = useState(true)

  const [activePage, setPage] = useState(1)

  const { t } = useTranslation()
  const visibleSection = useVisibleSection(menuItems(t))

  const startDate = parseISO(state?.attributes?.availabilityStartAt)
  const endDate = parseISO(state?.attributes?.availabilityEndAt)

  let formattedDate = ''

  if (startDate && endDate) {
    try {
      formattedDate = `${format(startDate, 'dd/MM')} - ${format(
        endDate,
        'dd/MM'
      )}`
    } catch (error) {
      formattedDate = ''
    }
  }

  const extractCity = (location) => {
    // Dividir a string usando a vírgula como delimitador
    const parts = location?.split(',') ?? []

    // Iterar sobre as partes em ordem reversa e encontrar a primeira que representa a cidade
    for (let i = parts.length - 1; i >= 0; i--) {
      const city = parts[i].trim().split(' ')[1]
      if (city) {
        return city
      }
    }

    // Se não encontrar, retornar null ou uma string vazia, dependendo da necessidade
    return null
  }

  const arrivalCity = extractCity(state?.attributes.arrivalLocation)
  const departureCity = extractCity(state?.attributes.departureLocation)

  const includedIN = state?.attributes.includedIN?.split('\n') ?? []
  const includedINFormatted = includedIN.map((item) => item.slice(2))

  const includedNO = state?.attributes.includedNO?.split('\n') ?? []
  const includedNOFormatted = includedNO.map((item) => item.slice(2))

  const [itinerary, setItinerary] = useState(null)

  const getTour = () => {
    fetchTour.getTourPerId(tour?.id).then((res) => {
      const dataCorrectLocale =
        res?.data?.data?.attributes?.locale === language
          ? res?.data?.data
          : res?.data?.data?.attributes?.localizations?.data.filter(
              (item) => item?.attributes?.locale === language
            )[0]
          ? res?.data?.data?.attributes?.localizations?.data.filter(
              (item) => item?.attributes?.locale === language
            )[0]
          : undefined
      if (dataCorrectLocale) {
        const images = res?.data?.data?.attributes?.images
        const mainImage = res?.data?.data?.attributes?.mainImage

        dataCorrectLocale.attributes.images = images
        dataCorrectLocale.attributes.mainImage = mainImage
        setState(dataCorrectLocale)
      } else {
        setState(dataCorrectLocale)
      }
    })
  }
  const getItinerary = () => {
    fetchTour.getItinerary(language).then((res) => {
      setItinerary(res.data.data)
    })
  }

  const getReview = () => {
    fetchTour.getReview(activePage, language).then((res) => {
      setReviews(res.data.data)
    })
  }

  const getImages = (id) => {
    fetchTour.getImages(id).then((res) => {
      setImagesData(res.data.data)
    })
  }

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useEffect(() => {
    getItinerary()
    // getImages(state.id)

    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [language])

  const handleClickAnchor = (id) => {
    const element = document.getElementById(id)
    element.scrollIntoView({ behavior: 'smooth' })
  }

  const galery = state?.attributes?.images?.data

  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString)
    const formattedDate = format(date, 'dd/MM/yy, HH:mm')
    return formattedDate
  }

  useEffect(() => {
    getReview()
  }, [activePage, language])

  useEffect(() => {
    const handleScroll = () => {
      const { scrollY: top } = window
      const isTop = top < 400
      setTransparentNavbar(isTop)
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [transparentNavbar])

  return (
    <>
      <Navbar transparent={transparentNavbar} />
      <Styled.Container withoutOverflow>
        <HeroParent>
          <Hero image={state?.attributes?.mainImage?.data?.attributes?.url}>
            <Info />
          </Hero>
        </HeroParent>

        <StickyParent>
          <StickyRightSidebar>
            {!!state && (
              <>
                <ScheduleNow
                  isTour
                  fullEvent={state}
                  eventPrice={state?.attributes?.providerPrice}
                />
              </>
            )}

            <LateralCards />
          </StickyRightSidebar>
        </StickyParent>

        <Details>
          <NameTour>{state?.attributes.name}</NameTour>
          <ContainerRating>
            <Rating
              value={state?.attributes.rate}
              fractions={2}
              readOnly
              size="xl"
            />
            <p>{state?.attributes.rate}</p>
          </ContainerRating>
          <div className="container">
            <div className="container-cards">
              <CardDetails>
                <Clock color="#27c7ff" size="2rem" />
                <div>
                  <p className="title">{t('tours.durationTime')}</p>
                  <p className="desc">
                    {state?.attributes.durationInHours} {t('tours.hours')}
                  </p>
                </div>
              </CardDetails>
              <CardDetails>
                <CalendarDays color="#27c7ff" size="2rem" />
                <div>
                  <p className="title">{t('tours.availability')}</p>
                  <p className="desc">{formattedDate}</p>
                </div>
              </CardDetails>
            </div>
            {/* <div className="container-cards">
              <CardDetails>
                <PlaneTakeoff color="#27c7ff" size="2rem" />
                <div>
                  <p className="title">Saída:</p>
                  <p className="desc">{departureCity}</p>
                </div>
              </CardDetails>
              <CardDetails>
                <PlaneLanding color="#27c7ff" size="2rem" />
                <div>
                  <p className="title">Chegada:</p>
                  <p className="desc">{arrivalCity}</p>
                </div>
              </CardDetails>
            </div> */}
            <div className="container-cards">
              <CardDetails>
                <User2 color="#27c7ff" size="2rem" />
                <div>
                  <p className="title">{t('tours.minimumAge')}</p>
                  <p className="desc">{state?.attributes.minimumAge} anos</p>
                </div>
              </CardDetails>
              <CardDetails>
                <Users2 color="#27c7ff" size="2rem" />
                <div>
                  <p className="title">{t('tours.capacity')}</p>
                  <p className="desc">
                    {state?.attributes.maxNPassengers} {t('events.people')}
                  </p>
                </div>
              </CardDetails>
            </div>
          </div>
        </Details>
        {screenSize.width > 1024 && (
          <NavbarTur style={{ marginBottom: '2.4rem' }}>
            {menuItems(t).map(({ id, title }) => (
              <TitleTab
                onClick={() => handleClickAnchor(id)}
                key={id}
                active={visibleSection === id}
              >
                <a>{title}</a>
              </TitleTab>
            ))}
          </NavbarTur>
        )}
        <Styled.ContentWrapper
          hasPadding
          style={{ marginTop: '0', height: 'auto' }}
        >
          <RightSidebar>
            {/* <BookingTour price={state?.attributes?.providerPrice} />

            <WhyBookinWithUs>
              <p className="title">Porque agendar connosco?</p>

              <div className="item">
                <BadgePercent color="#27c7ff" size={22} />
                Melhor preço garantido
              </div>
              <div className="item">
                <PhoneCall color="#27c7ff" size={22} />
                Atendimento ao cliente 24h/7d
              </div>
              <div className="item">
                <Map color="#27c7ff" size={22} />
                Passeios turísticos
              </div>
              <div className="item">
                <ShieldCheck color="#27c7ff" size={22} />
                Transfer seguro
              </div>
            </WhyBookinWithUs>

            <Questions>
              <p className="title">Tens dúvidas?</p>
              <p className="desc">Ligue agora</p>

              <div className="item">
                <Phone color="#0D0D0D" size={22} />
                <a>+351 217 161 351</a>
              </div>
              <div className="item">
                <Mail color="#0D0D0D" size={22} />
                <a>geral@toptourportugal.com</a>
              </div>
            </Questions> */}

            {!!state && (
              <ScheduleNow
                isTour
                fullEvent={state}
                eventPrice={state?.attributes?.providerPrice}
              />
            )}

            <LateralCards />
          </RightSidebar>

          {screenSize.width <= 1024 && (
            <NavbarTur>
              {menuItems(t).map(({ id, title }) => (
                <TitleTab
                  onClick={() => handleClickAnchor(id)}
                  key={id}
                  active={visibleSection === id}
                >
                  <a>{title}</a>
                </TitleTab>
              ))}
            </NavbarTur>
          )}

          <section
            id="details-section"
            className="section"
            style={{
              maxWidth: '650px',
              borderBottom: '1px solid #BFBFBF',
              paddingBottom: '24px'
            }}
          >
            <HeaderSection>
              <IconSection>
                <FileText color="#27c7ff" />
              </IconSection>

              <TitleSection>{t('tours.tourDetails')}</TitleSection>
            </HeaderSection>

            <DetailTextDescription>
              <span>{state?.attributes?.detailTextDescription}</span>
            </DetailTextDescription>

            <Detail>
              <DetailTextTitle>{t('tours.departureLocation')}</DetailTextTitle>
              <DetailTextDescription>
                <span>{state?.attributes?.departureLocation}</span>
              </DetailTextDescription>
            </Detail>

            <Detail>
              <DetailTextTitle>{t('tours.departureTime')}</DetailTextTitle>
              <DetailTextDescription>
                <span>
                  This a custom-made tour so you may choose your starting time.
                </span>
              </DetailTextDescription>
            </Detail>

            <Detail>
              <DetailTextTitle>{t('tours.returnLocation')}</DetailTextTitle>
              <DetailTextDescription>
                <span>{state?.attributes?.arrivalLocation}</span>
              </DetailTextDescription>
            </Detail>

            <Detail>
              <DetailTextTitle>{t('tours.included')}</DetailTextTitle>
              <DetailTextDescription>
                {includedINFormatted.map((item, index) => (
                  <div key={index} className="includedIn">
                    <CheckCircle2 color="#48D9A4" size={20} />
                    <span
                      style={{
                        fontSize: '14px'
                      }}
                    >
                      {item}
                    </span>
                  </div>
                ))}
              </DetailTextDescription>
            </Detail>

            <Detail>
              <DetailTextTitle>{t('tours.notIncluded')}</DetailTextTitle>
              <DetailTextDescription>
                {includedNOFormatted.map((item, index) => (
                  <div
                    key={index}
                    className="includedIn"
                    style={{
                      display: 'flex',
                      maxWidth: '460px',
                      alignItems: 'center',
                      gap: '8px'
                    }}
                  >
                    <XSquare color="#F8294E" size={20} />
                    <span
                      style={{
                        fontSize: '14px'
                      }}
                    >
                      {item}
                    </span>
                  </div>
                ))}
              </DetailTextDescription>
            </Detail>
          </section>

          <section
            id="itinerary-section"
            className="section"
            style={{
              maxWidth: '650px',
              borderBottom: '1px solid #BFBFBF',
              paddingBottom: '24px'
            }}
          >
            <HeaderSection>
              <IconSection>
                <FlagTriangleRight color="#27c7ff" />
              </IconSection>

              <TitleSection>{t('tours.itinerary')}</TitleSection>
            </HeaderSection>

            {itinerary
              ?.sort(
                (a, b) =>
                  a?.attributes.displayOrder - b?.attributes.displayOrder
              )
              .map((item, index) => (
                <div key={index}>
                  <SubHeading>
                    <MapPin color="#27c7ff" />
                    <span>{item?.attributes?.name}</span>
                  </SubHeading>
                  <DetailTextDescription>
                    <span>{item?.attributes?.description}</span>
                  </DetailTextDescription>
                </div>
              ))}
          </section>

          <section
            id="map-section"
            className="section"
            style={{
              maxWidth: '650px',
              borderBottom: '1px solid #BFBFBF',
              paddingBottom: '24px'
            }}
          >
            <HeaderSection>
              <IconSection>
                <Map color="#27c7ff" />
              </IconSection>

              <TitleSection>{t('tours.map')}</TitleSection>
            </HeaderSection>

            {hasMap ? (
              <Mapa
                id="mapRouteTour"
                style={{
                  width: '100%',
                  height: '500px'
                }}
                dangerouslySetInnerHTML={{
                  __html: state?.attributes?.mapEmbed
                }}
                allowFullScreen
              />
            ) : (
              <Mapa
                id="mapRouteTour"
                style={{
                  width: '100%',
                  height: '500px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                allowFullScreen
              >
                {!hasMap && <strong>MAPA INDISPONÍVEL</strong>}
              </Mapa>
            )}
          </section>

          <section
            id="photo-section"
            className="section"
            style={{
              maxWidth: '650px',
              borderBottom: '1px solid #BFBFBF',
              paddingBottom: '24px'
            }}
          >
            <HeaderSection>
              <IconSection>
                <Image color="#27c7ff" />
              </IconSection>

              <TitleSection>{t('tours.photos')}</TitleSection>
            </HeaderSection>

            <Carousel mx="auto" withIndicators>
              {galery?.map((item, index) => (
                <Carousel.Slide key={index}>
                  <img
                    src={item?.attributes?.url}
                    alt={item?.attributes?.alternativeText}
                    style={{
                      width: '100%',
                      height: '500px',
                      borderRadius: '16px',
                      objectFit: 'cover'
                    }}
                  />
                </Carousel.Slide>
              ))}
            </Carousel>
          </section>

          <section
            id="reviews-section"
            className="section"
            style={{
              maxWidth: '650px',
              borderBottom: '1px solid #BFBFBF',
              paddingBottom: '24px'
            }}
          >
            <HeaderSection
              style={{ width: '100%', justifyContent: 'space-between' }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <IconSection>
                  <MessagesSquare color="#27c7ff" />
                </IconSection>

                <TitleSection>{t('tours.reviews')}</TitleSection>
              </div>

              {/* <OrderingReviews>
                <div className="icon-left">
                  <Settings2 color="#27c7ff" size={18} />
                </div>
                <Select style={{ appearance: 'none' }}>
                  <option value="Mais Recentes">Mais Recentes</option>
                </Select>
                <div className="icon-right">
                  <ChevronDown color="#27c7ff" size={20} />
                </div>
              </OrderingReviews> */}
            </HeaderSection>
            {reviews?.map((item, index) => (
              <ReviewCard key={index}>
                <div className="review-header">
                  <img
                    className="review-photo"
                    src={item?.attributes?.photoReview?.data?.attributes?.url}
                  />
                  <h3 className="review-name">{item?.attributes?.name}</h3>
                </div>
                <div className="review-description">
                  {/* <Rating
                    value={item?.attributes.rate}
                    fractions={2}
                    readOnly
                    size="xl"
                  /> */}
                  <p className="review-date">
                    {formatDate(item?.attributes?.createdAt)}
                  </p>
                  <p className="review-text">{item?.attributes?.content}</p>
                </div>
              </ReviewCard>
            ))}
            <Pagination
              value={activePage}
              onChange={setPage}
              total={10}
              position="center"
              styles={(theme) => ({
                control: {
                  fontSize: '14px',
                  width: '32px',
                  height: '32px',
                  '&[data-active]': {
                    background: '#27c7ff',
                    border: 0
                  }
                }
              })}
            />
          </section>
        </Styled.ContentWrapper>
      </Styled.Container>
      <Footer />
    </>
  )
}
