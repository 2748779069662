import React from 'react'
import { Select } from '@mantine/core'
import * as Styled from './styles'

export const SelectComponent = ({
  data,
  title,
  placeholder,
  icon,
  value,
  iconAlt,
  onChange,
  size,
  style
}) => {
  return (
    <Styled.Container style={style}>
      <img src={icon} alt={iconAlt} />
      <Select
        data={data}
        placeholder={placeholder}
        label={<h2>{title}</h2>}
        variant="unstyled"
        styles={{ rightSection: { display: 'none' } }}
        value={value}
        onChange={onChange}
        size={size}
      />
    </Styled.Container>
  )
}
