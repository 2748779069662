import * as Styled from './styles'

import { GoogleMap, Marker, DirectionsRenderer } from '@react-google-maps/api'
import { useTransferContext } from '../../../../contexts/TransferContext'
import { useTranslation } from 'react-i18next'

export const RouteCard = () => {
  const { directionsResponse, going, isLoaded } = useTransferContext()

  const { t } = useTranslation()

  const center = { lat: 38.9737, lng: -9.4178 }

  if (!isLoaded) {
    return <div>teste</div>
  }

  console.log({ directionsResponse })

  return (
    <Styled.CardWrapper>
      <h1>{t('form.maps')}</h1>
      <div>
        <span>{t('form.route')}:</span>
        <span>
          {going.distance} - {going.duration}
        </span>
      </div>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
          <GoogleMap
            center={directionsResponse ? directionsResponse.going : center}
            zoom={15}
            mapContainerStyle={{
              width: '100%',
              height: '100%',
              borderRadius: '16px'
            }}
            options={{
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
              scaleControl: false,
              rotateControl: false,
              scrollwheel: false,
              disableDoubleClickZoom: true,
              disableDefaultUI: true,
              keyboardShortcuts: false,
              mapTypeControlOptions: false,
              clickableIcons: false,
              draggableCursor: 'default',
              draggingCursor: 'default',
              controlSize: false,
              fullscreenControlOptions: false,
              streetViewControlOptions: false,
              gestureHandling: 'none'
            }}
          >
            <Marker position={directionsResponse ? '' : center} />
            {directionsResponse && (
              <DirectionsRenderer
                options={{
                  suppressInfoWindows: true
                }}
                directions={directionsResponse.going}
              />
            )}
          </GoogleMap>
        </div>
      </div>
    </Styled.CardWrapper>
  )
}
