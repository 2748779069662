import {
  Link,
  LucideArrowRight,
  LucideArrowUpRight,
  LucideMail
} from 'lucide-react'
import { Button, FormButton, Input, Wrapper } from './styles'

import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

import suitcaseImg from '../../imgs/banner-suitcase.svg'
import planeImg from '../../imgs/banner-plane.svg'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import fetchMarketing from '../../../../services/marketing'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const registerEmailSchema = z.object({
  email: z.string().email({ message: 'Insira um e-mail válido.' })
})

export const BottomBanner = () => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors }
  } = useForm({
    resolver: zodResolver(registerEmailSchema)
  })

  const navigate = useNavigate()
  const { t } = useTranslation()

  const registerEmail = async (data) => {
    try {
      await fetchMarketing.registrateNewEmail(data.email)
      toast.success(t('toast.successEmail'))
    } catch (e) {
      console.log('Save e-mail error: ', e)
      toast.error(t('toast.errorEmail'))
    }
    reset({ email: '' })
  }

  return (
    <Wrapper>
      <div>
        <h3>{t('tours.getReadyToStart')}</h3>

        <Button onClick={() => navigate('/#reserve')}>
          <span>{t('tours.startNow')}</span>
          <LucideArrowUpRight />
        </Button>
      </div>

      <div>
        <div>
          <img src={suitcaseImg} alt="" />
        </div>

        <div>
          <h2>{t('tours.subscribeExclusiveOffers')}</h2>

          <img src={suitcaseImg} />
          <img src={planeImg} />

          <form onSubmit={handleSubmit(registerEmail)}>
            <div>
              <div>
                <LucideMail />
                <Input
                  {...register('email')}
                  placeholder={t('tours.yourBestEmailHere')}
                />
              </div>
              {errors?.email && <p>{errors.email.message}</p>}
            </div>

            <FormButton disabled={isSubmitting} type="submit">
              <span>{t('tours.subscribe')}</span>
              <LucideArrowRight />
            </FormButton>
          </form>
        </div>

        <div>
          <img src={planeImg} alt="" className="" />
        </div>
      </div>
    </Wrapper>
  )
}
