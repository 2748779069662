import { useEffect, useState } from 'react'
import { NavbarDashboard } from '../../components/NavbarDashboard'
import { SidebarDashboard } from './components/SidebarDashboard'
import { Outlet, useNavigate } from 'react-router-dom'

import * as Styled from './styles'
import { useAuth } from '../../contexts/useAuth'
import { useClickOutside } from '@mantine/hooks'

export default function Dashboard({ activePage, setActivePage }) {
  const [cardVisible, setCardVisible] = useState(false)

  const { ROLE, signOut } = useAuth()
  const navigate = useNavigate()
  const ref = useClickOutside(() => setCardVisible(false))

  useEffect(() => {
    if (!ROLE) signOut()

    if (
      !!ROLE &&
      window.location.pathname === '/dashboard' &&
      ROLE === 'ADMIN'
    ) {
      navigate('/dashboard/home')
    } else if (
      ROLE === 'USER' ||
      ROLE === 'COMPANY_USER' ||
      ROLE === 'HOTEL_USER' ||
      ROLE === 'COMPANY_ADMIN'
    ) {
      navigate('/dashboard/transfer')
    }
  }, [ROLE])

  return (
    <div>
      <NavbarDashboard setCardVisible={setCardVisible} />
      <Styled.DashboardGridContainer>
        <div ref={ref}>
          <SidebarDashboard
            setActivePage={setActivePage}
            cardVisible={cardVisible}
            activePage={activePage}
          />
        </div>
        <div style={{ width: '100%', height: '100%', paddingTop: '8rem' }}>
          <Outlet />
        </div>
      </Styled.DashboardGridContainer>
    </div>
  )
}
