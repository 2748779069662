import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 23.1367px 17.3525px;
  gap: 32.61px;

  width: 100%;
  height: 100%;

  background: #ffffff;
  box-shadow: 0px -1.01914px 16.3063px rgba(0, 0, 0, 0.05),
    0px 22.4212px 24.4595px rgba(0, 0, 0, 0.07);
  border-radius: 24px;
`
export const ImageCar = styled.img`
  width: 118.22px;
  min-height: 74.98px;
  object-fit: cover;
`

export const Title = styled.h1`
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 120%;

  letter-spacing: 0.01em;

  color: #0d0d0d;
`

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8.67px;
`

export const Passenger = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    height: 24px;
  }

  p {
    background-color: rgba(226, 226, 226, 0.4);
    border: 0.509009px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 4.07207px 6.10811px rgba(0, 0, 0, 0.07);
    border-radius: 69px;
    width: 22px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding-top: 2px;
  }
`

export const Briefcase = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    height: 24px;
  }

  p {
    background-color: rgba(226, 226, 226, 0.4);
    border: 0.509009px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 4.07207px 6.10811px rgba(0, 0, 0, 0.07);
    border-radius: 69px;
    width: 22px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding-top: 2px;
  }
`

export const Wifi = styled.img`
  height: 24px;
`

export const CarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`

export const ChoiceButton = styled.button`
  font-weight: 700;
  font-size: 14.4604px;
  line-height: 120%;

  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #ffffff;

  background: #27c7ff;
  width: 108.45px;
  height: 51.71px;
  border-radius: 11.5683px;
  padding: 17.3525px 23.1367px;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #fff;
    color: #27c7ff;
    border: solid 1px #27c7ff;
  }
`

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6rem;

  h1 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 100%;
    margin-top: 20px;
    color: #0d0d0d;
  }

  p {
    font-weight: 500;
    font-size: 11.5683px;
    line-height: 14px;

    text-align: center;

    color: transparent;
  }
`
