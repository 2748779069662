import styled, { css } from 'styled-components'
export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
`

export const TransferContainer = styled.div`
  position: static;
  display: flex;
  justify-content: center;
  padding: 2rem 1.6rem;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 25.0092px 27.2828px rgba(0, 0, 0, 0.07);
  border-radius: 25.0092px;
  z-index: 15;
  width: 672.97px;
  height: 100%;
  /* height: 21rem; */

  .mantine-Popover-dropdown {
    width: 346px !important;
    height: 244.61px !important;

    background: #ffffff !important;
    box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.07) !important;
    border-radius: 0px 22px 22px 22px !important;
  }

  .mantine-DateTimePicker-month {
    width: 100%;
    text-align: center;
  }

  .mantine-DateTimePicker-timeWrapper {
    position: absolute;
    top: 1.5rem;
    right: 5rem;
  }

  .mantine-TimeInput-input {
    border: 0;
  }

  .mantine-CalendarHeader-calendarHeaderLevel {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    /* identical to box height, or 106% */

    color: #38280e;
  }

  .mantine-DateTimePicker-calendarHeaderControlIcon {
    width: 2rem;
    height: 2rem;
  }

  .mantine-TimeInput-input {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    /* identical to box height, or 106% */

    color: #38280e;
  }

  .mantine-WeekdaysRow-weekday {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 9.86317px;
    line-height: 12px;
    /* identical to box height */

    color: #38280e;
  }

  @media only screen and (max-width: 768px) {
    bottom: 1.4rem;
    width: 90%;
    border-radius: 1.6rem;
    box-shadow: 0px 22px 24px 0px rgba(0, 0, 0, 0.07);
  }

  @media only screen and (max-width: 634px) {
    padding: 1.6rem;
    flex-direction: column;
  }

  @media only screen and (max-width: 460px) {
    .mantine-Popover-dropdown {
      /* max-width: 90%; */
      /* margin-right: 2vw; */
      left: 0 !important;
      right: 0 !important;
      width: 100% !important;
      border-radius: 2rem !important;
    }
  }
`

export const OneTransfer = styled.div`
  display: flex;
  flex: 1;
  /* flex-wrap: wrap; */
  align-items: center;
  gap: 15px;
  width: 208.92px;
  height: 49.83px;
  position: relative;

  .AutoComplete {
    z-index: 9999;
  }

  .mantine-InputWrapper-root {
    height: 49.83px;
  }

  .mantine-Select-input,
  .mantine-Autocomplete-input,
  .mantine-Input-input {
    font-family: 'Neometric';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: normal;
    color: #808080;
    border: transparent;
    padding: 0;
  }

  .mantine-Select-label h2,
  .mantine-Autocomplete-label {
    width: 89px;
    height: 22px;
    font-family: 'Neometric';
    font-style: normal;
    font-weight: 600;
    font-size: 18.1885px;
    line-height: 22px;
    color: #0d0d0d;
  }

  .mantine-Select-dropdown {
    background: #ffffff;
    box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.07);
    border-radius: 0px 16px 16px 16px;
    border: 0;
  }

  .mantine-DateTimePicker-input {
    border: 0;
  }
  .mantine-DateTimePicker-input[data-with-icon] {
    padding: 0;
  }
  .mantine-DateTimePicker-icon {
    padding-right: 0;
    position: relative;
  }
  .mantine-DateTimePicker-label,
  .mantine-InputWrapper-label {
    font-family: 'Neometric';
    font-style: normal;
    font-weight: 600;
    font-size: 18.1885px;
    line-height: 22px;
    color: #0d0d0d;

    /* padding-left: 3rem; */
  }

  .mantine-DateTimePicker-input span,
  .mantine-DateTimePicker-input {
    font-family: 'Neometric';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: normal;
    /* identical to box height */

    color: #808080;
  }

  .mantine-DateTimePicker-wrapper,
  .mantine-Autocomplete-wrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .mantine-Popover-dropdown {
    background: #ffffff;
    box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.07);
    border-radius: 0px 22px 22px 22px;
    border: 0;
    display: flex;
    z-index: 999 !important;
  }

  .mantine-Input-input::placeholder {
    color: #8a8a8a;
  }

  .mantine-DateTimePicker-monthLevel {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
  .mantine-MonthLevelGroup-monthLevelGroup {
    margin: 2rem auto;
  }

  .mantine-InputWrapper-root {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1;
  }

  .mantine-InputWrapper-label {
    padding-left: 0;
  }

  .mantine-InputWrapper-label {
    color: #404040;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: normal;
  }

  @media only screen and (max-width: 768px) {
    flex: 1;
    width: 100%;
    min-width: 18rem;

    .mantine-InputWrapper-root {
      flex: 1;
    }
  }
`

export const OneTransferContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 0.8rem;
  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 515px) {
    flex-direction: column;
  }
`

export const BtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.7rem;
  margin-left: 3rem;

  @media only screen and (max-width: 634px) {
    flex: 1;
    width: 100%;
    margin: 0;

    button {
      height: 4.8rem;
      padding: 1.4rem;
      border-radius: 0.8rem;

      max-width: 30rem;
      margin: 2rem auto 0;
    }
  }
`

export const BtnPrimary = styled.button`
  font-family: 'Neometric';
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 25px;

  color: #ffffff;
  background: #27c7ff;
  box-shadow: 0px 4.54713px 9.09425px rgba(223, 247, 255, 0.5);
  border-radius: 1.6rem;
  padding: 30px 40px;
  width: 100%;

  @media only screen and (max-width: 634px) {
    font-size: 1.4rem;
    text-transform: uppercase;
    line-height: 1;
  }
`
export const BtnSecondary = styled.button`
  font-family: 'Neometric';
  font-style: normal;
  font-weight: 600;
  font-size: 20.4621px;
  line-height: 25px;
  color: #fcb13c;
  padding: 30px 30px;
  font-weight: 600;
  border: 0.1px solid rgba(56, 40, 14, 0.3);
  background: rgba(255, 255, 255, 0.4);
  border: 0.568391px solid rgba(56, 40, 14, 0.3);
  box-shadow: 0px 4.54713px 6.82069px rgba(0, 0, 0, 0.07);
  border-radius: 18.1885px;
`

export const OptionsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    z-index: 10;
    position: absolute;
    bottom: 8.8rem;
    align-items: center;

    .mantine-SegmentedControl-root {
      display: flex;
      border-radius: 3px 3px 0 0;
      z-index: 10;
      align-items: center;
      padding: 0;
    }

    .mantine-SegmentedControl-label {
      padding: 8px 16px;
      color: white;
      font-size: 12px;
    }

    .mantine-SegmentedControl-control {
      background-color: ${theme.colors.primary};
    }

    .mantine-SegmentedControl-label[data-active] {
      background-color: white;
      transition: ease-in-out 300ms;
      color: black;
    }
  `}
`

export const SelectedOption = styled.h2`
  padding: 0 1rem;
  color: white;
`

export const PrimaryOption = styled.h2`
  padding: 0 1rem;
  background-color: white;
  border-radius: 3px 0 0 0;
  height: 100%;
  display: flex;
  align-items: center;
`

export const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 18px;

  .mantine-Checkbox-label {
    color: var(--black-brand-primary, #0d0d0d);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 19.2px */
    letter-spacing: 0.16px;
  }

  @media only screen and (max-width: 768px) {
    flex: 1;
    width: 100%;
  }
`

export const ChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  width: 100%;

  @media only screen and (max-width: 515px) {
    gap: 0.8rem;
  }
`

export const AddDestiny = styled.p`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;

  font-family: 'Neometric';
  font-style: normal;
  font-weight: 400;
  font-size: 11.3678px;
  line-height: 14px;
  color: #0d0d0d;

  img {
    cursor: pointer;
  }
`

export const StopButton = styled.button`
  background: transparent;
  color: #27c7ff;
  font: 400 1.2rem Poppins, sans-serif;
  color: #222222;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.4rem;

  svg {
    fill: #27c7ff;
    color: #fff;
  }
`
