import styled, { css } from 'styled-components'

export const Container = styled.div`
  min-width: 567.88px;
  // height: 566px;
  box-shadow: 0px -1.01581px 16.253px rgba(0, 0, 0, 0.05),
    0px 22.3479px 24.3795px rgba(0, 0, 0, 0.07);
  border-radius: 24px;
  padding: 23.0611px 17.2958px;

  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  label {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    color: #0d0d0d;

    margin: 4px 0 16px 0;
  }

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 120%;

    letter-spacing: 0.01em;

    color: #0d0d0d;
  }

  .mantine-Input-input {
    display: flex;
    align-items: center;
    // padding: 12.1898px 8px;
    gap: 20px;

    width: 100%;
    height: 54px;

    border: 0.507906px solid #bfbfbf;
    border-radius: 6.09488px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    &:focus {
      outline: none;
      border: 1px solid #fcb13c;
    }
  }

  #validade {
    width: 306px;
    padding-left: 134px !important;
    // text-align: center;
  }

  #validade.mantine-1whp2mg[data-with-icon] {
    padding-left: 130px;
  }

  #cvc {
    width: 132px;
  }

  @media only screen and (max-width: 944px) {
    max-width: 720px;
    min-width: 0;
    width: 100%;
    padding: 1.6rem;

    h1 {
      font-size: 2rem;
    }

    .mantine-InputWrapper-label {
      font-size: 1.4rem;
      margin-bottom: 0.8rem;
    }
  }
`

export const FormRow = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  gap: 2rem;

  div {
    width: 100%;
  }

  &.payment-methods {
    gap: 1.6rem;
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 944px) {
    gap: 1.6rem;
  }

  @media only screen and (max-width: 500px) {
    &.payment-methods {
      gap: 0.8rem;
    }
  }
`

const paymentCardModifiers = {
  active: () => css`
    border-color: #27c7ff;

    p {
      color: #27c7ff;
    }
  `
}

export const PaymentCard = styled.div`
  ${({ active }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 0.8rem;

    padding: 0.8rem;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 0.6rem;
    max-width: 9.3rem;
    height: auto;

    cursor: pointer;

    svg {
      width: 2.4rem;
      height: 2.4rem;
    }

    p {
      font-size: 1.2rem;
      font-weight: 500;
      color: #222222;
      font-weight: 500;
    }

    ${active && paymentCardModifiers.active}
  `}
`

const reimbursementWrapperModifiers = {
  expandable: () => css`
    .grid-wrapper {
      margin-bottom: 2.4rem;
    }
  `
}

export const ReimbursementWrapper = styled.div`
  ${({ expandable }) => css`
    width: 100%;
    height: 592px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2.4rem;

    h4 {
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;

      color: #38280e;

      margin-top: 25px;
    }

    .grid-wrapper {
      width: 100%;
      position: relative;

      > button {
        position: absolute;
        bottom: -3.6rem;
        left: 50%;
        transform: translateX(-50%);

        color: #27c7ff;
        font-size: 1.4rem;
        font-weight: 600;
        text-decoration-line: underline;
        cursor: pointer;
        background-color: transparent;
      }
    }

    @media only screen and (max-width: 944px) {
      height: auto;

      h4 {
        font-size: 1.4rem;
        line-height: normal;
      }
    }

    ${expandable && reimbursementWrapperModifiers.expandable}
  `}
`

export const GreenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px;
  gap: 12px;
  position: relative;

  background: rgba(72, 217, 164, 0.3);
  border-radius: 0.8rem;
  width: 100%;
  height: 56px;
  /* margin-top: -20px; */

  .mantine-Checkbox-body {
    display: flex;
    align-items: center;
  }

  .mantine-Checkbox-input,
  .mantine-Checkbox-inner {
    width: 24px;
    height: 24px;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
  }

  // svg {
  //   bottom: 5px;
  //   right: 5px;
  // }

  .mantine-Checkbox-label {
    position: relative;
    bottom: 3px;
    right: 5px;
  }

  label {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    margin: 10px 0 0 10px;
  }

  .price {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }

  @media only screen and (max-width: 944px) {
    gap: 0.4rem;
    .mantine-Checkbox-label {
      font-size: 1.4rem;
      line-height: normal;
    }

    .price {
      font-size: 1.6rem;
      white-space: nowrap;
    }
  }

  @media only screen and (max-width: 420px) {
    .mantine-Checkbox-label {
      font-size: clamp(1.1rem, 3vw, 1.4rem);
    }

    .price {
      font-size: clamp(1.4rem, 3vw, 1.6rem);
    }
  }
`

export const GreenTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 6px;
  gap: 8px;

  width: fit-content;
  height: 22px;
  background: #48d9a4;
  border-radius: 4px;

  font-weight: 600;
  font-size: 1.2rem;
  line-height: 14px;
  color: #ffffff;

  position: absolute;
  top: -1.4rem;
  right: 1.4rem;
`

const reimbursementGridModifiers = {
  expandable: () => css`
    max-height: 38rem;
    overflow: hidden;
    position: relative;

    > button {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  `,
  expanded: () => css`
    max-height: none;
    overflow: visible;
  `
}

export const ReimbursementGrid = styled.div`
  ${({ expandable, expanded }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(8, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 10px;

    width: 94%;
    margin: 0 auto;
    position: relative;
    right: 10px;

    div {
      display: flex;
      align-items: center;
      gap: 20px;

      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #68645f;
    }

    span {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 17px;

      color: #68645f;
      width: 184px;
    }

    @media only screen and (max-width: 944px) {
      padding-top: 0.8rem;
      width: 100%;
      margin: 0 1.6rem;
      grid-row-gap: none;
      grid-template-rows: none;
      grid-template-columns: repeat(auto-fill, minmax(28.5rem, 1fr));
      gap: 1.6rem;

      div {
        height: 3.8rem;

        span {
          width: 100%;
        }
      }
    }

    @media only screen and (max-width: 420px) {
      grid-template-columns: 1fr;
    }

    ${expandable && reimbursementGridModifiers.expandable}
    ${expanded && reimbursementGridModifiers.expanded}
  `}
`
export const ReimbursementOrangeWrapper = styled.div`
  display: flex;
  padding: 1.6rem 0.8rem;
  gap: 1.2rem;

  width: 100%;
  // height: 100%;
  background: #DFF7FF;
  border-radius: 8px;

  margin-top: 28px;

  div {
    display: flex;
    flex-direction: column;
    gap: 10px;

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }
  }

  .mantine-Checkbox-input {
    width: 24px;
    height: 24px;
    border-radius: 8px;

    cursor: pointer;
  }

  // svg {
  //   bottom: 5px;
  //   right: 5px;
  // }

  @media only screen and (max-width: 944px) {
    .mantine-Checkbox-root {
      .mantine-Checkbox-inner {
        width: 20px;
        height: 20px;
      }
      .mantine-Checkbox-input {
        width: 20px;
        height: 20px;
        border-radius: 0.4rem;
        padding: 0;
      }
    }
    div {
      gap: 0.8rem;
      span {
        font-size: clamp(1.4rem, 2vw, 1.6rem);
        line-height: normal;
      }
    }
  }
`

export const CancelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  gap: 4px;

  width: 100%;
  height: 44px;
  margin: 0 auto;
  background: #f5f5f5;
  border-radius: 80px;
  margin-top: 10px;

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #404040;
  }

  a {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #404040;
    text-decoration-color: #9197a1;
  }

  @media only screen and (max-width: 944px) {
    border-radius: 0.8rem;
    justify-content: center;
    height: auto;
    padding: 1.2rem 2.4rem;
    span {
      /* width: 100%; */
      font-size: clamp(1.2rem, 3vw, 1.6rem);
      font-weight: 500;
      line-height: normal;
    }
    a {
      display: none;
    }
  }
`

export const PaymentButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17.2958px 23.0611px;
  gap: 17.3px;

  width: 100%;
  height: 53.59px;

  background: #27c7ff;
  border-radius: 11.5305px;
  margin: 280px auto 20px;

  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;

  &:not(:disabled):hover {
    background-color: #fff;
    color: #27c7ff;
    border: solid 1px #27c7ff;
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  @media only screen and (max-width: 944px) {
    margin-top: 4.8rem;
    font-size: 1.6rem;
  }
`

export const CardNumberWrapper = styled.div`
  border: 0.5px solid #bfbfbf;
  border-radius: 6px;
  padding: 12px 8px;
`

export const CardTax = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem;

  p {
    color: #808080;
    font-size: 1.4rem;
    font-weight: 400;
  }

  strong {
    color: #808080;
    font-size: 1.6rem;
    font-weight: 500;
  }

  @media only screen and (max-width: 420px) {
    p {
      font-size: 1.2rem;
    }

    strong {
      font-size: 1.4rem;
    }
  }
`
