import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  gap: 9.84px;

  max-width: 988px;
  width: 100%;
  margin-top: 40px;

  h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    color: #0d0d0d;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 40px;
    gap: 16px;

    width: 100%;
    height: 51px;
    background: #27c7ff;
    box-shadow: 0px 4px 8px rgba(223, 247, 255, 0.5);
    border-radius: 8px;
    margin-top: 20px;

    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
  }
`

export const CardsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20.07px;

  max-width: 988px;
  width: 100%;
  height: 293.05px;
  margin-top: 40px;
`

export const FormRow = styled.div`
  max-width: 309px;
  width: 100%;

  display: flex;
  justify-content: space-between;

  &:first-of-type {
    justify-content: flex-start;
    gap: 11.28px;
  }

  a {
    color: #a0a0a0;
  }
`

export const OrientationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5.78px;

  span {
    font-weight: 600;
    font-size: 20px;
    line-height: 120%;

    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #0d0d0d;
  }

  img {
    width: 10.47px;
    margin-right: 10px;
  }
`

export const HeaderWrapper = styled.div`
  width: 100%;
  align-self: center;
  margin: 0 0 10px 40px;

  display: flex;
  align-items: center;
  gap: 142px;

  div {
    display: flex;
    align-items: center;
    gap: 5.17px;

    cursor: pointer;

    span {
      font-weight: 400;
      font-size: 11.5683px;
      line-height: 14px;

      display: flex;
      align-items: center;

      color: #27c7ff;
    }

    img {
      width: 8.44px;
      height: 8.44px;
    }
  }
`

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px 41.8947px;
  gap: 13.96px;

  /* max-width: 485.28px; */
  width: 100%;
  height: 313.05px;

  border: 0.872807px solid #bfbfbf;
  border-radius: 26.1842px;
`

export const RoutesWrapper = styled.div`
  display: flex;
  gap: 11.28px;

  width: 330px;

  .orange-bar {
    height: 154.24px;
  }

  .routes {
    display: flex;
    flex-direction: column;
  }
`

export const RoutePriceSpan = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  white-space: nowrap;

  color: #0d0d0d;
`

export const BillWrapper = styled.div`
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  .title {
    font-weight: 500;
    font-size: 10.1223px;
    line-height: 120%;

    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    margin-top: 10px;

    color: #404040;
  }
`

export const BillRow = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
`

export const NormalBillSpan = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  text-align: center;

  color: #808080;
`

export const StrongSpan = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: #0d0d0d;
`

export const NormalSpan = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  color: #808080;
`

export const DateAndHour = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  text-align: center;

  color: #808080;
`

export const Divider = styled.div`
  height: 1px;
  width: 367.29px;
  background: rgba(56, 40, 14, 0.2);
  margin: 12px auto;
  margin-bottom: -4px;
`

export const RouteWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const RouteStrongSpan = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #0d0d0d;

  // &:nth-of-type(2) {
  //   width: 222px;
  // }
`

export const RouteNormalSpan = styled.span`
  font-weight: 400;
  font-size: 14.0956px;
  line-height: 17px;
  margin-top: 10px;

  color: #a0a0a0;
  width: 100%;
`

export const IconsAndNumbersWrapper = styled.div`
  width: 100%;
  margin-top: 5px;

  display: flex;
  align-items: center;
  padding: 16px 0;
  gap: 24px;
`

export const IconAndNumber = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    color: #a0a0a0;
  }
`

export const PriceSpan = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  text-align: center;

  color: #808080;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;

  margin-bottom: 20px;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 4px;

    margin-top: 5px;
  }

  h5 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    color: #27c7ff;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    text-align: center;

    color: #808080;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    text-align: center;

    color: #0d0d0d;
  }
`
