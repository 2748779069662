import React, { useState } from 'react'

import { DetailsTransfer } from './components/DetailsTransfer'
import { DetailsPayment } from './components/DetailsPayment'
import { DetailsPassenger } from './components/DetailsPassenger'
import { SendTransferModal } from './components/SendTransferModal'
import Status from './components/Status'
import { useParams, useNavigate } from 'react-router'
import { useEffect } from 'react'
import fetchBooking from '../../../../services/booking'
import { RejectModal } from './components/RejectModal'
import { StatusModal } from './components/StatusModal'
import { toast } from 'react-toastify'
import { DetailsDriver } from '../FinancialDetails/components/DetailsDriver'

import * as Styled from './styles'
import { Copy } from 'lucide-react'
import { useClipboard } from '@mantine/hooks'
import { useTranslation } from 'react-i18next'

export const TransferDetailsAdm = () => {
  const [sendTransferModal, setSendTransferModal] = useState()
  const [rejectModal, setRejectModal] = useState()
  const [statusModal, setStatusModal] = useState(false)
  const [next, setNext] = useState(false)
  const [transfer, setTransfer] = useState()
  const [data, setData] = useState()

  const { id } = useParams()
  const navigate = useNavigate()
  const clipboard = useClipboard({ timeout: 500 })

  const { t } = useTranslation()

  const handleCancelTransfer = () => {
    const payload = {
      reasonToCancel: 'ADM'
    }
    if (data.cancel_modal === 'cancel') {
      fetchBooking
        .cancelTransfer(id, payload)
        .then(() => {
          toast.success(t('toast.transferCancelSuccess'))
          setRejectModal(false)
          navigate('/dashboard/transfer')
        })
        .catch((err) => {
          console.log(err, 'err')
        })
    } else if (data.cancel_modal === 'cancel_delete') {
      fetchBooking
        .remove(id)
        .then(() => {
          toast.success(t('toast.successDeletedTransfer'))
          setRejectModal(false)
          navigate('/dashboard/transfer')
        })
        .catch((err) => {
          console.log(err, 'err')
        })
    }
  }

  useEffect(() => {
    fetchBooking
      .getBookingId(id)
      .then((res) => {
        setTransfer(res.data)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }, [id])

  return (
    <>
      <Styled.Container>
        <Styled.HeaderContainer>
          <Styled.Title>
            Transfers{' '}
            <Styled.TransferId>
              #
              {transfer?.id.length > 8
                ? transfer?.id.slice(0, 8) + '...'
                : transfer?.id}{' '}
              <Copy
                size="16"
                style={{ cursor: 'pointer' }}
                alt="Copy id"
                onClick={(e) => {
                  e.stopPropagation()
                  try {
                    clipboard.copy(transfer.id)
                    toast.success(t('toast.copiedID'))
                  } catch {
                    toast.error(t('errorCopiedID'))
                  }
                }}
              />
            </Styled.TransferId>
          </Styled.Title>
          <Styled.ButtonsWrapper>
            {!transfer?.lowCost && (
              <Styled.SecondaryButton>ALTERAR STATUS</Styled.SecondaryButton>
            )}

            <Styled.SecondaryButton onClick={() => setRejectModal(true)}>
              CANCELAR TRANSFER
            </Styled.SecondaryButton>
            <Styled.PrimaryButton onClick={() => setSendTransferModal(true)}>
              ENVIAR TRANSFER
            </Styled.PrimaryButton>
          </Styled.ButtonsWrapper>
        </Styled.HeaderContainer>
        {transfer?.status === 'PENDING' ? (
          <Status status="Pendente" />
        ) : (
          <Status status="Enviado para motorista" />
        )}
        <Styled.DetailsContainer>
          <DetailsTransfer transfer={transfer} />
          <DetailsPayment transfer={transfer} />
        </Styled.DetailsContainer>
        <Styled.DetailsContainer>
          <DetailsPassenger transfer={transfer} />
          <DetailsDriver transfer={transfer} />
        </Styled.DetailsContainer>
      </Styled.Container>
      <SendTransferModal
        booking={transfer}
        opened={sendTransferModal}
        onClose={() => setSendTransferModal(false)}
        onSuccess={() => setSendTransferModal(false)}
      />
      <RejectModal
        opened={rejectModal}
        onClose={() => {
          setRejectModal(false)
        }}
        setData={setData}
        data={data}
        onFinish={handleCancelTransfer}
      />
      <StatusModal
        opened={statusModal}
        onClose={() => {
          setStatusModal(false)
        }}
        setData={setData}
        data={data}
        onFinish={handleCancelTransfer}
      />
    </>
  )
}
