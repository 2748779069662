import { ActionIcon, Group, Input, NumberInput } from '@mantine/core'
import * as Styled from './styles'
import { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import Send from './imgs/send.webp'
import Xis from './imgs/xis.webp'
import MinusBtn from './imgs/minus-btn.webp'
import PlusBtn from './imgs/plus-btn.webp'
import BriefCase from './imgs/briefcase.webp'
import Luggage from './imgs/luggage.webp'
import Backpack from './imgs/backpack.webp'
// import MinusSmall from './imgs/minus-small.webp'
// import PlusSmall from './imgs/plus-small.webp'

import { AddPassenger } from './components/AddPassenger'
import { NavigationTabs } from '../../components/NavigationTabs'

export const Transfer3 = () => {
  const [value, setValue] = useState(0)
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [checkedLuggage, setCheckedLuggage] = useState(0)
  const [handLuggage, setHandLuggage] = useState(0)
  const handlers = useRef()
  const { t } = useTranslation()

  const firstPanel = (
    <>
      <Input.Wrapper
        id="input-demo"
        label={t('transfer.from')}
        style={{ marginTop: '20px' }}
      >
        <Input
          icon={<img src={Send} style={{ width: '22px' }} />}
          id="input-demo"
          placeholder="Queen Alia International Airport (AMM)"
          size={'lg'}
          value={from}
          onChange={(e) => setFrom(e.target.value)}
          rightSection={
            from && (
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => setFrom('')}
                width={22.55}
                height={22.55}
                src={Xis}
              />
            )
          }
        />
      </Input.Wrapper>

      <Input.Wrapper id="input-to" label={t('transfer.to')}>
        <Input
          icon={<img src={Send} style={{ width: '22px' }} />}
          id="input-demo"
          placeholder="Queen Alia International Airport (AMM)"
          size={'lg'}
          value={to}
          onChange={(e) => setTo(e.target.value)}
          rightSection={
            to && (
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => setTo('')}
                width={22.55}
                height={22.55}
                src={Xis}
              />
            )
          }
        />
      </Input.Wrapper>

      <Styled.NumberContainer>
        <label>{t('transfer.passengers')}</label>
        <Group
          spacing={5}
          style={{ justifyContent: 'space-between', position: 'relative' }}
        >
          <ActionIcon
            className="btn-minus"
            size={60}
            variant="transparent"
            style={{
              background: 'transparent',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 'none'
            }}
            onClick={() => handlers.current.decrement()}
          >
            <img src={MinusBtn} />
          </ActionIcon>

          <NumberInput
            hideControls
            value={value}
            onChange={(val) => setValue(val)}
            handlersRef={handlers}
            max={10}
            min={0}
            step={1}
            styles={{ input: { width: '100%', textAlign: 'center' } }}
            style={{ width: '100%' }}
          />

          <ActionIcon
            className="btn-plus"
            size={60}
            variant="transparent"
            style={{
              background: 'transparent',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 'none'
            }}
            onClick={() => handlers.current.increment()}
          >
            <img src={PlusBtn} />
          </ActionIcon>
        </Group>
      </Styled.NumberContainer>
      <Styled.OptionsContainer>
        <Styled.NumberContainer>
          <AddPassenger
            label={t('transfer.checkedLuggage')}
            setValue={setCheckedLuggage}
            value={checkedLuggage}
            icon={BriefCase}
          />
        </Styled.NumberContainer>
        <Styled.NumberContainer>
          <AddPassenger
            label={t('transfer.handLuggage')}
            setValue={setHandLuggage}
            value={handLuggage}
            icon={Luggage}
          />
        </Styled.NumberContainer>
        <Styled.NumberContainer>
          <AddPassenger
            label={t('transfer.backpack')}
            setValue={setHandLuggage}
            value={handLuggage}
            icon={Backpack}
          />
        </Styled.NumberContainer>
      </Styled.OptionsContainer>

      <Styled.ButtonContainer>
        <Styled.JumpButton>{t('transfer.jumpButton')}</Styled.JumpButton>
        <Styled.SaveButton>{t('transfer.continueButton')}</Styled.SaveButton>
      </Styled.ButtonContainer>
    </>
  )

  const secondPanel = (
    <>
      <Input.Wrapper
        id="input-demo"
        label={t('transfer.from')}
        style={{ marginTop: '20px' }}
      >
        <Input
          icon={<img src={Send} style={{ width: '22px' }} />}
          id="input-demo"
          placeholder="Queen Alia International Airport (AMM)"
          size={'lg'}
          value={from}
          onChange={(e) => setFrom(e.target.value)}
          rightSection={
            from && (
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => setFrom('')}
                width={22.55}
                height={22.55}
                src={Xis}
              />
            )
          }
        />
      </Input.Wrapper>

      <Input.Wrapper id="input-to" label={t('transfer.to')}>
        <Input
          icon={<img src={Send} style={{ width: '22px' }} />}
          id="input-demo"
          placeholder="Queen Alia International Airport (AMM)"
          size={'lg'}
          value={to}
          onChange={(e) => setTo(e.target.value)}
          rightSection={
            to && (
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => setTo('')}
                width={22.55}
                height={22.55}
                src={Xis}
              />
            )
          }
        />
      </Input.Wrapper>

      <Styled.NumberContainer>
        <label>{t('transfer.passengers')}</label>
        <Group
          spacing={5}
          style={{ justifyContent: 'space-between', position: 'relative' }}
        >
          <ActionIcon
            className="btn-minus"
            size={60}
            variant="transparent"
            style={{
              background: 'transparent',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 'none'
            }}
            onClick={() => handlers.current.decrement()}
          >
            <img src={MinusBtn} />
          </ActionIcon>

          <NumberInput
            hideControls
            value={value}
            onChange={(val) => setValue(val)}
            handlersRef={handlers}
            max={10}
            min={0}
            step={1}
            styles={{ input: { width: '100%', textAlign: 'center' } }}
            style={{ width: '100%' }}
          />

          <ActionIcon
            className="btn-plus"
            size={60}
            variant="transparent"
            style={{
              background: 'transparent',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              border: 'none'
            }}
            onClick={() => handlers.current.increment()}
          >
            <img src={PlusBtn} />
          </ActionIcon>
        </Group>
      </Styled.NumberContainer>
      <Styled.OptionsContainer>
        <Styled.NumberContainer>
          <AddPassenger
            label={t('transfer.checkedLuggage')}
            setValue={setCheckedLuggage}
            value={checkedLuggage}
            icon={BriefCase}
          />
        </Styled.NumberContainer>
        <Styled.NumberContainer>
          <AddPassenger
            label={t('transfer.handLuggage')}
            setValue={setHandLuggage}
            value={handLuggage}
            icon={Luggage}
          />
        </Styled.NumberContainer>
        <Styled.NumberContainer>
          <AddPassenger
            label={t('transfer.backpack')}
            setValue={setHandLuggage}
            value={handLuggage}
            icon={Backpack}
          />
        </Styled.NumberContainer>
      </Styled.OptionsContainer>

      <Styled.ButtonContainer>
        <Styled.JumpButton>{t('transfer.jumpButton')}</Styled.JumpButton>
        <Styled.SaveButton>{t('transfer.continueButton')}</Styled.SaveButton>
      </Styled.ButtonContainer>
    </>
  )

  return (
    <Styled.Container>
      <h1>{t('transfer.title')}</h1>
      <NavigationTabs firstPanel={firstPanel} secondPanel={secondPanel} />
    </Styled.Container>
  )
}
