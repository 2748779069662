import styled from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  width: 100%;

  @media (max-width: 485px) {
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }
`

export const OrderBumpLabel = styled.h1`
  font-family: Neometric;
  font-weight: 600;
  font-size: 32px;
  line-height: 38.4px;
`

export const RouteButtonContainer = styled.div`
  width: 220px;
  height: 48px;
`

export const NewRouteButton = styled.button`
  width: 220px;
  height: 48px;

  background-color: #27c7ff;
  border: 2px solid #27c7ff;
  border-radius: 16px;

  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 1%;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
`
