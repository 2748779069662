import { useEffect, useRef } from 'react'

export function useInvertScroll() {
  const ref = useRef(null)

  useEffect(() => {
    ref?.current?.addEventListener('wheel', function (event) {
      if (ref?.current) {
        // Inverte a direção do scroll multiplicando a quantidade padrão pelo fator -1
        ref.current.scrollTop += event.deltaY * -1

        // Impede a rolagem padrão
        event.preventDefault()

        // const hitEnd = ref.current.scrollTop / 2 === ref.current.clientHeight

        // // console.log({ event })

        // console.log('ref.current.scrollTop', ref.current.scrollTop)
        // console.log('event.target.clientHeight', ref.current.clientHeight)

        // if (hitEnd) {
        //   console.log('bateu')
        // }
      }
    })

    return () => {
      removeEventListener('wheel', () => {})
    }
  }, [ref?.current])

  return ref
}
