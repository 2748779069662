import styled, { css } from 'styled-components'

const containerModifiers = {
  active: () => css`
    visibility: visible;
    opacity: 1;
    pointer-events: all;
  `,

  inactive: () => css`
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  `
}

export const Container = styled.div`
  ${({ active }) => css`
    position: absolute;
    right: 13rem;
    top: 5rem;
    display: flex;
    width: 340px;
    padding: 16px;
    flex-direction: column;
    gap: 16px;
    border-radius: 22px;
    background: #fff;
    box-shadow: 0px 22px 24px 0px rgba(0, 0, 0, 0.07),
      0px -1px 16px 0px rgba(0, 0, 0, 0.05);

    ${active && containerModifiers.active}
    ${!active && containerModifiers.inactive}
  `}
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Title = styled.h1`
  color: var(--black-brand-primary, #222);
  font-family: Neometric;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
  letter-spacing: 0.24px;
`
export const Close = styled.span`
  width: 32px;
  height: 32px;
  background-color: #DFF7FF;
  color: #27c7ff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const ContentTitle = styled.h2`
  color: var(--black-brand-primary, #222);
  font-family: Neometric;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .mantine-Checkbox-label {
    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .mantine-Checkbox-inner input {
    border-radius: 6px;
  }

  .mantine-Input-icon {
    left: 30px;
  }
  .mantine-DatePickerInput-input {
    border-radius: 6.095px;
    border: 0.508px solid var(--grey-3-grayscale, #bfbfbf);
    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 12.19px 8px;
    text-align: center;
  }
`
export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const PrimaryButton = styled.button`
  display: flex;
  height: 56px;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 16px;
  background: var(--yellow-brand-primary, #27c7ff);
  color: var(--white-grayscale, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;
  text-transform: uppercase;
`

export const SecondaryButton = styled.button`
  display: flex;
  height: 56px;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 16px;
  background-color: transparent;
  border: 2px solid var(--yellow-3-support, #1D95BF);
  color: var(--yellow-3-support, #1D95BF);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;
  text-transform: uppercase;
`
