import styled, { css } from 'styled-components'
export const Container = styled.div`
  max-width: 100%;
  overflow-y: auto;
  td {
    padding: 1.75rem 0.625rem !important;
  }

  &::-webkit-scrollbar {
    height: 0.6rem !important;
    display: unset;
  }

  &::-webkit-scrollbar-track {
    background: #f7f7f7 !important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #27c7ff !important;
    border-radius: 1.6rem !important;
  }

  scrollbar-width: thin !important;
  scrollbar-color: #27c7ff #f7f7f7 !important;

  ${({ theme }) => css`
    .mantine-Table-root {
      th {
        color: var(--black-brand-primary, #222);
        font-family: Neometric;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 20.8px */
        letter-spacing: 0.16px;
      }
    }

    .mantine-Text-root {
      color: var(--black-brand-primary, #222);
      font-family: Neometric;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 20.8px */
      letter-spacing: 0.16px;
    }

    .selected {
      border-radius: 8px;
      border-bottom: 1px solid var(--grey-4-grayscale, #f7f7f7);
      background: var(--yellow-3-support, #E9F9FF);
    }
  `}
`

export const IconsAndNumbersWrapper = styled.div`
  width: 100%;
  margin-top: 5px;

  display: flex;
  align-items: center;
  padding: 16px 0;
  gap: 24px;
`

export const IconAndNumber = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    color: #a0a0a0;
  }
`
