import { Table, Checkbox } from '@mantine/core'
import * as Styled from './styles'
import { column } from './grid-props'
import { ItemRow } from './ItemRow'

export function DataTableLowCost({ data, selection, setSelection }) {
  const toggleRow = (id) => {
    setSelection((current) =>
      current.includes(id)
        ? current.filter((item) => item !== id)
        : [...current, id]
    )
  }

  const toggleAll = () => {
    setSelection((current) =>
      current?.length === data?.length ? [] : data?.map((item) => item.id)
    )
  }

  return (
    <Styled.Container>
      <Table verticalSpacing="sm" highlightOnHover>
        <thead>
          <tr>
            <th>
              <Checkbox
                onChange={toggleAll}
                checked={selection?.length === data?.length}
                indeterminate={
                  selection?.length > 0 && selection?.length !== data?.length
                }
                color="yellow"
              />
            </th>
            {column.map((item, index) => (
              <th key={index}>{item.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => (
            <ItemRow
              key={item.id}
              selected={selection?.includes(item.id)}
              item={item}
              onSelectRow={() => toggleRow(item.id)}
            />
          ))}
        </tbody>
      </Table>
    </Styled.Container>
  )
}
