import styled from 'styled-components'

export const HeroParent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: 600px;

  /* margin-top: 3rem; */
`

export const Hero = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-shrink: 0;

  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @media screen and (min-width: 1240px) and (max-width: 1439px) {
    align-items: flex-start;
    justify-content: flex-end;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 40px;
  gap: 8px;
  background-image: linear-gradient(to top, #fff 30%, transparent 110%);

  width: 100%;

  padding: 0px 0px 16px;
  padding-left: 9rem;

  /* border-radius: 16px; */

  @media screen and (max-width: 1024px) {
    padding: 0 30px 16px;
  }
`

export const NameTour = styled.h1`
  font-family: Neometric;
  font-weight: 800;
  font-size: 2.8rem;
  color: #222222;
  max-width: 60%;

  text-transform: capitalize;

  letter-spacing: 0.01em;
  line-height: 120%;

  margin: 0;

  @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;

    font-size: 24px;
  }

  @media screen and (max-width: 768px) {
    -webkit-line-clamp: 1 !important;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    line-clamp: 1;
  }
`

export const ContainerRating = styled.div`
  display: flex;
  align-items: center;

  p {
    font-family: 'Roboto', sans-serif !important;
    font-weight: 700;
    font-size: 24px;
    color: #0d0d0d;

    margin-left: 12px;
  }
`

export const Details = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.6rem;

  width: 100%;

  padding: 1.2rem 15.6rem;

  background-color: #ffffff;

  .container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5.4rem;

    width: 1160px;
  }

  .container-cards {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    /* justify-content: flex-start; */
    gap: 5.4rem;
  }

  @media screen and (max-width: 905px) {
    align-self: stretch;
    width: auto;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;

    .container {
      width: 100%;
      flex-flow: row wrap;

      gap: 2.4rem;
    }

    .container-cards {
      width: 100%;
      flex-direction: column;
      flex-flow: row wrap;
      justify-content: space-between;
      gap: 2.4rem;
    }
  }
  /* @media screen and (min-width: 1240px) and (max-width: 1439px) {
    align-self: stretch;
    width: auto;
    padding-left: 90px;
    padding-right: 90px;
    box-sizing: border-box;
  } */
`

export const CardDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;

  .title {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #404040;
    margin-bottom: 8px;
  }

  .desc {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: #404040;
  }

  @media screen and (max-width: 905px) {
    min-width: 150px;
  }
`

export const NavbarTur = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;

  height: 64px;

  padding: 8px 156px 8px 0;

  background-color: #E9F9FF;

  box-sizing: border-box;
  padding-left: 15.6rem;
  padding-right: 15.6rem;

  @media screen and (max-width: 905px) {
    padding-left: 0;
    padding-right: 0;
  }
  /* @media screen and (max-width: 600px) {
    padding-left: 16px;
    padding-right: 16px;
  } */
`

export const TitleTab = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-family: 'Neometric', sans-serif;
  font-weight: 600;
  font-size: 14px;

  height: 64px;

  padding: 8px 16px;

  box-sizing: border-box;

  cursor: pointer;

  &:hover {
    border-bottom: 2px solid #27c7ff;
  }

  a {
    color: ${({ active }) => (active ? '#000' : '#404040')};
  }

  @media screen and (max-width: 600px) {
    height: 48px;
    padding-left: 16px;
    padding-right: 16px;
  }
`

export const HeaderSection = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 24px;
`

export const IconSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  padding: 8px;

  border-radius: 50%;
  background-color: #DFF7FF;
`

export const TitleSection = styled.h1`
  font-family: Neometric;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: left;

  margin-left: 1rem;
`

export const DetailTextDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #808080;

  margin: 0;

  span {
    display: block;
    text-align: left;
  }

  .includedIn {
    display: flex;
    align-items: center;
    gap: 12px;

    width: 100%;
  }

  @media screen and (max-width: 1024px) {
    span {
      text-align: left;
    }

    .includedIn {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 12px;

      svg {
        width: 24px;
        height: 24px;
      }

      span {
        max-width: 240px;
      }
    }
  }
`

export const Detail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;

  margin: 24px 0;

  @media screen and (max-width: 1024px) {
    flex-direction: column;

    max-width: 1024px;
  }
`

export const DetailTextTitle = styled.p`
  font-family: Neometric;
  font-size: 16px;
  font-weight: 600;
  text-align: left;

  margin-bottom: 14px;
`

export const SubHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 600;

  padding: 8px 16px;

  margin: 24px 0;

  border-radius: 8px;
  background-color: #DFF7FF;

  span {
    color: #1D95BF;
    font: 400 1.4rem 'Poppins', sans-serif !important;
  }
`

export const Mapa = styled.div`
  border: none;
  align-self: stretch;
  position: relative;
  border-radius: 16px;
  height: 500px;
  overflow: hidden;
  flex-shrink: 0;
  background-image: url('/mapa@3x.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  iframe {
    width: 100%;
    height: 500px;
  }

  > strong {
    font: 600 1.6rem 'Poppins', sans-serif;
    color: #27c7ff;
  }
`

export const OrderingReviews = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 252px;
  height: 48px;

  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #a2a2a2;

  border: 0.5px solid #bfbfbf;
  background-color: #fff;

  box-sizing: border-box;

  padding: 12px 16px;

  border-radius: 6.09px;

  .icon-left {
    position: absolute;
    left: 16px;
    z-index: 99;

    display: flex;
    align-items: center;
  }
  .icon-right {
    position: absolute;
    right: 16px;
    z-index: 99;

    display: flex;
    align-items: center;
  }
`

export const Select = styled.select`
  display: flex;
  align-items: center;

  width: 100%;

  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #a2a2a2;
  text-align: center;

  padding: 0 16px;

  border: none;
  border-radius: 0.4rem;

  &:focus {
    outline: none;
    border: none;
  }

  &::placeholder {
    color: #27c7ff;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2rem;
  }
`

export const RightSidebar = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 4;

  width: 360px;

  margin: 0 !important;

  font-size: 18px;
  color: #252525;
  display: none;
  /* text-align: center; */

  @media screen and (max-width: 768px) {
    position: static;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;

    > div:first-of-type {
      width: 100%;
    }

    width: 100%;

    align-items: center;
    justify-content: center;
  }
`

export const BookingTour = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;

  width: 360px;

  margin: 0;
  padding: 24px;

  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0px -1.4px 22.55px rgba(0, 0, 0, 0.05),
    0px 31px 33.83px rgba(0, 0, 0, 0.07);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    p {
      font-family: Neometric;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }

    .discount {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      font-family: 'Poppins', sans-serif !important;
      font-weight: 700;
      font-size: 14px;
      color: #1D95BF;

      padding: 8px 16px;

      border-radius: 8px;
      background-color: #DFF7FF;
    }
  }

  .price {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: center;

    margin-top: 16px;

    small {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: 0.01em;
      text-align: center;
    }
  }

  .select-tour {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #a2a2a2;

    width: 100%;
    height: 48px;

    padding: 12px 8px;

    border-radius: 6px;
    border: 0.5px solid #bfbfbf;
    background-color: transparent;

    box-sizing: border-box;
  }

  .btn-schedule {
    width: 100%;
    height: 48px;

    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    text-align: center;

    border-radius: 16px;
    background-color: #27c7ff;
  }

  .info-people {
    display: flex;
    align-items: center;

    p {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      text-align: left;

      margin-left: 8px;

      span {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
      }
    }
  }
`

export const WhyBookinWithUs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;

  width: 360px;

  margin: 0;
  padding: 24px;

  border-radius: 24px;
  background-color: #fff;
  box-shadow: 0px -1.4px 22.55px rgba(0, 0, 0, 0.05),
    0px 31px 33.83px rgba(0, 0, 0, 0.07);

  .title {
    font-family: Neometric;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: center;

    width: 100%;

    margin-bottom: 24px;
  }

  .item {
    display: flex;
    align-items: center;
    gap: 8px;

    font-family: Neometric;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
  }
`

export const Questions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;

  width: 360px;

  margin: 0;
  padding: 24px;

  border-radius: 24px;
  background-color: #27c7ff;
  box-shadow: 0px -1.4px 22.55px rgba(0, 0, 0, 0.05),
    0px 31px 33.83px rgba(0, 0, 0, 0.07);

  .title {
    font-family: Neometric;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;

    color: #fff;
  }

  .desc {
    font-family: Neometric;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;

    color: #fff;
  }

  .item {
    display: flex;
    align-items: center;
    gap: 8px;

    a {
      font-family: Neometric;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      letter-spacing: 0.01em;
      text-align: left;

      color: #ffffff;
    }
  }
`

export const ReviewCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;

  margin-bottom: 24px;

  .review-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 25%;
  }

  .review-photo {
    width: 40px;
    height: 40px;

    border-radius: 50%;

    margin-bottom: 8px;
  }

  .review-name {
    font-family: Neometric;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.01em;
    text-align: center;

    width: 100%;
  }

  .review-description {
    width: 100%;
    max-width: 75%;
  }

  .review-date {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
    color: #27c7ff;

    margin-bottom: 8px;
  }

  .review-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
  }
`

export const StickyParent = styled.div`
  pointer-events: none;

  position: absolute;
  z-index: 100;
  top: 0;
  bottom: 0;
  right: 3.2rem;
  height: 100%;
  padding-bottom: 3.2rem;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const StickyRightSidebar = styled.div`
  position: sticky;
  top: 10.2rem;
  margin-top: 612px;
  pointer-events: all;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;

  width: 360px;

  font-size: 18px;
  color: #252525;
  /* text-align: center; */

  > div:first-of-type,
  > div:last-of-type,
  > div:last-of-type > div {
    width: 100%;
  }
`
