import * as S from './styles'
import OpenQuotes from './imgs/open-quote.svg'
import CloseQuotes from './imgs/close-quote.svg'
import AboutUsPhoto from './imgs/aboutUs.svg'
import { useTranslation } from 'react-i18next'
import {
  motion,
  useTransform,
  useViewportScroll,
  AnimatePresence
} from 'framer-motion'
import { useRef } from 'react'
import { useState } from 'react'

export default function AboutUs() {
  const { t } = useTranslation()
  const targetRef = useRef(null)
  const { scrollYProgress } = useViewportScroll()
  const [isMobile, setIsMobile] = useState(() => window.innerWidth < 700)

  const scale = useTransform(scrollYProgress, [0, 2], [0.7, 1.8])

  return (
    <S.Container id="about">
      <AnimatePresence>
        {isMobile ? (
          <motion.div>
            <h1>{t('aboutUs')}</h1>
          </motion.div>
        ) : (
          <motion.div
            initial={{ x: '100%' }}
            whileInView={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <h1>{t('aboutUs')}</h1>
          </motion.div>
        )}
      </AnimatePresence>

      <S.ContentWrapper>
        <S.TextWrapper>
          <div className="up">
            <img src={OpenQuotes} alt="Quotes" />
          </div>
          <p dangerouslySetInnerHTML={{ __html: t('completeAboutUs') }}></p>
          <div className="down">
            <img src={CloseQuotes} alt="Quotes" />
          </div>
        </S.TextWrapper>
        <S.ImagesWrapper>
          <img src={AboutUsPhoto} alt="About Us Photo" />
        </S.ImagesWrapper>
      </S.ContentWrapper>
    </S.Container>
  )
}
