import { LucideArrowUpRight } from 'lucide-react'
import { Link } from 'react-router-dom'
import { VehicleCard } from './components/VehicleCard'
import { vehicles } from './constants'

import * as Styled from './styles'
import { useTranslation } from 'react-i18next'

export const ExploreOurFleet = () => {
  const { t } = useTranslation()
  return (
    <Styled.Wrapper>
      <h2 dangerouslySetInnerHTML={{ __html: t('corporate.exploreFleet') }} />

      <div>
        {vehicles(t).map(({ name, imgSrc, seatsAmount }) => (
          <VehicleCard
            key={name}
            vehicleImgSrc={imgSrc}
            vehicleName={name}
            vehicleSeatsQtd={seatsAmount}
          />
        ))}
      </div>

      <Styled.Carousel
        opts={{
          align: 'start'
        }}
        watchDrag
      >
        <Styled.CarouselContent>
          {vehicles(t).map(({ name, imgSrc, seatsAmount }) => (
            <Styled.CarouselItem key={name}>
              <VehicleCard
                vehicleImgSrc={imgSrc}
                vehicleName={name}
                vehicleSeatsQtd={seatsAmount}
              />
            </Styled.CarouselItem>
          ))}
        </Styled.CarouselContent>
      </Styled.Carousel>

      <Styled.Button asChild>
        <Link to="/fleet">
          <span>{t('events.meetTheFleet')}</span>
          <LucideArrowUpRight />
        </Link>
      </Styled.Button>
    </Styled.Wrapper>
  )
}
