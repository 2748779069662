import { removeDuplicateIds } from './removeDuplicatedIds'
import fetchMessage from '../../../../../services/chat'
import { PAGE_SIZE } from '../context/ChatContext'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export let pollingTimeout

async function messages({
  userId,
  page,
  setData,
  setLoading,
  disablePolling = false,
  abortSignal
}) {
  if (setLoading) setLoading(true)


  try {
    const { data, status } = await fetchMessage.getMessages(
      {
        size: PAGE_SIZE,
        userId,
        page: page ?? 0
      },
      abortSignal
    )

    if (status === 200) {
      setData((state) => {
        return removeDuplicateIds([...state, ...data.content]).sort((a, b) => {
          if (new Date(a.createdAt) > new Date(b.createdAt)) return 1
          if (new Date(a.createdAt) < new Date(b.createdAt)) return -1

          return 0
        })
      })

      if (!disablePolling) {
        pollingTimeout = setTimeout(() => {
          messages({ userId, setData, abortSignal })
        }, 1500)
      }
    }
  } catch (error) {
    console.log(error)
  } finally {
    if (setLoading) setLoading(false)
  }
}

export let listOfChatsTimeout
async function listOfChats({ setData, setLoading, abortSignal }) {
  if (setLoading) setLoading(true)

  try {
    const { data, status } = await fetchMessage.getPrevious({}, abortSignal)

    if (status === 200) {
      setData([...data])

      listOfChatsTimeout = setTimeout(() => {
        listOfChats({ setData, abortSignal })
      }, 3000)
    }
  } catch (error) {
    // O if garante que o toast não vai ficar infinitamente aparecendo na tela
    if (setLoading) {
      toast.error("Ops! Ocorreu um erro ao tentar obter as conversas...")
    }
  } finally {
    if (setLoading) setLoading(false)
  }
}

export const Polling = {
  messages,
  listOfChats
}
