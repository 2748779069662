import { useEffect, useRef } from 'react'
import * as Styled from './styles'

import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Input, Pagination } from '@mantine/core'
import { ChevronDown, Copy, Search } from 'lucide-react'
import debounce from '../../../../utils/debounce'
import { SwitchOptions } from './components/SwitchOptions'
import { FilterPopup } from './components/FilterPopup'
import { Counters } from './components/Counters'
import { DataTableFinancialDriver } from '../../../../components/DataTableFinancialDriver'
import useClickOutside from '../../../../hooks/useClickOutside'
import { GeneratePaymentModal } from './components/GeneratePaymentModal'
import { DriverHistoric } from './components/DriverHistoric'
import fetchBooking from '../../../../services/booking'
import fetchAccount from '../../../../services/account'
import fetchTransfer from '../../../../services/transfer'
import { useAuth } from '../../../../contexts/useAuth'
import { PartnerCounters } from './components/PartnerCounters'
import { useClipboard } from '@mantine/hooks'
import { toast } from 'react-toastify'
import { useMediaQuery } from '../../../../hooks/useMediaQuery'
import { useTranslation } from 'react-i18next'

const financialValues = [
  {
    option: 'Transfers',
    first: true,
    type: 'transfers'
  },
  {
    option: 'Pagamentos',
    type: 'payments',
    last: true
  }
]

const DEFAULT_COUNTERS_INITIAL_STATE = {
  total: 0,
  netTotal: 0,
  totalBalance: 0,
  doneBookings: 0
}

export const FinancialDriverPayment = () => {
  const params = useParams()
  const { user, ROLE } = useAuth()

  const [driver, setDriver] = useState(
    ['USER', 'HOTEL_USER', 'COMPANY_USER', 'COMPANY_ADMIN'].includes(ROLE)
      ? user
      : undefined
  )
  const [data, setData] = useState()
  const [total, setTotal] = useState()
  const [search, setSearch] = useState({
    status: 'DONE',
    hasTransfer: false,
    size: 8,
    page: 0,
    direction: 'asc',
    ...(ROLE !== 'HOTEL_USER' &&
      ROLE !== 'COMPANY_ADMIN' && { driverId: params.id }),
    ...(ROLE === 'HOTEL_USER' && {
      hostelId: user.hostel ? user.hostel.id : user.id
    }),
    ...(ROLE === 'COMPANY_ADMIN' && {
      companyId: user.company ? user.company.id : user.id
    })
  })
  const [deleteModal, setDeleteModal] = useState(false)
  const [filter, setFilter] = useState(undefined)
  const [selection, setSelection] = useState([])
  const [option, setOption] = useState(financialValues[0])
  const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false)
  const [isGeneratePaymentModalOpen, setIsGeneratePaymentModalOpen] =
    useState(false)
  const [counters, setCounters] = useState(DEFAULT_COUNTERS_INITIAL_STATE)

  const filterButtonRef = useRef(null)

  const clipboard = useClipboard({ timeout: 500 })

  const navigate = useNavigate()

  const domNode = useClickOutside(
    () => setIsFilterPopupOpen(false),
    filterButtonRef,
    []
  )

  const handleChange = (value, name) => {
    if (name === 'size' || name === 'page') {
      setSearch((prevSearch) => ({ ...prevSearch, [name]: value }))
    } else {
      debounce(() => setSearch({ ...search, [name]: value }), 500)
    }
  }

  const totalCredit = counters.totalBalance ? counters.totalBalance : 0

  const getDriver = async () => {
    const response = await fetchAccount.getById(params.id)
    setDriver(response.data)
  }

  const { t } = useTranslation()

  const getCounters = async () => {
    try {
      const response = await fetchTransfer.getCounters({
        ...(ROLE === 'HOTEL_USER' && user.hostel
          ? {
              hostelId: user.hostel.id,
              createdAtStartAt: filter?.from
                ? filter.from
                    .toLocaleDateString()
                    .split('/')
                    .reverse()
                    .join('-') + 'T00:00:00Z'
                : undefined,
              createdAtEndAt: filter?.to
                ? filter.to
                    .toLocaleDateString()
                    .split('/')
                    .reverse()
                    .join('-') + 'T23:59:59Z'
                : undefined
            }
          : ROLE === 'COMPANY_ADMIN' && user.company
          ? {
              companyId: user.company.id,

              createdAtStartAt: filter?.from
                ? filter.from
                    .toLocaleDateString()
                    .split('/')
                    .reverse()
                    .join('-') + 'T00:00:00Z'
                : undefined,
              createdAtEndAt: filter?.to
                ? filter.to
                    .toLocaleDateString()
                    .split('/')
                    .reverse()
                    .join('-') + 'T23:59:59Z'
                : undefined
            }
          : {
              userId: params.id,
              createdAtStartAt: filter?.from
                ? filter.from
                    .toLocaleDateString()
                    .split('/')
                    .reverse()
                    .join('-') + 'T00:00:00Z'
                : undefined,
              createdAtEndAt: filter?.to
                ? filter.to
                    .toLocaleDateString()
                    .split('/')
                    .reverse()
                    .join('-') + 'T23:59:59Z'
                : undefined
            })
      })
      if (!response.data) return
      console.log({ countersData: response.data })
      setCounters({
        total: response.data.grossTotal / 100,
        netTotal: response.data.netTotal / 100,
        totalBalance: response.data.totalBalanceAmount / 100,
        doneBookings: response.data.doneBookings ?? 0
      })
    } catch (err) {
      console.error(err)
      setCounters(DEFAULT_COUNTERS_INITIAL_STATE)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      !driver && (await getDriver())
      await getCounters()
      try {
        const requestParams = Object.entries(search).reduce(
          (acc, [key, value]) => {
            if (value !== undefined && value !== null && value !== '') {
              acc[key] = value
            }
            return acc
          },
          {}
        )

        const result = await fetchBooking.getAllBooking({
          ...requestParams,
          transferDateStartAt: filter?.from
            ? filter.from.toLocaleDateString().split('/').reverse().join('-') +
              'T00:00:00'
            : undefined,
          transferDateEndAt: filter?.to
            ? filter.to.toLocaleDateString().split('/').reverse().join('-') +
              'T23:59:59'
            : undefined
        })

        if (result) {
          setData(result.data?.content ?? [])

          const totalPages = Math.ceil(result.data.totalElements / search.size)
          setTotal(totalPages)
        }
      } catch (err) {
        console.error(err, 'err')
        // navigate('/dashboard/home')
      }
    }

    option.type === 'transfers' && params.id && fetchData()
  }, [search, option, filter, params.id, navigate, driver, ROLE])

  return (
    <>
      <Styled.Container>
        {driver && (
          <Styled.Header>
            <div style={{ display: 'flex', gap: '6px' }}>
              {ROLE === 'HOTEL_USER' || ROLE === 'COMPANY_ADMIN' ? (
                <Styled.Title>Ganhos</Styled.Title>
              ) : (
                <Styled.TitlesWrapper>
                  <Styled.Title>{driver?.name}</Styled.Title>
                  <Styled.SubTitle>
                    #
                    {driver.id.length > 8
                      ? driver.id.slice(0, 8) + '...'
                      : driver.id}
                    <Copy
                      size="16"
                      style={{ cursor: 'pointer' }}
                      alt="Copy id"
                      onClick={(e) => {
                        e.stopPropagation()
                        try {
                          clipboard.copy(driver.id)
                          toast.success(t('toast.copiedID'))
                        } catch {
                          toast.error(t('errorCopiedID'))
                        }
                      }}
                    />
                  </Styled.SubTitle>
                </Styled.TitlesWrapper>
              )}
            </div>
            {option.type === 'transfers' && (
              <Styled.ConfirmButton
                disabled={selection.length === 0}
                onClick={() => setIsGeneratePaymentModalOpen(true)}
              >
                GERAR PAGAMENTO
              </Styled.ConfirmButton>
            )}
          </Styled.Header>
        )}

        {ROLE === 'HOTEL_USER' || ROLE === 'COMPANY_ADMIN' ? (
          <PartnerCounters {...counters} />
        ) : (
          <Counters {...counters} />
        )}

        <Styled.FiltersWrapper>
          <SwitchOptions
            options={financialValues}
            setValue={setOption}
            value={option}
            disabled={!driver}
          />
          {option.type === 'transfers' && (
            <Styled.FiltersInputWrapper>
              <Input
                id="select-filter"
                className={filter ? 'active-filter' : undefined}
                component="button"
                ref={filterButtonRef}
                onClick={() => setIsFilterPopupOpen((oldState) => !oldState)}
                rightSection={
                  <ChevronDown
                    color={filter ? '#ffffff' : '#27c7ff'}
                    style={{
                      transform: isFilterPopupOpen
                        ? 'rotate(180deg)'
                        : 'rotate(0deg)',
                      transition: 'transform 0.2s ease-in-out'
                    }}
                  />
                }
                pointer
                mt="md"
              >
                Filtro
              </Input>
              {isFilterPopupOpen && (
                <FilterPopup
                  ref={domNode}
                  onClose={() => setIsFilterPopupOpen(false)}
                  initialFilter={filter}
                  clearFilter={() => {
                    setFilter(undefined)
                    setIsFilterPopupOpen(false)
                  }}
                  onSuccess={(filter) => {
                    setFilter(filter)
                    setIsFilterPopupOpen(false)
                  }}
                />
              )}
              <Input.Wrapper id="filters">
                <Input
                  id="input-demo"
                  placeholder="Pesquisar por id"
                  size={'lg'}
                  rightSection={
                    <Search width={24} height={24} color="#BFBFBF" />
                  }
                  onChange={(e) => handleChange(e.target.value, 'id')}
                />
              </Input.Wrapper>
            </Styled.FiltersInputWrapper>
          )}
        </Styled.FiltersWrapper>
        <Styled.Content>
          {option.type === 'transfers' ? (
            <>
              <DataTableFinancialDriver
                data={data}
                setDelete={setDeleteModal}
                selection={selection}
                setSelection={setSelection}
                type={option.type}
                isPartnershipFinancial={
                  ROLE === 'HOTEL_USER' || ROLE === 'COMPANY_ADMIN'
                }
              />
              <Pagination
                className="pagination"
                size="xl"
                total={total}
                radius="md"
                color="orange"
                withControls={false}
                value={search?.page + 1}
                onChange={(value) => handleChange(value - 1, 'page')}
              />
            </>
          ) : (
            <DriverHistoric driver={driver} updateDriver={getCounters} />
          )}
        </Styled.Content>
      </Styled.Container>
      {isGeneratePaymentModalOpen && (
        <GeneratePaymentModal
          onSuccess={() => {
            const updatedData = data.map((i) => i)
            const nonSelectedData = updatedData.filter(
              (i) => !selection.includes(i.id)
            )
            setData(nonSelectedData)
            getCounters()
            setIsGeneratePaymentModalOpen(false)
            setSelection([])
            setOption({
              option: 'Pagamentos',
              type: 'payments',
              last: true
            })
          }}
          driver={driver}
          totalCredit={totalCredit}
          selectedTransfers={data.filter((transfer) =>
            selection.includes(transfer.id)
          )}
          onClose={() => {
            setIsGeneratePaymentModalOpen(false)
          }}
        />
      )}
    </>
  )
}
