import styled from 'styled-components'
import * as FaqStyles from './components/Faq/styles'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-width: 90rem; */
  width: 100%;
  /* margin: 0 auto; */
`

export const TitleContainer = styled.div``

export const Title = styled.h1`
  font-family: 'Neometric';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48.01px;
  color: #222222;

  margin: 6rem 2rem;
  padding-left: 156px;
  padding-bottom: 40px;
  padding-top: 50px;

  width: 100%;

  background: linear-gradient(
    95.8deg,
    #27c7ff -23.21%,
    rgba(255, 255, 255, 0) 75.84%
  );

  @media (max-width: 768px) {
    /* padding: 24px 16px; */
    /* margin: 24px 16px; */
    /* padding: 16px; */
    padding: 0;
    /* text-align: left; */

    /* height: 128px; */
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 16px;
    /* margin: 2rem 1rem; */

    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
  }
`

export const SubTitle = styled.h2`
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.01em;
  text-align: left;

  /* margin: 0.5rem 0; */
  margin: 0;

  color: #222222;

  @media (max-width: 768px) {
    padding: 24px 16px 8px;
  }
`

export const Information = styled.h3`
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #222222;

  margin: 0.5rem 0;

  @media (max-width: 768px) {
    /* margin: 0; */
    padding-left: 16px;
    padding-right: 16px;
  }
`

export const Description = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-indent: 32px;
  margin: 2rem 156px;

  color: #222222;

  @media (max-width: 768px) {
    margin: 24px 16px;
  }
`

export const Container = styled.div`
  /* padding: 0 2rem; */
  margin-top: 2rem;

  @media only screen and (max-width: 768px) {
    ${Title} {
      text-align: left;
      font-size: 32px;
      line-height: 38.41px;
      margin-bottom: 0px;
    }

    ${SubTitle},
    ${Information} {
      width: 100%;
      font-size: 1.4rem;
      line-height: normal;
      /* margin-bottom: 1.6rem; */
      text-align: left;
    }

    ${Information} {
      margin-top: 0;
    }

    ${Description} {
      font-size: 1.4rem;
    }

    ${Description}:first-of-type {
      /* margin-top: 3.6rem; */
      margin-top: 16px;
      /* margin-bottom: 30px; */
    }

    ${Description}:last-of-type {
      margin-top: 1.6rem;
      margin-bottom: 0;
    }

    ${FaqStyles.Container} {
      margin-top: 1.6rem;
      /* margin-bottom: 12rem; */
    }
  }
`
