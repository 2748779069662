import { useState } from 'react'
import { Tabs } from '@mantine/core'
import * as Styled from './styles'
import { useTranslation } from 'react-i18next'

export const NavigationTabs = ({
  firstPanel,
  secondPanel,
  onTabChange = undefined,
  secondPanelActive = false
}) => {
  const [activeTab, setActiveTab] = useState('first')
  const [showWarn, setShowWarn] = useState(true)
  const { t } = useTranslation()

  const handleChangeTab = (value) => {
    if (value === 'second' && showWarn) setShowWarn(false)

    setActiveTab(value)

    onTabChange && onTabChange(value)
  }

  return (
    <Styled.Container>
      <Tabs
        variant="pills"
        radius="md"
        value={activeTab}
        onTabChange={handleChangeTab}
      >
        <Tabs.List>
          <Tabs.Tab value="first">{t('navigationTabs.departure')}</Tabs.Tab>
          {secondPanelActive && (
            <Tabs.Tab value="second">
              {t('navigationTabs.return')}
              {showWarn && <span className="warn">1</span>}
            </Tabs.Tab>
          )}
        </Tabs.List>
        <Tabs.Panel value="first">{firstPanel}</Tabs.Panel>
        <Tabs.Panel value="second">{secondPanel}</Tabs.Panel>
      </Tabs>
    </Styled.Container>
  )
}
