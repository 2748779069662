import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 48px;
  /* margin-bottom: 210px; */

  @media only screen and (max-width: 768px) {
    padding: 0 2rem;
  }
`

export const Title = styled.h1`
  color: var(--black-brand-primary, #222);
  font-family: Neometric;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media (max-width: 768px) {
    color: var(--black-brand-primary, #0d0d0d);
    text-align: center;
    font-family: Neometric;
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    /* max-width: 22rem; */
  }
`

export const ImagesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5rem;
  align-items: center;
`
