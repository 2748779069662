import googleImage from './mock/imgs/google_image.svg'
import next from '../../../../../../assets/next_arrow.webp'
import prev from '../../../../../../assets/prev_arrow.webp'
import star from './mock/imgs/star.svg'
import * as Styled from './styles'
import { useState, useEffect } from 'react'
import { Carousel } from '@mantine/carousel'
import fetchGoogle from '../../../../../../services/google'
import { useMediaQuery } from '../../../../../../hooks/useMediaQuery'
import { useTranslation } from 'react-i18next'
import { ChevronLeft, ChevronRight } from 'lucide-react'

const CardCarousel = ({ dark }) => {
  const [reviews, setReviews] = useState([])
  const [expandedReviews, setExpandedReviews] = useState([])

  const allExpandedMatch = useMediaQuery('(max-width: 500px)')

  const { t } = useTranslation()

  const getStars = (rating) => {
    const stars = new Array(rating).fill(star)
    return stars.map((star, index) => <img key={index} src={star} alt="star" />)
  }

  const handleToggleText = (index) => {
    if (expandedReviews.includes(index)) {
      setExpandedReviews(expandedReviews.filter((item) => item !== index))
    } else {
      setExpandedReviews([...expandedReviews, index])
    }
  }

  const formatDate = (time) => {
    const date = new Date(time * 1000)
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear().toString()

    return `${day}/${month}/${year}`
  }

  useEffect(() => {
    fetchGoogle
      .getAllBooking()
      .then((res) => {
        const reviews = res.data.reviews
        setReviews(reviews)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [])

  if (!reviews || reviews?.length === 0) return null

  return (
    <Styled.Container dark={dark}>
      <Carousel
        // withIndicators
        height="100%"
        slideSize="25%"
        slideGap="0.2rem"
        breakpoints={[
          { maxWidth: 'md', slideSize: '50%' },
          { maxWidth: 'sm', slideSize: '25%' }
        ]}
        align="start"
        nextControlIcon={
          dark ? (
            <ChevronRight
              size="3.6rem"
              color="#f3f4f6"
              style={{
                minWidth: '3.6rem',
                minHeight: '3.6rem',
                marginTop: '-2.4rem'
              }}
            />
          ) : (
            <Styled.BtnNext src={next} alt="Ícone de avançar" />
          )
        }
        previousControlIcon={
          dark ? (
            <ChevronLeft
              size="3.6rem"
              color="#f3f4f6"
              style={{
                minWidth: '3.6rem',
                minHeight: '3.6rem',
                marginTop: '-2.4rem'
              }}
            />
          ) : (
            <Styled.BtnPrev src={prev} alt="Ícone de retroceder" />
          )
        }
        loop
      >
        {reviews.map((item, index) => (
          <Carousel.Slide mb={30} key={index}>
            <Styled.CardContainer dark={dark} data-value={index}>
              <Styled.CardRow>
                <Styled.Header>
                  <img src={item.profile_photo_url} alt="user" />
                  <Styled.TextContainer>
                    <h1>{item.author_name.slice(0, 18)}</h1>
                    <span>{item.time && formatDate(item.time)}</span>
                  </Styled.TextContainer>
                  <Styled.ImageGoogle src={googleImage} alt="google" />
                </Styled.Header>
                <div>{getStars(item.rating)}</div>
                <p className="description-text">
                  {expandedReviews.includes(index) || allExpandedMatch
                    ? item.text
                    : item.text.length > 110
                    ? item.text.slice(0, 110) + '...'
                    : item.text}
                </p>
              </Styled.CardRow>
              {item.text.length > 110 &&
                !expandedReviews.includes(index) &&
                !allExpandedMatch && (
                  <Styled.Button onClick={() => handleToggleText(index)}>
                    {t('seeMore')}
                  </Styled.Button>
                )}
            </Styled.CardContainer>
          </Carousel.Slide>
        ))}
      </Carousel>
    </Styled.Container>
  )
}

export default CardCarousel
