import axios from 'axios'

const api = axios.create({
  baseURL: 'https://api.easytransferdrivers.site/'
})

const unauthorizedEndpoints = ['/account/authenticate', '/hostel/open']

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('@token')

  if (
    token &&
    !unauthorizedEndpoints.some((endpoint) => config.url.includes(endpoint))
  ) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      const token = localStorage.getItem('@token')

      if (!token) {
        if (error.response.data.error === 'Unauthorized') {
          localStorage.removeItem('@token')
          localStorage.removeItem('@user')
          localStorage.removeItem('@role')
          window.location.pathname = '/login'
        }
      } else {
        if (error.response.data.error === 'Unauthorized') {
          localStorage.removeItem('@token')
          localStorage.removeItem('@user')
          localStorage.removeItem('@role')
          window.location.pathname = '/'
        }
      }
    }

    return Promise.reject(error)
  }
)

export default api
