import React from 'react'
import * as Styled from './styles'

export function ChartCard({ title, Icon, width = 370, children }) {
  return (
    <Styled.Container width={width}>
      <Styled.Header>
        {!!Icon && (
          <Styled.IconContainer>
            <Icon />
          </Styled.IconContainer>
        )}
        <Styled.Title>{title}</Styled.Title>
      </Styled.Header>

      <Styled.Content>{children}</Styled.Content>
    </Styled.Container>
  )
}
