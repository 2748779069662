import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 2.4rem;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`

export const RegisterItemWrapper = styled.div`
  width: 44rem;
  border-radius: 0.8rem;
  border: 1px solid #dee2e6;
  padding: 2.4rem 1.6rem 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > p:first-of-type {
    color: #404040;
    text-align: center;
    font-family: Neometric;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
  }

  > input {
    color: #000;
    font-family: Neometric;
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    border: none;
    outline: none;
    padding: 2.4rem 0;

    :disabled {
      background: transparent;
      cursor: not-allowed;
    }
  }

  p.label {
    color: #222;
    font-family: Neometric;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 1.2rem;
  }

  > span {
    color: #808080;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    justify-content: center;
    font-family: Neometric;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .mantine-InputWrapper-root .mantine-Input-wrapper > button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12.1898px 8px;
    padding-left: 3.2rem;
    gap: 112.77px;

    width: 100%;
    height: 54px;

    border: 0.704782px solid rgba(56, 40, 14, 0.3);
    border-radius: 8.45738px;

    font-family: 'Neometric';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #0d0d0d;

    &:placeholder {
      color: #a2a2a2;
    }

    &:focus {
      border-color: #27c7ff;
    }

    button {
      .mantine-ActionIcon-root .btn-minus .mantine-47hpvh {
        height: 22px;
        width: 22px;
      }
    }

    &.empty {
      color: #a2a2a2;
    }

    option {
      color: #0d0d0d;
    }
  }

  .mantine-Input-icon {
    margin-left: 0.4rem;
  }

  .mantine-DateTimePicker-month {
    max-width: 28rem;
    width: 100%;
    text-align: center;
  }

  .mantine-DateTimePicker-timeWrapper {
    position: absolute;
    top: -0.4rem;
    right: 2rem;
  }

  .mantine-TimeInput-input {
    border: 0;
  }

  .mantine-CalendarHeader-calendarHeaderLevel {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    /* identical to box height, or 106% */

    color: #38280e;
  }

  .mantine-DateTimePicker-calendarHeaderControlIcon {
    width: 2rem;
    height: 2rem;
  }

  .mantine-TimeInput-input {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    /* identical to box height, or 106% */

    color: #38280e;
  }

  .mantine-WeekdaysRow-weekday {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 9.86317px;
    line-height: 12px;
    /* identical to box height */

    color: #38280e;
  }

  .mantine-InputWrapper-root .mantine-Input-wrapper > button {
    padding-left: 3.2rem;

    &:focus {
      border-color: rgba(56, 40, 14, 0.3);
    }

    &[aria-expanded='true'] {
      border-color: #27c7ff;
    }
  }

  @media (max-width: 500px) {
    &,
    input {
      width: 100%;
    }
  }
`

export const MessageWrapper = styled.div`
  textarea {
    padding: 0.8rem;
    border-radius: 8px;
    border: 0.508px solid #bfbfbf;
    min-height: 10rem;
    resize: none;
    width: 100%;

    color: #404040;
    font-family: Neometric;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    outline-color: #27c7ff;

    :disabled {
      cursor: not-allowed;
    }
  }
`

export const ButtonsWrapper = styled.div`
  > div {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    margin-bottom: 1rem;
  }

  button {
    flex: 1;
    width: 100%;
    height: 5.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    border-radius: 0.8rem;
    background-color: transparent;
    transition: filter 0.3s ease-in-out;

    :disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }

    :hover {
      filter: brightness(0.98);
    }

    svg {
      width: 2.4rem;
      height: 2.4rem;
    }

    span {
      font-family: Neometric;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1;
    }

    &.income {
      background-color: #d5f6ea;

      span {
        color: #48d9a4;
      }
    }

    &.outcome {
      background-color: #fdced6;

      span {
        color: #f8294e;
      }
    }

    &.charge {
      border: 1px solid #404040;

      span {
        color: #404040;
      }
    }
  }
`

export const Historic = styled.div`
  flex: 1;
  width: 100%;
  min-height: 62rem;
  padding: 1.6rem;
  border-radius: 0.8rem;
  border: 0.1rem solid #dee2e6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  > div {
    flex: 1;
    max-height: 58rem;
    display: flex;
    flex-direction: column;
    padding-right: 0.8rem;

    overflow: auto;

    > h2 {
      color: #000;
      font-family: Neometric;
      font-size: 2rem;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.2px;
      margin-bottom: 2.4rem;
    }

    > p {
      font-size: 1.4rem;
      font-weight: 600;
      color: #a2a2a2;
    }

    &::-webkit-scrollbar {
      width: 0.6rem !important;
      display: block;
    }

    &::-webkit-scrollbar-track {
      background: #f7f7f7 !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #27c7ff !important;
      border-radius: 1.6rem !important;
    }

    scrollbar-width: thin !important;
    scrollbar-color: #27c7ff #f7f7f7 !important;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex: 1;
      gap: 0.8rem;
    }
  }
`

export const HistoricItemIcon = styled.span`
  ${({ color }) => css`
    width: 3.2rem;
    height: 3.2rem;
    border-radius: 50%;
    background-color: ${color || '#bfbfbf'};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

export const HistoricItemValue = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;

  > p {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 400;

    p {
      font-size: 1.2rem;
      font-weight: 700;
    }
  }
`

const historicItemModifiers = {
  income: () => css`
    align-self: start;
    > div:last-of-type {
      p {
        span {
          color: #48d9a4;
        }
      }
    }
    ${HistoricItemValue} {
      span {
        background-color: #d5f6ea;
      }
    }
  `,
  outcome: () => css`
    align-self: end;
    > div:last-of-type {
      p {
        span {
          color: #f8294e;
        }
      }
    }
    ${HistoricItemValue} {
      span {
        background-color: #fdced6;
      }
    }
  `,
  charge: () => css`
    align-self: center;
    > div:last-of-type {
      p {
        span {
          color: #404040;
        }
      }
    }
  `
}

export const HistoricItemWrapper = styled.div`
  ${({ type, invertedUsers }) => css`
    width: 100%;
    border-radius: 0.8rem;
    border: 0.5px solid #bfbfbf;
    overflow: hidden;
    padding-top: 0.4rem;
    > div:first-of-type {
      display: flex;
      flex-direction: ${invertedUsers ? 'row-reverse' : 'row'};
      margin-bottom: 0.4rem;
      justify-content: space-between;
    }
    > div:last-of-type {
      background: red;
      padding: 0.6rem 0.8rem;
      background-color: #f7f7f7;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > button {
        font-size: 0;
        background: transparent;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        color: var(--grey-2-grayscale, #808080);
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.12px;

        span {
          strong {
            font-weight: 600;
          }

          span {
            font-style: italic;
          }
        }
      }
    }
    ${historicItemModifiers[type]()};
  `}

  @media(max-width: 425px) {
    width: 100%;
  }
`

export const AccountInfo = styled.div`
  padding: 0.4rem 1.6rem;
  max-width: 18rem;
  width: 100%;
  > div {
    display: flex;
    gap: 1.2rem;

    > span {
      width: 4.8rem;
      min-width: 4.8rem;
      height: 4.8rem;
      min-height: 4.8rem;
      background-color: #bfbfbf;
      border: 0.1rem solid #27c7ff;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;

      strong {
        color: #404040;
        font-family: Neometric;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.16px;
        word-break: break-all;
      }

      span {
        padding: 0.4rem 0.8rem;
        width: fit-content;
        background-color: #1D95BF;
        border-radius: 0.8rem;
        color: #ffffff;
        font-family: Neometric;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.12px;
      }

      p {
        color: #808080;
        font-family: Neometric;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.12px;
        max-width: 7rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
`
