import { Financial } from '../pages/Dashboard/pages/Financial'
import { FinancialDetails } from '../pages/Dashboard/pages/FinancialDetails'
import { FinancialDriverPayment } from '../pages/Dashboard/pages/FinancialDriverPayment'
import { Fleet } from '../pages/Dashboard/pages/Fleet'
import { Partnership } from '../pages/Dashboard/pages/Partnership'
import { PartnershipMerge } from '../pages/Dashboard/pages/PartnershipMerge'
import { Settings } from '../pages/Dashboard/pages/Settings'
import { SettingsAdm } from '../pages/Dashboard/pages/SettingsAdm'
import { CouponDetails, Coupons } from '../pages/Dashboard/pages/Coupons'
import { LowCostPage } from '../pages/Dashboard/pages/LowCost'
import { LowCostDetails } from '../pages/Dashboard/pages/LowCostDetails'
import { Config } from '../pages/Dashboard/pages/Config'
import { DashboardHome } from '../pages/Dashboard/pages/Home'
import { OrderBump } from '../pages/Dashboard/pages/OrderBump'
import { OrderBumpDetails } from '../pages/Dashboard/pages/OrderBumpDetails'
import { Chat } from '../pages/Dashboard/pages/Chat'
import { PartnershipAccountMerge } from '../pages/Dashboard/pages/PartnershipAccountMerge'
import { UserEnterpriseMerge } from '../pages/Dashboard/pages/UserEnterpriseMerge'

export default [
  {
    url: 'home',
    element: <DashboardHome />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN']
  },
  {
    url: 'frota',
    element: <Fleet />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN']
  },
  {
    url: 'frota/driver/registrar',
    element: <SettingsAdm />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN']
  },
  {
    url: 'frota/empresa/registrar',
    element: <SettingsAdm />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN']
  },
  {
    url: 'frota/empresa/:id/usuario',
    element: <UserEnterpriseMerge />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN', 'COMPANY_ADMIN']
  },
  {
    url: 'frota/empresa/:id/motorista',
    element: <UserEnterpriseMerge />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN', 'COMPANY_ADMIN']
  },
  {
    url: 'frota/empresa/:id/usuario/:accountId',
    element: <UserEnterpriseMerge />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN', 'COMPANY_ADMIN']
  },
  {
    url: 'frota/empresa/:id/motorista/:accountId',
    element: <UserEnterpriseMerge />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN', 'COMPANY_ADMIN']
  },
  {
    url: 'frota/empresa/:id',
    element: <SettingsAdm />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN']
  },
  {
    url: 'frota/driver/:id',
    element: <SettingsAdm />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN']
  },
  {
    url: 'partnership',
    element: <Partnership />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN']
  },
  {
    url: 'partnership/registrar',
    element: <PartnershipMerge />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN']
  },
  {
    url: 'partnership/:id',
    element: <PartnershipMerge />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN']
  },
  {
    url: 'partnership/:id/account',
    element: <PartnershipAccountMerge />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN', 'HOTEL_USER']
  },
  {
    url: 'partnership/:id/account/:accountId',
    element: <PartnershipAccountMerge />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN', 'HOTEL_USER']
  },
  {
    url: 'financial',
    element: <Financial />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN']
  },
  {
    url: 'financial/:id',
    element: <FinancialDetails />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN']
  },
  {
    url: 'financial/driver/:id',
    element: <FinancialDriverPayment />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN', 'USER', 'COMPANY_USER']
  },
  {
    url: 'financial/hostel/:id',
    element: <FinancialDriverPayment />,
    wrapWithDefaultPage: true,
    roles: ['HOTEL_USER']
  },
  {
    url: 'financial/company/:id',
    element: <FinancialDriverPayment />,
    wrapWithDefaultPage: true,
    roles: ['COMPANY_ADMIN']
  },
  {
    url: 'settings',
    element: <Settings />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN', 'HOTEL_USER', 'USER', 'COMPANY_USER', 'COMPANY_ADMIN']
  },
  {
    url: 'settings/hostel/:id',
    element: <Settings />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN', 'HOTEL_USER', 'USER', 'COMPANY_USER', 'COMPANY_ADMIN']
  },
  {
    url: 'settings/company/:id',
    element: <Settings />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN', 'HOTEL_USER', 'USER', 'COMPANY_USER', 'COMPANY_ADMIN']
  },
  {
    url: 'settings',
    element: <Settings />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN', 'HOTEL_USER', 'USER', 'COMPANY_USER', 'COMPANY_ADMIN']
  },
  {
    url: 'coupons',
    element: <Coupons />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN']
  },
  {
    url: 'coupons/:id',
    element: <CouponDetails />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN']
  },
  {
    url: 'low-cost',
    element: <LowCostPage />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN']
  },
  {
    url: 'low-cost/:id',
    element: <LowCostDetails />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN']
  },
  {
    url: 'order-bump',
    element: <OrderBump />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN']
  },
  {
    url: 'order-bump/:id',
    element: <OrderBumpDetails />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN']
  },
  {
    url: 'order-bump/novo',
    element: <OrderBumpDetails />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN']
  },
  {
    url: 'config',
    element: <Config />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN']
  },
  {
    url: 'chat',
    element: <Chat />,
    wrapWithDefaultPage: true,
    roles: ['ADMIN', 'USER', 'HOTEL_USER', 'COMPANY_USER', 'COMPANY_ADMIN']
  }
]
