import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 94vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TitleContainer = styled.div``

export const WelcomeText = styled.h1`
  display: inline;

  color: #404040;
  font-family: Neometric, sans-serif;
  font-size: 24px;
  font-weight: 400;
`

export const Row = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;

  & > div {
    flex: 1;
  }
`

export const LongChartsContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;

  & > div {
    flex: 1;
    min-width: 500px;

    @media (max-width: 600px) {
      min-width: unset;
    }
  }
`
