import React from 'react'
import ReactApexChart from 'react-apexcharts'

import { ReactComponent as CarIcon } from '../../icons/car.svg'
import { ChartCard } from '../ChartCard'

import { CATEGORIES, COLORS } from './constants'

const DEFAULT_DATA = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

export function FinishedTransfersChart({ data }) {
  return (
    <ChartCard title="Total de transfers finalizados" Icon={CarIcon}>
      <ReactApexChart
        height={170}
        type="area"
        series={[
          {
            name: 'Finalizados',
            data: data ?? DEFAULT_DATA
          }
        ]}
        options={{
          colors: COLORS,
          xaxis: { categories: CATEGORIES },
          dataLabels: { enabled: false },
          chart: { toolbar: { show: false } },
          stroke: { width: 2.5, curve: 'smooth' }
        }}
      />
    </ChartCard>
  )
}
