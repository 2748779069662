import { useMemo } from 'react'

export function useSanitizeSearch(search) {
  // const sanitizedSearch = useMemo(() => {
  const filteredSearch = { ...search }

  for (const key in filteredSearch) {
    if (filteredSearch[key].trim() === '') {
      delete filteredSearch[key]
    }
  }
  // }, [search])

  return filteredSearch
}
