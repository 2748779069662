import api from './api'

const fetchObj = {
  create: (payload) => {
    const res = api.post('/config', payload)

    return res
  },

  update: (payload) => {
    const res = api.put('/config', payload)

    return res
  },
  get: (payload) => {
    const res = api.get('/config', { params: payload })

    return res
  }
}

export default fetchObj
