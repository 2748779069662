import { useContext } from 'react'
import { ModalContext } from '../store/context/ModalContext'

export const useModal = () => {
  const context = useContext(ModalContext)

  if (!context)
    throw new Error('useDocument must be used within a DocumentContextProvider')

  return context
}
