import { useRef } from 'react'
import * as Styled from './styles'
import { useState } from 'react'
import { useIsInViewport } from '../../../../../../hooks/useIsInViewport'
import { stagger, useAnimate, motion } from 'framer-motion'
import { useEffect } from 'react'

export const Card = ({ resources }) => {
  const listRef = useRef(null)
  const [alreadyVisibleList, setAlreadyVisibleList] = useState(false)
  const isInViewPort = useIsInViewport(listRef)
  const [scope, animate] = useAnimate()

  const staggerMenuItems = stagger(0.1, { startDelay: 0.1 })

  useEffect(() => {
    if (alreadyVisibleList) return

    if (isInViewPort) setAlreadyVisibleList(true)

    animate(
      '.card-item',
      isInViewPort ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0 },
      {
        duration: 0.6,
        delay: isInViewPort ? staggerMenuItems : 0
      }
    )
  }, [isInViewPort, alreadyVisibleList])

  return (
    <div ref={listRef}>
      <Styled.Container ref={scope}>
        {resources.map((item, index) => (
          <Styled.CardContainer key={index}>
            <Styled.Card className="card-item">{item.img}</Styled.Card>
            <div className="wrapper">
              <motion.h2
                initial={{ y: '-20px', opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.4, delay: 0.8 }}
                viewport={{ once: true }}
              >
                {item.title}
              </motion.h2>
              <motion.p
                initial={{ y: '-20px', opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.4, delay: 1 }}
                viewport={{ once: true }}
              >
                {item.desc}
              </motion.p>
            </div>
          </Styled.CardContainer>
        ))}
      </Styled.Container>
    </div>
  )
}
