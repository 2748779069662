import * as Styled from './styles'
import { formatCurrency } from '../../../../../../utils/formatCurrency'

export const PaymentDetails = ({
  totalCharged,
  totalEasyTransfer,
  totalCredit,
  totalDriver
}) => {
  return (
    <Styled.PricingContainer>
      <ul>
        <li>
          <strong>ITEM</strong>
          <strong>PREÇO</strong>
        </li>
        <li>
          <span>Total cobrado</span>
          <strong>{formatCurrency(totalCharged)}</strong>
        </li>
        <li>
          <span>Total EasyTransfer</span>
          <strong>{formatCurrency(totalEasyTransfer)}</strong>
        </li>
        <li>
          <span>Total Carteira</span>
          <strong>{formatCurrency(totalCredit)}</strong>
        </li>
      </ul>
      <div>
        <p>
          Total líquido <br /> motorista
        </p>
        <strong>
          <span>€</span>
          {formatCurrency(totalDriver).split('€')[1]}
        </strong>
      </div>
    </Styled.PricingContainer>
  )
}
