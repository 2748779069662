import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 94vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`
export const Title = styled.h1`
  font-family: Neometric;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.01em;
  text-align: left;
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  button {
    /* width: 100%; */
    height: 48px;
    padding: 8px 16px 8px 8px;
    border-radius: 32px;
    border: 1px;
    gap: 8px;
    border: 1px solid #e6e6e6;
    font-family: Neometric;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    color: #404040;
  }

  div {
    justify-content: flex-start;
  }

  @media (max-width: 1024px) {
    & {
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      width: 92vw;
    }
    &::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }
  }
`

export const ButtonsWrapper = styled.div`
  #input-demo {
    display: flex;
    width: 138px;
    height: 48px;
    padding: 12.19px 8px;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    border: 0.508px solid var(--grey-3-grayscale, #bfbfbf);
  }

  #select-filter {
    display: flex;
    height: 48px;
    border-radius: 8px;
    border: 0.508px solid var(--grey-3-grayscale, #bfbfbf);
  }
  .mantine-Input-wrapper {
    margin-top: 0;
  }
  .activeCount {
    display: flex;
    height: 18px;
    padding: 8px 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    background: var(--yellow-brand-primary, #27c7ff);
    color: var(--white-grayscale, #fff);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 14.4px */
    letter-spacing: 0.12px;
  }
`

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* gap: 10px; */

  > div {
    display: flex;
    gap: 2.4rem;

    .mantine-Input-input {
      height: 4.8rem !important;

      font-size: 1.6rem;
    }

    #input-demo {
      width: 26rem;
      padding-right: 2.8rem;
    }

    > div.date-filter {
      border-radius: 0.8rem;
      border: 0.1rem solid #bfbfbf;
      display: flex;

      .mantine-SegmentedControl-root {
        border-radius: 0.8rem 0 0 0.8rem;
        height: 100%;
        padding: 0;
        background-color: #ffffff;
      }

      .mantine-SegmentedControl-indicator {
        visibility: hidden;
        /* transform: translate(16rem, -4px); */
      }

      .mantine-SegmentedControl-control {
        width: 16rem;

        &:not(:first-of-type) {
          border-left: 0.1rem solid #bfbfbf;
        }
      }

      .mantine-SegmentedControl-label {
        height: 100%;
        color: #808080;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        /* border: 0; */
      }

      .mantine-SegmentedControl-label[data-active] {
        background-color: #27c7ff;
        overflow: hidden;
        color: #ffffff;
        font-weight: 500;
        /* color: black; */

        /* border-radius: 0.8rem; */
        /* font-weight: 600; */
      }

      .mantine-Input-wrapper {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        gap: 1.2rem;
      }

      .mantine-DatePickerInput-input {
        width: 16rem;
        border-radius: 0 0.8rem 0.8rem 0;
        border: none;
        font-size: 1.2rem;
        text-align: center;
        border-left: 0.1rem solid #bfbfbf;

        &[aria-expanded='true'] {
          background-color: #27c7ff;
          color: #ffffff;
          font-weight: 500;
          span {
            color: inherit;
            font-weight: inherit;
          }
        }
      }

      .mantine-DatePickerInput-root {
        background-color: transparent;

        &.active {
          .mantine-DatePickerInput-input {
            background-color: #27c7ff;
            color: #ffffff;
            font-weight: 500;

            span {
              color: inherit;
              font-weight: inherit;
            }
          }
        }
      }

      .mantine-Popover-dropdown {
        width: 300px !important;
        height: auto !important;

        background: #ffffff !important;
        box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.07) !important;
        border-radius: 0px 22px 22px 22px !important;
      }

      .mantine-CalendarHeader-calendarHeader {
        max-width: 100%;
      }

      .mantine-DatePickerInput-month {
        width: 100%;
        text-align: center;
      }

      .mantine-TimeInput-input {
        border: 0;
      }

      .mantine-CalendarHeader-calendarHeaderLevel {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: normal;
        /* identical to box height, or 106% */

        color: #38280e;
      }

      .mantine-DatePickerInput-calendarHeaderControlIcon {
        width: 2rem;
        height: 2rem;
      }

      .mantine-TimeInput-input {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 17px;
        /* identical to box height, or 106% */

        color: #38280e;
      }

      .mantine-WeekdaysRow-weekday {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 700;
        font-size: 9.86317px;
        line-height: 12px;
        /* identical to box height */

        color: #38280e;
      }
    }
  }

  @media (max-width: 500px) {
    .filtes-wrapper,
    .mantine-SegmentedControl-root,
    .mantine-SegmentedControl-control,
    .date-filter {
      width: 100% !important;
    }
  }
`

export const Count = styled.div`
  color: var(--yellow-3-support, #1D95BF);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
  letter-spacing: 0.12px;
  display: flex;
  height: 18px;
  padding: 8px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: var(--yellow-2-support, #DFF7FF);
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const ConfirmButton = styled.button`
  display: flex;
  width: 220px;
  height: 48px;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: #27c7ff;
  color: var(--white-grayscale, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;
  text-transform: uppercase;
  border: 2px solid var(--yellow-brand-primary, #27c7ff);
`
export const InputsWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
  width: 100%;
  #input-demo,
  .mantine-Input-wrapper,
  .mantine-InputWrapper-root {
    width: 100%;
  }
`
