import { Header } from './Header'
import * as Styled from './styles'
import { Navbar } from '../../components/Navbar'
import { Steppers } from './components/Steppers'
import { StripeContainer } from './components/StripeContainer'
import { useState } from 'react'
import { useTransferContext } from '../../contexts/TransferContext'
import { ChoiceCars } from './components/ChoiceCars'
import { RightContainer } from './RightContainer'
import Footer from '../../components/Footer'
import { NavbarCheckout } from '../../components/NavbarCheckout'
import { useModal } from '../../hooks/useModal'
import { ModalConfirmTransfer } from '../../components/ModalConfirmTransfer'

export const Checkout3 = () => {
  const [showCars, setShowCars] = useState()

  const { carSelected, setCarSelected } = useTransferContext()
  const { modalPortalNode, openedModals, closeModal } = useModal()
  const { going, setGoing } = useTransferContext()
  return (
    <>
      <div style={{ maxWidth: 988, margin: '0 auto' }}>
        <NavbarCheckout />
      </div>
      <Styled.Container>
        <Steppers stepper={2} />
        <Styled.ChoiceCarsContainer showCars={showCars}>
          <ChoiceCars
            setSelectedCar={setCarSelected}
            setShowCars={setShowCars}
          />
        </Styled.ChoiceCarsContainer>

        <Styled.ContentContainer>
          <Styled.MiddleContainerRow showCars={showCars}>
            <StripeContainer />
            <RightContainer
              carSelected={carSelected}
              setShowCars={setShowCars}
              showCars={showCars}
              showCouponOnly
            />
          </Styled.MiddleContainerRow>
        </Styled.ContentContainer>
      </Styled.Container>
      <Footer />
      {openedModals.includes('ModalConfirmTransfer') && (
        <ModalConfirmTransfer
          onClose={() => console.log()}
          data={going}
          setData={setGoing}
        />
      )}
    </>
  )
}
