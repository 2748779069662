import * as Styled from './styles'

import { Navbar } from '../../components/Navbar'
import Footer from '../../components/Footer'

import Hero from './components/Hero'
import CardThings from './components/CardThings'
import Tours from './components/Tours'
import MostChosen from './components/MostChosen'

import { cardData } from './cardData'
import { TourProvider } from './useTour'
import { BottomBanner } from '../Events/components/BottomBanner'
import { useTranslation } from 'react-i18next'

export const Tour = () => {
  const { t } = useTranslation()
  return (
    <TourProvider>
      <Navbar />
      <Styled.Container>
        <Styled.ContentWrapper>
          <Hero />

          <CardThings data={cardData(t)} />

          <Tours />

          <MostChosen />

          <BottomBanner />
        </Styled.ContentWrapper>
      </Styled.Container>
      <Footer />
    </TourProvider>
  )
}
