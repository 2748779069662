import * as Styled from './styles'
import { ActionIcon, Group, NumberInput } from '@mantine/core'
import MinusBtn from '../../imgs/minus-btn.webp'
import PlusBtn from '../../imgs/plus-btn.webp'

export const AddPassenger = ({ label, setValue, value, icon, width }) => {
  const IncreaseValue = (value) => {
    setValue(value + 1)
  }
  const DecreaseValue = (value) => {
    if (value < 1) {
      return
    } else {
      setValue(value - 1)
    }
  }

  return (
    <>
      {label && icon && (
        <Styled.LabelAndIconWrapper>
          <label>{label}</label>
          <img src={icon} />
        </Styled.LabelAndIconWrapper>
      )}
      <Group
        spacing={5}
        style={{
          justifyContent: 'space-between',
          position: 'relative',
          width: '149.3px'
        }}
      >
        <ActionIcon
          className="btn-minus"
          size={60}
          variant="transparent"
          style={{
            background: 'transparent',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: 'none'
          }}
          onClick={() => DecreaseValue(value)}
        >
          <img src={MinusBtn} />
        </ActionIcon>

        <NumberInput
          hideControls
          value={value}
          onChange={(val) => setValue(val)}
          max={10}
          min={0}
          step={1}
          styles={{ input: { width: `${width}`, textAlign: 'center' } }}
          style={{ width: `${width}` }}
        />

        <ActionIcon
          className="btn-plus"
          size={60}
          variant="transparent"
          style={{
            background: 'transparent',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: 'none'
          }}
          onClick={() => IncreaseValue(value)}
        >
          <img src={PlusBtn} />
        </ActionIcon>
      </Group>
    </>
  )
}
