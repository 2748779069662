import { useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import { Navbar } from '../../../../components/Navbar'
import Footer from '../../../../components/Footer'
import PhoneInput from 'react-phone-input-2'
import { Checkbox, useMantineTheme } from '@mantine/core'
import CustomDropzone from './Arquives/CustomDropzone'
import classes from './Arquives/DropzoneButton.module.css'
import { AddPassenger } from './AddPassenger'
import { toast } from 'react-toastify'
import fetchAccount from '../../../../services/account'
import fetchCompany from '../../../../services/company'
import fetchFile from '../../../../services/file'
import { useTranslation } from 'react-i18next'

const Avatar = styled.img``

const H = styled.h3`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: 0.01em;
  line-height: 110%;
  font-weight: 900;
  font-family: inherit;
  @media screen and (max-width: 420px) {
    font-weight: 800;
    font-family: Neometric;
    font-size: 24px;
    align-self: stretch;
    width: auto;
  }
`
const PreenchaComSeus = styled.span``
const OsPrximosPassos = styled.span`
  font-weight: 500;
`
const H5Txt = styled.span`
  width: 100%;
`
const H1 = styled.h5`
  margin: 0;
  width: 531px;
  position: relative;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 120%;
  display: flex;
  align-items: center;
  font-family: Poppins;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
  }
`
const H3Parent = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
  }
`
const ImgIcon = styled.img`
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
    /* min-width: 400px; */
  }
`
const Sectionhero = styled.section`
  align-self: stretch;
  margin-top: 8rem;
  background: linear-gradient(95.8deg, #27c7ff, rgba(255, 255, 255, 0));
  height: 278px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 156px;
  box-sizing: border-box;
  gap: 10px;
  text-align: left;
  font-size: 32px;
  color: #222;
  font-family: Neometric;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
  @media screen and (max-width: 420px) {
    padding-left: 16px;
    padding-top: 24px;
    padding-right: 16px;
    box-sizing: border-box;
    height: 100%;
  }
`
const H2 = styled.h4`
  margin: 0;
  flex: 1;
  position: relative;
  font-size: 18px;
  letter-spacing: 0.01em;
  line-height: 120%;
  font-weight: 800;
  font-family: Neometric;
  color: #222;
  text-align: left;
`
const H4Wrapper = styled.div`
  align-self: stretch;
  border-bottom: 0.5px solid #bfbfbf;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
`
const Upload = styled.input`
  width: 150px;
  position: relative;
  height: 150px;
`
const P = styled.p`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 120%;
  font-weight: 500;
  font-family: Poppins;
  color: #222;
  text-align: center;
`
const Imgicon = styled.img`
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
`
const H3 = styled.h6`
  margin: 0;
  position: relative;
  font-size: 12px;
  font-weight: 400;
  font-family: Poppins;
  color: #222;
  text-align: left;
  @media screen and (max-width: 420px) {
    flex: 1;
  }
`
const ImgiconParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`
const Imgicon1 = styled.img`
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
`
const List = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 4px;
  }
`
const PParent = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px;
  gap: 16px;
`
const UploadParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 16px;
  gap: 10px;
`
const NomeCompleto = styled.b`
  font-size: 14px;
  font-family: Neometric;
  color: #222;
`
const Span = styled.span`
  font-size: 16px;
  font-weight: 500;
  font-family: Neometric;
  color: #f8294e;
`
const EMail = styled.label`
  cursor: pointer;
  position: relative;
  text-align: left;
`
const Label = styled.label`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`
const Input = styled.input`
  border: 0.5px solid #bfbfbf;
  outline: none;
  font-family: Poppins;
  font-size: 14px;
  background-color: transparent;
  align-self: stretch;
  border-radius: 6.09px;
  box-sizing: border-box;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12.189754486083984px 8px;
  color: #a2a2a2;
`
const InputForms = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`
const Span1 = styled.span`
  font-size: 16px;
  font-family: Neometric;
  color: #f8294e;
`
const Input1 = styled.input`
  border: 0.7px solid #bfbfbf;
  outline: none;
  font-family: Poppins;
  font-size: 14px;
  background-color: transparent;
  align-self: stretch;
  border-radius: 8.46px;
  box-sizing: border-box;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 16px 4px;
  color: #a2a2a2;
`
const RowInputFormsframe = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`
const Sim = styled.option``
const Select = styled.select`
  align-self: stretch;
  border-radius: 6.09px;
  background-color: transparent;
  border: 0.5px solid #bfbfbf;
  box-sizing: border-box;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12.189754486083984px 8px;
  font-family: Poppins;
  font-size: 14px;
  color: #a2a2a2;
`
const ImgIcon1 = styled.img`
  width: 45px;
  position: relative;
  height: 45px;
  overflow: hidden;
  flex-shrink: 0;
`
const H4 = styled.h5`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: 14px;
  line-height: 120%;
  font-weight: 500;
  font-family: Poppins;
  color: #222;
  text-align: left;
`
const H5 = styled.h6`
  margin: 0;
  width: 324px;
  position: relative;
  font-size: 12px;
  line-height: 130%;
  font-weight: 400;
  font-family: Poppins;
  color: #222;
  text-align: left;
  display: inline-block;
`
const H5Parent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`
const ImgParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  @media screen and (max-width: 420px) {
    flex: 1;
  }
`
const Div = styled.div`
  align-self: stretch;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
`
const DivWrapper = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
    flex: unset;
  }
`
const Upload1 = styled.input`
  width: 512px;
  border-radius: 16px;
  border: 2px dashed #bfbfbf;
  box-sizing: border-box;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 60px;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
    height: auto;
  }
`
const Imgicon2 = styled.img`
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  cursor: pointer;
`
const ImgiconWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 4px 0px 0px;
`
const Container = styled.div`
  width: 1128px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  @media screen and (max-width: 960px) {
    align-self: stretch;
    width: auto;
  }
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
    flex-direction: column;
    padding-bottom: 24px;
    box-sizing: border-box;
  }
`
const RowInputFormsframe1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
  }
`
const Div1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
`
const H6 = styled.h4`
  margin: 0;
  position: relative;
  font-size: 18px;
  letter-spacing: 0.01em;
  line-height: 120%;
  font-weight: 800;
  font-family: Neometric;
  color: #222;
  text-align: left;
`
const Modelo = styled.b`
  font-family: Neometric;
  color: #222;
`
const Span2 = styled.span`
  font-family: Neometric;
  color: #f8294e;
`
const EMail1 = styled.label`
  cursor: pointer;
  position: relative;
  font-size: 14px;
  text-align: left;
`
const Upload2 = styled.input`
  width: 264px;
  border-radius: 16px;
  border: 2px dashed #bfbfbf;
  box-sizing: border-box;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 60px;
  @media screen and (max-width: 420px) {
    min-width: 250px;
  }
`
const FotoDoInterior = styled.b`
  font-family: Neometric;
  color: #404040;
`
const H7 = styled.h5`
  margin: 0;
  align-self: stretch;
  width: 218px;
  position: relative;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 120%;
  text-align: left;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`
const H5Group = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`
const H8 = styled.h6`
  margin: 0;
  position: relative;
  font-size: 12px;
  letter-spacing: 0.01em;
  line-height: 120%;
  font-weight: 400;
  font-family: Poppins;
  color: #000;
  text-align: left;
`
const FrameParent = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  @media screen and (max-width: 420px) {
    flex: unset;
    align-self: stretch;
  }
`
const Container1 = styled.div`
  width: 552px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
    flex: 1;
    flex-direction: column;
  }
`
const Upload3 = styled.input`
  width: 263px;
  border-radius: 16px;
  border: 2px dashed #bfbfbf;
  box-sizing: border-box;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 60px;
  @media screen and (max-width: 420px) {
    min-width: 250px;
  }
`
const H9 = styled.h5`
  margin: 0;
  align-self: stretch;
  width: 197px;
  position: relative;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 120%;
  text-align: left;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`
const ContainerParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 0px;
  gap: 24px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
  @media screen and (max-width: 420px) {
    flex-direction: column;
  }
`
const Lucideusers2Icon = styled.img`
  width: 32px;
  position: relative;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
`
const Passageiros = styled.b`
  align-self: stretch;
  flex: 1;
  position: relative;
  font-size: 16px;
  display: flex;
  font-family: Neometric;
  color: #404040;
  text-align: left;
  align-items: center;
`
const Label1 = styled.label`
  cursor: pointer;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8.68px;
`
const Count = styled.select`
  align-self: stretch;
  border-radius: 8px;
  background-color: transparent;
  border: 0.5px solid #bfbfbf;
  box-sizing: border-box;
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12.189754486083984px 8px;
  font-family: Neometric;
  font-weight: 500;
  font-size: 16px;
  color: #404040;
`
const LabelParent = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`
const Bagagem = styled.p`
  margin: 0;
`
const BagagemDespachada = styled.label`
  cursor: pointer;
  flex: 1;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  font-family: Neometric;
  color: #404040;
  text-align: left;
`
const Count1 = styled.select`
  align-self: stretch;
  border-radius: 6.09px;
  background-color: transparent;
  border: 0.5px solid #bfbfbf;
  box-sizing: border-box;
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12.189754486083984px 8px;
  font-family: Neometric;
  font-weight: 500;
  font-size: 16px;
  color: #404040;
`
const LabelGroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
`
const Label2 = styled.label`
  cursor: pointer;
  align-self: stretch;
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8.68px;
`
const FrameContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`
const EscolhaOsDias = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-family: Neometric;
  color: #222;
`
const H10 = styled.h5`
  margin: 0;
  position: relative;
  letter-spacing: 0.01em;
  line-height: 120%;
  text-align: left;
  @media screen and (max-width: 960px) {
    align-self: stretch;
    width: auto;
  }
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
  }
`
// const Checkbox = styled.input`
//   margin: 0;
//   width: 18px;
//   position: relative;
//   height: 18px;
// `
const Label3 = styled.label`
  cursor: pointer;
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 120%;
  font-family: Poppins;
  color: #000;
  text-align: left;
`
const ChecboxeComLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  .mantine-Checkbox-inner {
    width: 20px;
    height: 20px;
  }

  .mantine-Checkbox-body {
    align-items: center;

    label {
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;

      letter-spacing: 0.01em;
      margin-bottom: 10px;

      color: #000000;
    }
  }

  .mantine-Checkbox-input {
    display: flex;
    align-items: flex-start;
    padding: 1.44609px;
    gap: 7.23px;

    width: 20px;
    height: 20px;
    border-radius: 5.78435px;
    cursor: pointer;
  }

  .mantine-Checkbox-input:checked {
    background: #27c7ff;
    border-color: #27c7ff;

    svg {
      width: 11.41px;
    }
  }

  @media only screen and (max-width: 944px) {
    .mantine-Checkbox-body {
      label {
        font-size: 1.4rem;
      }
    }
  }
`
const Div2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 0px;
  gap: 16px;
`
const TurnoComMelhores = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 12px;
  line-height: 100%;
  font-weight: 500;
  font-family: Poppins;
  color: #1D95BF;
  text-align: left;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`
const Div3 = styled.div`
  border-radius: 4px;
  background-color: #DFF7FF;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2px 4px;
  gap: 8px;
`
const H11 = styled.h4`
  margin: 0;
  position: relative;
  font-size: 18px;
  letter-spacing: 0.01em;
  line-height: 120%;
  font-weight: 800;
  font-family: Neometric;
  color: #222;
  text-align: left;
  @media screen and (max-width: 420px) {
    flex: 1;
  }
`
const LucideuploadIcon = styled.img`
  width: 48px;
  position: relative;
  height: 48px;
  overflow: hidden;
  flex-shrink: 0;
`
const SolteAquiSeu = styled.div`
  position: relative;
  font-size: 14px;
  font-family: Neometric;
  color: #404040;
  text-align: left;
`
const Upload4 = styled.div`
  flex: 1;
  border-radius: 16px;
  border: 2px dashed #bfbfbf;
  box-sizing: border-box;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 60px;
  gap: 7px;
  @media screen and (max-width: 420px) {
    min-width: 250px;
    flex: unset;
    align-self: stretch;
  }
`
const H12 = styled.h5`
  margin: 0;
  position: relative;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 120%;
  text-align: left;
`
const LucidealertCircleIcon = styled.img`
  width: 18px;
  position: relative;
  height: 18px;
  overflow: hidden;
  flex-shrink: 0;
`
const FrenteEVerso = styled.div`
  align-self: stretch;
  width: 89px;
  position: relative;
  font-size: 12px;
  letter-spacing: 0.01em;
  line-height: 120%;
  font-weight: 600;
  font-family: Poppins;
  color: #f8294e;
  text-align: left;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`
const LucidealertCircleParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
`
const Container2 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
    flex-direction: column;
    flex: unset;
  }
`
const ContainerGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 0px 0px;
  gap: 24px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
  @media screen and (max-width: 420px) {
    flex-direction: column;
  }
`
const H5DeConduo = styled.div`
  position: relative;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 120%;
  text-align: left;
`
const Container3 = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
  @media screen and (max-width: 420px) {
    flex-direction: column;
    flex: unset;
    align-self: stretch;
  }
`
const Container4 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
  @media screen and (max-width: 420px) {
    flex-direction: column;
    flex: unset;
    align-self: stretch;
  }
`
const TvdeTaxiRnaat = styled.span`
  text-transform: uppercase;
`
const H13 = styled.h5`
  margin: 0;
  width: 217px;
  position: relative;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 120%;
  text-align: left;
  display: inline-block;
  flex-shrink: 0;
`
const Container5 = styled.div`
  width: 552px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
    flex-direction: column;
  }
`
const ContainerWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 0px 0px;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
  @media screen and (max-width: 420px) {
    flex-direction: column;
  }
`
const Checkbox1 = styled.input`
  margin: 0;
  width: 24px;
  position: relative;
  height: 24px;
  accent-color: #27c7ff;
  @media screen and (max-width: 600px) {
    width: 32px;
    height: 32px;
  }
`
const ConcordoComOs = styled.span`
  font-size: 16px;
  font-weight: 500;
  font-family: Poppins;
  line-height: 130%;
  color: #000;
`
const Span3 = styled.span`
  font-size: 18px;
  font-family: Poppins;
  color: #f8294e;
`
const Label4 = styled.label`
  cursor: pointer;
  position: relative;
  letter-spacing: 0.01em;
  line-height: 120%;
  text-align: left;
`
const ChecboxeComLabel1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  @media screen and (max-width: 960px) {
    flex: 1;
  }
  @media screen and (max-width: 420px) {
    flex: 1;
  }
`
const Div4 = styled.div`
  align-self: stretch;
  border-top: 0.5px solid #bfbfbf;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px 0px;
`
const ConheaAFrota = styled.b`
  position: relative;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 120%;
  text-transform: uppercase;
  font-family: Roboto;
  color: #fff;
  text-align: center;
`
const Button = styled.button`
  cursor: pointer;
  border: none;
  padding: 16px 24px 16px 32px;
  background-color: #27c7ff;
  width: 360px;
  border-radius: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px 18px 19.69px rgba(0, 0, 0, 0.05);
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 16px;
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
  }
`
const ButtonWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const ImgIcon2 = styled.img`
  width: 64px;
  position: relative;
  height: 64px;
  overflow: hidden;
  flex-shrink: 0;
`
const H14 = styled.h5`
  margin: 0;
  position: relative;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 120%;
  font-weight: 600;
  font-family: Poppins;
  color: #222;
  text-align: left;
`
const EMail2 = styled.span`
  text-decoration: underline;
`
const FiqueAtentoAoSeuEMail = styled.b`
  font-family: Poppins;
`
const FiqueAtentoAoSeuEMail1 = styled.span`
  font-family: Poppins;
`
const H15 = styled.h5`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 120%;
  color: #222;
  text-align: center;
`
const H5Parent1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
`
const Container6 = styled.div`
  width: 1128px;
  border-radius: 24px;
  background-color: #d5f6ea;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 24px 32px;
  box-sizing: border-box;
  gap: 24px;
  @media screen and (max-width: 960px) {
    align-self: stretch;
    width: auto;
  }
  @media screen and (max-width: 420px) {
    align-self: stretch;
    width: auto;
    height: auto;
    padding: 16px 16px 24px;
    box-sizing: border-box;
    min-width: 300px;
  }
`
const FormsDriverContent = styled.div`
  margin: 0;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 156px;
  gap: 40px;
  @media screen and (max-width: 960px) {
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 420px) {
    padding: 16px;
    box-sizing: border-box;
  }
`
const SectionheroParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`
const SitePrincipalCadastroDeMRoot = styled.div`
  width: 100%;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const NumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;

  .mantine-NumberInput-input {
    padding: 16px;
    border: 0.5px solid rgba(56, 40, 14, 0.3);
    border-radius: 6px;
  }

  .btn-plus {
    position: absolute;
    right: 0.5rem;
    align-items: center;
  }
  .btn-plus-checked {
    position: absolute;
    right: 0.5rem;
    background: rgba(162, 162, 162, 0.4);
    border: 0.5px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    width: 22.55px !important;
    height: 22.55px !important;
    min-width: 22.55px !important;
    min-height: 22.55px !important;
    align-items: center;
    font-size: 22px;
    color: #0d0d0d;
  }
  .btn-minus {
    position: absolute;
    left: 0.5rem;
    z-index: 99;
    align-items: flex-end;
  }
`

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  border: 0.5px solid #bfbfbf;
  padding: 8px;
  border-radius: 8px;
`

const INITIAL_VALUES = {
  name: '',
  email: '',
  nif: '',
  quantityDrivers: 0,
  vehicle: {
    passengerCapacity: 0,
    luggageCapacity: 0,
    handLuggageCapacity: 0,
    backpackCapacity: 0
  },
  user: {
    name: '',
    email: '',
    phoneNumber: '',
    taxNumber: ''
  },
  image: {
    url: ''
  },
  drivers: [],
  files: []
}

export const FormEnterpriseRecruitment = () => {
  const [data, setData] = useState(INITIAL_VALUES)
  const [success, setSuccess] = useState(false)
  const [termsAndConditions, setTermsAndConditions] = useState(false)
  const theme = useMantineTheme()
  const openRef = useRef(null)

  const { t } = useTranslation()
  const handleFileDelete = (fileToDelete) => {
    setData((prevData) => ({
      ...prevData,
      files: prevData?.files?.filter((file) => file !== fileToDelete) || []
    }))
  }

  const handleFileDeleteCustom = (identifier) => {
    const fileToDelete = data.files?.find(
      (file) => file.identifier === identifier
    )

    setData((prevData) => ({
      ...prevData,
      files: prevData?.files?.filter((file) => file !== fileToDelete) || []
    }))
  }

  const readFileAsBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.readAsDataURL(file)
    })
  }

  const handleFileUpload = async (newFiles, setData, prevData, identifier) => {
    try {
      const filesData = await Promise.all(
        newFiles.map(async (newFile) => {
          const base64Data = await readFileAsBase64(newFile)
          const base64Parts = base64Data.split(',')

          if (base64Parts.length === 2) {
            return {
              title: identifier,
              fileName: newFile.name,
              fileExtension: newFile.type.split('/')[1],
              url: base64Data,
              base64: base64Parts[1],
              identifier
            }
          }
        })
      )

      const existingFiles = prevData.files.filter(
        (file) => file.identifier !== identifier
      )
      const newFilesData = filesData.filter(Boolean)

      setData({
        ...prevData,
        files: [...existingFiles, ...newFilesData]
      })
    } catch (error) {
      console.error('Erro ao ler os arquivos como base64', error)
    }
  }

  const onSave = () => {
    if (!termsAndConditions) {
      toast.error(t('toast.termsAndConditionsRule'))
      return
    }

    data.files.map((item) => {
      if (
        !item.identifier === 'frota' ||
        !item.identifier === 'contrato' ||
        !item.identifier === 'comprovativo-de-iban'
      ) {
        toast.error(t('toast.missingFiles'))
        return
      }
    })

    if (
      !data.name ||
      !data.email ||
      !data.nif ||
      !data.user.name ||
      !data.user.email ||
      !data.user.phoneNumber ||
      !data.user.taxNumber ||
      !data.image.url ||
      data.drivers.length < 0
    ) {
      toast.error(t('toast.fulfillAllFields'))
      return
    }

    const filesPromises = []
    if (data?.image && data?.image?.base64 && !data?.image?.id && data.user) {
      const profilePictureData = {
        title: data?.image?.title,
        base64: data?.image?.base64,
        fileName: data?.image?.fileName,
        fileExtension: data?.image?.fileExtension
      }
      const createProfilePicturePromise = fetchFile
        .create(profilePictureData)
        .then((res) => {
          data.user.image = res?.data?.id
        })
        .catch((err) => console.log(err))

      filesPromises.push(createProfilePicturePromise)
    }
    if (
      data?.image &&
      data?.image?.base64 &&
      !data?.image?.id &&
      data.drivers.length > 0
    ) {
      const profilePictureData = {
        title: data?.image?.title,
        base64: data?.image?.base64,
        fileName: data?.image?.fileName,
        fileExtension: data?.image?.fileExtension
      }
      const createProfilePicturePromise = data.drivers.map((item, index) =>
        fetchFile
          .create(profilePictureData)
          .then((res) => {
            data.drivers[index].profilePicture = res?.data.id
          })
          .catch((err) => console.log(err))
      )

      filesPromises.push(createProfilePicturePromise)
    }
    if (data?.image && data?.image?.base64 && !data?.image?.id) {
      const profilePictureData = {
        title: data?.image?.title,
        base64: data?.image?.base64,
        fileName: data?.image?.fileName,
        fileExtension: data?.image?.fileExtension
      }
      const createProfilePicturePromise = fetchFile
        .create(profilePictureData)
        .then((res) => {
          data.image.id = res?.data?.id
        })
        .catch((err) => console.log(err))

      filesPromises.push(createProfilePicturePromise)
    }

    if (data.files && data.files.length > 0) {
      data.files.forEach((file, index) => {
        if (file.base64 && !file.id) {
          const fileData = {
            title: file.title,
            base64: file.base64,
            fileName: file.fileName,
            fileExtension: file.fileExtension
          }
          const createFilePromise = fetchFile
            .create(fileData)
            .then((res) => {
              data.files[index].id = res.data.id

              return res.data
            })
            .catch((err) => console.log(err))
          filesPromises.push(createFilePromise)
        }
      })
    }
    const allFilesPromise = Promise.all(filesPromises)

    allFilesPromise
      .then((res) => {
        let files = data.files.map((obj) => {
          let newObj = { ...obj }
          delete newObj.base64
          delete newObj.url
          return newObj
        })
        let image = data.image
        delete image.url
        delete image.base64
        const payload = {
          active: false,
          name: data.name,
          email: data.email,
          nif: data.nif,
          image: data.image,
          files: files,
          commissionPercentage: 1,
          ivaPercentage: 1,
          address: {
            coutry: '',
            state: '',
            city: '',
            district: '',
            street: '',
            postalCode: ''
          }
        }

        if (data.user || data.drivers.length > 0) {
          fetchCompany
            .createCompany(payload)
            .then((res) => {
              const accountsPromisses = []
              const adminPayload = {
                active: false,
                name: data.user.name,
                profilePicture: {
                  id: data.user.image
                },
                email: data.user.email,
                phoneNumber: data.user.phoneNumber,
                taxNumber: data.user.taxNumber,
                role: 'COMPANY_ADMIN',
                password: '123456',
                dateOfBirth: '2024-12-12T00:00:00.000Z',
                company: {
                  id: res.data.id
                }
              }
              const driverPromise = data.drivers.map((driver) => {
                const driverPayload = {
                  active: false,
                  name: driver.name,
                  email: driver.email,
                  profilePicture: {
                    id: driver.profilePicture
                  },
                  phoneNumber: driver.phoneNumber,
                  role: 'COMPANY_USER',
                  password: '123456',
                  taxNumber: '',
                  dateOfBirth: '2024-12-12T00:00:00.000Z',
                  company: {
                    id: res.data.id
                  }
                }

                return fetchAccount.createAccount(driverPayload).then((res) => {
                  if (res) {
                    setData(INITIAL_VALUES)
                  }
                })
              })
              accountsPromisses.push(
                fetchAccount.createAccount(adminPayload).then((res) => {
                  if (res) {
                    setData(INITIAL_VALUES)
                  }
                })
              )
              accountsPromisses.push(driverPromise)

              setSuccess(true)
              setData(INITIAL_VALUES)
              setInputs([])
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          console.log('error')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const [inputs, setInputs] = useState([])

  const handleClick = useCallback(() => {
    setInputs((inputs) => [...inputs, ''])
  }, [])

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const photoData = e.target.result

        setData((prevData) => ({
          ...prevData,
          image: {
            // title: 'string',
            title: file.name,
            fileName: file.name,
            fileExtension: file.type.split('/')[1],
            // fileKey: 'string',
            url: photoData,
            base64: photoData.split(',')[1]
          }
        }))
      }
      console.log(file, 'FILE')
      reader.readAsDataURL(file)
    }
  }

  const fileInputRef = useRef(null)

  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const avatarSize = 150

  const imgStyle = {
    width: avatarSize,
    height: avatarSize,
    borderRadius: '100%',
    cursor: 'pointer'
  }

  return (
    <SitePrincipalCadastroDeMRoot>
      <Navbar />
      <SectionheroParent>
        <Sectionhero>
          <H3Parent>
            <H>{t('formCompany.registrationForm')}</H>
            <H1>
              <H5Txt>
                <OsPrximosPassos
                  dangerouslySetInnerHTML={{
                    __html: t('formCompany.fillWithYourDataAndDocuments')
                  }}
                />
              </H5Txt>
            </H1>
          </H3Parent>
          <ImgIcon alt="" src="/header-driver-form.png" />
        </Sectionhero>
        <FormsDriverContent>
          <Div1>
            <H4Wrapper>
              <H2>{t('formCompany.aboutYou')}</H2>
            </H4Wrapper>
            <UploadParent>
              {data?.image?.url || data?.image?.url ? (
                <img
                  onClick={handleAvatarClick}
                  src={data?.image?.url || data?.image?.url}
                  alt="User"
                  style={imgStyle}
                />
              ) : (
                <Avatar
                  src="/avatar.svg"
                  size={150}
                  onClick={handleAvatarClick}
                  style={{ cursor: 'pointer' }}
                />
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handlePhotoUpload}
                style={{ display: 'none' }}
                ref={fileInputRef}
              />
              <PParent>
                <P>{t('formCompany.uploadYourLogo')}</P>
                <List>
                  <ImgiconParent>
                    <Imgicon1 alt="" src="/imgicon@2x.png" />
                    <H3>{t('formCompany.transparentBackground')}</H3>
                  </ImgiconParent>
                  <ImgiconParent>
                    <Imgicon1 alt="" src="/imgicon@2x.png" />
                    <H3>{t('formCompany.highQuality')}</H3>
                  </ImgiconParent>
                  <ImgiconParent>
                    <Imgicon1 alt="" src="/imgicon@2x.png" />
                    <H3>{t('formCompany.fileFormats')}</H3>
                  </ImgiconParent>
                </List>
              </PParent>
            </UploadParent>
            <RowInputFormsframe>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucideuser2.svg" />
                  <EMail htmlFor="NameInput">
                    <NomeCompleto>{t('formCompany.name')}</NomeCompleto>
                    <Span>*</Span>
                  </EMail>
                </Label>
                <Input
                  id="NameInput"
                  onChange={(e) => {
                    setData((oldstate) => ({
                      ...oldstate,
                      name: e.target.value
                    }))
                  }}
                  value={data.name}
                  placeholder={t('formCompany.companyName')}
                  type="text"
                />
              </InputForms>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucidemail.svg" />
                  <EMail htmlFor="MailInput">
                    <NomeCompleto>E-mail</NomeCompleto>
                    <Span1>*</Span1>
                  </EMail>
                </Label>
                <Input
                  id="MailInput"
                  onChange={(e) => {
                    setData((oldstate) => ({
                      ...oldstate,
                      email: e.target.value
                    }))
                  }}
                  value={data.email}
                  placeholder={t('formCompany.companyEmail')}
                  type="email"
                />
              </InputForms>
              {/* <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucidephone.svg" />
                  <EMail htmlFor="InputPhone2">
                    <NomeCompleto>{t('formCompany.phoneWhatsApp')}</NomeCompleto>
                    <Span1>*</Span1>
                  </EMail>
                </Label>
                <PhoneInput
                  country={'pt'}
                  required
                  onChange={(e) => {
                    setData((oldstate) => ({
                      ...oldstate,
                      phoneNumber: e
                    }))
                  }}
                  value={data.phoneNumber}
                  inputStyle={{
                    width: '100%',
                    height: '48px',
                    borderRadius: '8.45738px',
                    fontSize: '16px'
                  }}
                />
              </InputForms> */}
            </RowInputFormsframe>
            <RowInputFormsframe>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucideusersquare.svg" />
                  <EMail htmlFor="InputNIf">
                    <NomeCompleto>NIF</NomeCompleto>
                    <Span1>*</Span1>
                  </EMail>
                </Label>
                <Input
                  onChange={(e) => {
                    setData((oldstate) => ({
                      ...oldstate,
                      nif: e.target.value
                    }))
                  }}
                  value={data.nif}
                  id="InputNIf"
                  placeholder="0000000"
                />
              </InputForms>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucideusersquare.svg" />
                  <EMail htmlFor="LicenseInput">
                    <NomeCompleto>
                      {t('formCompany.howManyDrivers')}
                    </NomeCompleto>
                    <Span1>*</Span1>
                  </EMail>
                </Label>
                <Input
                  type="number"
                  onChange={(e) => {
                    setData((oldstate) => ({
                      ...oldstate,
                      quantityDrivers: e.target.value
                    }))
                  }}
                  value={data.quantityDrivers}
                  placeholder="0"
                />
              </InputForms>
            </RowInputFormsframe>
          </Div1>
          <Div1>
            <H4Wrapper>
              <H6>{t('formCompany.aboutRepresentative')}</H6>
            </H4Wrapper>
            <RowInputFormsframe>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucideusersquare.svg" />
                  <EMail htmlFor="InputNIf">
                    <NomeCompleto>{t('formCompany.fullName')}</NomeCompleto>
                    <Span1>*</Span1>
                  </EMail>
                </Label>
                <Input
                  onChange={(e) => {
                    setData((oldstate) => ({
                      ...oldstate,
                      user: {
                        ...oldstate.user,
                        name: e.target.value
                      }
                    }))
                  }}
                  value={data.user.name}
                  id="InputNIf"
                  placeholder={t('formCompany.enterFullName')}
                />
              </InputForms>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucideusersquare.svg" />
                  <EMail htmlFor="InputNIf">
                    <NomeCompleto>E-mail</NomeCompleto>
                    <Span1>*</Span1>
                  </EMail>
                </Label>
                <Input
                  onChange={(e) => {
                    setData((oldstate) => ({
                      ...oldstate,
                      user: {
                        ...oldstate.user,
                        email: e.target.value
                      }
                    }))
                  }}
                  value={data.user.email}
                  id="InputNIf"
                  placeholder={t('formCompany.enterBestEmail')}
                />
              </InputForms>
            </RowInputFormsframe>
            <RowInputFormsframe>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucidephone.svg" />
                  <EMail htmlFor="InputPhone2">
                    <NomeCompleto>
                      {t('formCompany.phoneWhatsApp')}
                    </NomeCompleto>
                    <Span1>*</Span1>
                  </EMail>
                </Label>
                <PhoneInput
                  country={'pt'}
                  required
                  onChange={(e) => {
                    setData((oldstate) => ({
                      ...oldstate,
                      user: {
                        ...oldstate.user,
                        phoneNumber: e
                      }
                    }))
                  }}
                  value={data.user.phoneNumber}
                  inputStyle={{
                    width: '100%',
                    height: '48px',
                    borderRadius: '8.45738px',
                    fontSize: '16px'
                  }}
                />
              </InputForms>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucideusersquare.svg" />
                  <EMail htmlFor="InputNIf">
                    <NomeCompleto>NIF</NomeCompleto>
                    <Span1>*</Span1>
                  </EMail>
                </Label>
                <Input
                  onChange={(e) => {
                    setData((oldstate) => ({
                      ...oldstate,
                      user: {
                        ...oldstate.user,
                        taxNumber: e.target.value
                      }
                    }))
                  }}
                  value={data.user.taxNumber}
                  id="InputNIf"
                  placeholder="0000000"
                />
              </InputForms>
            </RowInputFormsframe>
          </Div1>
          <Div1>
            <H4Wrapper>
              <H6>{t('formCompany.yourVehicle')}</H6>
            </H4Wrapper>
            {/* <RowInputFormsframe>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucidecar.svg" />
                  <EMail htmlFor="MarkCarInput">
                    <NomeCompleto>{t('formDriver.brand')}</NomeCompleto>
                    <Span1>*</Span1>
                  </EMail>
                </Label>
                <Select required={true} autoFocus={true} id="MarkCarInput">
                  <Sim value="1">Abarth</Sim>
                  <Sim value="2">Acura</Sim>
                  <Sim value="3">Aiways</Sim>
                  <Sim value="4">Aixam</Sim>
                  <Sim value="5">Alfa Romeo</Sim>
                  <Sim value="6">Alpina</Sim>
                  <Sim value="7">Alpine</Sim>
                  <Sim value="8">Aston Martin</Sim>
                  <Sim value="9">Audi</Sim>
                  <Sim value="10">Austin</Sim>
                  <Sim value="11">Austin Healey</Sim>
                  <Sim value="12">Austin Morris</Sim>
                  <Sim value="13">Autobiachi</Sim>
                  <Sim value="14">Bedford</Sim>
                  <Sim value="15">Bellier</Sim>
                  <Sim value="16">Bentley</Sim>
                  <Sim value="17">BMW</Sim>
                  <Sim value="18">Bugatti</Sim>
                  <Sim value="19">Buick</Sim>
                  <Sim value="20">BYD</Sim>
                  <Sim value="21">Cadillac</Sim>
                  <Sim value="22">Casalini</Sim>
                  <Sim value="23">Caterham</Sim>
                  <Sim value="24">Chatenet</Sim>
                  <Sim value="25">Chevrolet</Sim>
                  <Sim value="26">Chrysler</Sim>
                  <Sim value="27">Citroën</Sim>
                  <Sim value="28">Cupra</Sim>
                  <Sim value="29">Dacia</Sim>
                  <Sim value="30">Daewoo</Sim>
                  <Sim value="31">Daihatsu</Sim>
                  <Sim value="32">Datsun</Sim>
                  <Sim value="33">Devinci</Sim>
                  <Sim value="34">Dodge</Sim>
                  <Sim value="35">DS</Sim>
                  <Sim value="36">e.GO</Sim>
                  <Sim value="37">Ferrari</Sim>
                  <Sim value="38">Fiat</Sim>
                  <Sim value="39">Fisker</Sim>
                  <Sim value="40">Ford</Sim>
                  <Sim value="41">GMC</Sim>
                  <Sim value="42">Hillman</Sim>
                  <Sim value="43">Honda</Sim>
                  <Sim value="44">Hummer</Sim>
                  <Sim value="45">Hyundai</Sim>
                  <Sim value="46">Isuzu</Sim>
                  <Sim value="47">Iveco</Sim>
                  <Sim value="48">Jaguar</Sim>
                  <Sim value="49">Jeep</Sim>
                  <Sim value="50">JaguarSport</Sim>
                  <Sim value="51">Jiayuan</Sim>
                  <Sim value="52">JDM</Sim>
                  <Sim value="53">Kaiser</Sim>
                  <Sim value="54">Kia</Sim>
                  <Sim value="55">Lada</Sim>
                  <Sim value="56">Lamborghini</Sim>
                  <Sim value="57">Lancia</Sim>
                  <Sim value="58">Land Rover</Sim>
                  <Sim value="59">Ligier</Sim>
                  <Sim value="60">Lexus</Sim>
                  <Sim value="61">Lotus</Sim>
                  <Sim value="62">Man</Sim>
                  <Sim value="63">Lincoln</Sim>
                  <Sim value="64">Maserati</Sim>
                  <Sim value="65">Maybach</Sim>
                  <Sim value="66">Mazda</Sim>
                  <Sim value="67">Mclaren</Sim>
                  <Sim value="68">Mercedes-Benz</Sim>
                  <Sim value="69">MG</Sim>
                  <Sim value="70">Microcar</Sim>
                  <Sim value="71">MINI</Sim>
                  <Sim value="72">Mitsubishi</Sim>
                  <Sim value="73">Morgan</Sim>
                  <Sim value="74">Morris</Sim>
                  <Sim value="75">Nissan</Sim>
                  <Sim value="76">NSU</Sim>
                  <Sim value="77">Opel</Sim>
                  <Sim value="78">Outra não listada</Sim>
                  <Sim value="79">Peugeot</Sim>
                  <Sim value="80">Polestar</Sim>
                  <Sim value="81">Porsche</Sim>
                  <Sim value="82">Pontiac</Sim>
                  <Sim value="83">Renault</Sim>
                  <Sim value="84">Riley</Sim>
                  <Sim value="85">Rolls Royce</Sim>
                  <Sim value="86">Rover</Sim>
                  <Sim value="87">Saab</Sim>
                  <Sim value="88">Seat</Sim>
                  <Sim value="89">Shelby</Sim>
                  <Sim value="90">Skoda</Sim>
                  <Sim value="91">Smart</Sim>
                  <Sim value="92">SsangYong</Sim>
                  <Sim value="93">Subaru</Sim>
                  <Sim value="94">Suzuki</Sim>
                  <Sim value="95">Tata</Sim>
                  <Sim value="96">Tesla</Sim>
                  <Sim value="97">Toyota</Sim>
                  <Sim value="98">Triumph</Sim>
                  <Sim value="99">UMM</Sim>
                  <Sim value="100">VW</Sim>
                  <Sim value="101">Volvo</Sim>
                </Select>
              </InputForms>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucidecar.svg" />
                  <EMail1 htmlFor="ModelCardInput">
                    <Modelo>Modelo</Modelo>
                    <Span2>*</Span2>
                  </EMail1>
                </Label>
                <Input
                  id="ModelCardInput"
                  placeholder="Digite o modelo do carro"
                  type="text"
                  onChange={(e) => {
                    setData((oldstate) => ({
                      ...oldstate,
                      model: e.target.value
                    }))
                  }}
                  value={data.model}
                />
              </InputForms>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucidecar.svg" />
                  <EMail1 htmlFor="PlacaInput">
                    <Modelo>{t('formDriver.licensePlate)}</Modelo>
                    <Span2>*</Span2>
                  </EMail1>
                </Label>
                <Input
                  id="PlacaInput"
                  onChange={(e) => {
                    setData((oldstate) => ({
                      ...oldstate,
                      registration: e.target.value
                    }))
                  }}
                  value={data.registration}
                  placeholder={t('formDriver.licensePlate)}
                  type="text"
                />
              </InputForms>
            </RowInputFormsframe>
            <RowInputFormsframe>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucidecar.svg" />
                  <EMail1 htmlFor="insurencyInput">
                    <Modelo>{`{t('formDriver.licensePlate)} `}</Modelo>
                    <Span2>*</Span2>
                  </EMail1>
                </Label>
                <Input
                  id="insurencyInput"
                  placeholder="00/00/0000"
                  type="date"
                />
              </InputForms>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucidecar.svg" />
                  <EMail1 htmlFor="OwnCarInput">
                    <Modelo>Veículo próprio?</Modelo>
                    <Span2>*</Span2>
                  </EMail1>
                </Label>
                <Select required={true} id="OwnCarInput">
                  <Sim value="1">Sim</Sim>
                  <Sim value="0">Não</Sim>
                </Select>
              </InputForms>
              <InputForms>
                <Label>
                  <Imgicon alt="" src="/lucidecar.svg" />
                  <EMail1 htmlFor="ColorInput">
                    <Modelo>{t('formDriver.color')}</Modelo>
                    <Span2>*</Span2>
                  </EMail1>
                </Label>
                <Input
                  id="ColorInput"
                  placeholder="{t('formDriver.color')} do veículo"
                  type="text"
                  onChange={(e) => {
                    setData((oldstate) => ({
                      ...oldstate,
                      color: e.target.value
                    }))
                  }}
                  value={data.color}
                />
              </InputForms>
            </RowInputFormsframe> */}
            <ContainerParent>
              <Container1>
                <CustomDropzone
                  openRef={openRef}
                  onDrop={(newFiles) => {
                    handleFileUpload(newFiles, setData, data, 'frota')
                  }}
                  identifier="frota"
                  files={data.files}
                  theme={theme}
                  classes={classes}
                  customDelete
                  // handleDelete={handleFileDelete}
                  name="Carta de condução (Frente e Versos)"
                  maxFiles={2}
                  // disabled={disabled}
                  // data={data}
                  // setData={setData}
                />
                <FrameParent>
                  <H5Group>
                    <H9>
                      <H5Txt>
                        <FotoDoInterior>
                          {t('formCompany.fleetPhoto')}
                        </FotoDoInterior>
                        <Span2>*</Span2>
                      </H5Txt>
                    </H9>
                    {data.files.find((item) => item.identifier === 'frota') && (
                      <Imgicon2
                        alt=""
                        src="/imgicon.svg"
                        onClick={() => {
                          handleFileDeleteCustom('frota')
                        }}
                      />
                    )}
                  </H5Group>
                  <H8>{t('formCompany.acceptedFileTypes')}</H8>
                </FrameParent>
              </Container1>
            </ContainerParent>
            {/* <FrameContainer>
              <LabelParent>
                <Label1>
                  <Lucideusers2Icon alt="" src="/lucideusers2.svg" />
                  <Passageiros>Passageiros</Passageiros>
                </Label1>
                <NumberContainer>
                  <AddPassenger
                    responsive
                    setValue={(value) => {
                      setData((prevData) => ({
                        ...prevData,
                        vehicle: {
                          ...prevData.vehicle,
                          passengerCapacity: value
                        }
                      }))
                    }}
                    value={data?.vehicle?.passengerCapacity}
                    name="backPack"
                    width="100%"
                  />
                </NumberContainer>
              </LabelParent>
              <LabelGroup>
                <Label1>
                  <Lucideusers2Icon alt="" src="/lucidebriefcase.svg" />
                  <BagagemDespachada htmlFor="Luggage1">
                    <Bagagem>Bagagem</Bagagem>
                    <Bagagem>Despachada</Bagagem>
                  </BagagemDespachada>
                </Label1>
                <NumberContainer>
                  <AddPassenger
                    responsive
                    setValue={(value) => {
                      setData((prevData) => ({
                        ...prevData,
                        vehicle: {
                          ...prevData.vehicle,
                          luggageCapacity: value
                        }
                      }))
                    }}
                    value={data?.vehicle?.luggageCapacity}
                    name="luggageCapacity"
                    width="100%"
                  />
                </NumberContainer>
              </LabelGroup>
              <LabelGroup>
                <Label2>
                  <Lucideusers2Icon alt="" src="/lucideluggage.svg" />
                  <BagagemDespachada htmlFor="Luggage2">
                    Bagagem de mão
                  </BagagemDespachada>
                </Label2>
                <NumberContainer>
                  <AddPassenger
                    responsive
                    setValue={(value) => {
                      setData((prevData) => ({
                        ...prevData,
                        vehicle: {
                          ...prevData.vehicle,
                          handLuggageCapacity: value
                        }
                      }))
                    }}
                    value={data?.vehicle?.handLuggageCapacity}
                    name="backPack"
                    width="100%"
                  />
                </NumberContainer>
              </LabelGroup>
              <LabelGroup>
                <Label2>
                  <Lucideusers2Icon alt="" src="/lucidebackpack.svg" />
                  <BagagemDespachada htmlFor="Luggage3">
                    Mochila
                  </BagagemDespachada>
                </Label2>
                <NumberContainer>
                  <AddPassenger
                    responsive
                    setValue={(value) => {
                      setData((prevData) => ({
                        ...prevData,
                        vehicle: {
                          ...prevData.vehicle,
                          backpackCapacity: value
                        }
                      }))
                    }}
                    value={data?.vehicle?.backpackCapacity}
                    name="backPack"
                    width="100%"
                  />
                </NumberContainer>
              </LabelGroup>
            </FrameContainer> */}
          </Div1>
          <Div1>
            <H4Wrapper>
              <H6>{t('formCompany.drivers')}</H6>
              <div
                onClick={() => handleClick()}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  cursor: 'pointer'
                }}
              >
                <Imgicon alt="" src="/plusicon.svg" />

                <span>{t('formCompany.addNew')}</span>
              </div>
            </H4Wrapper>
            <div
              style={{
                width: '100%'
              }}
            >
              {inputs.map((_, i) => (
                <InputsWrapper key={i}>
                  <RowInputFormsframe
                    style={{
                      width: '100%'
                    }}
                  >
                    <InputForms>
                      <Label>
                        <Imgicon alt="" src="/lucideuser2.svg" />
                        <EMail htmlFor={`NameInput${i}`}>
                          <NomeCompleto>{t('formCompany.name')}</NomeCompleto>
                        </EMail>
                      </Label>
                      <Input
                        id={`NameInput${i}`}
                        onChange={(e) => {
                          setData((oldstate) => {
                            const newDrivers = [...oldstate.drivers]
                            newDrivers[i] = {
                              ...newDrivers[i],
                              name: e.target.value
                            }
                            return {
                              ...oldstate,
                              drivers: newDrivers
                            }
                          })
                        }}
                        value={data.drivers[i]?.name || ''}
                        placeholder={t('formCompany.enterFullName')}
                        type="text"
                      />
                    </InputForms>
                    <InputForms>
                      <Label>
                        <Imgicon alt="" src="/lucidemail.svg" />
                        <EMail htmlFor={`MailInput${i}`}>
                          <NomeCompleto>E-mail</NomeCompleto>
                        </EMail>
                      </Label>
                      <Input
                        id={`MailInput${i}`}
                        onChange={(e) => {
                          setData((oldstate) => {
                            const newDrivers = [...oldstate.drivers]
                            newDrivers[i] = {
                              ...newDrivers[i],
                              email: e.target.value
                            }
                            return {
                              ...oldstate,
                              drivers: newDrivers
                            }
                          })
                        }}
                        value={data.drivers[i]?.email || ''}
                        placeholder={t('formCompany.enterBestEmail')}
                        type="email"
                      />
                    </InputForms>
                    <InputForms>
                      <Label>
                        <Imgicon alt="" src="/lucidephone.svg" />
                        <EMail htmlFor={`InputPhone${i}`}>
                          <NomeCompleto>
                            {t('formCompany.phoneWhatsApp')}
                          </NomeCompleto>
                          <Span1>*</Span1>
                        </EMail>
                      </Label>
                      <PhoneInput
                        country={'pt'}
                        required
                        onChange={(e) => {
                          setData((oldstate) => {
                            const newDrivers = [...oldstate.drivers]
                            newDrivers[i] = {
                              ...newDrivers[i],
                              phoneNumber: e
                            }
                            return {
                              ...oldstate,
                              drivers: newDrivers
                            }
                          })
                        }}
                        value={data.drivers[i]?.phoneNumber || ''}
                        inputStyle={{
                          width: '100%',
                          height: '48px',
                          borderRadius: '8.45738px',
                          fontSize: '16px'
                        }}
                      />
                    </InputForms>
                  </RowInputFormsframe>
                </InputsWrapper>
              ))}
            </div>
          </Div1>
          <Div1>
            <H4Wrapper>
              <H11>{t('formCompany.documents')}</H11>
            </H4Wrapper>
            <ContainerGroup>
              <Container3>
                <CustomDropzone
                  openRef={openRef}
                  onDrop={(newFiles) => {
                    handleFileUpload(newFiles, setData, data, 'contrato')
                  }}
                  identifier="contrato"
                  files={data.files}
                  theme={theme}
                  classes={classes}
                  customDelete
                  // handleDelete={handleFileDelete}
                  name="Carta de condução (Frente e Versos)"
                  maxFiles={2}
                  // disabled={disabled}
                  // data={data}
                  // setData={setData}
                />
                <FrameParent>
                  <H5Group>
                    <H5DeConduo>
                      <Bagagem>
                        <FotoDoInterior>
                          {t('formCompany.contract')}
                        </FotoDoInterior>
                        <Span2>*</Span2>
                      </Bagagem>
                    </H5DeConduo>
                    {data.files.find(
                      (item) => item.identifier === 'contrato'
                    ) && (
                      <Imgicon2
                        alt=""
                        src="/imgicon.svg"
                        onClick={() => {
                          handleFileDeleteCustom('contrato')
                        }}
                      />
                    )}
                  </H5Group>
                  <H8>{t('formCompany.acceptedDocumentTypes')}</H8>
                  <H8>{t('formCompany.maxSize')}</H8>
                </FrameParent>
              </Container3>
              <Container4>
                <CustomDropzone
                  openRef={openRef}
                  onDrop={(newFiles) => {
                    handleFileUpload(
                      newFiles,
                      setData,
                      data,
                      'comprovativo-de-iban'
                    )
                  }}
                  identifier="comprovativo-de-iban"
                  files={data.files}
                  theme={theme}
                  classes={classes}
                  customDelete
                  // handleDelete={handleFileDelete}
                  name="Carta de condução (Frente e Versos)"
                  maxFiles={2}
                  // disabled={disabled}
                  // data={data}
                  // setData={setData}
                />
                <FrameParent>
                  <H5Group>
                    <H12>
                      <FotoDoInterior>
                        {t('formCompany.ibanProof')}
                      </FotoDoInterior>
                      <Span2>*</Span2>
                    </H12>
                    {data.files.find(
                      (item) => item.identifier === 'comprovativo-de-iban'
                    ) && (
                      <Imgicon2
                        alt=""
                        src="/imgicon.svg"
                        onClick={() => {
                          handleFileDeleteCustom('comprovativo-de-iban')
                        }}
                      />
                    )}
                  </H5Group>
                  <LucidealertCircleParent></LucidealertCircleParent>
                  <H8>{t('formCompany.acceptedDocumentTypes')}</H8>
                  <H8>{t('formCompany.maxSize')}</H8>
                </FrameParent>
              </Container4>
            </ContainerGroup>
          </Div1>
          <Div4>
            <ChecboxeComLabel1>
              <Checkbox1
                onChange={(e) => setTermsAndConditions(e)}
                value={termsAndConditions}
                type="checkbox"
              />
              <Label4>
                <ConcordoComOs>{t('formCompany.agreeTerms')}</ConcordoComOs>
                <Span3>*</Span3>
              </Label4>
            </ChecboxeComLabel1>
          </Div4>
          <ButtonWrapper>
            <Button onClick={() => onSave()}>
              <ConheaAFrota>{t('formCompany.signup')}</ConheaAFrota>
              <Lucideusers2Icon alt="" src="/lucidearrowupright.svg" />
            </Button>
          </ButtonWrapper>
          {success && (
            <Container6>
              <ImgIcon2 alt="" src="/iconcheck.svg" />
              <H5Parent1>
                <H14>Sua inscrição foi enviada com sucesso!</H14>
                <H15>
                  <FiqueAtentoAoSeuEMail>
                    {`Fique atento ao seu `}
                    <EMail2>e-mail</EMail2>
                  </FiqueAtentoAoSeuEMail>
                  <FiqueAtentoAoSeuEMail1>
                    , pois receberá novas instruções para os próximos passos da
                    sua inscrição connosco!
                  </FiqueAtentoAoSeuEMail1>
                </H15>
              </H5Parent1>
            </Container6>
          )}
        </FormsDriverContent>
      </SectionheroParent>
      <Footer />
    </SitePrincipalCadastroDeMRoot>
  )
}
