import styled from 'styled-components'

export const Container = styled.div`
  padding: 24px 32px 24px 32px;
  gap: 16px;
`
export const addStopBtn = styled.button`
  max-width: 220px;
  max-height: 48px;
  padding: 24px 32px 24px 32px;
  gap: 16px;

  background-color: #27c7ff;

  border-radius: 8px;

  color: #ffff;
  font-family: Roboto;
  font-weight: 700;
  font-size: 16px;
  line-height: 19.2px;
  text-align: center;
`
