import * as Styled from './styles'
import { ReactComponent as Menu } from './images/menu.svg'
import easyTransferLogo from './images/EasyTransfer - logo oficial.svg'
import { LogOut } from 'lucide-react'
import { useAuth } from '../../contexts/useAuth'
export const NavbarDashboard = ({ setCardVisible }) => {
  const toggleCardVisibility = () => {
    setCardVisible((prevState) => !prevState)
  }

  const { signOut } = useAuth()

  return (
    <Styled.Container>
      <Styled.Navbar>
        <img src={easyTransferLogo} />
        <Styled.LogOutButton onClick={() => signOut()}>
          Sair <LogOut width={24} height={24} color="#1D95BF" />
        </Styled.LogOutButton>
        <Styled.MenuMobile>
          <Menu onClick={toggleCardVisibility} />
        </Styled.MenuMobile>
      </Styled.Navbar>
    </Styled.Container>
  )
}
