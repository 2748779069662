import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 147px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  gap: 16px;

  background: #ffffff;

  box-shadow: 0px -1.40956px 22.553px rgba(0, 0, 0, 0.05),
    0px 31.0104px 33.8295px rgba(0, 0, 0, 0.07);
  border-radius: 24px;

  h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    color: #0d0d0d;
  }

  input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12.1898px 8px;
    gap: 81.27px;

    width: 100%;
    height: 4.8rem;

    border: 0.507906px solid #bfbfbf;
    border-radius: 6.09488px;

    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 19px;

    &:focus {
      border-color: #27c7ff;
    }
  }
  .gray path {
    stroke: #808080;
  }
  .coupon {
    margin-right: 20px;
  }

  @media only screen and (max-width: 944px) {
    padding: 1.6rem;
    justify-content: flex-start;
    /* height: 13rem; */
    height: auto;
    box-shadow: 0px 22.34788px 24.37951px 0px rgba(0, 0, 0, 0.07),
      0px -1.01581px 16.25301px 0px rgba(0, 0, 0, 0.05);

    h1 {
      font-size: clamp(1.6rem, 3.8vw, 2rem);
    }

    .mantine-Input-wrapper {
      height: 4.8rem;
      .mantine-Input-input {
        padding-right: 4.8rem;
        font-size: 1.4rem;
        padding-top: 0;
        padding-bottom: 0;
        height: 4.8rem;

        &::placeholder {
          font-size: 1.4rem;
          line-height: 1;
        }
      }
    }
  }
`
