import * as Styled from './styles'
import { ReactComponent as DriverImage } from './imgs/DriverImage.svg'
import { ReactComponent as CloseIcon } from './imgs/CloseIcon.svg'
import { useAuth } from '../../../../../../contexts/useAuth'
import { SelectComponent } from '../../../../../../components/ReserveComponent/components/SelectComponent'
import { useEffect, useState } from 'react'
import fetchAccount from '../../../../../../services/account'

export const AcceptedModal = ({ opened, onClose, onSuccess }) => {
  const { user } = useAuth()

  const [accounts, setAccounts] = useState([])
  const [selectedDriver, setSelectedDriver] = useState()

  useEffect(() => {
    if (opened) {
      if (user.role === 'ADMIN') {
        fetchAccount
          .getAllAccount()
          .then((res) => {
            if (res) {
              res.data.map((account) => {
                setAccounts([
                  {
                    value: account.id,
                    label: account.name
                  }
                ])
              })
            }
          })
          .catch((err) => console.log(err, 'ERR'))
      }
    }
  }, [opened])

  return user.role === 'USER' || user.role === 'COMPANY_USER' ? (
    <>
      <Styled.ModalOverlay opened={opened} onClick={onClose} />
      <Styled.ModalContainer opened={opened}>
        <Styled.ModalHeader>
          <Styled.CloseButton>
            <CloseIcon onClick={onClose} />
          </Styled.CloseButton>
        </Styled.ModalHeader>
        <Styled.ModalContent>
          <DriverImage />
          <Styled.ModalTitle>
            Você tem certeza que aceita fazer este transfer?
          </Styled.ModalTitle>
        </Styled.ModalContent>
        <Styled.ModalButton onClick={() => onSuccess()}>
          SIM, ACEITO
        </Styled.ModalButton>
      </Styled.ModalContainer>
    </>
  ) : (
    user.role === 'ADMIN' && (
      <>
        <Styled.ModalOverlay
          opened={opened}
          onClick={() => {
            setSelectedDriver('')
            onClose()
          }}
        />
        <Styled.ModalContainer opened={opened}>
          <Styled.ModalHeader>
            <Styled.CloseButton>
              <CloseIcon
                onClick={() => {
                  setSelectedDriver('')
                  onClose()
                }}
              />
            </Styled.CloseButton>
          </Styled.ModalHeader>
          <Styled.ModalContentSelect>
            <DriverImage />
            <Styled.ModalTitleSelect>
              Selecione o Motorista que irá fazer a corrida
              <SelectComponent
                style={{ width: '100%', textAlign: 'center' }}
                data={accounts}
                value={selectedDriver}
                placeholder="Selecione um motorista"
                size="xl"
                onChange={(e) => setSelectedDriver(e)}
              />
            </Styled.ModalTitleSelect>
          </Styled.ModalContentSelect>
          <Styled.ModalButton
            onClick={() => onSuccess(selectedDriver, setSelectedDriver)}
          >
            ENVIAR CORRIDA
          </Styled.ModalButton>
        </Styled.ModalContainer>
      </>
    )
  )
}
