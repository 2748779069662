import {
  Component,
  createContext,
  ReactNode,
  useCallback,
  useState
} from 'react'
import { createHtmlPortalNode, HtmlPortalNode } from 'react-reverse-portal'

export const ModalContext = createContext(null)

export default function ModalContextProvider({ children }) {
  const [openedModals, setOpenedModals] = useState([])

  const modalPortalNode = createHtmlPortalNode()

  const openModal = useCallback(
    (type) => {
      if (openedModals.some((i) => i === type)) return

      console.log(type, 'TYPE')

      setOpenedModals((oldState) => [...oldState, type])
    },
    [openedModals]
  )

  const closeModal = useCallback(
    (type) => {
      const updatedOpenedModals = openedModals.map((i) => i)

      if (typeof type === 'object') {
        const updatedModals = updatedOpenedModals.filter(
          (i) => !type.includes(i)
        )

        setOpenedModals(updatedModals)

        return
      }

      const typeIndex = updatedOpenedModals.findIndex((i) => i === type)

      if (typeIndex < 0) return

      updatedOpenedModals.splice(typeIndex, 1)

      setOpenedModals(updatedOpenedModals)
    },
    [openedModals]
  )

  return (
    <ModalContext.Provider
      value={{ openedModals, openModal, closeModal, modalPortalNode }}
    >
      {children}
    </ModalContext.Provider>
  )
}
