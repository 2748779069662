import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'

import { ReactComponent as BadgeEuroIcon } from '../../icons/badge-euro.svg'
import { ChartCard } from '../ChartCard'

import fetchBooking from '../../../../../../services/booking'

import { COLORS, LABELS } from './constants'

export function BookingOriginsChart({ filter }) {
  const [counters, setCounter] = useState(null)

  useEffect(() => {
    fetchBooking
      .getBookingOriginCounters({
        transferDateStartAt: filter?.startDate,
        transferDateEndAt: filter?.endDate
      })
      .then(({ status, data }) => {
        if (status === 200) setCounter(data)
      })
  }, [filter])

  return (
    <ChartCard title="Origem dos bookings" Icon={BadgeEuroIcon}>
      {!!counters && (
        <ReactApexChart
          width={350}
          type="pie"
          series={[counters?.fromSite ?? 0, counters?.fromLandingPages ?? 0]}
          options={{
            legend: { position: 'left' },
            labels: LABELS,
            colors: COLORS
          }}
        />
      )}
    </ChartCard>
  )
}
