import api from './api'

const fetchObj = {
  createCompany: (payload) => {
    const res = api.post('/company', payload)

    return res
  },

  updateCompany: (payload) => {
    const res = api.put('/company', payload)

    return res
  },
  getAllCompany: (payload) => {
    const res = api.get('/company', { params: payload })

    return res
  },
  getById: (id) => {
    const res = api.get(`/company/${id}`)

    return res
  },
  status: (id, active) => {
    const res = api.patch(`/company/toggle-activation/${id}/${active}`)

    return res
  },

  delete: (id) => {
    const res = api.delete(`/company/${id}`)

    return res
  }
}

export default fetchObj
