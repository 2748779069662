import styled from 'styled-components'

export const Container = styled.div`
  .mantine-Tabs-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15.1364px 106.676px;
    gap: 5.77px;
    border-radius: 11.5325px;
    position: relative;

    .warn {
      position: absolute;
      top: -0.6rem;
      left: 2rem;
      width: 2rem;
      height: 2rem;
      font-size: 1rem;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f8294e;
      border-radius: 50%;
    }
  }

  .mantine-Tabs-tabLabel {
    font-style: normal;
    font-weight: 600;
    font-size: 17.2988px;
    line-height: 120%;
    color: #000;
  }

  .mantine-Tabs-tab {
    width: 50%;
  }
  .mantine-Tabs-tabsList {
    gap: 0;
  }

  .mantine-Tabs-tab[data-active] {
    background-color: #27c7ff !important;
  }

  .mantine-Tabs-tab[role='tab'] {
    background: #f7f7f7;
  }

  @media only screen and (max-width: 520px) {
    .mantine-Tabs-tab {
      padding: 1rem 1.4rem;
      border-radius: 0.8rem;
      min-width: 9rem;
    }

    .mantine-Tabs-tabLabel {
      font-size: 1.4rem;
    }
  }
`
