import styled from 'styled-components'

export const Container = styled.div`
  h1 {
    font-family: 'Neometric';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48.01px;
    color: #222222;

    margin: 6rem 0;
    padding-left: 156px;
    padding-bottom: 40px;
    padding-top: 50px;

    width: 100%;

    background: linear-gradient(
      95.8deg,
      #27c7ff -23.21%,
      rgba(255, 255, 255, 0) 75.84%
    );

    @media (max-width: 768px) {
      padding: 0;

      padding-top: 50px;
      padding-bottom: 24px;
      padding-left: 16px;

      font-family: 'Poppins';
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0.01em;
      text-align: left;
    }
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  width: 860px;
  position: relative;
  left: 479px;

  h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;

    color: #38280e;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;

    color: #38280e;
  }
`

export const UlGridHorizontal = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 16px;

  padding-left: 12px;

  width: 100%;

  li {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 120%;

    color: #68645f;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 0px;
  }
`

export const UlGridVertical = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 30px;

  width: 100%;
  padding-left: 12px;

  li {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 130%;

    color: #68645f;
  }

  @media (max-width: 768px) {
    gap: 16px;
  }
`

export const Table = styled.table`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #38280e;

  border-collapse: collapse;

  .left-column {
    width: 200px;
  }

  td,
  th {
    border: 2px solid #b3b3b3;
    padding: 8px;
  }

  tr {
    padding: 24px 13px;

    &:nth-child(3) {
      background-color: #e0e0e0;
    }

    .italic {
      font-style: italic;
      font-size: 14px;
    }
  }

  th {
    padding: 24px 13px;
    text-align: left;
    background-color: #e0e0e0;

    font-weight: 400;
    font-size: 16px;
  }

  div {
    display: flex;
    flex-direction: column;
    gap: 20px;

    padding: 12px;

    ul {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    li {
      margin-left: 24px;
    }
  }
`

export const UlNormalWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;

  font-family: 'Poppins';
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.01em;
  text-align: left;
  color: #222222;

  width: 100%;
  padding-left: 12px;
  margin-bottom: 16px;
`
export const ContentContainer = styled.div`
  width: 100%;

  padding: 0px 156px 0px 156px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  h2 {
    font-family: 'Neometric';
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #222222;

    border-bottom: 0.5px solid #27c7ff;
  }

  p {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #222222;
  }

  @media (max-width: 768px) {
    padding: 0px 16px 0px 16px;
  }
`
export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 4px;

  svg {
    align-self: baseline;
    min-width: 24px;
    min-height: 24px;

    color: #27c7ff;
  }

  h2 {
    font-family: 'Neometric';
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #222222;

    border-bottom: 0.5px solid #27c7ff;
  }

  p {
    font-family: 'Poppins';
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 16.8px;
    letter-spacing: 0.01em;
    text-align: left;
    color: #222222;
  }
`

export const QuestionContainer = styled.div`
  div {
    width: 100%;
  }

  transition: ease-in-out 300ms;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 768px) {
    align-items: center;
  }
`

export const QuestionsWrapper = styled.div`
  width: 100%;
  gap: 8px;
  margin-bottom: 3rem;
`

export const QuestionAndButtonWrapper = styled.div`
  width: 669px;
  padding: 0px 0px 0px 16px;
  height: 40px;
  border: 0.5px solid rgba(56, 40, 14, 0.3);
  border-radius: 6px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Question = styled.p`
  color: #222222;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`
export const QuestionContentWrapper = styled.div`
  width: 669px;
  padding: 0px 0px 0px 16px;
  height: 40px;

  border-radius: 6px;

  display: flex;

  align-items: center;
`

export const QuestionContent = styled.p`
  color: #222222;
  font-family: 'Poppins';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;

  width: 100%;

  margin-bottom: 8px;
`

export const Button = styled.button`
  cursor: pointer;
  color: #fff;
  background: #27c7ff;
  width: 50px;
  height: 50px;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const MainText = styled.p`
  color: #0d0d0d;
  font-weight: 700;
  font-size: 80px;
  line-height: 95px;

  @media only screen and (max-width: 768px) {
    color: var(--black-brand-primary, #0d0d0d);
    text-align: center;
    font-family: Neometric;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`

export const SubText = styled.p`
  color: #0d0d0d;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;

  @media only screen and (max-width: 768px) {
    font-size: 1.6rem;
  }
`
export const ChevronContainer = styled.div`
  padding: 5px;
`

export const NoRefundConditionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const lastElement = styled.p`
  margin-bottom: 150px;

  @media (max-width: 768px) {
    margin-bottom: 64px;
  }
`
