import { Input } from '@mantine/core'
import * as Styled from '../styles'
import { Banknote, Settings } from 'lucide-react'
import Xis from './imgs/xis.webp'
import { useState } from 'react'
import { valueMask } from './masks'

export const FleetConfig = ({ config, handleChangeConfig }) => {
  return (
    <Styled.InputsWrapper>
      <p>
        Insira um valor que represente a porcentagem global de comissão para o
        motorista
      </p>
      <div>
        <Input.Wrapper id="input-demo" label="Porcentagem">
          <Input
            icon={<Banknote />}
            id="input-demo"
            placeholder="10%"
            size={'lg'}
            onChange={(e) => {
              const value = valueMask(e.target.value, true)
              handleChangeConfig('defaultDriverCommission', value)
            }}
            value={config.defaultDriverCommission}
            rightSection={
              !!config.defaultDriverCommission && (
                <img
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    handleChangeConfig('defaultDriverCommission', 0)
                  }
                  width={22.55}
                  height={22.55}
                  src={Xis}
                />
              )
            }
          />
        </Input.Wrapper>
        <Input.Wrapper id="input-demo" label="Porcentagem de IVA">
          <Input
            icon={<Banknote />}
            id="input-demo"
            placeholder="10%"
            size={'lg'}
            onChange={(e) => {
              const value = valueMask(e.target.value, true)
              handleChangeConfig('defaultIvaPercentageForUsers', value)
            }}
            value={config.defaultIvaPercentageForUsers}
            rightSection={
              !!config.defaultIvaPercentageForUsers && (
                <img
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    handleChangeConfig('defaultIvaPercentageForUsers', 0)
                  }
                  width={22.55}
                  height={22.55}
                  src={Xis}
                />
              )
            }
          />
        </Input.Wrapper>
        <Input.Wrapper id="input-demo" label="Quantidade de transfers diários">
          <Input
            icon={<Settings />}
            id="input-demo"
            placeholder="10"
            size={'lg'}
            onChange={(e) => {
              const value = valueMask(e.target.value)
              handleChangeConfig('defaultMaxDailyTransfers', value)
            }}
            value={config.defaultMaxDailyTransfers}
            rightSection={
              !!config.defaultMaxDailyTransfers && (
                <img
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    handleChangeConfig('defaultMaxDailyTransfers', 0)
                  }
                  width={22.55}
                  height={22.55}
                  src={Xis}
                />
              )
            }
          />
        </Input.Wrapper>
      </div>
    </Styled.InputsWrapper>
  )
}
