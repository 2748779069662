import * as Styled from './styles'
import { Navbar } from '../../components/Navbar'
import { TopBanner } from './components/TopBanner'
import { useRef } from 'react'
import { TopBannerResponsive } from './components/TopBannerResponsive'
import { NumbersSection } from './components/NumbersSection'
import { UserReviews } from './components/UserReviews'
import { ExploreOurFleet } from './components/ExploreOurFleet'
import { BudgetRequest } from './components/BudgetRequest'
import Footer from '../../components/Footer'
import { GoogleComments } from '../Home/components/GoogleComments'
// import { Hero } from '../Corporate/components/Hero'
// import { OurFleet } from '../Home/components/OurFleet'
// import { GoogleComments } from './components/GoogleComments'
// import { Form } from './components/Form'
// import Footer from '../../components/Footer'

export const Corporate = () => {
  const budgetRef = useRef(null)

  const setBudgetRef = (ref) => {
    budgetRef.current = ref.current
  }

  return (
    <>
      <Navbar dark />
      <Styled.Container>
        <div>
          <TopBanner ref={budgetRef} />
        </div>
        <div>
          <TopBannerResponsive ref={budgetRef} />
        </div>
        <main>
          <NumbersSection />
          {/* <UserReviews /> */}
          <GoogleComments dark />
          <ExploreOurFleet />
          <BudgetRequest defineBudgetRef={setBudgetRef} />
        </main>
        {/* <Hero />
        <OurFleet dark />
        <Styled.ContentWrapper>
          <GoogleComments />
          <Form />
        </Styled.ContentWrapper>
        <Footer dark /> */}
        <Footer dark />
      </Styled.Container>
    </>
  )
}
