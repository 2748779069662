import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  justify-content: center;

  @media (max-width: 1024px) {
    justify-content: center;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`
export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 24px;

  width: 30%;
  height: 70px;

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    color: #0d0d0d;
  }

  @media (max-width: 1024px) {
    width: 40%;
  }

  @media (max-width: 768px) {
    h2 {
      color: var(--black-brand-primary, #0d0d0d);
      leading-trim: both;
      text-edge: cap;
      font-family: Neometric;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  @media (max-width: 380px) {
    width: 70%;
  }
  /* @media (max-width: 320px) {
    width: 90%;
  } */
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 7rem;
  height: 7rem;

  svg {
    width: 6rem;
    height: 6rem;

    &.small {
      width: 3rem;
      height: 3rem;
    }
  }

  background: #ffffff;
  border-radius: 0.7rem;
  box-shadow: 0px 16px 30px 0px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 768px) {
    width: 4rem;
    height: 4rem;

    svg {
      width: 4rem;
      height: 4rem;

      &.small {
        width: 2.2rem;
        height: 2.2rem;
      }
    }
  }
`
