import styled, { css } from 'styled-components'

import * as CardCouponStyles from './components/CardCoupon/styles'
import * as RouteCardStyles from './components/RouteCard/styles'
import * as OrderSummaryStyles from './components/OrderSummary/styles'
// import * as ChoiceCarStyles from './components/ChoiceCar/styles'

export const Container = styled.div`
  transition: ease-in-out 200ms;
  z-index: 9999;
  margin-top: 13rem;

  /* padding: 0 2rem; */
`
export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  transition: ease-in-out 900ms;
  transform: ${(props) => (props.opened ? 'translateY(0%)' : 'translateY(0%)')};
  z-index: 9999;

  margin: 0 2rem 15.2rem;
`

export const MiddleContainerRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 18px;

  max-width: 988.58px;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  transition: ease-in-out 300ms;
  margin-top: ${(props) => (props.showCars ? '85rem' : '0')};

  @media only screen and (max-width: 944px) {
    flex-direction: column-reverse;
    align-items: center;
    max-width: 720px;
    min-width: 0;
    width: 100%;
  }
`
export const MiddleContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 48px;

  max-width: 988.58px;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 20px;
`

const rightContainerModifiers = {
  showCouponOnly: () => css`
    ${OrderSummaryStyles.Container} {
      display: none;
    }
    ${CardCouponStyles.Container} {
      display: flex;
    }
  `,
  hide: () => css`
    display: none;
  `
}

export const RightContainer = styled.div`
  ${({ showCouponOnly, hide }) => css`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    z-index: 9999;
    width: 100%;

    ${hide && rightContainerModifiers.hide}

    @media only screen and (max-width: 944px) {
      ${CardCouponStyles.Container},
      ${RouteCardStyles.CardWrapper} {
        display: none;
      }

      ${showCouponOnly && rightContainerModifiers.showCouponOnly}
    }
  `}
`

export const ChoiceCarsContainer = styled.div`
  height: ${(props) => (props.showCars ? 'auto' : '0')};
  visibility: ${(props) => (props.showCars ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.showCars ? '1' : '0')};
  transition: height 0.3s ease-in-out, visibility 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
`
