import * as Styled from './styles'

export const SwitchOptions = ({ options, setValue, value }) => {
  const handleChangeOption = (option) => {
    if (option) {
      setValue(option)
    }
  }

  return (
    <Styled.Container>
      <Styled.Options>
        {options.map((item, index) => (
          <Styled.Option
            onClick={() => handleChangeOption(item)}
            key={index}
            first={item.first}
            last={item.last}
            select={item.option === value.option}
          >
            {item.option}
          </Styled.Option>
        ))}
      </Styled.Options>
    </Styled.Container>
  )
}
