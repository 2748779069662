import styled, { css } from 'styled-components'

export const Container = styled.div`
  max-width: fit-content;
`

export const Options = styled.div`
  display: flex;
  height: 48px;
  align-items: flex-start;
  border-radius: 8px;
  border: 0.5px solid var(--grey-3-grayscale, #bfbfbf);
`

export const Option = styled.div`
  ${({ select, first, last }) => css`
    position: relative;
    display: flex;
    gap: 8px;
    width: 120px;
    padding: 12.19px 8px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    cursor: pointer;

    color: ${select
      ? 'var(--black-brand-primary, #222)'
      : 'var(--grey-2-grayscale, #808080)'};
    background-color: ${select ? '#27c7ff' : ''};
    border-radius: ${first ? '8px 0px 0px 8px' : last ? '0px 8px 8px 0px' : ''};

    font-family: Neometric, sans-serif;
    font-size: 12px;
    line-height: normal;
    white-space: nowrap;

    border-right: ${last ? '' : '0.5px solid #BFBFBF'};

    & > svg {
      transform: scale(0.8);
    }
  `}
`

export const IconWrapper = styled.div`
  transform: scale(0.8);
`

export const FloatingContainer = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: 20%;

  z-index: 10;
  animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @keyframes slide-top {
    0% {
      opacity: 0;
      transform: translate(calc(-50% - 15px), 10px);
    }
    100% {
      opacity: 1;
      transform: translate(calc(-50% - 15px), 0);
    }
  }
`

export const Card = styled.div`
  background-color: #fff;

  border: 1px solid #dee2e6;
  border-radius: 8px;

  padding: 5px;
`
