import styled from 'styled-components'

export const HeroTourSectionParent = styled.section`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px;
  text-align: center;
  font-size: 56px;
  color: #222;
  font-family: Neometric;
`

export const HeroTourSectionInner = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: flex-start;
  justify-content: center;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`

export const HeroTourSection = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 0;
  @media screen and (max-width: 905px) {
    padding-left: 24px;
    padding-top: 100px;
    padding-right: 24px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
  @media screen and (max-width: 600px) {
    /* padding-left: 16px; */
    padding-top: 50px;
    /* padding-right: 16px; */
    box-sizing: border-box;
  }
`

export const SpanParent = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  @media screen and (max-width: 600px) {
    flex: unset;
    align-self: stretch;
  }
`

export const Span = styled.span`
  align-self: stretch;
  position: relative;
  letter-spacing: 0.01em;
  line-height: 120%;

  @media screen and (max-width: 905px) {
    text-align: left;

    font-size: 40px;
    align-self: stretch;
    width: auto;
  }
  @media screen and (max-width: 600px) {
    font-size: 32px;
    align-self: stretch;
    width: auto;
  }
`

export const EncontreOs = styled.b`
  span {
    font-weight: 900;
    color: #27c7ff;
  }
`

export const MelhoresPasseios = styled.span``

export const Paragraph = styled.p`
  margin: 0;
  width: 1128px;
  position: relative;
  font-size: 18px;
  letter-spacing: 0.01em;
  line-height: 120%;
  font-family: Poppins;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 905px) {
    align-self: start;
    text-align: left;
    width: auto;
  }
  @media screen and (max-width: 600px) {
    /* align-self: stretch; */
    width: auto;
  }
`

export const SectionMap = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 50px 156px 0px;
  position: relative;
  gap: 78px;
  text-align: left;
  font-size: 14px;
  color: #fff;
  font-family: Poppins;
  @media screen and (max-width: 905px) {
    padding-left: 24px;
    padding-top: 32px;
    padding-right: 24px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 768px) {
    padding-right: 0;
    padding-left: 0;
  }
  @media screen and (max-width: 600px) {
    /* padding-left: 16px; */
    padding-top: 32px;
    /* padding-right: 16px; */
    box-sizing: border-box;
  }
  @media screen and (min-width: 1240px) and (max-width: 1439px) {
    padding-left: 90px;
    padding-right: 90px;
    box-sizing: border-box;
  }
`

export const BackgroundShape1Icon = styled.img`
  position: absolute;
  top: 12%;
  left: -31%;
  z-index: 0;

  width: 80%;
  height: 100%;
  overflow: hidden;

  margin: 0 !important;

  @media screen and (max-width: 905px) {
    flex: 1;
    position: absolute;
  }
`

export const BackgroundShape2Icon = styled.img`
  position: absolute;
  top: 30%;
  right: -31%;
  z-index: -1;

  width: 80%;
  height: 100%;

  overflow: hidden;

  margin: 0 !important;

  @media screen and (max-width: 905px) {
    flex: 1;
    position: absolute;
  }
  @media screen and (max-width: 600px) {
    flex: 1;
  }
`

export const ImagePortugalAndSpainMapTIcon = styled.img`
  position: relative;
  z-index: 2;
  @media screen and (max-width: 905px) {
    width: 90%;
    height: 90%;
  }
`
