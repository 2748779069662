import React from 'react'
import * as Styled from './styled'
import { EmptyChat } from '../EmptyChat'
import { useChatStore } from '../../context/ChatContext'
import { ChatHeader } from '../ChatHeader'
import { ChatInput } from '../ChatInput'
import { ChatMessages } from '../ChatMessages'

export function ChatContainer() {
  const hasChatOpened = useChatStore((state) => state.hasChatOpened)

  if (!hasChatOpened) {
    return (
      <Styled.Container>
        <EmptyChat />
      </Styled.Container>
    )
  }

  return (
    <Styled.Container>
      <ChatHeader />
      <ChatMessages />
      <ChatInput />
    </Styled.Container>
  )
}
