import {
  createContext as createContextOriginal,
  useEffect,
  useRef
} from 'react'

export const ORIGINAL_PROVIDER = Symbol()

/**
 * doc: CUSTOM CREATE PROVIDER
 * --------------------------------
 *
 * Definição:
 * Utilizado para evitar re-renders ao alterar o estado do context.
 *
 * Explicação técnica:
 * Os dados são salvos como reference e não como estado, evitando
 * qualquer atualização do children. As atualização de estado ficam
 * a cargo do useContextSelector que utiliza de uma reference cache
 * para realizar as comparações e, se necessário, atualiza um estado
 * local com base na função recebida por parâmetro.
 *
 * Justificativa do uso:
 * Adicionado principalmente para comportar as atualizações do chat e
 * seus pollings de dados, garantindo atualizações apenas quando necessário.
 *
 */

function createProvider(OriginalProvider) {
  return function Provider({ value, children }) {
    const valueRef = useRef(value)
    const listenersRef = useRef(new Set())

    const contextValue = useRef({
      value: valueRef,
      registerListener: (listener) => {
        listenersRef.current.add(listener)
        return () => listenersRef.current.delete(listener)
      },
      listeners: new Set()
    })

    useEffect(() => {
      valueRef.current = value
      listenersRef.current.forEach((listener) => {
        listener(value)
      })
    }, [value])

    return (
      <OriginalProvider value={contextValue.current}>
        {children}
      </OriginalProvider>
    )
  }
}

export default function createContext(defaultValue) {
  const context = createContextOriginal({
    value: { current: defaultValue },
    register: () => {
      return () => {}
    }
  })

  context.Provider = createProvider(context.Provider)

  return context
}
