import styled, { css } from 'styled-components'
export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 360px;
  box-shadow: 0px 18.0481px 19.6888px rgba(0, 0, 0, 0.05);
  border: 0.820368px solid rgba(39, 199, 255, 0.2);
  background: #ffff;
  padding: 1.6rem;
  padding-bottom: 2.4rem;
  border-radius: 1rem;
  transition: transform 0.3s ease-in-out;
  transform: scale(1);

  &:hover {
    transform: scale(1.04);
  }

  @media (max-width: 768px) {
    width: auto;
    height: 300px;
  }

  @media (max-width: 630px) {
    width: 220px;
    height: 240px;
  }

  @media (max-width: 460px) {
    width: 170px;
    height: auto;
  }

  @media (max-width: 345px) {
    width: 140px;
    height: auto;
  }
`
export const ImageCar = styled.img`
  width: 70%;
  height: auto;

  @media (max-width: 630px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`

export const Title = styled.h1`
  font-family: 'Neometric';
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #222222;

  margin-top: -25px;
  
  @media (max-width: 768px) {
    margin-top: -10px;
  }
`

export const OptionsContainer = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 1rem;

  @media (max-width: 345px) {
    gap: 0.5rem;
  }
`

export const Passenger = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: 345px) {
    gap: 0;
  }
`

export const Briefcase = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  @media (max-width: 345px) {
    gap: 0;
  }
`

export const Wifi = styled.img``

export const EletricIconContainer = styled.div`
  position: absolute;
  right: 35px;
  top: 80px;

  z-index: -99;

  img {
    width: 107px;
    height: 107px;
    margin-top: -25px;
  }

  /* @media (max-width: 768px) {
    top: 40px;
    right: 25px;
  } */

  @media (max-width: 768px) {
    top: 20px;
    right: 25px;

    img {
      margin-top: 15px;
      width: 56px;
      height: 56px;
    }
  }

  @media (max-width: 460px) {
    right: 15px;
    top: 15px;
    img {
      margin-top: 15px;
      width: 40px;
      height: 40px;
    }
  }
`

export const CarCategoryContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 20px;
  left: 20px;
`

export const CarCategoryTag = styled.div`
  width: fit-content;
  padding: 1rem;
  height: 3rem;
  border-radius: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
     background-color: ${theme.colors.primary};
  `}

  p {
     font-size: 1.2rem;
     font-family: Poppins !important;
     text-transform: uppercase;
     color: #fff;
     font-weight: 500;
     letter-spacing: 0,5%;
     @media (max-width: 768px) {
      font-size: 1rem;
      
     }
  }
`