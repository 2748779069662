import * as Styled from './styles'
import { AlertCircle, CalendarDays } from 'lucide-react'
import { iconDecider } from './constants'
import { DateTimePicker } from '@mantine/dates'
import { useState } from 'react'
import { moneyMask } from '../../../Config/components/CreateCategoryModal/masks'
import {
  generateCardData,
  generatePayload,
  validateHistoricData
} from './utils'
import { useAuth } from '../../../../../../contexts/useAuth'
import fetchTransfer from '../../../../../../services/transfer'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const INITIAL_STATE = {
  amount: {
    value: '0,00',
    formattedValue: '€ 0,00'
  },
  message: '',
  date: ''
}

export const RegisterHistoricItem = ({
  onAddItem,
  loadingState,
  disabled,
  setLoadingState,
  driver
}) => {
  const { user, ROLE } = useAuth()
  const [data, setData] = useState(INITIAL_STATE)
  const handleChange = (field, value) => {
    setData({ ...data, [field]: value })
  }

  const { t } = useTranslation()

  const handleSubmit = async (type) => {
    setLoadingState((oldState) => ({ ...oldState, creatingTransfer: true }))
    try {
      const transferData = {
        ...data,
        type,
        user,
        driver
      }
      const cardData = generateCardData(transferData)
      const payload = generatePayload(transferData)
      await fetchTransfer.createPayment(payload)
      toast.success(t('toast.successfulPayment'))
      onAddItem(cardData)
      setData(INITIAL_STATE)
      setLoadingState((oldState) => ({ ...oldState, creatingTransfer: false }))
    } catch (err) {
      console.log(err)
      toast.error(t('toast.errorGeneratePayment'))
      setLoadingState((oldState) => ({ ...oldState, creatingTransfer: false }))
    }
  }
  return (
    <Styled.RegisterItemWrapper>
      <p>Registre um pagamento ou recebimento</p>

      <input
        disabled={disabled}
        value={data.amount.formattedValue}
        onChange={(e) => handleChange('amount', moneyMask(e.target.value))}
      />

      <Styled.MessageWrapper>
        <p className="label">Mensagem</p>
        <textarea
          disabled={disabled}
          value={data.message}
          onChange={(e) => handleChange('message', e.target.value)}
        />
      </Styled.MessageWrapper>

      <div>
        <p className="label">Data e hora</p>
        <DateTimePicker
          icon={<CalendarDays />}
          disabled={disabled}
          clearable
          placeholder="00/00/00 - 00:00"
          value={data.date}
          onChange={(e) => handleChange('date', e)}
        />
      </div>

      <span>
        <AlertCircle size="1.8rem" color="#808080" />
        <p>Deixe em branco caso queira registrar na data/hora atual</p>
      </span>

      <Styled.ButtonsWrapper>
        <div>
          <button
            onClick={() => handleSubmit('outcome')}
            className="outcome"
            disabled={
              validateHistoricData(data) ||
              loadingState.loadingTransfers ||
              loadingState.creatingTransfer ||
              disabled
            }
          >
            {iconDecider.outcome} <span>Pagar</span>
          </button>
        </div>
        {ROLE === 'ADMIN' && (
          <button
            onClick={() => handleSubmit('charge')}
            className="charge"
            disabled={
              validateHistoricData(data) ||
              loadingState.loadingTransfers ||
              loadingState.creatingTransfer ||
              disabled
            }
          >
            {iconDecider.charge} <span>Cobrar</span>
          </button>
        )}
      </Styled.ButtonsWrapper>
    </Styled.RegisterItemWrapper>
  )
}
