import { useDisclosure } from '@mantine/hooks'
import { Popover, Text, Button } from '@mantine/core'
import QuestionCircle from '../Transfer/imgs/question-circle.webp'

export function PopoverButton({
  icon = QuestionCircle,
  text = 'This popover is shown when user hovers the target element'
}) {
  const [opened, { close, open }] = useDisclosure(false)
  return (
    <Popover
      width={200}
      position="bottom"
      withArrow
      shadow="md"
      opened={opened}
    >
      <Popover.Target>
        <Button
          onMouseEnter={open}
          onMouseLeave={close}
          style={{ background: 'transparent' }}
        >
          <img src={icon} />
        </Button>
      </Popover.Target>
      <Popover.Dropdown
        sx={{ pointerEvents: 'none' }}
        style={{ borderRadius: '8px' }}
      >
        <Text size="lg">{text}</Text>
      </Popover.Dropdown>
    </Popover>
  )
}
