import styled, { css } from 'styled-components'

export const Content = styled.div`
  width: 1160px;
  display: flex;
  gap: 104px;

  @media (max-width: 1200px) {
    gap: 30px;
    width: 96%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

export const ImagesColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  width: 320px;
  height: 142.61px;

  a:first-of-type svg {
    width: 20rem;
  }

  a:last-of-type {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    > a:first-of-type {
      align-self: center;
    }
  }
`

export const ImagesFrame = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 16px;
  gap: 16px;

  width: 266.47px;
  height: 38.61px;

  background: #ffffff;
  box-shadow: 0px 22px 24px rgba(0, 0, 0, 0.07);
  border-radius: 48px;
`

export const ContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;

  @media (max-width: 768px) {
    width: calc(33.33% - 0.8rem);
  }

  @media only screen and (max-width: 570px) {
    &:nth-child(1),
    &:nth-child(2) {
      width: calc(50% - 0.8rem);
    }

    &:nth-child(3) {
      width: 100%;
    }
  }
`

export const ColumnTitle = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  color: #fffefd;
`

export const ColumnLinkItem = styled.a`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  position: relative;

  color: rgba(255, 255, 255, 0.7);

  /* &:hover {
    color: #27c7ff;
  } */
`

export const NotItemsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5vh;
  margin-top: -1vh;
`

export const ColumnNotLinkItem = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: rgba(255, 255, 255, 0.7);
  
`

export const Contact = styled.a`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;

  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  cursor: pointer;

   /* &:hover {
    color: #23b3e6;
  } */

  &:active {
    color: #000;
  }
`

export const MediasWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 19px;

  @media only screen and (max-width: 768px) {
    margin: 0 auto;
  }
`

export const MediaIcon = styled.a`
  cursor: pointer;
  text-decoration: none;

  path {
    fill: #fff;
  }

  /* &:hover {
    path {
      fill: #27c7ff;
    }
  } */
`

export const Separator = styled.div`
  background: rgba(56, 40, 14, 0.1);
  height: 1px;
  width: 60vw;
`

export const Copyright = styled.p`
  font: 400 1rem 'Poppins', sans-serif !important;

  text-align: center;
  margin-bottom: 33px;

  color: #ffffff;
`

export const ContainerColumn = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 768px) {
    width: 96%;
    flex-wrap: wrap;
    gap: 0.8rem;
  }
  @media (max-width: 500px) {
    width: 90%;
  }
`

const containerModifiers = {
  dark: () => css`
    /* background-color: #09090b; */
    background: linear-gradient(
      180deg,
      rgba(251, 176, 59, 0) 15.8%,
      #27c7ff 295.96%
    );
  `
}

export const Container = styled.div`
  ${({ dark }) => css`
    width: 100%;
    height: 100%;

    background: #27c7ff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 75px 0 0 0;
    gap: 2vh;

    ${dark && containerModifiers.dark(dark)}
  `}
`
