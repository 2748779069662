import { motion } from 'framer-motion'
import styled from 'styled-components'

export const CarouselWrapper = styled.section`
  .mantine-Carousel-container {
    align-items: flex-end !important;
  }

  margin-top: 18rem;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;

  color: #0d0d0d;
`

export const CarouselContainer = styled(motion.div)`
  position: relative;
  bottom: 692px;

  button:first-of-type {
  }

  @media (max-width: 1022px) {
    bottom: 90rem;

    .mantine-Carousel-root {
      position: absolute;
      left: 50% !important;
      transform: translateX(-50%);
      width: 80% !important;
      max-width: 70rem !important;
      bottom: 23rem !important;
      top: 0 !important;
      height: 100%;
    }

    .mantine-Carousel-slide img {
      width: 50vw !important;
      height: 50vw !important;

      min-width: 20rem !important;
      min-height: 20rem !important;
      max-width: 30rem !important;
      max-height: 30rem !important;
    }
  }

  @media (max-width: 725px) {
    .mantine-Carousel-root {
      left: 20vw !important;
      transform: none;
    }
  }
`

export const OrangeContainer = styled(motion.div)`
  background: #27c7ff;
  height: 503px;
  width: 55%;
  border-radius: 0px 32px 32px 0px;
  margin: 125px 0 231px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1022px) {
    width: 98%;

    justify-content: flex-end;
    padding-bottom: 4.2rem;
  }
`

export const FirstTitle = styled.h1`
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 120%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
`
export const SecondTitle = styled.h1`
  font-weight: 600;
  font-size: 4.8rem;
  line-height: 57.6px;
  margin-top: -28px;
`
export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`

export const ListItemWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const ListItemText = styled.span`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 19px;
`

export const ScheduleButton = styled.button`
  background: #ffffff;
  cursor: pointer;
  width: 219px;
  height: 64px;
  border-radius: 16px;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 32px;
  gap: 16px;

  transition: ease-in-out 300ms;

  &:hover {
    background-color: #27c7ff;
    color: white;
    border: solid 1px #fff;
    span {
      color: white;
    }

    path {
      stroke: #fff;
    }
  }
`

export const TextWrapper = styled.div`
  width: 551px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 44.68px;

  @media only screen and (max-width: 1022px) {
    width: 96%;
    gap: 0;

    ${FirstTitle} {
      font-size: 1.8rem;
      margin-bottom: 3.2rem;
    }

    ${SecondTitle} {
      font-size: 3.6rem;
      margin-bottom: 2.4rem;
      line-height: 3.6rem;
    }

    ${ListWrapper} {
      margin-bottom: 2.4rem;
    }

    ${ListItemText} {
      font-size: 1.2rem;
    }

    ${ScheduleButton} {
      width: 100%;
    }
  }
`

export const ButtonLabel = styled.span`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

  font-family: 'Roboto', sans-serif;

  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
`
export const Image = styled.img`
  width: 428.01px;
  height: 396.13px;
  border-radius: 14px;
  overflow: hidden;
  /* width: 100%;
  height: 100%; */
`

export const ImageTitle = styled.h3`
  position: absolute;
  left: 18rem;
  bottom: 10rem;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: white;

  @media only screen and (max-width: 1022px) {
    font-size: clamp(1.4rem, 2.5vw, 2rem);
    bottom: 10vw;
    left: 50%;
    transform: translateX(-50%);
    margin-left: -1rem;
  }
`

export const EmphasisTag = styled.div`
  display: flex;
  padding: 8px;
  gap: 8px;

  width: 9.8rem;
  height: 3.5rem;

  background: #27c7ff;
  border-radius: 8px;

  position: absolute;
  top: 16px;
  left: 16px;

  span {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 120%;

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

    color: #0d0d0d;
  }

  @media only screen and (max-width: 1022px) {
    width: 6.4rem;
    height: 2.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4rem;
    padding: 0;

    span {
      font-size: 1rem;
    }
  }
`

// export const HotTag = styled.img`
//   position: absolute;
//   top: 10px;
//   left: 6px;
// `
// export const PopularTag = styled.div`
//   background: linear-gradient(
//     90deg,
//     #1aa040 -49.27%,
//     #40f272 50.28%,
//     #1aa040 149.83%
//   );
//   box-shadow: 2.9337px 2.9337px 8.80111px rgba(0, 0, 0, 0.25);
//   transform: rotate(-45deg);
//   height: 36px;
//   width: 150px;
//   z-index: 9;

//   position: absolute;
//   top: 10px;
//   left: -5rem;

//   display: flex;
//   flex-direction: column;
//   justify-content: center;

//   text-align: center;
// `
