import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  border: 2px solid #f7f7f7;
  padding: 0 16px;

  color: #222;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.18px;

  input:checked + .mantine-Switch-track {
    background-color: #27c7ff;
    border-color: #27c7ff;
  }
`
