import * as Styled from './styles'

import passenger from '../../../../../Fleet/imgs/passenger.svg'
import briefcase from '../../../../../Fleet/imgs/briefcase.svg'
import wifi from '../../../../../Fleet/imgs/wifi.svg'
import { PopoverButton } from '../../../PopoverButton'
import alert from './imgs/alert.webp'
import { useTransferContext } from '../../../../../../contexts/TransferContext'
import { useTranslation } from 'react-i18next'

export const CarCard = ({ car = [], onSelectCar, setShowCars }) => {
  const { going } = useTransferContext()
  const distanceValue = parseFloat(going.distance)
  const { t } = useTranslation()
  return (
    <Styled.Container>
      {car.map((car) => (
        <Styled.CardContainer key={car.title}>
          <Styled.CarContainer>
            <Styled.ImageCar src={car.img} />
            <div>
              <Styled.Title>{car.title}</Styled.Title>
              <Styled.OptionsContainer>
                {car.passengers && (
                  <Styled.Passenger>
                    <img src={passenger} />
                    <p>{car.passengers}</p>
                  </Styled.Passenger>
                )}
                {car.briefcase && (
                  <Styled.Briefcase>
                    <img src={briefcase} />
                    <p>{car.briefcase}</p>
                  </Styled.Briefcase>
                )}
                {car.wifi && <img src={wifi} />}
              </Styled.OptionsContainer>
            </div>
          </Styled.CarContainer>
          <Styled.PriceContainer>
            <h1>
              €
              {going.totalPrice
                ? (car.price * distanceValue).toFixed(2)
                : car.price}
            </h1>
            <p>IVA incl.</p>
            <PopoverButton icon={alert} />
            <Styled.ChoiceButton
              onClick={() => {
                onSelectCar(car)
                setShowCars(false)
                console.log(car, '!!!')
              }}
            >
              {t('select')}
            </Styled.ChoiceButton>
          </Styled.PriceContainer>
        </Styled.CardContainer>
      ))}
    </Styled.Container>
  )
}
