import { ChevronRight } from 'lucide-react'
import * as Styled from '../../styles'
import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

export const ExpandableItem = ({ label, subItems = [], height = 10 }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const { pathname } = useLocation()
  const toggleExpanded = () => {
    setIsExpanded((oldState) => !oldState)
  }
  return (
    <>
      <button onClick={toggleExpanded}>
        <p>{label}</p>
        <ChevronRight
          style={{
            transition: 'transform 0.3s ease-in-out',
            transform: `rotate(${isExpanded ? 90 : 0}deg)`
          }}
        />
      </button>
      <Styled.ExpandedOptionsWrapper
        isExpanded={isExpanded}
        style={{ height: `${height}rem` }}
      >
        {subItems.map((item) =>
          item.blank ? (
            <a
              key={item.url}
              href={item.url}
              // target="_blank"
              style={{
                textDecoration: 'none',
                color:
                  pathname === item.url && !item.disableActive ? '#27c7ff' : ''
              }}
              rel="noreferrer"
            >
              <p>{item.label}</p>
            </a>
          ) : (
            <Link
              key={item.url}
              to={item.url}
              style={{
                textDecoration: 'none',
                color:
                  (pathname === item.url ||
                    (item.activeWhen && item.activeWhen.includes(pathname))) &&
                  !item.disableActive
                    ? '#27c7ff'
                    : ''
              }}
            >
              <p>{item.label}</p>
            </Link>
          )
        )}
      </Styled.ExpandedOptionsWrapper>
    </>
  )
}
