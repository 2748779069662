import styled, { css } from 'styled-components'

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100dvh;

  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;

  padding: 1.6rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c5c5c5;
    border-radius: 1rem;
  }

  scrollbar-width: thin;
  scrollbar-color: #c5c5c5 transparent;
`

export const ModalContainer = styled.div`
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  overflow-x: hidden;

  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-end;
  z-index: 200;

  gap: 10px;
  border-radius: 22px;
  width: 54rem;
  background: #fff;
  box-shadow: 0px 22px 24px 0px rgba(0, 0, 0, 0.07),
    0px -1px 16px 0px rgba(0, 0, 0, 0.05);
  display: flex;

  @media (max-width: 500px) {
    width: 100%;
  }
`

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  > span {
    width: 100%;
    text-align: center;
    padding: 1.2rem 0;
    border-radius: 0.8rem;
    font-family: Neometric;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;

    strong {
      font-weight: 600;
    }
  }
`

export const TotalValue = styled.h1`
  text-align: center;
  color: #000;
  font-family: Neometric;
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  margin: 0.8rem 0;

  strong {
    font-family: Neometric;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 500;
  }
`

export const MessageWrapper = styled.div`
  p {
    color: #222;
    font-family: Neometric;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 1.2rem;
  }

  div {
    padding: 0.8rem;
    border-radius: 8px;
    border: 0.508px solid #bfbfbf;
    min-height: 10rem;

    color: #404040;
    font-family: Neometric;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &.no-message {
      color: #a2a2a2;
      cursor: default;
    }
  }
`

export const TransfersWrapper = styled.div`
  > p {
    color: #222;
    font-family: Neometric;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 1rem;
  }

  ul {
    list-style: none;
    max-height: 20rem;
    overflow: auto;
    padding-right: 0.4rem;

    &::-webkit-scrollbar {
      display: block;
      width: 0.6rem !important;
    }

    &::-webkit-scrollbar-track {
      background: #f7f7f7 !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #27c7ff !important;
      border-radius: 1.6rem !important;
    }

    scrollbar-width: thin !important;
    scrollbar-color: #27c7ff #f7f7f7 !important;

    li {
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.4rem;
      border-bottom: 0.5px solid #bfbfbf;

      div {
        span {
          color: #27c7ff;
          font-family: Neometric;
          font-size: 1.2rem;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 0.16px;

          strong {
            font-weight: 600;
            font-size: 1.4rem;
            text-decoration: underline;
          }
        }
        p {
          color: #404040;
          font-family: Roboto;
          font-size: 1.2rem;
          font-style: normal;
          font-weight: 400;
          margin-top: 0.4rem;
        }
      }

      > span {
        color: #222;
        font-family: Neometric;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;

        strong {
          font-size: 1.6rem;
          font-weight: 700;
        }
      }

      & + li {
        margin-top: 0.8rem;
      }
    }
  }
`

const modalButtonModifiers = {
  outlined: () => css`
    background: transparent;
    border: 1px solid #27c7ff;
    color: #27c7ff;
  `
}

export const ModalButton = styled.button`
  ${({ outlined }) => css`
    flex: 1;
    display: flex;
    max-height: 56px;
    height: 56px;
    padding: 24px 0;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 16px;
    background: var(--yellow-brand-primary, #27c7ff);
    color: var(--white-grayscale, #fff);
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    width: 100%;
    margin: 2.4rem auto 0;

    :disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }

    ${outlined && modalButtonModifiers.outlined()}
  `}
`

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    color: var(--black-brand-primary, #222);
    font-family: Neometric;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.24px;
  }
`

export const CloseButton = styled.button`
  width: 32px;
  height: 32px;
  background-color: transparent;
`

export const ModelImageWrapper = styled.div`
  flex: 1;
  width: 100%;
  border-radius: 0.8rem;
  border: 0.1rem solid #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15rem;

  img {
    width: 10rem;
  }
`
