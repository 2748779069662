import * as S from './styles'
import BagPicture from './imgs/bag.svg'
import PlanePicture from './imgs/plane.svg'
import RightArrow from './imgs/right-arrow.svg'
import TopRightArrow from './imgs/top-right-arrow.svg'
import MailIcon from './imgs/mail-icon.svg'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import fetchMarketing from '../../../../services/marketing'

export const SubscribeSection = () => {
  const { t } = useTranslation()
  const { reset, handleSubmit, register } = useForm()

  const onSubmit = async (data) => {
    try {
      await fetchMarketing.registrateNewEmail(data.email)
    } catch (e) {
      console.log('Save e-mail error: ', e)
    }
    reset({ email: '' })
  }

  return (
    <S.Container>
      <S.Content>
        <S.BagPicture src={BagPicture} />
        <S.PlanePicture src={PlanePicture} />
        <S.Header>
          <h4>{t('subscribe.title')}</h4>
          <S.Button href="#transfer">
            {t('subscribe.tittleButton')}
            <img src={TopRightArrow} />
          </S.Button>
        </S.Header>
        <S.Body onSubmit={handleSubmit(onSubmit)}>
          <span>{t('subscribe.description')}</span>
          <div>
            <div>
              <img src={MailIcon} />
              <input
                placeholder={t('subscribe.inputPlaceHolder')}
                name="email"
                {...register('email')}
              />
            </div>
            <S.Button type="submit">
              {t('subscribe.sendButton')} <img src={RightArrow} />
            </S.Button>
          </div>
        </S.Body>
      </S.Content>
    </S.Container>
  )
}
