import { Select } from '@mantine/core'
import * as Styled from './styles'
import { ChevronDown, ClockIcon } from 'lucide-react'

export const Punishment = ({ data, setData, disabled }) => {
  return (
    <Styled.Container>
      <Styled.Title>Selecione o tempo de punição</Styled.Title>
      <Styled.InputWrapper>
        <Select
          id="select"
          label="Período"
          placeholder="5 min"
          disabled={disabled}
          icon={
            <ClockIcon
              style={{
                margin: '12px 8px'
              }}
            />
          }
          iconWidth={'5rem'}
          data={[
            { value: 0, label: 'Remover punicão' },
            { value: 15, label: '15 min' },
            { value: 30, label: '30 min' },
            { value: 45, label: '30 min' },
            { value: 60, label: '1 hora' },
            { value: 2 * 60, label: '2 horas' },
            { value: 3 * 60, label: '3 horas' },
            { value: 6 * 60, label: '6 horas' },
            { value: 12 * 60, label: '12 horas' },
            { value: 24 * 60, label: '24 horas' },
            { value: 48 * 60, label: '48 horas' }
          ]}
          value={data.durationInMinutes}
          styles={{
            rightSection: { pointerEvents: 'none' }
          }}
          rightSection={<ChevronDown style={{ margin: '12px 8px' }} />}
          rightSectionWidth={'5rem'}
          onChange={(option) =>
            setData((oldState) => ({ ...oldState, durationInMinutes: option }))
          }
        />
        <p>Somente depois desse período será possível realizar transfers.</p>
      </Styled.InputWrapper>
    </Styled.Container>
  )
}
