import * as Styled from './styles'
import { ReactComponent as Driver } from './imgs/driver-icon.svg'
import { ReactComponent as Avatar } from './imgs/avatar.svg'
import { ReactComponent as User } from './imgs/user.svg'
import { ReactComponent as Phone } from './imgs/phone.svg'
import { ReactComponent as Car } from './imgs/car-icon.svg'
import Surf from './imgs/shape.webp'
import BabyChair from './imgs/carseat.webp'
import Pet from './imgs/paw.webp'

import { Luggage, Plane, Users } from 'lucide-react'

export const DetailsDriver = ({ transfer }) => {
  console.log({ transfer })

  return transfer ? (
    <>
      <Styled.CardContainer>
        <Styled.Container key={transfer.id}>
          <Styled.Wrapper>
            <Styled.InfoWrapper>
              <Styled.PaymentTitle>
                <Driver />
                Motorista
              </Styled.PaymentTitle>
            </Styled.InfoWrapper>
            <Styled.PassengerInfoContainer>
              <span>
                <img
                  src="https://images-easytransfer.s3.amazonaws.com/b1ffa853a42e96e669f1030cb0c4c52d-46350bf6c1be9f1b465075e65272c02b.jpg.jpeg"
                  alt="Driver image"
                />
              </span>
              <Styled.PassengerInputs>
                <Styled.PaymentInfo>
                  <Styled.PaymentInfoTitle>
                    <User />
                    Nome
                  </Styled.PaymentInfoTitle>
                  <Styled.PaymentInfoDesc>
                    {transfer.driver.name}
                  </Styled.PaymentInfoDesc>
                </Styled.PaymentInfo>
                <Styled.PaymentInfo>
                  <Styled.PaymentInfoTitle>
                    <Phone />
                    Telemóvel
                  </Styled.PaymentInfoTitle>
                  <Styled.PaymentInfoDesc>
                    <a href={`tel:+${transfer.passengerPhoneNumber}`}>
                      {transfer.driver.phoneNumber}
                    </a>
                  </Styled.PaymentInfoDesc>
                </Styled.PaymentInfo>
                {transfer.driver.vehicle && (
                  <>
                    <Styled.PaymentInfo>
                      <Styled.PaymentInfoTitle>
                        <Car />
                        Automóvel
                      </Styled.PaymentInfoTitle>
                      <Styled.PaymentInfoDesc>
                        {transfer.driver.vehicle.model}
                      </Styled.PaymentInfoDesc>
                    </Styled.PaymentInfo>
                    <Styled.PaymentInfo>
                      <Styled.PaymentInfoTitle>
                        <Car />
                        Placa do Automóvel
                      </Styled.PaymentInfoTitle>
                      <Styled.PaymentInfoDesc>
                        {transfer.driver.vehicle.licensePlate}
                      </Styled.PaymentInfoDesc>
                    </Styled.PaymentInfo>
                    <Styled.PaymentInfo>
                      <Styled.PaymentInfoTitle>
                        <Car />
                        Cor do Automóvel
                      </Styled.PaymentInfoTitle>
                      <Styled.PaymentInfoDesc>
                        {transfer.driver.vehicle.color}
                      </Styled.PaymentInfoDesc>
                    </Styled.PaymentInfo>
                  </>
                )}
              </Styled.PassengerInputs>
              <Styled.PassengerInputsMobile>
                {transfer.numberOfPassengers && (
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <Users width={16} height={16} />
                      Passageiros
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>
                      {transfer.numberOfPassengers}
                    </Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                )}
                {transfer.flightNumber && (
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <Plane width={16} height={16} /> Número do Voo
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>
                      {transfer.flightNumber}
                    </Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                )}

                {(transfer.handLuggages ||
                  transfer.checkedLuggages ||
                  transfer.backpacks) > 0 && (
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <Luggage width={16} height={16} />
                      Bagagem
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>
                      {transfer.handLuggages +
                        transfer.checkedLuggages +
                        transfer.backpacks}{' '}
                      bagagem despachada
                    </Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                )}

                {transfer.surfboards > 0 && (
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <img
                        width={16}
                        height={16}
                        src={Surf}
                        alt="prancha de surf"
                      />
                      Prancha de Surf
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>
                      {transfer.surfboards}
                    </Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                )}

                {(transfer.childSeats ||
                  transfer.babySeats ||
                  transfer.boosterSeats) > 0 && (
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <img
                        width={16}
                        height={16}
                        src={BabyChair}
                        alt="cadeira de criança"
                      />
                      Cadeira de Criança
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>
                      {transfer.childSeats +
                        transfer.babySeats +
                        transfer.boosterSeats}
                    </Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                )}

                {transfer.animals > 0 && (
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <img width={16} height={16} src={Pet} alt="Pet" />
                      Pet
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>
                      {transfer.animals}
                    </Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                )}
              </Styled.PassengerInputsMobile>
              {/* <Divider
                  className="divider"
                  style={{ marginLeft: '30px' }}
                  orientation="vertical"
                />
                <Styled.PassengerInputs>
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <User />
                      Passageiros
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>Lorem ipsum</Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <Email />
                      Número do Voo
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>Lorem ipsum</Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <Phone />
                      Bagagem
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>Lorem ipsum</Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <User />
                      Praancha de Surf
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>Lorem ipsum</Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <Email />
                      Cadeira de Criança
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>Lorem ipsum</Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <Phone />
                      Pet
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>Lorem ipsum</Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                </Styled.PassengerInputs> */}
            </Styled.PassengerInfoContainer>
          </Styled.Wrapper>
        </Styled.Container>
      </Styled.CardContainer>
    </>
  ) : (
    <div></div>
  )
}
