import { MediaQuery } from '@mantine/core'
import * as Styled from './styles'

import { ReactComponent as MultibancoLogo } from './imgs/multibanco-logo.svg'
import { ReactComponent as CreditCard } from './imgs/credit-card.svg'
import { ReactComponent as MbWayLogo } from './imgs/mb_way_logo.svg'
import { ReactComponent as CashLogo } from './imgs/cash-icon.svg'

import StatusPayment from '../StatusPayment'

const PaymentMethodMap = (paymentMethod) => {
  switch (paymentMethod) {
    case 'CREDIT_CARD':
      return 'Cartão de crédito'
    case 'MBWAY':
      return 'MB Way'
    case 'MBMULTI':
      return 'MB Multi'
    case 'CASH':
      return 'Dinheiro'
    default:
      return paymentMethod
  }
}

export const DetailsPayment = ({ transfer }) => {
  const price = transfer?.totalPrice
    ? (transfer?.totalPrice / 100).toLocaleString('pt-PT')
    : '0,00'

  return (
    <Styled.CardContainer>
      <Styled.Container key={transfer?.id}>
        <Styled.Wrapper>
          <Styled.InfoWrapper>
            <Styled.PaymentTitle>
              <CreditCard />
              Pagamento
            </Styled.PaymentTitle>
          </Styled.InfoWrapper>

          <Styled.ValuesWrapper>
            <MediaQuery
              query="(max-width: 1024px)"
              styles={{ display: 'none' }}
            >
              <Styled.PriceTitle>
                <Styled.DepartureTitle>Valor</Styled.DepartureTitle>€{price}
              </Styled.PriceTitle>
            </MediaQuery>
          </Styled.ValuesWrapper>

          <Styled.PaymentDetails>
            <Styled.PaymentType>
              {transfer?.paymentMethod === 'MBMULTI' ? (
                <MultibancoLogo width={30} height={33} />
              ) : transfer?.paymentMethod === 'CREDIT_CARD' ? (
                <CreditCard />
              ) : transfer?.paymentMethod === 'MBWAY' ? (
                <MbWayLogo />
              ) : (
                <CashLogo stroke={'#27c7ff'} />
              )}
            </Styled.PaymentType>

            <Styled.PaymentInfo>
              <Styled.PaymentInfoTitle>
                Método de pagamento:
              </Styled.PaymentInfoTitle>
              <Styled.PaymentInfoDesc>
                {PaymentMethodMap(transfer?.paymentMethod)}
              </Styled.PaymentInfoDesc>

              <Styled.PaymentInfoTitle>Status:</Styled.PaymentInfoTitle>
              <StatusPayment status={transfer?.paymentStatus} />
            </Styled.PaymentInfo>
          </Styled.PaymentDetails>
        </Styled.Wrapper>
      </Styled.Container>
    </Styled.CardContainer>
  )
}
