import * as React from 'react'
import { useTranslation } from 'react-i18next'

import {
  ChevronContainer,
  Container,
  MainText,
  Question,
  QuestionContent,
  QuestionsWrapper,
  SubText,
  TextWrapper
} from './styles'
import { Accordion, createStyles } from '@mantine/core'
import { ReactComponent as SumSymbol } from '../../../../assets/sum_symbol.svg'
import { stagger, useAnimate } from 'framer-motion'
import { useIsInViewport } from '../../../../hooks/useIsInViewport'

export default function Faq() {
  const listRef = React.useRef(null)
  const [alreadyVisibleList, setAlreadyVisibleList] = React.useState(false)

  const { t } = useTranslation()
  const [scope, animate] = useAnimate()
  const isInViewPort = useIsInViewport(listRef)

  const staggerMenuItems = stagger(0.1, { startDelay: 0.3 })

  React.useEffect(() => {
    if (alreadyVisibleList) return

    if (isInViewPort) setAlreadyVisibleList(true)

    animate(
      '.mantine-Accordion-item',
      isInViewPort
        ? { opacity: 1, transform: 'translateX(0)' }
        : { opacity: 0, transform: 'translateX(30px)' },
      {
        duration: 0.2,
        delay: isInViewPort ? staggerMenuItems : 0
      }
    )
  }, [isInViewPort, alreadyVisibleList])

  const accordionData = [
    {
      title: t('faq.paymentMethods.title'),
      content: t('faq.paymentMethods.content'),
      index: 1
    },
    {
      title: t('faq.contact.title'),
      content: t('faq.contact.content'),
      index: 2
    },
    {
      title: t('faq.scheduleRide.title'),
      content: t('faq.scheduleRide.content'),
      index: 3
    },
    {
      title: t('faq.roundTrip.title'),
      content: t('faq.roundTrip.content'),
      index: 4
    },
    {
      title: t('faq.splitTripDiscount.title'),
      content: t('faq.splitTripDiscount.content'),
      index: 5
    },
    {
      title: t('faq.otherDestinations.title'),
      content: t('faq.otherDestinations.content'),
      index: 6
    },
    {
      title: t('faq.waitingTime.title'),
      content: t('faq.waitingTime.content'),
      index: 7
    },
    {
      title: t('faq.flightDelay.title'),
      content: t('faq.flightDelay.content'),
      index: 8
    },
    {
      title: t('faq.lastMinuteCancellation.title'),
      content: t('faq.lastMinuteCancellation.content'),
      index: 9
    },
    {
      title: t('faq.transportationItems.title'),
      content: t('faq.transportationItems.content'),
      index: 10
    },
    {
      title: t('faq.groupTravel.title'),
      content: t('faq.groupTravel.content'),
      index: 11
    },
    {
      title: t('faq.serviceAvailability.title'),
      content: t('faq.serviceAvailability.content'),
      index: 12
    },
    {
      title: t('faq.airportMeetingPoint.title'),
      content: t('faq.airportMeetingPoint.content'),
      index: 13
    },
    {
      title: t('faq.bestPriceGuarantee.title'),
      content: t('faq.bestPriceGuarantee.content'),
      index: 14
    }
  ]

  const useStyles = createStyles(() => ({
    chevron: {
      // '&[data-rotate]': {
      //   transform: 'rotate(-90deg)'
      // },
      backgroundColor: '#27c7ff',
      marginRight: 0,
      width: 'auto',
      padding: '8px',
      borderRadius: ' 0 6px 6px 0'
    },
    label: {
      padding: 0
    },
    control: {
      paddingRight: 0
    },
    item: {
      borderRadius: '6px',
      border: '0.5px solid #38280E4D',
      borderTop: '0.5px solid #38280E4D !important',
      ':first-of-type': {
        borderRadius: '6px'
      },
      ':last-of-type': {
        borderRadius: '6px'
      }
    },
    root: {
      gap: '10px',
      display: 'flex',
      flexDirection: 'column'
    }
  }))

  const { classes } = useStyles()

  const items = accordionData.map((item) => (
    <Accordion.Item value={item.title} key={item.title}>
      <Accordion.Control>
        <Question>{item.title}</Question>
      </Accordion.Control>
      <Accordion.Panel>
        <QuestionContent>{item.content}</QuestionContent>
      </Accordion.Panel>
    </Accordion.Item>
  ))

  return (
    <Container ref={scope}>
      <TextWrapper>
        <MainText>{t('faq.title')}</MainText>
        <SubText>{t('faq.subtitle')}</SubText>
      </TextWrapper>
      <QuestionsWrapper ref={listRef} className="accordion">
        <Accordion
          classNames={classes}
          className={classes.root}
          chevron={
            <ChevronContainer>
              <SumSymbol />
            </ChevronContainer>
          }
          chevronPosition="right"
          variant="contained"
          disableChevronRotation
        >
          {items}
        </Accordion>
      </QuestionsWrapper>
    </Container>
  )
}
