import styled from 'styled-components'

export const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  font-family: Neometric;
  font-size: 24px;
  font-weight: 500;
  line-height: 39.939px;

  min-width: 250px;

  color: #404040;
`
