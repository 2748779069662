import { Card, Topbar, Wrapper } from './styles'

import { FeaturesCards } from '../FeaturesCards'
import { LateralCards } from '../LateralCards'

export const CarouselSkeleton = () => {
  return (
    <>
      <Topbar />

      <Wrapper>
        <div>
          <div>
            <div>
              <h2 />
              <p />
            </div>

            <div>
              <FeaturesCards />
            </div>
          </div>

          <div>
            <Card />

            <LateralCards />
          </div>
        </div>
      </Wrapper>
    </>
  )
}
