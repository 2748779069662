export const filtersOverview = [
  {
    title: 'Status',
    options: [
      { label: 'Pendente', value: 'PENDING' },
      { label: 'Aceito', value: 'APPROVED' },
      // { label: 'Em andamento', value: 'PENDING' },
      // { label: 'Finalizado', value: 'PENDING' },
      { label: 'Cancelado', value: 'REFUSED' }
    ],
    filter: 'paymentStatus',
    type: 'normal'
  },
  {
    title: 'Data',
    options: [
      { label: 'Hoje' },
      { label: 'Ontem' },
      { label: 'Últimos 4 dias' },
      { label: 'Últimos 7 dias' },
      { label: 'Últimos 30 dias' }
    ],
    type: 'date',
    filter: 'date'
  },
  {
    title: 'Categoria',
    options: [
      { label: 'Econômico', value: 'ECONOMIC' },
      { label: 'Executivo', value: 'EXECUTIVE' },
      { label: 'Van', value: 'EXECUTIVE' },
      { label: 'Autocarro', value: 'EXECUTIVE' },
      { label: 'Avioneta', value: 'EXECUTIVE' }
    ],
    filter: 'vehicleCategoryName',
    type: 'normal'
  },
  {
    title: 'Métodos de pagamento',
    options: [
      { label: 'Dinheiro', value: 'CASH' },
      { label: 'Pagamento online', value: 'PAYMENT_ONLINE' }
    ],
    filter: 'methodPayment',
    type: 'solo'
  }
]
export const filtersDriver = [
  {
    title: 'Data',
    options: [
      { label: 'Hoje' },
      { label: 'Ontem' },
      { label: 'Últimos 4 dias' },
      { label: 'Últimos 7 dias' },
      { label: 'Últimos 30 dias' }
    ],
    type: 'date'
  },
  {
    title: 'Categoria',
    options: [
      { label: 'Econômico', value: 'ECONOMIC' },
      { label: 'Executivo', value: 'EXECUTIVE' },
      { label: 'Van', value: 'EXECUTIVE' },
      { label: 'Autocarro', value: 'EXECUTIVE' },
      { label: 'Avioneta', value: 'EXECUTIVE' }
    ],
    filter: 'vehicleCategoryName',
    type: 'normal'
  }
]
export const filtersDriverPayment = [
  {
    title: 'Status',
    options: [
      { label: 'Pendente', value: 'PENDING' },
      { label: 'Aceito', value: 'APPROVED' },
      // { label: 'Em andamento', value: 'PENDING' },
      // { label: 'Finalizado', value: 'PENDING' },
      { label: 'Cancelado', value: 'REFUSED' }
    ],
    filter: 'paymentStatus',
    type: 'normal'
  },
  {
    title: 'Métodos de pagamento',
    options: [
      { label: 'Dinheiro', value: 'CASH' },
      { label: 'Pagamento online', value: 'PAYMENT_ONLINE' }
    ],
    filter: 'methodPayment',
    type: 'solo'
  },
  {
    title: 'Data',
    options: [
      { label: 'Hoje' },
      { label: 'Ontem' },
      { label: 'Últimos 4 dias' },
      { label: 'Últimos 7 dias' },
      { label: 'Últimos 30 dias' }
    ],
    type: 'date'
  }
]
