import React from 'react'

import EmptyChatImg from '../../images/empty_chat.png'
import * as Styled from './styled'

export function EmptyChat() {
  return (
    <Styled.Container>
      <img src={EmptyChatImg} width={400} />
      <Styled.Text>
        Pesquise por nome ou ID a pessoa com quer iniciar uma conversa
      </Styled.Text>
    </Styled.Container>
  )
}
