import React from 'react'

import { Input } from '@mantine/core'
import Search from '../../../../../../assets/Search.webp'

export function SearchInput({
  onChange,
  propName,
  placeholder = 'Pesquisar...'
}) {
  return (
    <Input.Wrapper id="filters">
      <Input
        id="input-demo"
        placeholder={placeholder}
        size={'lg'}
        rightSection={
          <img
            style={{ cursor: 'pointer', backgroundColor: 'white' }}
            width={18}
            height={18}
            src={Search}
          />
        }
        onChange={(e) => onChange(e.target.value, propName)}
      />
    </Input.Wrapper>
  )
}
