import styled, { css } from 'styled-components'

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100dvh;

  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;

  padding: 1.6rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c5c5c5;
    border-radius: 1rem;
  }

  scrollbar-width: thin;
  scrollbar-color: #c5c5c5 transparent;
`

export const ModalContainer = styled.div`
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  overflow-x: hidden;

  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-end;
  z-index: 200;

  gap: 10px;
  border-radius: 22px;
  width: 54rem;
  background: #fff;
  box-shadow: 0px 22px 24px 0px rgba(0, 0, 0, 0.07),
    0px -1px 16px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  @media (max-width: 500px) {
    width: 100%;
  }
`

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  span {
    color: #404040;
    font-family: Neometric;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;

    strong {
      font-weight: 600;
    }
  }

  /* strong {
    display: block;
    color: var(--black-brand-primary, #222);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.34px;
    line-height: normal;
    margin: 0.8rem 0 1.6rem;
  }

  label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #0d0d0d;
    margin: 1rem 0;

    &.solo {
      display: block;
      margin-top: 2rem;
    }
  }

  .mantine-Input-icon svg {
    stroke: #bfbfbf;
  }

  select {
    cursor: pointer;
  }

  input:not(.mantine-TimeInput-input):not(.mantine-Radio-radio),
  select,
  .mantine-InputWrapper-root .mantine-Input-wrapper > button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12.1898px 8px;
    padding-left: 3.2rem;
    gap: 112.77px;

    width: 100%;
    height: 54px;

    border: 0.704782px solid rgba(56, 40, 14, 0.3);
    border-radius: 8.45738px;

    margin-bottom: 10px;

    font-family: 'Neometric';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #0d0d0d;

    &:placeholder {
      color: #a2a2a2;
    }

    &:focus {
      border-color: #27c7ff;
    }

    button {
      .mantine-ActionIcon-root .btn-minus .mantine-47hpvh {
        height: 22px;
        width: 22px;
      }
    }

    &.empty {
      color: #a2a2a2;
    }

    option {
      color: #0d0d0d;
    }
  } */
`

export const Receiver = styled.div`
  > strong {
    display: block;
    margin-bottom: 0.8rem;
    color: #27c7ff;
    font-family: Open Sans;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.2px;
  }

  > div {
    width: 100%;
    padding: 0.4rem 0.8rem;
    border-radius: 0.8rem;
    border: 0.5px solid #bfbfbf;
    display: flex;
    align-items: center;

    > span {
      width: 4.8rem;
      height: 4.8rem;
      background-color: #bfbfbf;
      border: 0.1rem solid #27c7ff;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    > div {
      margin-left: 1.2rem;

      strong {
        display: block;
        color: #404040;
        font-family: Neometric;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 0.4rem;
        letter-spacing: 0.16px;
      }

      p {
        color: #27c7ff;
        font-family: Neometric;
        font-size: 1.2rem;
        font-weight: 400;
        letter-spacing: 0.12px;
      }
    }
  }
`

export const PricingContainer = styled.div`
  width: 100%;
  border-radius: 0.8rem;
  border: 0.5px solid #bfbfbf;
  padding: 1.6rem;

  ul {
    list-style: none;
    li {
      display: flex;
      justify-content: space-between;

      :not(:first-of-type) {
        padding-bottom: 0.4rem;
        border-bottom: 0.5px solid #bfbfbf;
        font-family: Neometric;
        color: #404040;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;

        strong {
          font-weight: 600;
        }
      }

      :first-of-type {
        border: none;

        strong {
          color: #27c7ff;
          font-family: Open Sans;
          font-size: 1.2rem;
          font-weight: 600;
          letter-spacing: 0.12px;
        }
      }

      & + li {
        margin-top: 1.6rem;
      }
    }
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 2.4rem;

    p {
      color: #27c7ff;
      font-family: Neometric;
      font-size: 1.6rem;
      font-weight: 400;
    }

    strong {
      color: #000;
      font-family: Neometric;
      font-size: 4rem;
      font-style: normal;
      font-weight: 700;

      span {
        color: #000;
        font-family: Neometric;
        font-size: 2.2rem;
        font-style: normal;
        font-weight: 700;
      }
    }
  }
`

const modalButtonModifiers = {
  outlined: () => css`
    background: transparent;
    border: 1px solid #27c7ff;
    color: #27c7ff;
  `
}

export const ModalButton = styled.button`
  ${({ outlined }) => css`
    flex: 1;
    display: flex;
    max-height: 56px;
    height: 56px;
    padding: 24px 0;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 16px;
    background: var(--yellow-brand-primary, #27c7ff);
    color: var(--white-grayscale, #fff);
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    width: 100%;
    margin: 2.4rem auto 0;

    :disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }

    ${outlined && modalButtonModifiers.outlined()}
  `}
`

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    color: var(--black-brand-primary, #222);
    font-family: Neometric;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.24px;
  }
`

export const CloseButton = styled.button`
  width: 32px;
  height: 32px;
  background-color: transparent;
`

export const ModelImageWrapper = styled.div`
  flex: 1;
  width: 100%;
  border-radius: 0.8rem;
  border: 0.1rem solid #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15rem;

  img {
    width: 10rem;
  }
`
