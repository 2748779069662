import * as Styled from './styles'
import { Steppers } from '../Checkout/components/Steppers'
import { StripeContainer } from './components/StripeContainer'
import { RightContainer } from './RightContainer'
import Footer from '../../components/Footer'
import { NavbarCheckout } from '../../components/NavbarCheckout'
import { ModalConfirmTransfer } from '../../components/ModalConfirmTransfer'
import { InPortal } from 'react-reverse-portal'
import { useModal } from '../../hooks/useModal'
import { useTransferContext } from '../../contexts/TransferContext'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import fetchBooking from '../../services/booking'
export const CheckoutVerify = () => {
  const { modalPortalNode, openedModals, closeModal } = useModal()
  const params = useParams()
  const { tour, setTour } = useTransferContext()

  useEffect(() => {
    if (params.id) {
      fetchBooking
        .getBookingOpen(params.id)
        .then((res) => {
          setTour({
            ...res.data
          })
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }, [])
  return (
    <>
      <div style={{ maxWidth: 988, margin: '0 auto' }}>
        <NavbarCheckout />
      </div>
      <Styled.Container>
        <Steppers stepper={2} />
        <Styled.ContentContainer>
          <Styled.MiddleContainerRow>
            <StripeContainer />
            <RightContainer showCouponOnly />
          </Styled.MiddleContainerRow>
        </Styled.ContentContainer>
      </Styled.Container>
      <Footer />
      {/* {openedModals.includes('ModalConfirmTransfer') && ( */}
      <ModalConfirmTransfer
        onClose={() => closeModal('ModalConfirmTransfer')}
        data={tour}
        setData={setTour}
      />
      {/* )} */}
    </>
  )
}
