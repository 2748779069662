import {
  Badge,
  Button,
  Calendar,
  Select,
  Card,
  CardContent,
  CardHeader,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  PopoverButton
} from './styles'
import { zodResolver } from '@hookform/resolvers/zod'
import axios from 'axios'
import { format } from 'date-fns'
import pt from 'date-fns/locale/pt'
import {
  LucideCalendar,
  LucideClock,
  LucideEye,
  LucidePiggyBank,
  LucideUsers2
} from 'lucide-react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { z } from 'zod'
import { useTransferContext } from '../../../../../../contexts/TransferContext'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { formatCurrency } from '../../../../../../utils/formatCurrency'

const scheduleNowSchema = z.object({
  numberOfPassengers: z.string({
    required_error: 'Insira a quantidade de pessoas.'
  }),
  transferDate: z.date().optional(),
  transferHour: z.string().optional()
})

export const ScheduleNow = ({
  isTour = false,
  eventPrice,
  fullEvent,
  nameEvent,
  maxNPassengers,
  minNPassengers,
  strapiEventId
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, errors }
  } = useForm({
    resolver: zodResolver(scheduleNowSchema)
  })

  const navigate = useNavigate()

  const views = useMemo(() => Math.floor(Math.random() * 150), [])

  const {
    event: contextEvent,
    setEvent,
    tour: contextTour,
    setTour
  } = useTransferContext()

  const { t } = useTranslation()

  const scheduleNow = async (formData) => {
    const { transferDate, transferHour, numberOfPassengers, ...restFormData } =
      formData

    const formattedDate = transferDate
      ? new Date(transferDate.setHours(Number(transferHour || '0')))
      : new Date(new Date().setDate(new Date().getDate() + 1))
    const numberOfPassengersNumber = Number(numberOfPassengers)

    const data = {
      // ...restFormData,
      // nameEvent,
      // strapiEventId,
      allPassangers: numberOfPassengersNumber,
      dateTime: formattedDate
    }

    if (isTour) {
      setTour({ ...contextTour, ...data, ...fullEvent.attributes })
      navigate('/checkout/tour')
      return
    }

    setEvent({ ...contextEvent, ...data, ...fullEvent.attributes })
    navigate('/checkout/event')

    // try {
    //   await axios.post(
    //     'https://hook.eu2.make.com/48ljj5d9g9dp611rlbv2cclrhquwbwj5',
    //     JSON.stringify(data)
    //   )

    //   toast.success('Teu pedido foi processado com sucesso!')
    // } catch (err) {
    //   toast.error(
    //     'Tivemos um problema para processar teu pedido. Tente novamente mais tarde.'
    //   )
    // }
  }

  const formattedPassengersQtdRange = Array.from(
    { length: (maxNPassengers || 1) - (minNPassengers || 1) + 1 },
    (_, index) => (minNPassengers || 1) + index
  )

  return (
    <Card>
      <CardHeader>
        <span>{t('events.bookNow')}</span>

        <Badge>
          <LucidePiggyBank />
          <span>{t('events.save10Percent')}</span>
        </Badge>
      </CardHeader>

      <CardContent>
        <div>
          <span>€</span>

          <span> {formatCurrency(eventPrice).replace('€', '')}</span>

          <span>/{t('events.perPerson')}</span>
        </div>

        <form
          onSubmit={handleSubmit(scheduleNow, (data) => console.log({ data }))}
        >
          <div>
            <div
              style={{
                position: 'relative',
                height: '100%'
              }}
            >
              <Controller
                control={control}
                name="transferDate"
                render={({ field }) => (
                  <Popover {...register('transferDate')}>
                    <PopoverTrigger asChild>
                      <PopoverButton
                        notValue={!field.value}
                        variant={'outline'}
                      >
                        <LucideCalendar />
                        {field.value ? (
                          format(field.value, 'PPP', { locale: pt })
                        ) : (
                          <span>{t('events.selectDate')}</span>
                        )}
                      </PopoverButton>
                    </PopoverTrigger>

                    <PopoverContent>
                      {isTour && (
                        <Controller
                          control={control}
                          name="transferHour"
                          render={({ field: selectField }) => (
                            <Select
                              onValueChange={selectField.onChange}
                              value={selectField.value}
                              {...register('transferHour')}
                            >
                              <SelectTrigger>
                                <LucideClock />
                                <SelectValue
                                  placeholder={<span>00:00h</span>}
                                />
                              </SelectTrigger>
                              <SelectContent position="popper">
                                {Array.from({ length: 24 }).map((_, i) => {
                                  return (
                                    // eslint-disable-next-line react/jsx-key
                                    <SelectItem hideIcon value={String(i)}>
                                      <span
                                        style={{
                                          display: 'block',
                                          width: '100%'
                                        }}
                                      >
                                        {i < 10 ? `0${i}` : i}:00h
                                      </span>
                                    </SelectItem>
                                  )
                                })}
                              </SelectContent>
                            </Select>
                          )}
                        />
                      )}

                      <Calendar
                        mode="single"
                        locale={pt}
                        selected={field.value}
                        onSelect={field.onChange}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                )}
              />

              <div>
                {errors?.transferDate && <p>{errors.transferDate.message}</p>}
                {/* {errors?.transferHour && <p>{errors.transferHour.message}</p>} */}
              </div>
            </div>

            <div>
              <Controller
                control={control}
                name="numberOfPassengers"
                render={({ field }) => (
                  <Select
                    {...register('numberOfPassengers')}
                    onValueChange={field.onChange}
                    value={field.value}
                  >
                    <SelectTrigger>
                      <LucideUsers2
                        style={
                          field.value
                            ? { color: 'hsl(215.4 16.3% 46.9%)' }
                            : undefined
                        }
                      />
                      <SelectValue placeholder={<span>0</span>} />
                    </SelectTrigger>
                    <SelectContent>
                      {formattedPassengersQtdRange.map((passengerQtd, i) => {
                        if (passengerQtd === 0) {
                          return
                        }

                        return (
                          <SelectItem key={i} hideIcon value={String(i + 1)}>
                            {passengerQtd}
                          </SelectItem>
                        )
                      })}
                    </SelectContent>
                  </Select>
                )}
              />

              {errors?.numberOfPassengers && (
                <p>{errors.numberOfPassengers.message}</p>
              )}
            </div>
          </div>

          <Button type="submit" disabled={isSubmitting}>
            {t('events.schedule')}
          </Button>
        </form>

        <div>
          <div>
            <LucideEye />
            {views}
          </div>

          <span>{t('events.peopleViewingPackage')}</span>
        </div>
      </CardContent>
    </Card>
  )
}
