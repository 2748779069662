import { Header } from './Header'
import { Steppers } from './components/Steppers'
import { Checked } from './componentsById/Checked'
import { ChoicedCar } from './componentsById/ChoicedCar'
import { OrderSummaryResume } from './componentsById/OrderSummaryResume'
import { OrderSummary } from './componentsById/OrderSummary'
import { Tours } from './componentsById/Tours'
import * as Styled from './styles'
import { useTransferContext } from '../../contexts/TransferContext'
import { Navigate } from 'react-router-dom'
import { Navbar } from '../../components/Navbar'
import Footer from '../../components/Footer'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { InPortal, createHtmlPortalNode } from 'react-reverse-portal'
import { ChoiceCar } from './componentsById/ChoiceCar'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import fetchBooking from '../../services/booking'
import { useState } from 'react'

const portalNode = createHtmlPortalNode()

export const CheckoutCreditCard = ({ success }) => {
  const { id } = useParams()

  const [going, setGoing] = useState()
  const [carSelected, setCarSelected] = useState()

  // const { going, carSelected } = useTransferContext()

  const mobileLayoutMatch = useMediaQuery('(max-width: 944px)')

  // PAGAMENTO EM PROCESSO

  return (
    <>
      <div style={{ maxWidth: 988, margin: '0 auto' }}>
        <Navbar checkout />
      </div>
      <Styled.Container>
        <Header />
        <Steppers stepper={3} />
        <Styled.ContentContainer>
          {mobileLayoutMatch ? (
            <Styled.MiddleContainerRow>
              <InPortal node={portalNode}>
                <ChoiceCar selectedCar={carSelected} />
              </InPortal>
              {/* <OrderSummary going={going} portalNode={portalNode} reservation /> */}
            </Styled.MiddleContainerRow>
          ) : (
            <Styled.MiddleContainerColumn>
              <Checked success={success} />
              {/* <ChoicedCar /> */}
              {/* <OrderSummaryResume going={going} /> */}
              <Tours />
            </Styled.MiddleContainerColumn>
          )}
        </Styled.ContentContainer>
      </Styled.Container>
      <Footer />
    </>
  )
}
