import { Navbar } from '../../components/Navbar'
import { Hero } from './components/Hero'
import { GoogleComments } from './components/GoogleComments'
import { Resources } from './components/Resources'
import { Schedule } from './components/Schedule'
import { OurFleet } from './components/OurFleet'
import Competitors from './components/Competitors'
import AboutUs from './components/AboutUs'
import PortugalMap from './components/PortugalMap'
import PaymentMethods from './components/PaymentMethods'
import Trust from './components/Trust'
import Faq from './components/Faq'
import Footer from '../../components/Footer'
import { Tours } from '../../components/Tours'
import { ReserveComponent } from '../../components/ReserveComponent'
import { Stats } from './components/Stats'
import { GreatExperience } from './components/GreatExperience'
import { SubscribeSection } from './components/SubscribeSection'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Whatsapp } from '../../components/ReserveComponent/imgs/whatsapp.svg'

export default function Home() {
  const { t } = useTranslation()

  useEffect(() => {
    setTimeout(() => {
      const [, id] = window.location.href.split('#')

      if (id) {
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }, 1000)
  }, [])

  const accordionData = [
    {
      title: t('faq.paymentMethods.title'),
      content: t('faq.paymentMethods.content'),
      index: 1
    },
    {
      title: t('faq.contact.title'),
      content: t('faq.contact.content'),
      index: 2
    },
    {
      title: t('faq.scheduleRide.title'),
      content: t('faq.scheduleRide.content'),
      index: 3
    },
    {
      title: t('faq.roundTrip.title'),
      content: t('faq.roundTrip.content'),
      index: 4
    },
    {
      title: t('faq.splitTripDiscount.title'),
      content: t('faq.splitTripDiscount.content'),
      index: 5
    },
    {
      title: t('faq.otherDestinations.title'),
      content: t('faq.otherDestinations.content'),
      index: 6
    }
  ]

  return (
    <>
      <Navbar />
      <Hero />
      <ReserveComponent />
      <div
        style={{
          position: 'fixed',
          right: 0,
          bottom: 0,
          margin: '1.2rem',
          zIndex: 999
        }}
      >
        <a
          href="https://api.whatsapp.com/send?phone=351913012055"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Whatsapp
            style={{ width: '7rem', height: '7rem', cursor: 'pointer' }}
          />
        </a>
      </div>
      <GoogleComments />
      <Stats />
      {/* <Resources /> */}
      <Schedule />
      <AboutUs />
      <OurFleet />
      <Competitors />
      <Tours />
      <GreatExperience />
      {/* <PortugalMap /> */}
      <PaymentMethods />
      <Trust />
      <Faq accordionData={accordionData} />
      <SubscribeSection />
      <Footer />
    </>
  )
}
