import styled from 'styled-components'

import { Badge as UIBadge } from '../../../../../../components/Badge'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  padding: 1.6rem;

  > div:last-of-type {
    text-align: center;

    h4 {
      font-weight: 600;
      line-height: 1.6rem;
      font-size: 1.6rem;
    }

    p {
      max-width: 24.8rem;
      overflow-wrap: break-word;

      font-size: 1.2rem;
      line-height: 2rem;
      color: #404040;
    }

    > * + * {
      margin-top: 0.7rem;
    }
  }
`

export const Badge = styled(UIBadge)`
  display: flex;
  width: 5.6rem;
  height: 5.6rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.8rem;
  background-color: #E9F9FF;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

  > svg {
    width: 2.8rem;
    height: 2.8rem;
    color: #27c7ff;
  }

  :hover {
    background-color: #BCEEFF;
    opacity: 0.8;
  }
`
