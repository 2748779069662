import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const TAG_ROUTES = ['#about', '#faq', '#transfer']

export const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    const hrefLastElement = window.location.href.split('/').pop()
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    if (TAG_ROUTES.includes(hrefLastElement)) return
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual'
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [pathname])

  return children
}
