import { createStyles, rem } from '@mantine/core'

const HEADER_HEIGHT = rem(84)

export const useStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0
  },

  links: {
    paddingTop: theme.spacing.lg,
    height: HEADER_HEIGHT,
    display: 'flex',
    padding: 0,
    textDecoration: 'none',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  mainLinks: {
    marginRight: `calc(${theme.spacing.sm} * -1)`
  },

  mainLink: {
    color: '#404040',
    padding: `${rem(7)} ${theme.spacing.sm}`,
    transition: 'border-color 100ms ease, color 100ms ease',
    borderBottom: '1px solid var(--grey-3-grayscale, #BFBFBF)',
    textDecoration: 'none',
    fontFamily: 'Neometric',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '130%',
    letterSpacing: '0.16px',
    display: 'flex',
    gap: '5px',

    '&:hover': {
      textDecoration: 'none'
    }
  },

  secondaryLink: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
    fontSize: theme.fontSizes.xs,
    textTransform: 'uppercase',
    transition: 'color 100ms ease'
  },

  mainLinkActive: {
    color: '#27c7ff',
    borderBottomColor: '4px solid var(--yellow-brand-primary, #27c7ff)',
    fontFamily: 'Neometric',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '130%',
    letterSpacing: '0.16px'
  }
}))
