import styled from 'styled-components'

export const TransferContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
  min-height: 100px;
  margin-top: 16px;

  padding: 12px 16px;

  border: 1px solid #bfbfbf;
  border-radius: 16px;

  cursor: pointer;

  position: relative;

  @media (max-width: 1200px) {
    #sidevector {
      display: none;
    }
    flex-wrap: wrap;
  }

  @media (max-width: 780px) {
    flex-direction: column;
  }
`
export const RouteNum = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  max-width: 100%;

  gap: 8px;

  height: 56px;

  @media (max-width: 780px) {
    flex-direction: row;
  }
`

export const RouteLabel = styled.h1`
  font-family: Neometric;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #27c7ff;

  @media (max-width: 780px) {
    font-size: 24px;
    line-height: 28.8px;
  }
`

export const RouteNumLabel = styled.h3`
  font-family: Neometric;
  font-weight: 500;
  font-size: 24px;
  line-height: 28.8px;

  color: #27c7ff;
`

export const FromToContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  gap: 8px;
`

export const VectorContainer = styled.div`
  color: #e6e6e6;
  margin-left: 16px;
  margin-right: 16px;
`

export const RoutesInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;

  @media (max-width: 780px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const StartingPoint = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-wrap: wrap;

  @media (max-width: 780px) {
    flex: 1 0 0;
  }
`
export const ArrivingPoint = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  flex: 1;

  gap: 8px;

  @media (max-width: 780px) {
    flex: 1 0 0;
  }
`

export const StartText = styled.h1`
  font-family: Neometric;
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  line-height: 120%;
  letter-spacing: 0.16px;
  text-align: center;
  color: #27c7ff;
`
export const ArrivingText = styled.h1`
  font-family: Neometric;
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  line-height: 120%;
  text-align: center;
  color: #27c7ff;
  letter-spacing: 0.16px;
`

export const StartingLocation = styled.p`
  font-family: Neometric;
  font-weight: 400;
  font-size: 16px;
  line-height: 20.8px;
`

export const ArrivingLocation = styled.p`
  font-family: Neometric;
  font-weight: 400;
  font-size: 16px;
  line-height: 20.8px;
`
export const RouteDistanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-left: 24px;
  margin-right: 24px;

  @media (max-width: 780px) {
    margin-left: 0;
    margin-right: 0;
    gap: 8px;
    width: 100%;
  }
`

export const RouteNumDistance = styled.h1`
  font-family: Neometric;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  letter-spacing: 0.18px;
  color: #1D95BF;
`

export const ArrowIconContainer = styled.div`
  display: flex;
  width: 54px;
  pad: 2px 10px;
  justify-content: center;
  align-items: center;

  gap: 10px;

  border-radius: 8px;
  background-color: #DFF7FF;
`
export const StopsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;

  width: 100%;
`

export const StopsHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  width: 100%;
`

export const StopsLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const StopsText = styled.h1`
  font-family: Neometric;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.16px;
  text-align: center;
  color: #27c7ff;
`

export const RouteStopsQty = styled.p`
  align-self: stretch;

  color: #404040;
  font-family: Neometric;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.16px;
`
export const IconContainer = styled.div`
  display: flex;
  width: 18px;
  height: 18px;
  padding: 3px;
  justify-content: center;
  align-items: center;
`

export const NextButtonContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;

  right: 16px;
`
