import React, { useEffect, useState } from 'react'
import {
  createStyles,
  Header,
  Container,
  Anchor,
  Group,
  rem
} from '@mantine/core'
import * as Styled from './styles'
// import { useDisclosure } from '@mantine/hooks'
import { PersonalData } from './components/PersonalData'
import { HouseHold } from './components/HouseHold'
import { BankAccount } from './components/BankAccount'
import { useMemo } from 'react'
import { Commission } from './components/Commission'
import { Arquives } from './components/Arquives'
import fetchAccount from '../../../../services/account'
import fetchPunishment from '../../../../services/punishment'
import fetchCompany from '../../../../services/company'
import fetchFile from '../../../../services/file'
import { Car } from './components/Car'
import { toast } from 'react-toastify'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Acess } from './components/Acess'
import { ArquivesEnterprise } from './components/ArquivesEnterprise'
import { Users } from './components/Users'
import { Drivers } from './components/Drivers'
import { convertDateFormat, formatDate } from '../../../../utils/formattedDate'
import { SwitchAccounts } from '../../../../components/SwitchAccounts'
import { Punishment } from './components/Punishment'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const HEADER_HEIGHT = rem(84)

const useStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
    maxWidth: '100%'
  },

  // burger: {
  //   [theme.fn.largerThan('sm')]: {
  //     display: 'none'
  //   }
  // },

  links: {
    paddingTop: theme.spacing.lg,
    height: HEADER_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 0,
    textDecoration: 'none',
    gap: '16px'

    // [theme.fn.smallerThan('sm')]: {
    //   display: 'none'
    // }
  },

  mainLinks: {
    marginRight: `calc(${theme.spacing.sm} * -1)`
  },

  mainLink: {
    color: '#404040',
    padding: `${rem(7)} ${theme.spacing.sm}`,
    transition: 'border-color 100ms ease, color 100ms ease',
    borderBottom: '1px solid var(--grey-3-grayscale, #BFBFBF)',
    textDecoration: 'none',
    fontFamily: 'Neometric',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '130%',
    letterSpacing: '0.16px',

    '&:hover': {
      textDecoration: 'none'
    }
  },

  secondaryLink: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
    fontSize: theme.fontSizes.xs,
    textTransform: 'uppercase',
    transition: 'color 100ms ease'

    // '&:hover': {
    //   color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    //   textDecoration: 'none'
    // }
  },

  mainLinkActive: {
    color: '#27c7ff',
    borderBottomColor: '4px solid var(--yellow-brand-primary, #27c7ff)',
    fontFamily: 'Neometric',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '130%',
    letterSpacing: '0.16px'
  }
}))

const INITIAL_VALUES = {
  name: '',
  taxNumber: '',
  email: '',
  phoneNumber: '',
  password: '',
  driverLicense: '',
  commissionPercentage: 0,
  limitTransfersPerDay: 0,
  role: 'USER',
  bankAccountCountry: '',
  bank: '',
  ibanNib: '',
  swiftBic: '',
  bankAccount: '',
  profilePicture: {},
  files: [],
  vehicle: {
    category: '',
    manufacturer: '',
    manufacturingYear: '',
    model: '',
    providesInternet: false,
    providesAirConditioning: false,
    pricePerKilometer: 0,
    passengerCapacity: 0,
    handLuggageCapacity: 0,
    luggageCapacity: 0,
    backpackCapacity: 0,
    surfboardCapacity: 0,
    animalsCapacity: 0,
    babyseatCapacity: 0,
    childseatCapacity: 0,
    boosterseatCapacity: 0,
    file: {}
  }
}

export function SettingsAdm() {
  const [data, setData] = useState(INITIAL_VALUES)
  const [enableEdit, setEnableEdit] = useState()
  const [isActive, setIsActive] = useState()
  const [oldPunishmentDuration, setOldPunishmentDuration] = useState(undefined)
  const [punishment, setPunishment] = useState({
    durationInMinutes: 0
  })

  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const isEnterprise = location.pathname.includes('empresa')
  const isFreelancer = location.pathname.includes('driver')

  const { t } = useTranslation()

  const mainLinks = useMemo(
    () => [
      { label: 'Dados pessoais', link: '/personal-data' },
      { label: 'Morada', link: '/household' },
      { label: 'Conta bancária', link: '/bank-account' },
      { label: 'Viatura', link: '/car' },
      { label: 'Documentos', link: '/arquive' },
      { label: 'Porcentagem', link: '/commission' },
      { label: 'Acesso', link: '/access' },
      ...(params.id ? [{ label: 'Punição', link: '/punishment' }] : [])
    ],
    []
  )
  const mainLinksEnterprise = useMemo(() => {
    let links = [
      { label: 'Dados', link: '/personal-data' },
      { label: 'Morada', link: '/household' },
      { label: 'Conta bancária', link: '/bank-account' },
      { label: 'Documentos', link: '/arquive' },
      { label: 'Porcentagem', link: '/commission' },
      { label: 'Usuários', link: '/access' },
      { label: 'Motoristas', link: '/access' }
    ]

    if (!params.id) {
      links = links.filter(
        (link) => link.label !== 'Usuários' && link.label !== 'Motoristas'
      )
    }

    return links
  }, [params.id])

  const { classes, cx } = useStyles()
  const [active, setActive] = useState(0)

  const mainItems = (isFreelancer ? mainLinks : mainLinksEnterprise).map(
    (item, index) => (
      <Anchor
        href={item.link}
        key={item.label}
        className={cx(classes.mainLink, {
          [classes.mainLinkActive]: index === active
        })}
        onClick={(event) => {
          event.preventDefault()
          setActive(index)
        }}
      >
        {item.label}
      </Anchor>
    )
  )

  const switchStatus = (isActive) => {
    if (params.id) {
      if (isEnterprise) {
        fetchCompany
          .status(params.id, isActive)
          .then((res) => {
            toast.success(
              `Você ${isActive ? 'ativou' : 'desativou'} a conta com sucesso!`
            )
          })
          .catch((err) => console.log(err, 'err'))
      } else if (isFreelancer) {
        fetchAccount
          .status(params.id, isActive)
          .then((res) => {
            toast.success(
              `Você ${isActive ? 'ativou' : 'desativou'} a conta com sucesso!`
            )
          })
          .catch((err) => console.log(err, 'err'))
      }
    }
  }

  const getPunishment = async (userId) => {
    const { data } = await fetchPunishment.getPunishment({
      userId,
      deleted: false
    })

    if (data && data?.content?.length > 0) {
      setPunishment(data.content[0])
      setOldPunishmentDuration(data.content[0].durationInMinutes)
    }
  }

  const removePunishment = async () => {
    await fetchPunishment.deletePunishment(punishment.id)
    toast.success(t('toast.successPunishmentRemove'))
    setPunishment({ durationInMinutes: 0 })
    setOldPunishmentDuration(undefined)
  }

  const createOrUpdatePunishment = useCallback(async () => {
    if (punishment.durationInMinutes > 0 && !punishment.id) {
      try {
        const { data: punishmentData } = await fetchPunishment.createPunishment(
          {
            ...punishment,
            userNameOrId: data.id,
            userEmail: data.email
          }
        )
        toast.success(t('toast.successPunishmentCreation'))
        setPunishment(punishmentData)
        setOldPunishmentDuration(punishmentData.durationInMinutes)
      } catch (err) {
        console.log(err)
      }

      return
    }

    try {
      const { data: punishmentData } = await fetchPunishment.updatePunishment(
        punishment.id,
        punishment
      )

      toast.success(t('toast.successPunishmentEdition'))
      setPunishment(punishmentData)
      setOldPunishmentDuration(punishmentData.durationInMinutes)
    } catch (err) {
      console.log(err)
    }
  }, [data, punishment])

  useEffect(() => {
    if (params.id) {
      if (isEnterprise) {
        fetchCompany
          .getById(params.id)
          .then((res) => {
            setIsActive(res.data.active)

            const users = res.data.users
            users.forEach((user) => {
              delete user.workingDays
              delete user.workingShifts
            })
            setData({
              ...res.data,
              users
            })
          })
          .catch((err) => console.log(err, 'err'))
      } else if (isFreelancer) {
        fetchAccount
          .getById(params.id)
          .then((res) => {
            setIsActive(res.data.active)
            setData({
              ...res.data,
              dateOfBirth: formatDate(res.data.dateOfBirth),
              vehicle: {
                ...res.data.vehicle,
                endOfInsurance: formatDate(res.data.vehicle.endOfInsurance)
              }
            })
            getPunishment(res.data?.id)
          })
          .catch((err) => console.log(err, 'err'))
      }
    }
  }, [])
  const handleCreateProfile = () => {
    const toastId = toast.loading('Criando Conta...')
    if (params.id) {
      if (isFreelancer) {
        const filesPromises = []
        if (
          data?.profilePicture &&
          data?.profilePicture?.base64 &&
          !data?.profilePicture?.id
        ) {
          const profilePictureData = {
            title: data?.profilePicture?.title,
            base64: data?.profilePicture?.base64,
            fileName: data?.profilePicture?.fileName,
            fileExtension: data?.profilePicture?.fileExtension
          }
          const createProfilePicturePromise = fetchFile
            .create(profilePictureData)
            .then((res) => {
              data.profilePicture.id = res?.data?.id
            })
            .catch((err) => console.log(err))

          filesPromises.push(createProfilePicturePromise)
        }
        if (
          data?.vehicle?.externalPhoto &&
          data?.vehicle?.externalPhoto?.base64 &&
          !data?.vehicle?.externalPhoto?.id
        ) {
          const profilePictureData = {
            title: data.vehicle?.externalPhoto.title,
            base64: data.vehicle?.externalPhoto.base64,
            fileName: data.vehicle?.externalPhoto.fileName,
            fileExtension: data.vehicle?.externalPhoto.fileExtension
          }
          const createProfilePicturePromise = fetchFile
            .create(profilePictureData)
            .then((res) => {
              data.vehicle.externalPhoto.id = res.data.id
            })
            .catch((err) => console.log(err))

          filesPromises.push(createProfilePicturePromise)
        }
        if (
          data?.vehicle?.internalPhoto &&
          data?.vehicle?.internalPhoto?.base64 &&
          !data?.vehicle?.internalPhoto?.id
        ) {
          const profilePictureData = {
            title: data.vehicle?.internalPhoto.title,
            base64: data.vehicle?.internalPhoto.base64,
            fileName: data.vehicle?.internalPhoto.fileName,
            fileExtension: data.vehicle?.internalPhoto.fileExtension
          }
          const createProfilePicturePromise = fetchFile
            .create(profilePictureData)
            .then((res) => {
              data.vehicle.internalPhoto.id = res.data.id
            })
            .catch((err) => console.log(err))

          filesPromises.push(createProfilePicturePromise)
        }

        if (data.files && data.files.length) {
          data.files.forEach((file, index) => {
            if (file.base64 && !file.id) {
              const fileData = {
                title: file.title,
                base64: file.base64,
                fileName: file.fileName,
                fileExtension: file.fileExtension
              }
              const createFilePromise = fetchFile
                .create(fileData)
                .then((res) => {
                  data.files[index].id = res.data.id
                })
                .catch((err) => console.log(err))

              filesPromises.push(createFilePromise)
            }
          })
        }
        const allFilesPromise = Promise.all(filesPromises)

        allFilesPromise
          .then(() => {
            const payload = {
              ...data,
              role: 'USER',
              dateOfBirth: convertDateFormat(data.dateOfBirth) + 'T00:00:00Z',
              vehicle: {
                ...data.vehicle,
                endOfInsurance:
                  convertDateFormat(data.vehicle.endOfInsurance) + 'T00:00:00Z'
              }
            }
            delete payload.workingDays
            delete payload.workingShifts
            delete payload.presentationVideo

            if (data) {
              fetchAccount
                .updateAccount(payload)
                .then((res) => {
                  if (res) {
                    toast.update(toastId, {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      newestOnTop: false,
                      closeOnClick: true,
                      rtl: false,
                      pauseOnFocusLoss: true,
                      draggable: true,
                      pauseOnHover: true,
                      theme: 'light',
                      type: 'success',
                      closeButton: true,
                      isLoading: false,
                      render: 'Conta editada com sucesso!'
                    })
                  }
                  navigate(`/dashboard/frota/driver/${params.id}`)
                })
                .catch((err) => {
                  console.log(err)
                  toast.update(toastId, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    newestOnTop: false,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: true,
                    draggable: true,
                    pauseOnHover: true,
                    theme: 'light',
                    type: 'error',
                    closeButton: true,
                    isLoading: false,
                    render: 'Erro ao editar conta'
                  })
                })

              if (punishment.durationInMinutes === 0 && punishment.id)
                removePunishment()
              if (
                punishment.durationInMinutes > 0 &&
                punishment.durationInMinutes !== oldPunishmentDuration
              )
                createOrUpdatePunishment()
            }
          })
          .catch((err) => {
            console.log(err)
            toast.update(toastId, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              newestOnTop: false,
              closeOnClick: true,
              rtl: false,
              pauseOnFocusLoss: true,
              draggable: true,
              pauseOnHover: true,
              theme: 'light',
              type: 'error',
              closeButton: true,
              isLoading: false,
              render: 'Erro ao editar partnership devido a erros nos arquivos'
            })
          })
      } else {
        const filesPromises = []
        if (data?.image && data?.image?.base64 && !data?.image?.id) {
          const profilePictureData = {
            title: data.image.title,
            base64: data.image.base64,
            fileName: data.image.fileName,
            fileExtension: data.image.fileExtension
          }
          const createProfilePicturePromise = fetchFile
            .create(profilePictureData)
            .then((res) => {
              data.image.id = res.data.id
            })
            .catch((err) => console.log(err))

          filesPromises.push(createProfilePicturePromise)
        }

        if (data.files && data.files.length) {
          data.files.forEach((file, index) => {
            if (file.base64 && !file.id) {
              const fileData = {
                title: file.title,
                base64: file.base64,
                fileName: file.fileName,
                fileExtension: file.fileExtension
              }
              const createFilePromise = fetchFile
                .create(fileData)
                .then((res) => {
                  data.files[index].id = res.data.id
                })
                .catch((err) => console.log(err))

              filesPromises.push(createFilePromise)
            }
          })
        }
        const allFilesPromise = Promise.all(filesPromises)

        allFilesPromise
          .then(() => {
            const payload = {
              ...data
            }

            if (data) {
              fetchCompany
                .updateCompany(payload)
                .then((res) => {
                  if (res) {
                    toast.update(toastId, {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      newestOnTop: false,
                      closeOnClick: true,
                      rtl: false,
                      pauseOnFocusLoss: true,
                      draggable: true,
                      pauseOnHover: true,
                      theme: 'light',
                      type: 'success',
                      closeButton: true,
                      isLoading: false,
                      render: 'Conta editada com sucesso!'
                    })
                  }
                  navigate(`/dashboard/frota/empresa/${params.id}`)
                })
                .catch((err) => {
                  console.log(err)
                  toast.update(toastId, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    newestOnTop: false,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: true,
                    draggable: true,
                    pauseOnHover: true,
                    theme: 'light',
                    type: 'error',
                    closeButton: true,
                    isLoading: false,
                    render: 'Erro ao editar conta'
                  })
                })
            }
          })
          .catch((err) => {
            console.log(err)
            toast.update(toastId, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              newestOnTop: false,
              closeOnClick: true,
              rtl: false,
              pauseOnFocusLoss: true,
              draggable: true,
              pauseOnHover: true,
              theme: 'light',
              type: 'error',
              closeButton: true,
              isLoading: false,
              render: 'Erro ao editar empresa devido a erros nos arquivos'
            })
          })
      }
    } else {
      if (isFreelancer) {
        const filesPromises = []
        if (
          data?.profilePicture &&
          data?.profilePicture?.base64 &&
          !data?.profilePicture?.id
        ) {
          const profilePictureData = {
            title: data?.profilePicture?.title,
            base64: data?.profilePicture?.base64,
            fileName: data?.profilePicture?.fileName,
            fileExtension: data?.profilePicture?.fileExtension
          }
          const createProfilePicturePromise = fetchFile
            .create(profilePictureData)
            .then((res) => {
              data.profilePicture.id = res?.data?.id
            })
            .catch((err) => console.log(err))

          filesPromises.push(createProfilePicturePromise)
        }

        if (data.files && data.files.length) {
          data.files.forEach((file, index) => {
            if (file.base64 && !file.id) {
              const fileData = {
                title: file.title,
                base64: file.base64,
                fileName: file.fileName,
                fileExtension: file.fileExtension
              }
              const createFilePromise = fetchFile
                .create(fileData)
                .then((res) => {
                  data.files[index].id = res.data.id
                })
                .catch((err) => console.log(err))

              filesPromises.push(createFilePromise)
            }
          })
        }

        if (
          data?.vehicle?.externalPhoto &&
          data?.vehicle?.externalPhoto?.base64 &&
          !data?.vehicle?.externalPhoto?.id
        ) {
          const profilePictureData = {
            title: data.vehicle?.externalPhoto.title,
            base64: data.vehicle?.externalPhoto.base64,
            fileName: data.vehicle?.externalPhoto.fileName,
            fileExtension: data.vehicle?.externalPhoto.fileExtension
          }
          const createProfilePicturePromise = fetchFile
            .create(profilePictureData)
            .then((res) => {
              data.vehicle.externalPhoto.id = res.data.id
            })
            .catch((err) => console.log(err))

          filesPromises.push(createProfilePicturePromise)
        }
        if (
          data?.vehicle?.internalPhoto &&
          data?.vehicle?.internalPhoto?.base64 &&
          !data?.vehicle?.internalPhoto?.id
        ) {
          const profilePictureData = {
            title: data.vehicle?.internalPhoto.title,
            base64: data.vehicle?.internalPhoto.base64,
            fileName: data.vehicle?.internalPhoto.fileName,
            fileExtension: data.vehicle?.internalPhoto.fileExtension
          }
          const createProfilePicturePromise = fetchFile
            .create(profilePictureData)
            .then((res) => {
              data.vehicle.internalPhoto.id = res.data.id
            })
            .catch((err) => console.log(err))

          filesPromises.push(createProfilePicturePromise)
        }
        const allFilesPromise = Promise.all(filesPromises)

        console.log(filesPromises, 'PROMISSES', allFilesPromise)
        allFilesPromise
          .then(() => {
            const payload = {
              ...data,
              role: 'USER',
              dateOfBirth: convertDateFormat(data.dateOfBirth) + 'T00:00:00Z',
              vehicle: {
                ...data.vehicle,
                endOfInsurance:
                  convertDateFormat(data.vehicle.endOfInsurance) + 'T00:00:00Z'
              }
            }
            delete payload.presentationVideo
            delete payload.workingDays
            delete payload.workingShifts

            if (data) {
              fetchAccount
                .createAccount(payload)
                .then((res) => {
                  if (res) {
                    toast.update(toastId, {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      newestOnTop: false,
                      closeOnClick: true,
                      rtl: false,
                      pauseOnFocusLoss: true,
                      draggable: true,
                      pauseOnHover: true,
                      theme: 'light',
                      type: 'success',
                      closeButton: true,
                      isLoading: false,
                      render: 'Conta criado com sucesso!'
                    })
                  }
                  setData(INITIAL_VALUES)
                  navigate('/dashboard/frota')
                })
                .catch((err) => {
                  console.log(err)
                  toast.update(toastId, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    newestOnTop: false,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: true,
                    draggable: true,
                    pauseOnHover: true,
                    theme: 'light',
                    type: 'error',
                    closeButton: true,
                    isLoading: false,
                    render: 'Erro ao criar conta'
                  })
                })
            } else {
              toast.update(toastId, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                theme: 'light',
                type: 'error',
                closeButton: true,
                isLoading: false,
                render: 'Preencha os dados'
              })
            }
          })
          .catch((err) => {
            console.log(err)
            toast.update(toastId, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              newestOnTop: false,
              closeOnClick: true,
              rtl: false,
              pauseOnFocusLoss: true,
              draggable: true,
              pauseOnHover: true,
              theme: 'light',
              type: 'error',
              closeButton: true,
              isLoading: false,
              render: 'Erro ao criar conta devido a erros nos arquivos'
            })
          })
      } else {
        const filesPromises = []
        if (data?.image && data?.image?.base64 && !data?.image?.id) {
          const profilePictureData = {
            title: data.image.title,
            base64: data.image.base64,
            fileName: data.image.fileName,
            fileExtension: data.image.fileExtension
          }
          const createProfilePicturePromise = fetchFile
            .create(profilePictureData)
            .then((res) => {
              data.image.id = res.data.id
            })
            .catch((err) => console.log(err))

          filesPromises.push(createProfilePicturePromise)
        }

        if (data.files && data.files.length) {
          data.files.forEach((file, index) => {
            if (file.base64 && !file.id) {
              const fileData = {
                title: file.title,
                base64: file.base64,
                fileName: file.fileName,
                fileExtension: file.fileExtension
              }
              const createFilePromise = fetchFile
                .create(fileData)
                .then((res) => {
                  data.files[index].id = res.data.id
                })
                .catch((err) => console.log(err))

              filesPromises.push(createFilePromise)
            }
          })
        }
        const allFilesPromise = Promise.all(filesPromises)

        allFilesPromise
          .then(() => {
            const payload = {
              ...data
            }

            if (data) {
              fetchCompany
                .createCompany(payload)
                .then((res) => {
                  if (res) {
                    toast.update(toastId, {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      newestOnTop: false,
                      closeOnClick: true,
                      rtl: false,
                      pauseOnFocusLoss: true,
                      draggable: true,
                      pauseOnHover: true,
                      theme: 'light',
                      type: 'success',
                      closeButton: true,
                      isLoading: false,
                      render: 'Conta criado com sucesso!'
                    })
                  }
                  setData(INITIAL_VALUES)
                  navigate('/dashboard/frota')
                })
                .catch((err) => {
                  console.log(err)
                  toast.update(toastId, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    newestOnTop: false,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: true,
                    draggable: true,
                    pauseOnHover: true,
                    theme: 'light',
                    type: 'error',
                    closeButton: true,
                    isLoading: false,
                    render: 'Erro ao criar conta'
                  })
                })
            } else {
              toast.update(toastId, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                theme: 'light',
                type: 'error',
                closeButton: true,
                isLoading: false,
                render: 'Preencha os dados'
              })
            }
          })
          .catch((err) => {
            console.log(err)
            toast.update(toastId, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              newestOnTop: false,
              closeOnClick: true,
              rtl: false,
              pauseOnFocusLoss: true,
              draggable: true,
              pauseOnHover: true,
              theme: 'light',
              type: 'error',
              closeButton: true,
              isLoading: false,
              render: 'Erro ao criar conta devido a erros nos arquivos'
            })
          })
      }
    }
  }

  const renderFreelancer = () => {
    return (
      <>
        {active === 0 && (
          <PersonalData
            data={data}
            setData={setData}
            disabled={params.id ? !enableEdit : false}
          />
        )}
        {active === 1 && (
          <HouseHold
            data={data}
            setData={setData}
            disabled={params.id ? !enableEdit : false}
          />
        )}
        {active === 2 && (
          <BankAccount
            data={data}
            setData={setData}
            disabled={params.id ? !enableEdit : false}
          />
        )}
        {active === 3 && (
          <Car
            data={data}
            setData={setData}
            disabled={params.id ? !enableEdit : false}
          />
        )}
        {active === 4 && (
          <Arquives
            data={data}
            setData={setData}
            disabled={params.id ? !enableEdit : false}
          />
        )}
        {active === 5 && (
          <Commission
            data={data}
            setData={setData}
            disabled={params.id ? !enableEdit : false}
          />
        )}
        {active === 6 && (
          <Acess
            data={data}
            setData={setData}
            disabled={params.id ? !enableEdit : false}
          />
        )}
        {active === 7 && (
          <Punishment
            data={punishment}
            setData={setPunishment}
            disabled={params.id ? !enableEdit : false}
          />
        )}
      </>
    )
  }
  const renderEnterprise = () => {
    return (
      <>
        {active === 0 && (
          <PersonalData
            data={data}
            setData={setData}
            disabled={params.id ? !enableEdit : false}
            enterprise={isEnterprise}
          />
        )}
        {active === 1 && (
          <HouseHold
            data={data}
            setData={setData}
            disabled={params.id ? !enableEdit : false}
          />
        )}
        {active === 2 && (
          <BankAccount
            data={data}
            setData={setData}
            disabled={params.id ? !enableEdit : false}
          />
        )}
        {active === 3 && (
          <ArquivesEnterprise
            data={data}
            setData={setData}
            disabled={params.id ? !enableEdit : false}
          />
        )}
        {active === 4 && (
          <Commission
            data={data}
            setData={setData}
            disabled={params.id ? !enableEdit : false}
            enterprise={isEnterprise}
          />
        )}
        {active === 5 && (
          <Users
            data={data}
            setData={setData}
            disabled={params.id ? !enableEdit : false}
            id={params.id}
          />
        )}
        {active === 6 && (
          <Drivers
            data={data}
            setData={setData}
            disabled={params.id ? !enableEdit : false}
            id={params.id}
            driver
          />
        )}
      </>
    )
  }

  return (
    <Styled.Container>
      <Styled.HeaderContainer>
        <Styled.Title>{params.id ? 'Perfil' : 'Registrar'}</Styled.Title>
        <Styled.ButtonsContainer>
          {params.id && (
            <>
              <SwitchAccounts
                active={isActive}
                setActive={setIsActive}
                switchStatus={switchStatus}
              />
              <Styled.EditButton onClick={() => setEnableEdit(!enableEdit)}>
                Editar
              </Styled.EditButton>
              {enableEdit && (
                <Styled.ConfirmButton onClick={() => handleCreateProfile()}>
                  Salvar
                </Styled.ConfirmButton>
              )}
            </>
          )}

          {!params.id && (
            <Styled.ConfirmButton onClick={() => handleCreateProfile()}>
              Cadastrar
            </Styled.ConfirmButton>
          )}
        </Styled.ButtonsContainer>
      </Styled.HeaderContainer>
      <Header style={{ borderBottom: 'transparent' }}>
        <Container className={classes.inner}>
          <div className={classes.links}>
            <Group spacing={0} position="right" className={classes.mainLinks}>
              {mainItems}
            </Group>
          </div>
        </Container>
      </Header>
      {isFreelancer && <>{renderFreelancer()}</>}
      {isEnterprise && <>{renderEnterprise()}</>}
    </Styled.Container>
  )
}
