import { useTranslation } from 'react-i18next'
import * as Styled from './styles'

import OrangeBar from './imgs/icon_line_points- blue branding.svg'
import Arrow from './imgs/icon_chevron right.svg'
import { useTransferContext } from '../../../../contexts/TransferContext'
import { formatCurrency } from '../../../../utils/formatCurrency'
import dayjs from 'dayjs'

export const OrderSummaryResume = () => {
  const { t } = useTranslation()

  const { tour, returnTravel } = useTransferContext()

  // console.log({ tour, returnTravel, carSelected })

  const getAbbreviatedWeekday = (date) => {
    const weekday = date.toLocaleString('default', { weekday: 'short' })
    return weekday.substring(0, 3).toUpperCase()
  }

  return (
    <Styled.Container>
      <h2>{t('orderSummary.title')}</h2>
      <Styled.FormRow>
        <Styled.StrongSpan>{t('orderNumber')} :</Styled.StrongSpan>
        <Styled.NormalSpan>034938429</Styled.NormalSpan>
      </Styled.FormRow>
      <Styled.CardsContainer>
        <Styled.ContentWrapper>
          <Styled.OrientationWrapper>
            <img src={Arrow} alt={t('arrowAltText')} />
            <span>{t('ida')}</span>
          </Styled.OrientationWrapper>
          <Styled.HeaderWrapper>
            <Styled.DateAndHour>
              {getAbbreviatedWeekday(tour.dateTime)},{' '}
              {tour.dateTime.toLocaleDateString()} -{' '}
              {`${String(dayjs(tour.dateTime).get('hours')).padStart(
                2,
                '0'
              )}:${String(dayjs(tour.dateTime).get('minutes')).padStart(
                2,
                '0'
              )}`}
            </Styled.DateAndHour>
          </Styled.HeaderWrapper>
          <Styled.RoutesWrapper>
            <img
              src={OrangeBar}
              className="orange-bar"
              alt={t('orangeBarAltText')}
            />
            <div className="routes">
              <Styled.FormRow>
                <Styled.RouteStrongSpan>{tour.from}</Styled.RouteStrongSpan>
                <Styled.RoutePriceSpan style={{ width: '70px' }}>
                  € {formatCurrency(tour.totalPrice).replace('€', '')}
                </Styled.RoutePriceSpan>
              </Styled.FormRow>
              <Styled.FormRow>
                <Styled.RouteNormalSpan>{tour.from}</Styled.RouteNormalSpan>
              </Styled.FormRow>
              <Styled.FormRow style={{ marginTop: '16px' }}>
                <Styled.RouteStrongSpan style={{ marginTop: '30px' }}>
                  {tour.to}
                </Styled.RouteStrongSpan>
              </Styled.FormRow>
              <Styled.FormRow>
                <Styled.RouteNormalSpan>{tour.to}</Styled.RouteNormalSpan>
              </Styled.FormRow>
            </div>
          </Styled.RoutesWrapper>
        </Styled.ContentWrapper>
      </Styled.CardsContainer>
    </Styled.Container>
  )
}
