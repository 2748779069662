import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 48px;

  max-width: 988.02px;
  width: 100%;
  margin-top: 70px;
`

export const ConfirmationMessage = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;

  text-align: center;
  letter-spacing: 0.01em;

  color: #0d0d0d;
`

export const ImportantContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20.9474px;
  position: relative;

  max-width: 988.02px;
  width: 100%;
  min-height: 173.89px;
  margin-top: 20px;

  border: 0.872807px solid #27c7ff;
  border-radius: 27.9298px;

  font-weight: 500;
  font-size: 18px;
  line-height: 120%;

  letter-spacing: 0.01em;

  color: #404040;

  div {
    max-width: 837.89px;
    width: 100%;
    min-height: 132px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  span {
    color: #1D95BF;
  }
`

export const OrangeTag = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3.49123px 5.23684px;
  gap: 6.98px;

  width: 97.47px;
  height: 25.98px;

  background: #27c7ff;
  border-radius: 3.49123px;

  position: absolute;
  top: -1.6rem;
  left: 3rem;

  span {
    font-weight: 500;
    font-size: 15.7105px;
    line-height: 19px;

    color: #ffffff;
  }
`
