import api from './api'

const fetchObj = {
  create: (payload) => {
    const res = api.post('/coupon', payload)

    return res
  },

  update: (payload) => {
    const res = api.put('/coupon', payload)

    return res
  },
  getAll: (payload) => {
    const res = api.get('/coupon', { params: payload })

    return res
  },
  getById: (id) => {
    const res = api.get(`/coupon/${id}`)

    return res
  },
  delete: (id) => {
    const res = api.delete(`/coupon/${id}`)

    return res
  },

  validate: (coupon) => {
    const res = api.get(`/coupon/validate/${coupon}`)

    return res
  }
}

export default fetchObj
