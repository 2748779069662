import styled, { css } from 'styled-components'

import * as SibebarToggleStyles from './components/SidebarToggle/styles'

export const Navbar = styled.ul`
  display: flex;
  align-items: center;
  gap: 4rem;
  z-index: 99;
  width: 100%;
  justify-content: center;

  .mantine-UnstyledButton-root {
    width: 100%;
    height: 40px;

  }

  .mantine-Menu-dropdown {
    display: none;
    z-index: 99999999 !important;
  }

  #logo {
    height: 70px;
  }

  @media (max-width: 768px) {
    div,
    li,
    button:not(.navToggle),
    a {
      display: none;
    }
    justify-content: space-evenly;
    img {
      margin-right: 0;
    }
    #logo {
      height: 5rem;
    }
  }
`

export const Link = styled.li`
  ${({ theme }) => css`
    color: ${theme.colors.lightGray};
    list-style: none;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    position: relative;
  `}

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #27c7ff;
    z-index: 1;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s ease-in-out;
  }

  &:hover::before {
    transform: scaleX(1);
  }
`

export const Button = styled.button`
  padding: 12px 24px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(223, 247, 255, 0.5);
  background: #27c7ff;
  border: 0;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: 1s;
  border: solid 1px #27c7ff;

  &:hover {
    background-color: #fff;
    color: #27c7ff;
    border: solid 1px #27c7ff;
  }
`
export const NavbarWrapper = styled.span`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`

const containerModifiers = {
  dark: () => css`
    background-color: #1b1b1b;

    ${Button} {
      color: #000000;

      &:hover {
        background-color: #000;
        color: #27c7ff;
        border: solid 1px #27c7ff;
      }
    }

    ${SibebarToggleStyles.Wrapper} {
      span {
        background-color: #ffffff;
      }
    }
  `
}

export const HelpTitle = styled.h4`
  font-family: 'Neometric';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  color: #0d0d0d;
  display: flex;
  align-items: center;
  gap: 10px;
`

export const MoneyDropdown = styled.h2`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Container = styled.header`
  ${({ dark }) => css`
    font-weight: '400';
    padding: 3rem;

    display: flex;
    justify-content: center;
    height: 9rem;
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #27c7ff;
    width: 100%;
    box-shadow: 0px 18.0092px 27.2828px rgba(0, 0, 0, 0.07);

    ${dark && containerModifiers.dark}

    @media only screen and (max-width: 768px) {
      ${HelpTitle}, ${MoneyDropdown} {
        display: none;
    }
  `}
`
