import styled from 'styled-components'

import { Separator as UISeparator } from '../../components/Separator'
import * as OurFleetStyles from '../Home/components/OurFleet/styles'

export const Wrapper = styled.main`
  margin: 0 auto;
  /* margin-top: 0; */
  display: flex;
  min-height: 100dvh;
  width: 100%;
  flex-direction: column;
  /* background-color: #f3f4f6; */
  background-color: #fff !important;
`

export const EasyContent = styled.div`
  margin: 0 auto;
  margin-top: 4.2rem;
  width: 100%;
  max-width: 144rem;
  padding: 0 1.6rem;

  > div {
    margin: 0 auto;
  }

  > * + * {
    margin-top: 10.2rem;
  }

  ${OurFleetStyles.Container} {
    margin-top: 10.2rem;
  }

  @media (min-width: 1024px) {
    padding: 0 16rem;
  }
`

export const Separator = styled(UISeparator)`
  background-color: #fff;
  padding-bottom: 24rem;
`
