export const generateEditableData = (couponData) => {
  return {
    id: couponData.id,
    coupon: couponData.coupon,
    discountPercentage: couponData.discountPercentage,
    appliedTo: couponData.appliedTo,
    validFrom: new Date(couponData.validFrom),
    validTo: new Date(couponData.validTo),
    paymentMethods: couponData.paymentMethodsAllowed || [],
    useAmount: couponData.usageLimitPerUser || 1,
    useLimitOption:
      typeof couponData.usageLimitPerUser !== 'number'
        ? 'LIMITLESS'
        : couponData.usageLimitPerUser === 1
        ? 'ONCE'
        : 'CUSTOM'
  }
}
