export const removeDuplicateIds = (array) => {
  const newArray = []

  array.forEach((element) => {
    if (newArray.findIndex((k) => k?.id === element?.id) === -1)
      newArray.push(element)
  })

  return newArray
}
