import * as Styled from './styles'
import { ReactComponent as CloseIcon } from '../imgs/CloseIcon.svg'
import { useEffect, useState } from 'react'
import { useRef } from 'react'
import { PaymentMessage } from './PaymentMessage'
import { PaymentDetails } from './PaymentDetails'
import { User } from 'lucide-react'
import fetchTransfer from '../../../../../../services/transfer'
import { PAYMENT_STATUS, PAYMENT_TYPES } from '../../constants'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../../../contexts/useAuth'
import easyTransfer from '../../assets/easy.png'
import { useTranslation } from 'react-i18next'

const EASY_TRANSFER_PERCENTAGE = 0.25

const PAYMENT_TYPE_BY_ROLE = {
  USER: PAYMENT_TYPES.PAYMENT_TO_MANAGEMENT,
  COMPANY_USER: PAYMENT_TYPES.PAYMENT_TO_MANAGEMENT,
  ADMIN: PAYMENT_TYPES.PAYMENT_TO_DRIVER,
  HOTEL_USER: PAYMENT_TYPES.PAYMENT_TO_HOSTEL,
  COMPANY_ADMIN: PAYMENT_TYPES.PAYMENT_TO_HOSTEL
}

export const GeneratePaymentModal = ({
  onClose,
  onSuccess,
  selectedTransfers = [],
  totalCredit = 0,
  driver
}) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [step, setStep] = useState('DETAILS')
  const { ROLE, user } = useAuth()

  const { t } = useTranslation()

  const overlayRef = useRef(null)

  const totalPrice = selectedTransfers.reduce(
    (acc, curr) => acc + curr.totalPrice / 100,
    0
  )

  const totalEasyTransfer = EASY_TRANSFER_PERCENTAGE * totalPrice

  let totalDriver = totalPrice - totalEasyTransfer

  const onClickOverlay = (e) => {
    if (overlayRef === null) return
    if (e.target !== overlayRef.current) return
    if (!onClose) return
    onClose()
  }

  const stepDecider = {
    DETAILS: (
      <PaymentDetails
        totalCharged={totalPrice}
        totalEasyTransfer={totalEasyTransfer}
        totalCredit={totalCredit}
        totalDriver={totalDriver}
      />
    ),
    MESSAGE: (
      <PaymentMessage
        message={message}
        setMessage={(value) => setMessage(value)}
      />
    )
  }

  const handleConfirmPayment = async () => {
    setLoading(true)
    try {
      const bookings = selectedTransfers.map((transfer) => ({
        id: transfer.id
      }))
      const payload = {
        amount: Math.abs(Number(totalDriver.toFixed(2).replace('.', ''))),
        paidAt: new Date().toISOString(),
        message,
        bookings,
        status:
          ROLE === 'HOTEL_USER' || ROLE === 'COMPANY_ADMIN'
            ? PAYMENT_STATUS.PENDING
            : PAYMENT_STATUS.PAID,
        type: PAYMENT_TYPE_BY_ROLE[ROLE],
        ...(ROLE !== 'HOTEL_USER'
          ? {
              user: {
                id: driver.id
              }
            }
          : ROLE === 'COMPANY_ADMIN'
          ? {
              company: {
                id: user.company ? user.company.id : user.id
              }
            }
          : {
              hostel: {
                id: user.hostel ? user.hostel.id : user.id
              }
            })
      }
      await fetchTransfer.createPayment(payload)
      toast.success(t('toast.successfulPayment'))
      setLoading(false)
      onSuccess()
    } catch (err) {
      toast.error(t('toast.errorGeneratePayment'))
      setLoading(false)
    }
  }

  useEffect(() => {
    const escapeModal = ({ key }) => {
      if (key === 'Escape' && onClose) onClose()
    }

    window.document.addEventListener('keydown', escapeModal)

    return () => {
      window.document.removeEventListener('keydown', escapeModal)
    }
  }, [onClose])

  return (
    <Styled.ModalOverlay ref={overlayRef} onClick={onClickOverlay}>
      <Styled.ModalContainer>
        <Styled.ModalHeader>
          <h2>Pagamento</h2>
          <Styled.CloseButton>
            <CloseIcon
              onClick={() => {
                onClose()
              }}
            />
          </Styled.CloseButton>
        </Styled.ModalHeader>
        <Styled.ModalContent>
          <span>
            Você selecionou <strong>{selectedTransfers.length}</strong>{' '}
            transfer(s) para gerar pagamento.
          </span>
          <Styled.Receiver>
            <strong>RECEBEDOR</strong>
            <div>
              <span
                style={
                  driver.profilePicture
                    ? { display: 'block' }
                    : {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }
                }
              >
                {[
                  'USER',
                  'HOTEL_USER',
                  'COMPANY_USER',
                  'COMPANY_ADMIN'
                ].includes(ROLE) || driver.profilePicture ? (
                  <img
                    src={
                      [
                        'USER',
                        'HOTEL_USER',
                        'COMPANY_USER',
                        'COMPANY_ADMIN'
                      ].includes(ROLE)
                        ? easyTransfer
                        : driver.profilePicture.url
                    }
                  />
                ) : (
                  <User size="3rem" />
                )}
              </span>
              <div>
                <strong>
                  {ROLE === 'USER' || ROLE === 'COMPANY_USER'
                    ? 'EasyTransfer'
                    : driver.name}
                </strong>
                {['ADMIN', 'HOTEL_USER', 'COMPANY_ADMIN'].includes(ROLE) && (
                  <p>#{driver.id}</p>
                )}
              </div>
            </div>
          </Styled.Receiver>
          {stepDecider[step]}
        </Styled.ModalContent>
        {step === 'DETAILS' ? (
          <Styled.ModalButton onClick={() => setStep('MESSAGE')}>
            AVANÇAR
          </Styled.ModalButton>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1.6rem',
              width: '100%'
            }}
          >
            <Styled.ModalButton
              disabled={loading}
              onClick={() => setStep('DETAILS')}
              outlined
            >
              VOLTAR
            </Styled.ModalButton>
            <Styled.ModalButton
              disabled={loading}
              onClick={handleConfirmPayment}
            >
              CONFIRMAR
            </Styled.ModalButton>
          </div>
        )}
      </Styled.ModalContainer>
    </Styled.ModalOverlay>
  )
}
