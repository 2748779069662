import { Container, Title } from './styles'
import { TrustCarousel } from './EventsCarousel'
import { useTranslation } from 'react-i18next'
import { motion, AnimatePresence } from 'framer-motion'
import { useState } from 'react'

export default function Trust() {
  const { t } = useTranslation()
  const [isMobile, setIsMobile] = useState(() => window.innerWidth < 700)

  return (
    <Container id="faq">
      <AnimatePresence>
        {isMobile ? (
          <motion.div>
            <Title>{t('whoTrustsUs')}</Title>
          </motion.div>
        ) : (
          <motion.div
            initial={{ x: '100%' }}
            whileInView={{ x: 0 }}
            exit={{ x: '-100%' }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <Title>{t('whoTrustsUs')}</Title>
          </motion.div>
        )}
      </AnimatePresence>
      <TrustCarousel />
    </Container>
  )
}
