import { useState } from 'react'
import { options } from './options'

import { PeriodFilter } from './PeriodFilter'

import * as Styled from './styles'

export const DateFilter = ({ onChange }) => {
  const [value, setValue] = useState(null)

  const handleChangeOption = (option) => {
    if (option?.option === value?.option) {
      setValue(null)
      return onChange(null)
    }

    setValue(option)
    onChange(!option.last ? option.getValue() : null)
  }

  return (
    <Styled.Container>
      <Styled.Options>
        {options.map((item, index) => {
          const selected = item.option === value?.option

          if (item.last) {
            return (
              <PeriodFilter
                key={index}
                item={item}
                selected={selected}
                onClick={() => handleChangeOption(item)}
                onChange={onChange}
              />
            )
          }

          return (
            <Styled.Option
              onClick={() => handleChangeOption(item)}
              key={index}
              first={item.first}
              last={item.last}
              select={selected}
            >
              {item.option}
            </Styled.Option>
          )
        })}
      </Styled.Options>
    </Styled.Container>
  )
}
