import { CarCard } from './components/CarCard'
import * as Styled from './styles'

import { useTransferContext } from '../../../../contexts/TransferContext'
import { Pagination } from '@mantine/core'

export const ChoiceCars = ({ setSelectedCar, setShowCars }) => {
  const {
    carItem: CarItem,
    carPagination,
    setCarPagination
  } = useTransferContext()

  const handleSelectCar = (car = CarItem[0]) => {
    setSelectedCar(car)
  }

  console.log(carPagination.page, 'carPagination.page', carPagination.page + 1)

  return (
    <Styled.Container>
      <CarCard
        car={CarItem}
        onSelectCar={handleSelectCar}
        setShowCars={setShowCars}
      />
      {carPagination.totalPages > 1 && (
        <Pagination
          className="pagination"
          size="xl"
          total={carPagination.totalPages}
          radius="md"
          color="orange"
          withControls={false}
          value={carPagination.page + 1}
          onChange={(value) =>
            setCarPagination({
              ...carPagination,
              page: value - 1
            })
          }
        />
      )}
    </Styled.Container>
  )
}
