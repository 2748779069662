import {
  Button,
  Divider,
  Flex,
  LoadingOverlay,
  MediaQuery
  // , Pagination
} from '@mantine/core'
import * as Styled from './styles'
import { useEffect } from 'react'
import { ReactComponent as MultibancoLogo } from './imgs/multibanco-logo.svg'
import { ReactComponent as CreditCard } from './imgs/credit-card.svg'
import fetchBooking from '../../../../../../services/booking'
import { useState } from 'react'
import {
  formatDateAndTime,
  formatDayOfWeek,
  formatMonth,
  formatTime
} from '../../../../../../utils/formattedDate'
import { RejectModal } from '../RejectModal'
import { useAuth } from '../../../../../../contexts/useAuth'
import { useClipboard } from '@mantine/hooks'
import StatusPayment from '../StatusPayment'
import { useParams } from 'react-router-dom'
import { CheckCircle, Play } from 'lucide-react'

export const DetailsPayment = ({ transfer, setSendTransferModal }) => {
  const [rejectModal, setRejectModal] = useState(false)

  const clipboard = useClipboard({ timeout: 500 })

  const { user } = useAuth()
  const { id } = useParams()

  const hanldeModalOpen = (setModal) => {
    if (transfer?.status === 'DONE') {
      return
    }
    setModal(true)
  }

  return transfer ? (
    <>
      <Styled.CardContainer>
        <Styled.Container key={transfer?.id}>
          <Styled.Wrapper>
            <Styled.InfoWrapper>
              <Styled.PaymentTitle>
                <CreditCard />
                Pagamento
              </Styled.PaymentTitle>
            </Styled.InfoWrapper>
            <Styled.PricesWrapper realized={transfer?.status === 'DONE'}>
              <Styled.ValuesWrapper>
                <Styled.PriceTitle>
                  <Styled.DepartureTitle>VALOR A COBRAR</Styled.DepartureTitle>
                  {(transfer?.totalPrice / 100).toLocaleString('pt-PT')}
                </Styled.PriceTitle>
              </Styled.ValuesWrapper>
              <Styled.ValuesWrapper>
                <Styled.PriceTitle>
                  <Styled.DepartureTitle>
                    VALOR EASYTRANSFER
                  </Styled.DepartureTitle>
                  €
                  {(
                    ((transfer?.totalPrice / 100) *
                      (100 - user.commissionPercentage)) /
                    100
                  )
                    .toFixed(2)
                    .toLocaleString('pt-PT')}
                </Styled.PriceTitle>
              </Styled.ValuesWrapper>
              <Styled.ValuesWrapper>
                <Styled.PriceTitle>
                  <Styled.DepartureTitle>VALOR LIQUIDO</Styled.DepartureTitle>€
                  {(
                    ((transfer?.totalPrice / 100) * user.commissionPercentage) /
                    100
                  )
                    .toFixed(2)
                    .toLocaleString('pt-PT')}
                </Styled.PriceTitle>
              </Styled.ValuesWrapper>
            </Styled.PricesWrapper>

            <Styled.PaymentDetails>
              {transfer?.status !== 'DONE' && (
                <Styled.PaymentInfo>
                  <Styled.PaymentInfoTitle>Status:</Styled.PaymentInfoTitle>
                  {transfer?.paymentMethod === 'CASH' ? (
                    <StatusPayment status="Receber pagamento" />
                  ) : transfer?.paymentStatus === 'PENDING' ? (
                    <StatusPayment status="Pendente" />
                  ) : transfer?.paymentStatus === 'APPROVED' ? (
                    <StatusPayment status="Pagamento recebido" />
                  ) : (
                    <StatusPayment status="Erro" />
                  )}
                </Styled.PaymentInfo>
              )}
            </Styled.PaymentDetails>
            {transfer?.status === 'ACCEPTED' ? (
              <Styled.PaymentStart
                onClick={() => hanldeModalOpen(setSendTransferModal)}
              >
                INICIAR <Play color="white" />
              </Styled.PaymentStart>
            ) : (
              <Styled.PaymentAccomplished
                realized={transfer?.status === 'DONE'}
                onClick={() => hanldeModalOpen(setSendTransferModal)}
              >
                FINALIZAR
                <CheckCircle width={24} height={24} color="#FFFFFF" />
              </Styled.PaymentAccomplished>
            )}
          </Styled.Wrapper>
        </Styled.Container>

        <RejectModal
          opened={rejectModal}
          onClose={() => setRejectModal(false)}
        />
      </Styled.CardContainer>
    </>
  ) : (
    <div></div>
  )
}
