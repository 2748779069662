import standard from '../../../Fleet/imgs/car3.webp'
import executive from '../../../Fleet/imgs/car4.webp'
import peopleCarrier from '../../../Fleet/imgs/car10.webp'
import largePeopleCarrier from '../../../Fleet/imgs/car7.webp'
import executivePeopleCarrier from '../../../Fleet/imgs/car5.webp'
import miniBus from '../../../Fleet/imgs/car6.webp'

export const CarItem = [
  {
    img: standard,
    title: 'Standard',
    briefcase: 3,
    wifi: true,
    passengers: 4,
    price: 4
  },
  {
    img: executive,
    title: 'Executive',
    briefcase: 3,
    wifi: true,
    passengers: 4,
    price: 6
  },
  {
    img: peopleCarrier,
    title: 'People Carrier',
    briefcase: 3,
    wifi: true,
    passengers: 4,
    price: 8
  },
  {
    img: largePeopleCarrier,
    title: 'Large People Carrier',
    briefcase: 3,
    wifi: true,
    passengers: 4,
    price: 10
  },
  {
    img: executivePeopleCarrier,
    title: 'Executive People Carrier',
    briefcase: 3,
    wifi: true,
    passengers: 4,
    price: 12
  },
  {
    img: miniBus,
    title: 'Mini Bus',
    briefcase: 3,
    wifi: true,
    passengers: 4,
    price: 14
  }
]
