import React from 'react'
import * as Styled from './style'
import { useParams } from 'react-router-dom'

export function RouteInfo({ rotanum }) {
  const { id } = useParams()
  return (
    <Styled.RouteTextHeader>
      {id ? `Rota ${id.substring(0, 10)}` : 'Nova Rota'}
    </Styled.RouteTextHeader>
  )
}
