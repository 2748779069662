import { Button, Flex, Input, MediaQuery } from '@mantine/core'
import * as Styled from './styles'
import { ReactComponent as Avatar } from '../../imgs/avatar.svg'
import { useRef } from 'react'

export const PersonalData = ({ data, setData, disabled }) => {
  const handleInputChange = (field, value) => {
    setData((prevValues) => ({
      ...prevValues,
      [field]: value
    }))
  }

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const photoData = e.target.result

        setData((prevData) => ({
          ...prevData,
          image: {
            // title: 'string',
            title: file.name,
            fileName: file.name,
            fileExtension: file.type.split('/')[1],
            // fileKey: 'string',
            url: photoData,
            base64: photoData.split(',')[1]
          }
        }))
      }
      console.log(file, 'FILE')
      reader.readAsDataURL(file)
    }
  }

  const fileInputRef = useRef(null)

  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const avatarSize = 150

  const imgStyle = {
    width: avatarSize,
    height: avatarSize,
    borderRadius: '100%',
    cursor: 'pointer'
  }
  return (
    <Styled.Container>
      {data?.image?.url ? (
        <img
          onClick={handleAvatarClick}
          src={data?.image?.url}
          alt="User"
          style={imgStyle}
        />
      ) : (
        <Avatar
          size={avatarSize}
          onClick={handleAvatarClick}
          style={{ cursor: 'pointer' }}
        />
      )}
      <input
        type="file"
        accept="image/*"
        onChange={handlePhotoUpload}
        style={{ display: 'none' }}
        ref={fileInputRef}
        disabled={disabled}
      />
      <MediaQuery query="(max-width: 768px)">
        <Flex gap="lg" direction="column">
          <Input.Wrapper label="Nome do parceiro">
            <Input
              // icon={<Send />}
              onChange={(event) =>
                handleInputChange('name', event.target.value)
              }
              value={data?.name}
              placeholder="Escreva seu nome"
              size={'lg'}
              disabled={disabled}
            />
          </Input.Wrapper>
          <Input.Wrapper label="E - mail">
            <Input
              // icon={<Send />}
              onChange={(event) =>
                handleInputChange('email', event.target.value)
              }
              value={data?.email}
              placeholder="Seu melhor e-mail"
              size={'lg'}
              disabled={disabled}
            />
          </Input.Wrapper>

          <Input.Wrapper label="NIF">
            <Input
              // icon={<Send />}
              onChange={(event) => handleInputChange('nif', event.target.value)}
              value={data?.nif}
              placeholder="0000000000"
              size={'lg'}
              disabled={disabled}
            />
          </Input.Wrapper>
          <Input.Wrapper label="Telemóvel / WhatsApp">
            <Input
              // icon={<Send />}
              onChange={(event) =>
                handleInputChange('phoneNumber', event.target.value)
              }
              value={data?.phoneNumber}
              placeholder="0000000000"
              size={'lg'}
              disabled={disabled}
            />
          </Input.Wrapper>
        </Flex>
      </MediaQuery>
    </Styled.Container>
  )
}
