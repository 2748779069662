import { ReactComponent as TrendingDownSvg } from './imgs/trending_down.svg'

export function LowCostBadge() {
  return (
    <div
      style={{
        background: '#222222',
        padding: '7px 10px',
        marginTop: 5,
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        borderRadius: 8
      }}
    >
      <TrendingDownSvg />
      <span
        style={{
          fontFamily: 'Neometric',
          fontSize: 14,
          fontWeight: 600,
          color: '#fff'
        }}
      >
        Low Cost
      </span>
    </div>
  )
}
