import styled from 'styled-components'

export const RouteTextHeader = styled.h1`
  color: #27c7ff;
  font-family: Neometric;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  letter-spacing: 0.2px;

  margin-bottom: 16px;
`
