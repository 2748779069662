import {
  Button,
  Divider,
  Flex,
  LoadingOverlay,
  MediaQuery
  // , Pagination
} from '@mantine/core'
import * as Styled from './styles'
import { ReactComponent as Direction } from './imgs/direction.svg'
import { ReactComponent as Pin } from './imgs/mapPin.svg'
import { ReactComponent as Car } from './imgs/car.svg'
import { ReactComponent as Surf } from './imgs/surf.svg'
import { ReactComponent as Pet } from './imgs/pet.svg'
import { ReactComponent as ChildChair } from './imgs/child-chair.svg'
import { ReactComponent as Distance } from './imgs/distance-icon.svg'
import { ReactComponent as Duration } from './imgs/duration-icon.svg'
import { ReactComponent as Plane } from './imgs/plane-icon.svg'
import Chevron from './imgs/right.webp'
import { useEffect } from 'react'
import fetchBooking from '../../../../../../services/booking'
import { useState } from 'react'
import { RejectModal } from '../RejectModal'
import { useAuth } from '../../../../../../contexts/useAuth'
import { useClipboard } from '@mantine/hooks'
import {
  ArrowUpRight,
  Backpack,
  Briefcase,
  Luggage,
  Map,
  Users2
} from 'lucide-react'

import {
  formatDayOfWeek,
  formatMonth,
  formatTime
} from '../../../../../../utils/formattedDate'
import dayjs from 'dayjs'
import { GoogleMap, Marker, DirectionsRenderer } from '@react-google-maps/api'
import { useTransferContext } from '../../../../../../contexts/TransferContext'

export const DetailsTransfer = ({ transfer }) => {
  const { isLoaded, calculateRouteWithoutState } = useTransferContext()
  const [results, setResults] = useState(undefined)
  const distance = results ? results.routes[0].legs[0].distance : undefined
  const duration = results ? results.routes[0].legs[0].duration : undefined
  console.log({ transfer })
  const estimatedArrival = duration
    ? dayjs(new Date(transfer.transferDate.split('.')[0]))
        .add(duration.value, 'seconds')
        .format('HH:mm')
    : undefined
  useEffect(() => {
    ;(async () => {
      if (!transfer || !isLoaded) return
      const origin = {
        latitude: transfer.startingLatitude,
        longitude: transfer.startingLongitude
      }
      const destination = {
        latitude: transfer.arrivalLatitude,
        longitude: transfer.arrivalLongitude
      }
      const result = await calculateRouteWithoutState(origin, destination)
      setResults(result)
    })()
  }, [transfer, isLoaded])

  return transfer ? (
    <>
      <LoadingOverlay overlayOpacity={0.5} visible={false} />

      <Styled.CardContainer>
        <Styled.Container key={transfer.id}>
          {results && isLoaded && (
            <Styled.MapWrapper>
              {duration && distance && (
                <div className="route-info">
                  <div>
                    <Distance />
                    <div>
                      <p>DISTÂNCIA</p>
                      <strong>{distance.text.replace(/\s/, '')}</strong>
                    </div>
                  </div>
                  <div>
                    <Duration />
                    <div>
                      <p>ESTIMATIVA DE CHEGADA</p>
                      <strong>{estimatedArrival}</strong>
                    </div>
                  </div>
                </div>
              )}
              <GoogleMap
                center={results}
                zoom={15}
                mapContainerStyle={{ width: '100%', height: '100%' }}
                options={{
                  zoomControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                  scaleControl: false,
                  rotateControl: false,
                  scrollwheel: false,
                  disableDoubleClickZoom: true,
                  disableDefaultUI: true,
                  keyboardShortcuts: false,
                  mapTypeControlOptions: false,
                  clickableIcons: false,
                  draggableCursor: 'default',
                  draggingCursor: 'default',
                  controlSize: false,
                  fullscreenControlOptions: false,
                  streetViewControlOptions: false,
                  gestureHandling: 'none'
                }}
              >
                <Marker position={''} />
                <DirectionsRenderer
                  options={{
                    suppressInfoWindows: true
                  }}
                  directions={results}
                />
              </GoogleMap>
            </Styled.MapWrapper>
          )}
          <Styled.Wrapper>
            <Styled.InfoWrapper>
              <Styled.TransferTitle>
                <Car />
                Detalhes do transfer
              </Styled.TransferTitle>
            </Styled.InfoWrapper>
            <Styled.TimeAndStatusWrapper>
              <Styled.StatusCard>
                {formatDayOfWeek(transfer.transferDate)},{' '}
                {new Date(transfer.transferDate)
                  .getDate()
                  .toString()
                  .padStart(2, '0')}
                /{formatMonth(transfer.transferDate)} -{' '}
                {formatTime(transfer.transferDate)}
              </Styled.StatusCard>

              <Styled.Id>
                <strong>ID:</strong> {transfer.id}
              </Styled.Id>
            </Styled.TimeAndStatusWrapper>

            {/* <Styled.ValuesWrapper>
              <MediaQuery
                query="(max-width: 1024px)"
                styles={{ display: 'none' }}
              >
                <Styled.PriceTitle>
                  <Styled.DepartureTitle>Distância</Styled.DepartureTitle>
                  {transfer.distanceInKm}km
                </Styled.PriceTitle>
              </MediaQuery>
            </Styled.ValuesWrapper> */}
            <Styled.InfoWrapper>
              <Styled.AddressWrapper>
                <Styled.DepartureWrapper>
                  <Styled.DepartureTitle>
                    <Pin /> Partida
                  </Styled.DepartureTitle>
                  <Styled.DepartureDescription>
                    {transfer.startingPlace}
                  </Styled.DepartureDescription>
                </Styled.DepartureWrapper>
                <Styled.DirectionButton>
                  <Direction />
                </Styled.DirectionButton>
                <Styled.DepartureWrapper>
                  <Styled.DepartureTitle>
                    <Pin /> Chegada
                  </Styled.DepartureTitle>
                  <Styled.DepartureDescription>
                    {transfer.arrivalPlace}
                  </Styled.DepartureDescription>
                </Styled.DepartureWrapper>
              </Styled.AddressWrapper>
              {transfer?.bookingStops.map((item) => (
                <Styled.DepartureWrapper key={item.id} stops>
                  <Styled.DepartureTitle>
                    <Pin /> Parada
                  </Styled.DepartureTitle>
                  <Styled.DepartureDescription>
                    {item.place}
                  </Styled.DepartureDescription>
                </Styled.DepartureWrapper>
              ))}
              {transfer?.passengerStops.map((item) => (
                <Styled.DepartureWrapper key={item.id} stops>
                  <Styled.DepartureTitle>
                    <Pin /> Parada
                  </Styled.DepartureTitle>
                  <Styled.DepartureDescription>
                    {item.place}
                  </Styled.DepartureDescription>
                </Styled.DepartureWrapper>
              ))}
              {/* <Styled.FlightInfoWrapper>
                <div>
                  <Plane />
                  <div>
                    <strong>NÚMERO DO VOO</strong>
                    <p>3295</p>
                  </div>
                </div>
                <div>
                  <strong>ESTIMATIVA DE CHEGADA</strong>
                  <p>21:30</p>
                </div>
                <div>
                  <strong>TERMINAL</strong>
                  <p>1</p>
                </div>
                <div>
                  <strong>PORTÃO</strong>
                  <p>4</p>
                </div>
              </Styled.FlightInfoWrapper> */}
              {/* <MediaQuery
                query="(max-width: 1024px)"
                styles={{ display: 'none' }}
              >
                <Styled.DistanceMobile>
                  <a
                    href={`https://www.google.com/maps/dir/?api=1&origin=${transfer.startingLatitude},${transfer.startingLongitude}&destination=${transfer.arrivalLatitude},${transfer.arrivalLongitude}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Map color="#27c7ff" size={24} /> Google Maps
                    <ArrowUpRight color="#27c7ff" size={24} />
                  </a>
                </Styled.DistanceMobile>
              </MediaQuery> */}

              <Styled.IconsAndNumbersWrapper>
                {transfer.backpacks > 0 && (
                  <Styled.IconAndNumber>
                    <Styled.IconsBackground>
                      <Backpack color="#27c7ff" size={24} />
                    </Styled.IconsBackground>
                    <span>
                      <strong>{transfer.backpacks}</strong> Mochila
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer.checkedLuggages > 0 && (
                  <Styled.IconAndNumber>
                    <Styled.IconsBackground>
                      <Luggage color="#27c7ff" size={24} />
                    </Styled.IconsBackground>
                    <span>
                      <strong>{transfer.checkedLuggages}</strong> Bagagem
                      despachada
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer.handLuggages > 0 && (
                  <Styled.IconAndNumber>
                    <Styled.IconsBackground>
                      <Briefcase color="#27c7ff" size={24} />
                    </Styled.IconsBackground>
                    <span>
                      <strong>{transfer.handLuggages}</strong> Bagagem de mão
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer.numberOfPassengers > 0 && (
                  <Styled.IconAndNumber>
                    <Styled.IconsBackground>
                      <Users2 size={24} color="#27c7ff" />
                    </Styled.IconsBackground>
                    <span>
                      <strong>{transfer.numberOfPassengers} </strong>
                      Passageiro(s)
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer.surfboards > 0 && (
                  <Styled.IconAndNumber>
                    <Surf />
                    <span>
                      <strong>{transfer.surfboards} </strong>Prancha(s) de surf
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer.babySeats > 0 && (
                  <Styled.IconAndNumber>
                    <ChildChair />
                    <span>
                      <strong>{transfer.babySeats} </strong>Cadeira de bebê
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer.childSeats > 0 && (
                  <Styled.IconAndNumber>
                    <ChildChair />
                    <span>
                      <strong>{transfer.childSeats} </strong>Cadeira de bebê
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer.boosterSeats > 0 && (
                  <Styled.IconAndNumber>
                    <ChildChair />
                    <span>
                      <strong>{transfer.boosterSeats} </strong>Cadeira de bebê
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer.animals > 0 && (
                  <Styled.IconAndNumber>
                    <Pet />
                    <span>
                      <strong>{transfer.animals}</strong> Pet
                    </span>
                  </Styled.IconAndNumber>
                )}
              </Styled.IconsAndNumbersWrapper>
            </Styled.InfoWrapper>
          </Styled.Wrapper>
          {transfer?.bookingStops.length > 0 && (
            <>
              <Styled.TransferTitle
                style={{
                  marginBottom: '8px'
                }}
              >
                Paradas
              </Styled.TransferTitle>
              {transfer?.bookingStops.map((item) => (
                <div
                  key={item.id}
                  style={{
                    display: 'flex',
                    gap: '16px',
                    marginBottom: '8px'
                  }}
                >
                  <Styled.DepartureWrapper>
                    <Styled.DepartureTitle>Local</Styled.DepartureTitle>
                    <Styled.DepartureDescription>
                      {item?.stop?.place}
                    </Styled.DepartureDescription>
                  </Styled.DepartureWrapper>
                  <Styled.DepartureWrapper>
                    <Styled.DepartureTitle>Duração</Styled.DepartureTitle>
                    <Styled.DepartureDescription>
                      {item?.stop?.timeInMinutes} minuto(s)
                    </Styled.DepartureDescription>
                  </Styled.DepartureWrapper>
                </div>
              ))}
            </>
          )}
        </Styled.Container>
      </Styled.CardContainer>
    </>
  ) : (
    <div></div>
  )
}
