import { CarCard } from './components/CarCard'
import * as Styled from './styles'

import { CarItem } from './CarItem'
import { useTransferContext } from '../../../../contexts/TransferContext'
import { useEffect } from 'react'

export const ChoiceCar = ({ setOpened, opened, selectedCar = CarItem[0] }) => {
  const { carSelected, setCarSelected } = useTransferContext()

  useEffect(() => {
    if (carSelected === undefined) {
      setCarSelected(CarItem[0])
    }
  }, [])

  return (
    <Styled.Container>
      <CarCard setOpened={setOpened} opened={opened} car={selectedCar} />
    </Styled.Container>
  )
}
