import * as Styled from './styles'
import { ReactComponent as DriverImage } from './imgs/DriverImage.svg'
import { ReactComponent as CloseIcon } from './imgs/CloseIcon.svg'
import { Radio } from '@mantine/core'
import { useState } from 'react'
import { CancelOptions } from './constant'

export const RejectModal = ({ opened, onClose, onFinish, data, setData }) => {
  const handleChange = (field, value) => {
    setData({ ...data, [field]: value })
  }

  return (
    <>
      <Styled.ModalOverlay opened={opened} onClick={onClose} />
      <Styled.ModalContainer opened={opened}>
        <Styled.ModalHeader>
          <h1>Cancelar</h1>
          <Styled.CloseButton>
            <CloseIcon onClick={onClose} />
          </Styled.CloseButton>
        </Styled.ModalHeader>
        <Styled.ModalContent>
          <Styled.ModalTitle>
            {CancelOptions.map((item, i) => (
              <Styled.RadioWrapper key={i}>
                <Radio
                  label={item.label}
                  value={item.value}
                  checked={data?.cancel_modal === item.value}
                  onChange={(e) => handleChange('cancel_modal', e.target.value)}
                />
              </Styled.RadioWrapper>
            ))}
          </Styled.ModalTitle>
        </Styled.ModalContent>
        <Styled.ModalButton onClick={onFinish}>SIM</Styled.ModalButton>
      </Styled.ModalContainer>
    </>
  )
}
