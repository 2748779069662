import styled from 'styled-components'
import backgroundCar from './imgs/background-login-img.webp'
import backgroundCarMobile from './imgs/background-login-img.webp'

export const Container = styled.section`
  background-image: url(${backgroundCar});
  height: 100vh;
  width: 100%;
  /* background-position: 20% 50px; */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 768px) {
    background-image: url(${backgroundCarMobile});
    background-position: 50% 30%;
  }
`

export const Background = styled.img`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
`
