import styled from 'styled-components'

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* max-width: 90rem; */
  width: 100%;
  /* margin: 0 auto; */
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  max-width: 120rem;
  width: 100%;
  padding: 0 2rem;
  margin: 4rem auto 15rem;

  > strong {
    font: 600 14px Neometric, sans-serif;
    line-height: 18.2px;
  }
`

export const TitleContainer = styled.div``

export const Title = styled.h1`
  font-family: 'Neometric';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48.01px;
  color: #222222;

  margin-top: 6rem;
  padding-bottom: 40px;
  padding-top: 50px;

  width: 100%;

  background: linear-gradient(
    95.8deg,
    #27c7ff -23.21%,
    rgba(255, 255, 255, 0) 75.84%
  );

  h1 {
    font-family: inherit;
    font-style: inherit;
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    max-width: 120rem;
    width: 100%;
    padding: 0 2rem;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    /* padding: 24px 16px; */
    /* margin: 24px 16px; */
    /* padding: 16px; */
    padding: 0;
    /* text-align: left; */

    /* height: 128px; */
    padding-top: 24px;
    padding-bottom: 24px;
    /* margin: 2rem 1rem; */

    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.01em;
    text-align: left;
  }
`

export const SubTitle = styled.h2`
  font: 700 20px Neometric, sans-serif;
  line-height: 21.6px;
  color: #000000;
`

export const Information = styled.p`
  white-space: pre-line;
  font: 400 14px Neometric, sans-serif;
  line-height: 18.2px;
`

export const Description = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-indent: 32px;
  margin: 2rem 156px;

  color: #222222;

  @media (max-width: 768px) {
    margin: 24px 16px;
  }
`

export const Container = styled.div`
  /* padding: 0 2rem; */
  margin-top: 2rem;

  @media only screen and (max-width: 768px) {
    ${Title} {
      text-align: left;
      font-size: 32px;
      line-height: 38.41px;
      margin-bottom: 0px;
    }

    ${Description} {
      font-size: 1.4rem;
    }

    ${Description}:first-of-type {
      /* margin-top: 3.6rem; */
      margin-top: 16px;
      /* margin-bottom: 30px; */
    }

    ${Description}:last-of-type {
      margin-top: 1.6rem;
      margin-bottom: 0;
    }
  }
`
