import styled from 'styled-components'
export const Container = styled.div`
    width: 454.14px;
    height: 500px;
    top: 290px;
    left: 733px;
    padding: 40px 32px 40px 32px;
    border-radius: 16px;
    box-shadow: 0px 31.010395050048828px 33.82952117919922px 0px #00000012;
    box-shadow: 0px -1.409563422203064px 22.553014755249023px 0px #0000000d;
    background-color: white;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .backButtonLogin{
        background-color: transparent;
    }

    @media (max-width: 768px) {
        width: 360px;
        height: 470px;
        margin: 0 auto;

        label{
        font-family: Neometric;
        font-size: 12px !important;
        font-weight: 600 !important;
        line-height: 14px !important;
        letter-spacing: 0px !important;
        }
      }
    @media (max-width: 375px){
      width: 90%;
    }



     h1 {
      margin-bottom: 24px;
      font-weight: 600;
      font-size: 24px;
      line-height: 120%;

      letter-spacing: 0.01em;

      color: #0D0D0D;
    }

    label {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;

      color: #0D0D0D;
      margin: 10px 0;
    }

    input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12.1898px 8px;
      gap: 112.77px;

      width: 100%;
      height: 54px;

      border: 0.704782px solid rgba(56, 40, 14, 0.3);
      border-radius: 8.45738px;

      margin-bottom: 10px;

      font-family: 'Neometric';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      color: #0D0D0D;

      &:placeholder {
        color: #a2a2a2;
      }

      &:focus {
        border-color: #27c7ff;
      }

      button {
        mantine-ActionIcon-root
      btn-minus
      mantine-47hpvh {
      height: 22px;
      width: 22px;
      }
    }
  }
`
export const Title = styled.h1`
  font-family: Neometric !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 29px !important;
  letter-spacing: 0.01em !important;
  text-align: center;

  @media (max-width: 768px) {
    & {
      font-family: Neometric;
      font-size: 16px !important;
      font-weight: 400 !important;
      line-height: 19px !important;
      letter-spacing: 0.01em !important;
    }
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`

export const SaveButton = styled.button`
  width: 403px;
  height: 53.59px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 17.2958px 23.0611px;

  background: #27c7ff;
  border-radius: 11.5305px;

  font-family: 'Roboto' !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-transform: uppercase;

  color: #ffffff;

  @media (max-width: 768px) {
    & {
      width: 100%;
    }
  }
`
export const Logo = styled.img`
  width: 100px;
  height: 100px;
  position: absolute;
  top: -5rem;
`

export const Wrapper = styled.div`
  z-index: 2;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Password = styled.div`
  font-family: 'Neometric';
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-align: left;
  margin: 1rem 0;
  display: flex;
  gap: 8px;

  @media (max-width: 768px) {
    & {
      font-family: 'Neometric';
      font-size: 10px;
      font-weight: 600;
      line-height: 13px;
      letter-spacing: 0.01em;
      text-align: left;
      gap: 5px;
    }
  }
`

export const PasswordButton = styled.button`
  background-color: transparent;
  color: #27c7ff;
  font-family: Neometric;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.01em;
  text-align: left;
  @media (max-width: 768px) {
    & {
      font-family: Neometric;
      font-size: 10px;
      font-weight: 600;
      line-height: 13px;
      letter-spacing: 0.01em;
      text-align: left;
    }
  }
`

export const FormWrapper = styled.div`
  h1 {
    font-family: Neometric;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
  }
`

export const EmailWrapper = styled.div`
  display: flex;
  gap: 10rem;
  flex-direction: column;
`
export const PasswordsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
`

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`
