import React from 'react'
import * as Styled from './styles'
import { useNavigate } from 'react-router-dom'

export function TopBar() {
  const navigate = useNavigate()
  return (
    <Styled.HeaderContainer>
      <Styled.OrderBumpLabel>Order Bump</Styled.OrderBumpLabel>
      <Styled.RouteButtonContainer>
        <Styled.NewRouteButton
          onClick={() => navigate('/dashboard/order-bump/novo')}
        >
          Nova Rota
        </Styled.NewRouteButton>
      </Styled.RouteButtonContainer>
    </Styled.HeaderContainer>
  )
}
