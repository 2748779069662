import { useEffect, useState, useCallback } from 'react'
import FirstImage from '../../imgs/slide 1@1x.webp'
import SecondImage from '../../imgs/slide 2@1x.webp'
import ThirdImage from '../../imgs/slide 3@1x.webp'
import FourthImage from '../../imgs/slide 4@1x.webp'
import * as S from './styles'

const TIME_TO_SWOTCH_IMAGE = 4000

const images = [FirstImage, SecondImage, ThirdImage, FourthImage]

export const ImagesCarousel = () => {
  const [currentImage, setCurrentImage] = useState(0)

  const changeImage = useCallback(() => {
    setCurrentImage((prevState) => (prevState >= 3 ? 0 : prevState + 1))
  }, [])

  useEffect(() => {
    const interval = setInterval(changeImage, TIME_TO_SWOTCH_IMAGE)

    return () => clearInterval(interval)
  }, [])

  return (
    <S.CarouselContainer curr={currentImage}>
      {images.map((img, index) => (
        <S.SelectedImage key={index} src={img} />
      ))}
      {/* <S.CurrentImageIndicatorContainer currentImage={currentImage}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </S.CurrentImageIndicatorContainer> */}
    </S.CarouselContainer>
  )
}
