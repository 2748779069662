import styled, { css } from 'styled-components'
import { Badge as UIBadge } from '../../../../../../components/Badge'
import { Button as UIButton } from '../../../../../../components/Button'
import { Calendar as UICalendar } from '../../../../../../components/Calendar'
import {
  Card as UICard,
  CardContent as UICardContent,
  CardHeader as UICardHeader
} from '../../../../../../components/Card'
import {
  Popover as UIPopover,
  PopoverContent as UIPopoverContent,
  PopoverTrigger as UIPopoverTrigger
} from '../../../../../../components/Popover'
import {
  Select as UISelect,
  SelectContent as UISelectContent,
  SelectItem as UISelectItem,
  SelectTrigger as UISelectTrigger,
  SelectValue as UISelectValue
} from '../../../../../../components/Select'

export const Wrapper = styled.div``

export const Badge = styled(UIBadge)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  border-radius: 0.6rem;
  background-color: #E9F9FF;
  color: #27c7ff;

  > svg {
    width: 2.4rem;
    height: 2.4rem;
  }

  > span {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  :hover {
    background-color: #fff9e5;
    opacity: 0.8;
  }
`

export const Button = styled(UIButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #27c7ff;
  border-radius: 1.6rem;
  padding: 1.6rem 0;
  color: #ffffff;
  line-height: 1.6rem;

  :hover {
    background-color: #27c7ff;
    opacity: 0.85;
  }
`

export const PopoverButton = styled(UIButton)`
  ${({ notValue }) => css`
    width: 100%;
    justify-content: flex-start;
    text-align: left;
    font-weight: 400;
    border: 0.1rem solid #d1d5db;
    display: flex !important;
    align-items: center !important;

    > svg {
      margin-right: 0.8rem;
      height: 1.6rem;
      min-height: 1.6rem;
      min-width: 1.6rem;
      width: 1.6rem;
    }

    > span {
      color: #9ca3af;
    }

    ${!notValue &&
    css`
      > svg {
        color: #3f3f46;
      }
    `}

    ${notValue &&
    css`
      color: hsl(215.4 16.3% 46.9%);
    `}
  `}
`

export const Calendar = styled(UICalendar)``

export const Card = styled(UICard)`
  border: none;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
`

export const CardHeader = styled(UICardHeader)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  > * + * {
    margin-top: 0;
  }

  > span {
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.8rem;
  }
`

export const CardContent = styled(UICardContent)`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;

    > :nth-child(1) {
      margin-top: auto;
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 3.2rem;
    }

    > :nth-child(2) {
      font-size: 3.6rem; /* 36px */
      line-height: 4rem; /* 40px */
      font-weight: 700;
    }

    > :nth-child(3) {
      margin-top: auto;
      font-size: 2rem;
      line-height: 2.8rem;
      font-weight: 600;
    }
  }

  > form {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    > div {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      > div:first-of-type {
        width: 100%;

        > div:last-of-type {
          display: flex;
          flex-wrap: wrap;
          gap: 0.8rem;

          p {
            font-size: 1.4rem;
            line-height: 2rem;
            color: #f87171;
          }
        }

        @media (min-width: 640px) {
          width: 66.666667%;
        }
      }

      > div:last-of-type {
        flex: 1 1 0%;

        p {
          font-size: 1.4rem;
          line-height: 2rem;
          color: #f87171;
        }
      }

      @media (min-width: 640px) {
        flex-direction: row;
      }
    }
  }

  > div:last-of-type {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    font-size: 1.4rem;
    line-height: 2rem;

    > div {
      display: flex;
      align-items: center;
      gap: 0.4rem;
      font-weight: 600;
      font-size: 1.6rem;
      color: #6b7280;

      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    > span {
      color: #6b7280;
      font-size: 1.2rem;
    }

    @media (min-width: 640px) {
      flex-direction: row;
      align-items: center;
    }
  }
`

export const Popover = styled(UIPopover)``

export const PopoverContent = styled(UIPopoverContent)`
  display: flex;
  width: auto;
  flex-direction: column;
  padding: 0;
  z-index: 200;

  > * + * {
    margin-top: 0.8rem;
  }
`

export const PopoverTrigger = styled(UIPopoverTrigger)``

export const Select = styled(UISelect)``

export const SelectContent = styled(UISelectContent)`
  z-index: 300;
  max-height: 20rem;
`

export const SelectItem = styled(UISelectItem)`
  ${({ hideIcon }) => css`
    padding: 1.4rem 0;

    ${hideIcon &&
    css`
      text-align: left;
      svg {
        display: none;
      }
    `}
  `}
`

export const SelectTrigger = styled(UISelectTrigger)`
  width: 100%;

  border: 0.1rem solid #d1d5db;

  > svg {
    width: 1.6rem;
    height: 1.6rem;
    color: #9ca3af;
  }
`

export const PopoverSelectTrigger = styled(UISelectTrigger)`
  > svg {
    width: 1.6rem;
    height: 1.6rem;
    color: #9ca3af;
  }
`

export const SelectValue = styled(UISelectValue)`
  > span {
    font-size: 1.6rem;
    color: #9ca3af;
  }
`
