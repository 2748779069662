import styled from 'styled-components'

import {
  Carousel as UICarousel,
  CarouselContent as UICarouselContent,
  CarouselItem as UICarouselItem,
  CarouselNext as UICarouselNext,
  CarouselPrevious as UICarouselPrevious
} from '../../../../components/Carousel'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    max-width: 14.4rem;
  }

  > div:first-of-type {
    font-size: 1.4rem;
    line-height: 2rem;

    span {
      font-weight: bold;
      font-size: 1.6rem;
    }
  }
`

export const Carousel = styled(UICarousel)`
  margin-top: 2.4rem;
  display: flex;
  width: 100%;
  flex-direction: column;

  > div:last-of-type {
    margin: 0 auto;
    margin-top: 4rem;
    display: flex;
    gap: 0.8rem;
  }
`

export const CarouselContent = styled(UICarouselContent)`
  padding-left: 1.2rem;
`

export const CarouselItem = styled(UICarouselItem)`
  @media (min-width: 768px) {
    flex-basis: 50%;
  }

  @media (min-width: 1024px) {
    flex-basis: 33.333333%;
  }
`

export const CarouselPrevious = styled(UICarouselPrevious)`
  position: static;
  border: none;
  background-color: #27272a;
  color: #fbb13c;

  :hover {
    background-color: #27272a;
    opacity: 0.9;
    color: rgba(251, 177, 60, 0.7);
  }
`

export const CarouselNext = styled(UICarouselNext)`
  position: static;
  border: none;
  background-color: #27272a;
  color: #fbb13c;

  :hover {
    background-color: #27272a;
    opacity: 0.9;
    color: rgba(251, 177, 60, 0.7);
  }
`
