import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  max-width: 840px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  margin-top: 16px;
`
export const CardContainer = styled.div`
  display: flex;
  padding: 16px 16px 16px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  border-radius: 16px;
  border: 1px solid var(--grey-3-grayscale, #bfbfbf);

  position: relative;
`

export const Card = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  height: 190px;
`

export const UploadSection = styled.div`
  width: 200px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 2px dashed var(--grey-3-grayscale, #bfbfbf);

  #drop-element {
    width: 100%;
    height: 100%;
    padding: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    overflow: hidden;
  }

  #drop-element div > div > figure > div > img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }

  #delete-container {
    visibility: hidden;
  }

  :hover {
    #delete-container {
      visibility: visible;
    }
  }
`
export const DragImageText = styled.p`
  color: var(--grey-1-grayscale, #404040);
  text-align: center;
  font-family: Neometric;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
export const StopsSection = styled.div`
  flex: 1;
`
export const StopLocation = styled.div`
  input[type='text'] {
    display: flex;
    align-items: center;
    padding: 12.19px 45px;
    flex: 1;

    height: 54px;

    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    border-radius: 8px;
    border: 0.508px solid var(--grey-3-grayscale, #bfbfbf);
  }
`
export const StopLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 12px;

  svg:hover {
    cursor: pointer;
  }
`

export const StopLabel = styled.label`
  color: var(--grey-1-grayscale, #404040);
  font-family: Neometric;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  height: 100%;
`

export const InputStop = styled.input`
  display: flex;
  height: 54px;
  padding: 12.19px 8px;
  align-items: center;
  align-self: stretch;

  border-radius: 8px;
  border: 0.508px solid var(--grey-3-grayscale, #bfbfbf);
`
export const PriceTimeContainer = styled.div`
  display: flex;

  gap: 16px;

  margin-top: 16px;
  width: 100%;

  input[type='text'] {
    padding: 15px 45px;
    flex: 1;

    height: 54px;
    gap: 16px;

    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    border-radius: 8px;
    border: 0.508px solid var(--grey-3-grayscale, #bfbfbf);
  }

  label {
    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
  }

  #select {
    gap: 16px;
    height: 54px;
    padding: 15px 45px;
    vertical-align: 'center';
    display: flex;
    align-items: center;

    flex: 1;

    border-radius: 8px;
    border: 0.508px solid var(--grey-3-grayscale, #bfbfbf);

    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`

export const StopNumber = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 8px 8px 8px 0px;
  padding: 8px 12px 8px 12px;
  gap: 10px;
  background-color: #808080;

  font-family: Neometric;
  font-weight: 500;
  font-size: 18px;
  line-height: 21.6px;
  text-align: center;

  position: absolute;
  z-index: 1;
  color: #ffffff;
  left: 0px;
  top: 4px;
`
export const DeletePicture = styled.div`
  position: absolute;
  display: flex;
  align-items: center;

  top: 18px;
  left: 175px;

  font-size: 500;
  z-index: 2;
  height: 32px;

  width: auto;
  cursor: pointer;

  transition: ease-out 0.2s;

  svg {
    height: 25px;
    opacity: 0.8;
  }
`
