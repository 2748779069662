import styled from 'styled-components'

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ opened }) => (opened ? 'block' : 'none')};
  z-index: 100;
`

export const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-end;
  z-index: 200;

  gap: 10px;
  border-radius: 22px;
  width: 47.6rem;
  background: #fff;
  box-shadow: 0px 22px 24px 0px rgba(0, 0, 0, 0.07),
    0px -1px 16px 0px rgba(0, 0, 0, 0.05);
  display: ${({ opened }) => (opened ? 'flex' : 'none')};
`

export const ModalContent = styled.div`
  width: 100%;
  margin-bottom: 5.6rem;

  strong {
    display: block;
    color: var(--black-brand-primary, #222);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.34px;
    line-height: normal;
    margin: 0.8rem 0 1.6rem;
  }

  ul li {
    list-style: none;

    & + li {
      margin-top: 1.6rem;
    }

    > .mantine-Checkbox-root {
      margin-top: 1.2rem;

      .mantine-Checkbox-body {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .mantine-Checkbox-label {
        color: var(--grey-1-grayscale, #404040);
        font-family: Neometric;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
      }

      .mantine-Checkbox-inner {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }

      .mantine-Checkbox-input {
        width: 24px;
        height: 24px;
        border-radius: 0.8rem;
        padding: 0;
        cursor: pointer;
      }
    }
  }
`

export const ModalContentDriver = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`
export const CategoryDriver = styled.div`
  display: flex;
  width: 150px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--grey-4-grayscale, #f7f7f7);
`
export const DriverContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--grey-4-grayscale, #f7f7f7);
`
export const CategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
`

export const DriversWrapper = styled.div`
  width: 100%;
  height: 300px;
  overflow-y: scroll;
`

export const DriversContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  width: 100%;
`
export const CategoryTitle = styled.span`
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px 8px 0px 0px;
  background: var(--yellow-2-support, #DFF7FF);
  color: var(--yellow-brand-primary, #27c7ff);
  font-family: Neometric;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const Categories = styled.span`
  color: var(--grey-1-grayscale, #404040);
  font-family: Neometric;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: normal;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  p {
    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  span {
    color: var(--grey-1-grayscale, #404040);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .mantine-Checkbox-label {
    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`
export const Drivers = styled.span`
  color: var(--grey-1-grayscale, #404040);
  font-family: Neometric;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  gap: 10px;
  p {
    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  span {
    color: var(--grey-1-grayscale, #404040);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .mantine-Checkbox-label {
    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`

export const ModalContentSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  margin-bottom: 10px;
`
export const ModalTitle = styled.p`
  color: var(--grey-1-grayscale, #404040);
  text-align: center;
  font-family: Neometric;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  letter-spacing: 0.14px;
  align-self: stretch;
`
export const ModalTitleSelect = styled.p`
  color: var(--grey-1-grayscale, #404040);
  font-family: Neometric;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .mantine-Input-input {
    display: flex;
    height: 54px;
    padding: 12.19px 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    color: #a2a2a2;
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 1.016px solid var(--grey-4-grayscale, #f7f7f7);
  }
  .mantine-Select-root {
    width: 100%;
  }

  .mantine-Select-input {
    text-align: center;
  }
`

export const ModalButton = styled.button`
  display: flex;
  height: 56px;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 16px;
  background: var(--yellow-brand-primary, #27c7ff);
  color: var(--white-grayscale, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 24px */
  letter-spacing: 0.2px;
  text-transform: uppercase;

  :disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
`

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    color: var(--black-brand-primary, #222);
    font-family: Neometric;
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.24px;
  }
`

export const CloseButton = styled.button`
  width: 32px;
  height: 32px;
  background-color: transparent;
`
