import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: absolute;
  top: 42px;
  right: 0;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-end;
  z-index: 9;

  gap: 10px;
  border-radius: 22px;
  width: 34rem;
  background: #fff;
  box-shadow: 0px 22px 24px 0px rgba(0, 0, 0, 0.07),
    0px -1px 16px 0px rgba(0, 0, 0, 0.05);
  display: flex;
`

export const Content = styled.div`
  width: 100%;
  margin-bottom: 5.6rem;

  strong {
    display: block;
    color: var(--black-brand-primary, #222);
    font-family: Neometric;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.34px;
    line-height: normal;
    margin: 0.8rem 0 1.6rem;
  }

  ul li {
    list-style: none;

    & + li {
      margin-top: 1.6rem;
    }

    > .mantine-Checkbox-root {
      margin-top: 1.2rem;

      .mantine-Checkbox-body {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      .mantine-Checkbox-label {
        color: var(--grey-1-grayscale, #404040);
        font-family: Neometric;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
      }

      .mantine-Checkbox-inner {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }

      .mantine-Checkbox-input {
        width: 24px;
        height: 24px;
        border-radius: 0.8rem;
        padding: 0;
        cursor: pointer;
      }
    }
  }
  .mantine-Input-icon {
    left: 2rem;
  }

  .mantine-DatePickerInput-input {
    border-radius: 6.095px;
    border: 0.508px solid var(--grey-3-grayscale, #bfbfbf);
    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 12.19px 8px;
    text-align: center;
    margin-top: 1.4rem;
  }
`

const buttonModifiers = {
  outlined: () => css`
    border: 1px solid #27c7ff;
    color: #27c7ff;
    background: transparent;
  `
}

export const Button = styled.button`
  ${({ outlined }) => css`
    display: flex;
    height: 56px;
    padding: 24px 32px;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 16px;
    background: var(--yellow-brand-primary, #27c7ff);
    color: var(--white-grayscale, #fff);
    text-align: center;
    font-family: Roboto;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 24px */
    letter-spacing: 0.2px;
    text-transform: uppercase;

    :disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }

    ${outlined && buttonModifiers.outlined()}
  `}
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    color: var(--black-brand-primary, #222);
    font-family: Neometric;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.24px;
  }
`

export const CloseButton = styled.button`
  width: 32px;
  height: 32px;
  background-color: transparent;
`
