import React from 'react'
import styled from 'styled-components'
import { IconCircleCheck, IconCircleX } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

const Status = ({ status, type, paymentRedirect, paymentMethod }) => {
  const { t } = useTranslation()

  const statusMapPayment = {
    APPROVED: {
      background: ' #D5F6EA',
      color: '#48D9A4',
      icon: <IconCircleCheck />,
      message: t('checkoutVerify.paymentCompleted')
    },
    PENDING: {
      background: ' #FDCED6',
      color: '#F8294E',
      icon: <IconCircleX />,
      message: (
        <div>
          {paymentMethod === 'Card' || paymentMethod === 'CREDIT_CARD' ? (
            <>
              <span>{t('checkoutVerify.paymentPending')}.</span>
              <strong
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
                onClick={() => paymentRedirect()}
              >
                {t('checkoutVerify.tryAgainHere')}
              </strong>
            </>
          ) : (
            <>
              <span>{t('checkoutVerify.paymentPending')}.</span>
            </>
          )}
        </div>
      )
    }
  }
  const statusMap = {
    PENDING: {
      background: ' #D5F6EA',
      color: ' #48D9A4',
      icon: <IconCircleCheck />,
      message: t('checkoutVerify.bookingMade')
    }
  }
  // Styled component para o status
  const StyledStatus = styled.div`
    display: flex;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    background: ${(props) =>
      type === 'payment'
        ? statusMapPayment[props.status]?.background
        : statusMap[props.status]?.background};
    color: ${(props) =>
      type === 'payment'
        ? statusMapPayment[props.status]?.color
        : statusMap[props.status]?.color};
    text-align: center;
    font-family: Neometric;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 23.4px */
    letter-spacing: 0.18px;
  `

  return (
    <StyledStatus status={status}>
      <span>
        {(type === 'payment' ? statusMapPayment : statusMap)[status]?.message}
      </span>{' '}
      {type === 'payment'
        ? statusMapPayment[status]?.icon
        : statusMap[status]?.icon}
    </StyledStatus>
  )
}

export default Status
