import {
  Button,
  Divider,
  Flex,
  LoadingOverlay,
  MediaQuery,
  Popover,
  Text
  // , Pagination
} from '@mantine/core'
import * as Styled from './styles'
import { ReactComponent as Direction } from './imgs/direction.svg'
import { ReactComponent as Pin } from './imgs/mapPin.svg'
import { ReactComponent as Canceled } from './imgs/icon_canceled.svg'
import { ReactComponent as Accepted } from './imgs/icon_accepted.svg'
import { ReactComponent as Passenger } from './imgs/lucide_users-2.svg'
import Chevron from './imgs/right.webp'
import { useEffect, useRef } from 'react'
import fetchBooking from '../../../../../../services/booking'
import { useState } from 'react'
import {
  formatDateAndTime,
  formatDayOfWeek,
  formatMonth,
  formatTime
} from '../../../../../../utils/formattedDate'
import { AcceptedModal } from '../AcceptedModal'
import { RejectModal } from '../RejectModal'
import { useAuth } from '../../../../../../contexts/useAuth'
import { toast } from 'react-toastify'
import { useClipboard, useDisclosure } from '@mantine/hooks'
import Clipboard from './imgs/icon_clipboard.svg'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import useIsElementVisible from '../../../../../../hooks/useIsElementVisible'
import { BriefcaseIcon, Copy, Users2 } from 'lucide-react'
import { useMediaQuery } from '../../../../../../hooks/useMediaQuery'
import { useTranslation } from 'react-i18next'

export const PendingCards = ({
  latitude,
  longitude,
  direction,
  search,
  status
}) => {
  const [transfer, setTransfer] = useState([])
  const [loading, setLoading] = useState(true)
  const [acceptedModal, setAcceptedModal] = useState(false)
  const [rejectModal, setRejectModal] = useState(false)
  const [transferContent, setTransferContent] = useState()
  const [transferCancel, setTransferCancel] = useState()
  const [refreshBookings, setRefreshBookings] = useState()
  const [comission, setComission] = useState()
  const [size, setSize] = useState(10)
  const isMobile = useMediaQuery('(max-width: 500px)')

  const clipboard = useClipboard({ timeout: 500 })
  const [popoverOpened, setPopoverOpened] = useState({})

  const { t } = useTranslation()

  const NAME_DECIDER = {
    PENDING: 'Pendente',
    ACCEPTED: 'Aceito',
    DONE: 'Realizado',
    IN_PROGRESS: 'Andamento',
    CANCELED: 'Cancelado'
  }

  const COLOR_DECIDER = {
    PENDING: '#27c7ff',
    ACCEPTED: '#48d9a4',
    DONE: '#48d9a4',
    IN_PROGRESS: '#9163cb',
    CANCELED: '#f8294e'
  }

  const { user } = useAuth()
  const navigate = useNavigate()
  const lastRef = useRef(null)

  const isLastVisible = useIsElementVisible(lastRef.current)

  useEffect(() => {
    if (isLastVisible) {
      setSize(size + 10)
    }
  }, [isLastVisible])

  useEffect(() => {
    if (!isLastVisible) {
      setLoading(true)
    }

    const filteredSearch = { ...search }

    for (const key in filteredSearch) {
      if (filteredSearch[key].trim() === '') {
        delete filteredSearch[key]
      }
    }

    const payload = {
      // page: 0,
      size: size,
      hasTour: false,
      hasEvent: false,
      status,
      driverId: status !== 'PENDING' ? user.id : undefined,
      direction,
      ...filteredSearch
    }

    fetchBooking
      .getAllBooking(payload)
      .then((res) => {
        setTransfer(res.data.content)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [refreshBookings, direction, search, size, status])

  const handleAcceptTransfer = (value, driver, setDriver) => {
    const payload = {
      id: value.id,
      driver: {
        id: driver ? driver : user.id
      }
    }

    if (user.id) {
      fetchBooking
        .acceptedBooking(value.id, user.id, comission)
        .then((res) => {
          if (res) {
            setAcceptedModal(false)
            setRefreshBookings(!refreshBookings)
            toast.success(t('toast.successAcceptedTransfer'))
            navigate(`/dashboard/details/${res.data.id}`)
            if (driver) {
              setDriver('')
            }
          }
        })
        .catch((err) => {
          console.log(err)
          if (
            err.response.data.message === 'Driver has a current punishment.'
          ) {
            toast.error(t('toast.driverHasActivePunishment'))
          }
        })
    }
  }
  const handleRejectTransfer = (value) => {
    const payload = {
      reasonToCancel: 'Motorista recusou transfer'
    }
    fetchBooking
      .cancelTransfer(value.id, payload)
      .then((res) => {
        setRefreshBookings(!refreshBookings)
        setRejectModal(false)
        toast.success(t('toast.successRefusedTransfer'))
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }

  const NormalButton = () => {
    return (
      <Styled.ButtonsWrapper style={{ cursor: 'pointer' }}>
        {/* <Styled.ChevronButton> */}
        <img src="/next.svg" alt="Right Chevron" />

        {/* </Styled.ChevronButton> */}
      </Styled.ButtonsWrapper>
    )
  }
  const AcceptDrive = ({ transfer }) => {
    return (
      <Styled.ButtonsWrapper>
        <Styled.RejectButton>
          <Button
            onClick={() => {
              setRejectModal(true)
              setTransferCancel(transfer)
            }}
            rightIcon={<Canceled />}
          >
            RECUSAR
          </Button>
        </Styled.RejectButton>
        <Styled.AcceptedButton
          onClick={() => {
            setAcceptedModal(true)
            setTransferContent(transfer)
            setComission(
              Math.floor(
                (transfer.totalPrice / 100) * user.commissionPercentage
              )
            )
          }}
        >
          <Button rightIcon={<Accepted />}>ACEITAR</Button>
        </Styled.AcceptedButton>
      </Styled.ButtonsWrapper>
    )
  }

  return transfer ? (
    <>
      <Styled.CardContainer>
        {loading ? (
          <div
            style={{
              width: '100%',
              height: '40rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <ClipLoader size={50} color="#27c7ff" />
          </div>
        ) : (
          transfer?.map((transfer) => {
            const totalPrice = transfer.totalPrice / 100
            const totalDriverCommission =
              ((transfer.totalPrice / 100) *
                (100 - user.commissionPercentage)) /
              100

            const netValue =
              ((transfer.totalPrice / 100) * user.commissionPercentage) / 100

            return (
              <Styled.Container
                color={COLOR_DECIDER[status]}
                key={transfer.id}
                onClick={() => {
                  if (status !== 'PENDING') {
                    navigate(`/dashboard/details/${transfer.id}`, {
                      replace: true
                    })
                  }
                }}
              >
                <Styled.StatusContainer>
                  <Styled.StatusCard color={COLOR_DECIDER[status]}>
                    {NAME_DECIDER[status]}
                  </Styled.StatusCard>
                  <Styled.TimeDescription>
                    {formatDateAndTime(transfer.transferDate)}
                  </Styled.TimeDescription>
                  <Styled.PriceMobile>
                    €{' '}
                    {(transfer.totalPrice / 100)
                      .toFixed(2)
                      .toLocaleString('pt-PT')}
                  </Styled.PriceMobile>
                  <Styled.DayTitle>
                    {formatDayOfWeek(transfer.transferDate)},{' '}
                    {formatDateAndTime(transfer.transferDate)}
                  </Styled.DayTitle>
                  <Styled.IdWrapper
                    onClick={(e) => {
                      e.stopPropagation()
                      try {
                        clipboard.copy(transfer.id)
                        toast.success(t('toast.copiedID'))
                      } catch {
                        toast.error(t('errorCopiedID'))
                      }
                    }}
                  >
                    <strong>ID: </strong>
                    <span
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                        fontSize: '1.2rem'
                      }}
                    >
                      {transfer?.id.length > 8
                        ? transfer?.id.slice(0, 8) + '...'
                        : transfer?.id}
                      <Copy width={16} height={16} cursor="pointer" />
                    </span>
                  </Styled.IdWrapper>
                </Styled.StatusContainer>
                <Styled.Wrapper>
                  <Styled.InfoWrapper>
                    <Styled.AddressWrapper>
                      <Styled.DepartureWrapper>
                        <Styled.DepartureTitle>
                          <img src="/pin.svg" alt="Pin icon" /> Partida
                        </Styled.DepartureTitle>
                        <Popover
                          width={300}
                          position="bottom"
                          opened={popoverOpened[transfer.id + 'left']}
                          key={transfer.id + 'left'}
                          withArrow
                          shadow="md"
                        >
                          <Popover.Target
                            onMouseEnter={() => {
                              if (transfer.startingPlace.length > 18) {
                                setPopoverOpened({
                                  ...popoverOpened,
                                  [transfer.id + 'left']: true
                                })
                              }
                            }}
                            onMouseLeave={() =>
                              setPopoverOpened({
                                ...popoverOpened,
                                [transfer.id + 'left']: false
                              })
                            }
                          >
                            <Styled.DepartureDescription>
                              {transfer.startingPlace}
                            </Styled.DepartureDescription>
                          </Popover.Target>
                          <Popover.Dropdown
                            sx={{ pointerEvents: 'none' }}
                            style={{ borderRadius: '8px' }}
                          >
                            <Text size="xl">{transfer.startingPlace}</Text>
                          </Popover.Dropdown>
                        </Popover>
                      </Styled.DepartureWrapper>

                      <Styled.KmWrapper>
                        <Styled.KmTitle style={{ marginBottom: 10 }}>
                          {transfer.distanceInKm ?? 0}km
                        </Styled.KmTitle>

                        <Styled.DirectionButton>
                          <Direction />
                        </Styled.DirectionButton>
                      </Styled.KmWrapper>

                      <Styled.DepartureWrapper>
                        <Styled.DepartureTitle>
                          <img src="/pin.svg" alt="Pin icon" /> Chegada
                        </Styled.DepartureTitle>
                        <Popover
                          width={300}
                          position="bottom"
                          opened={popoverOpened[transfer.id + 'rigth']}
                          key={transfer.id + 'rigth'}
                          withArrow
                          shadow="md"
                        >
                          <Popover.Target
                            onMouseEnter={() => {
                              if (transfer.arrivalPlace.length > 18) {
                                setPopoverOpened({
                                  ...popoverOpened,
                                  [transfer.id + 'rigth']: true
                                })
                              }
                            }}
                            onMouseLeave={() =>
                              setPopoverOpened({
                                ...popoverOpened,
                                [transfer.id + 'rigth']: false
                              })
                            }
                          >
                            <Styled.DepartureDescription>
                              {transfer.arrivalPlace}
                            </Styled.DepartureDescription>
                          </Popover.Target>
                          <Popover.Dropdown
                            sx={{ pointerEvents: 'none' }}
                            style={{ borderRadius: '8px' }}
                          >
                            <Text size="xl">{transfer.arrivalPlace}</Text>
                          </Popover.Dropdown>
                        </Popover>
                      </Styled.DepartureWrapper>
                    </Styled.AddressWrapper>
                  </Styled.InfoWrapper>
                  {!isMobile && (
                    <Divider
                      className="divider"
                      style={{ margin: '0 25px' }}
                      orientation="vertical"
                    />
                  )}
                  <Styled.ValuesWrapper>
                    <MediaQuery
                      query="(max-width: 1024px)"
                      styles={{ display: 'none' }}
                    >
                      <Styled.PriceTitle>
                        <Styled.DepartureTitle>
                          Valor a Cobrar
                        </Styled.DepartureTitle>
                        €{totalPrice.toFixed(2).toLocaleString('pt-PT')}
                      </Styled.PriceTitle>
                    </MediaQuery>
                    <VerticalSeparator />

                    <MediaQuery
                      query="(max-width: 1024px)"
                      styles={{ display: 'none' }}
                    >
                      <Styled.PriceTitle>
                        <Styled.DepartureTitle>
                          Valor EasyTransfer
                        </Styled.DepartureTitle>
                        €
                        {totalDriverCommission
                          .toFixed(2)
                          .toLocaleString('pt-PT')}
                      </Styled.PriceTitle>
                    </MediaQuery>
                    <VerticalSeparator />

                    <MediaQuery
                      query="(max-width: 1024px)"
                      styles={{ display: 'none' }}
                    >
                      <Styled.PriceTitle>
                        <Styled.DepartureTitle>
                          Valor Líquido
                        </Styled.DepartureTitle>
                        €{netValue.toFixed(2).toLocaleString('pt-PT')}
                      </Styled.PriceTitle>
                    </MediaQuery>
                  </Styled.ValuesWrapper>
                  {!isMobile && (
                    <Divider
                      className="divider"
                      style={{ margin: '0 25px' }}
                      orientation="vertical"
                    />
                  )}

                  <Styled.ButtonNormal>
                    {status === 'PENDING' ? (
                      <AcceptDrive transfer={transfer} />
                    ) : (
                      <NormalButton />
                    )}
                  </Styled.ButtonNormal>
                </Styled.Wrapper>
                <Styled.IconsAndNumbersWrapper>
                  {transfer.backpacks > 0 && (
                    <Styled.IconAndNumber>
                      <img
                        src="/briefcase.svg"
                        style={{ height: '24px' }}
                        alt="Briefcase icon"
                      />
                      <span>{transfer.backpacks}</span>
                    </Styled.IconAndNumber>
                  )}
                  {transfer.numberOfPassengers > 0 && (
                    <Styled.IconAndNumber>
                      <img
                        src="/users.svg"
                        style={{ height: '24px' }}
                        alt="Passenger icon"
                      />
                      <span>{transfer.numberOfPassengers}</span>
                    </Styled.IconAndNumber>
                  )}
                  {transfer.surfboards > 0 && (
                    <Styled.IconAndNumber>
                      <img
                        src="/surf.svg"
                        style={{ height: '24px' }}
                        alt="Shape icon"
                      />

                      <span>{transfer.surfboards}</span>
                    </Styled.IconAndNumber>
                  )}
                  {transfer.babySeats > 0 && (
                    <Styled.IconAndNumber>
                      <img
                        src="/car-seat.svg"
                        style={{ height: '24px' }}
                        alt="Carseat icon"
                      />
                      <span>{transfer.babySeats}</span>
                    </Styled.IconAndNumber>
                  )}
                  {transfer.animals > 0 && (
                    <Styled.IconAndNumber>
                      <img
                        src="/paw.svg"
                        style={{ height: '24px' }}
                        alt="Paw icon"
                      />
                      <span>{transfer.animals}</span>
                    </Styled.IconAndNumber>
                  )}
                </Styled.IconsAndNumbersWrapper>
                <Styled.ButtonMobile>
                  {status === 'PENDING' ? (
                    <AcceptDrive transfer={transfer} />
                  ) : (
                    <NormalButton />
                  )}
                </Styled.ButtonMobile>
              </Styled.Container>
            )
          })
        )}
        {!!transfer.length && <div ref={lastRef}></div>}

        <AcceptedModal
          opened={acceptedModal}
          onClose={() => setAcceptedModal(false)}
          onSuccess={(driver, setDriver) =>
            handleAcceptTransfer(transferContent, driver, setDriver)
          }
        />
        <RejectModal
          opened={rejectModal}
          onClose={() => setRejectModal(false)}
          onSuccess={() => handleRejectTransfer(transferCancel)}
        />
      </Styled.CardContainer>
    </>
  ) : (
    <div>Nenhum transfer encontrado no momento</div>
  )
}

function VerticalSeparator() {
  return (
    <Styled.SeparatorWrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="2"
        height="46"
        viewBox="0 0 2 46"
        fill="none"
      >
        <path d="M1 0V46" stroke="#27C7FF" />
      </svg>
    </Styled.SeparatorWrapper>
  )
}
