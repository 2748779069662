import { Header } from './Header'
import { Budget } from './components/Budget'
import * as Styled from './styles'
import { Steppers } from './components/Steppers'
import { Navbar } from '../../components/Navbar'
import Footer from '../../components/Footer'
import { RightContainer } from './RightContainer'

export const Checkout5 = () => {
  return (
    <>
      <Navbar checkout />
      <Styled.Container>
        <Header />
        <Steppers />
        {/* <ChoiceCars /> */}
        <Styled.ContentContainer>
          <Styled.MiddleContainerRow>
            <Budget />
            {/* <InitialTransfer /> */}
            {/* <BookingDetailsChoiceCars /> */}
            <RightContainer hideOnColumn />
          </Styled.MiddleContainerRow>
        </Styled.ContentContainer>
      </Styled.Container>
      <Footer />
    </>
  )
}
