import car1 from './imgs/economic/Fiat tipo station.webp'
import car2 from './imgs/economic/Dacia sandero.webp'
import car3 from './imgs/economic/Pegeout e 208.webp'
import car4 from './imgs/xl/Renault Grand Scenic.webp'
import car5 from './imgs/xl/Citroen C4 Grand Picasso.webp'
import car6 from './imgs/xl/Kia Ev9 copiar.webp'
import car7 from './imgs/executive/Sedan Executivo Mercedes S Class.webp'
import car8 from './imgs/executive/Porsche panamera.webp'
import car9 from './imgs/executive/Tesla model y.webp'
import car10 from './imgs/executive/Van executiva.webp'
import car11 from './imgs/van/Van executiva vito.webp'
import car12 from './imgs/van/Peugeot E-Traveler.webp'
import car13 from './imgs/van/Toyota_hiace copiar.webp'
import car14 from './imgs/van/Van executiva.webp'
import car15 from './imgs/minibus/Mercedes Sprinter mini bus.webp'
import car16 from './imgs/minibus/ford transit minibus copiar.webp'
import car17 from './imgs/autocar/Autocarro.webp'
import car18 from './imgs/autocar/executive.webp'

export const fleets = [
  [{
    img: car1,
    title: 'Fiat tipo station',
    briefcase: 4,
    wifi: true,
    passengers: 4,
    category: 'Económico'
  },
  {
    img: car2,
    title: 'Dacia Sandero ECO-G 100',
    briefcase: 4,
    wifi: true,
    passengers: 4,
    category: 'Económico'
  },
  {
    img: car3,
    title: 'Peugeot e-208',
    briefcase: 4,
    wifi: true,
    passengers: 4,
    category: 'Económico'
  }], [{
    img: car4,
    title: 'Renault Grand Scenic',
    briefcase: 2,
    wifi: true,
    passengers: 7,
    category: 'XL'
  },
  {
    img: car5,
    title: 'Citroen C4 Grand Picasso',
    briefcase: 2,
    wifi: true,
    passengers: 7,
    category: 'XL'
  },
  {
    img: car6,
    title: 'KIA EV9',
    briefcase: 4,
    wifi: true,
    passengers: 4,
    category: 'XL'
  },],
  [{
    img: car7,
    title: 'Mercedes s400',
    briefcase: 4,
    wifi: true,
    passengers: 4,
    category: 'Executivo'
  },
  {
    img: car8,
    title: 'Porsche Panamera',
    briefcase: 3,
    wifi: true,
    passengers: 3,
    category: 'Executivo'
  },
  {
    img: car9,
    title: 'Tesla Model Y',
    briefcase: 7,
    wifi: true,
    passengers: 2,
    category: 'Executivo'
  },
  {
    img: car10,
    title: 'Mercedes EQV',
    briefcase: 8,
    wifi: true,
    passengers: 8,
    category: 'Executivo'
  }],
  [
    {
      img: car11,
      title: 'Mercedes Vito',
      briefcase: 8,
      wifi: true,
      passengers: 8,
      category: 'Van'
    },
    {
      img: car12,
      title: 'Peugeot E-Travaler',
      briefcase: 8,
      wifi: true,
      passengers: 8,
      category: 'Van'
    },
    {
      img: car13,
      title: 'Toyota HiAce',
      briefcase: 8,
      wifi: true,
      passengers: 8,
      category: 'Van'
    },
    {
      img: car14,
      title: 'Mercedes EQV',
      briefcase: 8,
      wifi: true,
      passengers: 8,
      category: 'Van'
    },
  ],
  [{
    img: car15,
    title: 'Mercedes Sprinter',
    briefcase: 16,
    wifi: true,
    passengers: 16,
    category: 'Minibus'
  },
  {
    img: car16,
    title: 'Ford Transit',
    briefcase: 16,
    wifi: true,
    passengers: 16,
    category: 'Minibus'
  }],
  [{
    img: car17,
    title: 'Standard',
    briefcase: 56,
    wifi: true,
    passengers: 56,
    category: 'Autocarro'
  },
  {
    img: car18,
    title: 'Executive',
    briefcase: 56,
    wifi: true,
    passengers: 56,
    category: 'Autocarro'
  }]
]
