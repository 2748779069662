import * as Styled from './styles'

import passenger from '../../imgs/passenger.svg'
import briefcase from '../../imgs/briefcase.svg'
import wifi from '../../imgs/wifi.svg'
import eletric from '../../imgs/energia-eletrica-1.webp'

export const CardOne = ({ car }) => {
  const isEletric = (model) => {
    if (model === 'Tesla model Y' || model === 'Peugeot E-Traveler') {
      return (
        <Styled.EletricIconContainer>
          <img src={eletric} alt="carro-eletrico-icone" />
        </Styled.EletricIconContainer>
      )
    }
  }

  return (
    <Styled.Container>
      {isEletric(car.title)}
      <Styled.CarCategoryContainer>
        <Styled.CarCategoryTag>
          <p>
            {car.category}
          </p>
        </Styled.CarCategoryTag>
      </Styled.CarCategoryContainer>
      <Styled.ImageCar src={car.img} alt={`Ilustração de um ${car.title}`} />
      <Styled.Title>{car.title}</Styled.Title>
      <Styled.OptionsContainer>
        {car.passengers && (
          <Styled.Passenger>
            <img src={passenger} alt="Ícone de passageiro" />
            <p
              style={{
                background: 'rgba(226, 226, 226, 0.4)',
                border: '0.5px solid rgba(56, 40, 14, 0.3)',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.07)',
                borderRadius: '16px',
                padding: '2px 4px'
              }}
            >
              {car.passengers}
            </p>
          </Styled.Passenger>
        )}
        {car.briefcase && (
          <Styled.Briefcase>
            <img src={briefcase} alt="Ícone de carga" />
            <p
              style={{
                background: 'rgba(226, 226, 226, 0.4)',
                border: '0.5px solid rgba(56, 40, 14, 0.3)',
                boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.07)',
                borderRadius: '16px',
                padding: '2px 4px'
              }}
            >
              {car.briefcase}
            </p>
          </Styled.Briefcase>
        )}
        {car.wifi && <img src={wifi} alt="Ícone de wifi" />}
      </Styled.OptionsContainer>
    </Styled.Container>
  )
}
