import * as Styled from './styles'
import { SegmentedControl } from '@mantine/core'
import { useState } from 'react'
import { Transfer } from './components/Transfer'
import { Tours } from './components/Tours'
import { Eventos } from './components/Eventos'
import { LowCost } from './components/LowCost'
import { CancelRide } from './components/CancelRide'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { ChevronRight } from 'lucide-react'

export const ReserveComponent = () => {
  const [flag, setFlag] = useState('Transfer')
  const { t } = useTranslation()

  const isMobile = useMediaQuery('(max-width: 500px)')

  const control = [
    {
      value: 'Transfer',
      label: t('reserveComponent.Transfer')
    },
    {
      value: 'Tours',
      label: t('reserveComponent.Tours')
    },
    {
      value: 'Eventos',
      label: t('reserveComponent.Events')
    },
    {
      value: 'Low cost',
      label: t('reserveComponent.LowCost')
    },
    {
      value: 'Cancel Ride',
      label: t('reserveComponent.CancelRide')
    }
  ]

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      whileInView={{ opacity: 1, scale: 1 }}
      transition={{ ease: 'easeOut', duration: 1 }}
      viewport={{ once: true }}
    >
      <Styled.Container>
        <div>
          <Styled.OptionsContainer>
            <SegmentedControl value={flag} onChange={setFlag} data={control} />
            {isMobile && <ChevronRight />}
          </Styled.OptionsContainer>
          {flag == 'Transfer' && <Transfer />}
          {flag == 'Tours' && <Tours />}
          {flag == 'Eventos' && <Eventos />}
          {flag == 'Low cost' && <LowCost />}
          {flag == 'Cancel Ride' && <CancelRide />}
        </div>
      </Styled.Container>
    </motion.div>
  )
}
