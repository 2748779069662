import {
  Button,
  Divider,
  Flex,
  LoadingOverlay,
  MediaQuery
  // , Pagination
} from '@mantine/core'
import * as Styled from './styles'
import { useEffect } from 'react'
import { ReactComponent as MultibancoLogo } from './imgs/multibanco-logo.svg'
import { ReactComponent as CreditCard } from './imgs/credit-card.svg'
import fetchBooking from '../../../../../../services/booking'
import { useState } from 'react'
import {
  formatDateAndTime,
  formatDayOfWeek,
  formatMonth,
  formatTime
} from '../../../../../../utils/formattedDate'
import { RejectModal } from '../RejectModal'
import { useAuth } from '../../../../../../contexts/useAuth'
import { useClipboard } from '@mantine/hooks'
import StatusPayment from '../StatusPayment'
import { useParams } from 'react-router-dom'
import { CheckCircle } from 'lucide-react'

export const DetailsPayment = ({ transfer, setSendTransferModal }) => {
  const [acceptedModal, setAcceptedModal] = useState(false)
  const [rejectModal, setRejectModal] = useState(false)
  const [transferContent, setTransferContent] = useState()
  const [refreshBookings, setRefreshBookings] = useState(false)

  const clipboard = useClipboard({ timeout: 500 })

  const { user } = useAuth()
  const { id } = useParams()

  const HOSTEL_PERCENTAGE = 0.2

  console.log(id, 'id')

  const handleAcceptTransfer = (value, driver, setDriver) => {
    const payload = {
      id: value.id,
      driver: {
        id: driver ? driver : user.id
      }
    }

    if (user.id) {
      fetchBooking
        .updateBooking(payload)
        .then((res) => {
          if (res) {
            setAcceptedModal(false)
            setRefreshBookings(!refreshBookings)
            if (driver) {
              setDriver()
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const formatCurrency = (currency) => {
    return Intl.NumberFormat('PT', {
      currency: 'EUR',
      style: 'currency'
    }).format(currency)
  }

  console.log(transfer, 'TRANSFER')

  return transfer ? (
    <>
      <Styled.CardContainer>
        <Styled.Container key={transfer.id}>
          <Styled.Wrapper>
            <Styled.InfoWrapper>
              <Styled.PaymentTitle>
                <CreditCard />
                Pagamento
              </Styled.PaymentTitle>
            </Styled.InfoWrapper>
            <Styled.PricesWrapper>
              <Styled.ValuesWrapper>
                <Styled.PriceTitle>
                  <Styled.DepartureTitle>VALOR A COBRAR</Styled.DepartureTitle>
                  {formatCurrency(
                    HOSTEL_PERCENTAGE * (transfer.totalPrice / 100) +
                      transfer.totalPrice / 100
                  )}
                </Styled.PriceTitle>
              </Styled.ValuesWrapper>

              <Styled.ValuesWrapper>
                <Styled.PriceTitle>
                  <Styled.DepartureTitle>VALOR LIQUIDO</Styled.DepartureTitle>
                  {formatCurrency(
                    HOSTEL_PERCENTAGE * (transfer.totalPrice / 100)
                  )}
                </Styled.PriceTitle>
              </Styled.ValuesWrapper>
            </Styled.PricesWrapper>

            <Styled.PaymentDetails>
              <Styled.PaymentInfo>
                <Styled.PaymentInfoTitle>Status:</Styled.PaymentInfoTitle>
                {transfer?.paymentMethod === 'CASH' ? (
                  <StatusPayment status="Receber pagamento" />
                ) : transfer?.paymentStatus === 'PENDING' ? (
                  <StatusPayment status="Pendente" />
                ) : transfer?.paymentStatus === 'APPROVED' ? (
                  <StatusPayment status="Pagamento recebido" />
                ) : (
                  <StatusPayment status="Erro" />
                )}
              </Styled.PaymentInfo>
            </Styled.PaymentDetails>
            <Styled.PaymentAccomplished
              onClick={() => setSendTransferModal(true)}
            >
              REALIZADO
              <CheckCircle width={24} height={24} color="#FFFFFF" />
            </Styled.PaymentAccomplished>
          </Styled.Wrapper>
        </Styled.Container>

        <RejectModal
          opened={rejectModal}
          onClose={() => setRejectModal(false)}
        />
      </Styled.CardContainer>
    </>
  ) : (
    <div></div>
  )
}
