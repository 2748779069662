import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  gap: 13px;

  background: #27c7ff;
  width: 100%;

  @media only screen and (max-width: 988px) {
    border-radius: 0;
  }

  @media only screen and (max-width: 595px) {
    gap: 1.2rem;
    height: 5.2rem;
    padding: 0 1.4rem;

    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
  }
`
export const CardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 2.67497px 0px;
  gap: 10px;

  @media only screen and (max-width: 1024px) {
    height: 100%;
    width: fit-content;
  }

  @media only screen and (max-width: 595px) {
    gap: 0.6rem;
    height: 100%;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.4rem;
  min-width: 2.4rem;
  height: 2.4rem;
  min-height: 2.4rem;

  background: #ffffff;
  box-shadow: 0px 4.86729px 9.73457px rgba(255, 255, 255, 0.3);
  /* border-radius: 14.6019px; */
  border-radius: 0.6rem;

  svg {
    width: 1.4rem;
  }

  @media only screen and (max-width: 1024px) {
    /* min-width: 3.2rem; */
    /* width: 3.2rem; */
    /* min-height: 3.2rem; */
    /* height: 3.2rem; */
    border-radius: 0.6rem;

    svg {
      /* width: 2rem; */
    }
  }

  @media only screen and (max-width: 595px) {
    /* width: 2.4rem;
    min-width: 2.4rem;
    height: 2.4rem;
    min-height: 2.4rem;

    svg {
      width: 1.4rem;
    } */
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 5.35px;

  width: 253.75px;

  h1,
  p {
    color: white;
  }

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 100%;
    letter-spacing: 0.6px;
  }

  p {
    font: 300 1.2rem Poppins, sans-serif;
    line-height: 120%;
    letter-spacing: 0.5px;
  }

  @media only screen and (max-width: 1024px) {
    gap: 0.2rem;
    width: fit-content;

    /* h1 {
      font-size: 1.4rem;
    } */

    p {
      font-size: 1rem;
    }
  }

  @media only screen and (max-width: 595px) {
    h1 {
      line-height: 1;
    }

    p {
      display: none;
    }
  }

  @media only screen and (max-width: 350px) {
    h1 {
      font-size: 1rem;
    }
  }
`
