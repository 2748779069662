import React from 'react'
import * as Styled from './styles'

import Passenger from './imgs/passenger.webp'
import Shape from './imgs/shape.webp'
import Carseat from './imgs/carseat.webp'
import Paw from './imgs/paw.webp'
import Briefcase from './imgs/briefcase.webp'

export function ItemCounters({ transfer }) {
  return (
    <Styled.IconsAndNumbersWrapper>
      {transfer.numberOfPassengers > 0 && (
        <Styled.IconAndNumber>
          <img
            src={Passenger}
            style={{ height: '17px' }}
            alt="Passenger icon"
          />
          <span>{transfer.numberOfPassengers}</span>
        </Styled.IconAndNumber>
      )}
      {transfer.surfboards > 0 && (
        <Styled.IconAndNumber>
          <img src={Shape} style={{ height: '24px' }} alt="Shape icon" />
          <span>{transfer.surfboards}</span>
        </Styled.IconAndNumber>
      )}
      {transfer.backpacks > 0 && (
        <Styled.IconAndNumber>
          <img
            src={Briefcase}
            style={{ height: '24px' }}
            alt="Briefcase icon"
          />
          <span>{transfer.backpacks}</span>
        </Styled.IconAndNumber>
      )}
      {transfer.babySeats > 0 && (
        <Styled.IconAndNumber>
          <img src={Carseat} style={{ height: '24px' }} alt="Carseat icon" />
          <span>{transfer.babySeats}</span>
        </Styled.IconAndNumber>
      )}
      {transfer.animals > 0 && (
        <Styled.IconAndNumber>
          <img src={Paw} style={{ height: '24px' }} alt="Paw icon" />
          <span>{transfer.animals}</span>
        </Styled.IconAndNumber>
      )}
    </Styled.IconsAndNumbersWrapper>
  )
}
