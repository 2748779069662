import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    width: 100%;
    padding: 0 16px;
  }

  margin-bottom: 150px;

  @media (max-width: 768px) {
    margin: -80px 0 64px 0;
  }
`

export const StatsContainer = styled.div`
  width: 85vw;
  display: flex;
  flex-wrap: no-wrap;
  justify-content: space-around;
  flex-shrink: 0;
`

export const StatsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  flex-wrap: wrap;
`

export const StatContainer = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  align-items: center;
  gap: clamp(8px, 0.42vw, 0.42vw);
`

export const StatTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-family: 'Neometric';

    :first-child {
      font-weight: 700;
      font-size: clamp(2rem, 3vw, 3.2rem);
    }

    :nth-child(2) {
      font-size: clamp(1.4rem, 3vw, 1.6rem);
    }
  }
`

export const DriverImageContainer = styled.div`
  /* margin-top: -8%; */
  @media (max-width: 960px) {
    margin-top: 0;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    height: clamp(145px, 7.55vw, 7.55vw);
    width: clamp(765px, 39.85vw, 39.85vw);
    border-radius: 50%;
    background: linear-gradient(
      180deg,
      #27c7ff -40.87%,
      rgba(251, 176, 59, 0) 50%
    );

    position: absolute;
    top: 60%;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }
`

export const DriverImage = styled.img`
  z-index: 25;
  position: relative;
  /* width: clamp(200px, 21.14vw, 21.14vw); */
  width: 30%;
  @media (max-width: 699px) {
    width: 70%;
  }
`

export const Plus = styled.div`
  flex-shrink: 0;
  width: clamp(24px, 1.25vw, 1.25vw);
  height: clamp(24px, 1.25vw, 1.25vw);
  background: #27c7ff;
  position: relative;

  div {
    background: white;
    width: clamp(8px, 0.41vw, 0.41vw);
    height: clamp(8px, 0.41vw, 0.41vw);

    :nth-child(1) {
      position: absolute;
      top: 0;
      left: 0;
    }

    :nth-child(2) {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    :nth-child(3) {
      position: absolute;
      top: 0;
      right: 0;
    }

    :nth-child(4) {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
`
