import styled from 'styled-components'

import { Button as UIButton } from '../../../../components/Button'
import { Badge as UIBadge } from '../../../../components/Badge'
import { Separator as UISeparator } from '../../../../components/Separator'

export const Wrapper = styled.section`
  > img {
    height: 100%;
    width: 100%;
  }

  > div {
    margin: 0 auto;
    display: flex;
    width: 100%;
    max-width: 140.8rem;
    flex-direction: column;
    padding-left: 4rem;
    padding-right: 4rem;

    @media (min-width: 1024px) {
      padding-left: 16rem;
      padding-right: 16rem;
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;

      h1 {
        font-size: 3.6rem;
        font-weight: bold;
        color: #27c7ff;
        line-height: 1;
      }

      p {
        font-size: 1.6rem;

        span {
          font-size: 2rem;
          line-height: 2.8rem;
          font-weight: bold;
          color: #27c7ff;
        }
      }

      > div:last-of-type {
        display: flex;
        /* flex-wrap: wrap; */
        gap: 1.6rem;
        width: 80%;

        @media (max-width: 390px) {
          gap: 0.8rem;
        }
      }
    }
  }
`
export const Button = styled(UIButton)`
  background-color: #27c7ff;
  border-radius: 1.2rem;
  padding: 1.6rem 3.2rem;
  font-weight: bold;
  color: #27272a;
  width: 80%;
  font-size: 1.6rem;
  height: 5.6rem;

  :hover {
    background-color: #27c7ff;
    opacity: 0.9;
  }
`

export const Badge = styled(UIBadge)`
  flex: 1;
  width: 100%;
  display: flex;
  gap: 1rem;
  border-radius: 0.8rem;
  background-color: #27272a;
  color: #27c7ff;
  padding: clamp(8px, 0.42vw, 0.42vw);

  svg {
    /* width: 2.8rem; */
    /* height: 2.8rem; */
    width: 2.4rem;
    height: 2.4rem;
    min-width: 2.4rem;
    min-height: 2.4rem;
  }

  > div {
    display: flex;
    flex-direction: column;

    span {
      /* font-size: 1.2rem; 12px */
      /* line-height: 1.6rem; 16px */
      font: 400 1.1rem Roboto, sans-serif !important;
      letter-spacing: 0.5px;
    }
  }

  @media (max-width: 460px) {
    svg {
      width: 1.8rem;
      height: 1.8rem;
      min-width: 1.8rem;
      min-height: 1.8rem;
    }
  }

  @media (max-width: 430px) {
    padding: 0.6rem 0.4rem;
    /* border-radius: 0.4rem; */
    /* gap: 0.4rem; */
  }

  @media (max-width: 370px) {
    gap: 0.6rem;
    > div span {
      font-size: clamp(0.8rem, 2.4vw, 1rem) !important;
    }
  }
`

export const Separator = styled(UISeparator)`
  height: 100%;
  width: 0.1rem;
  background-color: #27c7ff;
`
