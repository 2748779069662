import { ReactComponent as Icon1 } from './imgs/icon-1.svg'
import { ReactComponent as Icon2 } from './imgs/icon-2.svg'
import { ReactComponent as Icon3 } from './imgs/icon-3.svg'
import { ReactComponent as Icon4 } from './imgs/icon-4.svg'
import { ReactComponent as Icon5 } from './imgs/icon-5.svg'
import { ReactComponent as Icon6 } from './imgs/icon-6.svg'

export const imgs = [
  <Icon1 key={1} />,
  <Icon2 key={2} />,
  <Icon3 key={3} />,
  <Icon4 key={4} />,
  <Icon5 key={5} />,
  <Icon6 key={6} />
]

export const resources = (t) =>
  t('whySchedule', { returnObjects: true }).map(
    ({ title, description }, index) => ({
      title,
      desc: description,
      img: imgs[index] || <Icon1 />,
      alt: 'Ícone de garantia'
    })
  )
