import styled from 'styled-components'

export const ModalBackgroundOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.624);

  z-index: 200;
`

export const DeleteModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 300px;
  height: 250px;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  opacity: 1;

  z-index: 200;

  gap: 10px;
  border-radius: 22px;
  background-color: #fff;
  box-shadow: 0px 22px 24px 0px rgba(0, 0, 0, 0.07);
`

export const DeleteHeader = styled.h2`
  font-family: Neometric;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.14px;
`

export const ConfirmationText = styled.p`
  font-family: Neometric;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  letter-spacing: 0.14px;
`

export const ButtonContainer = styled.div`
  display: flex;
  /* padding: 1rem 10px; */
  align-items: center;
  justify-content: center;

  gap: 20px;
  height: 100%;
  width: 100%;

  button:first-child {
    background-color: #27c7ff;
    transition: 0.3s;

    :hover {
      background-color: #404040;
      border: 1px solid #404040;
      color: #27c7ff;
    }
  }

  button:nth-child(2) {
    background-color: #ffffff;
    color: #27c7ff;
    transition: 0.3s;

    :hover {
      background-color: #DFF7FF;
      border: 1px solid #DFF7FF;
    }
  }
`

export const Buttons = styled.button`
  width: 100px;
  height: 40px;

  cursor: pointer;

  font-family: Neometric;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  color: #fff;

  border-radius: 16px;
  border: 1px solid #27c7ff;
`
