import styled, { css } from 'styled-components'
export const Container = styled.div`
  ${({ dark }) => css`
    .description-text {
      overflow: hidden;
      overflow-y: auto;
      padding-right: 0.4rem;

      &::-webkit-scrollbar {
        width: 0.6rem !important;
        display: unset;
      }

      &::-webkit-scrollbar-track {
        background: trasnparent !important;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #27c7ff !important;
        border-radius: 1.6rem !important;
      }

      scrollbar-width: thin !important;
      scrollbar-color: #27c7ff transparent !important;
    }

    .mantine-Carousel-root {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 17.8414px 10.7102px 10.6686px 10.6686px;
    }

    .mantine-Carousel-container {
      gap: 0.2rem;
      margin-left: 1rem;
      padding: 1.2rem 0;
    }

    .mantine-Carousel-controls {
      gap: 113rem;
    }

    .mantine-Carousel-root {
      width: 114rem;
    }

    .mantine-Carousel-control {
      box-shadow: none;
    }

    ${dark &&
    css`
      .mantine-Carousel-control {
        background-color: transparent;

        :hover {
          background-color: transparent;
          filter: none;
        }
      }
    `}

    @media only screen and (max-width: 994px) {
      width: 100%;
      overflow: auto;
      .mantine-Carousel-controls {
        display: none;
      }
      .mantine-Carousel-container {
        .mantine-Carousel-slide {
          flex: none;
          width: fit-content !important;

          > div {
            padding: 1.6rem;
          }
          & + .mantine-Carousel-slide {
            margin-left: 1.2rem !important;
          }
        }
      }
    }

    @media only screen and (max-width: 768px) {
      .mantine-Carousel-root {
        padding: 0;
      }
      .mantine-Carousel-slide {
        h1 {
          font-size: 1.6rem;
          line-height: normal;
        }
        span {
          font-size: 1.4rem;
          line-height: normal;
        }
        .description-text {
          font-size: 1.4rem;
          height: 60%;
        }

        > div {
          width: 28rem;
          > div {
            > div:first-of-type > img:first-of-type {
              width: 4rem;
              height: 4rem;
            }
            > div:last-of-type {
              img {
                width: 2rem;
                height: 1.9rem;
              }
            }
          }
        }
      }
    }
  `}
`

export const Header = styled.div`
  display: flex;
  gap: 1.5rem;
  align-items: flex-start;
  margin-bottom: 1.5rem;

  img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
  }
`

export const TextContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;

  h1 {
    font: 700 1.4rem 'Roboto', sans-serif !important;
    color: #000000 !important;
    line-height: 1 !important;
  }

  span {
    font: 400 1.2rem 'Roboto', sans-serif !important;
    color: #000000 !important;
    opacity: 0.5 !important;
    line-height: 1 !important;
  }
`

export const ImageGoogle = styled.img`
  margin-left: auto;
  width: 2rem !important;
  height: 2rem !important;
`

const cardContainerModifiers = {
  dark: () => css`
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
      0 4px 6px -4px rgb(0 0 0 / 0.1);
    background-color: #27272a;
    h1 {
      color: #f3f4f6 !important;
    }

    span,
    .description-text {
      color: #d1d5db !important;
    }
  `
}

export const CardContainer = styled.div`
  ${({ dark }) => css`
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;

    font-family: Roboto, sans-serif;

    color: #000000;
    width: 96%;
    height: 230.42px;
    overflow: hidden;

    background: #ffffff;
    box-shadow: 1px 1px 7px 0px rgba(0, 0, 0, 0.13),
      0px 0px 2px 0px rgba(0, 0, 0, 0.05);
    border-radius: 1.6rem;
    padding: 2rem;

    transition: transform 0.3s ease-in-out;

    h1 {
      font-weight: 700;
      font-size: 20.0035px;
      line-height: 28px;
    }

    span {
      font-weight: 400;
      font-size: 16.0028px;
      line-height: 22px;

      color: rgba(0, 0, 0, 0.5);
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      margin-top: 8px;
      height: 50%;
    }

    :hover {
      transform: translateY(-0.8rem);
    }

    ${dark && cardContainerModifiers.dark}
  `}
`
// export const CarrouselBtnWrapper = styled.div`
//   position: absolute;
// `

export const BtnNext = styled.img`
  cursor: pointer;
  right: -3rem;
  top: 2rem;
`
export const BtnPrev = styled.img`
  cursor: pointer;
  top: 2rem;
  left: -3rem;
`

export const Button = styled.button`
  /* padding: 6px 24px; */
  /* border-radius: 8px; */
  margin-top: 0.8rem;

  font-weight: 300;
  cursor: pointer;
  transition: font-weight 0.3s ease-in-out;
  background-color: transparent;
  color: #27c7ff;
  border: solid 1px #27c7ff;

  &:hover {
    /* background: #27c7ff; */
    /* border: 0; */
    /* color: white; */
    font-weight: 600;
  }
`

export const CardRow = styled.div`
  height: 100%;

  > div:nth-child(2) > img {
    width: 1.7rem;
    height: 1.7rem;
  }

  > p {
    font: 400 1.5rem 'Roboto', sans-serif !important;
  }
`
