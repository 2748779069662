import React from 'react'
import * as Styled from './styles'

export function DeleteModal({ isOpen, setOpen, removeOrderBump }) {
  if (isOpen) {
    return (
      <Styled.ModalBackgroundOverlay>
        <Styled.DeleteModalContainer>
          <Styled.DeleteHeader>Alerta de exclusão</Styled.DeleteHeader>
          <Styled.ConfirmationText>
            Tem certeza que gostaria de remover essa sugestão de parada?
          </Styled.ConfirmationText>
          <Styled.ButtonContainer>
            <Styled.Buttons onClick={() => removeOrderBump()}>
              Sim
            </Styled.Buttons>
            <Styled.Buttons onClick={() => setOpen(!isOpen)}>
              Não
            </Styled.Buttons>
          </Styled.ButtonContainer>
        </Styled.DeleteModalContainer>
      </Styled.ModalBackgroundOverlay>
    )
  }
}
