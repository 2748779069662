import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 48px;

  width: 988.02px;
  margin-top: 50px;

  .alice-carousel__stage {
    width: 987.61px;
    height: 415.14px;

    display: flex;
    gap: 21px;
  }

  .alice-carousel__stage-item {
    height: 415.14px;
    width: 315px;

    border-radius: 21.6596px;
    // border: 0.5px solid #d3d3d3;
  }

  .mantine-Paper-root {
    width: 315px;
    height: 100%;

    border-radius: 21.6596px;
    // border: none;
    // box-shadow: 0px 3.60993px 40.6117px rgba(0, 0, 0, 0.2);
  }

  .mantine-Image-image {
    width: 315.87px;
    height: 201.25px !important;

    border-radius: 21.6596px 21.6596px 0px 0px;
  }

  .title {
    font-weight: 600;
    font-size: 18.0496px;
    line-height: 22px;
    margin-top: 5px;

    color: #0d0d0d;
  }

  svg {
    width: 23.05px;
    height: 22.17px;
  }

  .rate-value {
    font-weight: 700;
    font-size: 16.2447px;
    line-height: 19px;

    color: #0d0d0d;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14.4397px 28.8794px;
    gap: 21.66px;

    width: 135.37px;
    height: 45.88px;

    background: #27c7ff;
    border-radius: 14.4397px;

    font-weight: 700;
    font-size: 14.4397px;
    line-height: 120%;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #ffffff;

    &:hover {
      background-color: #fff;
      color: #27c7ff;
      border: solid 1px #27c7ff;
    }
  }
`

export const ConfirmationMessage = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;

  text-align: center;
  letter-spacing: 0.01em;

  color: #0d0d0d;
`

export const CardsWrapper = styled.div`
  display: flex;
  padding: 0px;
  gap: 20px;

  width: 988.02px;
  height: 415.14px;
`

export const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StrongSpan = styled.span`
  font-weight: 600;
  font-size: 14.4397px;
  line-height: 120%;

  letter-spacing: 0.01em;

  color: #0d0d0d;
`

export const NormalSpan = styled.span`
  font-weight: 400;
  font-size: 14.4397px;
  line-height: 120%;

  letter-spacing: 0.01em;

  color: #0d0d0d;
`

export const Price = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;

  letter-spacing: 0.01em;
  margin: 14px 40px 0 0;

  color: #0d0d0d;
`

export const CarrouselBtnWrapper = styled.div`
  position: absolute;
`

export const BtnNext = styled.img`
  cursor: pointer;
  position: absolute;
  left: 510px;
  top: -2rem;
`
export const BtnPrev = styled.img`
  cursor: pointer;
  position: absolute;
  top: -2rem;
  right: 510px;
`

// export const Row = styled.div`
//   width: 286.99px;

//   display: flex;
//   align-items: center;
// `
