import { Checkbox, Input } from '@mantine/core'
import * as Styled from './styles'

import { Link, useNavigate } from 'react-router-dom'
// import PaypalLogo from './imgs/paypal_logo.webp'
import CheckCircleIcon from './imgs/checked_circle_icon.webp'
import { useTranslation } from 'react-i18next' // Importação do useTranslation

import fetchPayment from '../../../../services/payment'
import { toast } from 'react-toastify'
import { useEffect, useState } from 'react'
import fetchBooking from '../../../../services/booking'
import { useTransferContext } from '../../../../contexts/TransferContext'
import { handleParseError, replaceHTMLEntities } from './parsers'
import dayjs from 'dayjs'
import { formatCurrency } from '../../../../utils/formatCurrency'
import { useMediaQuery } from '../../../../hooks/useMediaQuery'

import { ReactComponent as CardIcon } from './imgs/card-icon.svg'
import { ReactComponent as CashIcon } from './imgs/cash-icon.svg'
import { ReactComponent as MbWayIcon } from './imgs/mbway-icon.svg'
import { ReactComponent as MultiIcon } from './imgs/multi-icon.svg'
import { useModal } from '../../../../hooks/useModal'

const protections = [
  'Ts&Cs de infecção por Covid 19',
  'Falha programada da companhia aérea',
  'Doença ou Lesão',
  'Falha no transporte público',
  'Condições médicas pré-existentes',
  'Avaria Mecânica',
  'Morte de Família Imediata',
  'Intimação judicial',
  'Roubo de Ingressos',
  'Emergência Domiciliar',
  'Complicações na Gravidez',
  'Clima Adverso',
  'Serviço jurídico',
  'Realocado para trabalho',
  'Recall das Forças Armadas e Serviços de Emergência',
  'Mudanças nas Datas dos Exames'
]

const SHARE_RIDE_DISCOUNT = 0.25
const NIGHT_TAX = 9
const ANIMALS_TAX = 10
const CHILDSEATS_TAX = 3
const SURFBOARDS_TAX = 3
const REFOUND_TAX = 4.97

const paymentFunctions = {
  MbWay: fetchPayment.mbway,
  MbMulti: fetchPayment.multibanco,
  CreditCard: fetchPayment.creditCard
}

export const BookingDetails = () => {
  const [tax, setTax] = useState(0)
  const [shareRideDiscount, setShareRideDiscount] = useState(0)
  const [loadingPayment, setLoadingPayment] = useState(false)
  const [expandedProtections, setExpandedProtections] = useState(false)
  const [paymentReference, setPaymentReference] = useState('')
  const [paymentRedirect, setPaymentRedirect] = useState('')

  const { lowCost, setLowCost, returnTravel } = useTransferContext()
  const expandableReimbursementProtectionsMatch =
    useMediaQuery('(max-width: 615px)')
  const navigate = useNavigate()

  const { carSelected } = useTransferContext()

  const { openModal } = useModal()

  const hasGoingNightTax =
    dayjs(lowCost.dateTime).get('hours') > 19 ||
    (dayjs(lowCost.dateTime).get('hours') >= 19 &&
      dayjs(lowCost.dateTime).get('minutes') >= 30) ||
    dayjs(lowCost.dateTime).get('hours') < 8 ||
    (dayjs(lowCost.dateTime).get('hours') == 8 &&
      dayjs(lowCost.dateTime).get('minutes') == 0)

  const hasReturnNightTax =
    dayjs(returnTravel.dateTime).get('hours') > 19 ||
    (dayjs(returnTravel.dateTime).get('hours') >= 19 &&
      dayjs(returnTravel.dateTime).get('minutes') >= 30) ||
    dayjs(returnTravel.dateTime).get('hours') < 8 ||
    (dayjs(returnTravel.dateTime).get('hours') == 8 &&
      dayjs(returnTravel.dateTime).get('minutes') == 0)

  const nightTax =
    hasGoingNightTax && hasReturnNightTax
      ? NIGHT_TAX * 2
      : hasGoingNightTax || hasReturnNightTax
      ? NIGHT_TAX
      : 0

  const lowCostAnimalsTax = lowCost.animals
    ? lowCost.animalsQuantity * ANIMALS_TAX
    : 0
  const returnAnimalsTax =
    returnTravel.active && returnTravel.animals
      ? returnTravel.animalsQuantity * ANIMALS_TAX
      : 0

  const lowCostChildSeatsTax = lowCost.childseat
    ? (lowCost.childSeat + lowCost.boosterSeat + lowCost.babyChair) *
      CHILDSEATS_TAX
    : 0
  const returnChildSeatsTax =
    returnTravel.active && returnTravel.childseat
      ? (returnTravel.childSeat +
          returnTravel.boosterSeat +
          returnTravel.babyChair) *
        CHILDSEATS_TAX
      : 0

  const lowCostSurfboardsTax = lowCost.surf
    ? lowCost.surfQuantity * SURFBOARDS_TAX
    : 0
  const returnSurfboardsTax =
    returnTravel.active && returnTravel.surf
      ? returnTravel.surfQuantity * SURFBOARDS_TAX
      : 0
  const orderBumpsTax = lowCost.bookingStops
    ? lowCost.bookingStops.reduce((total, item) => total + item.price, 0)
    : 0

  useEffect(() => {
    if (lowCost.totalPrice) {
      const taxCalculate = returnTravel.active
        ? (lowCost.totalPrice + returnTravel.totalPrice) * 0.06
        : lowCost.totalPrice * 0.06
      setTax(taxCalculate)

      if (lowCost.shareRide) {
        const shareRideDiscountCalculate = returnTravel.active
          ? (lowCost.totalPrice + returnTravel.totalPrice) * SHARE_RIDE_DISCOUNT
          : lowCost.totalPrice * SHARE_RIDE_DISCOUNT
        setShareRideDiscount(shareRideDiscountCalculate)
      } else {
        setShareRideDiscount(0)
      }
    }
  }, [
    lowCost.totalPrice,
    returnTravel.active,
    returnTravel.totalPrice,
    lowCost.shareRide
  ])

  const totalValue = formatCurrency(
    lowCost.totalPrice -
      // shareRideDiscount +
      nightTax +
      lowCostAnimalsTax +
      returnAnimalsTax +
      lowCostSurfboardsTax +
      returnSurfboardsTax +
      lowCostChildSeatsTax +
      returnChildSeatsTax +
      orderBumpsTax +
      (lowCost.reimbursement ? REFOUND_TAX : 0) +
      tax
  )
    .replace('€', '')
    .trim()
    .replace('.', '')
    .replace(',', '.')

  const lowCostValue = formatCurrency(
    lowCost.totalPrice -
      lowCost.discountAmount -
      // shareRideDiscount / 2 +
      nightTax / 2 +
      lowCostAnimalsTax +
      lowCostSurfboardsTax +
      lowCostChildSeatsTax +
      orderBumpsTax +
      (lowCost.reimbursement ? REFOUND_TAX / 2 : 0) +
      tax / 2
  )
    .replace('€', '')
    .trim()
    .replace('.', '')
    .replace(',', '.')

  const returnValue = returnTravel.active
    ? formatCurrency(
        returnTravel.totalPrice -
          returnTravel.discountAmount -
          // shareRideDiscount / 2 +
          nightTax / 2 +
          returnAnimalsTax +
          returnSurfboardsTax +
          returnChildSeatsTax +
          (lowCost.reimbursement ? REFOUND_TAX / 2 : 0) +
          tax / 2
      )
        .replace('€', '')
        .trim()
        .replace('.', '')
        .replace(',', '.')
    : undefined

  console.log(lowCost, 'lowCost')
  const handleSubmitPayment = async (e) => {
    e.preventDefault()
    let paymentReference = undefined
    let paymentRedirect = undefined

    let lowCostValueNumber = Number(lowCostValue.split('.').join(''))
    const totalValueNumber = Number(totalValue.split('.').join(''))
    setLoadingPayment(true)
    const toastId = toast.loading(t('toast.loading'))
    const amount = Number(
      formatCurrency(
        lowCost.totalPrice -
          // shareRideDiscount +
          nightTax +
          lowCostAnimalsTax +
          returnAnimalsTax +
          lowCostSurfboardsTax +
          returnSurfboardsTax +
          lowCostChildSeatsTax +
          returnChildSeatsTax +
          orderBumpsTax +
          (lowCost.reimbursement ? REFOUND_TAX : 0) +
          tax
      )
        .replace('€', '')
        .trim()
        .replace('.', '')
        .replace(',', '.')
    )

    const lowCostPayload = {
      id: lowCost.id,
      status: 'PENDING',
      startingPlace: lowCost.from,
      startingLatitude: lowCost.startingLatitude,
      startingLongitude: lowCost.startingLongitude,
      arrivalLatitude: lowCost.arrivalLatitude,
      arrivalLongitude: lowCost.arrivalLongitude,
      arrivalPlace: lowCost.to,
      distanceInKm: parseFloat((lowCost.distanceMeters / 1000).toFixed(1)),
      totalPrice: totalValueNumber,
      paymentMethod:
        lowCost.paymentMethod === 'Card'
          ? 'CREDIT_CARD'
          : lowCost.paymentMethod.toUpperCase(),
      paymentStatus: 'PENDING',
      // totalDriverCommission: 50,
      numberOfPassengers: lowCost.allPassangers ? lowCost.allPassangers : 0,
      transferDate: lowCost.dateTime,
      flightNumber: lowCost.flightNumber,
      checkedLuggages: lowCost.checkedLuggage,
      handLuggages: lowCost.handLuggage,
      backpacks: lowCost.backPack,
      surfboards: lowCost.surfQuantity,
      babySeats: lowCost.babyChair,
      childSeats: lowCost.childSeat,
      boosterSeats: lowCost.boosterSeat,
      animals: lowCost.animalsQuantity,
      shareRide: lowCost.shareRide,
      passengerName: lowCost.passengerName,
      passengerPhoneNumber: lowCost.passengerPhoneNumber,
      passengerEmail: lowCost.passengerEmail,
      durationInSeconds: lowCost.durationInSeconds,
      bookingStops: lowCost.bookingStops ? lowCost.bookingStops : undefined
    }
    fetchBooking
      .createBookingLowCost(lowCostPayload)
      .then(async (res) => {
        if (lowCost.paymentMethod === 'Card') {
          try {
            const { data } = await paymentFunctions.CreditCard({
              email: lowCost.passengerEmail,
              amount,
              bookingId: res.data.id,
              id: res.data.id
            })

            const { data: responseData } = data

            if (responseData.transactionStatus === 'Success') {
              toast.update(toastId, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                theme: 'light',
                type: 'success',
                closeButton: true,
                isLoading: false,
                render:  t('toast.successfulPayment')
              })
            }
            paymentRedirect = responseData.redirectUrl
            setLowCost({
              ...lowCost,
              paymentRedirect: responseData.redirectUrl
            })
          } catch (err) {
            toast.update(toastId, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              newestOnTop: false,
              closeOnClick: true,
              rtl: false,
              pauseOnFocusLoss: true,
              draggable: true,
              pauseOnHover: true,
              theme: 'light',
              type: 'error',
              closeButton: true,
              isLoading: false,
              render: t('toast.internalError')
            })
            console.log(err, 'err')

            setLoadingPayment(false)

            return
          }
        }

        if (['MbWay', 'MbMulti'].includes(lowCost.paymentMethod)) {
          try {
            const { data } = await paymentFunctions[lowCost.paymentMethod]({
              phoneNumber: 987654321,
              email: lowCost.passengerEmail,
              amount,
              bookingId: res.data.id
            })

            const { data: responseData } = data

            const message = replaceHTMLEntities(responseData.resposta)

            if (responseData.sucesso) {
              toast.update(toastId, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                theme: 'light',
                type: 'success',
                closeButton: true,
                isLoading: false,
                render:  t('toast.successfulPayment')
              })
              // paymentReference = responseData.referencia
              // setLowCost({
              //   ...lowCost,
              //   paymentReference: String(responseData.referencia)
              // })
            } else {
              toast.update(toastId, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                newestOnTop: false,
                closeOnClick: true,
                rtl: false,
                pauseOnFocusLoss: true,
                draggable: true,
                pauseOnHover: true,
                theme: 'light',
                type: 'error',
                closeButton: true,
                isLoading: false,
                render: message
              })

              setLoadingPayment(false)

              return
            }
          } catch (err) {
            toast.update(toastId, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              newestOnTop: false,
              closeOnClick: true,
              rtl: false,
              pauseOnFocusLoss: true,
              draggable: true,
              pauseOnHover: true,
              theme: 'light',
              type: 'error',
              closeButton: true,
              isLoading: false,
              render: t('toast.internalError')
            })

            setLoadingPayment(false)

            return
          }
        }

        setLoadingPayment(false)
        setLowCost((oldState) => ({
          ...oldState,
          status: res.data.status,
          paymentStatus: res.data.paymentStatus
        }))
        openModal('ModalConfirmTransfer-low-cost')
        // navigate('/checkout4')
      })
      .catch((err) => {
        console.log(err, 'err')
        setLoadingPayment(false)
      })
  }

  const { t } = useTranslation()

  return (
    <form onSubmit={handleSubmitPayment}>
      <Styled.Container>
        <h1>{t('payment')}</h1>
        <Styled.FormRow className="payment-methods">
          <Styled.PaymentCard
            active={lowCost.paymentMethod === 'Card'}
            onClick={() => setLowCost({ ...lowCost, paymentMethod: 'Card' })}
          >
            <CardIcon
              stroke={lowCost.paymentMethod === 'Card' ? '#27c7ff' : '#222222'}
            />
            <p>Card</p>
          </Styled.PaymentCard>
          <Styled.PaymentCard
            active={lowCost.paymentMethod === 'Cash'}
            onClick={() => setLowCost({ ...lowCost, paymentMethod: 'Cash' })}
          >
            <CashIcon
              stroke={lowCost.paymentMethod === 'Cash' ? '#27c7ff' : '#222222'}
            />
            <p>Cash</p>
          </Styled.PaymentCard>

          <Styled.PaymentCard
            active={lowCost.paymentMethod === 'MbMulti'}
            onClick={() => setLowCost({ ...lowCost, paymentMethod: 'MbMulti' })}
          >
            <MultiIcon />
            <p>Multibanco</p>
          </Styled.PaymentCard>
          <Styled.PaymentCard
            active={lowCost.paymentMethod === 'MbWay'}
            onClick={() => setLowCost({ ...lowCost, paymentMethod: 'MbWay' })}
          >
            <MbWayIcon />
            <p>MB Way</p>
          </Styled.PaymentCard>
        </Styled.FormRow>
        {/* {lowCost.paymentMethod === 'Card' && (
          <>
            <Input.Wrapper id="input-demo" label={t('cardNumberLabel')}>
              {/* <Input
            icon={<img src={Card} style={{ width: '22px' }} />}
            id="card-number"
            placeholder="0000-0000-0000-0000"
            size={'xl'}
            radius={'md'}
            // required={true}
            {...register('cardNumber')}
            // value={cardNumber}
            // onChange={(e) => setCardNumber(e.target.value)}
            rightSection={
              cardNumber && (
                <img
                  style={{ cursor: 'pointer' }}
                  onClick={() => setValue('cardNumber', '')}
                  width={22.55}
                  height={22.55}
                  src={Xis}
                />
              )
            }
          /> */}
        {/* <Styled.CardNumberWrapper>
                <CardNumberElement
                  options={{
                    showIcon: true,
                    placeholder: '0000-0000-0000-0000'
                  }}
                />
              </Styled.CardNumberWrapper>
            </Input.Wrapper>
            <Styled.FormRow style={{ marginTop: '-10px' }}>
              <Input.Wrapper id="input-demo" label={t('expirationLabel')}>
                <Styled.CardNumberWrapper id="input-demo">
                  <CardExpiryElement />
                </Styled.CardNumberWrapper> */}

        {/* <Input
              icon={
                <img
                  src={Calendar}
                  size="1.1rem"
                  stroke={1.5}
                  style={{
                    position: 'relative',
                    right: '132px'
                  }}
                />
              }
              id="validade"
              placeholder="00/00"
              size={'xl'}
              radius={'md'}
              {...register('expiration')}
              maxLength={5}
              rightSection={
                expiration && (
                  <img
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      left: '270px'
                    }}
                    onClick={() => setValue('expiration', '')}
                    width={22.55}
                    height={22.55}
                    src={Xis}
                  />
                )
              }
            /> */}
        {/* </Input.Wrapper>
              <Input.Wrapper id="input-demo" label={t('cvcLabel')}>
                <Styled.CardNumberWrapper id="input-demo">
                  <CardCvcElement options={{ placeholder: '00/00' }} />
                </Styled.CardNumberWrapper> */}

        {/* <Input
              id="cvc"
              placeholder="XXX"
              size={'xl'}
              radius={'md'}
              required={true}
              {...register('cvc')}
              maxLength={3}
              // value={cvc}
              // onChange={(e) => setCvc(e.target.value)}
              rightSection={
                cvc && (
                  <img
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      left: '100px'
                    }}
                    onClick={() => setValue('cvc', '')}
                    width={22.55}
                    height={22.55}
                    src={Xis}
                  />
                )
              }
            /> */}
        {/* </Input.Wrapper>
            </Styled.FormRow>
          </> */}
        {/* )} */}
        {lowCost.paymentMethod === 'Card' && (
          <Styled.CardTax>
            <p>{t('checkoutTour.Taxa de cartão')} 2%</p>
            <strong>€ 0,00</strong>
          </Styled.CardTax>
        )}
        <Styled.ReimbursementWrapper
          expandable={expandableReimbursementProtectionsMatch}
        >
          <Styled.GreenWrapper>
            <Styled.GreenTag>{t('recommended')}</Styled.GreenTag>
            <Checkbox
              disabled={loadingPayment}
              label={t('form.updateMyRefundTerms')}
              size={'lg'}
              color="teal"
              checked={lowCost.reimbursement}
              onChange={() =>
                setLowCost({
                  ...lowCost,
                  reimbursement: !lowCost.reimbursement
                })
              }
            />
            <div className="price">€ 4,97</div>
          </Styled.GreenWrapper>
          <h4>{t('updateReimbursementTermsCover')}</h4>
          <div className="grid-wrapper">
            <Styled.ReimbursementGrid
              expandable={expandableReimbursementProtectionsMatch}
              expanded={
                expandedProtections || !expandableReimbursementProtectionsMatch
              }
            >
              {protections &&
                protections.map((protection) => (
                  <div key={protection}>
                    <img src={CheckCircleIcon} />
                    <span>{t(protection)}</span>
                  </div>
                ))}
            </Styled.ReimbursementGrid>
            {expandableReimbursementProtectionsMatch && (
              <button
                type="button"
                onClick={() => setExpandedProtections((oldState) => !oldState)}
              >
                {expandedProtections ? 'Minimizar' : t('seeMore')}
              </button>
            )}
          </div>
          <Styled.ReimbursementOrangeWrapper>
            <Checkbox disabled={loadingPayment} color="yellow.7" size="xl" />
            <div>
              <span>{t('refundToOriginalPaymentMethod')}</span>
              <span style={{ fontWeight: '400', color: '#808080' }}>
                {t('refundToOriginalPaymentMethodDescription')}
              </span>
            </div>
          </Styled.ReimbursementOrangeWrapper>
        </Styled.ReimbursementWrapper>
        <Styled.PaymentButton disabled={loadingPayment} type="submit">
          {/* {t('payAmount', {
            amount: `€ ${formatCurrency(
              lowCost.totalPrice +
                (returnTravel.active ? returnTravel.totalPrice : 0) -
                shareRideDiscount +
                nightTax +
                lowCostAnimalsTax +
                returnAnimalsTax +
                lowCostSurfboardsTax +
                returnSurfboardsTax +
                lowCostChildSeatsTax +
                returnChildSeatsTax +
                (lowCost.reimbursement ? REFOUND_TAX : 0) +
                tax
            ).replace('€', '')}`
          })} */}
          {t('checkoutTour.CONTINUAR')}
        </Styled.PaymentButton>
      </Styled.Container>
    </form>
  )
}
