import { CategoryItem } from '../CategoryItem'
import * as Styled from './styles'

export const CategoriesConfig = ({
  categories,
  deleteCategory,
  editCategory
}) => {
  return (
    <Styled.Wrapper>
      {categories.length === 0 && (
        <h1 style={{ color: '#808080', fontSize: '1.8rem' }}>
          Nenhuma categoria encontrada!
        </h1>
      )}
      {categories.map((category, idx) => (
        <CategoryItem
          key={category.id}
          order={idx + 1}
          category={category}
          deleteCategory={deleteCategory}
          editCategory={editCategory}
        />
      ))}
    </Styled.Wrapper>
  )
}
