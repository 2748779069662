import React from 'react'
import styled from 'styled-components'

const statusMap = {
  Aprovado: {
    background: 'var(--green-pastel, #D5F6EA)',
    color: 'var(--green-payment-support, #48D9A4)',
    message: 'Aprovado'
  },
  Processando: {
    background: 'var(--grey-4-grayscale, #F7F7F7)',
    color: 'var(--grey-2-grayscale, #808080)',
    message: 'Processando'
  },
  Recusado: {
    background: 'var(--red-pastel, #FDCED6)',
    color: 'var(--red-support, #F8294E)',
    message: 'Recusado'
  },
  Erro: {
    background: 'var(--red-pastel, #FDCED6)',
    color: 'var(--red-support, #F8294E)',
    message: 'Erro'
  },
  Pendente: {
    background: 'var(--yellow-2-support, #DFF7FF)',
    color: 'var(--yellow-3-support, #1D95BF)',
    message: 'Pendente'
  }
}

const StyledStatus = styled.div`
  text-align: center;
  font-family: Neometric;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: ${(props) => statusMap[props.status].background};
  color: ${(props) => statusMap[props.status].color};
`

const StatusPayment = ({ status }) => {
  return (
    <StyledStatus status={status}>
      <span>{statusMap[status].message}</span>
    </StyledStatus>
  )
}

export default StatusPayment
