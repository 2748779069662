export const formatDayOfWeek = (date) => {
  const daysOfWeek = [
    'domingo',
    'segunda',
    'terça',
    'quarta',
    'quinta',
    'sexta',
    'sábado'
  ]
  const dayIndex = new Date(date).getDay()
  return daysOfWeek[dayIndex]?.toUpperCase()
}

export const formatMonth = (date) => {
  const months = [
    'jan',
    'fev',
    'mar',
    'abr',
    'mai',
    'jun',
    'jul',
    'ago',
    'set',
    'out',
    'nov',
    'dez'
  ]
  const monthIndex = new Date(date).getMonth()
  return months[monthIndex]
}

export const formatTime = (date) => {
  const hours = new Date(date).getUTCHours()
  const minutes = new Date(date).getUTCMinutes()
  return `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`
}

export const formatDateAndTime = (date) => {
  const formattedDate = new Date(date).toLocaleString('pt-PT', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'GMT'
  })

  const [datePart, timePart] = formattedDate.split(', ')
  const [day, month, year] = datePart.split('/')
  const [hour, minute] = timePart.split(':')

  const formattedDateAndTime = `${day}/${month}/${year.slice(
    -2
  )} - ${hour}:${minute}`

  return formattedDateAndTime
}
export const formatDate = (date) => {
  const data = new Date(date)
  const dia = data.getUTCDate().toString().padStart(2, '0')
  const mes = (data.getUTCMonth() + 1).toString().padStart(2, '0') // Os meses começam do 0
  const ano = data.getUTCFullYear()

  return `${dia}/${mes}/${ano}`
}

export const convertDateFormat = (inputDate) => {
  console.log(inputDate, 'input')
  // inputDate in dd/mm/yyyy format
  let splitDate = inputDate?.split('/')
  if (splitDate.count === 0) {
    return null
  }

  let year = splitDate[2]
  let month = splitDate[1]
  let day = splitDate[0]

  return year + '-' + month + '-' + day
}
