import styled, { keyframes } from 'styled-components'

import { Card as UICard } from '../../../../../../components/Card'

const skeletonLoading = keyframes`
  0% {
    background-color: rgba(209, 213, 219, 0.5);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
`

export const Wrapper = styled.div`
  margin: 0 auto;
  margin-top: 3.2rem;
  max-width: 144rem;
  padding: 0 4rem;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;

    > div:first-of-type {
      display: flex;
      flex-direction: column;
      gap: 4.8rem;

      > div:first-of-type {
        > h2 {
          height: 3.6rem;
          width: 12rem;
          animation: ${skeletonLoading} 1s linear infinite alternate;
          border-radius: 0.8rem;
          background-color: rgba(209, 213, 219, 0.5);
        }

        > p {
          height: 10rem;
          width: 100%;
          animation: ${skeletonLoading} 1s linear infinite alternate;
          border-radius: 0.8rem;
          background-color: rgba(209, 213, 219, 0.5);
        }

        > * + * {
          margin-top: 0.8rem;
        }
      }

      > div:last-of-type {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 2.4rem;
      }
    }

    > div:last-of-type {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 2.4rem;
    }
  }

  @media (min-width: 1024px) {
    padding: 0 16rem;

    > div {
      flex-direction: row;
      align-items: flex-start;
      gap: 2.4rem;

      > div:first-of-type {
        width: 66.666667%;

        > div:first-of-type p {
          width: 70rem;
        }
      }

      > div:first-of-type {
        width: auto;
        flex: 1 1 0%;
      }
    }
  }
`

export const Card = styled(UICard)`
  height: 29.4rem;
  width: 100%;
  animation: ${skeletonLoading} 1s linear infinite alternate;
  border-radius: 0.8rem;
  background-color: rgba(209, 213, 219, 0.5);

  @media (min-width: 1024px) {
    height: 31rem;
    width: 34rem;
  }
`

export const Topbar = styled.div`
  display: flex;
  height: 42.2rem;
  width: 100%;
  animation: ${skeletonLoading} 1s linear infinite alternate;
  flex-direction: column;
  align-items: center;
  background-color: rgba(209, 213, 219, 0.5);

  @media (min-width: 1024px) {
    height: 46.6rem;
  }
`
