import { Divider, Flex, MediaQuery } from '@mantine/core'
import * as Styled from './styles'
import { ReactComponent as Direction } from './imgs/direction.svg'
import { ReactComponent as Pin } from './imgs/mapPin.svg'
import Chevron from './imgs/right.webp'
import Passenger from './imgs/passenger.webp'
import Shape from './imgs/shape.webp'
import Carseat from './imgs/carseat.webp'
import Paw from './imgs/paw.webp'
import Briefcase from './imgs/briefcase.webp'
import Clipboard from './imgs/Clipboard.webp'

import { useClipboard } from '@mantine/hooks'
import { useNavigate } from 'react-router-dom'
import {
  formatDateAndTime,
  formatDayOfWeek,
  formatMonth,
  formatTime
} from '../../../../../../utils/formattedDate'
import { toast } from 'react-toastify'
import { STATUS_LABEL_MAP } from '../../../../../../constants/status_label_map'
import { LowCostBadge } from '../LowCostBadge'
import { useTranslation } from 'react-i18next'
import { Copy } from 'lucide-react'

export function LowCostCard({ transfer }) {
  const clipboard = useClipboard({ timeout: 500 })
  const navigate = useNavigate()

  const totalPrice = transfer.totalPrice / 100
  const totalDriverCommission = (transfer?.totalDriverCommission ?? 0) / 100
  const netValue =
    (transfer.totalPrice - (transfer?.totalDriverCommission ?? 0)) / 100

  const { t } = useTranslation()

  return (
    <Styled.Container
      key={transfer.id}
      status={transfer.status}
      onClick={() =>
        navigate(`/dashboard/details/${transfer.id}`, {
          replace: true
        })
      }
    >
      <Styled.StatusContainer>
        <Styled.StatusCard status={transfer.status}>
          {STATUS_LABEL_MAP[transfer.status]}
        </Styled.StatusCard>
        <Styled.TimeDescription>
          {formatDateAndTime(transfer.transferDate)}
        </Styled.TimeDescription>
        <Styled.PriceMobile>
          € {(transfer.totalPrice / 100).toLocaleString('pt-PT')}
        </Styled.PriceMobile>
        <Styled.DayTitle>
          {formatDayOfWeek(transfer.transferDate)},{' '}
          {formatDateAndTime(transfer.transferDate)}
        </Styled.DayTitle>
        <Styled.IdWrapper
          onClick={(e) => {
            e.stopPropagation()
            try {
              clipboard.copy(transfer.id)
              toast.success('ID copiado')
            } catch {
              toast.error('Erro ao copiar ID')
            }
          }}
        >
          <strong>ID: </strong>
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            {transfer.id}
            <img style={{ cursor: 'pointer' }} src={Clipboard} alt="Copy id" />
          </span>
        </Styled.IdWrapper>
      </Styled.StatusContainer>
      <Styled.Wrapper>
        <Styled.InfoWrapper>
          <Styled.AddressWrapper>
            <Styled.DepartureWrapper>
              <Styled.DepartureTitle>
                <Pin /> Partida
              </Styled.DepartureTitle>
              <Styled.DepartureDescription>
                {transfer.startingPlace}
              </Styled.DepartureDescription>
            </Styled.DepartureWrapper>

            <div>
              <Styled.KmTitle style={{ marginBottom: 10 }}>
                {transfer.distanceInKm ?? 0}km
              </Styled.KmTitle>

              <Styled.DirectionButton>
                <Direction />
              </Styled.DirectionButton>
            </div>

            <Styled.DepartureWrapper>
              <Styled.DepartureTitle>
                <Pin /> Chegada
              </Styled.DepartureTitle>
              <Styled.DepartureDescription>
                {transfer.arrivalPlace}
              </Styled.DepartureDescription>
            </Styled.DepartureWrapper>
          </Styled.AddressWrapper>

          <Styled.DistanceMobile>
            {transfer.distanceInKm}km
          </Styled.DistanceMobile>
        </Styled.InfoWrapper>

        <Styled.ValuesWrapper>
          <MediaQuery query="(max-width: 1024px)" styles={{ display: 'none' }}>
            <Styled.PriceTitle>
              <Styled.DepartureTitle>Valor a Cobrar</Styled.DepartureTitle>€
              {totalPrice.toLocaleString('pt-PT')}
            </Styled.PriceTitle>
          </MediaQuery>
          <VerticalSeparator />

          <MediaQuery query="(max-width: 1024px)" styles={{ display: 'none' }}>
            <Styled.PriceTitle>
              <Styled.DepartureTitle>Valor EasyTransfer</Styled.DepartureTitle>€
              {totalDriverCommission.toLocaleString('pt-PT')}
            </Styled.PriceTitle>
          </MediaQuery>
          <VerticalSeparator />

          <MediaQuery query="(max-width: 1024px)" styles={{ display: 'none' }}>
            <Styled.PriceTitle>
              <Styled.DepartureTitle>Valor Líquido</Styled.DepartureTitle>€
              {netValue.toLocaleString('pt-PT')}
            </Styled.PriceTitle>
          </MediaQuery>
        </Styled.ValuesWrapper>
        {/* <Divider
          className="divider"
          style={{ margin: '0 25px' }}
          orientation="vertical"
        /> */}
        <Styled.ButtonsWrapper>
          <Styled.ChevronButton>
            <img src={Chevron} alt="Right Chevron" />
          </Styled.ChevronButton>
        </Styled.ButtonsWrapper>
      </Styled.Wrapper>
      <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
        <LowCostBadge />
        <Styled.IconsAndNumbersWrapper>
          <Styled.QuantityText>Quantidade Permitida</Styled.QuantityText>

          {transfer.backpacks > 0 && (
            <Styled.IconAndNumber>
              <img
                src={Briefcase}
                style={{ height: '24px' }}
                alt="Briefcase icon"
              />
              <span>{transfer.backpacks}</span>
            </Styled.IconAndNumber>
          )}
          {transfer.numberOfPassengers > 0 && (
            <Styled.IconAndNumber>
              <img
                src={Passenger}
                style={{ height: '17px' }}
                alt="Passenger icon"
              />
              <span>{transfer.numberOfPassengers}</span>
            </Styled.IconAndNumber>
          )}
          {transfer.surfboards > 0 && (
            <Styled.IconAndNumber>
              <img src={Shape} style={{ height: '24px' }} alt="Shape icon" />
              <span>{transfer.surfboards}</span>
            </Styled.IconAndNumber>
          )}
          {transfer.babySeats > 0 && (
            <Styled.IconAndNumber>
              <img
                src={Carseat}
                style={{ height: '24px' }}
                alt="Carseat icon"
              />
              <span>{transfer.babySeats}</span>
            </Styled.IconAndNumber>
          )}
          {transfer.animals > 0 && (
            <Styled.IconAndNumber>
              <img src={Paw} style={{ height: '24px' }} alt="Paw icon" />
              <span>{transfer.animals}</span>
            </Styled.IconAndNumber>
          )}
        </Styled.IconsAndNumbersWrapper>
      </div>
    </Styled.Container>
  )
}

function VerticalSeparator() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2"
      height="46"
      viewBox="0 0 2 46"
      fill="none"
    >
      <path d="M1 0V46" stroke="#27C7FF" />
    </svg>
  )
}
