import { Button, Flex, Input, MediaQuery } from '@mantine/core'
import * as Styled from './styles'

export const Acess = ({ data, setData, disabled }) => {
  const handleInputChange = (field, value) => {
    setData((prevValues) => ({
      ...prevValues,
      [field]: value
    }))
  }
  return (
    <Styled.Container>
      <MediaQuery query="(max-width: 768px)">
        <Flex gap="lg" direction="column">
          <Styled.Title>
            Digite a senha. Escolha sempre senhas fortes e fáceis de lembrar.
          </Styled.Title>
          {/* <Input.Wrapper label="Senha atual*">
            <Input
              // icon={<Send />}
              onChange={(event) =>
                handleInputChange('actualPassword', event.target.value)
              }
              value={data?.actualPassword}
              placeholder="************"
              size={'lg'}
                                disabled={disabled}

            />
          </Input.Wrapper> */}
          <Input.Wrapper label="Senha*">
            <Input
              // icon={<Send />}
              type="password"
              onChange={(event) =>
                handleInputChange('password', event.target.value)
              }
              value={data?.password}
              placeholder="************"
              size={'lg'}
              disabled={disabled}
            />
          </Input.Wrapper>

          <Input.Wrapper label="Confirmar senha*">
            <Input
              // icon={<Send />}
              onChange={(event) =>
                handleInputChange('confirmPassword', event.target.value)
              }
              type="password"
              value={data?.confirmPassword}
              placeholder="************"
              size={'lg'}
              disabled={disabled}
            />
          </Input.Wrapper>
        </Flex>
      </MediaQuery>
    </Styled.Container>
  )
}
