import React, { useEffect, useState } from 'react'
import {
  createStyles,
  Header,
  Container,
  Anchor,
  Group,
  rem
} from '@mantine/core'
import * as Styled from './styles'
// import { useDisclosure } from '@mantine/hooks'
import { PersonalData } from './components/PersonalData'
import { HouseHold } from './components/HouseHold'
import { BankAccount } from './components/BankAccount'
import { Customization } from './components/Customization'
import { Users } from './components/Users'
import { Drivers } from './components/Drivers'
import { useMemo } from 'react'
import { useAuth } from '../../../../contexts/useAuth'
import { Car } from './components/Car'
import fetchAccount from '../../../../services/account'
import { toast } from 'react-toastify'
import { Arquives } from './components/Arquives'
import { Acess } from './components/Acess'
import { convertDateFormat, formatDate } from '../../../../utils/formattedDate'
import fetchFile from '../../../../services/file'
import { useParams } from 'react-router-dom'
import fetchCompany from '../../../../services/company'
import fetchHostel from '../../../../services/hostel'
import { ArquivesEnterprise } from './components/ArquivesEnterprise'

// import { TransferCard } from './TransferCard' // Crie o componente TransferCard para exibir os dados de cada transferência

const HEADER_HEIGHT = rem(84)

const useStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
    maxWidth: '100%',
    '@media (max-width: 768px)': {
      height: 'auto'
    }
  },

  // burger: {
  //   [theme.fn.largerThan('sm')]: {
  //     display: 'none'
  //   }
  // },

  links: {
    paddingTop: theme.spacing.lg,
    height: HEADER_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 0,
    textDecoration: 'none',
    gap: '16px',
    '@media (max-width: 768px)': {
      height: 'auto'
    }
    // [theme.fn.smallerThan('sm')]: {
    //   display: 'none'
    // }
  },

  mainLinks: {
    marginRight: `calc(${theme.spacing.sm} * -1)`,

    '@media (max-width: 768px)': {
      justifyContent: 'center',
      marginRight: '0'
    }
  },

  mainLink: {
    color: '#404040',
    padding: '8px 20px',
    transition: 'border-color 100ms ease, color 100ms ease',
    borderBottom: '1px solid var(--grey-3-grayscale, #BFBFBF)',
    textDecoration: 'none',
    fontFamily: 'Neometric',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '130%',
    letterSpacing: '0.16px',

    '&:hover': {
      textDecoration: 'none'
    },

    '@media (max-width: 768px)': {
      width: '180px',
      fontSize: '14px',
      textAlign: 'center'
    }
  },

  secondaryLink: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
    fontSize: theme.fontSizes.xs,
    textTransform: 'uppercase',
    transition: 'color 100ms ease'

    // '&:hover': {
    //   color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    //   textDecoration: 'none'
    // }
  },

  mainLinkActive: {
    color: '#27c7ff',
    borderBottom: '3px solid var(--yellow-brand-primary, #27c7ff)',
    fontFamily: 'Neometric',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '130%',
    letterSpacing: '0.16px'
  }
}))

const INITIAL_VALUES = {
  files: []
}

export function Settings() {
  const { ROLE, user } = useAuth()
  const [data, setData] = useState(INITIAL_VALUES)

  const { classes, cx } = useStyles()
  const [active, setActive] = useState(0)

  const isCompany = window.location.pathname.includes('company')
  const isHostel = window.location.pathname.includes('hostel')

  console.log(data, 'data')

  const driverLinks = useMemo(
    () => [
      { label: 'Dados pessoais', link: '/personal-data' },
      { label: 'Morada', link: '/household' },
      { label: 'Conta bancária', link: '/bank-account' },
      { label: 'Automovel', link: '/bank-account' },
      { label: 'Documentos', link: '/arquives' },
      { label: 'Acesso', link: '/acess' }
    ],
    []
  )
  const admLinks = useMemo(
    () => [
      { label: 'Dados pessoais', link: '/personal-data' },
      // { label: 'Morada', link: '/household' },
      { label: 'Conta bancária', link: '/bank-account' }
    ],
    []
  )
  const hostelAdmLinks = useMemo(
    () => [
      { label: 'Dados pessoais', link: '/personal-data' },
      { label: 'Morada', link: '/household' },
      { label: 'Acesso', link: '/bank-account' }
    ],
    []
  )
  const hostelLinks = useMemo(
    () => [
      { label: 'Dados pessoais' },
      { label: 'Morada' },
      { label: 'Conta bancária' },
      { label: 'Documentos' },
      { label: 'Usuários' },
      { label: 'Personalização' }
    ],
    []
  )
  const companyAdmLinks = useMemo(
    () => [
      { label: 'Dados pessoais', link: '/personal-data' },
      { label: 'Morada', link: '/household' },
      { label: 'Acesso', link: '/bank-account' }
    ],
    []
  )
  const companyLinks = useMemo(
    () => [
      { label: 'Dados pessoais' },
      { label: 'Morada' },
      { label: 'Conta bancária' },
      { label: 'Documentos' },
      { label: 'Usuários' },
      { label: 'Motoristas' }
    ],
    []
  )

  const SETTINGS_DECIDER = {
    USER: driverLinks,
    ADMIN: admLinks,
    HOTEL_USER: hostelAdmLinks,
    COMPANY_USER: driverLinks,
    COMPANY_ADMIN: companyAdmLinks
  }

  const mainItems = (
    window.location.pathname.includes('hostel')
      ? hostelLinks
      : window.location.pathname.includes('company')
      ? companyLinks
      : SETTINGS_DECIDER[ROLE]
  ).map((item, index) => (
    <Anchor
      key={item.label}
      className={cx(classes.mainLink, {
        [classes.mainLinkActive]: index === active
      })}
      onClick={(event) => {
        event.preventDefault()
        setActive(index)
      }}
    >
      {item.label}
    </Anchor>
  ))

  useEffect(() => {
    if (window.location.pathname.includes('hostel')) {
      fetchHostel
        .getByIdAll(user.hostel.id)
        .then((res) => {
          if (res) {
            setData({
              ...res.data,
              files: res.data.files
                ? res.data.files.filter((item) => item.deleted === false)
                : []
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } else if (window.location.pathname.includes('company')) {
      fetchCompany
        .getById(user.company.id)
        .then((res) => {
          if (res) {
            setData({
              ...res.data,
              files: res.data.files
                ? res.data.files.filter((item) => item.deleted === false)
                : []
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      fetchAccount
        .getById(user.id)
        .then((res) => {
          if (res) {
            if (ROLE === 'USER' || ROLE === 'COMPANY_USER') {
              setData({
                ...res.data,
                files: res.data.files
                  ? res.data.files.filter((item) => item.deleted === false)
                  : [],
                dateOfBirth: formatDate(res.data.dateOfBirth),
                vehicle: {
                  ...res.data.vehicle,
                  endOfInsurance: formatDate(res.data.vehicle.endOfInsurance)
                }
              })
            } else {
              setData({
                ...res.data,
                dateOfBirth: formatDate(res.data.dateOfBirth),
                files: res.data.files
                  ? res.data.files.filter((item) => item.deleted === false)
                  : []
              })
            }
          }
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [window.location.pathname])

  const handleEditProfile = () => {
    const toastId = toast.loading('Editando Conta...')

    if (data.id) {
      if (
        ROLE === 'COMPANY_ADMIN' ||
        ROLE === 'HOTEL_USER' ||
        isCompany ||
        isHostel
      ) {
        const filesPromises = []
        if (data?.image && data?.image?.base64 && !data?.image?.id) {
          const profilePictureData = {
            title: data.image.title,
            base64: data.image.base64,
            fileName: data.image.fileName,
            fileExtension: data.image.fileExtension
          }
          const createProfilePicturePromise = fetchFile
            .create(profilePictureData)
            .then((res) => {
              data.image.id = res.data.id
            })
            .catch((err) => console.log(err))

          filesPromises.push(createProfilePicturePromise)
        }

        if (data.files && data.files.length) {
          data.files.forEach((file, index) => {
            if (file.base64 && !file.id) {
              const fileData = {
                title: file.title,
                base64: file.base64,
                fileName: file.fileName,
                fileExtension: file.fileExtension
              }
              const createFilePromise = fetchFile
                .create(fileData)
                .then((res) => {
                  data.files[index].id = res.data.id
                })
                .catch((err) => console.log(err))

              filesPromises.push(createFilePromise)
            }
          })
        }
        const allFilesPromise = Promise.all(filesPromises)

        allFilesPromise
          .then(() => {
            if (isCompany) {
              const payload = {
                ...data
              }

              fetchCompany
                .updateCompany(payload)
                .then((res) => {
                  if (res) {
                    toast.update(toastId, {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      newestOnTop: false,
                      closeOnClick: true,
                      rtl: false,
                      pauseOnFocusLoss: true,
                      draggable: true,
                      pauseOnHover: true,
                      theme: 'light',
                      type: 'success',
                      closeButton: true,
                      isLoading: false,
                      render: 'Conta editada com sucesso!'
                    })
                  }
                })
                .catch((err) => {
                  console.log(err)
                  toast.update(toastId, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    newestOnTop: false,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: true,
                    draggable: true,
                    pauseOnHover: true,
                    theme: 'light',
                    type: 'error',
                    closeButton: true,
                    isLoading: false,
                    render: 'Erro ao editar conta'
                  })
                })
            } else if (isHostel) {
              const payload = {
                ...data
              }
              fetchHostel
                .update(payload)
                .then((res) => {
                  if (res) {
                    toast.update(toastId, {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      newestOnTop: false,
                      closeOnClick: true,
                      rtl: false,
                      pauseOnFocusLoss: true,
                      draggable: true,
                      pauseOnHover: true,
                      theme: 'light',
                      type: 'success',
                      closeButton: true,
                      isLoading: false,
                      render: 'Conta editada com sucesso!'
                    })
                  }
                })
                .catch((err) => {
                  console.log(err)
                  toast.update(toastId, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    newestOnTop: false,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: true,
                    draggable: true,
                    pauseOnHover: true,
                    theme: 'light',
                    type: 'error',
                    closeButton: true,
                    isLoading: false,
                    render: 'Erro ao editar conta'
                  })
                })
            } else if (ROLE === 'COMPANY_ADMIN' || ROLE === 'HOTEL_USER') {
              const payload = {
                ...data,
                dateOfBirth: convertDateFormat(data.dateOfBirth) + 'T00:00:00Z'
              }
              delete payload.workingDays
              delete payload.workingShifts
              delete payload.presentationVideo
              delete payload.vehicle
              delete payload.category
              fetchAccount
                .updateAccount(payload)
                .then((res) => {
                  if (res) {
                    toast.update(toastId, {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      newestOnTop: false,
                      closeOnClick: true,
                      rtl: false,
                      pauseOnFocusLoss: true,
                      draggable: true,
                      pauseOnHover: true,
                      theme: 'light',
                      type: 'success',
                      closeButton: true,
                      isLoading: false,
                      render: 'Conta editada com sucesso!'
                    })
                  }
                })
                .catch((err) => {
                  console.log(err)
                  toast.update(toastId, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    newestOnTop: false,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: true,
                    draggable: true,
                    pauseOnHover: true,
                    theme: 'light',
                    type: 'error',
                    closeButton: true,
                    isLoading: false,
                    render: 'Erro ao editar conta'
                  })
                })
            }
          })
          .catch((err) => {
            console.log(err)
            toast.update(toastId, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              newestOnTop: false,
              closeOnClick: true,
              rtl: false,
              pauseOnFocusLoss: true,
              draggable: true,
              pauseOnHover: true,
              theme: 'light',
              type: 'error',
              closeButton: true,
              isLoading: false,
              render: 'Erro ao editar empresa devido a erros nos arquivos'
            })
          })
      } else {
        const filesPromises = []
        if (
          data?.profilePicture &&
          data?.profilePicture?.base64 &&
          !data?.profilePicture?.id
        ) {
          const profilePictureData = {
            title: data?.profilePicture?.title,
            base64: data?.profilePicture?.base64,
            fileName: data?.profilePicture?.fileName,
            fileExtension: data?.profilePicture?.fileExtension
          }
          const createProfilePicturePromise = fetchFile
            .create(profilePictureData)
            .then((res) => {
              data.profilePicture.id = res?.data?.id
            })
            .catch((err) => console.log(err))

          filesPromises.push(createProfilePicturePromise)
        }
        if (
          data?.vehicle?.externalPhoto &&
          data?.vehicle?.externalPhoto?.base64 &&
          !data?.vehicle?.externalPhoto?.id
        ) {
          const profilePictureData = {
            title: data.vehicle?.externalPhoto.title,
            base64: data.vehicle?.externalPhoto.base64,
            fileName: data.vehicle?.externalPhoto.fileName,
            fileExtension: data.vehicle?.externalPhoto.fileExtension
          }
          const createProfilePicturePromise = fetchFile
            .create(profilePictureData)
            .then((res) => {
              data.vehicle.externalPhoto.id = res.data.id
            })
            .catch((err) => console.log(err))

          filesPromises.push(createProfilePicturePromise)
        }
        if (
          data?.vehicle?.internalPhoto &&
          data?.vehicle?.internalPhoto?.base64 &&
          !data?.vehicle?.internalPhoto?.id
        ) {
          const profilePictureData = {
            title: data.vehicle?.internalPhoto.title,
            base64: data.vehicle?.internalPhoto.base64,
            fileName: data.vehicle?.internalPhoto.fileName,
            fileExtension: data.vehicle?.internalPhoto.fileExtension
          }
          const createProfilePicturePromise = fetchFile
            .create(profilePictureData)
            .then((res) => {
              data.vehicle.internalPhoto.id = res.data.id
            })
            .catch((err) => console.log(err))

          filesPromises.push(createProfilePicturePromise)
        }

        if (data.files && data.files.length) {
          data.files.forEach((file, index) => {
            if (file.base64 && !file.id) {
              const fileData = {
                title: file.title,
                base64: file.base64,
                fileName: file.fileName,
                fileExtension: file.fileExtension
              }
              const createFilePromise = fetchFile
                .create(fileData)
                .then((res) => {
                  data.files[index].id = res.data.id
                })
                .catch((err) => console.log(err))

              filesPromises.push(createFilePromise)
            }
          })
        }
        const allFilesPromise = Promise.all(filesPromises)
        allFilesPromise
          .then(() => {
            const payload = {
              ...data,
              dateOfBirth: convertDateFormat(data.dateOfBirth) + 'T00:00:00Z',
              vehicle: {
                ...data.vehicle,
                endOfInsurance:
                  convertDateFormat(data.vehicle.endOfInsurance) + 'T00:00:00Z'
              }
            }
            delete payload.workingDays
            delete payload.workingShifts
            delete payload.presentationVideo

            if (data) {
              fetchAccount
                .updateAccount(payload)
                .then((res) => {
                  if (res) {
                    toast.update(toastId, {
                      position: 'top-right',
                      autoClose: 5000,
                      hideProgressBar: false,
                      newestOnTop: false,
                      closeOnClick: true,
                      rtl: false,
                      pauseOnFocusLoss: true,
                      draggable: true,
                      pauseOnHover: true,
                      theme: 'light',
                      type: 'success',
                      closeButton: true,
                      isLoading: false,
                      render: 'Conta editada com sucesso!'
                    })
                  }
                })
                .catch((err) => {
                  console.log(err)
                  toast.update(toastId, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    newestOnTop: false,
                    closeOnClick: true,
                    rtl: false,
                    pauseOnFocusLoss: true,
                    draggable: true,
                    pauseOnHover: true,
                    theme: 'light',
                    type: 'error',
                    closeButton: true,
                    isLoading: false,
                    render: 'Erro ao editar conta'
                  })
                })
            }
          })
          .catch((err) => {
            console.log(err)
            toast.update(toastId, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              newestOnTop: false,
              closeOnClick: true,
              rtl: false,
              pauseOnFocusLoss: true,
              draggable: true,
              pauseOnHover: true,
              theme: 'light',
              type: 'error',
              closeButton: true,
              isLoading: false,
              render: 'Erro ao editar devido a erros nos arquivos'
            })
          })
      }
    }
  }

  return (
    <Styled.Container>
      <Styled.HeaderContainer>
        <Styled.Title>Perfil</Styled.Title>
        <Styled.EditButton onClick={handleEditProfile}>
          Editar
        </Styled.EditButton>
      </Styled.HeaderContainer>
      <Header style={{ borderBottom: 'transparent' }}>
        <Container className={classes.inner}>
          <div className={classes.links}>
            <Group spacing={0} position="right" className={classes.mainLinks}>
              {mainItems}
            </Group>
          </div>
        </Container>
      </Header>
      <Styled.InputsContent>
        {(ROLE === 'USER' || ROLE === 'COMPANY_USER') && (
          <>
            {active === 0 && <PersonalData data={data} setData={setData} />}
            {active === 1 && <HouseHold data={data} setData={setData} />}
            {active === 2 && <BankAccount data={data} setData={setData} />}
            {active === 3 && <Car data={data} setData={setData} />}
            {active === 4 && <Arquives data={data} setData={setData} />}
            {active === 5 && <Acess data={data} setData={setData} />}
          </>
        )}
        {ROLE === 'ADMIN' && (
          <>
            {active === 0 && <PersonalData data={data} setData={setData} />}
            {/* {active === 1 && <HouseHold data={data} setData={setData} />} */}
            {active === 1 && <BankAccount data={data} setData={setData} />}
          </>
        )}
        {(ROLE === 'HOTEL_USER' || ROLE === 'COMPANY_ADMIN') &&
          !window.location.pathname.includes('hostel') &&
          !window.location.pathname.includes('company') && (
            <>
              {active === 0 && <PersonalData data={data} setData={setData} />}
              {active === 1 && <HouseHold data={data} setData={setData} />}
              {active === 2 && <Acess data={data} setData={setData} />}
            </>
          )}
        {window.location.pathname.includes('hostel') && (
          <>
            {active === 0 && <PersonalData data={data} setData={setData} />}
            {active === 1 && <HouseHold data={data} setData={setData} />}
            {active === 2 && <BankAccount data={data} setData={setData} />}
            {active === 3 && (
              <ArquivesEnterprise data={data} setData={setData} />
            )}
            {active === 4 && <Users data={data} setData={setData} hostel />}
            {active === 5 && <Customization data={data} setData={setData} />}
          </>
        )}
        {window.location.pathname.includes('company') && (
          <>
            {active === 0 && <PersonalData data={data} setData={setData} />}
            {active === 1 && <HouseHold data={data} setData={setData} />}
            {active === 2 && <BankAccount data={data} setData={setData} />}
            {active === 3 && (
              <ArquivesEnterprise data={data} setData={setData} />
            )}
            {active === 4 && (
              <Users data={data} setData={setData} hostel={false} />
            )}
            {active === 5 && (
              <Drivers
                data={data}
                setData={setData}
                driver
                id={user?.company?.id}
              />
            )}
          </>
        )}
      </Styled.InputsContent>
    </Styled.Container>
  )
}
