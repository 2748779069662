import * as Styled from './styles'

import passenger from '../../../../../Fleet/imgs/passenger.webp'
import briefcase from '../../../../../Fleet/imgs/briefcase.webp'
import wifi from '../../../../../Fleet/imgs/wifi.webp'

export const CarCard = ({ car }) => {
  return (
    <Styled.Container>
      {car.map((car) => (
        <>
          <Styled.CarContainer key={car.title}>
            <Styled.ContentWrapper>
              <Styled.ImageCar src={car.img} />
              <Styled.OptionsAndTitleContainer>
                <Styled.Title>{car.title}</Styled.Title>
                <Styled.OptionsContainer>
                  {car.passengers && (
                    <Styled.Passenger>
                      <img src={passenger} />
                      <p
                        style={{
                          background: 'rgba(226, 226, 226, 0.4)',
                          border: '0.509009px solid rgba(56, 40, 14, 0.3)',
                          boxShadow:
                            '0px 4.07207px 6.10811px rgba(0, 0, 0, 0.07)',
                          borderRadius: '69px',
                          width: '19.68px',
                          height: '19.68px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontWeight: '500',
                          fontSize: '12.38px',
                          lineHeight: '15px'
                        }}
                      >
                        {car.passengers}
                      </p>
                    </Styled.Passenger>
                  )}
                  {car.briefcase && (
                    <Styled.Briefcase>
                      <img src={briefcase} />
                      <p
                        style={{
                          background: 'rgba(226, 226, 226, 0.4)',
                          border: '0.509009px solid rgba(56, 40, 14, 0.3)',
                          boxShadow:
                            '0px 4.07207px 6.10811px rgba(0, 0, 0, 0.07)',
                          borderRadius: '69px',
                          width: '19.68px',
                          height: '19.68px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontWeight: '500',
                          fontSize: '12.38px',
                          lineHeight: '15px'
                        }}
                      >
                        {car.briefcase}
                      </p>
                    </Styled.Briefcase>
                  )}
                  {car.wifi && <Styled.Wifi src={wifi} />}
                </Styled.OptionsContainer>
              </Styled.OptionsAndTitleContainer>
            </Styled.ContentWrapper>
          </Styled.CarContainer>
          <Styled.PriceContainer>
            <Styled.ChoiceButton>Total</Styled.ChoiceButton>
            <h1>€ {car.price}</h1>
            <p>IVA incl.</p>
          </Styled.PriceContainer>
        </>
      ))}
    </Styled.Container>
  )
}
