export const validateData = (data) => {
  return Object.entries(data).every(([key, value]) => {
    if (key === 'deleted') return true
    return value instanceof Array ? value.length > 0 : !!value
  })
}

export const generatePayload = (data, file = {}) => {
  return {
    id: data.id,
    name: data.name,
    kmPerLiter: Number(
      data.kmPerLiter.value.replace('.', '').replace(',', '.')
    ),
    imageIdentifier: data.imageIdentifier,
    file: file,
    passengerCapacity: data.passengerCapacity,
    luggageCapacity: data.luggageCapacity,
    handLuggageCapacity: data.handLuggageCapacity,
    backpackCapacity: data.backpackCapacity,
    providesAirConditioning: data.providesAirConditioning,
    providesInternet: data.providesInternet
  }
}

export const formatErrorMessage = (message, isEditing) => {
  switch (message) {
    case 'Fields: "coupon" already registered.':
      return 'Esta categoria já foi cadastrada!'
    default:
      return `Erro ao ${isEditing ? 'editar' : 'criar'} categoria!`
  }
}
