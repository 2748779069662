import styled, { css } from 'styled-components'

import * as BottomBannerStyles from '../Events/components/BottomBanner/styles'

export const Container = styled.div`
  ${({ withoutOverflow }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${!withoutOverflow
      ? css`
          overflow-x: hidden;
        `
      : css`
          overflow-x: clip;
        `}
    position: relative;
  `}
`

export const ContentWrapper = styled.div`
  ${({ hasPadding }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;

    width: 1160px;
    height: auto;

    margin-top: 12rem;

    ${BottomBannerStyles.Wrapper} {
      margin-bottom: 15rem;
    }

    .mantine-Carousel-control {
      width: 32px;
      height: 32px;

      svg {
        width: 14px;
        height: 14px;
      }
    }

    ${hasPadding &&
    css`
      padding-left: 15.6rem;
      align-self: flex-start;
    `}

    @media screen and (max-width: 768px) {
      width: 100%;
      gap: 24px;

      margin-top: 8rem;
      padding: 0 16px;
    }
  `}
`
