import styled, { css } from 'styled-components'

import CardTourImage from '../../../../assets/cardTour_1.png'

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  margin-bottom: 4rem;

  @media screen and (max-width: 905px) {
    align-items: center;
    padding: 0;
  }
`

export const TopHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 2rem;

  h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 2rem;
    color: #222222;
  }

  @media screen and (max-width: 905px) {
    align-self: flex-start;
  }
`

const tagModifiers = {
  active: () => css`
    /* background-color: #27c7ff; */
    border-color: #27c7ff;
    color: #fff;
  `
}

export const Tag = styled.span`
  ${({ active }) => css`
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1;

    background-color: transparent;
    text-align: center;
    border: 0.1rem solid #000;
    border-radius: 5rem;
    padding: 0.6rem 1.6rem;
    z-index: 2;
    color: #000;
    height: 3rem;
    width: 10rem;
    min-width: 10rem;
    max-width: 10rem;
    white-space: nowrap;
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out,
      color 0.3s ease-in-out;

    :hover {
      background-color: #27c7ff;
      border-color: #27c7ff;
      color: #fff;
    }

    ${active && tagModifiers.active}
  `}
`

export const FormLabel = styled.span`
  ${({ activeItemIdx }) => css`
    /* font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #38280e; */

    display: flex;
    align-items: center;
    gap: 0.4rem;

    > div {
      flex: 1;
      width: 100%;
      max-width: 100%;
      display: flex;
      align-items: center;
      gap: 0.4rem;
      overflow-x: auto;
      position: relative;

      ::before {
        content: '';
        position: absolute;
        z-index: 1;
        height: 3rem;
        width: 10rem;
        border-radius: 5rem;
        border: 0.1rem solid #27c7ff;
        background-color: #27c7ff;
        transition: margin 0.3s ease;
        margin-left: calc(${activeItemIdx} * 10.4rem);
      }
    }

    @media screen and (max-width: 905px) {
      align-self: flex-start;
    }

    /* @media screen and (max-width: 905px) {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
  } */
  `}
`

export const Select = styled.select`
  font-family: 'Neometric';
  font-weight: 600;
  font-size: 2rem;

  padding: 0.5rem 0.25rem;
  width: 10.8rem;

  border: none;
  border-radius: 0.4rem;

  display: flex;
  align-items: center;

  option {
    font-size: 1.4rem;
  }

  &:focus {
    outline: none;
    border: none;
  }

  &::placeholder {
    color: #27c7ff;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.2rem;
  }
`

export const ContainerCardTours = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 10px;

  margin-top: 24px;

  @media screen and (max-width: 905px) {
    justify-content: center;
  }

  a {
    width: 264px;
  }

  @media screen and (max-width: 768px) {
    a {
      width: 100%;
    }
  }
`

export const CardTour = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  width: 264px;
  height: 143px;

  border-radius: 8px;

  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

export const CardDescription = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  padding: 8px;
  border-radius: 0 0 8px 8px;

  background: linear-gradient(0deg, #111 7.33%, rgba(64, 64, 64, 0) 112.67%),
    linear-gradient(0deg, #111 7.33%, rgba(64, 64, 64, 0) 94.67%);

  svg {
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    min-height: 2rem;
  }
`

export const CardTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #fff;

  margin-bottom: 4px;
`

export const CardRating = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: 14px;
    font-weight: 700;

    color: #fff;

    margin-left: 8px;
  }
`
