import { createContext, useState } from 'react'

export const MobileSidebarContext = createContext(null)

export const MobileSidebarContextProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  return (
    <MobileSidebarContext.Provider
      value={{
        isSidebarOpen,
        setIsSidebarOpen
      }}
    >
      {children}
    </MobileSidebarContext.Provider>
  )
}
