import styled, { css } from 'styled-components'
import { ReactComponent as Menu } from './images/menu.svg'

export const Container = styled.header`
  font-weight: '400';
  padding: 3rem;

  display: flex;
  height: 8rem;
  border: 1px solid #e6e6e6;
  position: relative;

  position: fixed;
  width: 100%;
  top: 0;

  background-color: #fff;
  z-index: 10;
`

export const Navbar = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 4rem;

  img {
    height: 70px;
  }

  g {
    filter: none;
  }

  @media (max-width: 1024px) {
    svg {
      display: flex;
      cursor: pointer;
    }
  }
`

export const MenuMobile = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: flex;
    cursor: pointer;
  }
`

export const Link = styled.li`
  ${({ theme }) => css`
    color: ${theme.colors.lightGray};
    list-style: none;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    position: relative;
  `}

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: #27c7ff;
    z-index: 1;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s ease-in-out;
  }

  &:hover::before {
    transform: scaleX(1);
  }
`

export const Button = styled.button`
  padding: 12px 24px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(223, 247, 255, 0.5);
  background: #27c7ff;
  border: 0;
  color: white;
  font-weight: 600;
  cursor: pointer;
  transition: 1s;
  border: solid 1px #27c7ff;

  &:hover {
    background-color: #fff;
    color: #27c7ff;
    border: solid 1px #27c7ff;
  }
`
export const LogOutButton = styled.button`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: var(--yellow-2-support, #DFF7FF);

  color: var(--yellow-3-support, #1D95BF);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;
  text-transform: uppercase;

  @media (max-width: 425px) {
    display: none;
  }
`
