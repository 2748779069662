import styled, { css } from 'styled-components'
export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
`

export const TransferContainer = styled.div`
  ${({ theme }) => css`
    position: static;
    display: flex;
    /* justify-content: space-between; */
    gap: 3.2rem;
    background-color: ${theme.colors.white};
    border-radius: 22px;
    padding: 2rem 1.6rem;
    align-items: center;
    z-index: 15;
    width: 672.97px;
    height: auto;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 22px 24px 0px rgba(0, 0, 0, 0.07);

    @media only screen and (max-width: 768px) {
      gap: 1.6rem;
      bottom: 1.4rem;
      width: 80vw;
      border-radius: 1.6rem;

      > div > img {
        width: 12.5rem;
        /* height: 15.1rem; */
      }
    }

    @media only screen and (max-width: 700px) {
      padding: 1.6rem;
      width: 90%;

      height: auto;
      flex-direction: column;
      align-items: center;
    }
  `}
`

export const OneTransfer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  span {
    color: var(--yellow-brand-primary, #27c7ff);
    text-align: center;
    font-family: Roboto !important;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    margin-left: -10px;
    line-height: 120%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    /* 14.4px */
  }

  .mantine-TextInput-input {
    border: 0;
  }

  .mantine-TextInput-icon {
    /* position: absolute; */
    /* bottom: 10px; */
    /* bottom: 20px; */
    left: -1rem;
  }

  .mantine-TextInput-wrapper {
    display: flex;
  }

  .mantine-TextInput-label {
    font-family: 'Neometric';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    padding-left: 2.2rem;

    color: #38280e;
  }

  .mantine-InputWrapper-root {
    position: relative;
  }

  .mantine-TextInput-input {
    font-family: 'Neometric';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: #8a8a8a;

    :focus {
      ::placeholder {
        visibility: hidden !important;
      }
    }
  }

  @media only screen and (max-width: 420px) {
    flex-direction: column;
  }
`

export const BtnContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* gap: 1rem; */
  width: 30rem;
  height: 100%;

  > div {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    p {
      font-family: Neometric;
      color: #27c7ff;
      font-size: 1.4rem;
      font-weight: 500;
    }

    strong {
      color: #222222;
      font-family: Neometric;
      font-size: 2rem;
      font-weight: 600;
    }
  }

  @media only screen and (max-width: 700px) {
    width: 100%;
  }
`

export const BtnPrimary = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    background-color: rgb(251, 59, 59);
    width: 20rem;
    height: 8rem;
    /* padding: 30px 40px; */
    border-radius: 1.6rem;
    font-size: 1.6rem;
    font-weight: 600;
    box-shadow: ${theme.shadows.minimal};

    @media only screen and (max-width: 700px) {
      font-size: 1.4rem;
      text-transform: uppercase;
      line-height: 1;

      height: 4.8rem;
      padding: 1.4rem;
      border-radius: 0.8rem;

      max-width: 30rem;
      margin: 2rem auto 0;
    }
  `}
`
export const BtnSecondary = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    background-color: ${theme.colors.white};
    padding: 30px 30px;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 600;
    border: 0.1px solid rgba(56, 40, 14, 0.3);
    box-shadow: ${theme.shadows.minimal};
  `}
`

export const OptionsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    z-index: 10;
    position: absolute;
    bottom: 8.8rem;
    align-items: center;

    .mantine-SegmentedControl-root {
      display: flex;
      border-radius: 3px 3px 0 0;
      z-index: 10;
      align-items: center;
      padding: 0;
    }

    .mantine-SegmentedControl-label {
      padding: 8px 16px;
      color: white;
      font-size: 12px;
    }

    .mantine-SegmentedControl-control {
      background-color: ${theme.colors.primary};
    }

    .mantine-SegmentedControl-label[data-active] {
      background-color: white;
      transition: ease-in-out 300ms;
      color: black;
    }
  `}
`

export const SelectedOption = styled.h2`
  padding: 0 1rem;
  color: white;
`

export const PrimaryOption = styled.h2`
  padding: 0 1rem;
  background-color: white;
  border-radius: 3px 0 0 0;
  height: 100%;
  display: flex;
  align-items: center;
`
