import 'dayjs/locale/pt-br'
import * as Styled from './styles'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import fetchCoupon from '../../../../../../services/coupon'
import { PAYMENT_METHODS } from '../CreateCouponModal/constants'
import { Switch } from '@mantine/core'
import { useState } from 'react'
import { toast } from 'react-toastify'

dayjs.locale('pt-br')
dayjs.extend(relativeTime)

export const CouponInfo = ({ couponData, loadingState, setLoadingState }) => {
  const {
    coupon,
    active,
    discountPercentage,
    validFrom,
    validTo,
    paymentMethodsAllowed,
    usageLimitPerUser
  } = couponData

  const [innerActive, setInnerActive] = useState(active)

  const formattedFrom = dayjs(validFrom).format('DD/MM/YYYY')
  const formattedTo = dayjs(validTo).format('DD/MM/YYYY')
  const remainingTime = dayjs().to(dayjs(validTo)).substring(3)
  const paymentMethodLabels = paymentMethodsAllowed.map(
    (method) => PAYMENT_METHODS.find((m) => m.value === method).label
  )

  const handleChangeActive = async (status) => {
    setLoadingState((oldState) => ({ ...oldState, changingActive: true }))
    const toastId = toast.loading('Alterando status...')
    try {
      await fetchCoupon.update({
        ...couponData,
        active: status
      })
      setInnerActive(status)
      toast.update(toastId, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: 'light',
        type: 'success',
        closeButton: true,
        isLoading: false,
        render: 'Status do cupom alterado com sucesso!'
      })
      setLoadingState((oldState) => ({ ...oldState, changingActive: false }))
    } catch (err) {
      console.log(err)
      toast.update(toastId, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: 'light',
        type: 'error',
        closeButton: true,
        isLoading: false,
        render: 'Erro ao alterar status do cupom!'
      })
      setLoadingState((oldState) => ({ ...oldState, changingActive: false }))
    }
  }

  return (
    <Styled.Wrapper>
      <Styled.InfoWrapper>
        <div>
          <div>
            <strong>Código promocional</strong>
            <p>{coupon}</p>
          </div>
          <div>
            <strong>Valor/%</strong>
            <p>{discountPercentage}%</p>
          </div>
        </div>
        <div>
          <div>
            <strong>Período de validade</strong>
            <p>
              {formattedFrom} - {formattedTo}
            </p>
            {innerActive && <span>Restando {remainingTime} para acabar</span>}
          </div>
          {typeof usageLimitPerUser === 'number' && (
            <div>
              <strong>Limite de uso</strong>
              <p>{usageLimitPerUser}</p>
            </div>
          )}
        </div>
        <div>
          <div>
            <strong>Aplicar em métodos de pagamento:</strong>
            <div>
              {paymentMethodLabels.map((method, index) => (
                <span key={index}>{method}</span>
              ))}
            </div>
          </div>
        </div>
      </Styled.InfoWrapper>
      <div>
        <Switch
          label={innerActive ? 'Ativo' : 'Inativo'}
          size="lg"
          labelPosition="left"
          color="green-switch"
          checked={innerActive}
          disabled={loadingState.changingActive}
          onChange={(event) => handleChangeActive(event.currentTarget.checked)}
          styles={{
            input: {
              cursor: 'pointer !important'
            },
            label: {
              cursor: 'pointer !important'
            },
            track: {
              cursor: 'pointer !important'
            },
            trackLabel: {
              cursor: 'pointer !important'
            }
          }}
        />
      </div>
    </Styled.Wrapper>
  )
}
