import { useEffect, useMemo, useRef, useState } from 'react'
import { CalendarIcon } from '../../icons/CalendarIcon'
import * as Styled from './styles'

import { Button, Group } from '@mantine/core'
import { DatePicker } from '@mantine/dates'
import { periodFormatter } from './period_formatter'

export function PeriodFilter({ onChange, onClick, item, selected }) {
  const ref = useRef(null)

  const [datePickerVisibility, setDatePickerVisibility] = useState(false)
  const [range, setRange] = useState([null, null])
  const [isConfirmed, setIsConfirmed] = useState(false)

  const label = useMemo(() => {
    if (isConfirmed) {
      return `${periodFormatter(range[0])} - ${periodFormatter(range[1])}`
    }

    return item.option
  }, [item.option, isConfirmed, range])

  function handleClick() {
    if (!selected) onClick()
    setDatePickerVisibility((s) => !s)
  }

  function handleChange() {
    setIsConfirmed(true)
    setDatePickerVisibility(false)
    onChange({
      startDate: range[0],
      endDate: range[1]
    })
  }

  function onClear() {
    setIsConfirmed(false)
    setDatePickerVisibility(false)
    setRange([null, null])
    onChange({ startDate: null, endDate: null })
    onClick()
  }

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setDatePickerVisibility(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return (
    <Styled.Option
      ref={ref}
      onClick={handleClick}
      first={false}
      select={selected}
      last
    >
      {label}
      <CalendarIcon selected={selected} />
      {datePickerVisibility && (
        <DateRangePicker
          onConfirm={handleChange}
          onClear={onClear}
          setValue={(value) => {
            setIsConfirmed(false)
            setRange(value)
          }}
          value={range}
        />
      )}
    </Styled.Option>
  )
}

function DateRangePicker({ onConfirm, value, setValue, onClear }) {
  return (
    <Styled.FloatingContainer onClick={(e) => e.stopPropagation()}>
      <Styled.Card>
        <Group position="center">
          <DatePicker
            type="range"
            value={value}
            onChange={setValue}
            defaultDate={value[0] ?? new Date()}
            size="xl"
          />
        </Group>
        <div>
          <Button
            style={{ marginTop: 10, backgroundColor: '#27c7ff' }}
            onClick={onConfirm}
            size="lg"
          >
            Salvar
          </Button>
          <Button
            style={{
              backgroundColor: 'transparent',
              border: '1px solid #dee2e6',
              color: 'black',
              fontWeight: 'normal',
              marginLeft: 5
            }}
            onClick={onClear}
            size="lg"
          >
            Limpar
          </Button>
        </div>
      </Styled.Card>
    </Styled.FloatingContainer>
  )
}
