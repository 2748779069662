import { useCallback, useEffect, useState } from 'react'
import api from '../../services/api'
import {
  Avatar,
  Badge,
  Table,
  Group,
  Text,
  ActionIcon,
  Anchor,
  rem,
  Checkbox
} from '@mantine/core'
import { Copy, ChevronLeft } from 'lucide-react'
import { useClipboard } from '@mantine/hooks'
import * as Styled from '../DataTableFinancial/styles'

const columns = [
  { title: 'Nome', prop: 'name' },
  { title: 'ID', prop: 'id' },
  { title: 'Status', prop: 'active' },
  { title: 'E-mail', prop: 'email' }
]

export const FinancialPartners = ({ setFilters, filters }) => {
  const [selectedPartner, setSelectedPartner] = useState(null)
  const [partners, setPartners] = useState([])
  const [loading, setLoading] = useState(false)

  const clipboard = useClipboard({ timeout: 500 })

  const getPartners = useCallback(async () => {
    setLoading(true)
    try {
      const response = await api.get('/hostel')
      setPartners(response?.data?.content ?? [])
    } catch (err) {
      console.log('Error while fetching hostels', err)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getPartners()
  }, [getPartners])

  useEffect(() => {
    if (filters?.hostelId !== selectedPartner?.id) {
      setSelectedPartner(
        partners?.find((item) => item?.id === filters?.hostelId)
      )
    }
  }, [filters?.hostelId, partners, selectedPartner])

  return (
    <Styled.Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px'
        }}
      >
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          {selectedPartner && (
            <button
              type="button"
              onClick={() => {
                setFilters((prevFilters) => {
                  const newFilters = { ...prevFilters }
                  if (newFilters?.hostelId) delete newFilters.hostelId
                  return newFilters
                })
                setSelectedPartner(null)
              }}
              style={{ all: 'unset', cursor: 'pointer' }}
            >
              <ChevronLeft size={32} />
            </button>
          )}
          <span style={{ fontSize: '18px', padding: '4px 0' }}>
            {selectedPartner ? selectedPartner?.name : 'Selecione um Partner'}
          </span>
        </div>
        {!selectedPartner && (
          <Table verticalSpacing="sm">
            <thead>
              <tr>
                {columns.map((item, index) => (
                  <th key={index}>{item.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {partners?.map((partner) => {
                const { id, name, deleted, email, image } = partner
                return (
                  <tr
                    key={id}
                    onClick={() => {
                      setSelectedPartner(partner)
                      setFilters({
                        hostelId: partner?.id
                      })
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <td>
                      <Group gap="sm">
                        <Avatar size={32} src={image?.url} radius={30} />
                        <Text fz="sm" fw={500}>
                          {name}
                        </Text>
                      </Group>
                    </td>
                    <td>
                      <Text fz="sm">
                        {id?.length > 8 ? id?.slice(0, 8) + '...' : id}{' '}
                        <Copy
                          size="16"
                          style={{ cursor: 'pointer' }}
                          alt="Copy id"
                          onClick={(e) => {
                            e.stopPropagation()
                            try {
                              clipboard.copy(id)
                            } catch {
                              console.log('Erro ao copiar ID')
                            }
                          }}
                        />
                      </Text>
                    </td>
                    <td>
                      <Text fz="sm">{deleted ? 'Inativo' : 'Ativo'}</Text>
                    </td>
                    <td>
                      <Anchor component="button" size="sm">
                        {email}
                      </Anchor>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        )}
      </div>
    </Styled.Container>
  )
}
