import apiStrapi from './apiStrapi'

const fetchObj = {
  // createPayment: (payload) => {
  //   const res = apiStrapi.post('/transfer', payload)

  //   return res
  // },
  // changeStatus: (id, status) => {
  //   const res = apiStrapi.patch(`/transfer/change-status/${id}/${status}`)

  //   return res
  // },
  // transfersPerUser: (params) => {
  //   const res = apiStrapi.get(`/transfer`, {
  //     params: {
  //       ...params
  //     }
  //   })

  //   return res
  // },

  getAll: (params) => {
    const res = apiStrapi.get('/tours', { params })
    return res
  }
}

export default fetchObj
