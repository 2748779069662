export const generateEditableData = (categoryData) => {
  const formattedValue = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2
  }).format(parseFloat(categoryData.kmPerLiter))
  return {
    id: categoryData.id,
    name: categoryData.name,
    imageIdentifier: categoryData.imageIdentifier,
    kmPerLiter: {
      value: formattedValue,
      formattedValue: `€ ${formattedValue}`
    },
    files: categoryData.file ? [categoryData.file] : []
  }
}
