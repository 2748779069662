import styled from 'styled-components'

import {
  Card as UICard,
  CardHeader as UICardHeader,
  CardContent as UICardContent
} from '../../../../../../components/Card'

export const Card = styled(UICard)`
  border: none;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
`

export const CardHeader = styled(UICardHeader)`
  text-align: center;
  font-size: 1.8rem;
  line-height: 2.8rem;
  font-weight: 600;
  line-height: 2rem;
`

export const CardContent = styled(UICardContent)`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  > div {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    > svg {
      width: 2.4rem;
      height: 2.4rem;
      color: #27c7ff;
    }

    > span {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
`
