import { MoreVertical } from 'lucide-react'
import { useRef, useState } from 'react'
import { MODEL_DECIDER } from '../CreateCategoryModal/constants'
import useClickOutside from '../../../../../../hooks/useClickOutside'
import * as Styled from './styles'

export const CategoryItem = ({
  category,
  order,
  deleteCategory,
  editCategory
}) => {
  const { name, kmPerLiter, imageIdentifier } = category

  const optionsButtonRef = useRef(null)
  const [isItemOptionsOpen, setIsItemOptionsOpen] = useState(false)
  const domNode = useClickOutside(
    () => setIsItemOptionsOpen(false),
    optionsButtonRef,
    null
  )

  const formatCurrency = (currency) => {
    return Intl.NumberFormat('PT', {
      currency: 'EUR',
      style: 'currency'
    }).format(currency)
  }

  const handleDeleteCategory = async () => {
    setIsItemOptionsOpen(false)
    await deleteCategory(category.id)
  }

  const handleEditCategory = () => {
    setIsItemOptionsOpen(false)
    editCategory(category)
  }

  return (
    <Styled.Wrapper>
      <div>
        <span>{order}</span>
        <div>
          <button
            ref={optionsButtonRef}
            onClick={() => setIsItemOptionsOpen((oldState) => !oldState)}
          >
            <MoreVertical color={isItemOptionsOpen ? '#27c7ff' : '#222222'} />
          </button>
          {isItemOptionsOpen && (
            <div ref={domNode}>
              <button onClick={handleEditCategory}>Editar</button>
              <button onClick={handleDeleteCategory}>Excluir</button>
            </div>
          )}
        </div>
      </div>
      <div>
        <img src={MODEL_DECIDER[imageIdentifier]} />
      </div>
      <div>
        <strong>{name}</strong>
        <span>{formatCurrency(kmPerLiter)} / km</span>
      </div>
    </Styled.Wrapper>
  )
}
