import { Card } from './components/Card'
import * as Styled from './styles'
import { item } from './item'
import { useTranslation } from 'react-i18next'

export const Header = () => {
  const { t } = useTranslation()

  return (
    <Styled.Container>
      <Card item={item(t)} />
    </Styled.Container>
  )
}
