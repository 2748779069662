import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: clamp(16px, 0.833vw, 0.833vw);
  margin-bottom: 150px;
  color: #0d0d0d;

  @media (max-width: 768px) {
    margin-bottom: 64px;
  }
`

export const Title = styled.h1`
  font-weight: 700;
  font-size: clamp(24px, 2.5vw, 2.5vw);

  color: var(--black-brand-primary, #222);
  font-family: Neometric;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-bottom: 32px;

  @media (max-width: 768px) {
    color: var(--black-brand-primary, #0d0d0d);
    text-align: center;
    font-family: Neometric;
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    /* max-width: 22rem; */

    margin-bottom: 24px;
  }
`
