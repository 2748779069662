import styled from 'styled-components'

export const Container = styled.div`
  padding: 1.6rem;
  border: 0.1rem solid #808080;
  border-radius: 1.6rem;
  width: 34rem;
  min-height: 20.8rem;
  position: relative;
  background-color: #ffffff;
  cursor: pointer;

  &.active {
    border-color: #27c7ff;
  }

  > svg {
    position: absolute;
    left: 0.8rem;
    top: 0.8rem;
    width: 6rem;
    min-width: 6rem;
    height: 6rem;
    min-height: 6rem;
    transform: rotate(-45deg);
    opacity: 0.4;
    stroke: #27c7ff;
  }

  > span {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    padding: 0.6rem 0.8rem;
    display: flex;
    align-items: center;
    background-color: #f7f7f7;
    border-radius: 0.8rem;
    color: #404040;
    font-family: Roboto;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.16px;

    > span {
      width: 1.2rem;
      height: 1.2rem;
      min-height: 1.2rem;
      min-width: 1.2rem;
      border-radius: 50%;
      margin-right: 0.6rem;

      background-color: #808080;

      &.active {
        background-color: #48d9a4;
      }
    }
  }
`

export const CouponDetails = styled.div`
  margin-left: 6rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    strong {
      display: block;
      color: #27c7ff;
      font-family: Neometric;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.16px;
      margin-bottom: 0.4rem;

      &:first-of-type {
        margin-top: 3.4rem;
      }
    }

    p {
      color: #404040;
      font-family: Neometric;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.16px;
      margin-bottom: 0.4rem;
    }

    span {
      color: #808080;
      font-family: Neometric;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.14px;
    }
  }

  button {
    font-size: 0;
    border-radius: 50%;
    width: 3.6rem;
    min-width: 3.6rem;
    height: 3.6rem;
    min-height: 3.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f7;

    svg {
      width: 2rem;
      height: 2rem;
    }
  }
`
