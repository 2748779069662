import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
`

export const TransferContainer = styled.div`
  ${({ theme }) => css`
    position: static;
    display: flex;
    /* justify-content: space-between; */
    gap: 3.2rem;
    background-color: ${theme.colors.white};
    border-radius: 22px;
    padding: 2rem 1.6rem;
    align-items: center;
    z-index: 15;
    /* width: 672.97px; */
    height: auto;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 22px 24px 0px rgba(0, 0, 0, 0.07);

    @media only screen and (max-width: 768px) {
      gap: 1.6rem;
      bottom: 1.4rem;
      width: 80vw;
      border-radius: 1.6rem;

      > div > img {
        width: 12.5rem;
        /* height: 15.1rem; */
      }
    }

    @media only screen and (max-width: 634px) {
      padding: 1.6rem;
      width: 90%;

      height: auto;
      flex-direction: column;
      align-items: center;
    }
  `}
`

export const OneTransfer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 208.92px;
  height: 49.83px;

  .mantine-InputWrapper-root {
    height: 49.83px;
  }

  .mantine-Select-input {
    font-family: 'Neometric';
    font-style: normal;
    font-weight: 400;
    font-size: 14.7782px;
    line-height: 18px;
    color: #8a8a8a;
  }

  .mantine-Select-label h2 {
    width: 89px;
    height: 22px;
    font-family: 'Neometric';
    font-style: normal;
    font-weight: 600;
    font-size: 18.1885px;
    line-height: 22px;
    color: #0d0d0d;
  }

  .mantine-Select-dropdown {
    background: #ffffff;
    box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.07);
    border-radius: 0px 16px 16px 16px;
    border: 0;
  }

  .mantine-DateTimePicker-input {
    border: 0;
  }
  .mantine-DateTimePicker-input[data-with-icon] {
    padding: 0;
  }
  .mantine-DateTimePicker-icon {
    padding-right: 0;
    position: relative;
  }
  .mantine-DateTimePicker-label {
    font-family: 'Neometric';
    font-style: normal;
    font-weight: 600;
    font-size: 18.1885px;
    line-height: 22px;
    color: #0d0d0d;

    padding-left: 3rem;
  }

  .mantine-DateTimePicker-input span,
  .mantine-DateTimePicker-input {
    font-family: 'Neometric';
    font-style: normal;
    font-weight: 400;
    font-size: 14.7782px;
    line-height: 18px;
    /* identical to box height */

    color: #8a8a8a;
  }

  .mantine-DateTimePicker-wrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .mantine-Popover-dropdown {
    background: #ffffff;
    box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.07);
    border-radius: 0px 22px 22px 22px;
    border: 0;
    display: flex;
  }

  .mantine-Input-input::placeholder {
    color: #8a8a8a;
  }

  .mantine-DateTimePicker-monthLevel {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
  .mantine-MonthLevelGroup-monthLevelGroup {
    margin: 2rem auto;
  }
`

export const OneTransferContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  gap: 8px;
`

export const BtnContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* gap: 1rem; */
  gap: 1.6rem;
  height: 100%;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.8rem;
      width: 100%;

      button {
        font-size: 0;
        background: #f7f7f7;
        border: none;

        width: 4rem;
        height: 4rem;

        border-radius: 50%;

        svg {
          width: 2.8rem;
          height: 2.8rem;
          color: #000000;
        }

        &:disabled {
          cursor: not-allowed;

          svg {
            color: #bfbfbf;
          }
        }
      }
    }

    span {
      font: 400 1.2rem 'Roboto', sans-serif;
      color: #222222;

      strong {
        font-size: 1.4rem;
        font-weight: 700;
      }
    }
  }

  /* > div:last-of-type {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    p {
      font-family: Neometric;
      color: #27c7ff;
      font-size: 1.4rem;
      font-weight: 500;
    }

    strong {
      color: #222222;
      font-family: Neometric;
      font-size: 2rem;
      font-weight: 600;
    }
  } */

  @media only screen and (max-width: 634px) {
    width: 100%;

    > div > div {
      gap: 2.4rem;
    }
  }
`

export const BtnPrimary = styled.button`
  font-family: 'Neometric';
  font-style: normal;
  font-weight: 600;
  font-size: 20.4621px;
  line-height: 1;

  color: #ffffff;
  background: #27c7ff;
  box-shadow: 0px 4.54713px 9.09425px rgba(223, 247, 255, 0.5);
  border-radius: 18.1885px;
  padding: 30px 40px;

  ${({ theme }) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.primary};
    width: 100%;
    max-width: 20rem;
    height: 8rem;
    /* padding: 30px 40px; */
    border-radius: 1.6rem;
    font-size: 1.6rem;
    font-weight: 600;
    box-shadow: ${theme.shadows.minimal};

    @media only screen and (max-width: 634px) {
      font-size: 1.4rem;
      text-transform: uppercase;
      line-height: 1;

      height: 4.8rem;
      padding: 1.4rem;
      border-radius: 0.8rem;

      max-width: 30rem;
      margin: 2rem auto 0;
    }
  `}
`
export const BtnSecondary = styled.button`
  font-family: 'Neometric';
  font-style: normal;
  font-weight: 600;
  font-size: 20.4621px;
  line-height: 25px;
  color: #fcb13c;
  padding: 30px 30px;
  font-weight: 600;
  border: 0.1px solid rgba(56, 40, 14, 0.3);
  background: rgba(255, 255, 255, 0.4);
  border: 0.568391px solid rgba(56, 40, 14, 0.3);
  box-shadow: 0px 4.54713px 6.82069px rgba(0, 0, 0, 0.07);
  border-radius: 18.1885px;
`

export const OptionsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    z-index: 10;
    position: absolute;
    bottom: 8.8rem;
    align-items: center;

    .mantine-SegmentedControl-root {
      display: flex;
      border-radius: 3px 3px 0 0;
      z-index: 10;
      align-items: center;
      padding: 0;
    }

    .mantine-SegmentedControl-label {
      padding: 8px 16px;
      color: white;
      font-size: 12px;
    }

    .mantine-SegmentedControl-control {
      background-color: ${theme.colors.primary};
    }

    .mantine-SegmentedControl-label[data-active] {
      background-color: white;
      transition: ease-in-out 300ms;
      color: black;
    }
  `}
`

export const SelectedOption = styled.h2`
  padding: 0 1rem;
  color: white;
`

export const PrimaryOption = styled.h2`
  padding: 0 1rem;
  background-color: white;
  border-radius: 3px 0 0 0;
  height: 100%;
  display: flex;
  align-items: center;
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const ChoiceContainer = styled.div`
  display: flex;
  gap: 10px;
  overflow: auto;
  max-width: 360px;
  width: 100%;

  .selected {
    border: 1.5px solid #27c7ff;
  }
`

export const AddDestiny = styled.p`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;

  font-family: 'Neometric';
  font-style: normal;
  font-weight: 400;
  font-size: 11.3678px;
  line-height: 14px;
  color: #0d0d0d;

  img {
    cursor: pointer;
  }
`
export const CardContainer = styled.div`
  border-radius: 8px;
  border: 1px solid #bfbfbf;
  background: var(--white-1-lp, #fff);
  display: flex;
  padding: 0px 16px 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  position: relative;
  width: 350px;
  cursor: pointer;
`

export const TimeWrapper = styled.div`
  border-radius: 0px 0px 8px 8px;
  background: var(--yellow-3-support, #1D95BF);
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

export const Time = styled.span`
  color: var(--white-1-lp, #fff);
  leading-trim: both;
  text-edge: cap;
  font-family: Neometric;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 19.2px */
`
export const Price = styled.span`
  color: var(--black-brand-primary, #222);
  text-align: right;
  font-family: Neometric;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  right: 2rem;
  top: 0.4rem;

  strong {
    color: var(--black-brand-primary, #222);
    font-family: Neometric;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }
`
export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const Title = styled.h2`
  color: var(--grey-1-grayscale, #404040);
  font-family: Neometric;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const Description = styled.span`
  color: var(--grey-2-grayscale, #808080);
  font-family: Neometric;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`
export const AditionalInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  strong {
    color: var(--grey-1-grayscale, #404040);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  span {
    color: var(--grey-1-grayscale, #404040);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`
