import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

const ImgIcon = styled.img`
  width: 400px;
  position: relative;
  border-radius: 24px;
  height: 300px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  @media screen and (max-width: 599px) {
    align-self: stretch;
    width: auto;
  }
`
const H = styled.h4`
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 110%;
  font-weight: 700;
  font-family: inherit;
  @media screen and (max-width: 599px) {
    text-align: left;
    align-self: stretch;
    width: auto;
    flex: 1;
  }
`
const AsseguramosAosNossos = styled.span``
const GanhosConsistentesMas = styled.span`
  font-weight: 600;
  font-family: Poppins;
`
const ConteComA = styled.span`
  font-family: Poppins;
`
const AsseguramosAosNossosMotoris = styled.span`
  display: block;
`
const PTxt = styled.span`
  width: 100%;
`
const P = styled.p`
  margin: 0;
  width: 378px;
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 120%;
  color: #000;
  display: flex;
  align-items: center;
  font-family: Poppins;
  @media screen and (max-width: 599px) {
    text-align: left;
    align-self: stretch;
    width: auto;
    flex: 1;
  }
`
const H4Parent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  @media screen and (max-width: 599px) {
    align-self: stretch;
    width: auto;
  }
`
const ImgParentRoot = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 64px;
  text-align: left;
  font-size: 24px;
  color: #222;
  font-family: Neometric;
  @media screen and (max-width: 960px) {
    flex-direction: row;
    gap: 64px;
  }
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
`

const Container2Columns = () => {
  const { t } = useTranslation()
  return (
    <ImgParentRoot>
      <ImgIcon alt="" src="/img@2x.png" />
      <H4Parent>
        <H>{t('company.dailyTripsPerDriver')}</H>
        <P>
          <PTxt>
            <AsseguramosAosNossosMotoris>
              <AsseguramosAosNossos
                dangerouslySetInnerHTML={{
                  __html: t('company.dailyTripsDescription')
                }}
              />
            </AsseguramosAosNossosMotoris>
            <AsseguramosAosNossosMotoris>
              <AsseguramosAosNossos>&nbsp;</AsseguramosAosNossos>
            </AsseguramosAosNossosMotoris>
            <AsseguramosAosNossosMotoris>
              <AsseguramosAosNossos
                dangerouslySetInnerHTML={{
                  __html: t('company.longTrips')
                }}
              />
            </AsseguramosAosNossosMotoris>
          </PTxt>
        </P>
      </H4Parent>
    </ImgParentRoot>
  )
}

export default Container2Columns
