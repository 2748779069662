import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  width: 100%;
  height: calc(100vh - 80px - 24px - 80px);

  border-radius: 16px;
  border: 0.5px solid #bfbfbf;
`
