import busImg from '../../imgs/vehicles/fleet-4.png'
import economicImg from '../../imgs/vehicles/fleet.png'
import executiveImg from '../../imgs/vehicles/fleet-2.png'
import vanImg from '../../imgs/vehicles/fleet-3.png'
import planeImg from '../../imgs/vehicles/fleet-5.png'

export const vehicles = (t) => [
  {
    imgSrc: economicImg,
    name: t('fleet.economic'),
    seatsAmount: 4
  },
  {
    imgSrc: executiveImg,
    name: t('fleet.executive'),
    seatsAmount: 4
  },
  {
    imgSrc: vanImg,
    name: t('fleet.van'),
    seatsAmount: 8
  },
  {
    imgSrc: busImg,
    name: t('fleet.bus'),
    seatsAmount: 52
  },
  {
    imgSrc: planeImg,
    name: t('fleet.airplane'),
    seatsAmount: 3
  }
]
