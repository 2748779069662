import { Routes, Route } from 'react-router-dom'
import Home from '../pages/Home'
import { Corporate } from '../pages/Corporate'
import { Fleet } from '../pages/Fleet'
import { Events } from '../pages/Events'
import { Checkout } from '../pages/Checkout/Checkout'
import { Checkout2 } from '../pages/Checkout/Checkout2'
import { Checkout3 } from '../pages/Checkout/Checkout3'
import { Checkout4 } from '../pages/Checkout/Checkout4'
import { Checkout5 } from '../pages/Checkout/Checkout5'
import { Checkout6 } from '../pages/Checkout/Checkout6'
import { Checkout as CheckoutTour } from '../pages/CheckoutTourEvents/Checkout'
import { Checkout2 as CheckoutTour2 } from '../pages/CheckoutTourEvents/Checkout2'
import { Checkout3 as CheckoutTour3 } from '../pages/CheckoutTourEvents/Checkout3'
import { Checkout4 as CheckoutTour4 } from '../pages/CheckoutTourEvents/Checkout4'
import { Checkout as CheckoutLowCost } from '../pages/CheckoutLowCost/Checkout'
import { Checkout2 as CheckoutLowCost2 } from '../pages/CheckoutLowCost/Checkout2'
import { Checkout3 as CheckoutLowCost3 } from '../pages/CheckoutLowCost/Checkout3'
import { Checkout4 as CheckoutLowCost4 } from '../pages/CheckoutLowCost/Checkout4'
import { CheckoutEvent } from '../pages/CheckoutEvents/Checkout'
import { CheckoutEvent2 } from '../pages/CheckoutEvents/Checkout2'
import { CheckoutEvent3 } from '../pages/CheckoutEvents/Checkout3'
import { CheckoutEvent4 } from '../pages/CheckoutEvents/Checkout4'
import { Tour } from '../pages/Tour'
import { TourLocal } from '../pages/Tour/Local'
import { Reimbursement } from '../pages/Reimbursement'
import { TermsConditions } from '../pages/TermsConditions'
import Login from '../pages/Login'
import Dashboard from '../pages/Dashboard'
import { DefaultPage } from '../components/DefaultPage'
import { TransferDetailsAdm } from '../pages/Dashboard/pages/TransferDetailsAdm'
import { TransferAdm } from '../pages/Dashboard/pages/TransferAdm'
import { Settings } from '../pages/Dashboard/pages/Settings'
import { ContentDashboard } from '../pages/Dashboard/components/ContentDashboard'
import { useMemo, useState } from 'react'
import { SettingsAdm } from '../pages/Dashboard/pages/SettingsAdm'
import { TransferDetails } from '../pages/Dashboard/pages/TransferDetails'
import {
  CheckoutById,
  CheckoutCreditCard
} from '../pages/Checkout/CheckoutById'
import { useAuth } from '../contexts/useAuth'
import { Partnership } from '../pages/Dashboard/pages/Partnership'
import { PartnershipMerge } from '../pages/Dashboard/pages/PartnershipMerge'
import { TransferDetailsHostel } from '../pages/Dashboard/pages/TransferDetailsHostel'
import ProtectedRoute from './ProtectedRoute'
import routesArr from './routesArr'
import Hostel from '../pages/Hostel'
import { Transfer } from '../pages/Dashboard/pages/Transfer'
import { TransferHostel } from '../pages/Dashboard/pages/TransferHostel'
import { DriverRecruitment } from '../pages/Dashboard/pages/DriverRecruitment'
import { EnterpriseRecruitment } from '../pages/Dashboard/pages/EnterpriseRecruitment'
import { FormDriverRecruitment } from '../pages/Dashboard/pages/FormDriverRecruitment'
import { FormEnterpriseRecruitment } from '../pages/Dashboard/pages/FormEnterpriseRecruitment'
import { CheckoutVerify } from '../pages/CheckoutTourEvents/CheckoutVerify'
import { Policies } from '../pages/Policies'

export const mainLinks = [
  { label: 'Dados pessoais', link: '/personal-data' },
  { label: 'Morada', link: '/household' },
  { label: 'Conta bancária', link: '/bank-account' }
]

export const Router = () => {
  const [activePage, setActivePage] = useState('')

  const TRANSFER_DETAILS_DECIDER = {
    USER: <TransferDetails />,
    ADMIN: <TransferDetailsAdm />,
    HOTEL_USER: <TransferDetailsHostel />,
    COMPANY_USER: <TransferDetails />,
    COMPANY_ADMIN: <TransferDetailsHostel />
  }
  const TRANSFER_LIST_DECIDER = {
    USER: <Transfer />,
    ADMIN: <TransferAdm />,
    HOTEL_USER: <TransferHostel />,
    COMPANY_USER: <Transfer />,
    COMPANY_ADMIN: <TransferHostel />
  }

  const { user, ROLE } = useAuth()
  const wrappedRoutes = routesArr.filter((i) => i.wrapWithDefaultPage)
  const nonWrappedRoutes = routesArr.filter((i) => !i.wrapWithDefaultPage)

  return (
    <Routes>
      <Route element={<DefaultPage />}>
        <Route path="/hostel/:hostelId" element={<Hostel />} />
        <Route path="/" element={<Home />} />
        <Route path="/corporate" element={<Corporate />} />
        <Route path="/fleet" element={<Fleet />} />
        <Route path="/eventos" element={<Events />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/checkout2" element={<Checkout2 />} />
        <Route path="/checkout3" element={<Checkout3 />} />
        <Route path="/checkout4" element={<Checkout4 />} />
        <Route path="/checkout5" element={<Checkout5 />} />
        <Route path="/checkout6" element={<Checkout6 />} />
        <Route path="/checkout/tour" element={<CheckoutTour />} />
        <Route path="/checkout2/tour" element={<CheckoutTour2 />} />
        <Route path="/checkout3/tour" element={<CheckoutTour3 />} />
        <Route path="/checkout4/tour" element={<CheckoutTour4 />} />
        <Route path="/checkout/event" element={<CheckoutEvent />} />
        <Route path="/checkout2/event" element={<CheckoutEvent2 />} />
        <Route path="/checkout3/event" element={<CheckoutEvent3 />} />
        <Route path="/checkout4/event" element={<CheckoutEvent4 />} />
        <Route path="/checkout/verify/:id" element={<CheckoutVerify />} />
        <Route path="/checkout/low-cost" element={<CheckoutLowCost />} />
        <Route path="/checkout2/low-cost" element={<CheckoutLowCost2 />} />
        <Route path="/checkout3/low-cost" element={<CheckoutLowCost3 />} />
        <Route path="/checkout4/low-cost" element={<CheckoutLowCost4 />} />
        <Route
          path="/checkout/success"
          element={<CheckoutCreditCard success={true} />}
        />
        <Route
          path="/checkout/error"
          element={<CheckoutCreditCard sucess={false} />}
        />

        <Route path="/tour" element={<Tour />} />
        <Route path="/tour/:city" element={<TourLocal />} />

        <Route path="/refund" element={<Reimbursement />} />
        <Route path="/termsconditions" element={<TermsConditions />} />
        <Route path="/policies" element={<Policies />} />
        <Route
          path="/trabalhe-conosco-motorista"
          element={<DriverRecruitment />}
        />
        <Route
          path="/trabalhe-conosco-motorista/formulario"
          element={<FormDriverRecruitment />}
        />
        <Route
          path="/trabalhe-conosco-empresa"
          element={<EnterpriseRecruitment />}
        />
        <Route
          path="/trabalhe-conosco-empresa/formulario"
          element={<FormEnterpriseRecruitment />}
        />
      </Route>
      <Route path="/login" element={<Login />} />

      <Route element={<ProtectedRoute isAllowed={!!user} />}>
        <Route
          path="/dashboard"
          element={
            <Dashboard activePage={activePage} setActivePage={setActivePage} />
          }
        >
          {wrappedRoutes.map(
            (route) =>
              route.roles.includes(ROLE) && (
                <Route
                  key={route.url}
                  path={route.url}
                  element={route.element}
                />
              )
          )}
          <Route path="transfer" element={TRANSFER_LIST_DECIDER[ROLE]} />
          <Route path="details/:id" element={TRANSFER_DETAILS_DECIDER[ROLE]} />
        </Route>
        {nonWrappedRoutes.map(
          (route) =>
            route.roles.includes(ROLE) && (
              <Route key={route.url} path={route.url} element={route.element} />
            )
        )}
      </Route>
    </Routes>
  )
}
