import {
  Button,
  Calendar,
  Card,
  CardContent,
  Input,
  Label,
  Popover,
  PopoverButton,
  PopoverContent,
  PopoverTrigger,
  Textarea,
  ToggleGroup,
  ToggleGroupItem,
  Wrapper,
  ToggleLabel,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectPeopleContent,
  SelectValue
} from './styles'

// import { env } from '@/env'
import { cn } from '../../../../utils/cn'
import { zodResolver } from '@hookform/resolvers/zod'
import axios from 'axios'
import { format } from 'date-fns'
import pt from 'date-fns/locale/pt'
import {
  LucideCalendarDays,
  LucideCar,
  LucideMail,
  LucideMap,
  LucideMapPin,
  LucideMenuSquare,
  LucidePhone,
  LucideUser2,
  LucideUsers2
} from 'lucide-react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { z } from 'zod'

import { vehicles } from './constants'
import { useQuery } from '@tanstack/react-query'

import { api } from '../../api'
import { useTranslation } from 'react-i18next'

const requestBudgetSchema = z.object({
  passengerName: z
    .string()
    .min(3, { message: 'Insira teu nome com ao menos 3 caracteres.' }),
  passengerPhoneNumber: z
    .string()
    .min(10, { message: 'Insira um telefone válido. Ex: (+351) 000000000' }),
  passengerEmail: z.string().email({ message: 'Insira um e-mail válido.' }),
  passengersNumber: z.string({
    required_error: 'Insira a quantidade de passageiros.'
  }),
  // startingPlace: z
  //   .string()
  //   .min(3, { message: 'Insira um ponto de partida válido.' }),
  EventName: z.string({ required_error: 'Insira o evento escolhido.' }),
  transferDate: z.date({ required_error: 'Insira uma data válida.' }),
  vehicleType: z.enum(
    ['Economico', 'SedanExecutivo', 'VanExecutiva', 'Autocarro'],
    {
      required_error: 'Escolha uma viatura.',
      invalid_type_error: 'Escolha uma viatura.'
    }
  ),
  message: z
    .string()
    .min(5, { message: 'Por favor dê mais detalhes sobre teu pedido.' })
})

export const BudgetRequest = ({ defineBudgetRef }) => {
  const { data: eventsList, isLoading: isEventListLoading } = useQuery({
    queryKey: ['get-events'],
    queryFn: async () => {
      const events = await api.get('/events?populate=mainImage')

      return events.data
    },
    refetchOnWindowFocus: false
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, errors }
  } = useForm({
    resolver: zodResolver(requestBudgetSchema)
  })

  const { t } = useTranslation()

  // const budgetRef = useRef(null)

  const requestBudget = async (_data) => {
    const [EventName] = _data.EventName.split('||')
    const data = {
      ..._data,
      EventName,
      passengersNumber: Number(_data.passengersNumber) // rhf controller bs
    }

    try {
      await axios.post(
        'https://connect.pabbly.com/workflow/sendwebhookdata/IjU3NjYwNTZkMDYzNjA0M2Q1MjZiNTUzNTUxMzci_pc',
        JSON.stringify(data)
      )

      toast.success(t('Success!'))
    } catch (err) {
      toast.error(
        'Tivemos um problema para processar seu pedido. Tente novamente mais tarde.'
      )
    }
  }

  // useEffect(() => {
  //   defineBudgetRef(budgetRef)
  // }, [defineBudgetRef])

  return (
    <Wrapper>
      <h2>{t('events.quoteRequest')}</h2>

      <form onSubmit={handleSubmit(requestBudget)}>
        <div>
          <div>
            <Label>
              <LucideUser2 />
              {t('events.fullName')}
            </Label>

            <div>
              <Input
                placeholder={t('events.enterFullName')}
                {...register('passengerName')}
              />

              {errors?.passengerName && <p>{errors.passengerName.message}</p>}
            </div>
          </div>

          <div>
            <Label>
              <LucidePhone />
              {t('events.phoneWhatsApp')}
            </Label>

            <div>
              <Input
                placeholder="(+351) 000000000"
                {...register('passengerPhoneNumber')}
              />

              {errors?.passengerPhoneNumber && (
                <p>{errors.passengerPhoneNumber.message}</p>
              )}
            </div>
          </div>

          <div>
            <Label>
              <LucideMail />
              {t('events.email')}
            </Label>

            <div>
              <Input
                placeholder={t('events.enterBestEmail')}
                {...register('passengerEmail')}
              />

              {errors?.passengerEmail && <p>{errors.passengerEmail.message}</p>}
            </div>
          </div>
        </div>

        <div>
          {/* <div>
            <Label>
              <LucideMapPin />
              {t('events.departureLocation')}
            </Label>

            <div>
              <Input
                placeholder={t('events.yourLocation')}
                {...register('startingPlace')}
              />

              {errors?.startingPlace && <p>{errors.startingPlace.message}</p>}
            </div>
          </div> */}

          <div>
            <Label>
              <LucideUsers2 />
              {t('events.numOfPeople')}
            </Label>

            <Controller
              control={control}
              name="passengersNumber"
              render={({ field }) => (
                <div>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <SelectTrigger isBudgetIcon>
                      <SelectValue placeholder={<span>0</span>} />
                    </SelectTrigger>

                    <SelectPeopleContent>
                      {Array.from({ length: 56 }).map((_, i) => {
                        return (
                          <SelectItem key={i + 1} value={String(i + 1)}>
                            {i + 1}
                          </SelectItem>
                        )
                      })}
                    </SelectPeopleContent>
                  </Select>

                  {errors?.passengersNumber && (
                    <p>{errors.passengersNumber.message}</p>
                  )}
                </div>
              )}
            />
          </div>

          <div>
            <Label>
              <LucideMap />
              {t('events.event')}
            </Label>

            <Controller
              control={control}
              name="EventName"
              render={({ field }) => (
                <div>
                  <Select
                    {...register('EventName')}
                    onValueChange={field.onChange}
                    value={field.value}
                  >
                    <SelectTrigger isBudgetIcon>
                      <SelectValue
                        placeholder={<span>{t('events.selectEvent')}</span>}
                      />
                    </SelectTrigger>

                    <SelectContent>
                      {!isEventListLoading && (
                        <>
                          {eventsList?.data.map((event) => {
                            return (
                              <SelectItem
                                value={`${String(event.id)}||${
                                  event.attributes.place
                                }`}
                                key={event.id}
                                hideIcon
                              >
                                <span
                                  style={{
                                    width: '100%',
                                    display: 'block',
                                    whiteSpace: 'nowrap'
                                    // marginLeft: '3.2rem'
                                  }}
                                >
                                  {event.attributes.name}
                                </span>
                              </SelectItem>
                            )
                          })}
                        </>
                      )}
                    </SelectContent>
                  </Select>

                  {errors?.EventName && <p>{errors.EventName.message}</p>}
                </div>
              )}
            />
          </div>

          <div>
            <Label>
              <LucideCalendarDays />
              {t('events.date')}
            </Label>

            <Controller
              control={control}
              name="transferDate"
              render={({ field }) => (
                <div>
                  <Popover {...register('transferDate')}>
                    <PopoverTrigger asChild>
                      <PopoverButton
                        notValue={!field.value}
                        variant={'outline'}
                      >
                        {field.value ? (
                          format(field.value, 'PPP', { locale: pt })
                        ) : (
                          <span>1 de janeiro de 2024</span>
                        )}
                      </PopoverButton>
                    </PopoverTrigger>
                    <PopoverContent>
                      <Calendar
                        mode="single"
                        locale={pt}
                        selected={field.value}
                        onSelect={field.onChange}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>

                  {errors?.transferDate && <p>{errors.transferDate.message}</p>}
                </div>
              )}
            />
          </div>
        </div>

        <div>
          <Label>
            <LucideCar />
            {t('events.vehicle')}
          </Label>

          <Controller
            control={control}
            name="vehicleType"
            render={({ field }) => (
              <div>
                <ToggleGroup
                  type="single"
                  onValueChange={field.onChange}
                  value={field.value}
                  {...register('vehicleType')}
                >
                  {vehicles(t).map(({ imgSrc, name, value }) => (
                    <ToggleGroupItem key={value} value={value} asChild>
                      <Card>
                        <CardContent>
                          <img src={imgSrc} alt="" />

                          <ToggleLabel>{name}</ToggleLabel>
                        </CardContent>
                      </Card>
                    </ToggleGroupItem>
                  ))}
                </ToggleGroup>

                {errors?.vehicleType && <p>Escolha uma viatura.</p>}
              </div>
            )}
          />
        </div>

        <div>
          <Label>
            <LucideMenuSquare />
            {t('events.message')}
          </Label>

          <div>
            <Textarea
              placeholder={t('events.writeDetails')}
              {...register('message')}
            />

            {errors?.message && <p>{errors.message.message}</p>}
          </div>
        </div>

        <div>
          <Button type="submit" disabled={isSubmitting}>
            {t('events.getQuote')}
          </Button>
        </div>
      </form>
    </Wrapper>
  )
}
