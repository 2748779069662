import { Button, Group, Input, PinInput } from '@mantine/core'
import * as Styled from './styles'
import User from './imgs/user.webp'
import Password from './imgs/password.svg'
import Xis from './imgs/xis.webp'
import { useState } from 'react'
import logo from './imgs/flaticon_easytransfer.webp'
import GoBack from './imgs/goBack.webp'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../../../../../../contexts/useAuth'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export const Card = () => {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [step, setStep] = useState('step1')
  const [code, setCode] = useState()
  const [newPasswordOne, setNewPasswordOne] = useState()
  const [newPasswordTwo, setNewPasswordTwo] = useState()

  const { t } = useTranslation()

  const { requestCode, signIn, user } = useAuth()

  const handleClickBack = () => {
    if (step === 'step2') {
      setStep('step1')
    } else if (step === 'step3') {
      setStep('step2')
    } else {
      setStep('step3')
    }
  }

  const handleLogin = async () => {
    try {
      await signIn({ email, password }).then(() =>
        toast.success(t('toast.successLogin'), {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        })
      )
    } catch (error) {
      toast.error(t('toast.errorCheckCode'), {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      })
      console.log(error)
    }
  }

  const handleRequestCode = () => {
    try {
      requestCode({ email }).then(
        () => (
          toast.success(t('toast.successCode'), {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          }),
          setStep('step3')
        )
      )
    } catch (error) {
      toast.error(t('toast.errorCheckCode'), {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      })
    }
  }

  const handleVerifyCode = async () => {
    try {
      await requestCode({ email, code }).then(
        () => (
          toast.success(t('success.verifyCode'), {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          }),
          setStep('step4')
        )
      )
    } catch (error) {
      toast.error(t('toast.errorCheckCode'), {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      })
    }
  }

  const handleSetNewPassword = async () => {
    try {
      await requestCode({ email, code, newPassword: newPasswordOne }).then(
        () => {
          toast.success(t('toast.sucessChangePassword'), {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          })

          setEmail('')
          setCode('')
          setPassword('')
          setStep('step1')
        }
      )
    } catch (error) {
      toast.error(t('toast.errorCheckCode'), {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      })
    }
  }

  return user ? (
    <Navigate to="/dashboard" replace />
  ) : (
    <Styled.Wrapper>
      <Styled.Container>
        {(step === 'step2' || step === 'step3' || step === 'step4') && (
          <Button
            className="backButtonLogin"
            onClick={handleClickBack}
            style={{ position: 'absolute', top: '3rem' }}
            variant="subtle"
            leftIcon={<img src={GoBack} alt="Go Back" />}
          />
        )}
        <Styled.LogoWrapper>
          <Styled.Logo src={logo} />
        </Styled.LogoWrapper>
        {step === 'step1' && (
          <>
            <Styled.Title>Olá, Seja bem vindo(a)</Styled.Title>
            <Input.Wrapper id="input-demo" label="E-mail">
              <Input
                icon={<img src={User} style={{ width: '22px' }} />}
                id="input-demo"
                placeholder="email@email.com"
                size={'lg'}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                rightSection={
                  email && (
                    <img
                      style={{ cursor: 'pointer' }}
                      onClick={() => setEmail('')}
                      width={22.55}
                      height={22.55}
                      src={Xis}
                    />
                  )
                }
              />
            </Input.Wrapper>
            <Input.Wrapper id="input-demo" label="Senha">
              <Input
                icon={<img src={Password} style={{ width: '22px' }} />}
                id="input-demo"
                placeholder="*************"
                type="password"
                size={'lg'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                rightSection={
                  password && (
                    <img
                      style={{ cursor: 'pointer' }}
                      onClick={() => setPassword('')}
                      width={22.55}
                      height={22.55}
                      src={Xis}
                    />
                  )
                }
              />
            </Input.Wrapper>
            <Styled.Password>
              Esqueceu sua senha?
              <Styled.PasswordButton onClick={() => setStep('step2')}>
                Clique aqui
              </Styled.PasswordButton>
            </Styled.Password>
            <Styled.ButtonContainer>
              <Styled.SaveButton onClick={() => handleLogin()}>
                Entrar
              </Styled.SaveButton>
            </Styled.ButtonContainer>
          </>
        )}
        {step === 'step2' && (
          <Styled.FormWrapper>
            <Styled.Title>
              Informe o e-mail cadastrado para receber o código de verificação{' '}
            </Styled.Title>
            <Styled.EmailWrapper>
              <Input.Wrapper id="input-demo" label="E-mail">
                <Input
                  icon={<img src={User} style={{ width: '22px' }} />}
                  id="input-demo"
                  placeholder="email@email.com"
                  size={'lg'}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  rightSection={
                    email && (
                      <img
                        style={{ cursor: 'pointer' }}
                        onClick={() => setEmail('')}
                        width={22.55}
                        height={22.55}
                        src={Xis}
                      />
                    )
                  }
                />
              </Input.Wrapper>
              <Styled.ButtonContainer>
                <Styled.SaveButton
                  onClick={() => {
                    handleRequestCode()
                  }}
                >
                  Avançar
                </Styled.SaveButton>
              </Styled.ButtonContainer>
            </Styled.EmailWrapper>
          </Styled.FormWrapper>
        )}
        {step === 'step3' && (
          <Styled.FormWrapper>
            <Styled.Title>
              Acesse seu e-mail e insira o código recebido
            </Styled.Title>
            <Styled.EmailWrapper>
              <Input.Wrapper id="input-demo" label="Código de verificação">
                <Group position="center">
                  <PinInput
                    type="number"
                    value={code}
                    onChange={(e) => setCode(e)}
                    length={6}
                  />
                </Group>
              </Input.Wrapper>
              <Styled.ButtonContainer>
                <Styled.SaveButton onClick={() => handleVerifyCode()}>
                  Avançar
                </Styled.SaveButton>
              </Styled.ButtonContainer>
            </Styled.EmailWrapper>
          </Styled.FormWrapper>
        )}
        {step === 'step4' && (
          <Styled.FormWrapper>
            <Styled.Title>Crie uma nova senha</Styled.Title>
            <Styled.PasswordsWrapper>
              <Input.Wrapper id="input-demo" label="Nova senha">
                <Input
                  icon={<img src={Password} style={{ width: '22px' }} />}
                  id="input-demo"
                  placeholder="*************"
                  type="password"
                  size={'lg'}
                  value={newPasswordOne}
                  onChange={(e) => setNewPasswordOne(e.target.value)}
                  rightSection={
                    newPasswordOne && (
                      <img
                        style={{ cursor: 'pointer' }}
                        onClick={() => setNewPasswordOne('')}
                        width={22.55}
                        height={22.55}
                        src={Xis}
                      />
                    )
                  }
                />
              </Input.Wrapper>
              <Input.Wrapper id="input-demo" label="Confirmar senha">
                <Input
                  icon={<img src={Password} style={{ width: '22px' }} />}
                  id="input-demo"
                  placeholder="*************"
                  type="password"
                  size={'lg'}
                  value={newPasswordTwo}
                  onChange={(e) => setNewPasswordTwo(e.target.value)}
                  rightSection={
                    newPasswordTwo && (
                      <img
                        style={{ cursor: 'pointer' }}
                        onClick={() => setNewPasswordTwo('')}
                        width={22.55}
                        height={22.55}
                        src={Xis}
                      />
                    )
                  }
                />
              </Input.Wrapper>
            </Styled.PasswordsWrapper>
            <Styled.ButtonContainer>
              <Styled.SaveButton onClick={() => handleSetNewPassword()}>
                Avançar
              </Styled.SaveButton>
            </Styled.ButtonContainer>
          </Styled.FormWrapper>
        )}
      </Styled.Container>
    </Styled.Wrapper>
  )
}
