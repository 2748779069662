import * as Styled from './styles'
import { Navbar } from '../../components/Navbar'
import Footer from '../../components/Footer'
import { Card } from './components/Card'
import { fleets } from './fleets'
import { useTranslation } from 'react-i18next'
import { Carousel } from '@mantine/carousel'
import placeholder1 from './imgs/carousel/carro_01.webp'
import placeholder2 from './imgs/carousel/carro_02.webp'
import placeholder3 from './imgs/carousel/fleet2.jpg'
import placeholder4 from './imgs/carousel/carro_03.png'
import { useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import { Center } from '@mantine/core'
import { AlignCenter } from 'lucide-react'

export const Fleet = () => {
  const { t } = useTranslation()
  const autoplay = useRef(Autoplay({ delay: 3000 }));
  return (
    <Styled.Container>
      <Navbar />
      <Styled.ContentContainer>
        <Styled.CarouselContainer>
          <Carousel height={600} withIndicators withControls={false} 
            loop 
            plugins={[autoplay.current]}
            onMouseEnter={autoplay.current.stop}
            onMouseLeave={autoplay.current.reset}>
            <Carousel.Slide><Styled.CarouselImg src={placeholder1} height={600} style={{ maxWidth: '100%', objectFit: 'cover', objectPosition: '25% 110%' }} /></Carousel.Slide>
            <Carousel.Slide><Styled.CarouselImg src={placeholder2} height={600} style={{ maxWidth: '100%', objectFit: 'cover', objectPosition: '50% 100%' }} /></Carousel.Slide>
            <Carousel.Slide><Styled.CarouselImg src={placeholder3} height={600} style={{ maxWidth: '100%', objectFit: 'cover', objectPosition: '50% 100%' }} /></Carousel.Slide>
            <Carousel.Slide><Styled.CarouselImg src={placeholder4} height={600} style={{ maxWidth: '100%', height: '100%', objectFit: 'cover', objectPosition: '25% 70%' }} /></Carousel.Slide>
          </Carousel>
        </Styled.CarouselContainer>
        <Styled.Title>{t('fleet.exploreFleet')}</Styled.Title>
        <div>
          {fleets.map((fleets) => {
            const category = fleets[0].category
            return (
              <>
                <Styled.CategoryContainer>
                  <Styled.CategoryTextContainer>
                    <Styled.CategoryText>
                      {category}
                    </Styled.CategoryText>
                    <Styled.CategoryLine />
                  </Styled.CategoryTextContainer>
                  <Card fleets={fleets} />
                </Styled.CategoryContainer>
              </>
            )
          })}
        </div>
      </Styled.ContentContainer>
      <Footer />
    </Styled.Container>
  )
}
