import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { CouponInfo } from '../components/CouponInfo'
import { ChevronDown, Search } from 'lucide-react'
import { Input } from '@mantine/core'
import { DataTable } from '../components/DataTable'
import { toast } from 'react-toastify'
import debounce from '../../../../../utils/debounce'
import fetchCoupons from '../../../../../services/coupon'
import * as Styled from './styles'
import { CreateCouponModal } from '../components/CreateCouponModal'
import { generateEditableData } from './utils'

const COUPON_MOCK = {
  coupon: 'VAIDEEASY',
  discountPercentage: 24,
  validFrom: '2023-11-30T00:00:00',
  validTo: '2023-12-05T23:59:59',
  appliedTo: 'ALL',
  paymentMethods: ['ONLINE_ALL', 'CARD', 'MBWAY', 'MULTI', 'CASH'],
  useLimitOption: 'LIMITLESS',
  useAmount: 1
}

const TRANSFERS_MOCK = [
  {
    id: '078c327e-e018-4d5f-a665-e00d74446a3e',
    transferDate: '2023-10-22T10:30:00',
    totalPrice: 127
  },
  {
    id: '078c327e-e020-4d5f-a665-e00d74446a3e',
    transferDate: '2023-01-15T11:45:00',
    totalPrice: 611
  },
  {
    id: '078c327e-e021-4d5f-a665-e00d74446a3e',
    transferDate: '2023-09-05T23:50:00',
    totalPrice: 1065
  },
  {
    id: '078c327e-e022-4d5f-a665-e00d74446a3e',
    transferDate: '2023-11-21T09:45:00',
    totalPrice: 240
  }
]

export const CouponDetails = () => {
  const [search, setSearch] = useState('')
  const [loadingState, setLoadingState] = useState({
    loadingCoupon: true,
    removingCoupon: false,
    changingActive: false
  })
  const [transfers, setTransfers] = useState(TRANSFERS_MOCK)
  const [couponData, setCouponData] = useState()
  const [createCouponModal, setCreateCouponModal] = useState(false)

  const { id } = useParams()
  const navigate = useNavigate()

  const handleChange = (value) => {
    debounce(() => setSearch(value), 500)
  }

  const deleteCoupon = async () => {
    setLoadingState((oldState) => ({ ...oldState, removingCoupon: true }))
    const toastId = toast.loading('Removendo cupom...')

    try {
      await fetchCoupons.delete(id)
      toast.update(toastId, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: 'light',
        type: 'success',
        closeButton: true,
        isLoading: false,
        render: 'Cupom removido com sucesso!'
      })
      setLoadingState((oldState) => ({ ...oldState, removingCoupon: false }))
      navigate('/dashboard/coupons')
    } catch (err) {
      console.log(err)
      setLoadingState((oldState) => ({ ...oldState, removingCoupon: false }))
      toast.update(toastId, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: 'light',
        type: 'error',
        closeButton: true,
        isLoading: false,
        render: 'Erro ao remover cupom!'
      })
    }
  }

  useEffect(() => {
    ;(async () => {
      setLoadingState((oldState) => ({ ...oldState, loadingCoupon: true }))
      try {
        const { data } = await fetchCoupons.getById(id)
        if (data.deleted) navigate('/dashboard/coupons')
        setCouponData(data)
        setLoadingState((oldState) => ({ ...oldState, loadingCoupon: false }))
      } catch (err) {
        navigate('/dashboard/coupons')
        setLoadingState((oldState) => ({ ...oldState, loadingCoupon: false }))
      }
    })()
  }, [id, navigate])

  // quando getar os transfers, mudar o valor totalPrice para totalPrice / 100 no data

  return (
    <>
      {loadingState.loadingCoupon ? (
        <div
          style={{
            width: '100%',
            height: '40rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <ClipLoader size={50} color="#27c7ff" />
        </div>
      ) : (
        <>
          <Styled.Container>
            <Styled.Header>
              <Styled.Title>Cupom #{couponData.coupon}</Styled.Title>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}
              >
                <Styled.ConfirmButton
                  disabled={
                    loadingState.removingCoupon || loadingState.changingActive
                  }
                  outlined
                  onClick={deleteCoupon}
                >
                  EXCLUIR
                </Styled.ConfirmButton>
                <Styled.ConfirmButton
                  disabled={
                    loadingState.removingCoupon || loadingState.changingActive
                  }
                  onClick={() => setCreateCouponModal(true)}
                >
                  EDITAR
                </Styled.ConfirmButton>
              </div>
            </Styled.Header>
            <Styled.Content>
              <CouponInfo
                couponData={couponData}
                loadingState={loadingState}
                setLoadingState={setLoadingState}
              />
              <Styled.FiltersWrapper>
                <Input.Wrapper id="filters">
                  <Input
                    id="input-demo"
                    placeholder="Pesquisar ID"
                    size={'lg'}
                    rightSection={
                      <Search width={24} height={24} color="#BFBFBF" />
                    }
                    onChange={(e) => handleChange(e.target.value)}
                  />
                </Input.Wrapper>
                <div style={{ position: 'relative' }}>
                  <Input
                    id="select-filter"
                    component="button"
                    onClick={() => console.log('não sei')}
                    rightSection={
                      <ChevronDown
                        color="#27c7ff"
                        style={{
                          transition: 'transform 0.2s ease-in-out'
                        }}
                      />
                    }
                    pointer
                    mt="md"
                  >
                    Filtro
                  </Input>
                </div>
              </Styled.FiltersWrapper>
              <DataTable data={transfers} />
            </Styled.Content>
          </Styled.Container>
          {createCouponModal && (
            <CreateCouponModal
              onClose={() => setCreateCouponModal(false)}
              initialData={generateEditableData(couponData)}
              onSuccess={(newCouponData) => {
                setCouponData(newCouponData)
                setCreateCouponModal(false)
              }}
            />
          )}
        </>
      )}
    </>
  )
}
