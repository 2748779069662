import {
  ActionIcon,
  Checkbox,
  Flex,
  Group,
  Input,
  MediaQuery,
  NumberInput,
  Select
} from '@mantine/core'
import * as Styled from './styles'
import { ReactComponent as CarAvatar } from '../../imgs/car.svg'
import Childseat1 from './imgs/childseat1.webp'
import Childseat2 from './imgs/childseat2.webp'
import Childseat3 from './imgs/childseat3.webp'
import { useAuth } from '../../../../../../contexts/useAuth'
import fetchAccount from '../../../../../../services/account'
import { useEffect, useRef, useState } from 'react'
import { Car as CarIcon } from 'lucide-react'
import { Luggage } from 'lucide-react'
import { Briefcase } from 'lucide-react'
import { Backpack } from 'lucide-react'
import { AddPassenger } from './components/AddPassenger'
import { IMaskInput } from 'react-imask'
import fetchCarCategory from '../../../../../../services/carCategory'
import MinusBtn from './imgs/minus-btn.webp'
import PlusBtn from './imgs/plus-btn.webp'
export const Car = ({ data, setData }) => {
  const [categoryData, setCategoryData] = useState([])

  const handleInputChange = (field, value) => {
    setData((prevValues) => ({
      ...prevValues,
      vehicle: {
        ...prevValues.vehicle,
        [field]: value
      }
    }))
  }
  const internalPhotoInputRef = useRef(null)
  const externalPhotoInputRef = useRef(null)

  const handleInternalPhotoUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const photoData = e.target.result

        setData((prevData) => ({
          ...prevData,
          vehicle: {
            ...prevData.vehicle,
            internalPhoto: {
              title: file.name,
              fileName: file.name,
              fileExtension: file.type.split('/')[1],
              url: photoData,
              base64: photoData.split(',')[1]
            }
          }
        }))
      }
      reader.readAsDataURL(file)
    }
  }

  const handleExternalPhotoUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const photoData = e.target.result

        setData((prevData) => ({
          ...prevData,
          vehicle: {
            ...prevData.vehicle,
            externalPhoto: {
              title: file.name,
              fileName: file.name,
              fileExtension: file.type.split('/')[1],
              url: photoData,
              base64: photoData.split(',')[1]
            }
          }
        }))
      }
      reader.readAsDataURL(file)
    }
  }

  const handleInternalAvatarClick = () => {
    if (internalPhotoInputRef.current) {
      internalPhotoInputRef.current.click()
    }
  }

  const handleExternalAvatarClick = () => {
    if (externalPhotoInputRef.current) {
      externalPhotoInputRef.current.click()
    }
  }
  const avatarSize = 210

  const imgStyle = {
    width: avatarSize,
    height: avatarSize,
    borderRadius: '10px',
    cursor: 'pointer'
  }

  const yearsData = [
    '2023',
    '2022',
    '2021',
    '2020',
    '2019',
    '2018',
    '2017',
    '2016',
    '2015'
  ]
  // const categoryData = [
  //   {
  //     value: 'STANDARD',
  //     label: 'Padrão'
  //   },
  //   {
  //     value: 'ECONOMIC',
  //     label: 'Economico'
  //   },
  //   {
  //     value: 'EXECUTIVE',
  //     label: 'Executivo'
  //   }
  // ]

  useEffect(() => {
    fetchCarCategory.getAll().then((res) => {
      if (res.data) {
        const categoryData = res.data.content.map((item) => ({
          value: item.id,
          label: item.name
        }))
        setCategoryData(categoryData)
      }
    })
  }, [])

  return (
    <Styled.Container>
      <Styled.AvatarWrapper>
        {data?.vehicle?.internalPhoto?.url ? (
          <img
            onClick={handleInternalAvatarClick}
            src={data?.vehicle?.internalPhoto?.url}
            alt="input-foto-interior-do-carro"
            style={imgStyle}
          />
        ) : (
          <>
            <CarAvatar
              size={avatarSize}
              onClick={handleInternalAvatarClick}
              style={{ cursor: 'pointer' }}
            />
            <label
              style={{
                textAlign: 'center'
              }}
            >
              Interior
            </label>
          </>
        )}
        {data?.vehicle?.externalPhoto?.url ? (
          <>
            <img
              onClick={handleExternalAvatarClick}
              src={data?.vehicle?.externalPhoto?.url}
              alt="input-foto-exterior-do-carro"
              style={imgStyle}
            />
          </>
        ) : (
          <>
            <CarAvatar
              size={avatarSize}
              onClick={handleExternalAvatarClick}
              style={{ cursor: 'pointer' }}
            />
            <label
              style={{
                textAlign: 'center'
              }}
            >
              Exterior
            </label>
          </>
        )}
        <label>
          OBS: Envie uma foto que mostre a placa e a lateral do seu automóvel
        </label>
        <input
          type="file"
          accept="image/*"
          onChange={handleInternalPhotoUpload}
          style={{ display: 'none' }}
          ref={internalPhotoInputRef}
        />
        <input
          type="file"
          accept="image/*"
          onChange={handleExternalPhotoUpload}
          style={{ display: 'none' }}
          ref={externalPhotoInputRef}
        />
      </Styled.AvatarWrapper>
      <MediaQuery query="(max-width: 768px)">
        <Flex gap="lg" direction="column">
          <MediaQuery
            query="(max-width: 768px)"
            styles={{ flexDirection: 'column' }}
          >
            <Flex gap="md" justify="space-between">
              <Input.Wrapper label="Marca">
                <Input
                  icon={<CarIcon />}
                  onChange={(event) =>
                    handleInputChange('manufacturer', event.target.value)
                  }
                  value={data?.vehicle?.manufacturer}
                  placeholder="Insira a marca do seu automóvel"
                  size={'lg'}
                />
              </Input.Wrapper>
              <Input.Wrapper label="Modelo">
                <Input
                  icon={<CarIcon />}
                  onChange={(event) =>
                    handleInputChange('model', event.target.value)
                  }
                  value={data?.vehicle?.model}
                  placeholder="Insira o modelo do seu automóvel"
                  size={'lg'}
                />
              </Input.Wrapper>
              <Input.Wrapper label="Ano do carro">
                <Select
                  data={yearsData}
                  value={data?.vehicle?.manufacturingYear}
                  onChange={(value) =>
                    handleInputChange('manufacturingYear', value)
                  }
                  placeholder="Selecione o ano de fabricação"
                  size="lg"
                />
              </Input.Wrapper>
            </Flex>
          </MediaQuery>

          <MediaQuery
            query="(max-width: 768px)"
            styles={{ flexDirection: 'column' }}
          >
            <Flex gap="md" justify="space-between">
              <Input.Wrapper label="Placa">
                <Input
                  icon={<CarIcon />}
                  onChange={(event) =>
                    handleInputChange('licensePlate', event.target.value)
                  }
                  value={data?.vehicle?.licensePlate}
                  placeholder="Insira a placa do seu automóvel"
                  size={'lg'}
                />
              </Input.Wrapper>
              <Input.Wrapper label="Cor">
                <Input
                  icon={<CarIcon />}
                  placeholder="Insira a cor do seu automóvel"
                  onChange={(event) =>
                    handleInputChange('color', event.target.value)
                  }
                  value={data?.vehicle?.color}
                  size={'lg'}
                />
              </Input.Wrapper>

              <Input.Wrapper label="Categoria">
                <Select
                  icon={<CarIcon />}
                  data={categoryData}
                  onChange={(event) => {
                    setData((prevValues) => ({
                      ...prevValues,
                      vehicle: {
                        ...prevValues.vehicle,
                        category: {
                          id: event
                        }
                      }
                    }))
                  }}
                  value={data?.vehicle?.category?.id}
                  placeholder="Selecione a categoria"
                  size={'lg'}
                />
              </Input.Wrapper>
            </Flex>
          </MediaQuery>
          <MediaQuery
            query="(max-width: 768px)"
            styles={{ flexDirection: 'column' }}
          >
            <Flex gap="md" justify="space-between">
              <Input.Wrapper label="Término do seguro">
                <Input
                  icon={<CarIcon />}
                  component={IMaskInput}
                  mask="00/00/0000"
                  placeholder="Insira o término do seguro do seu automóvel"
                  onChange={(event) =>
                    handleInputChange('endOfInsurance', event.target.value)
                  }
                  value={data?.vehicle?.endOfInsurance}
                  size={'lg'}
                />
              </Input.Wrapper>

              <Input.Wrapper label="Viatura própria?">
                <Select
                  icon={<CarIcon />}
                  data={[
                    {
                      label: 'Sim',
                      value: true
                    },
                    {
                      label: 'Não',
                      value: false
                    }
                  ]}
                  onChange={(event) => handleInputChange('isOwner', event)}
                  value={data?.vehicle?.isOwner}
                  placeholder="Selecione se sua viatura é própria"
                  size={'lg'}
                />
              </Input.Wrapper>
            </Flex>
          </MediaQuery>
          <MediaQuery
            query="(max-width: 768px)"
            styles={{ flexDirection: 'column' }}
          >
            <Flex gap="md" justify="space-between">
              <Styled.OptionsContainer>
                <Styled.NumberContainer>
                  <AddPassenger
                    responsive
                    label="Quantidade de passageiros"
                    setValue={(value) => {
                      setData((prevData) => ({
                        ...prevData,
                        vehicle: {
                          ...prevData.vehicle,
                          passengerCapacity: value
                        }
                      }))
                    }}
                    value={data?.vehicle?.passengerCapacity}
                    name="backPack"
                    width="100%"
                  />
                </Styled.NumberContainer>
                <Styled.NumberContainer>
                  <AddPassenger
                    responsive
                    label="Bagagem Despachada"
                    setValue={(value) => {
                      setData((prevData) => ({
                        ...prevData,
                        vehicle: {
                          ...prevData.vehicle,
                          luggageCapacity: value
                        }
                      }))
                    }}
                    value={data?.vehicle?.luggageCapacity}
                    Icon={Briefcase}
                    name="briefcase"
                    width="100%"
                  />
                </Styled.NumberContainer>
                <Styled.NumberContainer>
                  <AddPassenger
                    responsive
                    label="Bagagem de mão"
                    setValue={(value) => {
                      setData((prevData) => ({
                        ...prevData,
                        vehicle: {
                          ...prevData.vehicle,
                          handLuggageCapacity: value
                        }
                      }))
                    }}
                    value={data?.vehicle?.handLuggageCapacity}
                    Icon={Luggage}
                    name="luggage"
                    width="100%"
                  />
                </Styled.NumberContainer>
                <Styled.NumberContainer>
                  <AddPassenger
                    responsive
                    label="Mochila"
                    setValue={(value) => {
                      setData((prevData) => ({
                        ...prevData,
                        vehicle: {
                          ...prevData.vehicle,
                          backpackCapacity: value
                        }
                      }))
                    }}
                    value={data?.vehicle?.backpackCapacity}
                    Icon={Backpack}
                    name="backPack"
                    width="100%"
                  />
                </Styled.NumberContainer>
              </Styled.OptionsContainer>
            </Flex>
          </MediaQuery>
          <MediaQuery
            query="(max-width: 768px)"
            styles={{ flexDirection: 'column' }}
          >
            <Styled.SurfAndAnimalsWrapper>
              <Styled.CheckboxOptionsContainer>
                <Checkbox
                  size="lg"
                  checked={data?.surf || data?.vehicle?.surfboardCapacity > 0}
                  onChange={() =>
                    setData((prevData) => ({
                      ...prevData,
                      surf: !prevData.surf
                    }))
                  }
                  label="Surfboard"
                />
                {(data?.surf || data?.vehicle?.surfboardCapacity > 0) && (
                  <Styled.NumberContainer>
                    <AddPassenger
                      setValue={(value) => {
                        setData((prevData) => ({
                          ...prevData,
                          vehicle: {
                            ...prevData.vehicle,
                            surfboardCapacity: value
                          }
                        }))
                      }}
                      value={data?.vehicle?.surfboardCapacity}
                      width="149.3px"
                    />
                  </Styled.NumberContainer>
                )}
              </Styled.CheckboxOptionsContainer>

              <Styled.CheckboxOptionsContainer>
                <Checkbox
                  size="lg"
                  checked={data?.animals || data?.vehicle?.animalsCapacity > 0}
                  onChange={() =>
                    setData((prevData) => ({
                      ...prevData,
                      animals: !prevData.animals
                    }))
                  }
                  label="Animais"
                />
                {(data?.animals || data?.vehicle?.animalsCapacity > 0) && (
                  <Styled.NumberContainer>
                    <AddPassenger
                      setValue={(value) => {
                        setData((prevData) => ({
                          ...prevData,
                          vehicle: {
                            ...prevData.vehicle,
                            animalsCapacity: value
                          }
                        }))
                      }}
                      value={data?.vehicle?.animalsCapacity}
                      width="149.3px"
                    />
                  </Styled.NumberContainer>
                )}
              </Styled.CheckboxOptionsContainer>
              <Styled.CheckboxAditionalWrapper>
                <label>Ar Condicionado</label>
                <Checkbox
                  size="lg"
                  checked={data?.vehicle?.providesAirConditioning}
                  onChange={() =>
                    setData((prevData) => ({
                      ...prevData,
                      vehicle: {
                        ...prevData.vehicle,
                        providesAirConditioning:
                          !prevData.vehicle?.providesAirConditioning
                      }
                    }))
                  }
                  label="Sim"
                />
              </Styled.CheckboxAditionalWrapper>
              <Styled.CheckboxAditionalWrapper>
                <label>Wifi</label>
                <Checkbox
                  size="lg"
                  checked={data?.vehicle?.providesInternet}
                  onChange={() =>
                    setData((prevData) => ({
                      ...prevData,
                      vehicle: {
                        ...prevData.vehicle,
                        providesInternet: !prevData.vehicle?.providesInternet
                      }
                    }))
                  }
                  label="Sim"
                />
              </Styled.CheckboxAditionalWrapper>
            </Styled.SurfAndAnimalsWrapper>
          </MediaQuery>
          <Styled.CheckboxOptionsContainer>
            <Checkbox
              size="lg"
              checked={
                data?.childseat ||
                data?.vehicle?.babyseatCapacity > 0 ||
                data?.vehicle?.childseatCapacity > 0 ||
                data?.vehicle?.boosterseatCapacity > 0
              }
              onChange={() =>
                setData((prevData) => ({
                  ...prevData,
                  childseat: !prevData.childseat
                }))
              }
              label="Cadeira de criança"
            />
            {(data?.childseat ||
              data?.vehicle?.babyseatCapacity > 0 ||
              data?.vehicle?.childseatCapacity > 0 ||
              data?.vehicle?.boosterseatCapacity > 0) && (
              <Styled.ChildCardsWrapper>
                <Styled.ChildCardContainer>
                  <Checkbox default />
                  <Styled.ChildCard>
                    <Styled.ChildCardImg src={Childseat1} />
                    <Styled.ChildCardText>
                      <h1>Cadeira de bebê</h1>
                      <span>0 months - 12 months</span>
                      <Styled.NumberSmallContainer>
                        <Group
                          spacing={5}
                          style={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            position: 'relative'
                          }}
                        >
                          <ActionIcon
                            size={60}
                            className="btn-minus"
                            variant="transparent"
                            style={{
                              background: 'transparent',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              border: 'none'
                            }}
                            onClick={() =>
                              setData((prevData) => ({
                                ...prevData,
                                vehicle: {
                                  ...prevData.vehicle,
                                  babyseatCapacity:
                                    prevData.vehicle.babyseatCapacity - 1
                                }
                              }))
                            }
                          >
                            <img src={MinusBtn} />
                          </ActionIcon>

                          <NumberInput
                            hideControls
                            value={data?.vehicle?.babyseatCapacity}
                            max={10}
                            min={0}
                            step={1}
                            styles={{
                              input: { width: '100%', textAlign: 'center' }
                            }}
                            style={{ width: '100%' }}
                          />

                          <ActionIcon
                            size={60}
                            className="btn-plus"
                            variant="transparent"
                            style={{
                              background: 'transparent',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              border: 'none'
                            }}
                            onClick={() =>
                              setData((prevData) => ({
                                ...prevData,
                                vehicle: {
                                  ...prevData.vehicle,
                                  babyseatCapacity:
                                    prevData.vehicle.babyseatCapacity + 1
                                }
                              }))
                            }
                          >
                            <img src={PlusBtn} />
                          </ActionIcon>
                        </Group>
                      </Styled.NumberSmallContainer>
                    </Styled.ChildCardText>
                  </Styled.ChildCard>
                </Styled.ChildCardContainer>
                <Styled.ChildCardContainer>
                  <Checkbox />
                  <Styled.ChildCard>
                    <Styled.ChildCardImg src={Childseat2} />
                    <Styled.ChildCardText>
                      <h1>Cadeira de criança</h1>
                      <span>1 year - 4 years</span>
                      <Styled.NumberSmallContainer>
                        <Group
                          spacing={5}
                          style={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            position: 'relative'
                          }}
                        >
                          <ActionIcon
                            size={60}
                            className="btn-minus"
                            variant="transparent"
                            style={{
                              background: 'transparent',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              border: 'none'
                            }}
                            onClick={() =>
                              setData((prevData) => ({
                                ...prevData,
                                vehicle: {
                                  ...prevData.vehicle,
                                  childseatCapacity:
                                    prevData.vehicle.childseatCapacity - 1
                                }
                              }))
                            }
                          >
                            <img src={MinusBtn} />
                          </ActionIcon>

                          <NumberInput
                            hideControls
                            value={data?.vehicle?.childseatCapacity}
                            max={10}
                            min={0}
                            step={1}
                            styles={{
                              input: { width: '100%', textAlign: 'center' }
                            }}
                            style={{ width: '100%' }}
                          />

                          <ActionIcon
                            size={60}
                            className="btn-plus"
                            variant="transparent"
                            style={{
                              background: 'transparent',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              border: 'none'
                            }}
                            onClick={() =>
                              setData((prevData) => ({
                                ...prevData,
                                vehicle: {
                                  ...prevData.vehicle,
                                  childseatCapacity:
                                    prevData.vehicle.childseatCapacity + 1
                                }
                              }))
                            }
                          >
                            <img src={PlusBtn} />
                          </ActionIcon>
                        </Group>
                      </Styled.NumberSmallContainer>
                    </Styled.ChildCardText>
                  </Styled.ChildCard>
                </Styled.ChildCardContainer>
                <Styled.ChildCardContainer>
                  <Checkbox />
                  <Styled.ChildCard>
                    <Styled.ChildCardImg src={Childseat3} />
                    <Styled.ChildCardText>
                      <h1>Banco elevatório</h1>
                      <span>4 years - 12 years</span>
                      <Styled.NumberSmallContainer>
                        <Group
                          spacing={5}
                          style={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            position: 'relative'
                          }}
                        >
                          <ActionIcon
                            size={60}
                            className="btn-minus"
                            variant="transparent"
                            style={{
                              background: 'transparent',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              border: 'none'
                            }}
                            onClick={() =>
                              setData((prevData) => ({
                                ...prevData,
                                vehicle: {
                                  ...prevData.vehicle,
                                  boosterseatCapacity:
                                    prevData.vehicle.boosterseatCapacity + 1
                                }
                              }))
                            }
                          >
                            <img src={MinusBtn} />
                          </ActionIcon>

                          <NumberInput
                            hideControls
                            value={data?.vehicle?.boosterseatCapacity}
                            max={10}
                            min={0}
                            step={1}
                            styles={{
                              input: { width: '100%', textAlign: 'center' }
                            }}
                            style={{ width: '100%' }}
                          />

                          <ActionIcon
                            size={60}
                            className="btn-plus"
                            variant="transparent"
                            style={{
                              background: 'transparent',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              border: 'none'
                            }}
                            onClick={() =>
                              setData((prevData) => ({
                                ...prevData,
                                vehicle: {
                                  ...prevData.vehicle,
                                  boosterseatCapacity:
                                    prevData.vehicle.boosterseatCapacity + 1
                                }
                              }))
                            }
                          >
                            <img src={PlusBtn} />
                          </ActionIcon>
                        </Group>
                      </Styled.NumberSmallContainer>
                    </Styled.ChildCardText>
                  </Styled.ChildCard>
                </Styled.ChildCardContainer>
              </Styled.ChildCardsWrapper>
            )}
          </Styled.CheckboxOptionsContainer>
        </Flex>
      </MediaQuery>
    </Styled.Container>
  )
}
