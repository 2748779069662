import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  width: 100%;
  align-items: flex-start;
`
export const Title = styled.h1`
  font-family: Neometric;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.01em;
  text-align: left;
`
export const ConfirmButton = styled.button`
  display: flex;
  width: 220px;
  height: 48px;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background: #27c7ff;
  color: var(--white-grayscale, #fff);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;
  text-transform: uppercase;
  border: 2px solid var(--yellow-brand-primary, #27c7ff);
`
export const EditButton = styled.button`
  display: flex;
  width: 220px;
  height: 48px;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  border: 2px solid var(--yellow-brand-primary, #27c7ff);
  color: var(--yellow-brand-primary, #27c7ff);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;
  text-transform: uppercase;
  background-color: transparent;
`
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
`
