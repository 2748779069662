import axios from 'axios'

const api = axios.create({
  baseURL: 'https://strapi.easytransferdrivers.site:444/api/'
})

const unauthorizedEndpoints = ['/account/authenticate', '/hostel/open']

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('@token')

  // if (
  //   token &&
  //   !unauthorizedEndpoints.some((endpoint) => config.url.includes(endpoint))
  // ) {
  config.headers.Authorization = `Bearer 6b23cbc23125c00db7bcc67dcb7c38c10bc58ba09a3959e63db28c0e39d01121c2dae637ff3f282fb9114d59ac218b15089337dc5cdfe67d4e809a101c9808fe3ae464cc457935cce8d55066a034f8afbd3cf4a105a4d0a643ad7f76c5e42431f741fcc19e82577dd7233958002b285428f9b0ec35616a0d0058058ac0cefc2a`
  // }

  return config
})

api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      if (error.response.data.error === 'Unauthorized') {
        localStorage.removeItem('@token')
        localStorage.removeItem('@user')
        localStorage.removeItem('@role')
        window.location.pathname = '/'
      }
    }

    return Promise.reject(error)
  }
)

export default api
