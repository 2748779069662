import {
  Button,
  Divider,
  Flex,
  LoadingOverlay,
  MediaQuery
  // , Pagination
} from '@mantine/core'
import * as Styled from './styles'
import { ReactComponent as Direction } from './imgs/direction.svg'
import { ReactComponent as Pin } from './imgs/mapPin.svg'
import { ReactComponent as Car } from './imgs/car.svg'
import { ReactComponent as Surf } from './imgs/icon_surfboard- blue branding.svg'
import { ReactComponent as Pet } from './imgs/pet.svg'
import { ReactComponent as ChildChair } from './imgs/child-chair.svg'
import Chevron from './imgs/right.webp'
import { useEffect } from 'react'
import fetchBooking from '../../../../../../services/booking'
import { useState } from 'react'
import { RejectModal } from '../RejectModal'
import { useAuth } from '../../../../../../contexts/useAuth'
import { useClipboard } from '@mantine/hooks'
import {
  ArrowUpRight,
  Backpack,
  Briefcase,
  Luggage,
  Map,
  Plane,
  Users2
} from 'lucide-react'

import {
  formatDayOfWeek,
  formatMonth,
  formatTime
} from '../../../../../../utils/formattedDate'

export const DetailsTransfer = ({ transfer }) => {
  const [acceptedModal, setAcceptedModal] = useState(false)
  const [rejectModal, setRejectModal] = useState(false)
  const [transferContent, setTransferContent] = useState()
  const [refreshBookings, setRefreshBookings] = useState(false)

  const clipboard = useClipboard({ timeout: 500 })

  const { user } = useAuth()

  const handleRejectTransfer = (value) => {
    const payload = {
      id: value.id,
      driver: {
        id: user.id
      }
    }

    if (user.id) {
      fetchBooking
        .updateBooking(payload)
        .then((res) => {
          console.log(res)
          setRejectModal(false)
          setRefreshBookings(!refreshBookings)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  return transfer ? (
    <>
      <LoadingOverlay overlayOpacity={0.5} visible={false} />

      <Styled.CardContainer>
        <Styled.Container key={transfer.id}>
          <Styled.Wrapper>
            <Styled.InfoWrapper>
              <Styled.TransferTitle>
                <Car />
                Detalhes do transfer
              </Styled.TransferTitle>
            </Styled.InfoWrapper>
            <Styled.TimeAndStatusWrapper>
              <Styled.StatusCard>
                {formatDayOfWeek(transfer.transferDate)},{' '}
                {new Date(transfer.transferDate)
                  .getDate()
                  .toString()
                  .padStart(2, '0')}
                /{formatMonth(transfer.transferDate)} -{' '}
                {formatTime(transfer.transferDate)}
              </Styled.StatusCard>

              <Styled.Id>
                <strong>ID:</strong> {transfer.id}
              </Styled.Id>
            </Styled.TimeAndStatusWrapper>

            <Styled.ValuesWrapper>
              <MediaQuery
                query="(max-width: 1024px)"
                styles={{ display: 'none' }}
              >
                <Styled.PriceTitle>
                  <Styled.DepartureTitle>Distância</Styled.DepartureTitle>
                  {transfer.distanceInKm}km
                </Styled.PriceTitle>
              </MediaQuery>
            </Styled.ValuesWrapper>
            <Styled.InfoWrapper>
              <Styled.AddressWrapper>
                <Styled.DepartureWrapper realized={transfer.status === 'DONE'}>
                  <Styled.DepartureTitle>
                    <Pin /> Partida
                  </Styled.DepartureTitle>
                  <Styled.DepartureDescription>
                    {transfer.startingPlace}
                  </Styled.DepartureDescription>
                </Styled.DepartureWrapper>
                <Styled.DirectionButton>
                  <Direction />
                </Styled.DirectionButton>
                <Styled.DepartureWrapper realized={transfer.status === 'DONE'}>
                  <Styled.DepartureTitle>
                    <Pin /> Chegada
                  </Styled.DepartureTitle>
                  <Styled.DepartureDescription>
                    {transfer.arrivalPlace}
                  </Styled.DepartureDescription>
                </Styled.DepartureWrapper>
              </Styled.AddressWrapper>
              {transfer?.bookingStops.map((item) => (
                <Styled.DepartureWrapper key={item.id} stops>
                  <Styled.DepartureTitle>
                    <Pin /> Parada
                  </Styled.DepartureTitle>
                  <Styled.DepartureDescription>
                    {item.place}
                  </Styled.DepartureDescription>
                </Styled.DepartureWrapper>
              ))}
              {transfer?.passengerStops.map((item) => (
                <Styled.DepartureWrapper key={item.id} stops>
                  <Styled.DepartureTitle>
                    <Pin /> Parada
                  </Styled.DepartureTitle>
                  <Styled.DepartureDescription>
                    {item.place}
                  </Styled.DepartureDescription>
                </Styled.DepartureWrapper>
              ))}

              {/* <Styled.FlighstatBackground>
                <Styled.FlighstatWrapper>
                  <Styled.FlighstatContent>
                    <Plane width={32} height={32} color="#404040" />
                    <Styled.FlighstatContentWrapper>
                      <Styled.FlighstatTitle>
                        NÚMERO DO VOO
                      </Styled.FlighstatTitle>
                      <Styled.FlighstatDescription>
                        3295
                      </Styled.FlighstatDescription>
                    </Styled.FlighstatContentWrapper>
                  </Styled.FlighstatContent>
                  <Styled.FlighstatContent>
                    <Styled.FlighstatContentWrapper>
                      <Styled.FlighstatTitle>
                        ESTIMATIVA DE CHEGADA
                      </Styled.FlighstatTitle>
                      <Styled.FlighstatDescription>
                        21:30
                      </Styled.FlighstatDescription>
                    </Styled.FlighstatContentWrapper>
                  </Styled.FlighstatContent>
                  <Styled.FlighstatContent>
                    <Styled.FlighstatContentWrapper>
                      <Styled.FlighstatTitle>TERMINAL</Styled.FlighstatTitle>
                      <Styled.FlighstatDescription>
                        1
                      </Styled.FlighstatDescription>
                    </Styled.FlighstatContentWrapper>
                  </Styled.FlighstatContent>
                  <Styled.FlighstatContent>
                    <Styled.FlighstatContentWrapper>
                      <Styled.FlighstatTitle>PORTÃO</Styled.FlighstatTitle>
                      <Styled.FlighstatDescription>
                        4
                      </Styled.FlighstatDescription>
                    </Styled.FlighstatContentWrapper>
                  </Styled.FlighstatContent>
                </Styled.FlighstatWrapper>
              </Styled.FlighstatBackground> */}

              <MediaQuery
                query="(max-width: 1024px)"
                styles={{ display: 'none' }}
              >
                <Styled.DistanceMobile realized={transfer.status === 'DONE'}>
                  <a
                    href={`https://www.waze.com/ul?from=${transfer.startingLatitude},${transfer.startingLongitude}&navigate=yes&to=${transfer.arrivalLatitude},${transfer.arrivalLongitude}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      if (transfer.status === 'DONE') {
                        e.preventDefault()
                      }
                    }}
                  >
                    <Map color="#ffff" size={24} /> Abrir Waze
                    <ArrowUpRight color="#ffff" size={24} />
                  </a>
                </Styled.DistanceMobile>
              </MediaQuery>

              <Styled.IconsAndNumbersWrapper>
                {transfer.backpacks > 0 && (
                  <Styled.IconAndNumber>
                    <Styled.IconsBackground
                      realized={transfer.status === 'DONE'}
                    >
                      <Backpack
                        color={
                          transfer.status === 'DONE' ? '#808080' : '#27c7ff'
                        }
                        size={24}
                      />
                    </Styled.IconsBackground>
                    <span>
                      <strong>{transfer.backpacks}</strong> Mochila
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer.checkedLuggages > 0 && (
                  <Styled.IconAndNumber>
                    <Styled.IconsBackground
                      realized={transfer.status === 'DONE'}
                    >
                      <Luggage
                        color={
                          transfer.status === 'DONE' ? '#808080' : '#27c7ff'
                        }
                        size={24}
                      />
                    </Styled.IconsBackground>
                    <span>
                      <strong>{transfer.checkedLuggages}</strong> Bagagem
                      despachada
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer.handLuggages > 0 && (
                  <Styled.IconAndNumber>
                    <Styled.IconsBackground
                      realized={transfer.status === 'DONE'}
                    >
                      <Briefcase
                        color={
                          transfer.status === 'DONE' ? '#808080' : '#27c7ff'
                        }
                        size={24}
                      />
                    </Styled.IconsBackground>
                    <span>
                      <strong>{transfer.handLuggages}</strong> Bagagem de mão
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer.numberOfPassengers > 0 && (
                  <Styled.IconAndNumber>
                    <Styled.IconsBackground
                      realized={transfer.status === 'DONE'}
                    >
                      <Users2
                        size={24}
                        color={
                          transfer.status === 'DONE' ? '#808080' : '#27c7ff'
                        }
                      />
                    </Styled.IconsBackground>
                    <span>
                      <strong>{transfer.numberOfPassengers} </strong>
                      Passageiro(s)
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer.surfboards > 0 && (
                  <Styled.IconAndNumber>
                    <Surf />
                    <span>
                      <strong>{transfer.surfboards} </strong>Prancha(s) de surf
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer.babySeats > 0 && (
                  <Styled.IconAndNumber>
                    <ChildChair />
                    <span>
                      <strong>{transfer.babySeats} </strong>Cadeira de bebê
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer.childSeats > 0 && (
                  <Styled.IconAndNumber>
                    <ChildChair />
                    <span>
                      <strong>{transfer.childSeats} </strong>Cadeira de bebê
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer.boosterSeats > 0 && (
                  <Styled.IconAndNumber>
                    <ChildChair />
                    <span>
                      <strong>{transfer.boosterSeats} </strong>Cadeira de bebê
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer.animals > 0 && (
                  <Styled.IconAndNumber>
                    <Pet />
                    <span>
                      <strong>{transfer.animals}</strong> Pet
                    </span>
                  </Styled.IconAndNumber>
                )}
              </Styled.IconsAndNumbersWrapper>
            </Styled.InfoWrapper>
          </Styled.Wrapper>
        </Styled.Container>

        <RejectModal
          opened={rejectModal}
          onClose={() => setRejectModal(false)}
        />
      </Styled.CardContainer>
    </>
  ) : (
    <div></div>
  )
}
