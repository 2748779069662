import * as Styled from './styles'

import safe from './imgs/safe.webp'
import tour from './imgs/tour.webp'
import complaint from './imgs/complaint-book.webp'

export const ComplaintsBookLink = () => {
  return (
    <Styled.Wrapper>
      <img src={safe} />
      <img src={tour} />
      <span>
        RNVAT
        <br />
        8510
      </span>
      <img src={complaint} />
    </Styled.Wrapper>
  )
}
