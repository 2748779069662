import styled from 'styled-components'

export const Container = styled.div`
  padding-bottom: 200px;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    padding-bottom: 64px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  width: 70%;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0px 32px;
  }
`

export const Button = styled.a`
  text-decoration: none;
  display: flex;
  height: 56px;
  padding: 16px 24px 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border-radius: 16px;
  background: var(--white-grayscale, #fff);
  box-shadow: 0px 18.048px 19.689px 0px rgba(0, 0, 0, 0.05),
    0px 4px 8px 0px rgba(0, 0, 0, 0.1);

  color: var(--yellow-brand-primary, #27c7ff);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;
  text-transform: uppercase;

  transition: ease-in-out 300ms;

  :hover {
    background-color: #27c7ff;
    color: white;
    border: solid 1px #fff;
  }

  @media (max-width: 1150px) {
    width: 100%;
    margin: 0 16px;
    display: flex;
    height: 48px;
    padding: 16px 10.991px 16px 14.655px;
    justify-content: center;
    align-items: center;
    gap: 7.328px;
    align-self: stretch;

    border-radius: 8px;
    background: var(--white-grayscale, #fff);
    box-shadow: 0px 8.266px 9.017px 0px rgba(0, 0, 0, 0.05),
      0px 1.832px 3.664px 0px rgba(0, 0, 0, 0.1);
  }
`

export const Header = styled.div`
  background: #27c7ff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: clamp(16px, 0.833vw, 0.833vw);
  gap: clamp(24px, 1.25vw, 1.25vw);
  border-top-left-radius: clamp(16px, 0.833vw, 0.833vw);
  border-top-right-radius: clamp(16px, 0.833vw, 0.833vw);

  h4 {
    color: var(--black-brand-primary, #222);
    text-align: center;
    font-family: Neometric;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`

export const Body = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: clamp(24px, 1.25vw, 1.25vw);
  gap: clamp(48px, 1.25vw, 1.25vw);

  background: #DFF7FF;
  border-bottom-left-radius: clamp(16px, 0.833vw, 0.833vw);
  border-bottom-right-radius: clamp(16px, 0.833vw, 0.833vw);

  span {
    color: var(--yellow-brand-primary, #27c7ff);
    font-family: Neometric;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }

  @media (max-width: 768px) {
    span {
      font-size: 24px;
    }
  }

  div {
    display: flex;
    flex-wrap: wrap;
    gap: clamp(16px, 0.833vw, 0.833vw);
    align-items: center;

    div {
      all: unset;
      flex-grow: 1;
      display: flex;
      flex-wrap: no-wrap;
      background: white;
      border: 1px solid #808080;
      border-radius: clamp(8px, 0.42vw, 0.42vw);
      padding: 0 clamp(8px, 0.833vw, 0.833vw);
      gap: clamp(8px, 0.833vw, 0.833vw);

      img {
        width: clamp(16px, 1.25vw, 1.25vw);
      }

      input {
        all: unset;
        /* padding: clamp(12px, 0.833vw, 0.833vw) 0; */
        font-size: clamp(12px, 0.833vw, 0.833vw);
        height: 5.6rem;
      }

      @media (max-width: 1150px) {
        width: 100%;
        margin: 0 16px;
      }
    }

    button {
      border-radius: 8px;

      @media (max-width: 1150px) {
        width: 100%;
      }
    }
  }
`
export const BagPicture = styled.img`
  width: clamp(240px, 12.5vw, 12.5vw);
  position: absolute;
  bottom: -3%;
  left: -8%;

  @media (max-width: 1160px) {
    width: 18rem;
    left: -16%;
  }
`
export const PlanePicture = styled.img`
  width: clamp(240px, 15vw, 15vw);
  position: absolute;
  top: 10%;
  right: -8%;

  @media (max-width: 1160px) {
    width: 18rem;
    right: -16%;
  }
`
