import styled from 'styled-components'

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 17.3525px;

  max-width: 402.71px;
  width: 100%;
  height: 339px;

  background: #ffffff;
  box-shadow: 0px -0.723022px 11.5683px rgba(0, 0, 0, 0.05),
    0px 15.9065px 17.3525px rgba(0, 0, 0, 0.07);
  border-radius: 24px;

  h1 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 28px;

    color: #38280e;
  }

  div {
    margin: 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      text-align: center;

      color: #a0a0a0;

      &:first-child {
        font-weight: 500;
        color: #38280e;
      }
    }
  }

  img {
    position: relative;
    left: -26px;
    width: 420px;
    height: 292px;

    box-shadow: 0px 31.0899px 44.1043px -17.3525px rgba(15, 22, 30, 0.02);
    filter: drop-shadow(0px 1.44604px 13.7374px rgba(15, 22, 30, 0.04));
    border-radius: 8.67626px;
  }
`
