import api from './api'

const fetchObj = {
  create: (payload) => {
    const res = api.post('/file', payload)

    return res
  },

  update: (payload) => {
    const res = api.put('/file', payload)

    return res
  },

  changeStatus: (id, status) => {
    const res = api.patch(`/file/change-status/${id}/${status}`)

    return res
  },
  delete: (id) => {
    const res = api.delete(`/file/${id}`)

    return res
  },
  getById: (id) => {
    const res = api.get(`/file/${id}`)

    return res
  }
}

export default fetchObj
