import { ChevronLeft, ChevronRight } from 'lucide-react'
import * as React from 'react'
import { DayPicker } from 'react-day-picker'

import { buttonVariants } from '../Button'
import { cn } from '../../utils/cn'

import styles from './styles.module.css'

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  darkMode,
  ...props
}) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn(styles.padding, className)}
      classNames={{
        months: styles.months,
        month: styles.month,
        caption: styles.caption,
        caption_label: styles.captionLabel,
        nav: styles.nav,
        nav_button: cn(
          buttonVariants({ variant: 'outline' }),
          darkMode ? styles.navButtonDark : styles.navButton
        ),
        nav_button_previous: styles.navButtonPrevious,
        nav_button_next: styles.navButtonNext,
        table: styles.table,
        head_row: styles.headRow,
        head_cell: styles.headCell,
        row: styles.row,
        cell: styles.cell,
        day: cn(
          buttonVariants({ variant: 'ghost' }),
          darkMode ? styles.dayDark : styles.day
        ),
        day_range_end: 'day-range-end',
        day_selected: darkMode ? styles.daySelectedDark : styles.daySelected,
        day_today: darkMode ? styles.dayTodayDark : styles.dayToday,
        day_outside: styles.dayOutside,
        day_disabled: styles.dayDisabled,
        day_range_middle: styles.dayRangeMiddle,
        day_hidden: styles.dayHidden,
        ...classNames
      }}
      components={{
        IconLeft: () => <ChevronLeft className={styles.icon} />,
        IconRight: () => <ChevronRight className={styles.icon} />
      }}
      {...props}
    />
  )
}
Calendar.displayName = 'Calendar'

export { Calendar }
