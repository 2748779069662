import { ColorInput, Flex, Input } from '@mantine/core'
import * as Styled from './styles'

export const Customization = ({ data, setData, disabled }) => {
  const handleInputChange = (field, value) => {
    setData((prevValues) => ({
      ...prevValues,
      [field]: value
    }))
  }

  return (
    <Styled.Container>
      <Flex gap="lg" direction="column">
        <Input.Wrapper label="Cor primária">
          <ColorInput
            onChange={(event) => handleInputChange('primaryColor', event)}
            value={data?.primaryColor}
            placeholder="Insira a cor primária"
            size={'lg'}
            disabled={disabled}
          />
        </Input.Wrapper>
        <Input.Wrapper label="Cor secundaria">
          <ColorInput
            onChange={(event) => handleInputChange('secundaryColor', event)}
            value={data?.secundaryColor}
            placeholder="Insira a cor secundaria"
            size={'lg'}
            disabled={disabled}
          />
        </Input.Wrapper>
      </Flex>
    </Styled.Container>
  )
}
