import * as Styled from './styles'
import { Steppers } from './components/Steppers'
import { StripeContainer } from './components/StripeContainer'
import { RightContainer } from './RightContainer'
import Footer from '../../components/Footer'
import { NavbarCheckout } from '../../components/NavbarCheckout'
import { ModalConfirmTransfer } from '../../components/ModalConfirmTransfer'
import { InPortal } from 'react-reverse-portal'
import { useModal } from '../../hooks/useModal'
import { useTransferContext } from '../../contexts/TransferContext'
export const Checkout3 = () => {
  const { modalPortalNode, openedModals, closeModal } = useModal()
  const { tour, setTour } = useTransferContext()

  return (
    <>
      <div style={{ maxWidth: 988, margin: '0 auto' }}>
        <NavbarCheckout />
      </div>
      <Styled.Container>
        <Steppers stepper={2} />
        <Styled.ContentContainer>
          <Styled.MiddleContainerRow>
            <StripeContainer />
            <RightContainer showCouponOnly />
          </Styled.MiddleContainerRow>
        </Styled.ContentContainer>
      </Styled.Container>
      <Footer />
      {openedModals.includes('ModalConfirmTransfer-tour') && (
        <ModalConfirmTransfer
          onClose={() => console.log()}
          data={tour}
          setData={setTour}
        />
      )}
    </>
  )
}
