import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: #DFF7FF;
  padding: 1.6rem 2.4rem;
  width: 100%;
  border-radius: 0.8rem;
  min-height: 18rem;

  display: flex;
  justify-content: space-between;

  > div:last-of-type {
    .mantine-Switch-label {
      line-height: normal;
      color: #222222;
      font-family: Neometric;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.18px;
    }
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  gap: 3.2rem;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > div {
      > strong {
        color: #1D95BF;
        font-family: Neometric;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: 120%; /* 20.8px */
        letter-spacing: 0.16px;
        display: block;
        margin-bottom: 0.8rem;
      }

      > p {
        color: #404040;
        font-family: Neometric;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 20.8px */
        letter-spacing: 0.16px;
      }

      > span {
        color: #808080;
        font-family: Neometric;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.14px;
        display: block;
        margin-top: 0.8rem;
      }

      > div {
        display: flex;
        gap: 1.2rem;
        flex-wrap: wrap;
        max-width: 45rem;

        span {
          cursor: default;
          padding: 0.4rem 1.2rem;
          border-radius: 3.2rem;
          border: 1px solid #27c7ff;

          background-color: #27c7ff;
          color: #ffffff;
          font-family: Neometric;
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          display: flex;
          align-items: center;
        }
      }
    }
  }
`
