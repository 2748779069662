import { useTranslation } from 'react-i18next'
import * as Styled from './styles'
import CheckedCircle from './imgs/check-circle.webp'
import { useTransferContext } from '../../../../contexts/TransferContext'
import { XCircle } from 'lucide-react'

export const Checked = ({ success }) => {
  const { t } = useTranslation()

  const { going } = useTransferContext()

  return (
    <Styled.Container>
      {success ? (
        <>
          <img src={CheckedCircle} alt="Checked Circle" />
          <Styled.ConfirmationMessage>
            {t('successMessage')}
          </Styled.ConfirmationMessage>
          <Styled.ImportantContainer>
            <Styled.OrangeTag>
              <span>{t('importantText')}</span>
            </Styled.OrangeTag>
            <div>
              <p>
                {t('thankYou')} <span>{t('summaryMessage')}</span>{' '}
                {t('availabilityMessage')}
              </p>
              <p>{t('tipMessage')}</p>
            </div>
          </Styled.ImportantContainer>
        </>
      ) : (
        <>
          <XCircle width={126} height={126} color="red" />
          <Styled.ConfirmationMessage>
            Pagamento não realizado
          </Styled.ConfirmationMessage>
          <Styled.ImportantContainer>
            <Styled.OrangeTag>
              <span>{t('importantText')}</span>
            </Styled.OrangeTag>
            <div>
              <p>
                Pagamento não realizado, por favor entre em contato com a
                administração, ou tente realizar o pagamento novamente pelo link
                enviado no seu e-mail
              </p>
              <p>
                <span>+351 963 650 278</span>
              </p>
            </div>
          </Styled.ImportantContainer>
        </>
      )}
    </Styled.Container>
  )
}
