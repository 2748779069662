import axios from 'axios'

export const token =
  'cb1f1317dd3eafc8a3c84660b2812ca147a4fd465afe19b1f1b7f91e5aaa2f373d592dc7874f831ec718c427e2993ac63b6826ef504f0ef7d29f548767f98c43e7c5cf38cc6beb8c4501d19a61ed78d99844d8d29ee65abe858e3cfa285d964351f43f7294528b64a6a5d7dadcc729264decc94329c15437056e8acffa4687f3'

const api = axios.create({
  baseURL: 'https://strapi.easytransferdrivers.site:444/api/',
  headers: {
    Authorization: 'Bearer' + ' ' + token
  }
})
const locale = localStorage.getItem('language')

export const query = (locale) => `
 {
  cityTours(locale: "${locale}") {
    data {
      attributes {
        cityName
        tours {
          data {
              id,
            attributes {
              name,
              arrivalLocation,
              availabilityEndAt,
              availabilityStartAt,
              bestTour,
              departureLocation,
              detailTextDescription,
              durationInHours,
              includedIN,
              includedNO,
              mapEmbed,
              maxNPassengers,
              minNPassengers,
              minimumAge,
              providerPrice,
              rate,
              images {
                data {
                  attributes {
                    url
                  }
                }
              }
              mainImage {
                data {
                  attributes {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`

const fetchTour = {
  getSelect: () => {
    axios
      .post(
        'https://strapi.easytransferdrivers.site:444/graphql',
        {
          query: query(locale)
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      )
      .then((res) => {
        const response = console.log(
          res?.data?.data?.cityTours?.data,
          'cityTours'
        )

        return response
      })
  },

  getTours: (language) => {
    const res = api.get(
      `tours?populate=*${language ? `&locale=${language}` : ''}`
    )

    return res
  },

  getTourPerId: (id) => {
    const res = api.get(`tours/${id}?populate=*`)

    return res
  },

  getImages: (id) => {
    const res = api.get(`tours/${id}?populate=mainImage&populate=images`)

    return res
  },

  getItinerary: (language) => {
    const res = api.get(
      `on-goings?populate=*${language ? `&locale=${language}` : ''}`
    )

    return res
  },

  getReview: (pageNumber, language) => {
    const res = api.get(
      `tour-reviews?populate=*&pagination[page]=${pageNumber}${
        language ? `&locale=${language}` : ''
      }`
    )

    return res
  }
}

export default fetchTour
