import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  width: 208.92px;
  height: 49.83px;

  .mantine-Menu-dropdown {
    width: auto !important;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
export const Text = styled.p`
  font-family: 'Neometric';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: normal;
  width: 149px;
  height: 18px;
  /* identical to box height */

  color: #808080;

  cursor: pointer;
`

export const Label = styled.label`
  font-family: 'Neometric';
  font-style: normal;

  color: #404040;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: normal;
`

export const IconContainer = styled.div`
  align-self: flex-end;
  margin-bottom: 2%;
`

export const Icon = styled.img``

export const CounterIcon = styled.img``

export const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  .mantine-Menu-dropdown {
    background: #ffffff;
    box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.07);
    border-radius: 0px 12px 12px 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 8px;
  }
`

export const CounterContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;

  .btn-plus {
    width: 16px !important;
    height: 16px !important;

    background: rgba(68, 255, 109, 0.4);
    border: 0.5px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.07);
    border-radius: 16px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;
    font-size: 22px;
  }

  .btn-minus {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 10px;

    width: 16px;
    height: 16px;

    background: rgba(216, 99, 99, 0.4);
    border: 0.5px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
  }

  strong {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #38280e;
    width: 7px;
    height: 17px;
  }
`

export const CounterTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  label {
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem;

    color: #38280e;
  }

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 8px;
    line-height: 9px;

    color: #38280e;
  }
`

export const CounterLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 10px;
  width: 50%;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  gap: 10px;
  justify-content: flex-end;
`
