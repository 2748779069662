import React, { useState } from 'react'
import { Button, Input, MediaQuery } from '@mantine/core'
import * as Styled from './styles'
// import { TransferCard } from './TransferCard' // Crie o componente TransferCard para exibir os dados de cada transferência

import Pending from './imgs/pending.svg'
import Acepted from './imgs/acepted.svg'
import Recused from './imgs/recused.svg'
import InProgress from './imgs/inprogress.svg'
import { PendingCards } from './components/PendingCards'
import { AcceptedCards } from './components/AcceptedCards'
import { RejectCards } from './components/RejectCards'
import { CanceledCards } from './components/CanceledCards'
import { ChevronDown, ChevronRight } from 'lucide-react'
import { AccomplishedCards } from './components/AccomplishedCards'
import Search from '../../../../assets/Search.webp'
import debounce from '../../../../utils/debounce'
import { InProgressCards } from './components/InProgressCards'
import { useMediaQuery } from '../../../../hooks/useMediaQuery'

export const Transfer = () => {
  const [filter, setFilter] = useState('PENDING')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [direction, setDirection] = useState()
  const [search, setSearch] = useState('')

  const isMobile = useMediaQuery('(max-width: 500px)')

  const handleFilterClick = (filter) => {
    setFilter(filter)
  }

  const handleChange = (value, name) => {
    debounce(() => setSearch({ ...search, [name]: value }), 500)
  }

  // if ('geolocation' in navigator) {
  //   navigator.geolocation.getCurrentPosition(
  //     function (position) {
  //       setLatitude(position.coords.latitude)
  //       setLongitude(position.coords.longitude)
  //       console.log(latitude, longitude)
  //     },
  //     function (error) {
  //       switch (error.code) {
  //         case error.PERMISSION_DENIED:
  //           console.log(
  //             'Permissão para obter a localização negada pelo usuário.'
  //           )
  //           break
  //         case error.POSITION_UNAVAILABLE:
  //           console.log('Localização indisponível.')
  //           break
  //         case error.TIMEOUT:
  //           console.log('Tempo limite expirado para obter a localização.')
  //           break
  //         default:
  //           console.log('Erro desconhecido ao obter a localização.')
  //       }
  //     }
  //   )
  // } else {
  //   console.log('Geolocalização não suportada no seu navegador.')
  // }b
  return (
    <Styled.Container>
      <Styled.Title>Transfers</Styled.Title>
      <Styled.ButtonsWrapper>
        <Styled.ButtonsContainer>
          <Button
            onClick={() => handleFilterClick('PENDING')}
            style={
              filter === 'PENDING'
                ? { backgroundColor: '#27c7ff', color: 'white' }
                : { backgroundColor: 'transparent' }
            }
            leftIcon={<img src={Pending} />}
          >
            Pendente(s)
          </Button>
          <Button
            onClick={() => handleFilterClick('ACCEPTED')}
            style={
              filter === 'ACCEPTED'
                ? { backgroundColor: '#48D9A4', color: 'white' }
                : { backgroundColor: 'transparent' }
            }
            leftIcon={<img src={Acepted} />}
          >
            Aceito(s)
          </Button>
          <Button
            onClick={() => handleFilterClick('IN_PROGRESS')}
            style={
              filter === 'IN_PROGRESS'
                ? { backgroundColor: '#9163CB', color: 'white' }
                : { backgroundColor: 'transparent' }
            }
            leftIcon={<img src={InProgress} />}
          >
            Em andamento
          </Button>

          <Button
            onClick={() => handleFilterClick('DONE')}
            style={
              filter === 'DONE'
                ? { backgroundColor: '#48D9A4', color: 'white' }
                : { backgroundColor: 'transparent' }
            }
            leftIcon={<img src={Acepted} />}
          >
            Realizado(s)
          </Button>
          <Button
            onClick={() => handleFilterClick('CANCELED')}
            style={
              filter === 'CANCELED'
                ? { backgroundColor: '#F8294E', color: 'white' }
                : { backgroundColor: 'transparent' }
            }
            leftIcon={<img src={Recused} />}
          >
            Cancelado(s)
          </Button>
        </Styled.ButtonsContainer>
        {isMobile && <ChevronRight />}
        <MediaQuery
          query="(max-width: 1025px)"
          styles={{ display: 'none !important' }}
        >
          <Styled.FiltersWrapper>
            <Input.Wrapper>
              <Input
                id="input-demo"
                placeholder="Filtro por zona"
                size={'lg'}
                rightSection={
                  <img
                    style={{ cursor: 'pointer', backgroundColor: 'white' }}
                    width={18}
                    height={18}
                    src={Search}
                  />
                }
                onChange={(e) => handleChange(e.target.value, 'places')}
              />
            </Input.Wrapper>
            <Input
              id="input-select"
              component="select"
              rightSection={<ChevronDown color="#27c7ff" />}
              pointer
              mt="md"
              onChange={(e) => {
                setDirection(e.target.value)
              }}
            >
              <option value="">Filtro</option>
              <option value="desc">Decrescente</option>
              <option value="asc">Crescente</option>
            </Input>
          </Styled.FiltersWrapper>
        </MediaQuery>
      </Styled.ButtonsWrapper>

      {/* {filter === 'Pendente' && ( */}
      <PendingCards
        // latitude={latitude}
        // longitude={longitude}
        status={filter}
        direction={direction}
        search={search}
      />
      {/* // )} */}
      {/* {filter === 'Aceito' && (
        <AcceptedCards direction={direction} search={search} />
      )}
      {filter === 'Andamento' && (
        <InProgressCards direction={direction} search={search} />
      )}
      {filter === 'Cancelado' && (
        <RejectCards direction={direction} search={search} />
      )}
      {filter === 'Realizado' && (
        <AccomplishedCards direction={direction} search={search} />
      )} */}
      {/* {filter === 'Cancelado' && <CanceledCards />} */}
    </Styled.Container>
  )
}
