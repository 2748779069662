import * as Styled from './styles'
import { Navbar } from '../../components/Navbar'
import Footer from '../../components/Footer'
import { useTranslation } from 'react-i18next'
import { ChevronRight } from 'lucide-react'
import { Accordion, createStyles } from '@mantine/core'
import { ReactComponent as SumSymbol } from '../../../src/assets/sum_symbol.svg'

export const Reimbursement = () => {
  const { t } = useTranslation()

  const IllnessRefundConditions = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>{t('reembolso.table.cannotProvideEvidence')}</p>
        <p>{t('reembolso.table.telephoneConsultations')}</p>
        <p>{t('reembolso.table.notPhysicallyExamined')}</p>
        <p>{t('reembolso.table.unpredictableConditions')}</p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const medicalConditions = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>{t('reembolso.table.guidelinesPreventAttending')}</p>
        <p>{t('reembolso.table.telephoneConsultations')}</p>
        <p>{t('reembolso.table.unpredictableConditions')}</p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const pregnancyConditions = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>{t('reembolso.table.normalPregnancy')}</p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const deathConditions = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>{t('reembolso.table.death.notRefundContent.content')}</p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const publicTransportFailureConditions = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>
          {t(
            'reembolso.table.publicTransportFailure.notRefundContent.providerFailure'
          )}
        </p>
        <p>
          {t(
            'reembolso.table.publicTransportFailure.notRefundContent.trafficClosure'
          )}
        </p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const flightDisruptionsConditions = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>
          {t(
            'reembolso.table.flightDisruption.notRefundContent.bookingCancellation'
          )}
        </p>
        <p>
          {t(
            'reembolso.table.flightDisruption.notRefundContent.disruptionAwareness'
          )}
        </p>
        <p>
          {t(
            'reembolso.table.flightDisruption.notRefundContent.providerFailure'
          )}
        </p>
        <p>
          {t(
            'reembolso.table.flightDisruption.notRefundContent.bookingPurpose'
          )}
        </p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const mechanicalBreakdownConditions = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>
          {t(
            'reembolso.table.mechanicalBreakdown.notRefundContent.insufficientTime'
          )}
        </p>
        <p>
          {t(
            'reembolso.table.mechanicalBreakdown.notRefundContent.noAlternativeArrangements'
          )}
        </p>
        <p>
          {t(
            'reembolso.table.mechanicalBreakdown.notRefundContent.vehicleDuringBooking'
          )}
        </p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const weatherConditions = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>
          {t(
            'reembolso.table.adverseWeather.notRefundContent.noGovernmentWarnings'
          )}
        </p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const homerEmergencyConditions = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>{t('reembolso.table.homeEmergency.notRefundContent.noEvidence')}</p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const theftOfDocumentsConditions = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>
          {t('reembolso.table.theftOfDocuments.notRefundContent.canBeReplaced')}
        </p>
        <p>
          {t('reembolso.table.theftOfDocuments.notRefundContent.lostDocuments')}
        </p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const relocatedForWorkConditions = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>
          {t(
            'reembolso.table.relocatedForWork.notRefundContent.businessAttendance'
          )}
        </p>
        <p>
          {t(
            'reembolso.table.relocatedForWork.notRefundContent.temporaryRelocation'
          )}
        </p>
        <p>
          {t(
            'reembolso.table.relocatedForWork.notRefundContent.voluntaryRelocation'
          )}
        </p>
        <p>
          {t('reembolso.table.relocatedForWork.notRefundContent.businessOwner')}
        </p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const armedForcesRecallCondtions = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>
          {t(
            'reembolso.table.armedForcesEmergencyServicesRecall.notRefundContent.awareOfWork'
          )}
        </p>
        <p>
          {t(
            'reembolso.table.armedForcesEmergencyServicesRecall.notRefundContent.unsuccessfulRequest'
          )}
        </p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const juryServiceConditions = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>{t('reembolso.table.juryService.notRefundContent.noEvidence')}</p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const courtSummonsConditions = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>{t('reembolso.table.courtSummons.notRefundContent.noWitness')}</p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const emergencyCircumstancesConditions = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>
          {t(
            'reembolso.table.emergencyCircumstances.notRefundContent.notIntended'
          )}
        </p>
        <p>
          {t(
            'reembolso.table.emergencyCircumstances.notRefundContent.workScheduleChange'
          )}
        </p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const illnessEvidence = () => {
    return (
      <>
        <p style={{ marginBottom: '8px' }}>
          {t('reembolso.table.doctorsNote')}
        </p>
        <Styled.UlNormalWrapper>
          <li>{t('reembolso.table.illnessInjuryDetails')}</li>
          <li>{t('reembolso.table.dateOccurred')}</li>
          <li>{t('reembolso.table.preventsAttending')}</li>
          <li>{t('reembolso.table.proofOfRelationship')}</li>
        </Styled.UlNormalWrapper>
      </>
    )
  }

  const medicalConditionEvidence = () => {
    return (
      <>
        <p style={{ marginBottom: '8px' }}>
          {t('reembolso.table.doctorsNote')}
        </p>
        <Styled.UlNormalWrapper>
          <li>{t('reembolso.table.illnessDetails')}</li>
          <li>{t('reembolso.table.dateChanged')}</li>
          <li>{t('reembolso.table.preventsAttending')}</li>
        </Styled.UlNormalWrapper>
      </>
    )
  }

  const pregnancyComplicationEvidence = () => {
    return (
      <>
        <p style={{ marginBottom: '8px' }}>
          {t('reembolso.table.doctorsNote')}
        </p>
        <Styled.UlNormalWrapper>
          <li>{t('reembolso.table.complicationDetails')}</li>
          <li>{t('reembolso.table.dateOccurred')}</li>
          <li>{t('reembolso.table.preventsAttending')}</li>
        </Styled.UlNormalWrapper>
      </>
    )
  }

  const deathEvidence = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>{t('reembolso.table.death.deathCertificate')}</p>
        <p>{t('reembolso.table.death.proofOfRelationship')}</p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const publicTransportFailureEvidence = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>{t('reembolso.table.publicTransportFailure.evidence')}</p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const flightDisruptionEvidence = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p> {t('reembolso.table.publicTransportFailure.evidence')}</p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const mechanicalBreakDownEvidence = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>{t('reembolso.table.mechanicalBreakdown.evidence.breakdown')}</p>
        <p>
          {t('reembolso.table.mechanicalBreakdown.evidence.incidentReport')}
        </p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const weatherEvidence = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>{t('reembolso.table.adverseWeather.evidence.travelWarning')}</p>
        <p>{t('reembolso.table.adverseWeather.evidence.routeClosures')}</p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const homeEmergencyEvidence = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>
          {t(
            'reembolso.table.homeEmergency.evidence.burglaryFloodMaliciousDamage'
          )}
        </p>
        <p>{t('reembolso.table.homeEmergency.evidence.fire')}</p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const documentsTheftEvidence = () => {
    return (
      <Styled.UlNormalWrapper>
        <li>{t('reembolso.table.theftOfDocuments.evidence.policeReport')}</li>
        <li>{t('reembolso.table.theftOfDocuments.evidence.passportProof')}</li>
        <li>
          {t('reembolso.table.theftOfDocuments.evidence.cannotBeReplaced')}
        </li>
      </Styled.UlNormalWrapper>
    )
  }

  const workRelocationEvidence = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>{t('reembolso.table.relocatedForWork.evidence.employerLetter')}</p>
        <p>{t('reembolso.table.relocatedForWork.evidence.addressEvidence')}</p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const armyEmergencyRecallEvidence = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>
          {t(
            'reembolso.table.armedForcesEmergencyServicesRecall.evidence.commandingOfficerNote'
          )}
        </p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const jueryServiceEvidence = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>{t('reembolso.table.juryService.evidence.letterCopy')}</p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const courtSummonEvidence = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p> {t('reembolso.table.juryService.evidence.letterCopy')}</p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const emergencyCircumstancesEvidence = () => {
    return (
      <Styled.NoRefundConditionsContainer>
        <p>
          {t('reembolso.table.emergencyCircumstances.evidence.requiredByTeam')}
        </p>
      </Styled.NoRefundConditionsContainer>
    )
  }

  const accordionData = [
    {
      title: `${t('reembolso.table.illnessInjury')}`,
      meaning: `${t('reembolso.table.meaning')}`,
      notRefundable: `${t('reembolso.table.notRefund')}:`,
      evidenceRequired: `${t('reembolso.table.evidenceRequired')}:`,
      conditions: IllnessRefundConditions(),
      evidences: illnessEvidence(),
      // noRefundableCondition2: `${t('reembolso.table.telephoneConsultations')}`,
      // noRefundableCondition3: `${t('reembolso.table.notPhysicallyExamined')}`,
      // noRefundableCondition4: `${t('reembolso.table.unpredictableConditions')}`,
      index: 1
    },
    {
      title: `${t('reembolso.table.preExistingMedicalCondition')}`,
      meaning: `${t('reembolso.table.meaning')}`,
      notRefundable: `${t('reembolso.table.notRefund')}:`,
      evidenceRequired: `${t('reembolso.table.evidenceRequired')}:`,
      conditions: medicalConditions(),
      evidences: medicalConditionEvidence(),
      index: 2
    },
    {
      title: `${t('reembolso.table.pregnancyComplication')}`,
      meaning: `${t('reembolso.table.meaning')}`,
      notRefundable: `${t('reembolso.table.notRefund')}:`,
      evidenceRequired: `${t('reembolso.table.evidenceRequired')}:`,
      conditions: pregnancyConditions(),
      evidences: pregnancyComplicationEvidence(),
      index: 3
    },
    {
      title: `${t('reembolso.table.death.death')}`,
      meaning: `${t('reembolso.table.death.meaning')}`,
      notRefundable: `${t('reembolso.table.notRefund')}:`,
      evidenceRequired: `${t('reembolso.table.death.evidenceRequired')}:`,
      conditions: deathConditions(),
      evidences: deathEvidence(),
      index: 4
    },
    {
      title: `${t('reembolso.table.publicTransportFailure.failure')}`,
      meaning: `${t('reembolso.table.publicTransportFailure.meaning')}`,
      notRefundable: `${t('reembolso.table.notRefund')}:`,
      evidenceRequired: `${t(
        'reembolso.table.publicTransportFailure.evidenceRequired'
      )}:`,
      conditions: publicTransportFailureConditions(),
      evidences: publicTransportFailureEvidence(),
      index: 5
    },
    {
      title: `${t('reembolso.table.flightDisruption.disruption')}`,
      meaning: `${t('reembolso.table.flightDisruption.meaning')}`,
      notRefundable: `${t('reembolso.table.notRefund')}:`,
      evidenceRequired: `${t('reembolso.table.evidenceRequired')}:`,
      conditions: flightDisruptionsConditions(),
      evidences: flightDisruptionsConditions(),
      index: 6
    },
    {
      title: `${t('reembolso.table.mechanicalBreakdown.breakdown')}`,
      meaning: `${t('reembolso.table.mechanicalBreakdown.meaning')}`,
      notRefundable: `${t('reembolso.table.notRefund')}:`,
      evidenceRequired: `${t('reembolso.table.evidenceRequired')}:`,
      conditions: mechanicalBreakdownConditions(),
      evidences: mechanicalBreakDownEvidence(),
      index: 7
    },
    {
      title: `${t('reembolso.table.adverseWeather.weather')}`,
      meaning: `${t('reembolso.table.adverseWeather.meaning')}`,
      notRefundable: `${t('reembolso.table.notRefund')}:`,
      evidenceRequired: `${t('reembolso.table.evidenceRequired')}:`,
      conditions: weatherConditions(),
      evidences: weatherEvidence(),
      index: 8
    },
    {
      title: `${t('reembolso.table.homeEmergency.emergency')}`,
      meaning: `${t('reembolso.table.homeEmergency.meaning')}`,
      notRefundable: `${t('reembolso.table.notRefund')}:`,
      evidenceRequired: `${t('reembolso.table.evidenceRequired')}:`,
      conditions: homerEmergencyConditions(),
      evidences: homeEmergencyEvidence(),
      index: 9
    },
    {
      title: `${t('reembolso.table.theftOfDocuments.theft')}`,
      meaning: `${t('reembolso.table.theftOfDocuments.meaning')}`,
      notRefundable: `${t('reembolso.table.notRefund')}:`,
      evidenceRequired: `${t('reembolso.table.evidenceRequired')}:`,
      conditions: theftOfDocumentsConditions(),
      evidences: documentsTheftEvidence(),
      index: 10
    },
    {
      title: `${t('reembolso.table.relocatedForWork.relocation')}`,
      meaning: `${t('reembolso.table.relocatedForWork.meaning')}`,
      notRefundable: `${t('reembolso.table.notRefund')}:`,
      evidenceRequired: `${t('reembolso.table.evidenceRequired')}:`,
      conditions: relocatedForWorkConditions(),
      evidences: workRelocationEvidence(),
      index: 11
    },
    {
      title: `${t(
        'reembolso.table.armedForcesEmergencyServicesRecall.recall'
      )}`,
      meaning: `${t(
        'reembolso.table.armedForcesEmergencyServicesRecall.meaning'
      )}`,
      notRefundable: `${t('reembolso.table.notRefund')}:`,
      evidenceRequired: `${t('reembolso.table.evidenceRequired')}:`,
      conditions: armedForcesRecallCondtions(),
      evidences: armyEmergencyRecallEvidence(),
      index: 12
    },
    {
      title: `${t('reembolso.table.juryService.service')}`,
      meaning: `${t('reembolso.table.juryService.meaning')}`,
      notRefundable: `${t('reembolso.table.notRefund')}:`,
      evidenceRequired: `${t('reembolso.table.evidenceRequired')}:`,
      conditions: juryServiceConditions(),
      evidences: jueryServiceEvidence(),
      index: 13
    },
    {
      title: `${t('reembolso.table.courtSummons.service')}`,
      meaning: `${t('reembolso.table.courtSummons.meaning')}`,
      notRefundable: `${t('reembolso.table.notRefund')}:`,
      evidenceRequired: `${t('reembolso.table.evidenceRequired')}:`,
      conditions: courtSummonsConditions(),
      evidences: courtSummonEvidence(),
      index: 14
    },
    {
      title: `${t('reembolso.table.emergencyCircumstances.service')}`,
      meaning: `${t('reembolso.table.emergencyCircumstances.meaning')}`,
      notRefundable: `${t('reembolso.table.notRefund')}:`,
      evidenceRequired: `${t('reembolso.table.evidenceRequired')}:`,
      conditions: emergencyCircumstancesConditions(),
      evidences: emergencyCircumstancesEvidence(),
      index: 15
    }
  ]

  const useStyles = createStyles(() => ({
    chevron: {
      // '&[data-rotate]': {
      //   transform: 'rotate(-90deg)'
      // },
      backgroundColor: '#27c7ff',
      marginRight: 0,
      width: 'auto',
      padding: '8px',
      borderRadius: ' 0 6px 6px 0'
    },
    label: {
      padding: 0
    },
    control: {
      paddingRight: 0
    },
    item: {
      borderRadius: '6px',
      border: '0.5px solid #38280E4D',
      borderTop: '0.5px solid #38280E4D !important',
      ':first-of-type': {
        borderRadius: '6px'
      },
      ':last-of-type': {
        borderRadius: '6px'
      }
    },
    root: {
      gap: '10px',
      display: 'flex',
      flexDirection: 'column'
    },
    panel: {
      padding: '1.6rem 2rem 1.6rem'
    }
  }))

  const { classes } = useStyles()

  const items = accordionData.map((item) => (
    <Accordion.Item value={item.title} key={item.title}>
      <Accordion.Control>
        <Styled.Question>{item.title}</Styled.Question>
      </Accordion.Control>
      <Accordion.Panel>
        <Styled.QuestionContent>{item.meaning}</Styled.QuestionContent>
        <Styled.QuestionContent style={{ fontWeight: 'bold' }}>
          {item.notRefundable}
        </Styled.QuestionContent>
        <Styled.QuestionContainer>{item.conditions}</Styled.QuestionContainer>
        <Styled.QuestionContent
          style={{ fontWeight: 'bold', marginTop: '8px' }}
        >
          {item.evidenceRequired}
        </Styled.QuestionContent>
        <Styled.QuestionContainer>{item.evidences}</Styled.QuestionContainer>
      </Accordion.Panel>
    </Accordion.Item>
  ))

  return (
    <Styled.Container>
      <Navbar />
      <h1>{t('reembolso.title')}</h1>
      <Styled.ContentContainer>
        <h2>{t('reembolso.refundableDescription')}</h2>

        <Styled.HeaderContainer>
          <ChevronRight />
          <p>{t('reembolso.reasonsHeader')}</p>
        </Styled.HeaderContainer>

        <Styled.UlGridHorizontal>
          <li>{t('reembolso.illnessInjury')}</li>
          <li>{t('reembolso.homeEmergency')}</li>
          <li>{t('reembolso.preExistingMedicalCondition')}</li>
          <li>{t('reembolso.theftOfDocuments')}</li>
          <li>{t('reembolso.pregnancyComplication')}</li>
          <li>{t('reembolso.juryService')}</li>
          <li>{t('reembolso.deathOfImmediateFamily')}</li>
          <li>{t('reembolso.courtSummons')}</li>
          <li>{t('reembolso.publicTransportFailure')}</li>
          <li>{t('reembolso.armedForcesEmergencyServicesRecall')}</li>
          <li>{t('reembolso.flightDisruption')}</li>
          <li>{t('reembolso.relocatedForWork')}</li>
          <li>{t('reembolso.mechanicalBreakdown')}</li>
          <li>{t('reembolso.changesToExaminationDates')}</li>
          <li>{t('reembolso.adverseWeather')}</li>
        </Styled.UlGridHorizontal>

        <Styled.HeaderContainer>
          <ChevronRight />
          <p>{t('reembolso.bookingCancellation')}</p>
        </Styled.HeaderContainer>

        <p>{t('reembolso.otherEmergencyCircumstances')}</p>
        <p>{t('reembolso.generalConditions')}</p>
        <Styled.UlGridVertical>
          <li>{t('reembolso.reasonForRefund')}</li>
          <li>{t('reembolso.noRefundByMistake')}</li>
          <li>{t('reembolso.contactCustomerService')}</li>
          <li>{t('reembolso.makeArrangements')}</li>
          <li>{t('reembolso.provideEvidence')}</li>
          <li>{t('reembolso.provideBankDetails')}</li>
          <li>{t('reembolso.maxRefundValue')}</li>
        </Styled.UlGridVertical>

        <h2>{t('reembolso.covid19.title')}</h2>
        <Styled.HeaderContainer>
          <ChevronRight />
          <p>{t('reembolso.covid19.description')}</p>
        </Styled.HeaderContainer>
        <Styled.UlGridVertical>
          <li>{t('reembolso.covid19.reason1')}</li>
          <li>{t('reembolso.covid19.reason2')}</li>
          <li>{t('reembolso.covid19.reason3')}</li>
          <li>{t('reembolso.covid19.reason4')}</li>
        </Styled.UlGridVertical>
        <p>{t('reembolso.covid19.notRefundable')}</p>
        <h2>{t('reembolso.covid19.requestRefund')}</h2>
        <p>{t('reembolso.covid19.applyRefund')}</p>

        <Styled.QuestionContainer>
          <Styled.QuestionsWrapper className="accordion">
            <Accordion
              classNames={classes}
              className={classes.root}
              chevron={
                <Styled.ChevronContainer>
                  <SumSymbol />
                </Styled.ChevronContainer>
              }
              chevronPosition="right"
              variant="contained"
              disableChevronRotation
            >
              {items}
            </Accordion>
          </Styled.QuestionsWrapper>
        </Styled.QuestionContainer>

        <h2>{t('reembolso.table.refundPolicy.noRefundReasons')}</h2>
        <p>{t('reembolso.table.refundPolicy.noRefundAssociated')}</p>

        <Styled.UlNormalWrapper>
          <li>{t('reembolso.table.refundPolicy.noRefundReasonsList.1')}</li>
          <li>{t('reembolso.table.refundPolicy.noRefundReasonsList.2')}</li>
          <li>{t('reembolso.table.refundPolicy.noRefundReasonsList.3')}</li>
          <li>{t('reembolso.table.refundPolicy.noRefundReasonsList.4')}</li>
          <li>{t('reembolso.table.refundPolicy.noRefundReasonsList.5')}</li>
          <li>{t('reembolso.table.refundPolicy.noRefundReasonsList.6')}</li>
        </Styled.UlNormalWrapper>

        <p>
          {t('reembolso.table.refundDefinitions.boldText')} -
          {t('reembolso.table.refundDefinitions.meaning')}
        </p>
        <p>Nós/Nós/Nosso – {t('reembolso.table.refundDefinitions.we')}</p>
        <p>
          Você/Você/Você mesmo – {t('reembolso.table.refundDefinitions.you')}
        </p>
        <p>Provedor – {t('reembolso.table.refundDefinitions.provider')}</p>
        <p>
          Forças Armadas – {t('reembolso.table.refundDefinitions.armedForces')}
        </p>
        <p>Participar – {t('reembolso.table.refundDefinitions.attend')}</p>
        <p>Reserva – {t('reembolso.table.refundDefinitions.booking')}</p>
        <p>
          Doença Transmissível –
          {t('reembolso.table.refundDefinitions.communicableDisease')}
        </p>
        <p>Médico – {t('reembolso.table.refundDefinitions.doctor')}</p>
        <p>
          Serviços de Emergência –
          {t('reembolso.table.refundDefinitions.emergencyServices')}
        </p>
        <p>
          Família Imediata –
          {t('reembolso.table.refundDefinitions.immediateFamily')}
        </p>
        <p>
          Domicílio Imediato –
          {t('reembolso.table.refundDefinitions.immediateHousehold')}
        </p>
        <p>
          Parte Pagadora – {t('reembolso.table.refundDefinitions.payingParty')}
        </p>
        <p style={{ marginTop: '50px' }}>
          {t('reembolso.table.refundDefinitions.importantTitle')}
        </p>
        <p>{t('reembolso.table.refundDefinitions.importantContent1')}</p>
        <p>{t('reembolso.table.refundDefinitions.importantContent2')}</p>
        <Styled.lastElement>
          {t('reembolso.table.refundDefinitions.importantContent3')}
        </Styled.lastElement>
      </Styled.ContentContainer>
      <Footer />
    </Styled.Container>
  )
}
