import * as Styled from './styles'
import { ReactComponent as DriverImage } from './imgs/DriverImage.svg'
import { ReactComponent as CloseIcon } from './imgs/CloseIcon.svg'
import { useAuth } from '../../../../../../contexts/useAuth'
import { SelectComponent } from '../../../../../../components/ReserveComponent/components/SelectComponent'
import { useEffect, useState } from 'react'
import fetchAccount from '../../../../../../services/account'
import { Checkbox, Input } from '@mantine/core'
import Search from '../../../../../../assets/Search.webp'
import fetchBooking from '../../../../../../services/booking'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'
import debounce from '../../../../../../utils/debounce'
import { useTranslation } from 'react-i18next'

export const SendTransferModal = ({ opened, onClose, onSuccess, booking }) => {
  const { user } = useAuth()

  const [accounts, setAccounts] = useState([])
  const [selectedDriver, setSelectedDriver] = useState([])
  const [search, setSearch] = useState('')
  const [category, setCategory] = useState('STANDARD')
  const allDriverIds = accounts.map((account) => account.id)

  const { t } = useTranslation()

  const navigate = useNavigate()

  const handleSelectAll = () => {
    if (selectedDriver.length === allDriverIds.length) {
      setSelectedDriver([])
    } else {
      setSelectedDriver(allDriverIds)
    }
  }

  const handleSelectDriver = (driverId) => {
    if (selectedDriver.includes(driverId)) {
      setSelectedDriver(selectedDriver.filter((id) => id !== driverId))
    } else {
      setSelectedDriver([...selectedDriver, driverId])
    }
  }

  useEffect(() => {
    const payload = {
      // vehicleCategoryName: category,
      name: search,
      role: 'USER'
    }

    if (opened) {
      // if (user.role === 'ADMIN') {
      fetchAccount
        .getAllAccount(payload)
        .then((res) => {
          if (res.data) {
            setAccounts(res.data.content)
          }
        })
        .catch((err) => console.log(err, 'ERR'))
    }
    // }
  }, [opened, search, category])

  const handleSendTransfer = (driver) => {
    fetchBooking
      .inviteDrivers({
        booking: {
          ...booking
        },
        driversIds: [...driver]
      })
      .then((res) => {
        toast.success(t('toast.successSentTransfer'))
        navigate('/dashboard/transfer')
      })
      .catch((err) => console.log(err, 'err'))
  }

  return (
    <>
      <Styled.ModalOverlay
        opened={opened}
        onClick={() => {
          setSelectedDriver('')
          onClose()
        }}
      />
      <Styled.ModalContainer opened={opened}>
        <Styled.ModalHeader>
          <Styled.CloseButton>
            <CloseIcon
              onClick={() => {
                setSelectedDriver('')
                onClose()
              }}
            />
          </Styled.CloseButton>
        </Styled.ModalHeader>
        <Styled.ModalContentSelect>
          <Styled.ModalTitleSelect>
            Pesquise e selecione o(s) motorista(s) que podem realizar esse
            transfer
            <Input.Wrapper id="filters">
              <Input
                id="input-demo"
                placeholder="Pesquisar"
                size={'lg'}
                rightSection={
                  <img
                    style={{ cursor: 'pointer' }}
                    // onClick={() => setGoing({ ...going, flightNumber: '' })}
                    width={18}
                    height={18}
                    src={Search}
                  />
                }
                onChange={(e) => debounce(() => setSearch(e.target.value), 500)}
              />
            </Input.Wrapper>
          </Styled.ModalTitleSelect>
          <Styled.ModalContentDriver>
            <Styled.CategoryDriver>
              <Styled.CategoryTitle>Categoria</Styled.CategoryTitle>
              <Styled.CategoriesContainer>
                <Styled.Categories
                  onClick={() => setCategory('STANDARD')}
                  className={category === 'STANDARD' ? 'selected' : ''}
                >
                  Económico
                </Styled.Categories>
                <Styled.Categories
                  onClick={() => setCategory('EXECUTIVE')}
                  className={category === 'EXECUTIVE' ? 'selected' : ''}
                >
                  Executivo
                </Styled.Categories>
                <Styled.Categories
                  onClick={() => setCategory('ECONOMIC_VAN')}
                  className={category === 'ECONOMIC_VAN' ? 'selected' : ''}
                >
                  Van
                </Styled.Categories>
                <Styled.Categories
                  onClick={() => setCategory('BUS')}
                  className={category === 'BUS' ? 'selected' : ''}
                >
                  Autocarro
                </Styled.Categories>
                <Styled.Categories
                  onClick={() => setCategory('AIRPLANE')}
                  className={category === 'AIRPLANE' ? 'selected' : ''}
                >
                  Avioneta
                </Styled.Categories>
              </Styled.CategoriesContainer>
            </Styled.CategoryDriver>
            <Styled.DriverContainer>
              <Styled.CategoryTitle>Motorista(s)</Styled.CategoryTitle>
              <Styled.DriversWrapper>
                <Styled.DriversContainer>
                  <Styled.Drivers>
                    <Checkbox
                      size="lg"
                      // styles={{ label: { color: 'black' } }}
                      color="orange-primary"
                      label="Selecionar todos"
                      onChange={handleSelectAll}
                      checked={selectedDriver.length === allDriverIds.length}
                    />
                  </Styled.Drivers>
                  {accounts.map((account) => (
                    <Styled.Drivers key={account.id}>
                      <Checkbox
                        size="lg"
                        // styles={{ label: { color: 'black' } }}
                        color="orange-primary"
                        onChange={() => handleSelectDriver(account.id)}
                        checked={selectedDriver.includes(account.id)}
                      />
                      <div>
                        <p>{account.name}</p>
                        <span>Económico, Lisboa - PT</span>
                      </div>
                    </Styled.Drivers>
                  ))}
                </Styled.DriversContainer>
              </Styled.DriversWrapper>
            </Styled.DriverContainer>
          </Styled.ModalContentDriver>
        </Styled.ModalContentSelect>
        <Styled.ModalButton onClick={() => handleSendTransfer(selectedDriver)}>
          ENVIAR
        </Styled.ModalButton>
      </Styled.ModalContainer>
    </>
  )
}
