import { CardOne } from '../CardOne'
import * as Styled from './styles'

export const Card = ({ fleets }) => {
  return (
    <Styled.Container>
      {fleets.map((item) => (
        <CardOne key={item.car} car={item} className="item" />
      ))}
    </Styled.Container>
  )
}
