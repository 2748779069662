import styled from 'styled-components'
export const Container = styled.div`
  .mantine-NumberInput-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12.1898px 8px;
    gap: 81.27px;

    width: 166.3px;
    height: 54px;

    border: 0.507906px solid #bfbfbf;
    border-radius: 6.09488px;
  }

  .btn-plus {
    position: absolute;
    right: 0.5rem;
    align-items: center;
  }
  .btn-plus-checked {
    position: absolute;
    right: 0.5rem;
    background: rgba(162, 162, 162, 0.4);
    border: 0.5px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    width: 22.55px !important;
    height: 22.55px !important;
    min-width: 22.55px !important;
    min-height: 22.55px !important;
    align-items: center;
    font-size: 22px;
    color: #0d0d0d;
  }
  .btn-minus {
    position: absolute;
    left: 0.5rem;
    z-index: 99;
    align-items: flex-end;
  }
`

export const LabelAndIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8.68px;

  height: 38px;

  margin-bottom: 14px;

  svg {
    width: 24px;
    height: 24px;
  }
  label {
    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`
