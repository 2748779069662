import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  text-align: center;
  width: 100%;

  > svg {
    margin: 0 auto;
    width: 4.8rem;
    height: 4.8rem;
    color: #27c7ff;
  }

  > h2 {
    font-weight: 600;
    line-height: 1;
    font-size: 2.4rem;
    line-height: 3.2rem;

    > span {
      color: #27c7ff;
    }

    @media (min-width: 1024px) {
      font-size: 3.2rem;
    }
  }

  > p {
    margin: 0 auto;
    max-width: 73rem;
    font-size: 1.6rem;
  }

  > img {
    margin: 0 auto;
    width: 100%;

    @media (min-width: 768px) {
      max-height: 43.5rem;
      max-width: 43.5rem;
    }
  }

  > div {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    justify-content: center;
    gap: 3.2rem;

    > div:first-of-type {
      grid-column: span 2 / span 2;
    }

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.2rem;

      > span {
        font-size: 3.6rem;
        line-height: 4rem;
        font-weight: bold;
        color: #27c7ff;
      }

      > div {
        max-width: 15rem;
        text-align: center;

        > h5 {
          font-size: 1.4rem;
          font-weight: 800;
          text-transform: uppercase;
        }

        > p {
          font-size: 1.2rem;
          line-height: 1.8rem;
        }
      }
    }

    @media (min-width: 1024px) {
      > div {
        > span {
          font-size: 6.8rem;
        }

        > div {
          text-align: start;
          font-size: 1.6rem; /* 16px */
          line-height: 2.4rem; /* 24px */
        }
      }
    }

    @media (min-width: 768px) {
      display: flex;
      gap: 1.6rem;

      > div {
        flex-direction: row;

        > span {
          font-size: 4.5rem;
        }
      }
    }
  }
`
