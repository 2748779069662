import React from 'react'
import * as Styled from './styles'
import { useParams } from 'react-router-dom'

export function TopBarDetails({ onSave, setOpen, isOpen }) {
  const { id } = useParams()
  return (
    <Styled.TopBarContainer>
      <Styled.OrderBumpLabel>Order Bump</Styled.OrderBumpLabel>
      <Styled.ButtonsContainer>
        {id && (
          <Styled.ActionDeleteButton onClick={() => setOpen(!isOpen)}>
            Excluir
          </Styled.ActionDeleteButton>
        )}

        <Styled.ActionSaveButton onClick={onSave}>
          Salvar
        </Styled.ActionSaveButton>
      </Styled.ButtonsContainer>
    </Styled.TopBarContainer>
  )
}
