import { useRef, useState } from 'react'
import { Text, Group, Button, rem, useMantineTheme } from '@mantine/core'
import { Dropzone, MIME_TYPES } from '@mantine/dropzone'
import { IconCloudUpload, IconX, IconDownload } from '@tabler/icons-react'
import classes from './DropzoneButton.module.css'
import { FileText, Paperclip, Trash, Trash2, Upload } from 'lucide-react'
import CustomDropzone from './CustomDropzone'

export const ArquivesEnterprise = ({ data, setData, disabled }) => {
  const theme = useMantineTheme()
  const openRef = useRef(null)

  const handleFileDelete = (fileToDelete) => {
    setData((prevData) => ({
      ...prevData,
      files: prevData?.files?.filter((file) => file !== fileToDelete) || []
    }))
  }

  const readFileAsBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.readAsDataURL(file)
    })
  }

  const handleFileUpload = async (newFiles, setData, prevData, identifier) => {
    try {
      const filesData = await Promise.all(
        newFiles.map(async (newFile) => {
          const base64Data = await readFileAsBase64(newFile)
          const base64Parts = base64Data.split(',')

          if (base64Parts.length === 2) {
            return {
              title: identifier,
              fileName: newFile.name,
              fileExtension: newFile.type.split('/')[1],
              url: base64Data,
              base64: base64Parts[1],
              identifier
            }
          }
        })
      )

      const existingFiles = prevData.files.filter(
        (file) => file.identifier !== identifier
      )
      const newFilesData = filesData.filter(Boolean)

      setData({
        ...prevData,
        files: [...existingFiles, ...newFilesData]
      })
    } catch (error) {
      console.error('Erro ao ler os arquivos como base64', error)
    }
  }

  console.log(data, 'data')

  return (
    <div style={{ overflow: 'scroll' }}>
      <CustomDropzone
        openRef={openRef}
        onDrop={(newFiles) => {
          handleFileUpload(newFiles, setData, data, 'frota')
        }}
        identifier="frota"
        files={data.files}
        theme={theme}
        classes={classes}
        handleDelete={handleFileDelete}
        name="Frota"
        disabled={disabled}
        data={data}
        setData={setData}
      />
      <CustomDropzone
        openRef={openRef}
        onDrop={(newFiles) => {
          handleFileUpload(newFiles, setData, data, 'contrato')
        }}
        identifier="contrato"
        files={data.files}
        theme={theme}
        classes={classes}
        handleDelete={handleFileDelete}
        name="Contrato"
        disabled={disabled}
        data={data}
        setData={setData}
      />
      <CustomDropzone
        openRef={openRef}
        onDrop={(newFiles) => {
          handleFileUpload(newFiles, setData, data, 'iban')
        }}
        identifier="iban"
        files={data.files}
        theme={theme}
        classes={classes}
        handleDelete={handleFileDelete}
        name="Comprovativo de IBAN"
        disabled={disabled}
        data={data}
        setData={setData}
      />
    </div>
  )
}
