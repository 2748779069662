import * as React from 'react'
import { cva } from 'class-variance-authority'

import { cn } from '../../utils/cn'

import styles from './styles.module.css'

const badgeVariants = cva(styles.base, {
  variants: {
    variant: {
      default: styles.default,
      secondary: styles.secondary,
      destructive: styles.destructive,
      outline: styles.outline
    }
  },
  defaultVariants: {
    variant: 'default'
  }
})

function Badge({ className, variant, ...props }) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge, badgeVariants }
