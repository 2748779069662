import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 27.9298px 20.9474px;
  gap: 39.37px;

  max-width: 988.02px;
  width: 100%;
  height: 156.54px;
  margin-top: 30px;

  border: 0.872807px solid #bfbfbf;
  border-radius: 27.0661px;
`
export const ImageCar = styled.img`
  width: 176.31px;
`

export const OptionsAndTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const Title = styled.h1`
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;

  letter-spacing: 0.01em;

  color: #0d0d0d;
`

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8.67px;
`

export const Passenger = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    height: 20.91px;
  }
`

export const Briefcase = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    height: 29.53px;
  }
`

export const Wifi = styled.img`
  height: 20.91px;
`

export const CarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`

export const ChoiceButton = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #27c7ff;
`

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;

  h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;

    color: #0d0d0d;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: #a0a0a0;

    text-align: center;

    color: #a0a0a0;
  }
`
