import * as Styled from './styles'
import { ReactComponent as DriverImage } from './imgs/DriverImage.svg'
import { ReactComponent as CloseIcon } from './imgs/CloseIcon.svg'
import { useAuth } from '../../../../../../contexts/useAuth'
import { SelectComponent } from '../../../../../../components/ReserveComponent/components/SelectComponent'
import { useEffect, useState } from 'react'
import fetchAccount from '../../../../../../services/account'
import { Checkbox, Input } from '@mantine/core'
import Search from '../../../../../../assets/Search.webp'

export const SendTransferModal = ({ opened, onClose, onSuccess, status }) => {
  const { user } = useAuth()

  const [accounts, setAccounts] = useState([])
  const [selectedDriver, setSelectedDriver] = useState()

  // useEffect(() => {
  //   if (opened) {
  //     if (user.role === 'ADMIN') {
  //       fetchAccount
  //         .getAllAccount()
  //         .then((res) => {
  //           if (res) {
  //             res.data.map((account) => {
  //               setAccounts([
  //                 {
  //                   value: account.id,
  //                   label: account.name
  //                 }
  //               ])
  //             })
  //           }
  //         })
  //         .catch((err) => console.log(err, 'ERR'))
  //     }
  //   }
  // }, [opened])

  return (
    <>
      <Styled.ModalOverlay
        opened={opened}
        onClick={() => {
          setSelectedDriver('')
          onClose()
        }}
      />
      <Styled.ModalContainer opened={opened}>
        <Styled.ModalHeader>
          <Styled.CloseButton>
            <CloseIcon
              onClick={() => {
                onClose()
                setSelectedDriver('')
              }}
            />
          </Styled.CloseButton>
        </Styled.ModalHeader>
        <Styled.ModalContent>
          <DriverImage />
          {status === 'ACCEPTED' ? (
            <Styled.ModalTitle>
              Você tem certeza que deseja iniciar este transfer?
            </Styled.ModalTitle>
          ) : (
            <Styled.ModalTitle>
              Você tem certeza que realizou este transfer?
            </Styled.ModalTitle>
          )}
        </Styled.ModalContent>
        <Styled.ModalButton onClick={onSuccess}>SIM</Styled.ModalButton>
      </Styled.ModalContainer>
    </>
  )
}
