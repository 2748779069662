import { useEffect, useState } from 'react'

export function useSessionState({ name, defaultValue }) {
  const [state, setState] = useState(() => {
    const storage = sessionStorage.getItem(name)
    if (storage) return storage

    return defaultValue
  })

  useEffect(() => {
    sessionStorage.setItem(name, state)
  }, [state, name])

  return [state, setState]
}
