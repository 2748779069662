import api from './api'

const fetchPayment = {
  mbway: (payload) => {
    const res = api.post(`/payment/mbway/${payload.bookingId}`, payload)

    return res
  },

  multibanco: (payload) => {
    const res = api.post(`/payment/multibanco/${payload.bookingId}`, payload)

    return res
  },

  creditCard: (payload) => {
    const res = api.post(`/payment/creditCard/${payload.bookingId}`, payload)

    return res
  }
}

export default fetchPayment
