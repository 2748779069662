import { Button, Flex, Input, MediaQuery } from '@mantine/core'
import * as Styled from './styles'
import { ReactComponent as Avatar } from '../../imgs/avatar.svg'
import { useRef } from 'react'
import { IMaskInput } from 'react-imask'
import { useAuth } from '../../../../../../contexts/useAuth'

export const PersonalData = ({ data, setData, disabled }) => {
  const { ROLE } = useAuth()
  const handleInputChange = (field, value) => {
    setData((prevValues) => ({
      ...prevValues,
      [field]: value
    }))
  }

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const photoData = e.target.result
        {
          window.location.pathname.includes('company') ||
          window.location.pathname.includes('hostel')
            ? setData((prevData) => ({
                ...prevData,
                image: {
                  // title: 'string',
                  title: file.name,
                  fileName: file.name,
                  fileExtension: file.type.split('/')[1],
                  // fileKey: 'string',
                  url: photoData,
                  base64: photoData.split(',')[1]
                }
              }))
            : setData((prevData) => ({
                ...prevData,
                profilePicture: {
                  // title: 'string',
                  title: file.name,
                  fileName: file.name,
                  fileExtension: file.type.split('/')[1],
                  // fileKey: 'string',
                  url: photoData,
                  base64: photoData.split(',')[1]
                }
              }))
        }
      }
      console.log(file, 'FILE')
      reader.readAsDataURL(file)
    }
  }

  const fileInputRef = useRef(null)

  const handleAvatarClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const avatarSize = 150

  const imgStyle = {
    width: avatarSize,
    height: avatarSize,
    borderRadius: '100%',
    cursor: 'pointer'
  }
  return (
    <Styled.Container>
      {data?.profilePicture?.url || data?.image?.url ? (
        <img
          onClick={handleAvatarClick}
          src={data?.profilePicture?.url || data?.image?.url}
          alt="User"
          style={imgStyle}
        />
      ) : (
        <Avatar
          size={avatarSize}
          onClick={handleAvatarClick}
          style={{ cursor: 'pointer' }}
        />
      )}
      <input
        disabled={disabled}
        type="file"
        accept="image/*"
        onChange={handlePhotoUpload}
        style={{ display: 'none' }}
        ref={fileInputRef}
      />
      <MediaQuery query="(max-width: 768px)">
        <Flex gap="lg" direction="column">
          <Input.Wrapper
            label={`Nome ${
              window.location.pathname.includes('company') ||
              window.location.pathname.includes('hostel')
                ? 'empresa'
                : 'completo'
            } `}
          >
            <Input
              // icon={<Send />}
              onChange={(event) =>
                handleInputChange('name', event.target.value)
              }
              value={data?.name}
              placeholder="Escreva seu nome"
              size={'lg'}
              disabled={disabled}
            />
          </Input.Wrapper>
          <Input.Wrapper label="E - mail">
            <Input
              // icon={<Send />}
              onChange={(event) =>
                handleInputChange('email', event.target.value)
              }
              value={data?.email}
              placeholder="Seu melhor e-mail"
              size={'lg'}
              disabled
            />
          </Input.Wrapper>
          <MediaQuery
            query="(max-width: 768px)"
            styles={{ flexDirection: 'column' }}
          >
            <Flex gap="md" justify="space-between">
              {!(
                window.location.pathname.includes('company') ||
                window.location.pathname.includes('hostel')
              ) && (
                <Input.Wrapper label="Data de nascimento">
                  <Input
                    // icon={<Send />}
                    component={IMaskInput}
                    mask="00/00/0000"
                    onChange={(event) =>
                      handleInputChange('dateOfBirth', event.target.value)
                    }
                    value={data?.dateOfBirth}
                    placeholder="00/00/0000"
                    size={'lg'}
                    disabled={disabled}
                  />
                </Input.Wrapper>
              )}

              <Input.Wrapper
                label={`NIF ${
                  !(
                    window.location.pathname.includes('company') ||
                    window.location.pathname.includes('hostel')
                  )
                    ? '/ CPF'
                    : ''
                }`}
              >
                <Input
                  // icon={<Send />}
                  onChange={(event) => {
                    if (
                      !(
                        window.location.pathname.includes('company') ||
                        window.location.pathname.includes('hostel')
                      )
                    ) {
                      handleInputChange('taxNumber', event.target.value)
                    } else {
                      handleInputChange('nif', event.target.value)
                    }
                  }}
                  value={
                    !(
                      window.location.pathname.includes('company') ||
                      window.location.pathname.includes('hostel')
                    )
                      ? data?.taxNumber
                      : data?.nif
                  }
                  placeholder="0000000000"
                  size={'lg'}
                  disabled={disabled}
                />
              </Input.Wrapper>
            </Flex>
          </MediaQuery>
          {!(
            window.location.pathname.includes('company') ||
            window.location.pathname.includes('hostel')
          ) && (
            <>
              <Input.Wrapper label="Telemóvel / WhatsApp">
                <Input
                  // icon={<Send />}
                  onChange={(event) =>
                    handleInputChange('phoneNumber', event.target.value)
                  }
                  value={data?.phoneNumber}
                  placeholder="0000000000"
                  size={'lg'}
                  disabled={disabled}
                />
              </Input.Wrapper>
              {!(ROLE === 'HOTEL_USER' || ROLE === 'COMPANY_ADMIN') && (
                <Input.Wrapper label="Carta de condução">
                  <Input
                    // icon={<Send />}
                    onChange={(event) =>
                      handleInputChange('driverLicense', event.target.value)
                    }
                    value={data?.driverLicense}
                    placeholder="0000000000"
                    size={'lg'}
                    disabled={disabled}
                  />
                </Input.Wrapper>
              )}
            </>
          )}
        </Flex>
      </MediaQuery>
    </Styled.Container>
  )
}
