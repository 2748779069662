import * as S from './styles'
import { ReactComponent as CheckedIcon } from './imgs/check-icon.svg'
import { ReactComponent as Arrow } from './imgs/button-arrow.svg'
import CarrouselLisbon from './imgs/carrousel_lisbon.webp'
import CarrouselEriceira from './imgs/carrousel_ericeira.webp'
import { ReactComponent as OrangeShape } from './imgs/Rectangle1162.svg'
import { ReactComponent as NextIcon } from './imgs/arrow-right.svg'
import { Carousel } from '@mantine/carousel'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '../../hooks/useMediaQuery'

export const Tours = () => {
  const { t } = useTranslation()
  const singleSlideMatch = useMediaQuery('(max-width: 860px)')

  return (
    <S.CarouselWrapper style={{ position: 'relative' }}>
      <S.BackgroundShape>
        <OrangeShape />
      </S.BackgroundShape>
      <S.TextContainer>
        <h3>{t('goBeyondTheSights')}</h3>
        <h2>{t('ourImmersiveTours')}</h2>
        <div>
          <CheckedIcon />
          <span>{t('authenticLocalExperience')}</span>
        </div>
        <div>
          <CheckedIcon />
          <span>{t('professionalExperiencedGuides')}</span>
        </div>
        <div>
          <CheckedIcon />
          <span>{t('flexibleCustomItineraries')}</span>
        </div>
        <button>
          {t('seeMore')} <Arrow />
        </button>
      </S.TextContainer>
      <S.CarouselContainer>
        <Carousel
          align="center"
          slideSize="50%"
          slidesToScroll={singleSlideMatch ? 1 : 2}
          slideGap={20}
          styles={{
            control: {
              '&[data-inactive]': {
                opacity: 0,
                cursor: 'default'
              }
            }
          }}
          nextControlIcon={<NextIcon id="nexticon" />}
          previousControlIcon={
            <NextIcon id="nexticon" style={{ rotate: '180deg' }} />
          }
          controlSize={50}
          loop
        >
          <Carousel.Slide>
            <S.EmphasisTag>
              <span>{t('highlight')}</span>
            </S.EmphasisTag>
            <S.Image src={CarrouselLisbon} alt="First slide" />
            <S.ImageTitleContainer>
              <S.ImageTitle>Lisboa</S.ImageTitle>
            </S.ImageTitleContainer>
          </Carousel.Slide>
          <Carousel.Slide>
            <S.EmphasisTag>
              <span>{t('highlight')}</span>
            </S.EmphasisTag>
            <S.Image src={CarrouselEriceira} alt="Second slide" />
            <S.ImageTitleContainer>
              <S.ImageTitle>Ericeira</S.ImageTitle>
            </S.ImageTitleContainer>
          </Carousel.Slide>
        </Carousel>
      </S.CarouselContainer>
    </S.CarouselWrapper>
  )
}
