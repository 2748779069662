import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 24px 24px 24px;

  gap: 8px;

  & textarea {
    resize: none;
    width: 100%;

    border-radius: 10px;
    border: 0.5px solid #bfbfbf;
    padding: 16px;
  }

  @media (max-width: 500px) {
    textarea {
      height: 100px;
    }
  }
`

export const SendButton = styled.button.attrs({ type: 'button' })`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  border: 0.5px solid #bfbfbf;
  background: #fff;

  height: 100%;
  padding: 14px;

  transition: all 0.2s;

  &:hover {
    transform: scale(1.035);
    border: 0.5px solid #27c7ff;
  }

  &:active {
    transform: scale(0.95);
  }

  @media (max-width: 500px) {
    height: auto;
  }
`

export const SendText = styled.p`
  font-family: Neometric;
  color: #404040;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
`
