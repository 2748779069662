import styled from 'styled-components'

import {
  Card as UICard,
  CardContent as UICardContent
} from '../../../../../../components/Card'

// rounded-xl border-none bg-gradient-to-t from-zinc-700 to-zinc-300 p-0
export const Card = styled(UICard)`
  border-radius: 1.2rem;
  border: 0.1rem solid #fff9e5;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
`

export const CardContent = styled(UICardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.4rem;

  img {
    height: 12.8rem;
    max-width: 14.8rem;
    width: 100%;
  }

  span:first-of-type {
    font-size: 1.6rem;
    font-weight: 600;
    /* color: #f3f4f6; */
  }

  span:last-of-type {
    color: #9ca3af;
    font-size: 1.4rem;
    line-height: 2rem;
  }
`
