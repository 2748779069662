import styled from 'styled-components'
export const Container = styled.div`
  .description-text {
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 0.25rem;
    }
  }

  .mantine-Carousel-root {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 17.8414px 10.7102px 10.6686px 10.6686px;
  }

  .mantine-Carousel-container {
    gap: 0.2rem;
    margin-left: 1rem;
  }

  .mantine-Carousel-controls {
    gap: 113rem;
  }

  .mantine-Carousel-root {
    width: 114rem;
  }

  .mantine-Carousel-control {
    box-shadow: none;
  }

  @media only screen and (max-width: 994px) {
    width: 100%;
    overflow: auto;
    .mantine-Carousel-controls {
      display: none;
    }
    .mantine-Carousel-container {
      .mantine-Carousel-slide {
        flex: none;
        width: fit-content !important;

        > div {
          padding: 1.6rem;
        }
        & + .mantine-Carousel-slide {
          margin-left: 1.2rem !important;
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .mantine-Carousel-root {
      padding: 0;
    }
    .mantine-Carousel-slide {
      h1 {
        font-size: 1.6rem;
        line-height: normal;
      }
      span {
        font-size: 1.4rem;
        line-height: normal;
      }
      .description-text {
        font-size: 1.4rem;
        height: 60%;
      }

      > div {
        width: 28rem;
        > div {
          > div:first-of-type > img:first-of-type {
            width: 4rem;
            height: 4rem;
          }
          > div:last-of-type {
            img {
              width: 2rem;
              height: 1.9rem;
            }
          }
        }
      }
    }
  }
`

export const Header = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-start;

  img {
    width: 53px;
    height: 53px;
    border-radius: 40px;
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  margin-bottom: 4px;
`

export const ImageGoogle = styled.img`
  margin-left: auto;
  width: 22px !important;
  height: 22px !important;
`

export const CardContainer = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;

  font-family: 'Roboto', sans-serif;

  color: #000000;
  width: 354.27px;
  height: 230.42px;
  overflow: hidden;

  background: #ffffff;
  box-shadow: 1.33357px 1.33357px 9.33499px rgba(0, 0, 0, 0.15),
    0px 0px 2.66714px rgba(0, 0, 0, 0.05);
  border-radius: 6.66785px;
  padding: 2rem;

  h1 {
    font-weight: 700;
    font-size: 20.0035px;
    line-height: 28px;
  }

  span {
    font-weight: 400;
    font-size: 16.0028px;
    line-height: 22px;

    color: rgba(0, 0, 0, 0.5);
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    margin-top: 8px;
    height: 50%;
  }
`
// export const CarrouselBtnWrapper = styled.div`
//   position: absolute;
// `

export const BtnNext = styled.img`
  cursor: pointer;
  right: -3rem;
  top: 2rem;
`
export const BtnPrev = styled.img`
  cursor: pointer;
  top: 2rem;
  left: -3rem;
`

export const Button = styled.button`
  padding: 6px 24px;
  border-radius: 8px;

  font-weight: 600;
  cursor: pointer;
  transition: 1s;
  background-color: #fff;
  color: #27c7ff;
  border: solid 1px #27c7ff;

  &:hover {
    background: #27c7ff;
    border: 0;
    color: white;
  }
`

export const CardRow = styled.div`
  height: 100%;
`
