import styled from 'styled-components'

export const ButtonsContainer = styled.div`
  display: flex;
  /* align-items: flex-start; */
  align-items: center;
  justify-content: center;
  gap: 24px;

  max-width: 840px;
  margin-top: 16px;
`
export const AddStopButton = styled.button`
  display: flex;
  width: 220px;
  height: 48px;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
  gap: 16px;

  border-radius: 16px;
  background: #27c7ff;

  font-family: Roboto;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  color: #fff;
`
