import * as Styled from './styles'
import { Menu, Navbar, ScrollArea, createStyles, rem } from '@mantine/core'

import { UserButton } from './UserButton'
import { LinksGroup } from './NavbarLinksGroup'
// import { Logo } from './Logo'
import { ReactComponent as HomeIcon } from './imgs/home.svg'
import { ReactComponent as CarIcon } from './imgs/car.svg'
import { ReactComponent as Driver } from './imgs/driver.svg'
import { ReactComponent as LineChart } from './imgs/line_chart.svg'
import { ReactComponent as CouponIcon } from './imgs/coupon-icon.svg'
import { ReactComponent as TrandingDownIcon } from './imgs/trending_down.svg'
import { ReactComponent as ConfigIcon } from './imgs/config-icon.svg'
import { ReactComponent as OrderBumpIcon } from './imgs/lucide_boxes.svg'
import { useAuth } from '../../../../../../contexts/useAuth'
import { IconUserCircle } from '@tabler/icons-react'
import { Link } from 'react-router-dom'
import { ChatButton } from './ChatButton'
import { LogOut } from 'lucide-react'

const useStyles = createStyles((theme) => ({
  navbar: {
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.white,
    paddingBottom: 0
  },

  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`
  },

  links: {
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`
  },

  linksInner: {
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl
  },

  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: `calc(${theme.spacing.md} * -1)`,
    marginRight: `calc(${theme.spacing.md} * -1)`,
    paddingTop: 10,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`
  }
}))

const adminData = [
  {
    label: 'Home',
    icon: HomeIcon,
    onClick: 'home'
  },
  {
    label: 'Transfer',
    icon: CarIcon,
    onClick: 'transfer'
  },
  {
    label: 'Frota',
    icon: Driver,
    onClick: 'frota'
  },
  {
    label: 'Partnership',
    icon: Driver,
    onClick: 'partnership'
  },
  { label: 'Low Cost', icon: TrandingDownIcon, onClick: 'low-cost' },
  {
    label: 'Financeiro',
    icon: LineChart,
    onClick: 'financial'
  },
  {
    label: 'Cupons',
    icon: CouponIcon,
    onClick: 'coupons'
  },
  {
    label: 'Order Bump',
    icon: OrderBumpIcon,
    onClick: 'order-bump'
  },
  {
    label: 'Configurações',
    icon: ConfigIcon,
    onClick: 'config'
  }
]

export const Card = ({ role, onOptionClick, activePage }) => {
  const { signOut } = useAuth()

  const { classes } = useStyles()
  const { user } = useAuth()
  const driversData = [
    { label: 'Transfer', icon: CarIcon, onClick: 'transfer' },
    {
      label: 'Ganhos',
      icon: LineChart,
      onClick: `financial/driver/${user.id}`
    }
  ]
  const companyData = [
    { label: 'Transfer', icon: CarIcon, onClick: 'transfer' },
    {
      label: 'Ganhos',
      icon: LineChart,
      onClick: `financial/company/${user.id}`
    }
  ]
  const hotelData = [
    { label: 'Transfer', icon: CarIcon, onClick: 'transfer' },
    {
      label: 'Ganhos',
      icon: LineChart,
      onClick: `financial/hostel/${user.id}`
    }
  ]
  const linksDecider = {
    ADMIN: adminData,
    USER: driversData,
    COMPANY_USER: driversData,
    COMPANY_ADMIN: companyData,
    HOTEL_USER: hotelData
  }
  const links = linksDecider[role].map((item) => (
    <Link key={item.label} to={item.onClick}>
      <LinksGroup
        onOptionClick={onOptionClick}
        {...item}
        activePage={activePage}
      />
    </Link>
  ))
  const userProfile =
    role === 'HOTEL_USER' || role === 'COMPANY_ADMIN' ? (
      <>
        <Menu withArrow>
          <Menu.Target>
            <UserButton
              image={
                user.profilePicture ? user?.profilePicture?.url : IconUserCircle
              }
              name={user?.name}
              email={user?.email}
              onClick={() => onOptionClick('settings')}
            />
          </Menu.Target>
          <Menu.Dropdown>
            {role === 'HOTEL_USER' ? (
              <>
                <Link to={`settings/hostel/${user.hostel.id}`}>
                  <Menu.Item>
                    <label>Perfil do hostel</label>
                  </Menu.Item>
                </Link>
                <Link to="settings">
                  <Menu.Item>
                    <label>Seu perfil</label>
                  </Menu.Item>
                </Link>
              </>
            ) : (
              <>
                <Link to={`settings/company/${user.company.id}`}>
                  <Menu.Item>
                    <label>Perfil da empresa</label>
                  </Menu.Item>
                </Link>
                <Link to="settings">
                  <Menu.Item>
                    <label>Seu perfil</label>
                  </Menu.Item>
                </Link>
              </>
            )}
          </Menu.Dropdown>
        </Menu>
      </>
    ) : (
      <Link to="settings">
        <UserButton
          image={
            user.profilePicture ? user?.profilePicture?.url : IconUserCircle
          }
          name={user?.name}
          email={user?.email}
          onClick={() => onOptionClick('settings')}
        />
      </Link>
    )

  return (
    <Styled.Container>
      <Navbar
        p="md"
        className={classes.navbar}
        style={{
          maxWidth: 255,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 'calc(100vh - 8rem)',
          position: 'fixed',
          left: 0,
          zIndex: 1
        }}
      >
        <Navbar.Section grow className={classes.links} component={ScrollArea}>
          <div className={classes.linksInner}>{links}</div>
        </Navbar.Section>

        <Navbar.Section className={classes.footer}>
          <Styled.LogoutWrapper>
            <Styled.LogOutButton onClick={() => signOut()}>
              Sair <LogOut width={24} height={24} color="#1D95BF" />
            </Styled.LogOutButton>
          </Styled.LogoutWrapper>
          <Link to="chat">
            <ChatButton />
          </Link>
          {userProfile}
        </Navbar.Section>
      </Navbar>
    </Styled.Container>
  )
}
