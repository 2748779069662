import { motion } from 'framer-motion'
import styled from 'styled-components'

export const OrangeContainer = styled.div`
  background: #27c7ff;
  width: 100%;
  height: 636px;
  top: 10vh;
  padding: 3.5%;

  display: flex;
  justify-content: center;
  gap: 75.28px;
  margin-bottom: 20rem;

  @media (max-width: 1346px) {
    gap: 0;
    position: relative;
    margin-top: 26rem;
  }

  @media only screen and (max-width: 580px) {
    height: 56rem;
  }
`

export const MapImage = styled(motion.img)`
  width: 800px;
  height: 800px;
  box-shadow: 0px 43px 61px -24px rgba(15, 22, 30, 0.02);
  filter: drop-shadow(0px 22px 24px rgba(0, 0, 0, 0.07))
    drop-shadow(0px 2px 19px rgba(15, 22, 30, 0.04));
  position: relative;
  bottom: 11.2rem;

  @media (max-width: 1346px) {
    bottom: 47rem;
    width: 100%;
    height: 100%;
    position: absolute;
  }

  @media only screen and (max-width: 580px) {
    bottom: 69vw;
  }

  @media only screen and (max-width: 460px) {
    bottom: calc(69vw + 10rem);
  }
`

export const Title = styled.h1`
  color: #0d0d0d;
  font-weight: 600;
  font-size: 48px;
  line-height: 120%;
  margin-bottom: 16px;
  margin-top: 50px;
`

export const SubTitle = styled.h4`
  color: #0d0d0d;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
`

export const Text = styled.p`
  color: #0d0d0d;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`

export const ScheduleButton = styled(motion.button)`
  background: #ffffff;
  cursor: pointer;
  width: 234px;
  height: 64px;
  border-radius: 16px;
  border: none;
  margin: 24px auto;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 24px 16px 32px;
  gap: 16px;

  transition: ease-in-out 300ms;

  &:hover {
    background-color: #27c7ff;
    color: white;
    border: solid 1px #fff;
    span {
      color: #fff;
    }

    path {
      stroke: #fff;
    }
  }
`

export const ContentWrapper = styled(motion.div)`
  width: 540px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: 1346px) {
    width: 100%;
    height: 100%;
    margin-top: 7rem;
  }

  @media only screen and (max-width: 580px) {
    gap: 0;

    ${Title} {
      font-size: 2.4rem;
    }

    ${SubTitle},
    ${Text} {
      font-size: 1.4rem;
    }

    ${ScheduleButton} {
      width: 100%;
    }
  }
`

export const ButtonLabel = styled.span`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  line-height: 120%;
  font-weight: 700;
  color: #0d0d0d;
  letter-spacing: 0.01em;
  text-transform: uppercase;
`
