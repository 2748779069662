import styled from 'styled-components'

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ opened }) => (opened ? 'block' : 'none')};
  z-index: 100;
`

export const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 340px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-end;
  z-index: 200;

  gap: 10px;
  border-radius: 22px;
  background: #fff;
  box-shadow: 0px 22px 24px 0px rgba(0, 0, 0, 0.07),
    0px -1px 16px 0px rgba(0, 0, 0, 0.05);
  display: ${({ opened }) => (opened ? 'flex' : 'none')};
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
`
export const ModalTitle = styled.p`
  color: var(--grey-1-grayscale, #404040);
  text-align: center;
  font-family: Neometric;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  letter-spacing: 0.14px;
  align-self: stretch;
`

export const ModalButton = styled.button`
  display: flex;
  height: 48px;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 16px;
  background: #27c7ff;
  color: white;
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const CloseButton = styled.button`
  width: 32px;
  height: 32px;
  background-color: transparent;
`
export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  gap: 3.2rem;

  .mantine-Radio-root {
    cursor: pointer;
  }

  .mantine-Radio-body {
    display: flex;
    align-items: center;
  }

  .mantine-Radio-inner {
    width: 2rem;
    height: 2rem;
    cursor: pointer;

    > input {
      width: 2rem;
      height: 2rem;
      background-color: #ffffff;
      border-color: #bfbfbf;
      cursor: pointer;
    }

    svg {
      width: 1.4rem;
      height: 1.4rem;

      top: calc(50% - 1.4rem / 2);
      left: calc(50% - 1.4rem / 2);

      color: #27c7ff;
      cursor: pointer;
    }
  }

  .mantine-Radio-label {
    margin: 0;
    line-height: 1;
    cursor: pointer;

    color: #404040;
    font-family: Neometric;
    font-size: 1.2rem;
    font-weight: 400;
  }
`
