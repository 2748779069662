import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import createContext from '../../../../../lib/createContext'
import { useContextSelector } from '../../../../../lib/useContextSelector'
import fetchMessage from '../../../../../services/chat'
import { useAuth } from '../../../../../contexts/useAuth'

import * as uuid from 'uuid'
import { Polling, pollingTimeout } from '../utils/polling'

let controller = new AbortController()
let abortSignal = controller.signal
export const PAGE_SIZE = 20

const ChatStoreContext = createContext()

const ChatStoreProvider = ({ children }) => {
  const { user } = useAuth()

  const [loading, setLoading] = useState(false)

  const page = useRef(0)
  const [userChat, setUserChat] = useState(null)
  const [messages, setMessages] = useState([])
  const [sendingMessages, setSendingMessages] = useState([])

  const openedChatId = userChat?.id
  const hasChatOpened = useMemo(() => openedChatId != null, [openedChatId])

  const mergedMessages = [...messages, ...sendingMessages]

  const loadMoreMessages = useCallback(() => {
    if (!userChat?.id) return

    page.current += 1

    Polling.messages({
      userId: userChat?.id,
      setData: setMessages,
      setLoading,
      page: page.current,
      disablePolling: true
    })
  }, [userChat?.id])

  const openChat = useCallback((user) => {
    closeChat()

    abortSignal = new AbortController().signal
    setUserChat(user)
  }, [])

  const closeChat = useCallback(() => {
    controller.abort()
    clearTimeout(pollingTimeout)

    page.current = 0
    setUserChat(null)

    setMessages([])
    setSendingMessages([])
  }, [])

  const addNewMessage = useCallback(
    async (message) => {
      const messageObj = {
        messageHtml: '',
        message,
        recipient: { ...userChat, address: undefined }
      }

      const tempId = uuid.v4()
      setSendingMessages((state) => [
        ...state,
        { ...messageObj, sender: user, tempId }
      ])

      const { data } = await fetchMessage.send(messageObj)
      setMessages((state) => [...state, data])
      setSendingMessages((state) => state?.filter((i) => i.tempId !== tempId))
    },
    [userChat]
  )

  useEffect(() => {
    if (userChat?.id && !abortSignal.aborted) {
      controller.abort()
      clearTimeout(pollingTimeout)
      setMessages([])

      controller = new AbortController()
      abortSignal = controller.signal

      Polling.messages({
        userId: userChat?.id,
        setData: setMessages,
        setLoading
      })
    }

    return () => {
      clearTimeout(pollingTimeout)
    }
  }, [userChat?.id])

  return (
    <ChatStoreContext.Provider
      value={{
        userChat,
        isGettingMessages: loading,

        openedChatId,
        hasChatOpened,

        openChat,
        closeChat,

        messages: mergedMessages,
        addNewMessage,
        loadMoreMessages
      }}
    >
      {children}
    </ChatStoreContext.Provider>
  )
}

export default ChatStoreProvider

export const useChatStore = (selector) => {
  return useContextSelector(ChatStoreContext, selector)
}
