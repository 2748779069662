import styled from 'styled-components'
export const Container = styled.div`
  .mantine-InputWrapper-root {
    width: 100%;
  }
  .mantine-Input-input {
    display: flex;
    padding: 12.19px 8px;
    width: 100%;
    border-radius: 6.095px;
    color: #a2a2a2;
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 54px;
  }

  .mantine-InputWrapper-label {
    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
  }
  @media (max-width: 768px) {
    & {
      width: 100%;
    }
  }
`
