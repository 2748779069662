import styled, { css, keyframes } from 'styled-components'
const scroll = keyframes`
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
`

export const Container = styled.div`
  max-width: 1120px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  height: 200px;
`

export const InnerContainer = styled.div`
  width: 2180px;
  overflow: hidden;
  position: relative;
  height: 200px;

  @media only screen and (max-width: 768px) {
    width: 1500px;
  }
`

export const Content = styled.div`
  width: 2180px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  animation: ${scroll} 15s linear infinite;
  height: 200px;

  @media only screen and (max-width: 768px) {
    width: 1500px;
  }
`

export const Header = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-start;
`

export const TextContainer = styled.div``

export const ImageGoogle = styled.img`
  margin-left: auto;
`

export const CardContainer = styled.div`
  ${({ theme }) => css`
    margin: 0 1.6rem;
    box-shadow: ${theme.shadows.minimal};
    height: 160px;
    min-width: 240px;
    border-radius: 6px;

    padding: 2rem;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 104px;
      height: 104px;
      object-fit: contain;
      margin: 0 auto;
    }

    @media only screen and (max-width: 768px) {
      box-shadow: none;

      min-width: 160px;
    }
  `}
`
export const CarrouselBtnWrapper = styled.div`
  position: absolute;
`

export const BtnNext = styled.img`
  cursor: pointer;
  left: 52rem;
  top: 2rem;
`
export const BtnPrev = styled.img`
  cursor: pointer;
  position: absolute;
  top: 2rem;
  right: 52rem;
`
