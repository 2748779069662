import styled, { css } from 'styled-components'
export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
  `}
`
export const Options = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 500px) {
  }
`

export const Option = styled.div`
  ${({ select, first, last }) => css`
    padding: 12.19px 8px;
    border-radius: 8px;
    border: 1px solid var(--grey-3-grayscale, #dee2e6);
    width: 32%;

    color: ${select
      ? 'var(--black-brand-primary, #222)'
      : 'var(--grey-2-grayscale, #808080)'};
    background-color: ${select ? '#27c7ff' : ''};
    border-radius: ${first ? '8px 0px 0px 8px' : last ? '0px 8px 8px 0px' : ''};
    font-family: Neometric;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-right: ${last ? '' : '0.5px solid #BFBFBF'};
  `}
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 23px;
`

export const OptionTitle = styled.h2`
  color: var(--yellow-brand-primary, #27c7ff);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16.8px; /* 120% */
  letter-spacing: 0.28px;
  text-transform: uppercase;
  @media (max-width: 500px) {
    font-size: 1.2rem;
  }
`

export const OptionValue = styled.strong`
  color: #000;
  font-family: Neometric;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 100% */
  @media (max-width: 500px) {
    font-size: 2rem;
  }
`

export const OptionUpdate = styled.label`
  color: #868e96;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.6px; /* 132.857% */
`

export const OptionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
