import styled from 'styled-components'

export const DashboardGridContainer = styled.div`
  display: grid;
  grid-template-columns: 250px calc(100vw - 250px);

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`
