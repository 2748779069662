import api from './api'

const fetchObj = {
  create: (payload) => {
    const res = api.post('/vehicleCategory', payload)

    return res
  },

  update: (payload) => {
    const res = api.put('/vehicleCategory', payload)

    return res
  },
  getAll: (payload) => {
    const res = api.get('/vehicleCategory', { params: payload })

    return res
  },
  getById: (id) => {
    const res = api.get(`/vehicleCategory/${id}`)

    return res
  },
  delete: (id) => {
    const res = api.delete(`/vehicleCategory/${id}`)

    return res
  }
}

export default fetchObj
