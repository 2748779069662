import { useJsApiLoader } from '@react-google-maps/api'
import { createContext, useContext, useState } from 'react'

export const MapsContext = createContext(null)

export const MapsContextProvider = ({ children }) => {
  const { isLoaded } = useJsApiLoader({
    // googleMapsApiKey: 'AIzaSyDoZH9mfcrCK1Uzn5S4_vVjYeNfXixMgKg', // LOCAL
    googleMapsApiKey: 'AIzaSyDoZH9mfcrCK1Uzn5S4_vVjYeNfXixMgKg', // PROD
    libraries: ['places']
  })

  return (
    <MapsContext.Provider
      value={{
        isLoaded
      }}
    >
      {children}
    </MapsContext.Provider>
  )
}

export function useMaps() {
  return useContext(MapsContext)
}
