import { Helmet } from 'react-helmet'
import { Router } from './routes'
import { useTranslation } from 'react-i18next'

function App() {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <meta name="description" content={t('seo.description')} />
      </Helmet>
      <Router />
    </>
  )
}

export default App
