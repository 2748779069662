import * as Styled from './styles'
import { ReactComponent as CloseIcon } from '../imgs/CloseIcon.svg'
import { useEffect, useState } from 'react'
import { ActionIcon, Group, Input, NumberInput, Radio } from '@mantine/core'
import Xis from '../imgs/xis.webp'
import { BadgeCheck, Ticket, CalendarDays, X, Car } from 'lucide-react'
import { useRef } from 'react'
import { DateTimePicker } from '@mantine/dates'
import MinusBtn from '../../../../../Checkout/components/Transfer/imgs/minus-btn.webp'
import PlusBtn from '../../../../../Checkout/components/Transfer/imgs/plus-btn.webp'
import { formatErrorMessage, generatePayload, validateData } from './utils'
import { couponMask, percentageMask } from './masks'
import fetchCoupons from '../../../../../../services/coupon'
import { toast } from 'react-toastify'
import { INITIAL_STATE, LIMIT_OPTIONS, PAYMENT_METHODS } from './constants'
import { AxiosError } from 'axios'

// SOMAR MAIS 3 HORAS NO VALID FROM E TO
// new Date("2023-11-29T10:00:00.000-06:00")
// se der bo tentar subtrair essas 3 horas

export const CreateCouponModal = ({
  onClose,
  onSuccess,
  initialData = undefined
}) => {
  const [data, setData] = useState({ ...INITIAL_STATE, ...(initialData || {}) })

  const [loading, setLoading] = useState(false)

  const overlayRef = useRef(null)
  const handlers = useRef()

  const submitFunction = initialData ? fetchCoupons.update : fetchCoupons.create

  const handleChange = (field, value) => {
    setData({ ...data, [field]: value })
  }

  const handleChangePaymentMethods = (value) => {
    const updatedPaymentMethods = data.paymentMethods.map((i) => i)
    const index = updatedPaymentMethods.indexOf(value)
    if (index < 0) updatedPaymentMethods.push(value)
    if (index >= 0) updatedPaymentMethods.splice(index, 1)
    handleChange('paymentMethods', updatedPaymentMethods)
  }

  const onClickOverlay = (e) => {
    if (overlayRef === null) return
    if (e.target !== overlayRef.current) return
    if (!onClose) return
    onClose()
  }

  const handleCreateCoupon = async () => {
    const payload = generatePayload(data)
    const toastId = toast.loading(
      `${initialData ? 'Editando' : 'Criando'} cupom...`
    )
    try {
      const response = await submitFunction(payload)
      toast.update(toastId, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: 'light',
        type: 'success',
        closeButton: true,
        isLoading: false,
        render: `Cupom ${initialData ? 'editado' : 'criado'} com sucesso!`
      })
      onSuccess(response.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
      const message =
        err instanceof AxiosError
          ? formatErrorMessage(err.response.data.message, !!initialData)
          : formatErrorMessage(err.message, !!initialData)
      toast.update(toastId, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: 'light',
        type: 'error',
        closeButton: true,
        isLoading: false,
        render: message
      })
      setLoading(false)
    }
  }

  useEffect(() => {
    const escapeModal = ({ key }) => {
      if (key === 'Escape' && onClose) onClose()
    }

    window.document.addEventListener('keydown', escapeModal)

    return () => {
      window.document.removeEventListener('keydown', escapeModal)
    }
  }, [onClose])

  return (
    <Styled.ModalOverlay ref={overlayRef} onClick={onClickOverlay}>
      <Styled.ModalContainer>
        <Styled.ModalHeader>
          <h2>Cupom de desconto</h2>
          <Styled.CloseButton>
            <CloseIcon
              onClick={() => {
                onClose()
              }}
            />
          </Styled.CloseButton>
        </Styled.ModalHeader>
        <Styled.ModalContent>
          <Input.Wrapper id="input-demo" label="Código promocional">
            <Input
              icon={<Ticket />}
              id="input-demo"
              placeholder="CUPOM20"
              size={'lg'}
              onChange={(e) => {
                const value = couponMask(e.target.value)
                handleChange('coupon', value)
              }}
              value={data.coupon}
              rightSection={
                data.coupon && (
                  <img
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleChange('coupon', '')}
                    width={22.55}
                    height={22.55}
                    src={Xis}
                  />
                )
              }
            />
          </Input.Wrapper>
          <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
            <Input.Wrapper
              id="input-demo"
              label="Porcentagem de desconto"
              style={{ flex: 1, width: '100%' }}
            >
              <Input
                icon={<BadgeCheck />}
                id="input-demo"
                placeholder="Valor de 1 a 100"
                size={'lg'}
                onChange={(e) => {
                  const value = percentageMask(e.target.value)
                  handleChange('discountPercentage', value)
                }}
                value={data.discountPercentage}
                rightSection={
                  data.discountPercentage && (
                    <img
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleChange('discountPercentage', '')}
                      width={22.55}
                      height={22.55}
                      src={Xis}
                    />
                  )
                }
              />
            </Input.Wrapper>
            <Input.Wrapper
              id="input-demo"
              label="Tipo de transfer"
              style={{ flex: 1, width: '100%' }}
            >
              <Input
                icon={<Car />}
                classNames={{ input: !data.appliedTo ? 'empty' : undefined }}
                id="input-demo"
                component="select"
                size={'lg'}
                onChange={(e) => handleChange('appliedTo', e.target.value)}
                value={data.appliedTo}
                rightSection={
                  data.appliedTo && (
                    <img
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleChange('appliedTo', '')}
                      width={22.55}
                      height={22.55}
                      src={Xis}
                    />
                  )
                }
              >
                <option value="">Selecione</option>
                <option value="ALL">Todos</option>
                <option value="BOOKING">Booking</option>
                <option value="TOUR">Tour</option>
              </Input>
            </Input.Wrapper>
          </div>
          <label style={{ display: 'block', marginTop: '1rem' }}>
            Período de validade
          </label>
          <Styled.DatePickersWrapper>
            <DateTimePicker
              icon={<CalendarDays />}
              clearable
              placeholder="00/00/00 - 00:00"
              value={data.validFrom}
              onChange={(e) => handleChange('validFrom', e)}
              minDate={new Date()}
              maxDate={data.validTo || undefined}
            />
            <DateTimePicker
              icon={<CalendarDays />}
              clearable
              placeholder="00/00/00 - 00:00"
              value={data.validTo}
              onChange={(e) => handleChange('validTo', e)}
              minDate={data.validFrom || new Date()}
            />
          </Styled.DatePickersWrapper>
          <label style={{ display: 'block', marginTop: '1rem' }}>
            Métodos de pagamento
          </label>
          <Styled.PaymentMethodsWrapper>
            {PAYMENT_METHODS.map(({ label, value }) => (
              <span
                key={value}
                onClick={() => handleChangePaymentMethods(value)}
                className={
                  data.paymentMethods.includes(value) ? 'active' : undefined
                }
              >
                {label}
                {data.paymentMethods.includes(value) && (
                  <button>
                    <X />
                  </button>
                )}
              </span>
            ))}
          </Styled.PaymentMethodsWrapper>
          <label className="solo">Limite de uso</label>
          <Styled.LimitOptionsWrapper>
            {LIMIT_OPTIONS.map(({ label, value }) => (
              <Radio
                key={value}
                label={label}
                value={value}
                checked={data.useLimitOption === value}
                onChange={(e) =>
                  handleChange('useLimitOption', e.currentTarget.value)
                }
              />
            ))}
          </Styled.LimitOptionsWrapper>
          {data.useLimitOption === 'CUSTOM' && (
            <>
              <label className="solo">Quantidade de uso</label>
              <Styled.NumberContainer>
                <Group
                  spacing={5}
                  style={{
                    justifyContent: 'space-between',
                    position: 'relative'
                  }}
                >
                  {data.useAmount > 0 && (
                    <ActionIcon
                      className="btn-minus"
                      size={60}
                      variant="transparent"
                      style={{
                        background: 'transparent',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: 'none'
                      }}
                      onClick={() => {
                        if (data.useAmount === 0) return
                        handleChange('useAmount', data.useAmount - 1)
                      }}
                      disabled={data.useAmount === 0}
                    >
                      <img src={MinusBtn} />
                    </ActionIcon>
                  )}

                  <NumberInput
                    hideControls
                    value={data.useAmount}
                    // onChange={(val) => setValue(val)}
                    handlersRef={handlers}
                    max={10}
                    min={0}
                    step={1}
                    styles={{ input: { width: '100%', textAlign: 'center' } }}
                    style={{ width: '100%' }}
                  />

                  <ActionIcon
                    className="btn-plus"
                    size={60}
                    variant="transparent"
                    style={{
                      background: 'transparent',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: 'none'
                    }}
                    onClick={() =>
                      handleChange('useAmount', data.useAmount + 1)
                    }
                  >
                    <img src={PlusBtn} />
                  </ActionIcon>
                </Group>
              </Styled.NumberContainer>
            </>
          )}
        </Styled.ModalContent>
        <Styled.ModalButton
          disabled={!validateData(data) || loading}
          onClick={handleCreateCoupon}
        >
          SALVAR
        </Styled.ModalButton>
      </Styled.ModalContainer>
    </Styled.ModalOverlay>
  )
}
