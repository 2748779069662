import React from 'react'

import { Input } from '@mantine/core'
import { ReactComponent as ChevronDown } from '../../imgs/chevron-down.svg'

export function StatusFilterInput({ selected, options, onChange }) {
  return (
    <Input
      id="input-select"
      component="select"
      rightSection={<ChevronDown />}
      pointer
      mt="md"
      onChange={(e) => onChange(e.target.value)}
      value={selected}
    >
      {options.map(([value, label]) => (
        <option key={value ?? 'DEFAULT'} value={value}>
          {label}
        </option>
      ))}
    </Input>
  )
}
