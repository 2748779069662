import * as Styled from './styles'
import { ReactComponent as CloseIcon } from '../imgs/CloseIcon.svg'
import { Checkbox } from '@mantine/core'
import { forwardRef, useState } from 'react'

const Component = ({ onClose, onSuccess, clearFilter, initialFilter }, ref) => {
  const [selectedOption, setSelectedOption] = useState(initialFilter)

  return (
    <Styled.Container ref={ref}>
      <Styled.Header>
        <h2>Filtro</h2>
        <Styled.CloseButton>
          <CloseIcon
            onClick={() => {
              onClose()
            }}
          />
        </Styled.CloseButton>
      </Styled.Header>
      <Styled.Content>
        <strong>Status</strong>
        <ul>
          <li>
            <Checkbox
              size="lg"
              checked={selectedOption === 'active'}
              color="orange-primary"
              onChange={() =>
                setSelectedOption((oldState) =>
                  oldState === 'active' ? '' : 'active'
                )
              }
              label="Ativo"
            />
          </li>
          <li>
            <Checkbox
              size="lg"
              checked={selectedOption === 'inactive'}
              color="orange-primary"
              onChange={() =>
                setSelectedOption((oldState) =>
                  oldState === 'inactive' ? '' : 'inactive'
                )
              }
              label="Inativo"
            />
          </li>
        </ul>
      </Styled.Content>
      <Styled.Button outlined onClick={clearFilter}>
        LIMPAR FILTRO
      </Styled.Button>
      <Styled.Button onClick={() => onSuccess(selectedOption)}>
        APLICAR FILTRO
      </Styled.Button>
    </Styled.Container>
  )
}

export const FilterPopup = forwardRef(Component)
