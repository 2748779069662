import styled from 'styled-components'

export const ContainerPayment = styled.div`
  margin-bottom: 150px;

  @media (max-width: 768px) {
    margin-bottom: 64px;
  }
`

export const Container = styled.div`
  width: 70%;
  height: 235.74px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 64px;
  gap: 48px;
  background: #fff;
  border: 1px solid rgba(39, 199, 255, 0.2);
  box-shadow: 0px 22px 24px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  position: relative;
  margin: 0 auto;

  @media only screen and (max-width: 768px) {
    box-shadow: none;
    border: none;

    width: 96%;
    height: auto;
    padding: 0;
  }
`

export const Title = styled.h1`
  color: var(--black-brand-primary, #222);
  font-family: Neometric;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: max-content;

  @media (max-width: 768px) {
    color: var(--black-brand-primary, #0d0d0d);
    text-align: center;
    font-family: Neometric;
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* max-width: 22rem; */
  }
`

export const ImagesWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  @media only screen and (max-width: 768px) {
    justify-content: center;
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 590px) {
    gap: 0.4rem;

    svg {
      width: 70.87px;
      height: 40.74px;
    }
  }
`
