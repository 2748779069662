import busImg from '../../imgs/vehicles/bus-2.svg'
import sedanEconomicImg from '../../imgs/vehicles/sedan-economic.svg'
import sedanExecutiveImg from '../../imgs/vehicles/sedan-executive.svg'
import vanExecutiveImg from '../../imgs/vehicles/van-executive.svg'

export const vehicles = (t) => [
  {
    imgSrc: sedanEconomicImg,
    name: t('events.economySedan'),
    value: 'Economico'
  },
  {
    imgSrc: sedanExecutiveImg,
    name: t('events.executiveSedan'),
    value: 'SedanExecutivo'
  },
  {
    imgSrc: vanExecutiveImg,
    name: t('events.executiveVan'),
    value: 'VanExecutiva'
  },
  {
    imgSrc: busImg,
    name: t('events.bus'),
    value: 'Autocarro'
  }
]
