export const moneyMask = (inputValue) => {
  const value =
    inputValue.replace('.', '').replace(',', '').replace(/\D/g, '') || 0

  const formattedValue = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2
  }).format(parseFloat(value) / 100)

  return {
    value: formattedValue,
    formattedValue: `€ ${formattedValue}`
  }
}
