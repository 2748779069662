import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  width: 100%;
  align-items: flex-start;
  height: 95vh;
  overflow: scroll;
`
export const Title = styled.h1`
  font-family: Neometric;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0.01em;
  text-align: left;
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  button {
    /* width: 100%; */
    height: 48px;
    padding: 8px 16px 8px 8px;
    border-radius: 32px;
    border: 1px;
    gap: 8px;
    border: 1px solid #e6e6e6;
    font-family: Neometric;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    color: #404040;
  }

  div {
    justify-content: flex-start;
  }
`

export const InputsContent = styled.div`
  .mantine-Input-input:focus {
    border-color: #27c7ff;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`
export const EditButton = styled.button`
  display: flex;
  width: 220px;
  height: 48px;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  border: 2px solid var(--yellow-brand-primary, #27c7ff);
  color: var(--yellow-brand-primary, #27c7ff);
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;
  text-transform: uppercase;
  background-color: transparent;
  transition: ease-in-out 300ms;

  &:hover {
    color: #fff;
    background-color: #27c7ff;
    border: 2px solid var(--yellow-brand-primary, #27c7ff);
  }
`
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    align-items: center;
  }
  @media (max-width: 320px) {
    flex-direction: column;
    gap: 16px;
  }
`
