import styled, { css } from 'styled-components'
export const Container = styled.div`
  ${({ theme }) => css`
    .mantine-Table-root {
      th {
        color: var(--black-brand-primary, #222);
        font-family: Neometric;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: 130%; /* 20.8px */
        letter-spacing: 0.16px;
      }
    }

    .mantine-Text-root {
      color: var(--black-brand-primary, #222);
      font-family: Neometric;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 20.8px */
      letter-spacing: 0.16px;
    }

    .selected {
      border-radius: 8px;
      border-bottom: 1px solid var(--grey-4-grayscale, #f7f7f7);
      background: var(--yellow-3-support, #E9F9FF);
    }
    td {
      padding: 1.75rem 0.625rem !important;
    }

    .mobile {
      @media (max-width: 425px) {
        display: none;
      }
    }
  `}
`
