import { MediaQuery } from '@mantine/core'

import { ReactComponent as Direction } from './imgs/direction.svg'
import { ReactComponent as Pin } from './imgs/mapPin.svg'
import { ReactComponent as Car } from './imgs/car.svg'
import { ReactComponent as Surf } from './imgs/surf.svg'
import { ReactComponent as Pet } from './imgs/pet.svg'
import { ReactComponent as ChildChair } from './imgs/child-chair.svg'

import {
  formatDayOfWeek,
  formatMonth,
  formatTime
} from '../../../../../../utils/formattedDate'
import { Backpack, Briefcase, Luggage, Users2 } from 'lucide-react'
import { LowCostBadge } from '../../../TransferAdm/components/LowCostBadge'

import * as Styled from './styles'

export const DetailsTransfer = ({ transfer }) => {
  return (
    <>
      <Styled.CardContainer>
        <Styled.Container key={transfer?.id}>
          <Styled.Wrapper>
            <Styled.TitleWrapper>
              <Styled.TransferTitle>
                <Car />
                Detalhes do transfer
              </Styled.TransferTitle>

              {transfer?.lowCost && <LowCostBadge />}
            </Styled.TitleWrapper>

            <Styled.TimeAndStatusWrapper>
              <Styled.StatusCard>
                {formatDayOfWeek(transfer?.transferDate)},{' '}
                {new Date(transfer?.transferDate)
                  .getDate()
                  .toString()
                  .padStart(2, '0')}
                /{formatMonth(transfer?.transferDate)} -{' '}
                {formatTime(transfer?.transferDate)}
              </Styled.StatusCard>
            </Styled.TimeAndStatusWrapper>

            <Styled.ValuesWrapper>
              <MediaQuery
                query="(max-width: 1024px)"
                styles={{ display: 'none' }}
              >
                <Styled.PriceTitle>
                  <Styled.DepartureTitle>Distância</Styled.DepartureTitle>
                  {transfer?.distanceInKm ?? 0}km
                </Styled.PriceTitle>
              </MediaQuery>
            </Styled.ValuesWrapper>
            <Styled.InfoWrapper>
              <Styled.AddressWrapper>
                <Styled.DepartureWrapper>
                  <Styled.DepartureTitle>
                    <Pin /> Partida
                  </Styled.DepartureTitle>
                  <Styled.DepartureDescription>
                    {transfer?.startingPlace}
                  </Styled.DepartureDescription>
                </Styled.DepartureWrapper>
                <Styled.DirectionButton>
                  <Direction />
                </Styled.DirectionButton>
                <Styled.DepartureWrapper>
                  <Styled.DepartureTitle>
                    <Pin /> Chegada
                  </Styled.DepartureTitle>
                  <Styled.DepartureDescription>
                    {transfer?.arrivalPlace}
                  </Styled.DepartureDescription>
                </Styled.DepartureWrapper>
              </Styled.AddressWrapper>
              {transfer?.bookingStops.map((item) => (
                <Styled.DepartureWrapper key={item.id} stops>
                  <Styled.DepartureTitle>
                    <Pin /> Parada
                  </Styled.DepartureTitle>
                  <Styled.DepartureDescription>
                    {item.place}
                  </Styled.DepartureDescription>
                </Styled.DepartureWrapper>
              ))}
              {transfer?.passengerStops.map((item) => (
                <Styled.DepartureWrapper key={item.id} stops>
                  <Styled.DepartureTitle>
                    <Pin /> Parada
                  </Styled.DepartureTitle>
                  <Styled.DepartureDescription>
                    {item.place}
                  </Styled.DepartureDescription>
                </Styled.DepartureWrapper>
              ))}
              <Styled.DistanceMobile>
                {transfer?.distanceInKm}km
              </Styled.DistanceMobile>
              <Styled.IconsAndNumbersWrapper>
                {transfer?.backpacks > 0 && (
                  <Styled.IconAndNumber>
                    <Styled.IconsBackground>
                      <Backpack color="#27c7ff" />
                    </Styled.IconsBackground>
                    <span>
                      <strong>{transfer?.backpacks}</strong> Mochila
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer?.checkedLuggages > 0 && (
                  <Styled.IconAndNumber>
                    <Styled.IconsBackground>
                      <Luggage color="#27c7ff" />
                    </Styled.IconsBackground>
                    <span>
                      <strong>{transfer?.checkedLuggages}</strong> Bagagem
                      despachada
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer?.handLuggages > 0 && (
                  <Styled.IconAndNumber>
                    <Styled.IconsBackground>
                      <Briefcase color="#27c7ff" />
                    </Styled.IconsBackground>
                    <span>
                      <strong>{transfer?.handLuggages}</strong> Bagagem de mão
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer?.numberOfPassengers > 0 && (
                  <Styled.IconAndNumber>
                    <Styled.IconsBackground>
                      <Users2 color="#27c7ff" />
                    </Styled.IconsBackground>
                    <span>
                      <strong>{transfer?.numberOfPassengers} </strong>
                      Passageiro(s)
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer?.surfboards > 0 && (
                  <Styled.IconAndNumber>
                    <Surf />
                    <span>
                      <strong>{transfer?.surfboards} </strong>Prancha(s) de surf
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer?.babySeats > 0 && (
                  <Styled.IconAndNumber>
                    <ChildChair />
                    <span>
                      <strong>{transfer?.babySeats} </strong>Cadeira de bebê
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer?.childSeats > 0 && (
                  <Styled.IconAndNumber>
                    <ChildChair />
                    <span>
                      <strong>{transfer?.childSeats} </strong>Cadeira de bebê
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer?.boosterSeats > 0 && (
                  <Styled.IconAndNumber>
                    <ChildChair />
                    <span>
                      <strong>{transfer?.boosterSeats} </strong>Cadeira de bebê
                    </span>
                  </Styled.IconAndNumber>
                )}
                {transfer?.animals > 0 && (
                  <Styled.IconAndNumber>
                    <Pet />
                    <span>
                      <strong>{transfer?.animals}</strong> Pet
                    </span>
                  </Styled.IconAndNumber>
                )}
              </Styled.IconsAndNumbersWrapper>
            </Styled.InfoWrapper>
          </Styled.Wrapper>
          {transfer?.bookingStops.length > 0 && (
            <>
              <Styled.TransferTitle
                style={{
                  marginBottom: '8px'
                }}
              >
                Paradas
              </Styled.TransferTitle>
              {transfer?.bookingStops.map((item) => (
                <div
                  key={item.id}
                  style={{
                    display: 'flex',
                    gap: '16px',
                    marginBottom: '8px'
                  }}
                >
                  <Styled.DepartureWrapper>
                    <Styled.DepartureTitle>Local</Styled.DepartureTitle>
                    <Styled.DepartureDescription>
                      {item?.stop?.place}
                    </Styled.DepartureDescription>
                  </Styled.DepartureWrapper>
                  <Styled.DepartureWrapper>
                    <Styled.DepartureTitle>Duração</Styled.DepartureTitle>
                    <Styled.DepartureDescription>
                      {item?.stop?.timeInMinutes} minuto(s)
                    </Styled.DepartureDescription>
                  </Styled.DepartureWrapper>
                </div>
              ))}
            </>
          )}
        </Styled.Container>
      </Styled.CardContainer>
    </>
  )
}
