import { useState } from 'react'
import { Banknote } from 'lucide-react'
import { valueMask } from './masks'
import { Input } from '@mantine/core'
import Xis from './imgs/xis.webp'
import * as Styled from '../styles'

export const CompanyConfig = ({ config, handleChangeConfig }) => {
  return (
    <Styled.InputsWrapper>
      <p>Insira um valor que represente a porcentagem de IVA</p>
      <div>
        <Input.Wrapper id="input-demo" label="Porcentagem de IVA">
          <Input
            icon={<Banknote />}
            id="input-demo"
            placeholder="10%"
            size={'lg'}
            onChange={(e) => {
              const value = valueMask(e.target.value, true)
              handleChangeConfig('defaultIvaPercentageForCompanies', value)
            }}
            value={config.defaultIvaPercentageForCompanies}
            rightSection={
              !!config.defaultIvaPercentageForCompanies && (
                <img
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    handleChangeConfig('defaultIvaPercentageForCompanies', 0)
                  }
                  width={22.55}
                  height={22.55}
                  src={Xis}
                />
              )
            }
          />
        </Input.Wrapper>
      </div>
    </Styled.InputsWrapper>
  )
}
