import { LoadingOverlay } from '@mantine/core'
import * as Styled from './styles'
import { useEffect } from 'react'
import fetchBooking from '../../../../../../services/booking'
import { useState } from 'react'

import { LowCostCard } from './LowCostCard'
import { useSanitizeSearch } from '../../../../../../hooks/useSanitizeSearch'

const { getAllBooking } = fetchBooking

const DEFAULT_PAGE_SIZE = 10

export const LowCostList = ({ search, status }) => {
  const [transfer, setTransfer] = useState([])
  const [loading, setLoading] = useState(true)

  const sanitizedSearch = useSanitizeSearch(search)

  useEffect(() => {
    const payload = {
      size: DEFAULT_PAGE_SIZE,
      status: status,
      lowCost: true,
      ...sanitizedSearch
    }

    getAllBooking(payload)
      .then((res) => setTransfer(res.data.content))
      .catch((err) => console.error(err))
      .finally(() => setLoading(false))
  }, [search, status])

  return (
    <Styled.CardContainer>
      <LoadingOverlay overlayOpacity={0.5} visible={loading} />
      {transfer.length > 0 &&
        transfer?.map((transfer) => (
          <LowCostCard key={transfer.id} transfer={transfer} />
        ))}
    </Styled.CardContainer>
  )
}
