import { ContactInfo } from '../ContactInfo'
import { Schedule } from '../Schedule'
import { Wrapper } from './styles'

export const LateralCards = () => {
  return (
    <Wrapper>
      <Schedule />
      <ContactInfo />
    </Wrapper>
  )
}
