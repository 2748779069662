import { useTranslation } from 'react-i18next'
import * as Styled from './styles'
import CheckedCircle from './imgs/check-circle.webp'
import { useTransferContext } from '../../../../contexts/TransferContext'

export const Checked = () => {
  const { t } = useTranslation()

  const { going } = useTransferContext()

  return (
    <Styled.Container>
      <img src={CheckedCircle} alt="Checked Circle" />
      <Styled.ConfirmationMessage>
        {['MbWay', 'MbMulti', 'Card'].includes(going.paymentMethod)
          ? 'Pagamento em Processo'
          : t('successMessage')}
      </Styled.ConfirmationMessage>
      <Styled.ImportantContainer>
        <Styled.OrangeTag>
          <span>{t('importantText')}</span>
        </Styled.OrangeTag>
        <div>
          <p>
            {t('thankYou')} <span>{t('summaryMessage')}</span>{' '}
            {t('availabilityMessage')}
          </p>
          <p>{t('tipMessage')}</p>
        </div>
      </Styled.ImportantContainer>
    </Styled.Container>
  )
}
