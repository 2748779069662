import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: clamp(16px, 0.833vw, 0.833vw);
  background: #fff;
  margin-bottom: 150px;

  h1 {
    font-weight: 700;
    font-size: 32px;
    font-family: Neometric;
    font-style: normal;
    line-height: normal;
    text-align: center;
    color: #0d0d0d;
    z-index: 999;
  }

  @media (max-width: 768px) {
    margin-bottom: 64px;

    h1 {
      color: var(--black-brand-primary, #222);
      font-family: Neometric;
      font-size: 2.2rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
`

export const TextWrapper = styled.div`
  height: 100%;
  flex-shrink: 0;
  flex: 1;
  width: clamp(240px, 100%, 100%);
  display: flex;
  background: #ffffff;
  padding: clamp(16px, 0.833vw, 0.833vw);
  gap: clamp(16px, 0.833vw, 0.833vw);
  box-shadow: 28.3163px 29.5474px 96.0292px rgba(0, 0, 0, 0.25);
  border-radius: clamp(24px, 1.25vw, 1.25vw);
  z-index: 4;

  p {
    font-family: Poppins, 'Poppins';
    font-weight: 400;
    font-size: 1.4rem;
    color: #222222;

    small {
      font-family: 'Yellowtail', Yellowtail;
      font-weight: bold;
      font-size: inherit;
    }
  }

  div {
    all: unset;
    display: flex;
  }

  .up {
    align-items: flex-start;
  }

  .down {
    align-items: flex-end;
  }
`

export const ImagesWrapper = styled.div`
  z-index: 4;
  display: flex;
  img {
    width: clamp(200px, 44vw, 44vw);
    margin-bottom: -20vh;

    @media (max-width: 1040px) {
      margin-bottom: -10vh;
    }
  }
`

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: clamp(16px, 0.833vw, 0.833vw);
  padding: 0 10vw;

  @media (max-width: 1040px) {
    flex-direction: column-reverse;

    ${TextWrapper} {
      margin-top: -14vw;
    }

    ${ImagesWrapper} img {
      width: 100%;
    }
  }
`
