import { useMemo } from 'react'
import * as Styled from './styles'
import { useAuth } from '../../../../contexts/useAuth'
import { TransferAdm } from '../../pages/TransferAdm'
import { Settings } from '../../pages/Settings'
import { Transfer } from '../../pages/Transfer'
import { SettingsAdm } from '../../pages/SettingsAdm'
import { TransferDetails } from '../../pages/TransferDetails'
import { TransferHostel } from '../../pages/TransferHostel'
import { LowCostPage } from '../../pages/LowCost'

export const ContentDashboard = ({ activePage }) => {
  const { ROLE } = useAuth()

  return (
    <>
      {ROLE === 'ADMIN' && (
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          {activePage === 'transfer' && <TransferAdm />}
        </div>
      )}

      {ROLE === 'HOTEL_USER' && (
        <div
          style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
          {activePage === 'transfer' && <TransferHostel />}
        </div>
      )}

      {ROLE === 'USER' && (
        <div style={{ width: '100%' }}>
          {activePage === 'transfer' && <Transfer />}
        </div>
      )}
    </>
  )
}
