import * as Styled from './styles'
import { Card } from './components/Card'

export const Hero = () => {
  return (
    <Styled.Container>
      <Card />
      {/* <Styled.Background /> */}
      {/* <Styled.Background src={background1} /> */}
      {/* <Styled.Background src={background2} /> */}
      {/* <Styled.Background src={background3} /> */}
    </Styled.Container>
  )
}
