import { useEffect } from 'react'
import fetchBooking from '../../../../services/booking'
import { useState } from 'react'
import debounce from '../../../../utils/debounce'
import { toast } from 'react-toastify'

const PAGE_SIZE = 10

export const BookingTypes = {
  AvailablesToLowCost: Symbol('lowCostAvailable'),
  LowCost: Symbol('lowCost')
}

export function useFetchBooking() {
  const [data, setData] = useState()

  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState()
  const [search, setSearch] = useState({
    page: 0,
    lowCostAvailable: true,
    lowCost: false
  })

  const currentPage = search.page + 1

  const onSearch = (value, name) => {
    debounce(
      () => setSearch((prevSearch) => ({ ...prevSearch, [name]: value })),
      500
    )
  }

  const onChangePage = (value, name) => {
    console.log('value, name', value, name)
    setSearch((prevSearch) => ({ ...prevSearch, [name]: value }))
  }

  const changeBookingType = (type) => {
    if (type === BookingTypes.AvailablesToLowCost) {
      setSearch((prevSearch) => ({
        ...prevSearch,
        lowCostAvailable: true,
        lowCost: false
      }))
    }

    if (type === BookingTypes.LowCost) {
      setSearch((prevSearch) => ({
        ...prevSearch,
        lowCostAvailable: true,
        lowCost: true
      }))
    }
  }

  async function createLowCosts(ids) {
    return toast.promise(fetchBooking.createLowCosts({ ids }), {
      pending: 'Habilitando Low Cost(s)...',
      success: 'Low Cost(s) habilitado(s) com sucesso!',
      error: 'Ops! Ocorreu um erro ao habilitar Low Cost(s)'
    })
  }

  async function disableLowCosts(ids) {
    return toast.promise(fetchBooking.disableLowCosts({ ids }), {
      pending: 'Desabilitando Low Cost(s)...',
      success: 'Low Cost(s) desabilitado(s) com sucesso!',
      error: 'Ops! Ocorreu um erro ao desabilitar Low Cost(s)'
    })
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)

        const params = Object.entries(search).reduce((acc, [key, value]) => {
          if (value !== undefined && value !== null && value !== '') {
            acc[key] = value
          }
          return acc
        }, {})

        const result = await fetchBooking.getAllBooking(params)

        if (result) {
          const formattedData =
            result?.data?.content?.length > 0 ? result.data.content : []

          setData(formattedData)

          const totalPages = Math.ceil(result.data.totalElements / PAGE_SIZE)
          setTotal(totalPages)
        }
      } catch (err) {
        console.error(err, 'err')
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [search])

  return {
    bookings: data,
    totalPages: total,
    loading,
    onSearch,
    currentPage,
    onChangePage,
    changeBookingType,
    createLowCosts,
    disableLowCosts
  }
}
