import api from './api'

const fetchObj = {
  createBooking: (payload) => {
    const res = api.post('/booking', payload)

    return res
  },

  createBookingLowCost: (payload) => {
    const res = api.put('/booking/book-low-cost', payload)

    return res
  },

  passengerCancel: (payload) => {
    const res = api.post('/booking/passenger-cancel', payload)

    return res
  },

  updateBooking: (payload) => {
    const res = api.put('/booking/choose-driver', payload)

    return res
  },
  acceptedBooking: (id, driverId, driverCommission) => {
    const res = api.patch(
      `/booking/accept/${id}/${driverId}/${driverCommission}`
    )

    return res
  },
  inviteDrivers: (payload) => {
    const res = api.post(`/booking/invite-drivers`, payload)

    return res
  },
  getAllBooking: (payload) => {
    const res = api.get('/booking', { params: { ...payload, deleted: false } })

    return res
  },

  getAvailableLowCost: (params) => {
    const res = api.get('/booking/available-low-costs', { params })

    return res
  },
  getBookingOpen: (id) => {
    const res = api.get(`/booking/open/${id}`)

    return res
  },
  getBookingCounters: (params) => {
    const res = api.get('/booking/counters', { params })

    return res
  },
  getTabCounters: (params) => {
    const res = api.get('/booking/tabs-counters', { params })

    return res
  },
  getBookingDashboardCounters: (params) => {
    const res = api.get('/booking/dashboard-counters', { params })

    return res
  },
  getBookingOriginCounters: (params) => {
    const res = api.get('/booking/origin-counters', { params })

    return res
  },
  getBookingPaymentCounters: (params) => {
    const res = api.get('/booking/payment-counters', { params })

    return res
  },
  getBookingYearlyCounters: (params) => {
    const res = api.get('/booking/yearly-data', { params })

    return res
  },
  getBookingFinancialCounters: (params) => {
    const res = api.get('/booking/financial-counters', { params })

    return res
  },
  getBookingId: (id) => {
    const res = api.get(`/booking/${id}`)

    return res
  },
  cancelTransfer: (id, payload) => {
    const res = api.patch(`/booking/cancel/${id}`, payload)

    return res
  },
  reportTransfer: (id, payload) => {
    const res = api.patch(`/booking/report/${id}`, payload)

    return res
  },
  finishTransfer: (id) => {
    const res = api.patch(`/booking/finish/${id}`)

    return res
  },
  startTransfer: (id) => {
    const res = api.patch(`/booking/start/${id}`)

    return res
  },
  getByLatLng: (payload) => {
    const res = api.get('/booking/find-nearests-pending', { params: payload })

    return res
  },
  remove: (id) => api.delete(`/booking/${id}`),
  createLowCosts: (payload) =>
    api.post('/booking/low-cost', { ...payload, lowCost: true }),
  disableLowCosts: (payload) =>
    api.post('/booking/low-cost', { ...payload, lowCost: false })
}

export default fetchObj
