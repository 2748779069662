import styled, { css } from 'styled-components'
export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
`

export const TransferContainer = styled.div`
  ${({ theme }) => css`
    position: static;
    display: flex;
    /* justify-content: space-between; */
    gap: 3.2rem;
    background-color: ${theme.colors.white};
    border-radius: 22px;
    padding: 2rem 1.6rem;
    align-items: center;
    z-index: 15;
    width: 672.97px;
    height: 21rem;
    box-shadow: 0px 22px 24px 0px rgba(0, 0, 0, 0.07);

    @media only screen and (max-width: 768px) {
      gap: 1.6rem;
      bottom: 1.4rem;
      width: 80vw;
      border-radius: 1.6rem;
      align-items: flex-start;

      > div > img {
        width: 12.5rem;
        /* height: 15.1rem; */
      }
    }

    @media only screen and (max-width: 634px) {
      padding: 1.6rem;
      width: 90%;

      height: auto;
      flex-direction: column;

      > div:first-of-type {
        width: 100%;
        border-radius: 0.8rem;
        position: relative;
        overflow: hidden;

        > img {
          width: 100%;
          height: 12rem;
          object-fit: cover;
          object-position: top left;
        }

        h3 {
          font-family: Neometric;
          font-weight: 600;
          font-size: 1.4rem;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 1.6rem;
          color: #ffffff;
        }
      }
    }
  `}
`

export const OneTransfer = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;

  /* min-width: 21rem; */

  .mantine-DateTimePicker-input {
    border: 0;
  }
  .mantine-DateTimePicker-input[data-with-icon] {
    padding: 0;
  }
  .mantine-DateTimePicker-icon {
    padding-right: 0;
    position: relative;
  }
  .mantine-InputWrapper-label h2,
  .mantine-DateTimePicker-label {
    font-family: 'Neometric';
    font-style: normal;
    line-height: normal;

    color: #404040;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: normal;
  }

  .mantine-InputWrapper-root {
    flex: 1;
  }

  .mantine-DateTimePicker-wrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .mantine-DateTimePicker-root {
    width: 100%;
  }

  .wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;

    .mantine-InputWrapper-label {
      padding: 0;
      line-height: 22px;
    }

    .mantine-DateTimePicker-input {
      padding-left: 0;
    }
  }

  p.warn {
    font-family: Roboto;
    color: #f8294e;
    font-size: 1.2rem;
    margin-left: 2.4rem;
  }
`
export const OneTransferContainer = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;

  p.warn {
    font-family: Roboto;
    color: #f8294e;
    font-size: 1.2rem;
    margin-left: 2.4rem;
  }
`

export const BtnContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* gap: 1rem; */
  height: 100%;

  > div {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    p {
      font-family: Neometric;
      color: #27c7ff;
      font-size: 1.4rem;
      font-weight: 500;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 3;
      line-clamp: 3;
    }

    strong {
      color: #222222;
      font-family: Neometric;
      font-size: 2rem;
      font-weight: 600;
    }
  }

  @media only screen and (max-width: 634px) {
    width: 100%;
  }
`

export const BtnPrimary = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    background-color: ${theme.colors.primary};
    width: 100%;
    height: 8rem;
    /* padding: 30px 40px; */
    border-radius: 1.6rem;
    font-size: 1.6rem;
    font-weight: 600;
    box-shadow: ${theme.shadows.minimal};

    :disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }

    @media only screen and (max-width: 634px) {
      font-size: 1.4rem;
      text-transform: uppercase;
      line-height: 1;

      height: 4.8rem;
      padding: 1.4rem;
      border-radius: 0.8rem;

      max-width: 30rem;
      margin: 2rem auto 0;
    }
  `}
`
export const BtnSecondary = styled.button`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
    background-color: ${theme.colors.white};
    padding: 30px 30px;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 600;
    border: 0.1px solid rgba(56, 40, 14, 0.3);
    box-shadow: ${theme.shadows.minimal};
  `}
`

export const OptionsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    z-index: 10;
    position: absolute;
    bottom: 8.8rem;
    align-items: center;

    .mantine-SegmentedControl-root {
      display: flex;
      border-radius: 3px 3px 0 0;
      z-index: 10;
      align-items: center;
      padding: 0;
    }

    .mantine-SegmentedControl-label {
      padding: 8px 16px;
      color: white;
      font-size: 12px;
    }

    .mantine-SegmentedControl-control {
      background-color: ${theme.colors.primary};
    }

    .mantine-SegmentedControl-label[data-active] {
      background-color: white;
      transition: ease-in-out 300ms;
      color: black;
    }
  `}
`

export const SelectedOption = styled.h2`
  padding: 0 1rem;
  color: white;
`

export const PrimaryOption = styled.h2`
  padding: 0 1rem;
  background-color: white;
  border-radius: 3px 0 0 0;
  height: 100%;
  display: flex;
  align-items: center;
`

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;

  border-radius: 0.8rem;
  position: relative;
  overflow: hidden;

  > img {
    width: 100%;
    height: 19rem;
    object-fit: cover;
    object-position: center;
  }
`

export const LeftTitle = styled.p`
  font-family: 'Neometric';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: #8a8a8a;
`
