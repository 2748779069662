import * as Styled from './styles'
import { useTranslation } from 'react-i18next'
import { MapPin, Users2, ChevronLeft, ChevronRight } from 'lucide-react'
import { useEffect, useState } from 'react'
import fetchBooking from '../../../../services/booking'
import { formatCurrency } from '../../../../utils/formatCurrency'
import { formatDateAndTime } from '../../../../utils/formattedDate'
import { Carousel } from '@mantine/carousel'
import { useTransferContext } from '../../../../contexts/TransferContext'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ClipLoader } from 'react-spinners'
import { useCallback } from 'react'

export const LowCost = () => {
  const { t } = useTranslation()
  const { setLowCost } = useTransferContext()
  const [lowCostMap, setLowCostMap] = useState([])
  const [selectedItem, setSelectedItem] = useState(null)
  const [loading, setLoading] = useState(false)
  const [emblaApi, setEmblaApi] = useState(null)
  const [canScrollNext, setCanScrollNext] = useState(false)
  const [canScrollPrev, setCanScrollPrev] = useState(false)

  const navigate = useNavigate()

  const onSelect = useCallback((api) => {
    if (!api) return

    setCanScrollNext(api.canScrollNext())
    setCanScrollPrev(api.canScrollPrev())
  }, [])

  const scrollNext = useCallback(() => emblaApi?.scrollNext(), [emblaApi])

  const scrollPrev = useCallback(() => emblaApi?.scrollPrev(), [emblaApi])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)

    emblaApi.on('reInit', onSelect)
    emblaApi.on('select', onSelect)
  }, [emblaApi, onSelect])

  useEffect(() => {
    setLoading(true)
    const params = {
      size: 999
    }
    fetchBooking.getAvailableLowCost(params).then((res) => {
      setLowCostMap(res.data.content)
      setLoading(false)
    })
  }, [])

  console.log({ lowCostMap })
  console.log({ emblaApi })

  return (
    <>
      {loading ? (
        <div
          style={{
            width: '100%',
            height: '20rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <ClipLoader size={50} color="#27c7ff" />
        </div>
      ) : (
        <Styled.TransferContainer>
          <Styled.ChoiceContainer>
            <Carousel
              slideSize="fit-content"
              slideGap="16px"
              align="start"
              slidesToScroll={1}
              getEmblaApi={setEmblaApi}
              withControls={false}
            >
              {lowCostMap.map((item, i) => (
                <Carousel.Slide key={i}>
                  <Styled.CardContainer
                    key={item.id}
                    onClick={() => setSelectedItem(item)}
                    className={selectedItem === item ? 'selected' : ''}
                  >
                    <Styled.TimeWrapper>
                      <Styled.Time>
                        {formatDateAndTime(item.transferDate)}
                      </Styled.Time>
                    </Styled.TimeWrapper>
                    <Styled.Price>
                      €
                      <strong>
                        {formatCurrency(item.totalPrice / 100).replace('€', '')}
                      </strong>
                    </Styled.Price>

                    <Styled.OneTransferContainer>
                      <Styled.InputContainer>
                        <MapPin size="2.4rem" color="#27c7ff" />
                        <Styled.LabelContainer>
                          <Styled.Title>{t('departure')}</Styled.Title>
                          <Styled.Description>
                            {item.startingPlace}
                          </Styled.Description>
                        </Styled.LabelContainer>
                      </Styled.InputContainer>
                      <Styled.InputContainer>
                        <MapPin size="2.4rem" color="#27c7ff" />
                        <Styled.LabelContainer>
                          <Styled.Title>{t('arrival')}</Styled.Title>
                          <Styled.Description>
                            {item.arrivalPlace}
                          </Styled.Description>
                        </Styled.LabelContainer>
                      </Styled.InputContainer>
                    </Styled.OneTransferContainer>
                    <Styled.AditionalInfo>
                      <Users2 size="2.4rem" color="#27c7ff" />
                      <strong>
                        {item.numberOfPassengers > 0
                          ? item.numberOfPassengers
                          : 1}
                      </strong>
                      <span>{t('person')}</span>
                    </Styled.AditionalInfo>
                  </Styled.CardContainer>
                </Carousel.Slide>
              ))}
            </Carousel>
          </Styled.ChoiceContainer>
          <Styled.BtnContainer>
            {lowCostMap.length > 0 && (
              <div>
                <div>
                  <button disabled={!canScrollPrev} onClick={scrollPrev}>
                    <ChevronLeft />
                  </button>
                  <button disabled={!canScrollNext} onClick={scrollNext}>
                    <ChevronRight />
                  </button>
                </div>
                <span>
                  <strong>{lowCostMap.length}</strong>{' '}
                  {lowCostMap.length > 1 ? 'disponíveis' : 'disponível'}
                </span>
              </div>
            )}
            <Styled.BtnPrimary
              onClick={() => {
                if (!selectedItem) {
                  toast.error(t('toast.selectTripToContinue'))
                  return
                }
                setLowCost({
                  ...selectedItem,
                  totalPrice: selectedItem?.totalPrice / 100,
                  status: 'PENDING',
                  from: selectedItem?.startingPlace,
                  startingLatitude: selectedItem?.startingLatitude,
                  startingLongitude: selectedItem?.startingLongitude,
                  arrivalLatitude: selectedItem?.arrivalLatitude,
                  arrivalLongitude: selectedItem?.arrivalLongitude,
                  to: selectedItem?.arrivalPlace,
                  distanceMeters: selectedItem?.distanceInKm,
                  allPassangers: selectedItem?.numberOfPassengers
                    ? selectedItem.numberOfPassengers
                    : 1,
                  dateTime: new Date(selectedItem?.transferDate),
                  flightNumber: selectedItem?.flightNumber,
                  checkedLuggage: selectedItem?.checkedLuggages
                    ? selectedItem?.checkedLuggages
                    : 0,
                  handLuggage: selectedItem?.handLuggages
                    ? selectedItem?.handLuggages
                    : 0,
                  backPack: selectedItem?.backpacks
                    ? selectedItem?.backpacks
                    : 0,
                  surfQuantity: selectedItem?.surfboards
                    ? selectedItem?.surfboards
                    : 0,
                  babyChair: selectedItem?.babySeats
                    ? selectedItem?.babySeats
                    : 0,
                  childSeat: selectedItem?.childSeats
                    ? selectedItem?.childSeats
                    : 0,
                  boosterSeat: selectedItem?.boosterSeats
                    ? selectedItem?.boosterSeats
                    : 0,
                  animalsQuantity: selectedItem?.animals
                    ? selectedItem?.animals
                    : 0,
                  shareRide: selectedItem?.shareRide,
                  passengerName: selectedItem?.passengerName,
                  passengerPhoneNumber: selectedItem?.passengerPhoneNumber,
                  passengerEmail: selectedItem?.passengerEmail,
                  durationInSeconds: selectedItem?.durationInSeconds,
                  bookingStops: selectedItem?.bookingStops
                    ? selectedItem?.bookingStops
                    : undefined
                })
                navigate('/checkout/low-cost')
              }}
            >
              {t('reserve')}
            </Styled.BtnPrimary>
          </Styled.BtnContainer>
        </Styled.TransferContainer>
      )}
    </>
  )
}
