import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 10px;
  padding: 0 1.6rem;
  border-radius: 6rem;
  width: 100%;
  cursor: pointer;

  > img {
    height: 38px;

    &:first-of-type {
      width: 28px;
      height: 28px;
      object-fit: cover;
    }
  }

  > span {
    text-align: center;
    font: 600 6px 'Roboto', sans-serif;
    color: #b0b0b0;
  }
`
