import * as Styled from './styles'
import { formatCurrency } from '../../../../../../utils/formatCurrency'
import { AccountInfo, HistoricItemIcon } from '../DriverHistoric/styles'
import {
  iconBackgroundDecider,
  iconDecider,
  typeLabelDecider,
  iconColorDecider
} from '../DriverHistoric/constants'
import { ReactComponent as CloseIcon } from '../imgs/CloseIcon.svg'
import { useEffect, useState } from 'react'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { User } from 'lucide-react'
import dayjs from 'dayjs'
import { useAuth } from '../../../../../../contexts/useAuth'
import easyTransfer from '../../assets/easy.png'

export const PaymentDetailsModal = ({
  onClose,
  onSuccess,
  driver,
  data,
  invertedUsers
}) => {
  const overlayRef = useRef(null)
  const {
    ROLE,
    user: { hostel, company }
  } = useAuth()

  const receivedFromDriver =
    ROLE === 'ADMIN' && data.type === 'income' && data.user.id === driver.id
  const paidToManagement =
    (ROLE === 'USER' || ROLE === 'COMPANY_USER') &&
    data.type === 'outcome' &&
    data.user.id === driver.id

  const onClickOverlay = (e) => {
    if (overlayRef === null) return
    if (e.target !== overlayRef.current) return
    if (!onClose) return
    onClose()
  }

  useEffect(() => {
    const escapeModal = ({ key }) => {
      if (key === 'Escape' && onClose) onClose()
    }

    window.document.addEventListener('keydown', escapeModal)

    return () => {
      window.document.removeEventListener('keydown', escapeModal)
    }
  }, [onClose])

  return (
    <Styled.ModalOverlay ref={overlayRef} onClick={onClickOverlay}>
      <Styled.ModalContainer>
        <Styled.ModalHeader>
          <h2></h2>
          <Styled.CloseButton>
            <CloseIcon
              onClick={() => {
                onClose()
              }}
            />
          </Styled.CloseButton>
        </Styled.ModalHeader>
        <Styled.ModalContent>
          <div
            style={
              (data.type !== 'charge' && invertedUsers) ||
              ROLE === 'HOTEL_USER' ||
              (ROLE === 'COMPANY_ADMIN' && invertedUsers)
                ? { flexDirection: 'row-reverse' }
                : undefined
            }
          >
            <AccountInfo
              style={
                (paidToManagement || receivedFromDriver) &&
                data.type !== 'charge'
                  ? { paddingRight: 0 }
                  : ROLE === 'HOTEL_USER' || ROLE === 'COMPANY_ADMIN'
                  ? { paddingRight: 0 }
                  : undefined
              }
            >
              <div>
                <span
                  style={
                    data.user.profilePicture
                      ? { display: 'block' }
                      : {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }
                  }
                >
                  {data.user.profilePicture ||
                  ROLE === 'HOTEL_USER' ||
                  ROLE === 'COMPANY_ADMIN' ||
                  receivedFromDriver ||
                  paidToManagement ? (
                    <img
                      src={
                        receivedFromDriver ||
                        paidToManagement ||
                        ROLE === 'HOTEL_USER' ||
                        ROLE === 'COMPANY_ADMIN'
                          ? easyTransfer
                          : data.user.profilePicture.url
                      }
                    />
                  ) : (
                    <User size="3rem" />
                  )}
                </span>
                <div>
                  <strong>
                    {receivedFromDriver ||
                    paidToManagement ||
                    ROLE === 'HOTEL_USER' ||
                    ROLE === 'COMPANY_ADMIN'
                      ? 'EasyTransfer'
                      : data.user.name}
                  </strong>
                  {data.user.role === 'ADMIN' ||
                  ROLE === 'HOTEL_USER' ||
                  ROLE === 'COMPANY_ADMIN' ||
                  receivedFromDriver ||
                  paidToManagement ? (
                    <span>admin</span>
                  ) : (
                    <p>#{data.user.id}</p>
                  )}
                </div>
              </div>
            </AccountInfo>
            <div>
              <HistoricItemIcon color={iconBackgroundDecider[data.type]}>
                {iconDecider[data.type]}
              </HistoricItemIcon>
            </div>
            <AccountInfo>
              <div>
                <span
                  style={
                    driver.profilePicture
                      ? { display: 'block' }
                      : {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }
                  }
                >
                  {driver.profilePicture ? (
                    <img src={driver.profilePicture.url} />
                  ) : (
                    <User size="3rem" />
                  )}
                </span>
                <div>
                  <strong>
                    {ROLE === 'HOTEL_USER' && hostel
                      ? hostel.name
                      : ROLE === 'COMPANY_ADMIN' && company
                      ? company.name
                      : driver.name}
                  </strong>
                  <p>
                    #
                    {ROLE === 'HOTEL_USER' && hostel
                      ? hostel.id
                      : ROLE === 'COMPANY_ADMIN' && company
                      ? company.id
                      : driver.name}
                  </p>
                </div>
              </div>
            </AccountInfo>
          </div>
          <span
            style={{
              backgroundColor: iconBackgroundDecider[data.type],
              color: iconColorDecider[data.type]
            }}
          >
            <strong>{typeLabelDecider[data.type]}</strong> em{' '}
            {dayjs(data.paidAt).format('DD/MM/YYYY - HH:mm')}
          </span>
          <Styled.TotalValue>
            <strong>€</strong>
            {formatCurrency(data.value).split('€')[1]}
          </Styled.TotalValue>
          <Styled.MessageWrapper>
            <p>Mensagem</p>
            <div className={!data.message ? 'no-message' : undefined}>
              {data.message || 'Nenhuma mensagem registrada.'}
            </div>
          </Styled.MessageWrapper>
          {!!data.transfers && (
            <Styled.TransfersWrapper>
              <p>Transfers</p>

              <ul>
                {data.transfers.map((transfer) => (
                  <li key={transfer.id}>
                    <div>
                      <span>
                        #<strong>{transfer.id}</strong>
                      </span>
                      <p>{dayjs(transfer.transferDate).format('DD/MM/YYYY')}</p>
                    </div>
                    <span>
                      €
                      <strong>
                        {
                          formatCurrency(transfer.totalPrice / 100).split(
                            '€'
                          )[1]
                        }
                      </strong>
                    </span>
                  </li>
                ))}
              </ul>
            </Styled.TransfersWrapper>
          )}
        </Styled.ModalContent>
      </Styled.ModalContainer>
    </Styled.ModalOverlay>
  )
}
