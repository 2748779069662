import React, { useRef } from 'react'
import * as Styled from './styles'
import { ReactComponent as LucideMapPin } from '../imgs/lucide_map-pin.svg'
import { ReactComponent as ArrowIcon } from '../imgs/lucide_arrow-right2.svg'
import { Autocomplete } from '@react-google-maps/api'
import { TextInput } from '@mantine/core'
import { useMaps } from '../../../../../../contexts/MapsContext'
import { toast } from 'react-toastify'

export function Destinations({ going, setGoing, distance, setDistance }) {
  const { isLoaded } = useMaps()

  const originRef = useRef()
  const destinationRef = useRef()

  const calculateRoute = async () => {
    if (
      originRef?.current?.value !== '' &&
      destinationRef?.current?.value !== ''
    ) {
      try {
        //eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService()
        const results = await directionsService.route({
          origin: originRef?.current?.value,
          destination: destinationRef?.current?.value,
          //eslint-disable-next-line no-undef
          travelMode: google.maps.TravelMode.DRIVING
        })

        const distanceText = results?.routes[0]?.legs[0]?.distance?.text
        const distanceInteger = distanceText.toString().replace(/\s\S+$/, '')

        setDistance(parseFloat(distanceInteger))
      } catch (error) {
        console.log('erro', error)
        setDistance(0)
        toast.warning('Não foi possível calcular a distância')
      }
    }
  }

  if (!isLoaded) {
    return <div>Loading...</div>
  }

  return (
    <Styled.DestinationContainer>
      <Styled.LocationContainer>
        <Autocomplete
          onPlaceChanged={() => {
            setGoing((going) => {
              return { ...going, from: originRef.current.value }
            })
            calculateRoute()
          }}
        >
          <TextInput
            placeholder="Ericeira, Portugal"
            label="Partida"
            icon={<LucideMapPin style={{ marginBottom: '5px' }} />}
            iconWidth={'4rem'}
            value={going.from}
            ref={originRef}
            onChange={() => {
              setGoing((going) => {
                return { ...going, from: originRef.current.value }
              })
            }}
          />
        </Autocomplete>
      </Styled.LocationContainer>
      <Styled.DistanceContainer>
        <Styled.DistanceLabel>
          {distance ? `${distance.toString().replace(/\s\S+$/, '')} km` : '...'}
        </Styled.DistanceLabel>
        <Styled.ArrowContainer>
          <ArrowIcon />
        </Styled.ArrowContainer>
      </Styled.DistanceContainer>
      <Styled.LocationContainer>
        <Autocomplete
          onPlaceChanged={() => {
            setGoing((going) => {
              return { ...going, to: destinationRef.current.value }
            })

            calculateRoute()
          }}
        >
          <TextInput
            placeholder="Ericeira, Portugal"
            label="Chegada"
            icon={<LucideMapPin style={{ marginBottom: '5px' }} />}
            iconWidth={'4rem'}
            value={going.to}
            ref={destinationRef}
            onChange={() =>
              setGoing((going) => {
                return { ...going, to: destinationRef.current.value }
              })
            }
          />
        </Autocomplete>
      </Styled.LocationContainer>
    </Styled.DestinationContainer>
  )
}
