import styled from 'styled-components'
export const Container = styled.div`
  background: radial-gradient(
    58.18% 58.18% at 50% 50%,
    rgba(251, 176, 59, 0.1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  justify-content: space-around;
  gap: clamp(32px, 1.667vw, 1.667vw);
  width: clamp(240px, 60vw, 60vw);

  @media only screen and (max-width: 840px) {
    width: 85vw;
  }
`
export const CardContainer = styled.div`
  width: clamp(160px, 14vw, 14vw);
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: clamp(16px, 0.833vw, 0.833vw);

  .wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: clamp(16px, 0.833vw, 0.833vw);
  }

  h2 {
    font-weight: 600;
    font-size: 2rem;
    /* line-height: clamp(18px, 1.3vw, 1.3vw); */
    /* min-height: clamp(36px, 2.6vw, 2.6vw); */
    text-align: center;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    text-align: center;
    color: #404040;
  }

  strong {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #68645f;
  }
`

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: clamp(24px, 1.25vw, 1.25vw);
  background: #ffffff;
  box-shadow: 0px 22px 24px rgba(0, 0, 0, 0.07);
  border-radius: clamp(16px, 0.833vw, 0.833vw);

  svg {
    height: clamp(48px, 2.5vw, 2.5vw);
    width: clamp(48px, 2.5vw, 2.5vw);
  }

  @media only screen and (max-width: 768px) {
    width: 6rem;
    height: 6rem;
    border-radius: 1.032rem;

    svg {
      min-width: 2.8rem;
      min-height: 2.8rem;
    }
  }
`
