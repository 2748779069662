import { Container, ContainerPayment, ImagesWrapper, Title } from './styles'
import { ReactComponent as VisaLogo } from '../../../../assets/visa_logo.svg'
import { ReactComponent as MbMultiLogo } from '../../../../assets/mb_multi_logo.svg'
import { ReactComponent as MasterCardLogo } from '../../../../assets/mastercard_logo.svg'
import { ReactComponent as MbWayLogo } from '../../../../assets/mb_way_logo.svg'
import { ReactComponent as PayPalLogo } from '../../../../assets/paypal_logo.svg'
import { useTranslation } from 'react-i18next'

export default function PaymentMethods() {
  const { t } = useTranslation()

  return (
    <ContainerPayment>
      <Container>
        <Title>{t('paymentMethods')}</Title>
        <ImagesWrapper>
          <VisaLogo />
          <MbMultiLogo />
          <MasterCardLogo />
          <MbWayLogo />
          <PayPalLogo />
        </ImagesWrapper>
      </Container>
    </ContainerPayment>
  )
}
