import React, { useEffect, useRef, useState } from 'react'
import * as Styled from './style'

import { ReactComponent as LucideUpload } from '../imgs/lucide_upload.svg'
import { ReactComponent as LucideMapPin } from '../imgs/lucide_map-pin.svg'
import { ReactComponent as EuroIcon } from '../imgs/lucide_badge-euro.svg'
import { ReactComponent as ClockIcon } from '../imgs/lucide_clock.svg'
import { ReactComponent as ChevronDown } from '../imgs/lucide_chevron-down.svg'
import { ReactComponent as TrashIcon } from '../imgs/lucide_trash-2.svg'
import { ReactComponent as DeletePictureIcon } from '../imgs/lucide_x.svg'

import { TextInput, NumberInput, Group } from '@mantine/core'
import { Select } from '@mantine/core'

import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone'
import { Text, Image } from '@mantine/core'
import { Autocomplete } from '@react-google-maps/api'
import { useMaps } from '../../../../../../contexts/MapsContext'

export function Card({ stop, onChange, position, removeStop }) {
  const [price, setPrice] = useState(stop.price ?? undefined)
  const [timeInMinutes, setTimeInMinutes] = useState(stop.timeInMinutes ?? 0)
  const [image, setImage] = useState(stop.image ?? null)
  const hasImage = image
  const [stopLocation, setStopLocation] = useState(stop.place ?? '')
  const stopLocationRef = useRef(stop.place ?? '')
  const priceRef = useRef(stop.price ?? undefined)

  const { isLoaded } = useMaps()

  const removePicture = () => {
    setImage(null)
  }

  useEffect(() => {
    onChange({
      place: stopLocation,
      timeInMinutes,
      price,
      image
    })
  }, [stopLocation, timeInMinutes, price, image])

  const readFileAsBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.readAsDataURL(file)
    })
  }

  if (!isLoaded) {
    return <></>
  }

  return (
    <Styled.Container>
      <Styled.CardContainer>
        <Styled.StopNumber>{`${position + 1}º`}</Styled.StopNumber>
        <Styled.Card>
          <Styled.UploadSection>
            <Dropzone
              id="drop-element"
              onDrop={async (files) => {
                const base64Data = await readFileAsBase64(files[0])
                const base64Parts = base64Data.split(',')

                if (base64Parts.length === 2) {
                  setImage({
                    ...files[0],
                    title: files[0].name,
                    fileName: files[0].name,
                    fileExtension: files[0].type.split('/')[1],
                    url: base64Data,
                    base64: base64Parts[1]
                  })
                }
              }}
              onReject={(files) => console.log('rejected files', files)}
              accept={IMAGE_MIME_TYPE}
            >
              {!hasImage && (
                <Group style={{ flexDirection: 'column' }}>
                  <LucideUpload />
                  <Text
                    style={{
                      fontFamily: 'Neometric',
                      fontWeight: '400',
                      fontSize: '14px',
                      color: '#404040',
                      textAlign: 'center',
                      lineHeight: '16.8px',
                      width: '150px',
                      height: '34px'
                    }}
                  >
                    Solte uma imagem aqui
                  </Text>
                </Group>
              )}
              {image && <Image src={image.url} />}
            </Dropzone>
            {hasImage && (
              <Styled.DeletePicture
                id="delete-container"
                onClick={removePicture}
              >
                <DeletePictureIcon />
              </Styled.DeletePicture>
            )}
          </Styled.UploadSection>
          <Styled.StopsSection>
            <Styled.StopLocation>
              <Styled.StopLabelContainer>
                <Styled.StopLabel>Localização</Styled.StopLabel>
                <TrashIcon
                  onClick={() => removeStop(stop?.tempId ?? stop.id)}
                />
              </Styled.StopLabelContainer>
              <Autocomplete
                onPlaceChanged={() => {
                  setStopLocation(stopLocationRef.current.value)
                }}
              >
                <TextInput
                  icon={<LucideMapPin style={{ margin: '12px 8px' }} />}
                  iconWidth={'5rem'}
                  placeholder="Mafra, Portugal"
                  ref={stopLocationRef}
                  value={stopLocation}
                  onChange={() =>
                    setStopLocation(stopLocationRef.current.value)
                  }
                />
              </Autocomplete>
            </Styled.StopLocation>
            <Styled.PriceTimeContainer>
              <NumberInput
                hideControls
                decimalSeparator=","
                thousandsSeparator="."
                icon={
                  <EuroIcon
                    style={{
                      marginBottom: '3px'
                    }}
                  />
                }
                iconWidth={'5rem'}
                placeholder="5,00"
                label="Preço"
                value={stop.price}
                precision={2}
                step={0.01}
                ref={priceRef}
                onChange={setPrice}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
              <Select
                id="select"
                label="Tempo"
                placeholder="5 min"
                icon={
                  <ClockIcon
                    style={{
                      margin: '12px 8px'
                    }}
                  />
                }
                iconWidth={'5rem'}
                data={[
                  { value: 5, label: '5 min' },
                  { value: 10, label: '10 min' },
                  { value: 15, label: '15 min' },
                  { value: 20, label: '20 min' },
                  { value: 25, label: '25 min' },
                  { value: 30, label: '30 min' }
                ]}
                value={stop.timeInMinutes}
                styles={{
                  rightSection: { pointerEvents: 'none' }
                }}
                rightSection={<ChevronDown style={{ margin: '12px 8px' }} />}
                rightSectionWidth={'5rem'}
                onChange={(option) => setTimeInMinutes(option)}
              />
            </Styled.PriceTimeContainer>
          </Styled.StopsSection>
        </Styled.Card>
      </Styled.CardContainer>
    </Styled.Container>
  )
}
