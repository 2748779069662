import React, { useState } from 'react'
import * as Styled from './styles'

import { DetailsTransfer } from './components/DetailsTransfer'
import { DetailsPayment } from './components/DetailsPayment'
import { DetailsPassenger } from './components/DetailsPassenger'
import { SendTransferModal } from './components/SendTransferModal'
import Status from './components/Status'
import { RejectModal } from './components/RejectModal'
import { CheckCircle, Copy, Play, XCircle } from 'lucide-react'
import { LoadingOverlay, MediaQuery } from '@mantine/core'
import { useParams, useNavigate } from 'react-router'
import { useEffect } from 'react'
import fetchBooking from '../../../../services/booking'
import { toast } from 'react-toastify'
import { useClipboard } from '@mantine/hooks'
import { useTranslation } from 'react-i18next'

export const TransferDetails = () => {
  const [sendTransferModal, setSendTransferModal] = useState()
  const [rejectModal, setRejectModal] = useState()
  const [transfer, setTransfer] = useState()
  const [next, setNext] = useState(false)
  const [loading, setLoading] = useState(true)
  const [update, setUpdate] = useState(false)

  const { id } = useParams()
  const navigate = useNavigate()
  const clipboard = useClipboard({ timeout: 500 })

  const { t } = useTranslation()

  useEffect(() => {
    if (id) {
      fetchBooking
        .getBookingId(id)
        .then((res) => {
          setTransfer(res.data)
          setLoading(false)
        })
        .catch((err) => {
          console.log(err, 'err')
        })
    }
  }, [id, update])

  const handleCancelTransfer = (selectedMessage, message) => {
    const payload = {
      reasonToCancel: `${
        selectedMessage ? selectedMessage + ', ' : ''
      } ${message}`
    }
    console.log(payload, 'payload')
    fetchBooking
      .cancelTransfer(id, payload)
      .then((res) => {
        setNext(true)
      })
      .catch((err) => {
        console.log(err, 'err')
        if (
          err?.response?.data?.message ===
          'Transfers only can be canceled 3 hours before transferDate.'
        ) {
          toast.error(
            'Os transfers só podem ser cancelados 3 horas antes da data do transfer!'
          )
        }
      })
  }

  const handleConfirmTransfer = () => {
    if (transfer?.status === 'ACCEPTED') {
      fetchBooking
        .startTransfer(id)
        .then((res) => {
          setNext(true)
          toast.success(t('toast.successInitiedTransfer'))
          setUpdate(!update)
          // navigate('/dashboard/transfer')
        })
        .catch((err) => {
          console.log(err, 'err')
        })
    } else {
      fetchBooking
        .finishTransfer(id)
        .then((res) => {
          setNext(true)
          toast.success(t('toast.successAcommplishedTransfer'))
          navigate('/dashboard/transfer')
        })
        .catch((err) => {
          console.log(err, 'err')
        })
    }
  }

  return (
    <>
      <Styled.Container realized={transfer?.status === 'DONE'}>
        <LoadingOverlay overlayOpacity={0.5} visible={loading} />
        <Styled.HeaderContainer>
          <Styled.Title>
            Transfers{' '}
            <Styled.TransferId>
              #{id.length > 8 ? id.slice(0, 8) + '...' : id}
              <Copy
                size="16"
                style={{ cursor: 'pointer' }}
                alt="Copy id"
                onClick={(e) => {
                  e.stopPropagation()
                  try {
                    clipboard.copy(transfer.id)
                    toast.success(t('toast.copiedID'))
                  } catch {
                    toast.error(t('errorCopiedID'))
                  }
                }}
              />
            </Styled.TransferId>
          </Styled.Title>
          {transfer?.status !== 'DONE' && (
            <Styled.ButtonsWrapper>
              <Styled.SecondaryButton onClick={() => setRejectModal(true)}>
                CANCELAR TRANSFER
              </Styled.SecondaryButton>
              {transfer?.status === 'ACCEPTED' ? (
                <Styled.PaymentStart onClick={() => setSendTransferModal(true)}>
                  INICIAR <Play color="white" />
                </Styled.PaymentStart>
              ) : (
                <Styled.PaymentAccomplished
                  realized={transfer?.status === 'DONE'}
                  onClick={() => setSendTransferModal(true)}
                >
                  FINALIZAR
                  <CheckCircle width={24} height={24} color="#FFFFFF" />
                </Styled.PaymentAccomplished>
              )}
            </Styled.ButtonsWrapper>
          )}
        </Styled.HeaderContainer>
        {/* <MediaQuery query="(max-width: 768px)" styles={{ display: 'none' }}>
          <div>
            <Status status="Enviado para motorista" />
          </div>
        </MediaQuery> */}
        <Styled.DetailsContainer>
          <DetailsTransfer transfer={transfer} />
          <DetailsPayment
            transfer={transfer}
            setSendTransferModal={setSendTransferModal}
          />
        </Styled.DetailsContainer>
        <DetailsPassenger transfer={transfer} />
        {transfer?.status !== 'DONE' && (
          <Styled.CancelTransfer onClick={() => setRejectModal(true)}>
            <XCircle color="#F8294E" width={20} height={20} /> Pedir o
            cancelamento
          </Styled.CancelTransfer>
        )}
      </Styled.Container>
      <SendTransferModal
        opened={sendTransferModal}
        onClose={() => setSendTransferModal(false)}
        onSuccess={() => {
          handleConfirmTransfer()
          setSendTransferModal(false)
        }}
        status={transfer?.status}
      />
      <RejectModal
        opened={rejectModal}
        onClose={() => {
          setRejectModal(false)
          if (next) {
            navigate('/dashboard/transfer')
          }
        }}
        next={next}
        onFinish={(motiveSelected, message) =>
          handleCancelTransfer(motiveSelected, message)
        }
      />
    </>
  )
}
