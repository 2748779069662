import React, { useRef, useState } from 'react'
import * as Styled from './styles'

import { DetailsTransfer } from './components/DetailsTransfer'
import { DetailsPayment } from './components/DetailsPayment'
import { DetailsPassenger } from './components/DetailsPassenger'
import { useParams, useNavigate } from 'react-router'
import { useEffect } from 'react'
import fetchBooking from '../../../../services/booking'
import { RejectModal } from './components/RejectModal'
import { toast } from 'react-toastify'
import { LoadingOverlay, Switch } from '@mantine/core'

async function createLowCosts(ids) {
  return toast.promise(fetchBooking.createLowCosts({ ids }), {
    pending: 'Habilitando Low Cost...',
    success: 'Low Cost habilitado com sucesso!',
    error: 'Ops! Ocorreu um erro ao habilitar Low Cost'
  })
}

async function disableLowCosts(ids) {
  return toast.promise(fetchBooking.disableLowCosts({ ids }), {
    pending: 'Desabilitando Low Cost...',
    success: 'Low Cost desabilitado com sucesso!',
    error: 'Ops! Ocorreu um erro ao desabilitar Low Cost'
  })
}

export const LowCostDetails = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [rejectModal, setRejectModal] = useState()
  const [transfer, setTransfer] = useState()

  const { id } = useParams()
  const navigate = useNavigate()

  const deleteTransfer = async () => {
    const res = await toast.promise(fetchBooking.remove(id), {
      pending: 'Excluindo Low Cost...',
      success: 'Low Cost excluído com sucesso!',
      error: 'Ops! Ocorreu um erro ao excluir Low Cost'
    })

    if (res.status === 200) {
      setRejectModal(false)
      navigate('/dashboard/low-cost')
    }
  }

  const toggleLowCost = async (isEnabling) => {
    const res = isEnabling
      ? await createLowCosts([id])
      : await disableLowCosts([id])

    if (res.status === 200 && !isEnabling) {
      navigate('/dashboard/low-cost')
    }
  }

  useEffect(() => {
    if (!id) return
    ;(async function () {
      try {
        setIsLoading(true)
        const res = await fetchBooking.getBookingId(id)

        if (res.status === 200) setTransfer(res.data.content)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [id])

  return (
    <>
      <LoadingOverlay overlayOpacity={0.5} visible={isLoading} />
      <Styled.Container>
        <Styled.HeaderContainer>
          <Styled.Title>
            Transfer <Styled.TransferId>#{transfer?.id}</Styled.TransferId>
          </Styled.Title>

          <Styled.ButtonsWrapper>
            <LowCostToggle
              defaultChecked={transfer?.lowCost}
              onChange={toggleLowCost}
            />
            <Styled.SecondaryButton onClick={() => setRejectModal(true)}>
              Exluir
            </Styled.SecondaryButton>
          </Styled.ButtonsWrapper>
        </Styled.HeaderContainer>

        <Styled.DetailsContainer>
          <DetailsTransfer transfer={transfer} />
          <DetailsPayment transfer={transfer} />
        </Styled.DetailsContainer>

        <DetailsPassenger transfer={transfer} />
      </Styled.Container>

      <RejectModal
        opened={rejectModal}
        onClose={() => setRejectModal(false)}
        onFinish={deleteTransfer}
      />
    </>
  )
}

function LowCostToggle({ defaultChecked, disabled, onChange }) {
  const cachedDefaultChecked = useRef(defaultChecked)
  const [value, setValue] = useState(false)

  useEffect(() => {
    if (defaultChecked != cachedDefaultChecked.current) {
      setValue(defaultChecked)
      cachedDefaultChecked.current = defaultChecked
    }
  }, [defaultChecked])

  function toggle({ currentTarget }) {
    setValue(currentTarget.checked)
    onChange(currentTarget.checked)
  }

  return (
    <Switch
      defaultChecked={defaultChecked}
      checked={value}
      onChange={toggle}
      size="xl"
      color="lime"
      label="Low Cost"
      labelPosition="left"
      onLabel="Ativo"
      disabled={disabled}
    />
  )
}
