import { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import Container2Columns from './components/Container2Columns'
import ProcessDivContainer from './components/ProcessDivContainer'
import { Navbar } from '../../../../components/Navbar'
import Footer from '../../../../components/Footer'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Faq from '../../../Home/components/Faq'
import { Container } from '../../../Home/components/Faq/styles'

const ImgShapebackgroundIcon = styled.img`
  height: calc(100% - 38.9px);
  width: 1579px;
  position: absolute;
  margin: 0 !important;
  top: 46px;
  right: -790px;
  bottom: -7.1px;
  max-height: 100%;
  object-fit: contain;
  z-index: -2;
  @media screen and (max-width: 1200px) {
    z-index: -2;
  }
  @media screen and (max-width: 599px) {
    display: flex;
    max-height: 230px;
  }
`
const animationFadeInLeft = keyframes`
    0% {
        transform: translateX(-50px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
  `
const VenhaSerUm = styled.span`
  font-weight: 500;
  font-size: 4.8rem;
`
const Motorista = styled.span`
  font-weight: 900;
`
const Span = styled.span`
  align-self: stretch;
  position: relative;
  letter-spacing: 0.01em;
  line-height: 110%;
  display: inline-block;
  opacity: 0;
  &.animate {
    animation: 0.3s ease-out 0.2s 1 normal forwards ${animationFadeInLeft};
  }
  @media screen and (max-width: 1200px) {
    opacity: 0;
    &.animate {
      animation: 0.3s ease-out 0.2s 1 normal forwards ${animationFadeInLeft};
    }
  }
  @media screen and (max-width: 599px) {
    font-size: 4.8rem;
  }
`
const H = styled.h4`
  margin: 0;
  width: 454px;
  position: relative;
  font-size: clamp(16px, 0.833vw, 0.833vw);
  letter-spacing: 0.01em;
  line-height: 120%;
  font-weight: 400;
  font-family: Poppins;
  display: flex;
  align-items: center;
  @media screen and (max-width: 599px) {
    align-self: stretch;
    width: auto;
  }
`
const LucidemapPinnedIcon = styled.img`
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
`
const DivChild = styled.img`
  width: 0.7px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 34.8px;
`
const ViagensLongas = styled.p`
  margin: 0;
  font-size: 1.1rem;
  font-family: 'Roboto' !important;
`
const H6Oferecidas = styled.div`
  position: relative;
  letter-spacing: 0.01em;
  line-height: 110%;
  text-transform: uppercase;
`
const Div = styled.div`
  border-radius: 11.49px;
  background-color: #fff;
  box-shadow: 0px 11.5px 21.55px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4.310553550720215px 8.62110710144043px;
  gap: 11.49px;
`
const InfosHero = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 17.24px;
  font-size: 12px;
  font-family: Roboto;
  @media screen and (max-width: 599px) {
    align-self: stretch;
    width: auto;
    flex-direction: row;
    flex-wrap: wrap;
  }
`
const ConheaAFrota = styled.b`
  position: relative;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 120%;
  text-transform: uppercase;
  font-family: Roboto;
  color: #fff;
  text-align: center;
`
const LucidearrowUpRightIcon = styled.img`
  width: 32px;
  position: relative;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
`
const Buttonstartnow = styled.button`
  cursor: pointer;
  border: none;
  padding: 16px 24px 16px 32px;
  background-color: #27c7ff;
  width: 360px;
  border-radius: 16px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1),
    0px 18px 19.69px rgba(0, 0, 0, 0.05);
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 16px;
  @media screen and (max-width: 599px) {
    align-self: stretch;
    width: auto;
    padding-left: 32px;
    box-sizing: border-box;
  }
`
const Div1 = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  z-index: 1;
  @media screen and (max-width: 960px) {
    flex: unset;
    align-self: stretch;
  }
  @media screen and (max-width: 599px) {
    flex: unset;
    align-self: stretch;
  }
`
const ImgIcon = styled.img`
  width: 612.6px;
  position: relative;
  height: 441.9px;
  object-fit: cover;
  z-index: 2;
  @media screen and (max-width: 1200px) {
    flex: 1;
  }
  @media screen and (max-width: 960px) {
    flex: 1;
  }
  @media screen and (max-width: 599px) {
    align-self: stretch;
    width: auto;
    flex: 1;
    /* min-width: 360px; */
  }
`
const SectionHero = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 200px 156px 0px;
  position: relative;
  gap: 10px;
  text-align: left;
  font-size: 56px;
  color: #222;
  font-family: Neometric;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    padding-left: 24px;
    padding-top: 70px;
    padding-right: 24px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 599px) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-start;
    padding-left: 16px;
    padding-top: 70px;
    padding-right: 16px;
    box-sizing: border-box;
  }
`
const VantagensDeSer = styled.span`
  span {
    color: #27c7ff;
  }
`
const Easytransfer = styled.span`
  color: #27c7ff;
`
const H1 = styled.h3`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: 32px;
  letter-spacing: 0.01em;
  line-height: 100%;
  font-weight: 800;
  font-family: inherit;
  text-align: center;
  color: #000;
  @media screen and (max-width: 599px) {
    font-size: 24px;
  }
`
const H2 = styled.h4`
  margin: 0;
  width: 360px;
  position: relative;
  font-size: inherit;
  line-height: 110%;
  font-weight: 700;
  font-family: inherit;
  display: flex;
  align-items: center;
  @media screen and (max-width: 599px) {
    text-align: left;
    align-self: stretch;
    width: auto;
    flex: 1;
  }
`
const ComANossa = styled.span`
  font-family: Poppins;
`
const ComANossa1 = styled.b`
  font-family: Poppins;
`
const ComANossaAbordagemInovador = styled.span`
  display: block;
`
const PTxt = styled.span`
  width: 100%;
`
const P = styled.p`
  margin: 0;
  width: 391px;
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 120%;
  color: #000;
  display: flex;
  align-items: center;
  font-family: Poppins;
  @media screen and (max-width: 599px) {
    text-align: left;
    align-self: stretch;
    width: auto;
    flex: 1;
  }
`
const H4Parent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 24px;
  @media screen and (max-width: 599px) {
    align-self: stretch;
    width: auto;
  }
`
const ImgIcon1 = styled.img`
  width: 400px;
  position: relative;
  border-radius: 24px;
  height: 300px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  @media screen and (max-width: 599px) {
    align-self: stretch;
    width: auto;
  }
`
const FrameParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 64px;
  @media screen and (max-width: 960px) {
    gap: 64px;
  }
  @media screen and (max-width: 599px) {
    flex-direction: column-reverse;
  }
`
const H3 = styled.h4`
  margin: 0;
  width: 378px;
  position: relative;
  font-size: inherit;
  line-height: 110%;
  font-weight: 700;
  font-family: inherit;
  display: flex;
  align-items: center;
  @media screen and (max-width: 599px) {
    align-self: stretch;
    width: auto;
    flex: 1;
  }
`
const P1 = styled.p`
  margin: 0;
  width: 404px;
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 120%;
  color: #000;
  display: flex;
  align-items: center;
  font-family: Poppins;
  @media screen and (max-width: 599px) {
    align-self: stretch;
    width: auto;
    flex: 1;
  }
`
const H4Group = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  @media screen and (max-width: 599px) {
    align-self: stretch;
    width: auto;
  }
`
const ImgParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 64px;
  text-align: left;
  @media screen and (max-width: 960px) {
    gap: 64px;
  }
  @media screen and (max-width: 599px) {
    height: auto;
    flex-direction: column;
  }
`
const P2 = styled.p`
  margin: 0;
  width: 360px;
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 120%;
  color: #000;
  display: flex;
  align-items: center;
  font-family: Poppins;
  @media screen and (max-width: 599px) {
    text-align: left;
    align-self: stretch;
    width: auto;
    flex: 1;
  }
`
const H4Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 24px;
  @media screen and (max-width: 599px) {
    align-self: stretch;
    width: auto;
    flex: 1;
  }
`
const FrameGroup = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  gap: 64px;
  @media screen and (max-width: 960px) {
    gap: 64px;
  }
  @media screen and (max-width: 599px) {
    align-self: stretch;
    width: auto;
    height: auto;
    flex-direction: column-reverse;
  }
`
const Sectionbenefits = styled.section`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 156px;
  gap: 32px;
  text-align: right;
  font-size: 24px;
  color: #222;
  font-family: Neometric;
  @media screen and (max-width: 960px) {
    gap: 32px;
    padding: 0px 24px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 599px) {
    gap: 32px;
    padding: 0px 16px;
    box-sizing: border-box;
  }
`
const ImgIcon2 = styled.img`
  width: 437.3px;
  height: 328px;
  position: relative;
  border-radius: 26.24px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  @media screen and (max-width: 599px) {
    align-self: stretch;
    width: auto;
    max-width: 400px;
  }
`
const Sectionprocess = styled.section`
  margin-top: -100px;
  align-self: stretch;
  background: radial-gradient(
    30rem 50% at 50% 50%,
    #27c7ff,
    rgba(255, 255, 255, 0)
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 156px;
  @media screen and (max-width: 960px) {
    padding: 0px 24px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 599px) {
    padding: 0px 16px;
    box-sizing: border-box;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #27c7ff,
      rgba(255, 255, 255, 0)
    );
  }
`
const H4 = styled.h3`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: 0.01em;
  line-height: 100%;
  font-weight: 800;
  font-family: inherit;
  @media screen and (max-width: 960px) {
    font-size: 24px;
  }
`
const B = styled.b`
  position: relative;
  letter-spacing: 0.01em;
  line-height: 100%;
`
const Wrapper = styled.div`
  width: 68px;
  border-radius: 100px;
  background-color: #DFF7FF;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
`
const H5 = styled.b`
  align-self: stretch;
  position: relative;
  letter-spacing: 0.01em;
  line-height: 120%;
  @media screen and (max-width: 599px) {
    font-size: 16px;
  }
`
const P3 = styled.p`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 110%;
  font-family: Poppins;
`
const H501Parent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  text-align: left;
  font-size: 18px;
  color: #222;
`
const FrameDiv = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  @media screen and (max-width: 960px) {
    align-self: stretch;
    width: auto;
  }
`
const H6 = styled.h5`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  letter-spacing: 0.01em;
  line-height: 120%;
  font-weight: 700;
  font-family: inherit;
  @media screen and (max-width: 599px) {
    font-size: 16px;
  }
`
const FrameParent1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  @media screen and (max-width: 960px) {
    align-self: stretch;
    width: auto;
  }
`
const H503Parent = styled.div`
  width: 270px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  text-align: left;
  font-size: 18px;
  color: #222;
`
const FrameParent2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  @media screen and (max-width: 960px) {
    align-self: stretch;
    width: auto;
  }
  @media screen and (max-width: 599px) {
    align-self: stretch;
    width: auto;
  }
`
const FrameContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  color: #1D95BF;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  @media screen and (max-width: 599px) {
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: flex-start;
  }
`
const ImgIcon3 = styled.img`
  width: 631px;
  position: relative;
  height: auto;
  object-fit: cover;
  @media screen and (max-width: 599px) {
    /* width: 100%; */
    object-fit: contain;
    margin-bottom: -100px;
    width: 100%;
  }
`
const Sectionhowworks = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 156px;
  gap: 24px;
  text-align: center;
  font-size: 32px;
  color: #000;
  font-family: Neometric;
  @media screen and (max-width: 960px) {
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 599px) {
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }
`
const Span1 = styled.span`
  align-self: stretch;
  position: relative;
  font-weight: 800;
  @media screen and (max-width: 599px) {
    font-size: 4.8rem;
  }
`
const H7 = styled.h4`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: 18px;
  font-weight: 400;
  font-family: Poppins;
`
const SpanParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`
const FrameChild = styled.img`
  width: 253.7px;
  position: absolute;
  margin: 0 !important;
  top: calc(50% - 151.5px);
  left: calc(50% - 127px);
  border-radius: 30.91px;
  height: 303.9px;
  z-index: 0;
`
const ImgIcon4 = styled.img`
  width: 359.9px;
  position: relative;
  height: 326px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 1;
`
const VectorParent = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 8px;
  @media screen and (max-width: 599px) {
    padding-left: 16px;
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }
`
const P4 = styled.div`
  position: relative;
  letter-spacing: 0.01em;
  line-height: 130%;
`
const GroupChild = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #27c7ff;
  width: 40px;
  height: 40px;
`
const LucideplusIcon = styled.img`
  position: absolute;
  top: 8px;
  left: 8px;
  width: 24px;
  height: 24px;
  overflow: hidden;
`
const RectangleParent = styled.div`
  width: 40px;
  position: relative;
  height: 40px;
`
const Div2 = styled.div`
  align-self: stretch;
  border-radius: 6px;
  border: 0.5px solid #bfbfbf;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 0px 16px;
`
const P5 = styled.div`
  width: 487px;
  position: relative;
  letter-spacing: 0.01em;
  line-height: 130%;
  display: inline-block;
  flex-shrink: 0;
  @media screen and (max-width: 960px) {
    flex: 1;
  }
  @media screen and (max-width: 599px) {
    flex: 1;
  }
`
const Div3 = styled.div`
  align-self: stretch;
  border-radius: 6px;
  border: 0.5px solid #bfbfbf;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 0px 16px;
  font-size: 15px;
`
const DivParent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  @media screen and (max-width: 599px) {
    align-self: stretch;
    width: auto;
    height: auto;
    padding-left: 16px;
    box-sizing: border-box;
    flex: unset;
  }
`
const FrameParent3 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: 24px;
  text-align: left;
  font-size: 16px;
  font-family: Poppins;
  @media screen and (max-width: 599px) {
    flex-direction: column;
  }
`
const Sectionfaq = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 156px 0px;
  gap: 24px;
  text-align: center;
  font-size: 64px;
  color: #222;
  font-family: Neometric;
  @media screen and (max-width: 960px) {
    padding-left: 24px;
    padding-top: 0px;
    padding-right: 24px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 599px) {
    padding-left: 16px;
    padding-top: 0px;
    padding-right: 16px;
    box-sizing: border-box;
  }
`
const H8 = styled.h3`
  text-align: center;
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-weight: 800;
  font-family: inherit;
  @media screen and (max-width: 599px) {
    font-size: 24px;
  }
`
const H9 = styled.h5`
  margin: 0;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  font-family: Poppins;
  color: #000;
  @media screen and (max-width: 960px) {
    align-self: stretch;
    width: auto;
  }
  @media screen and (max-width: 599px) {
    align-self: stretch;
    width: auto;
  }
`
const H3Parent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
`
const ConheaAFrota1 = styled.b`
  position: relative;
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 120%;
  text-transform: uppercase;
  font-family: Roboto;
  color: #222;
  text-align: center;
`
const Buttonstartnow1 = styled.button`
  cursor: pointer;
  border: none;
  padding: 16px 24px 16px 32px;
  background-color: #27c7ff;
  width: 360px;
  border-radius: 8px;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  gap: 8px;
  @media screen and (max-width: 599px) {
    align-self: stretch;
    width: auto;
    padding-left: 16px;
    box-sizing: border-box;
  }
`
const Containersingup = styled.div`
  flex: 1;
  border-radius: 16px;
  background-color: #DFF7FF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 0px;
  gap: 48px;
  @media screen and (max-width: 960px) {
    align-self: stretch;
    width: auto;
    flex: unset;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-left: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 599px) {
    align-self: stretch;
    width: auto;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    box-sizing: border-box;
    /* min-width: 360px; */
  }
`
const Sectionstartnow = styled.section`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 50px 156px;
  text-align: left;
  font-size: 32px;
  color: #27c7ff;
  font-family: Neometric;
  @media screen and (max-width: 960px) {
    flex-direction: column;
    padding: 0px 24px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 599px) {
    padding: 0px 16px;
    box-sizing: border-box;
  }
`
const SitePrincipalCadastroDeMRoot = styled.div`
  width: 100%;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 150px;

  ${Container} {
    margin-top: 0;
    margin-bottom: 0;
  }
`

export const EnterpriseRecruitment = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      '[data-animate-on-scroll]'
    )
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target
            targetElement.classList.add('animate')
            observer.unobserve(targetElement)
          }
        }
      },
      {
        threshold: 0.15
      }
    )

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i])
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i])
      }
    }
  }, [])

  const { t } = useTranslation()

  const accordionData = [
    {
      title: 'Precisa ser um motorista profissional?',
      content:
        'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus dolor, fugiat, nulla velit, dolorum veniam accusantium minima iusto nesciunt autem reiciendis. Ea recusandae magnam sunt fuga maxime et totam quisquam?',
      index: 1
    },
    {
      title: 'Como a EasyTransfer seleciona seus motoristas?',
      content:
        'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus dolor, fugiat, nulla velit, dolorum veniam accusantium minima iusto nesciunt autem reiciendis. Ea recusandae magnam sunt fuga maxime et totam quisquam?',
      index: 2
    },
    {
      title: 'Como posso começar?',
      content:
        'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus dolor, fugiat, nulla velit, dolorum veniam accusantium minima iusto nesciunt autem reiciendis. Ea recusandae magnam sunt fuga maxime et totam quisquam?',
      index: 3
    },
    {
      title: 'Como a EasyTransfer faz dinheiro?',
      content:
        'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus dolor, fugiat, nulla velit, dolorum veniam accusantium minima iusto nesciunt autem reiciendis. Ea recusandae magnam sunt fuga maxime et totam quisquam?',
      index: 4
    },
    {
      title: 'O que a EasyTransfer tem de diferente das outras empresas?',
      content:
        'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus dolor, fugiat, nulla velit, dolorum veniam accusantium minima iusto nesciunt autem reiciendis. Ea recusandae magnam sunt fuga maxime et totam quisquam?',
      index: 5
    },
    {
      title: 'É um trabalho integral?',
      content:
        'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Natus dolor, fugiat, nulla velit, dolorum veniam accusantium minima iusto nesciunt autem reiciendis. Ea recusandae magnam sunt fuga maxime et totam quisquam?',
      index: 6
    }
  ]

  return (
    <SitePrincipalCadastroDeMRoot>
      <Navbar />
      <SectionHero>
        <ImgShapebackgroundIcon alt="" src="/imgshapebackground.svg" />
        <Div1>
          <Span data-animate-on-scroll>
            <VenhaSerUm
              dangerouslySetInnerHTML={{ __html: t('company.becomePartner') }}
            />
          </Span>
          <H>{t('company.expandYourBusiness')}</H>
          <InfosHero id="ContainerBadgesHero">
            <Div>
              <LucidemapPinnedIcon alt="" src="/lucidemappinned.svg" />
              <DivChild alt="" src="/vector-29.svg" />
              <H6Oferecidas>
                <ViagensLongas>{t('longTripsOffered')}</ViagensLongas>
              </H6Oferecidas>
            </Div>
            <Div>
              <LucidemapPinnedIcon alt="" src="/lucidewallet.svg" />
              <DivChild alt="" src="/vector-29.svg" />
              <H6Oferecidas>
                <ViagensLongas>{t('weeklyPayments')}</ViagensLongas>
              </H6Oferecidas>
            </Div>
            <Div>
              <LucidemapPinnedIcon alt="" src="/lucideactivity.svg" />
              <DivChild alt="" src="/vector-29.svg" />
              <H6Oferecidas>
                <ViagensLongas>{t('earningsManagement')}</ViagensLongas>
              </H6Oferecidas>
            </Div>
          </InfosHero>
          <Buttonstartnow onClick={() => navigate('formulario')}>
            <ConheaAFrota>{t('signUp')}</ConheaAFrota>
            <LucidearrowUpRightIcon alt="" src="/lucidearrowupright.svg" />
          </Buttonstartnow>
        </Div1>
        <ImgIcon id="heroBanner001" alt="" src="/cars_black_with_driver2222.webp" />
      </SectionHero>
      <Sectionbenefits>
        <H1>
          <VantagensDeSer
            dangerouslySetInnerHTML={{ __html: t('company.partnerAdvantages') }}
          />
        </H1>
        <Container2Columns />
        <FrameParent>
          <H4Parent>
            <H2>{t('company.flexibleWeeklyCalendar')}</H2>
            <P>
              <PTxt>
                <ComANossaAbordagemInovador>
                  <ComANossa
                    dangerouslySetInnerHTML={{
                      __html: t('company.innovativeApproach')
                    }}
                  />
                </ComANossaAbordagemInovador>
                <ComANossaAbordagemInovador>
                  <VantagensDeSer
                    dangerouslySetInnerHTML={{
                      __html: t('company.routeOptimization')
                    }}
                  />
                </ComANossaAbordagemInovador>
                <ComANossaAbordagemInovador>
                  <VantagensDeSer
                    dangerouslySetInnerHTML={{
                      __html: t('company.transformDailyExperience')
                    }}
                  />
                </ComANossaAbordagemInovador>
              </PTxt>
            </P>
          </H4Parent>
          <ImgIcon1 alt="" src="/enterprise-recruiter-2.svg" />
        </FrameParent>
        <ImgParent>
          <ImgIcon1 alt="" src="/img2@2x.png" />
          <H4Group>
            <H3>{t('company.trainedDrivers')}</H3>
            <P1>
              <PTxt>
                <ComANossaAbordagemInovador>
                  <ComANossa
                    dangerouslySetInnerHTML={{
                      __html: t('company.specializedTraining')
                    }}
                  />
                </ComANossaAbordagemInovador>
                <ComANossaAbordagemInovador>
                  <VantagensDeSer>&nbsp;</VantagensDeSer>
                </ComANossaAbordagemInovador>
                <ComANossaAbordagemInovador>
                  <VantagensDeSer
                    dangerouslySetInnerHTML={{
                      __html: t('company.qualityElevation')
                    }}
                  />
                  <ComANossa>.</ComANossa>
                </ComANossaAbordagemInovador>
              </PTxt>
            </P1>
          </H4Group>
        </ImgParent>
        <FrameParent>
          <H4Container>
            <H2>{t('weeklyPaymentsSupport')}</H2>
            <P2>
              <PTxt>
                <ComANossaAbordagemInovador>
                  <VantagensDeSer
                    dangerouslySetInnerHTML={{
                      __html: t('company.weeklyPaymentsDescription')
                    }}
                  />
                </ComANossaAbordagemInovador>
                <ComANossaAbordagemInovador>
                  <VantagensDeSer>&nbsp;</VantagensDeSer>
                </ComANossaAbordagemInovador>
              </PTxt>
            </P2>
          </H4Container>
          <ImgIcon1 alt="" src="/img3@2x.png" />
        </FrameParent>
      </Sectionbenefits>
      <Sectionprocess>
        <ImgIcon2 alt="" src="/img4@2x.png" />
        <ProcessDivContainer />
      </Sectionprocess>
      <Sectionhowworks>
        <H4>{t('howItWorks')}</H4>
        <FrameContainer>
          <FrameDiv>
            <Wrapper>
              <B>01</B>
            </Wrapper>
            <H501Parent>
              <H5>{t('receiveTripsOnPlatform')}</H5>
              <P3>{t('chooseTripDateTime')}</P3>
            </H501Parent>
          </FrameDiv>
          <FrameParent1>
            <Wrapper>
              <B>02</B>
            </Wrapper>
            <H501Parent>
              <H6>{t('acceptAndCompleteTrip')}</H6>
              <P3>{t('earningsValidCompletion')}</P3>
            </H501Parent>
          </FrameParent1>
          <FrameParent2>
            <Wrapper>
              <B>03</B>
            </Wrapper>
            <H503Parent>
              <H6>{t('receivePayment')}</H6>
              <P3>{t('paymentMethod')}</P3>
            </H503Parent>
          </FrameParent2>
        </FrameContainer>
        <ImgIcon3 alt="" src="/smartphone_drivers_page2.webp" />
      </Sectionhowworks>
      <Faq accordionData={accordionData} />
      <Sectionstartnow>
        <Containersingup>
          <H3Parent>
            <H8>{t('signUpNow')}</H8>
            <H9>{t('earnMoneyAndWorkWhenWant')}</H9>
          </H3Parent>
          <Buttonstartnow1 onClick={() => navigate('formulario')}>
            <ConheaAFrota1>{t('startNow')}</ConheaAFrota1>
            <LucidearrowUpRightIcon alt="" src="/lucidearrowright.svg" />
          </Buttonstartnow1>
        </Containersingup>
      </Sectionstartnow>
      <Footer />
    </SitePrincipalCadastroDeMRoot>
  )
}
