import styled from 'styled-components'

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ opened }) => (opened ? 'block' : 'none')};
  z-index: 100;
`

export const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 444px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-end;
  z-index: 200;

  gap: 10px;
  border-radius: 22px;
  background: #fff;
  box-shadow: 0px 22px 24px 0px rgba(0, 0, 0, 0.07),
    0px -1px 16px 0px rgba(0, 0, 0, 0.05);
  display: ${({ opened }) => (opened ? 'flex' : 'none')};
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  margin-bottom: 40px;
`
export const ModalTitle = styled.p`
  color: var(--grey-1-grayscale, #404040);
  text-align: center;
  font-family: Neometric;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 18.2px */
  letter-spacing: 0.14px;
`

export const ModalButton = styled.button`
  display: flex;
  height: 48px;
  padding: 24px 32px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 16px;
  background: var(--yellow-brand-primary, #27c7ff);
  color: white;
`

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CloseButton = styled.button`
  width: 32px;
  height: 32px;
  background-color: transparent;
`
export const Observation = styled.div`
  color: var(--yellow-3-support, #1D95BF);
  font-family: Neometric;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 8px;
  background: var(--yellow-2-support, #DFF7FF);
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`
export const Motive = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const MotiveTitle = styled.h1`
  color: var(--black-brand-primary, #222);
  font-family: Neometric;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
export const Options = styled.div`
  color: var(--grey-1-grayscale, #404040);
  font-family: Neometric;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  gap: 10px;
  p {
    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  span {
    color: var(--grey-1-grayscale, #404040);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .mantine-Checkbox-label {
    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`
