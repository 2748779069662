import React, { useState } from 'react'
import * as Styled from './styles'

import { PendingCards } from './components/PendingCards'
import { createStyles, rem, Input, SegmentedControl } from '@mantine/core'
import Search from '../../../../assets/Search.webp'
import { ReactComponent as ChevronDown } from './imgs/chevron-down.svg'
import { AccomplishedCards } from './components/AccomplishedCards'
import { DatePickerInput } from '@mantine/dates'
import { ReactComponent as Calendar } from './imgs/calendar.svg'
import debounce from '../../../../utils/debounce'
import { useMediaQuery } from '../../../../hooks/useMediaQuery'

const HEADER_HEIGHT = rem(84)

const useStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0
  },

  // burger: {
  //   [theme.fn.largerThan('sm')]: {
  //     display: 'none'
  //   }
  // },

  links: {
    paddingTop: theme.spacing.lg,
    // height: HEADER_HEIGHT,
    display: 'flex',
    padding: 0,
    textDecoration: 'none',
    justifyContent: 'space-between',
    alignItems: 'center',

    // [theme.fn.smallerThan('sm')]: {
    //   display: 'none'
    // }

    '@media (max-width: 500px)': {
      flexWrap: 'wrap',
      gap: '16px'
    }
  },

  mainLinks: {
    marginRight: `calc(${theme.spacing.sm} * -1)`
  },

  mainLink: {
    color: '#404040',
    padding: `${rem(7)} ${theme.spacing.sm}`,
    transition: 'border-color 100ms ease, color 100ms ease',
    borderBottom: '1px solid var(--grey-3-grayscale, #BFBFBF)',
    textDecoration: 'none',
    fontFamily: 'Neometric',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '130%',
    letterSpacing: '0.16px',
    display: 'flex',
    gap: '5px',

    '&:hover': {
      textDecoration: 'none'
    }
  },

  secondaryLink: {
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[2]
        : theme.colors.gray[6],
    fontSize: theme.fontSizes.xs,
    textTransform: 'uppercase',
    transition: 'color 100ms ease'

    // '&:hover': {
    //   color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    //   textDecoration: 'none'
    // }
  },

  mainLinkActive: {
    color: '#27c7ff',
    borderBottomColor: '4px solid var(--yellow-brand-primary, #27c7ff)',
    fontFamily: 'Neometric',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '130%',
    letterSpacing: '0.16px'
  }
}))

const currentDate = new Date()
const tomorrowDate = new Date()
tomorrowDate.setDate(currentDate.getDate() + 1)

export const TransferHostel = () => {
  const [filter, setFilter] = useState('PENDING')
  const { classes } = useStyles()
  const [search, setSearch] = useState('')
  const [dateFilter, setDateFilter] = useState('')
  const [customDate, setCustomDate] = useState(undefined)

  const isMobile = useMediaQuery('(max-width: 500px)')

  const date = customDate
    ? customDate
    : dateFilter === 'today'
    ? currentDate
    : dateFilter === 'tomorrow'
    ? tomorrowDate
    : undefined
  console.log(filter, 'FILTER')
  return (
    <Styled.Container>
      <Styled.HeaderContainer>
        <Styled.Title>Transfers</Styled.Title>
      </Styled.HeaderContainer>
      <Styled.ButtonsWrapper>
        <div className={classes.links}>
          <Styled.FiltersWrapper>
            <div className="filtes-wrapper">
              {!isMobile && (
                <Input.Wrapper id="filters">
                  <Input
                    id="input-demo"
                    placeholder="Pesquisar por ID"
                    size={'lg'}
                    rightSection={
                      <img
                        style={{ cursor: 'pointer' }}
                        // onClick={() => setGoing({ ...going, flightNumber: '' })}
                        width={18}
                        height={18}
                        src={Search}
                      />
                    }
                    onChange={(e) => {
                      debounce(() => setSearch(e.target.value), 500)
                    }}
                  />
                </Input.Wrapper>
              )}

              <div className="date-filter">
                <SegmentedControl
                  value={dateFilter}
                  onClickCapture={(e) => {
                    const target = e.target

                    if (
                      target.value &&
                      ['tomorrow', 'today'].includes(target.value)
                    ) {
                      setCustomDate(undefined)

                      if (dateFilter === target.value) {
                        setDateFilter('')
                      } else {
                        setDateFilter(target.value)
                      }
                    }
                  }}
                  // onChange={(value) => {
                  //   setDateFilter(value)
                  // }}
                  data={[
                    { label: 'Hoje', value: 'today' },
                    { label: 'Amanhã', value: 'tomorrow' }
                  ]}
                />
                <DatePickerInput
                  clearable
                  size="xl"
                  valueFormat="DD/MM/YYYY"
                  key={customDate}
                  className={customDate ? 'active' : undefined}
                  placeholder={'Selecionar data'}
                  onFocus={() => setDateFilter('')}
                  value={customDate}
                  onChange={(e) => setCustomDate(e)}
                />
              </div>
            </div>
            {!isMobile && (
              <Input
                id="select-filter"
                component="select"
                rightSection={<ChevronDown />}
                pointer
                mt="md"
                onChange={(e) => setFilter(e.target.value)}
              >
                {/* <option value="">Filtro</option> */}
                <option value="PENDING">Pendente</option>
                <option value="DONE">Realizado</option>
              </Input>
            )}
          </Styled.FiltersWrapper>
          {isMobile && (
            <Styled.InputsWrapper>
              <Input
                id="select-filter"
                component="select"
                rightSection={<ChevronDown />}
                pointer
                mt="md"
                onChange={(e) => setFilter(e.target.value)}
              >
                {/* <option value="">Filtro</option> */}
                <option value="PENDING">Pendente</option>
                <option value="DONE">Realizado</option>
              </Input>
              <Input.Wrapper id="filters">
                <Input
                  id="input-demo"
                  placeholder="Pesquisar por ID"
                  size={'lg'}
                  rightSection={
                    <img
                      style={{ cursor: 'pointer' }}
                      // onClick={() => setGoing({ ...going, flightNumber: '' })}
                      width={18}
                      height={18}
                      src={Search}
                    />
                  }
                  onChange={(e) => {
                    debounce(() => setSearch(e.target.value), 500)
                  }}
                />
              </Input.Wrapper>
            </Styled.InputsWrapper>
          )}
        </div>
      </Styled.ButtonsWrapper>

      <PendingCards status={filter} search={search} date={date} />
    </Styled.Container>
  )
}
