import { Header } from './Header'
import { Steppers } from './components/Steppers'
import { Checked } from './components/Checked'
import { ChoicedCar } from './components/ChoicedCar'
import { OrderSummaryResume } from './components/OrderSummaryResume'
import { OrderSummary } from './components/OrderSummary'
import { Tours } from './components/Tours'
import * as Styled from './styles'
import { useTransferContext } from '../../contexts/TransferContext'
import { Navigate, useNavigate } from 'react-router-dom'
import { Navbar } from '../../components/Navbar'
import Footer from '../../components/Footer'
import { useMediaQuery } from '../../hooks/useMediaQuery'
import { InPortal, createHtmlPortalNode } from 'react-reverse-portal'
import { ChoiceCar } from './components/ChoiceCar'
import { useEffect } from 'react'
import { NavbarCheckout } from '../../components/NavbarCheckout'

const portalNode = createHtmlPortalNode()

export const Checkout4 = () => {
  const { going, carSelected } = useTransferContext()
  const navigate = useNavigate()

  const mobileLayoutMatch = useMediaQuery('(max-width: 944px)')

  useEffect(() => {
    if (going.paymentRedirect) {
      window.location.href = going.paymentRedirect
    }
  }, [navigate, going.paymentRedirect])

  console.log(going, 'GOIN')

  // PAGAMENTO EM PROCESSO

  return !going.dateTime ? (
    <Navigate to="/" />
  ) : (
    <>
      <div style={{ maxWidth: 988, margin: '0 auto' }}>
        <NavbarCheckout />
      </div>
      <Styled.Container>
        <Steppers stepper={3} />
        <Styled.ContentContainer>
          {mobileLayoutMatch ? (
            <Styled.MiddleContainerRow>
              <InPortal node={portalNode}>
                <ChoiceCar selectedCar={carSelected} />
              </InPortal>
              <OrderSummary portalNode={portalNode} reservation />
            </Styled.MiddleContainerRow>
          ) : (
            <Styled.MiddleContainerColumn>
              <Checked />
              <ChoicedCar />
              <OrderSummaryResume />
              <Tours />
            </Styled.MiddleContainerColumn>
          )}
        </Styled.ContentContainer>
      </Styled.Container>
      <Footer />
    </>
  )
}
