import React from 'react'
import {
  BookNowButton,
  ColumnWrapper,
  Container,
  EasyTransferLogo,
  OrangeContainerSeparator,
  OrangeHorizontalContainer,
  OrangeVerticalContainer,
  OrangeVerticalWrapper,
  Price,
  PriceButton,
  Text,
  TextWrapper,
  TextWrapperSeparator,
  WhiteContainer,
  WhiteContainerSeparator,
  MobileTableContainer,
  MobileContainer,
  MobileTableHeader,
  MobileTextContainer,
  EasyTransferContainer,
  MobileTableContentsContainer,
  MobileTableContents,
  MobileTableContentHeader,
  MobileTableChecks
} from './styles'
import Logo from './imgs/EasyTransfer - logo black.svg'
import { ReactComponent as CheckCircleWhite } from '../../../../assets/check_circle_fill.svg'
import { ReactComponent as CheckCircleOrange } from '../../../../assets/check_circle_orange.svg'
import { ReactComponent as XCircle } from '../../../../assets/x_circle.svg'
import { ReactComponent as UberLogo } from '../../../../assets/uber_logo.svg'
import { ReactComponent as TaxiLogo } from '../../../../assets/taxi_logo.svg'
import { ReactComponent as Frame } from '../../../../assets/Frame1171275237.svg'
import { ReactComponent as TableComparativePrices } from './imgs/TableComparativePrices.svg'
import { ReactComponent as SeparatorVector } from '../../../../assets/Vector13.svg'
import { useTranslation } from 'react-i18next'

export default function Competitors() {
  const { t } = useTranslation()

  return (
    <Container>
      {/* <MobileTableContainer>
        <TableComparativePrices />
      </MobileTableContainer> */}
      <MobileContainer>
        <MobileTableHeader>
          <MobileTextContainer>
            <UberLogo />
          </MobileTextContainer>
          <EasyTransferContainer>
            <h1>EasyTransfer</h1>
          </EasyTransferContainer>
          <MobileTextContainer>
            <TaxiLogo />
          </MobileTextContainer>
        </MobileTableHeader>
        <MobileTableContentsContainer>
          <MobileTableContentHeader>
            {t('fluentDriver')}
          </MobileTableContentHeader>
          <MobileTableContents>
            <MobileTableChecks>
              <XCircle />
            </MobileTableChecks>
            <Frame id="frame-vector" />
            <MobileTableChecks>
              <CheckCircleOrange />
            </MobileTableChecks>
            <Frame id="frame-vector" />
            <MobileTableChecks>
              <XCircle />
            </MobileTableChecks>
          </MobileTableContents>
          <MobileTableContentHeader>
            {t('transferAndTaxiService')}
          </MobileTableContentHeader>
          <MobileTableContents>
            <MobileTableChecks>
              <XCircle />
            </MobileTableChecks>
            <Frame id="frame-vector" />
            <MobileTableChecks>
              <CheckCircleOrange />
            </MobileTableChecks>
            <Frame id="frame-vector" />
            <MobileTableChecks>
              <XCircle />
            </MobileTableChecks>
          </MobileTableContents>
          <MobileTableContentHeader>
            {t('tourGuideService')}
          </MobileTableContentHeader>
          <MobileTableContents>
            <MobileTableChecks>
              <XCircle />
            </MobileTableChecks>
            <Frame id="frame-vector" />
            <MobileTableChecks>
              <CheckCircleOrange />
            </MobileTableChecks>
            <Frame id="frame-vector" />
            <MobileTableChecks>
              <XCircle />
            </MobileTableChecks>
          </MobileTableContents>
          <MobileTableContentHeader>
            {t('airTourService')}
          </MobileTableContentHeader>
          <MobileTableContents>
            <MobileTableChecks>
              <XCircle />
            </MobileTableChecks>
            <Frame id="frame-vector" />
            <MobileTableChecks>
              <CheckCircleOrange />
            </MobileTableChecks>
            <Frame id="frame-vector" />
            <MobileTableChecks>
              <XCircle />
            </MobileTableChecks>
          </MobileTableContents>
          <MobileTableContentHeader>{t('driverChat')}</MobileTableContentHeader>
          <MobileTableContents>
            <MobileTableChecks>
              <XCircle />
            </MobileTableChecks>
            <Frame id="frame-vector" />
            <MobileTableChecks>
              <CheckCircleOrange />
            </MobileTableChecks>
            <Frame id="frame-vector" />
            <MobileTableChecks>
              <XCircle />
            </MobileTableChecks>
          </MobileTableContents>
          <MobileTableContentHeader>
            {t('24HoursSupport')}
          </MobileTableContentHeader>
          <MobileTableContents>
            <MobileTableChecks>
              <XCircle />
            </MobileTableChecks>
            <Frame id="frame-vector" />
            <MobileTableChecks>
              <CheckCircleOrange />
            </MobileTableChecks>
            <Frame id="frame-vector" />
            <MobileTableChecks>
              <XCircle />
            </MobileTableChecks>
          </MobileTableContents>
        </MobileTableContentsContainer>
      </MobileContainer>
      <OrangeHorizontalContainer>
        <TextWrapper>
          <Text>{t('fluentDriver')}</Text>
          <TextWrapperSeparator />
          <Text>{t('transferAndTaxiService')}</Text>
          <TextWrapperSeparator />
          <Text>{t('tourGuideService')}</Text>
          <TextWrapperSeparator />
          <Text>{t('airTourService')}</Text>
          <TextWrapperSeparator />
          <Text>{t('driverChat')}</Text>
          <TextWrapperSeparator />
          <Text>{t('24HoursSupport')}</Text>
        </TextWrapper>
        <ColumnWrapper>
          <UberLogo />
          <PriceButton>€ 40-97</PriceButton>
          <WhiteContainer>
            <XCircle />
            <WhiteContainerSeparator />
            <CheckCircleOrange />
            <WhiteContainerSeparator />
            <XCircle />
            <WhiteContainerSeparator />
            <XCircle />
            <WhiteContainerSeparator />
            <CheckCircleOrange />
            <WhiteContainerSeparator />
            <CheckCircleOrange />
          </WhiteContainer>
        </ColumnWrapper>
        <OrangeVerticalContainer>
          <EasyTransferLogo src={Logo} alt="Logotipo EasyTransfer" />
          <Price>€ 35-55</Price>
          <OrangeVerticalWrapper>
            <CheckCircleWhite />
            <OrangeContainerSeparator />
            <CheckCircleWhite />
            <OrangeContainerSeparator />
            <CheckCircleWhite />
            <OrangeContainerSeparator />
            <CheckCircleWhite />
            <OrangeContainerSeparator />
            <CheckCircleWhite />
            <OrangeContainerSeparator />
            <CheckCircleWhite />
          </OrangeVerticalWrapper>
          <BookNowButton href="#transfer">
            {t('greatExperienceButtonText') + '!'}
          </BookNowButton>
        </OrangeVerticalContainer>
        <ColumnWrapper>
          <TaxiLogo />
          <PriceButton>€ 65-95</PriceButton>
          <WhiteContainer>
            <XCircle />
            <WhiteContainerSeparator />
            <CheckCircleOrange />
            <WhiteContainerSeparator />
            <CheckCircleOrange />
            <WhiteContainerSeparator />
            <XCircle />
            <WhiteContainerSeparator />
            <XCircle />
            <WhiteContainerSeparator />
            <XCircle />
          </WhiteContainer>
        </ColumnWrapper>
      </OrangeHorizontalContainer>
    </Container>
  )
}
