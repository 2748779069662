import {
  Button,
  Calendar,
  Card,
  CardContent,
  Input,
  Label,
  Popover,
  PopoverButton,
  PopoverContent,
  PopoverTrigger,
  Textarea,
  ToggleGroup,
  ToggleGroupItem,
  Wrapper,
  ToggleLabel
} from './styles'

// import { env } from '@/env'
import { cn } from '../../../../utils/cn'
import { zodResolver } from '@hookform/resolvers/zod'
import axios from 'axios'
import { format } from 'date-fns'
import pt from 'date-fns/locale/pt'
import {
  LucideArrowUpRight,
  LucideCalendarDays,
  LucideCar,
  LucideMail,
  LucideMapPin,
  LucideMenuSquare,
  LucidePhone,
  LucideUser2
} from 'lucide-react'
import { useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { z } from 'zod'

import { vehicles } from './constants'
import { useTranslation } from 'react-i18next'

const requestBudgetSchema = z.object({
  passengerName: z
    .string()
    .min(3, { message: 'Insira teu nome com ao menos 3 caracteres.' }),
  passengerPhoneNumber: z
    .string()
    .min(10, { message: 'Insira um telefone válido. Ex: (+351) 000000000' }),
  passengerEmail: z.string().email({ message: 'Insira um e-mail válido.' }),
  startingPlace: z
    .string()
    .min(3, { message: 'Insira um ponto de partida válido.' }),
  arrivalPlace: z
    .string()
    .min(3, { message: 'Insira um ponto de chegada válido.' }),
  transferDate: z.date({ required_error: 'Insira uma data válida.' }),
  vehicleType: z.enum(
    ['SedanExecutivo', 'VanExecutiva', 'Limousine', 'Autocarro'],
    {
      required_error: 'Escolha uma viatura.',
      invalid_type_error: 'Escolha uma viatura.'
    }
  ),
  message: z
    .string()
    .min(5, { message: 'Por favor dê mais detalhes sobre teu pedido.' })
})

export const BudgetRequest = ({ defineBudgetRef }) => {
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, errors }
  } = useForm({
    resolver: zodResolver(requestBudgetSchema)
  })

  const budgetRef = useRef(null)

  const requestBudget = async (data) => {
    try {
      await axios.post(
        'https://connect.pabbly.com/workflow/sendwebhookdata/IjU3NjYwNTZkMDYzNjA0M2Q1MjZiNTUzMDUxMzYi_pc',
        JSON.stringify(data)
      )

      toast.success(t('toast.successRequest'), {
        theme: 'dark'
      })
    } catch (err) {
      console.error(err)

      toast.error(
        t('toast.errorRequest'),
        { theme: 'dark' }
      )
    }
  }

  useEffect(() => {
    defineBudgetRef(budgetRef)
  }, [defineBudgetRef])

  return (
    <Wrapper ref={budgetRef}>
      <h2>{t('events.quoteRequest')}</h2>

      <form onSubmit={handleSubmit(requestBudget)}>
        <div>
          <div>
            <Label>
              <LucideUser2 />
              {t('events.fullName')}
            </Label>

            <div>
              <Input
                placeholder={t('events.enterFullName')}
                {...register('passengerName')}
              />

              {errors?.passengerName && <p>{errors.passengerName.message}</p>}
            </div>
          </div>

          <div>
            <Label>
              <LucideMail />
              {t('events.email')}
            </Label>

            <div>
              <Input
                placeholder={t('events.enterBestEmail')}
                {...register('passengerEmail')}
              />

              {errors?.passengerEmail && <p>{errors.passengerEmail.message}</p>}
            </div>
          </div>

          <div>
            <Label>
              <LucidePhone />
              {t('events.phoneWhatsApp')}
            </Label>

            <div>
              <Input
                placeholder="(+351) 000000000"
                {...register('passengerPhoneNumber')}
              />

              {errors?.passengerPhoneNumber && (
                <p>{errors.passengerPhoneNumber.message}</p>
              )}
            </div>
          </div>

          <div>
            <Label>
              <LucideCalendarDays />
              {t('events.date')}
            </Label>

            <div>
              <Controller
                control={control}
                name="transferDate"
                render={({ field }) => (
                  <Popover {...register('transferDate')}>
                    <PopoverTrigger asChild>
                      <PopoverButton
                        variant={'outline'}
                        notValue={!field.value}
                      >
                        {field.value ? (
                          format(field.value, 'PPP', { locale: pt })
                        ) : (
                          <span>1 de janeiro de 2024</span>
                        )}
                      </PopoverButton>
                    </PopoverTrigger>
                    <PopoverContent>
                      <Calendar
                        mode="single"
                        darkMode
                        locale={pt}
                        selected={field.value}
                        onSelect={field.onChange}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                )}
              />

              {errors?.transferDate && <p>{errors.transferDate.message}</p>}
            </div>
          </div>

          <div>
            <Label>
              <LucideMapPin />
              {t('events.departureLocation')}
            </Label>

            <div>
              <Input
                placeholder="Tua localização"
                {...register('startingPlace')}
              />

              {errors?.startingPlace && <p>{errors.startingPlace.message}</p>}
            </div>
          </div>

          <div>
            <Label>
              <LucideMapPin />
              {t('tours.returnLocation')}
            </Label>

            <div>
              <Input
                placeholder={t('tours.returnLocation')}
                {...register('arrivalPlace')}
              />

              {errors?.arrivalPlace && <p>{errors.arrivalPlace.message}</p>}
            </div>
          </div>
        </div>

        <div className="space-y-4">
          <Label>
            <LucideCar />
            {t('events.vehicle')}
          </Label>

          <div>
            <Controller
              control={control}
              name="vehicleType"
              render={({ field }) => (
                <ToggleGroup
                  type="single"
                  variant={'secondary'}
                  onValueChange={field.onChange}
                  value={field.value}
                  {...register('vehicleType')}
                >
                  {vehicles(t).map(({ imgSrc, name, value }) => (
                    <ToggleGroupItem key={value} value={value} asChild>
                      <Card>
                        <CardContent>
                          <img src={imgSrc} alt="" />

                          <ToggleLabel>{name}</ToggleLabel>
                        </CardContent>
                      </Card>
                    </ToggleGroupItem>
                  ))}
                </ToggleGroup>
              )}
            />

            {errors?.vehicleType && <p>Escolha uma viatura.</p>}
          </div>
        </div>

        <div className="space-y-4">
          <Label>
            <LucideMenuSquare />
            {t('events.message')}
          </Label>

          <div>
            <Textarea
              placeholder={t('events.writeDetails')}
              {...register('message')}
            />

            {errors?.message && <p>{errors.message.message}</p>}
          </div>
        </div>

        <div>
          <Button type="submit" disabled={isSubmitting}>
            {t('corporate.send')}
            <LucideArrowUpRight className="size-5" />
          </Button>
        </div>
      </form>
    </Wrapper>
  )
}
