import styled from 'styled-components'
export const Container = styled.div`
  min-width: 567.88px;
  box-shadow: 0px -1px 16px rgba(0, 0, 0, 0.05),
    0px 22px 24px rgba(0, 0, 0, 0.07);
  border-radius: 22px;
  padding: 3rem;

  h1 {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 2rem;
    line-height: 120%;

    letter-spacing: 0.01em;

    color: #0d0d0d;
  }

  @media only screen and (max-width: 944px) {
    max-width: 720px;
    min-width: 0;
    width: 100%;
    padding: 1.6rem;

    h1 {
      font-size: 2rem;
    }
  }

  @media only screen and (max-width: 450px) {
    .mantine-Input-input,
    .form-control {
      height: 4.8rem !important;
      font-size: 1.4rem;
      &::placeholder {
        font-size: 1.4rem !important;
      }
    }

    .mantine-Input-input {
      padding-right: 3rem;
    }
  }

  label {
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 19px;

    color: #0d0d0d;
    margin: 10px 0;
  }

  input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12.1898px 8px;
    gap: 112.77px;

    width: 100%;
    height: 4.8rem;

    border: 0.704782px solid rgba(56, 40, 14, 0.3);
    border-radius: 8.45738px;

    margin-bottom: 10px;

    font-family: 'Neometric';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 19px;

    color: #0d0d0d;

    &:placeholder {
      color: #a2a2a2;
    }

    &:focus {
      border-color: #27c7ff;
    }
  }
`

export const CardNumber = styled.div`
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 8px;

  margin: 10px 0;

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;

    display: flex;
    align-items: center;
    letter-spacing: 0.01em;

    color: #a2a2a2;
  }
`

export const NumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .mantine-NumberInput-input {
    padding: 16px;
    border: 0.5px solid rgba(56, 40, 14, 0.3);
    border-radius: 6px;
  }

  .btn-plus {
    position: absolute;
    right: 0.5rem;
    align-items: center;
  }
  .btn-plus-checked {
    position: absolute;
    right: 0.5rem;
    background: rgba(162, 162, 162, 0.4);
    border: 0.5px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    width: 22.55px !important;
    height: 22.55px !important;
    min-width: 22.55px !important;
    min-height: 22.55px !important;
    align-items: center;
    font-size: 22px;
    color: #0d0d0d;
  }
  .btn-minus {
    position: absolute;
    left: 0.5rem;
    z-index: 99;
    align-items: flex-end;
  }
`

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3rem;

  margin-top: 20px;
`

export const CheckboxOptionsContainer = styled.div`
  margin-top: 10px;

  .mantine-Checkbox-body {
    align-items: center;

    label {
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;

      letter-spacing: 0.01em;
      margin-bottom: 10px;

      color: #000000;
    }
  }

  .mantine-Checkbox-input {
    display: flex;
    align-items: flex-start;
    padding: 1.44609px;
    gap: 7.23px;

    width: 20px;
    height: 20px;
    border-radius: 5.78435px;
    cursor: pointer;
  }

  .mantine-Checkbox-input:checked {
    background: #27c7ff;
    border-color: #27c7ff;

    svg {
      width: 11.41px;
    }
  }
`

export const ChildCardsWrapper = styled.div`
  margin-top: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 17.35px;

  width: 100%;
`

export const ChildCardContainer = styled.div`
  .mantine-Checkbox-input {
    width: 16.63px;
    height: 16.63px;
    border-radius: 16.63px;

    position: absolute;
    top: 17px;
    left: 5px;
  }
`

export const ChildCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding: 8px 0px;
  gap: 2.89px;

  width: 166.3px;
  height: 166.3px;
  background: #ffffff;
  border: 0.723044px solid #bfbfbf;
  border-radius: 11.5687px;
`

export const ChildCardText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5.06px;

  width: 124px;
  height: 67.62px;

  h1 {
    width: 180px;
    height: 17px;
    margin-bottom: 0;

    font-weight: 600;
    font-size: 14px;
    line-height: 120%;

    letter-spacing: 0.01em;

    color: #38280e;
    text-align: center;
  }

  span {
    font-weight: 300;
    font-size: 10px;
    line-height: 120%;

    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #808080;
  }
`

export const ChildCardImg = styled.img`
  height: 80.98px;
`

export const NumberSmallContainer = styled.div`
  .mantine-NumberInput-input {
    width: 85.82px;
    height: 28.5px;
    padding: 8.24794px 7.80189px 8.24794px 8.24794px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 54.99px;

    border: 0.343664px solid #bfbfbf;
    border-radius: 4.12397px;

    font-weight: 500;
    font-size: 10.1226px;
    line-height: 12px;

    color: #a2a2a2;
  }

  .btn-plus {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5.49863px;
    gap: 6.87px;

    width: 11px;
    height: 11px;

    background: rgba(162, 162, 162, 0.4);
    border: 0.343664px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 2.74932px 4.12397px rgba(0, 0, 0, 0.07);
    border-radius: 10.9973px;

    position: absolute;
    right: 0.5rem;
    top: 0.75rem;
  }
  .btn-plus-checked {
    position: absolute;
    right: 0.5rem;
    background: rgba(162, 162, 162, 0.4);
    border: 0.5px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.07);
    border-radius: 16px;
    width: 22.55px !important;
    height: 22.55px !important;
    min-width: 22.55px !important;
    min-height: 22.55px !important;
    align-items: center;
    font-size: 22px;
    color: #0d0d0d;
  }
  .btn-minus {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5.49863px;
    gap: 6.87px;

    width: 11px;
    height: 11px;

    background: rgba(162, 162, 162, 0.4);
    border: 0.343664px solid rgba(56, 40, 14, 0.3);
    box-shadow: 0px 2.74932px 4.12397px rgba(0, 0, 0, 0.07);
    border-radius: 10.9973px;
    position: absolute;
    left: 0.5rem;
    top: 0.75rem;
    z-index: 99;
  }
`

export const CheckedItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 16px 0px;
  gap: 17px;

  height: 125px;
  margin-top: 20px;

  .mantine-Checkbox-body {
    align-items: center;

    label {
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;

      letter-spacing: 0.01em;

      color: #000000;
    }
  }

  .mantine-Checkbox-input {
    display: flex;
    align-items: flex-start;
    padding: 1.44609px;
    gap: 7.23px;

    width: 20px;
    height: 20px;
    border-radius: 5.78435px;
    cursor: pointer;
  }

  .mantine-Checkbox-input:checked {
    background: #27c7ff;
    border-color: #27c7ff;

    svg {
      width: 11.41px;
    }
  }
`

export const CheckedItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 4px;

  height: 40px;

  background: #DFF7FF;
  border-radius: 8px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  margin-top: 6rem;
`

export const JumpButton = styled.button`
  font-family: 'Roboto' !important;
  padding: 23.0611px 17.2958px;
  border: 2px solid #27c7ff;
  border-radius: 16px;
  filter: drop-shadow(0px 4px 8px rgba(223, 247, 255, 0.5));
  background-color: transparent;
  width: 216.2px;
  height: 53.59px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 700;
  font-size: 16px;
  line-height: 120%;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  color: #27c7ff;
`

export const SaveButton = styled.button`
  width: 100%;
  height: 53.59px;
  margin-top: -10px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 17.2958px 23.0611px;

  background: #27c7ff;
  border-radius: 11.5305px;

  font-family: 'Roboto' !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-transform: uppercase;

  color: #ffffff;
`
