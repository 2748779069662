import { useTranslation } from 'react-i18next'
import { Card, CardContent, CardHeader } from './styles'
import { LucidePhone, LucideMail } from 'lucide-react'

export const ContactInfo = () => {
  const { t } = useTranslation()
  return (
    <Card>
      <CardHeader>
        <h3>{t('events.anyQuestions')}</h3>
        <span>{t('events.callNow')}</span>
      </CardHeader>

      <CardContent>
        <div>
          <LucidePhone />
          <span>+351 963 650 278</span>
        </div>

        <div>
          <LucideMail />
          <span>geral@easytransferericeira.com</span>
        </div>
      </CardContent>
    </Card>
  )
}
