import * as Styled from './styles'
import { ReactComponent as User } from './imgs/user.svg'
import { ReactComponent as Phone } from './imgs/phone.svg'
import { ReactComponent as Driver } from './imgs/driver.svg'
import { ReactComponent as NoDriver } from './imgs/nodriver.svg'
import fetchBooking from '../../../../../../services/booking'
import { useEffect, useState } from 'react'

import { RejectModal } from '../RejectModal'
import { useAuth } from '../../../../../../contexts/useAuth'
import { useClipboard } from '@mantine/hooks'
import { ArrowUpRight } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import fetchFile from '../../../../../../services/file'

export const DetailsDriver = ({ transfer }) => {
  const [acceptedModal, setAcceptedModal] = useState(false)
  const [rejectModal, setRejectModal] = useState(false)
  const [refreshBookings, setRefreshBookings] = useState(false)
  const [avatar, setAvatar] = useState()

  const clipboard = useClipboard({ timeout: 500 })
  const navigate = useNavigate()

  const { user } = useAuth()

  const handleAcceptTransfer = (value, driver, setDriver) => {
    const payload = {
      id: value.id,
      driver: {
        id: driver ? driver : user.id
      }
    }

    if (user.id) {
      fetchBooking
        .updateBooking(payload)
        .then((res) => {
          if (res) {
            setAcceptedModal(false)
            setRefreshBookings(!refreshBookings)
            if (driver) {
              setDriver()
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  // const handleRejectTransfer = (value) => {
  //   const payload = {
  //     id: value.id,
  //     driver: {
  //       id: user.id
  //     }
  //   }

  //   if (user.id) {
  //     fetchBooking
  //       .updateBooking(payload)
  //       .then((res) => {
  //         console.log(res)
  //       })
  //       .catch((err) => {
  //         console.log(err)
  //       })
  //   }
  // }

  return (
    <>
      {transfer?.driver?.id ? (
        <Styled.CardContainer>
          <Styled.Container key={transfer?.driver?.id}>
            <Styled.Wrapper>
              <Styled.InfoWrapper>
                <Styled.PaymentTitle>
                  <Driver />
                  Motorista
                </Styled.PaymentTitle>
                <Styled.ButtonProfile
                  onClick={() => {
                    navigate(`/dashboard/frota/driver/${transfer?.driver?.id}`)
                  }}
                >
                  Ver perfil <ArrowUpRight color="white" />
                </Styled.ButtonProfile>
              </Styled.InfoWrapper>
              <Styled.PassengerInfoContainer>
                {/* <Styled.Avatar src={transfer.}/> */}
                <Styled.PassengerInputs>
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <User />
                      Nome
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>
                      {transfer?.driver?.name}
                    </Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <User />
                      ID
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>
                      {transfer?.driver?.id}
                    </Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>

                  {/* <Styled.PaymentInfo>
                  <Styled.PaymentInfoTitle>
                    <Email />E - mail
                  </Styled.PaymentInfoTitle>
                  <Styled.PaymentInfoDesc>
                    {transfer?.passengerEmail}
                  </Styled.PaymentInfoDesc>
                </Styled.PaymentInfo> */}
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <Phone />
                      Telemóvel
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>
                      <a href={`tel:${transfer?.driver?.phoneNumber}`}>
                        {transfer?.driver?.phoneNumber}
                      </a>
                    </Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                  {/* <Styled.PaymentInfo>
                  <Styled.PaymentInfoTitle>
                    <User />
                    Nome
                  </Styled.PaymentInfoTitle>
                  <Styled.PaymentInfoDesc>
                    {transfer?.passengerName}
                  </Styled.PaymentInfoDesc>
                </Styled.PaymentInfo> */}
                </Styled.PassengerInputs>
              </Styled.PassengerInfoContainer>
            </Styled.Wrapper>
          </Styled.Container>

          <RejectModal
            opened={rejectModal}
            onClose={() => setRejectModal(false)}
          />
        </Styled.CardContainer>
      ) : (
        <Styled.CardContainer>
          <Styled.Container key={transfer?.driver?.id}>
            <Styled.Wrapper>
              <Styled.InfoWrapper>
                <Styled.PaymentTitle>
                  <Driver />
                  Motorista
                </Styled.PaymentTitle>
              </Styled.InfoWrapper>
              <Styled.WaitingDriver>
                <NoDriver /> Aguardando motorista
              </Styled.WaitingDriver>
            </Styled.Wrapper>
          </Styled.Container>

          <RejectModal
            opened={rejectModal}
            onClose={() => setRejectModal(false)}
          />
        </Styled.CardContainer>
      )}
    </>
  )
}
