import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import GlobalStyles from './styles/global'
import { ThemeProvider } from 'styled-components'
import theme from './styles/theme'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'
import { ToastContainer } from 'react-toastify'
import 'animate.css'
import 'animate.css/animate.min.css'
import TransferProvider from './contexts/TransferContext'
import { AuthProvider } from './contexts/useAuth'
import { MantineProvider } from '@mantine/core'
import { MobileSidebarContextProvider } from './contexts/MoblieSidebarContext'
import 'react-toastify/dist/ReactToastify.css'
import { MapsContextProvider } from './contexts/MapsContext'
import ModalContextProvider from './store/context/ModalContext'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ScrollToTop } from './ScrollToTop'

const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <MapsContextProvider>
          <MantineProvider
            theme={{
              colors: {
                'orange-primary': [
                  '#27c7ff',
                  '#27c7ff',
                  '#27c7ff',
                  '#27c7ff',
                  '#27c7ff',
                  '#27c7ff',
                  '#27c7ff',
                  '#27c7ff',
                  '#27c7ff',
                  '#27c7ff'
                ],
                'green-switch': [
                  '#48D9A4',
                  '#48D9A4',
                  '#48D9A4',
                  '#48D9A4',
                  '#48D9A4',
                  '#48D9A4',
                  '#48D9A4',
                  '#48D9A4',
                  '#48D9A4',
                  '#48D9A4'
                ]
              },
              loader: 'oval'
            }}
          >
            <ModalContextProvider>
              <TransferProvider>
                <ThemeProvider theme={theme}>
                  <I18nextProvider i18n={i18n}>
                    <MobileSidebarContextProvider>
                      <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                      />
                      <QueryClientProvider client={queryClient}>
                        <ScrollToTop />
                        <App />
                      </QueryClientProvider>
                      <GlobalStyles />
                    </MobileSidebarContextProvider>
                  </I18nextProvider>
                </ThemeProvider>
              </TransferProvider>
            </ModalContextProvider>
          </MantineProvider>
        </MapsContextProvider>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
)
