import { createContext, useContext, useCallback, useState } from 'react'
import api from '../services/api'

export const AuthContext = createContext({})

export function AuthProvider({ children }) {
  //@ts-ignore
  const ROLE = localStorage.getItem('@role')

  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@token')
    const user = localStorage.getItem('@user')

    if (token && user) {
      return { token, user: JSON.parse(user) }
    }

    return {}
  })

  const requestCode = useCallback(async ({ email, code, newPassword }) => {
    try {
      const { data } = (await api.post)('/authenticate/forgotten-password', {
        email,
        code,
        newPassword
      })

      if (data) {
        return data
      }
    } catch (error) {
      throw new Error(error.response.data.error)
    }
  }, [])

  // Login
  const signIn = useCallback(async ({ email, password }) => {
    const { data } = await api.post('/authenticate', {
      email,
      password
      // accessCode
    })

    const { account, token } = data

    if (token) {
      localStorage.setItem('@token', token)
      localStorage.setItem('@role', account.role)
      localStorage.setItem(
        '@user',
        JSON.stringify({
          ...account
        })
      )

      setData({
        token: token,
        user: {
          ...account
        }
      })
    }
  }, [])

  // Logout user
  const signOut = useCallback(() => {
    localStorage.removeItem('@token')
    localStorage.removeItem('@user')
    localStorage.removeItem('@role')

    window.location.pathname = '/login'
    setData({})
  }, [])

  return (
    <AuthContext.Provider
      value={{ signIn, signOut, requestCode, user: data.user, ROLE }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)

  return context
}
