import {
  Button,
  Divider,
  Flex,
  LoadingOverlay,
  MediaQuery
  // , Pagination
} from '@mantine/core'
import * as Styled from './styles'
import { useEffect } from 'react'
import { ReactComponent as Passenger } from './imgs/passenger.svg'
import { ReactComponent as Avatar } from './imgs/avatar.svg'
import { ReactComponent as User } from './imgs/user.svg'
import { ReactComponent as Email } from './imgs/mail.svg'
import { ReactComponent as Phone } from './imgs/phone.svg'
import Surf from './imgs/icon_surfboard- blue branding.svg'
import BabyChair from './imgs/icon_car_baby_seat- blue branding.svg'
import Pet from './imgs/icon_pet- blue branding.svg'

import { Luggage, Plane, Users } from 'lucide-react'
import fetchBooking from '../../../../../../services/booking'
import { useState } from 'react'
import {
  formatDateAndTime,
  formatDayOfWeek,
  formatMonth,
  formatTime
} from '../../../../../../utils/formattedDate'
import { RejectModal } from '../RejectModal'
import { useAuth } from '../../../../../../contexts/useAuth'
import { useClipboard } from '@mantine/hooks'

export const DetailsPassenger = ({ transfer }) => {
  const [acceptedModal, setAcceptedModal] = useState(false)
  const [transferContent, setTransferContent] = useState()
  const [refreshBookings, setRefreshBookings] = useState(false)

  const clipboard = useClipboard({ timeout: 500 })

  const { user } = useAuth()

  const handleAcceptTransfer = (value, driver, setDriver) => {
    const payload = {
      id: value.id,
      driver: {
        id: driver ? driver : user.id
      }
    }

    if (user.id) {
      fetchBooking
        .updateBooking(payload)
        .then((res) => {
          if (res) {
            setAcceptedModal(false)
            setRefreshBookings(!refreshBookings)
            if (driver) {
              setDriver()
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  // const handleRejectTransfer = (value) => {
  //   const payload = {
  //     id: value.id,
  //     driver: {
  //       id: user.id
  //     }
  //   }

  //   if (user.id) {
  //     fetchBooking
  //       .updateBooking(payload)
  //       .then((res) => {
  //         console.log(res)
  //       })
  //       .catch((err) => {
  //         console.log(err)
  //       })
  //   }
  // }

  return transfer ? (
    <>
      <Styled.CardContainer>
        <Styled.Container key={transfer.id}>
          <Styled.Wrapper>
            <Styled.InfoWrapper>
              <Styled.PaymentTitle>
                <Passenger />
                Passageiro principal
              </Styled.PaymentTitle>
            </Styled.InfoWrapper>
            <Styled.PassengerInfoContainer>
              <Styled.PassengerInputs>
                <Styled.PaymentInfo>
                  <Styled.PaymentInfoTitle>
                    <User />
                    Nome
                  </Styled.PaymentInfoTitle>
                  <Styled.PaymentInfoDesc>
                    {transfer.passengerName}
                  </Styled.PaymentInfoDesc>
                </Styled.PaymentInfo>
                <Styled.PaymentInfo>
                  <Styled.PaymentInfoTitle>
                    <Email />E - mail
                  </Styled.PaymentInfoTitle>
                  <Styled.PaymentInfoDesc>
                    {transfer.passengerEmail}
                  </Styled.PaymentInfoDesc>
                </Styled.PaymentInfo>
                {transfer.status !== 'DONE' && (
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <Phone />
                      Telemóvel
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>
                      <a href={`tel:+${transfer.passengerPhoneNumber}`}>
                        {transfer.passengerPhoneNumber}
                      </a>
                    </Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                )}
              </Styled.PassengerInputs>
              <Styled.PassengerInputsMobile>
                {transfer.numberOfPassengers && (
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <Users width={20} height={20} />
                      Passageiros
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>
                      {transfer.numberOfPassengers}
                    </Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                )}
                {transfer.flightNumber && (
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <Plane width={20} height={20} /> Número do Voo
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>
                      {transfer.flightNumber}
                    </Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                )}

                {(transfer.handLuggages ||
                  transfer.checkedLuggages ||
                  transfer.backpacks) > 0 && (
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <Luggage width={20} height={20} />
                      Bagagem
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>
                      {transfer.handLuggages +
                        transfer.checkedLuggages +
                        transfer.backpacks}{' '}
                      bagagem despachada
                    </Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                )}

                {transfer.surfboards > 0 && (
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <img
                        width={20}
                        height={20}
                        src={Surf}
                        alt="prancha de surf"
                      />
                      Prancha de Surf
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>
                      {transfer.surfboards}
                    </Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                )}

                {(transfer.childSeats ||
                  transfer.babySeats ||
                  transfer.boosterSeats) > 0 && (
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <img
                        width={20}
                        height={20}
                        src={BabyChair}
                        alt="cadeira de criança"
                      />
                      Cadeira de Criança
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>
                      {transfer.childSeats +
                        transfer.babySeats +
                        transfer.boosterSeats}
                    </Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                )}

                {transfer.animals > 0 && (
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <img width={20} height={20} src={Pet} alt="Pet" />
                      Pet
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>
                      {transfer.animals}
                    </Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                )}
              </Styled.PassengerInputsMobile>
              {/* <Divider
                  className="divider"
                  style={{ marginLeft: '30px' }}
                  orientation="vertical"
                />
                <Styled.PassengerInputs>
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <User />
                      Passageiros
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>Lorem ipsum</Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <Email />
                      Número do Voo
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>Lorem ipsum</Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <Phone />
                      Bagagem
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>Lorem ipsum</Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <User />
                      Praancha de Surf
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>Lorem ipsum</Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <Email />
                      Cadeira de Criança
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>Lorem ipsum</Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                  <Styled.PaymentInfo>
                    <Styled.PaymentInfoTitle>
                      <Phone />
                      Pet
                    </Styled.PaymentInfoTitle>
                    <Styled.PaymentInfoDesc>Lorem ipsum</Styled.PaymentInfoDesc>
                  </Styled.PaymentInfo>
                </Styled.PassengerInputs> */}
            </Styled.PassengerInfoContainer>
          </Styled.Wrapper>
        </Styled.Container>
      </Styled.CardContainer>
    </>
  ) : (
    <div></div>
  )
}
