import styled from 'styled-components'

export const OneTransfer = styled.div`
  display: flex;
  flex: 1;
  /* flex-wrap: wrap; */
  align-items: center;
  gap: 15px;
  width: 208.92px;
  height: 49.83px;
  position: relative;

  .AutoComplete {
    z-index: 9999;
  }

  .mantine-InputWrapper-root {
    height: 49.83px;
  }

  .mantine-Select-input,
  .mantine-Autocomplete-input,
  .mantine-Input-input {
    font-family: 'Neometric';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: normal;
    color: #808080;
    border: transparent;
    padding: 0;

    :focus {
      ::placeholder {
        visibility: hidden !important;
      }
    }
  }

  .mantine-Select-label h2,
  .mantine-Autocomplete-label {
    width: 89px;
    height: 22px;
    font-family: 'Neometric';
    font-style: normal;
    font-weight: 600;
    font-size: 18.1885px;
    line-height: 22px;
    color: #0d0d0d;
  }

  .mantine-Select-dropdown {
    background: #ffffff;
    box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.07);
    border-radius: 0px 16px 16px 16px;
    border: 0;
  }

  .mantine-DateTimePicker-input {
    border: 0;
  }
  .mantine-DateTimePicker-input[data-with-icon] {
    padding: 0;
  }
  .mantine-DateTimePicker-icon {
    padding-right: 0;
    position: relative;
  }
  .mantine-DateTimePicker-label,
  .mantine-InputWrapper-label {
    font-family: 'Neometric';
    font-style: normal;
    font-weight: 600;
    font-size: 18.1885px;
    line-height: 22px;
    color: #0d0d0d;

    /* padding-left: 3rem; */
  }

  .mantine-DateTimePicker-input span,
  .mantine-DateTimePicker-input {
    font-family: 'Neometric';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: normal;
    /* identical to box height */

    color: #808080;
  }

  .mantine-DateTimePicker-wrapper,
  .mantine-Autocomplete-wrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .mantine-Popover-dropdown {
    background: #ffffff;
    box-shadow: 0px 5px 22px rgba(0, 0, 0, 0.07);
    border-radius: 0px 22px 22px 22px;
    border: 0;
    display: flex;
    z-index: 999 !important;
  }

  .mantine-Input-input::placeholder {
    color: #8a8a8a;
  }

  .mantine-DateTimePicker-monthLevel {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
  .mantine-MonthLevelGroup-monthLevelGroup {
    margin: 2rem auto;
  }

  .mantine-InputWrapper-root {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1;
  }

  .mantine-InputWrapper-label {
    padding-left: 0;
  }

  .mantine-InputWrapper-label {
    color: #404040;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: normal;
  }

  @media only screen and (max-width: 768px) {
    flex: 1;
    width: 100%;
    min-width: 18rem;

    .mantine-InputWrapper-root {
      flex: 1;
    }
  }
`

export const SuggestionsContainer = styled.div`
  width: 24rem;
  min-height: 20rem;
  position: absolute;
  z-index: 9999;
  /* z-index: 1; */
  top: 5.2rem;
  left: 2rem;
  padding: 1.6rem;

  border-radius: 0.8rem;
  border: 1px solid #f7f7f7;
  background: #ffffff;
  box-shadow: 0px 31.0104px 33.82952px 0px rgba(0, 0, 0, 0.07),
    0px -1.40956px 22.55301px 0px rgba(0, 0, 0, 0.05);

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;

    max-height: 28rem;
    overflow-y: auto;
    padding-right: 0.8rem;

    &::-webkit-scrollbar {
      width: 0.6rem !important;
      display: unset;
    }

    &::-webkit-scrollbar-track {
      background: #f7f7f7 !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #27c7ff !important;
      border-radius: 1.6rem !important;
    }

    scrollbar-width: thin !important;
    scrollbar-color: #27c7ff #f7f7f7 !important;

    > strong {
      margin-bottom: 0.8rem;
      color: #404040;
      font-family: Neometric;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    li {
      cursor: pointer;
      padding: 0.2rem 0;
      display: flex;
      align-items: center;
      gap: 0.8rem;

      svg {
        stroke: #222222;
      }

      :hover {
        background-color: #f7f7f7;
      }

      &.focused {
        background-color: #fcb13c;

        p {
          color: #ffffff;
        }

        svg {
          stroke: #ffffff;
        }
      }

      p {
        color: #808080;
        font-family: Neometric;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        span.highlight {
          font-weight: 600;
        }
      }

      & + li {
        margin-top: 1.4rem;
      }
    }
  }
`
