import * as Styled from './styles'
import { Navbar } from '../../components/Navbar'
import Footer from '../../components/Footer'
import Faq from './components/Faq'
import { useTranslation } from 'react-i18next'

export const TermsConditions = () => {
  const { t } = useTranslation()
  return (
    <>
      <Navbar />
      <Styled.Container>
        <Styled.ContentContainer>
          <Styled.Title>
            {t('termsAndConditions.termsAndConditions')}
          </Styled.Title>
          <Styled.SubTitle>
            <strong>EASY TRANSFER ERICEIRA</strong> - Transfers / Tours /
            Eventos - Portugal
          </Styled.SubTitle>
          <Styled.Information>
            Sede: R. Prudêncio Franco da Trindade 4, 2655-344 Ericeira –
            Ericeira Business Factory
          </Styled.Information>
          <Styled.Information>
            E-mail: easytransferericeira@gmail.com
          </Styled.Information>
          <Styled.Description>
            {t('termsAndConditions.privacyPolicy')}
          </Styled.Description>
          <Styled.Description
            dangerouslySetInnerHTML={{
              __html: t('termsAndConditions.privacyCommitment')
            }}
          />
        </Styled.ContentContainer>

        <Faq />
      </Styled.Container>
      <Footer />
    </>
  )
}
