import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;

  flex: 1;

  @media (max-width: 500px) {
    img {
      width: 100%;
    }
  }
`

export const Text = styled.p`
  font-family: 'Neometric';
  color: #222;
  text-align: center;
  font-size: 14px;

  max-width: 250px;

  margin-top: -30px;
`
