import styled, { css } from 'styled-components'
import { Button as UIButton } from '../../../../components/Button'
import { Input as UIInput } from '../../../../components/Input'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
  border-radius: 1.2rem;
  background-color: #DFF7FF;
  padding-bottom: 3.2rem;

  > :nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3.2rem;
    border-top-left-radius: 1.2rem;
    border-top-right-radius: 1.2rem;
    background-color: #27c7ff;
    padding: 1.6rem;

    > h3 {
      text-align: center;
      font-weight: bold;
      color: #27272a;

      font-size: 2rem;
      line-height: 2.8rem;
    }

    @media (min-width: 1024px) {
      flex-direction: row;

      > h3 {
        font-size: 2.4rem; /* 24px */
        line-height: 3.2rem; /* 32px */
      }
    }
  }

  > :nth-child(2) {
    display: flex;
    justify-content: space-around;

    > :nth-child(1) {
      z-index: 10;
      margin-left: -15rem;
      margin-top: -6rem;
      display: none;
      height: 25.6rem;
      width: 25.6rem;

      img {
        /* transform: rotate(22deg); */
        width: 100%;
      }

      @media (min-width: 1280px) {
        display: block;
      }
    }

    > :nth-child(2) {
      display: flex;
      max-width: 100%;
      flex-direction: column;
      align-items: center;
      gap: 3.2rem;
      padding: 1.6rem;
      position: relative;

      > h2 {
        text-align: center;
        font-weight: bold;
        color: #27c7ff;
        font-size: 2.4rem;
        line-height: 3.2rem;
      }

      > img {
        display: block;
        position: absolute;
        top: 0;
        width: 16rem;

        :first-of-type {
          left: -16vw;
        }

        :last-of-type {
          right: -8vw;
          top: -10vw;
        }
      }

      > form {
        display: flex;
        max-width: 100%;
        flex-direction: column;
        gap: 1.2rem;

        > div {
          display: flex;
          flex-direction: column;
          gap: 0.4rem;
          align-items: flex-start;

          > div {
            display: flex;
            align-items: center;
            border-radius: 0.8rem;
            border: 0.1rem solid #d1d5db;
            background-color: #ffffff;
            padding-left: 1.2rem;

            > svg {
              width: 2.4rem;
              height: 2.4rem;
              color: #6b7280;
            }
          }

          p {
            font-size: 1.4rem;
            line-height: 2rem;
            color: #f87171;
          }
        }
      }

      @media (min-width: 1280px) {
        position: static;
        > img {
          display: none;
        }
      }

      @media (min-width: 768px) {
        > form {
          flex-direction: row;
        }
      }

      @media (min-width: 1024px) {
        > h2 {
          font-size: 3rem;
          line-height: 3.6rem;
        }
      }
    }

    > :nth-child(3) {
      z-index: 10;
      margin-right: -11rem;
      margin-top: -9rem;
      display: none;
      width: 25.6rem;
      right: 25.6rem;

      img {
        width: 100%;
      }

      @media (min-width: 1280px) {
        display: block;
      }
    }
  }
`

export const Button = styled(UIButton)`
  display: flex;
  gap: 1.6rem;
  border-radius: 0.8rem;
  background-color: #ffffff;
  color: #27c7ff;

  :hover {
    background-color: #f3f4f6;
  }

  span {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 2rem;
  }

  svg {
    width: 2.8rem;
    height: 2.8rem;
    color: #27c7ff;
  }
`

export const FormButton = styled(UIButton)`
  display: flex;
  gap: 0.8rem;
  border-radius: 0.8rem;
  background-color: #ffffff;
  color: #27c7ff;

  span {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: bold;
  }

  svg {
    width: 2rem;
    height: 2rem;
    color: #27c7ff;
  }

  :hover:not(:disabled) {
    background-color: #f3f4f6;
  }

  :disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
`

export const Input = styled(UIInput)`
  border: none;
  background-color: #ffffff;

  :focus-visible {
    offset: 0;
  }

  @media (min-width: 1024px) {
    min-width: 32rem;
  }
`
