import { UnstyledButton, Checkbox, Image } from '@mantine/core'
import { useUncontrolled } from '@mantine/hooks'
import * as S from './styles'
import { formatCurrency } from '../../../../utils/formatCurrency'
import { useState } from 'react'
import { useTransferContext } from '../../../../contexts/TransferContext'

export function StopSelection({
  checked,
  defaultChecked,
  onChange,
  title,
  time,
  price,
  image,
  item,
  ...others
}) {
  const [value, handleChange] = useUncontrolled({
    value: checked,
    defaultValue: defaultChecked,
    finalValue: false
  })
  const { setGoing, going } = useTransferContext()

  console.log(going, 'GOING')

  const handleStopSelection = () => {
    onChange(item)
    handleChange(!value)

    if (going.bookingStops.includes(item)) {
      setGoing({
        ...going,
        bookingStops: going.bookingStops.filter((stop) => stop !== item)
      })
    } else {
      setGoing({
        ...going,
        bookingStops: [...going.bookingStops, item]
      })
    }
  }

  return (
    <S.Container>
      <UnstyledButton
        {...others}
        onClick={handleStopSelection}
        data-checked={value || undefined}
        className="button"
      >
        <Checkbox
          checked={value}
          onChange={() => {}}
          tabIndex={-1}
          styles={{ input: { cursor: 'pointer' } }}
          color="orange-primary"
        />

        <Image src={image} alt={title} width={120} height={120} />

        <div className="body">
          <h1>{title}</h1>
          <p className="time">{time} min stop</p>
          <p className="price">
            Adicionar por{' '}
            <span>€ {formatCurrency(price).replace('€', '')}</span>
          </p>
        </div>
      </UnstyledButton>
    </S.Container>
  )
}
