import styled from 'styled-components'

import * as OurFleetStyles from '../Home/components/OurFleet/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  margin-top: 8rem;

  background: #09090b;

  color: #f3f4f6;

  > div:not(:last-of-type) {
    width: 100%;
    &:first-of-type {
      display: none;
      @media (min-width: 1024px) {
        display: block;
      }
    }
  }

  > div:nth-child(2) {
    @media (min-width: 1024px) {
      display: none;
    }
  }

  > main {
    margin: 0 auto;
    width: 100%;
    max-width: 144rem;
    margin-top: 14.4rem;
    padding: 0 4rem 11.2rem;

    display: flex;
    flex-direction: column;
    gap: 14.4rem;

    > div {
      width: 100%;
      margin: 0;
    }

    @media (min-width: 1024px) {
      padding-left: 16rem;
      padding-right: 16rem;
    }
  }
  /*
  ${OurFleetStyles.Container} {
    margin-top: 99px;
  } */
`

export const HeroContainer = styled.section`
  height: 545px;
  width: 100%;
  background-position: 100%;
  display: flex;
  align-items: center;
  z-index: 99;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15) 51.88%,
      rgba(255, 255, 255, 0.55) 73.91%,
      #ffffff 100%
    ),
    linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.15) 65.81%,
      rgba(255, 255, 255, 0.55) 81.46%,
      #ffffff 100%
    );
`

export const ContentWrapper = styled.div`
  margin: 99px auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 64px;
`
