import * as Styled from './styles'
import { ReactComponent as DriverImage } from './imgs/DriverImage.svg'
import { ReactComponent as CloseIcon } from './imgs/CloseIcon.svg'
import { Checkbox } from '@mantine/core'

export const RejectModal = ({ opened, onClose, onFinish, next }) => {
  return (
    <>
      <Styled.ModalOverlay opened={opened} onClick={onClose} />
      <Styled.ModalContainer opened={opened}>
        <Styled.ModalHeader>
          <Styled.CloseButton>
            <CloseIcon onClick={onClose} />
          </Styled.CloseButton>
        </Styled.ModalHeader>
        {!next ? (
          <Styled.ModalContent>
            <Styled.Observation>
              OBS: Cancelamento só será aceito com 3 horas de antecedência.
            </Styled.Observation>
            <Styled.Motive>
              <Styled.MotiveTitle>
                Informe o motivo de cancelamento do transfer:
              </Styled.MotiveTitle>
              <Styled.Options>
                <Checkbox
                  size="lg"
                  // styles={{ label: { color: 'black' } }}
                  color="orange-primary"
                />
                <div>
                  <p>Problemas com o automóvel</p>
                </div>
              </Styled.Options>
              <Styled.Options>
                <Checkbox
                  size="lg"
                  // styles={{ label: { color: 'black' } }}
                  color="orange-primary"
                />
                <div>
                  <p>Problemas de saúde</p>
                </div>
              </Styled.Options>
              <Styled.Options>
                <Checkbox
                  size="lg"
                  // styles={{ label: { color: 'black' } }}
                  color="orange-primary"
                />
                <div>
                  <p>Imprevisto qualquer</p>
                </div>
              </Styled.Options>
            </Styled.Motive>
            <Styled.ModalButton onClick={onFinish}>ENVIAR</Styled.ModalButton>
          </Styled.ModalContent>
        ) : (
          <Styled.ModalContent>
            <DriverImage />
            <Styled.ModalTitle>
              Sua solicitação de cancelamento foi enviada para a administração.
            </Styled.ModalTitle>
          </Styled.ModalContent>
        )}
      </Styled.ModalContainer>
    </>
  )
}
