import { NavbarHostel } from '../../components/NavbarHostel'
import { Hero } from './components/Hero'
import { GoogleComments } from './components/GoogleComments'
import { OurFleet } from './components/OurFleet'
import PaymentMethods from './components/PaymentMethods'
import Trust from './components/Trust'
import Faq from './components/Faq'
import Footer from '../../components/Footer'
import Tours from './components/Tours'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import fetchHostel from '../../services/hostel'
import { Schedule } from '../Home/components/Schedule'
import { GreatExperience } from '../Home/components/GreatExperience'

export default function Hostel() {
  const [data, setData] = useState()

  const params = useParams()

  useEffect(() => {
    if (params?.hostelId) {
      fetchHostel.getById(params.hostelId).then((res) => {
        setData(res.data)
      })
    }
  }, [])

  return (
    <>
      <NavbarHostel hostel={data} />
      <Hero hostel={data} />
      <GoogleComments />
      <Schedule />
      <OurFleet />
      <Tours />
      <GreatExperience />
      <PaymentMethods />
      <Faq />
      <Footer />
    </>
  )
}
