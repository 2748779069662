import { useTranslation } from 'react-i18next'
import * as Styled from './styles'

import OrangeBar from './imgs/orange-bar.webp'
import Arrow from './imgs/arrow.webp'
import { useTransferContext } from '../../../../contexts/TransferContext'
import { formatCurrency } from '../../../../utils/formatCurrency'
import dayjs from 'dayjs'

export const OrderSummaryResume = () => {
  const { t } = useTranslation()

  const { going, returnTravel } = useTransferContext()

  // console.log({ going, returnTravel, carSelected })

  const getAbbreviatedWeekday = (date) => {
    const weekday = date.toLocaleString('default', { weekday: 'short' })
    return weekday.substring(0, 3).toUpperCase()
  }

  return (
    <Styled.Container>
      <h2>{t('orderSummary.title')}</h2>
      <Styled.FormRow>
        <Styled.StrongSpan>{t('orderNumber')} :</Styled.StrongSpan>
        <Styled.NormalSpan>034938429</Styled.NormalSpan>
      </Styled.FormRow>
      <Styled.CardsContainer>
        <Styled.ContentWrapper>
          <Styled.OrientationWrapper>
            <img src={Arrow} alt={t('arrowAltText')} />
            <span>{t('ida')}</span>
          </Styled.OrientationWrapper>
          <Styled.HeaderWrapper>
            <Styled.DateAndHour>
              {getAbbreviatedWeekday(going.dateTime)},{' '}
              {going.dateTime.toLocaleDateString()} -{' '}
              {`${String(dayjs(going.dateTime).get('hours')).padStart(
                2,
                '0'
              )}:${String(dayjs(going.dateTime).get('minutes')).padStart(
                2,
                '0'
              )}`}
            </Styled.DateAndHour>
          </Styled.HeaderWrapper>
          <Styled.RoutesWrapper>
            <img
              src={OrangeBar}
              className="orange-bar"
              alt={t('orangeBarAltText')}
            />
            <div className="routes">
              <Styled.FormRow>
                <Styled.RouteStrongSpan>{going.from}</Styled.RouteStrongSpan>
                <Styled.RoutePriceSpan style={{ width: '70px' }}>
                  € {formatCurrency(going.totalPrice).replace('€', '')}
                </Styled.RoutePriceSpan>
              </Styled.FormRow>
              <Styled.FormRow>
                <Styled.RouteNormalSpan>{going.from}</Styled.RouteNormalSpan>
              </Styled.FormRow>
              <Styled.FormRow style={{ marginTop: '16px' }}>
                <Styled.RouteStrongSpan style={{ marginTop: '30px' }}>
                  {going.to}
                </Styled.RouteStrongSpan>
              </Styled.FormRow>
              <Styled.FormRow>
                <Styled.RouteNormalSpan>{going.to}</Styled.RouteNormalSpan>
              </Styled.FormRow>
            </div>
          </Styled.RoutesWrapper>
        </Styled.ContentWrapper>
        {returnTravel.active && (
          <Styled.ContentWrapper>
            <Styled.OrientationWrapper style={{ marginTop: '20px' }}>
              <img src={Arrow} alt={t('arrowAltText')} />
              <span>{t('volta')}</span>
            </Styled.OrientationWrapper>
            <Styled.HeaderWrapper>
              <Styled.DateAndHour>
                {getAbbreviatedWeekday(returnTravel.dateTime)},{' '}
                {returnTravel.dateTime.toLocaleDateString()} -{' '}
                {`${String(dayjs(returnTravel.dateTime).get('hours')).padStart(
                  2,
                  '0'
                )}:${String(
                  dayjs(returnTravel.dateTime).get('minutes')
                ).padStart(2, '0')}`}
              </Styled.DateAndHour>
            </Styled.HeaderWrapper>
            <Styled.RoutesWrapper>
              <img
                src={OrangeBar}
                className="orange-bar"
                alt={t('orangeBarAltText')}
              />
              <div className="routes">
                <Styled.FormRow style={{ justifyContent: 'space-between' }}>
                  <Styled.RouteStrongSpan>
                    {returnTravel.from}
                  </Styled.RouteStrongSpan>
                  <Styled.RoutePriceSpan style={{ width: '62px' }}>
                    € {formatCurrency(returnTravel.totalPrice).replace('€', '')}
                  </Styled.RoutePriceSpan>
                </Styled.FormRow>
                <Styled.FormRow>
                  <Styled.RouteNormalSpan>
                    {returnTravel.from}
                  </Styled.RouteNormalSpan>
                </Styled.FormRow>
                <Styled.FormRow style={{ marginTop: '16px' }}>
                  <Styled.RouteStrongSpan
                    style={{ marginTop: '30px', maxWidth: '240px' }}
                  >
                    {returnTravel.to}
                  </Styled.RouteStrongSpan>
                </Styled.FormRow>
                <Styled.FormRow>
                  <Styled.RouteNormalSpan>
                    {returnTravel.to}
                  </Styled.RouteNormalSpan>
                </Styled.FormRow>
              </div>
            </Styled.RoutesWrapper>
          </Styled.ContentWrapper>
        )}
      </Styled.CardsContainer>
    </Styled.Container>
  )
}
