import * as Styled from './styles'
import { Input } from '@mantine/core'
import { ReactComponent as Check } from './imgs/check.svg'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import fetchCoupon from '../../../../services/coupon'
import { toast } from 'react-toastify'
import debounce from '../../../../utils/debounce'
import { useTransferContext } from '../../../../contexts/TransferContext'

export const CouponCard = () => {
  const [coupon, setCoupon] = useState()
  const [searchCoupon, setSearchCoupon] = useState()
  const [foundCoupon, setFoundCoupon] = useState(false)
  const { t } = useTranslation()
  const { going, setGoing, returnTravel, setReturnTravel } =
    useTransferContext()

  useEffect(() => {
    if (searchCoupon) {
      if (going.paymentMethod === '') {
        toast.error(t('toast.selectPaymentMethod'))
      }
      const params = {
        coupon: searchCoupon,
        appliedTo: 'ALL',
        deleted: false
      }
      fetchCoupon.getAll(params).then((res) => {
        const currentDate = new Date()
        let foundCoupon = res.data.content.find(
          (c) =>
            c.coupon === searchCoupon &&
            c.paymentMethodsAllowed.includes(
              going.paymentMethod === 'Card'
                ? 'CREDIT_CARD'
                : going.paymentMethod.toUpperCase()
            ) &&
            new Date(c.validTo) >= currentDate
        )
        setFoundCoupon(foundCoupon)
        console.log(foundCoupon, 'foundCoupon')
        if (!foundCoupon) {
          toast.error(t('toast.incorrectCoupon'))
          setGoing({
            ...going,
            discountAmount: 0
          })
        } else {
          const discountAmount =
            going.totalPrice * (foundCoupon.discountPercentage / 100)

          setGoing({
            ...going,
            discountAmount,
            discountId: foundCoupon.id
          })
          if (returnTravel.active) {
            const discountAmountReturn =
              returnTravel.totalPrice * (foundCoupon.discountPercentage / 100)
            setReturnTravel({
              ...returnTravel,
              discountAmount: discountAmountReturn,
              discountId: foundCoupon.id
            })
          }
        }
      })
    }
  }, [searchCoupon, going.paymentMethod])

  const handleInputChange = (e) => {
    setCoupon(e.target.value)
    // if (e.target.value === '' || e.target.value === searchCoupon) {
    //   return
    // }
    debounce(() => setSearchCoupon(e.target.value), 500)
  }

  return (
    <Styled.Container>
      <h1>{t('discountCoupon.title')}</h1>
      <Input
        placeholder={t('discountCoupon.placeholder')}
        size={'lg'}
        onChange={handleInputChange}
        value={coupon}
        disabled={foundCoupon}
        rightSection={
          <div className={`${foundCoupon ? '' : 'gray'} coupon`}>
            <Check />
          </div>
        }
      />
    </Styled.Container>
  )
}
