import { LineChart } from 'lucide-react'
import * as Styled from './styles'
import { useEffect, useState } from 'react'
import fetchAccount from '../../../../../../services/account'
import fetchBooking from '../../../../../../services/booking'
import { ClipLoader } from 'react-spinners'

export const Counters = ({ type }) => {
  const [dataCounter, setDataCounter] = useState([])
  const [loading, setLoading] = useState(true)
  console.log(type, 'type')

  const fetchTypeCounter = {
    drivers: fetchAccount.getFinancialCounters,
    geral: fetchBooking.getBookingFinancialCounters
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await fetchTypeCounter[type]()
        setDataCounter(data)
        setLoading(false)
      } catch (err) {
        console.log(err, 'error')
        setLoading(false)
      }
    }
    fetchData()
  }, [type])

  const convertMoney = (valueCents) => {
    const valueEur = (valueCents / 100).toFixed(2)
    return `€ ${valueEur}`
  }

  const renderCounters = () => {
    switch (type) {
      case 'drivers':
        return (
          <>
            <Styled.Option>
              <Styled.Header>
                <Styled.OptionTitle>Total de Motoristas</Styled.OptionTitle>
                <LineChart color="#BFBFBF" />
              </Styled.Header>
              <Styled.OptionContent>
                <Styled.OptionValue>
                  {dataCounter.totalDrivers}
                </Styled.OptionValue>
                <Styled.OptionUpdate>Atualizado até hoje</Styled.OptionUpdate>
              </Styled.OptionContent>
            </Styled.Option>
            <Styled.Option>
              <Styled.Header>
                <Styled.OptionTitle>
                  Total de motoristas ativos
                </Styled.OptionTitle>
                <LineChart color="#BFBFBF" />
              </Styled.Header>
              <Styled.OptionContent>
                <Styled.OptionValue>
                  {dataCounter.activeDrivers}
                </Styled.OptionValue>
                <Styled.OptionUpdate>Atualizado até hoje</Styled.OptionUpdate>
              </Styled.OptionContent>
            </Styled.Option>
            <Styled.Option>
              <Styled.Header>
                <Styled.OptionTitle>Novos motoristas</Styled.OptionTitle>
                <LineChart color="#BFBFBF" />
              </Styled.Header>
              <Styled.OptionContent>
                <Styled.OptionValue>1000</Styled.OptionValue>
                <Styled.OptionUpdate>Atualizado até hoje</Styled.OptionUpdate>
              </Styled.OptionContent>
            </Styled.Option>
          </>
        )

      case 'geral':
        return (
          <>
            <Styled.Option>
              <Styled.Header>
                <Styled.OptionTitle>Total de ganhos</Styled.OptionTitle>
                <LineChart color="#BFBFBF" />
              </Styled.Header>
              <Styled.OptionContent>
                <Styled.OptionValue>
                  {convertMoney(dataCounter.totalAmount)}
                </Styled.OptionValue>
                <Styled.OptionUpdate>Atualizado até hoje</Styled.OptionUpdate>
              </Styled.OptionContent>
            </Styled.Option>
            <Styled.Option>
              <Styled.Header>
                <Styled.OptionTitle>
                  Ticket médio por transfer
                </Styled.OptionTitle>
                <LineChart color="#BFBFBF" />
              </Styled.Header>
              <Styled.OptionContent>
                <Styled.OptionValue>
                  {convertMoney(dataCounter?.averagePrice)}
                </Styled.OptionValue>
                <Styled.OptionUpdate>Atualizado até hoje</Styled.OptionUpdate>
              </Styled.OptionContent>
            </Styled.Option>
            <Styled.Option>
              <Styled.Header>
                <Styled.OptionTitle>Transfers realizados</Styled.OptionTitle>
                <LineChart color="#BFBFBF" />
              </Styled.Header>
              <Styled.OptionContent>
                <Styled.OptionValue>
                  {dataCounter.bookingsCount}
                </Styled.OptionValue>
                <Styled.OptionUpdate>Atualizado até hoje</Styled.OptionUpdate>
              </Styled.OptionContent>
            </Styled.Option>
          </>
        )
      case 'partnership':
        return (
          <>
            <Styled.Option>
              <Styled.Header>
                <Styled.OptionTitle>Total de Motoristas</Styled.OptionTitle>
                <LineChart color="#BFBFBF" />
              </Styled.Header>
              <Styled.OptionContent>
                <Styled.OptionValue>
                  {dataCounter.totalDrivers}
                </Styled.OptionValue>
                <Styled.OptionUpdate>Atualizado até hoje</Styled.OptionUpdate>
              </Styled.OptionContent>
            </Styled.Option>
            <Styled.Option>
              <Styled.Header>
                <Styled.OptionTitle>
                  Total de motoristas ativos
                </Styled.OptionTitle>
                <LineChart color="#BFBFBF" />
              </Styled.Header>
              <Styled.OptionContent>
                <Styled.OptionValue>
                  {dataCounter.activeDrivers}
                </Styled.OptionValue>
                <Styled.OptionUpdate>Atualizado até hoje</Styled.OptionUpdate>
              </Styled.OptionContent>
            </Styled.Option>
            <Styled.Option>
              <Styled.Header>
                <Styled.OptionTitle>Novos motoristas</Styled.OptionTitle>
                <LineChart color="#BFBFBF" />
              </Styled.Header>
              <Styled.OptionContent>
                <Styled.OptionValue>1000</Styled.OptionValue>
                <Styled.OptionUpdate>Atualizado até hoje</Styled.OptionUpdate>
              </Styled.OptionContent>
            </Styled.Option>
          </>
        )

      default:
        return null
    }
  }

  return loading ? (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <ClipLoader size={50} color="#27c7ff" />
    </div>
  ) : (
    <Styled.Container>
      <Styled.Options>{renderCounters()}</Styled.Options>
    </Styled.Container>
  )
}
