export const PAYMENT_TYPES = {
  PAYMENT_TO_DRIVER: 'PAYMENT_TO_DRIVER',
  PAYMENT_TO_MANAGEMENT: 'PAYMENT_TO_MANAGEMENT',
  PAYMENT_TO_HOSTEL: 'PAYMENT_TO_HOSTEL',
  PAYMENT_TO_COMPANY: 'PAYMENT_TO_COMPANY'
}

export const PAYMENT_STATUS = {
  PAID: 'PAID',
  PENDING: 'PENDING',
  CANCELED: 'CANCELED '
}
