import {
  UnstyledButton,
  Group,
  Avatar,
  Text,
  createStyles
} from '@mantine/core'
import { IconChevronRight } from '@tabler/icons-react'

const useStyles = createStyles((theme) => ({
  user: {
    display: 'block',
    padding: theme.spacing.md,
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[8]
          : theme.colors.gray[0]
    }
  },
  avatar: {
    width: '37.87px',
    height: '37.87px'
  },
  title: {
    fontFamily: 'Neometric',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '28px',
    letterSpacing: '0em',

    maxWidth: 152,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  subTitle: {
    fontFamily: 'Neometric',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '19px',
    letterSpacing: '0em',
    color: '#BFBFBF',
    maxWidth: 152,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
}))

export function UserButton({ image, name, email, icon, ...others }) {
  const { classes } = useStyles()

  return (
    <UnstyledButton className={classes.user} {...others}>
      <Group>
        <Avatar className={classes.avatar} src={image} radius="xl" />

        <div style={{ flex: 1 }}>
          <Text className={classes.title} weight={500}>
            {name}
          </Text>

          <Text className={classes.subTitle} color="dimmed" size="xs">
            {email}
          </Text>
        </div>

        {icon || <IconChevronRight size="2rem" stroke={1.5} />}
      </Group>
    </UnstyledButton>
  )
}
