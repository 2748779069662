import styled, { css } from 'styled-components'

import * as CheckedStyles from '../Checked/styles'

const containerModifiers = {
  expanded: () => css`
    h2 {
      max-width: none;
    }

    button {
      svg {
        transform: rotate(-90deg);
      }
    }
  `
}

export const Container = styled.div`
  ${({ expanded }) => css`
    width: 100%;
    // height: 566px;
    background: #ffffff;
    box-shadow: 0px -1.01914px 16.3063px rgba(0, 0, 0, 0.05),
      0px 22.4212px 24.4595px rgba(0, 0, 0, 0.07);
    border-radius: 24px;
    margin-bottom: 6px;
    padding: 23.1367px 17.3525px;
    overflow: hidden;
    position: relative;

    ${CheckedStyles.Container} {
      gap: 2.4rem;
      margin: 4rem 0;

      > img {
        width: 9.7rem;
        height: 9.7rem;
      }

      > span {
        font-size: 2rem !important;
      }
    }

    ${CheckedStyles.ImportantContainer} {
      margin-top: 4.8rem;
      padding: 2rem;
      font-size: 1.4rem;
    }

    > button {
      position: absolute;
      bottom: 1.2rem;
      left: 1.6rem;
      right: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.8rem;
      /* width: 100%; */
      font-size: 1.2rem;
      font-weight: 400;
      letter-spacing: 0.2px;
      border-radius: 0.8rem;
      background-color: #E9F9FF;

      svg {
        transition: transform 0.3s ease-in-out;
        stroke: #27c7ff;
        width: 2rem;
        height: 2rem;
        transform: rotate(90deg);
      }
    }

    > div,
    .content-wrapper {
      /* transition: margin-top 0.3s ease-in-out; */
      display: flex;
      flex-direction: column;
      gap: 23.14px;
    }

    label {
      font-family: 'Neometric';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: #38280e;

      margin: 16px 0;
    }

    h2 {
      font-weight: 600;
      font-size: 2rem;
      line-height: 29px;

      color: #0d0d0d;
      margin-bottom: -16px;
    }

    /* button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 40px;
      gap: 16px;

      width: 100%;
      height: 51px;
      background: #27c7ff;
      box-shadow: 0px 4px 8px rgba(223, 247, 255, 0.5);
      border-radius: 8px;
      margin-top: 20px;

      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      text-transform: uppercase;
      color: #ffffff;
    } */

    > a {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #27c7ff;
      color: #ffffff;
      font-family: Roboto;
      font-size: 1.6rem;
      font-weight: 700;
      letter-spacing: 0.16px;
      height: 5.4rem;
      border-radius: 0.8rem;
      margin-top: 3rem;
    }

    @media only screen and (max-width: 944px) {
      padding: 1.6rem;
      h2 {
        font-size: 1.8rem;
        max-width: 14rem;
      }
    }

    ${expanded && containerModifiers.expanded}
  `}
`

export const FormRow = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;

  &:first-of-type {
    justify-content: flex-start;
    gap: 11.28px;
  }

  a {
    color: #a0a0a0;
  }
`

export const OrientationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5.78px;

  span {
    font-weight: 600;
    font-size: 14.4604px;
    line-height: 120%;

    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    color: #0d0d0d;
  }

  @media only screen and (max-width: 944px) {
    span {
      font-size: 1.4rem;
    }
  }
`

export const HeaderWrapper = styled.div`
  width: 100%;
  align-self: center;
  margin: 0 0 10px -10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 142px; */
  padding-right: 1.6rem;

  div {
    span {
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 14px;

      display: flex;
      align-items: center;

      color: #27c7ff;
    }

    img {
      width: 8.44px;
      height: 8.44px;
    }
  }

  .mantine-DatePicker-calendarHeaderControl svg {
    width: 2rem;
    height: 100%;
  }

  .mantine-Popover-dropdown {
    display: flex;
    justify-content: center;
    flex-direction: column;

    padding: 2rem 1rem;

    background: #ffffff;
    border: 1px solid #e9ecef;
    box-shadow: 0px -1.01914px 16.3063px rgba(0, 0, 0, 0.05),
      0px 22.4212px 24.4595px rgba(0, 0, 0, 0.07),
      0px 7px 7px -5px rgba(0, 0, 0, 0.04),
      0px 10px 15px -5px rgba(0, 0, 0, 0.05), 0px 1px 3px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  .mantine-CalendarHeader-calendarHeader {
    margin-bottom: 25px;
  }

  @media only screen and (max-width: 944px) {
    padding: 0;
  }
`

export const ContentWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;

  position: relative;
  left: 20px;

  svg {
    top: 70px;
  }

  @media only screen and (max-width: 944px) {
    left: 0;
  }
`

export const RoutesWrapper = styled.div`
  display: flex;
  gap: 11.28px;

  width: 330px;

  .orange-bar {
    height: 136.06px;
  }

  .routes {
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: 944px) {
    width: 100%;

    .routes {
      flex: 1;

      > div:first-of-type {
        justify-content: space-between;
      }
    }
  }
`

export const RoutePriceSpan = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 17px;
  white-space: nowrap;

  color: #0d0d0d;
`

export const BillWrapper = styled.div`
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  gap: 24px;

  .title {
    font-weight: 500;
    font-size: 10.1223px;
    line-height: 120%;

    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    margin-top: 10px;

    color: #404040;
  }

  @media only screen and (max-width: 944px) {
    > h2 {
      font-size: 1.6rem;
    }

    .title {
      font-size: 1rem;
    }
  }
`

export const BillRow = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;

  @media only screen and (max-width: 944px) {
    gap: 0;
  }
`

export const NormalBillSpan = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  text-align: center;

  color: #808080;

  @media only screen and (max-width: 944px) {
    font-size: 1.2rem;
    /* white-space: nowrap; */
  }
`

export const StrongSpan = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: #0d0d0d;

  @media only screen and (max-width: 944px) {
    font-size: 1.2rem;
  }
`

export const NormalSpan = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  color: #808080;

  @media only screen and (max-width: 944px) {
    font-size: 1.2rem;
  }
`

export const DateAndHour = styled.span`
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;

  color: #808080;
`

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: rgba(56, 40, 14, 0.2);
  margin: 12px auto;
  margin-bottom: -4px;
`

export const RouteWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const RouteStrongSpan = styled.span`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 19px;

  color: #0d0d0d;

  // &:nth-of-type(2) {
  //   width: 222px;
  // }

  @media only screen and (max-width: 944px) {
    font-size: 1.4rem;
  }
`

export const RouteNormalSpan = styled.span`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 17px;
  margin-top: 10px;

  color: #a0a0a0;
  width: 100%;

  @media only screen and (max-width: 944px) {
    font-size: 1.2rem;
  }
`

export const IconsAndNumbersWrapper = styled.div`
  width: 100%;
  margin-top: 5px;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px 0;
  gap: 24px;

  @media only screen and (max-width: 944px) {
    gap: 1.6rem;
  }
`

export const IconAndNumber = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    color: #a0a0a0;
  }

  @media only screen and (max-width: 944px) {
    img {
      /* width: 1.6rem; */
      /* min-width: 1.6rem; */
      max-height: 1.8rem;
      min-height: 1.8rem;
    }

    span {
      font-size: 1.4rem;
    }
  }
`

export const PriceSpan = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  text-align: center;

  color: #808080;

  @media only screen and (max-width: 944px) {
    font-size: 1.2rem;
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;

  margin-bottom: 20px;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 4px;

    margin-top: 5px;
  }

  h5 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 29px;

    color: #27c7ff;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    text-align: center;

    color: #808080;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    text-align: center;

    color: #0d0d0d;
  }

  @media only screen and (max-width: 944px) {
    h5 {
      font-size: 2rem;
    }
    p {
      font-size: 1.4rem;
    }
    span {
      font-size: 1.2rem;
    }
  }
`

export const EditContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5.13px;
  cursor: pointer;
`

export const CalendarWrapper = styled.div`
  .mantine-DatePicker-day[data-selected] {
    background-color: #27c7ff;
    color: white;
  }

  .mantine-DatePicker-day {
    color: #495057;
    width: 44px;
    height: 44px;

    border-radius: 4px;
  }

  .mantine-DatePicker-day[data-outside] {
    color: #ced4da;
  }

  .mantine-TimeInput-icon img {
    width: 24px;
    height: 24px;
  }

  .mantine-TimeInput-input {
    border: 0.507906px solid #bfbfbf;
    border-radius: 6.09488px;
  }

  .mantine-TimeInput {
    font-family: 'Neometric';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #0d0d0d;
  }

  .mantine-CalendarHeader-calendarHeaderLevel {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 155%;
  }

  .mantine-DatePicker-weekday {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 155%;
    color: #adb5bd;
  }

  .mantine-DatePicker-day {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 155%;
  }

  .mantine-MonthLevel-calendarHeader {
    max-width: none;
  }

  .mantine-TimeInput-wrapper,
  .mantine-TimeInput-input {
    width: 322px;
    height: 54px;

    border: 0.507906px solid #bfbfbf;
    border-radius: 6.09488px;
  }

  .mantine-TimeInput-input {
    font-family: 'Neometric';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #0d0d0d;
  }
  .mantine-TimeInput-icon {
    margin-left: 1rem;
  }

  .mantine-TimeInput-input[data-with-icon] {
    padding-left: 4.5rem;
  }
`

export const IconX = styled.img`
  width: 24px !important;
  height: 24px !important;
  margin-right: 10px;
  cursor: pointer;
`
