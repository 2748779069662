import styled, { css } from 'styled-components'
export const Container = styled.div`
  ${({ dark }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 14rem auto;
    gap: 16px;
    z-index: 200;
    position: relative;

    > img {
      width: 15rem;
      /* height: 5rem; */
    }

    button {
      border: 0 !important;
    }

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: ${dark ? '#f3f4f6' : '#000000'};
    }

    .mantine-Carousel-controls {
      gap: 95rem;
      justify-content: center;
    }

    margin-top: 15rem;

    @media only screen and (max-width: 768px) {
      // > img {
      //   width: 14rem;
      // }

      > span {
        font-size: 1.4rem;
      }
    }
  `}
`

export const TitleContainer = styled.h1`
  margin-bottom: 7rem;
  font-size: 16px;
`

export const EasyTransferImage = styled.img`
  all: unset;
  width: clamp(200px, 70vw, 70vw);
`
