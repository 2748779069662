import { useState } from 'react'

const FILTER_MAP = [
  ['', 'Filtro'],
  ['PENDING', 'Pendente'],
  ['ACCEPTED', 'Aceito'],
  ['DONE', 'Realizado'],
  ['CANCELED', 'Cancelado']
]

export function useFilter() {
  const [filter, setFilter] = useState(FILTER_MAP[1])
  const [value, label] = filter

  function changeFilter(value) {
    const index = FILTER_MAP.findIndex((item) => item[0] === value)

    setFilter(FILTER_MAP[index])
  }

  return {
    filter: { label, value },
    changeFilter,
    filterOptions: FILTER_MAP
  }
}
