import React from 'react'
import { Dropzone } from '@mantine/dropzone'
import { Group, Input, Text, rem } from '@mantine/core'
import { IconDownload, IconX } from '@tabler/icons-react'
import { Car, CopyCheck, FileText, Trash2, Upload } from 'lucide-react'
import './index.css'
import { useTranslation } from 'react-i18next'

const CustomDropzone = ({
  openRef,
  onDrop,
  identifier,
  files,
  theme,
  classes,
  handleDelete,
  name,
  maxFiles,
  disabled = false,
  setData,
  data,
  maxSize,
  customDelete
}) => {
  const isDropzoneSelected = files?.some(
    (item) => (item.identifier || item.title) === identifier
  )
  const { t } = useTranslation()

  const onDeleteClick = () => {
    const fileToDelete = files?.find((file) => file.identifier === identifier)
    handleDelete(fileToDelete)
  }

  const handleChange = (field, value) => {
    setData({ ...data, [field]: value })
  }

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <Dropzone
          openRef={openRef}
          onDrop={onDrop}
          className={`${classes.dropzone} ${
            isDropzoneSelected ? classes.selected : ''
          }`}
          radius="md"
          multiple={maxFiles > 1}
          maxFiles={maxFiles}
          disabled={disabled}
          maxSize={maxSize ? maxSize : 2 * 1024 ** 2}
        >
          <div
            style={{
              pointerEvents: 'none',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <Group justify="center">
              <Dropzone.Accept>
                <IconDownload
                  style={{ width: rem(50), height: rem(50) }}
                  color={theme.colors.blue[6]}
                  stroke={1.5}
                />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX
                  style={{ width: rem(50), height: rem(50) }}
                  color={theme.colors.red[6]}
                  stroke={1.5}
                />
              </Dropzone.Reject>
              {files?.filter(
                (item) => (item.identifier || item.title) === identifier
              ).length > 0 ? (
                <FileText color="#27c7ff" width={48} height={48} />
              ) : (
                <Dropzone.Idle>
                  <Upload color="#27c7ff" width={48} height={48} />
                </Dropzone.Idle>
              )}
            </Group>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {files?.filter(
                (item) => (item.identifier || item.title) === identifier
              ).length > 0 ? (
                files
                  ?.filter(
                    (item) => (item.identifier || item.title) === identifier
                  )
                  .map((file) => (
                    <Text
                      ta="center"
                      fw={700}
                      fz="lg"
                      mt="xl"
                      key={file.fileName}
                    >
                      <span>{file.fileName}</span>
                    </Text>
                  ))
              ) : (
                <Text ta="center" fw={700} fz="lg" mt="xl">
                  <Dropzone.Accept>Drop data here</Dropzone.Accept>
                  <Dropzone.Reject>Pdf file less than 30mb</Dropzone.Reject>
                  <Dropzone.Idle>{t('formCompany.dropYourFile')}</Dropzone.Idle>
                </Text>
              )}
            </div>
            <Text ta="center" fz="sm" mt="xs" c="dimmed"></Text>
          </div>
        </Dropzone>
      </div>
      <div>
        <div className={classes.info_wrapper}>
          {files?.filter(
            (item) => (item.identifier || item.title) === identifier
          ).length > 0 ? (
            <>
              {!customDelete && (
                <Trash2
                  style={{ cursor: 'pointer' }}
                  color="#27c7ff"
                  width={24}
                  height={24}
                  onClick={onDeleteClick}
                />
              )}
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

export default CustomDropzone
