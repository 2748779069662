import styled, { css } from 'styled-components'
export const Container = styled.div`
  border-radius: 16px;
  border: 2px solid var(--grey-4-grayscale, #f7f7f7);
  width: 100%;
  padding: 16px;

  @media (max-width: 768px) {
    border: 0;
  }

  @media (max-width: 1024px) {
    & {
      padding: 8px;
    }
  }
`

export const IconsBackground = styled.div`
  ${({ realized }) => css`
    display: flex;
    padding: 13.366px;
    flex-direction: column;
    align-items: center;
    gap: 8.354px;
    border-radius: 4px;
    background: var(--grey-4-grayscale, #f7f7f7);
  `}
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  @media (max-width: 1300px) {
    & {
      gap: 15px;
    }
    .divider {
      margin-right: 15px;
    }
  }
  @media (max-width: 1024px) {
    & {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }
  }
`

export const TimeWrapper = styled.div`
  border: 1px solid #27c7ff;
  padding: 8px;
  border-radius: 8px;
  width: 90px;
  height: 99px;

  @media (max-width: 1024px) {
    & {
      display: none;
    }
  }
`

export const TimeAndStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const TransferTitle = styled.h1`
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--grey-1-grayscale, #404040);
  font-family: Neometric;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 21.6px */
  letter-spacing: 0.18px;

  @media (max-width: 768px) {
    display: none;
  }
`

export const StatusCard = styled.div`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--yellow-3-support, #E9F9FF);
  color: var(--grey-1-grayscale, #404040);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Neometric;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column-reverse;
`

export const RejectButton = styled.div`
  button {
    border-radius: 16px;
    padding: 16px, 24px, 16px, 32px;
    background-color: #f8294e;
    width: 200px;
    height: 56px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.01em;
  }
  button:hover {
    background-color: #f8294e;
  }

  @media (max-width: 1024px) {
    button {
      display: flex;
      padding: 16px 24px 16px 32px;
      justify-content: space-between;
      align-items: center;
    }
  }
`

export const AcceptedButton = styled.div`
  button {
    border-radius: 16px;
    padding: 16px, 24px, 16px, 32px;
    background-color: #48d9a4;
    width: 200px;
    height: 56px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.01em;
  }

  button:hover {
    background-color: #48d9a4;
  }
`

export const DayTitle = styled.p`
  font-family: Neometric;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #404040;
`

export const MonthTitle = styled.p`
  font-family: Neometric;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0px;
  color: #27c7ff;
`

export const YearTitle = styled.p`
  font-family: Neometric;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 8px;
  color: #404040;
`

export const TimeTitle = styled.p`
  font-family: Neometric;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0px;
  color: #27c7ff;
`

export const IdWrapper = styled.p`
  strong {
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.01em;
  }

  span {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.01em;
  }
`

export const AddressWrapper = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`

export const DepartureWrapper = styled.div`
  ${({ realized, stops }) => css`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: ${stops && '2.2rem'};
    p {
      color: ${stops && 'black'};
    }
    svg > path {
      stroke: ${stops && 'black'};
    }
    p {
      color: ${realized && '#808080'};
    }
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  `}
`

export const DepartureTitle = styled.p`
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--yellow-brand-primary, #27c7ff);
  text-align: center;
  font-family: Neometric;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;

  @media (max-width: 768px) {
    color: var(--yellow-brand-primary, #27c7ff);
    text-align: center;
    font-family: Neometric;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 14.4px */
    letter-spacing: 0.12px;
  }
`

export const DirectionButton = styled.button`
  width: 54px;
  height: 28px;
  border-radius: 8px;
  background-color: #DFF7FF;
`

export const PriceTitle = styled.p`
  color: var(--grey-1-grayscale, #404040);
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Neometric;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 28.8px */
  letter-spacing: 0.24px;

  @media (max-width: 1024px) {
    & {
      color: #27c7ff !important;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      letter-spacing: 0.16px;
      width: 100%;
    }
  }
`

export const PriceMobile = styled.p`
  font-family: Neometric;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.01em;
  color: #404040;
  margin-left: 30px;
  margin-bottom: auto;
  display: none;

  @media (max-width: 1024px) {
    & {
      display: block;
    }
  }
`

export const DepartureDescription = styled.p`
  font-family: Neometric;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.01em;
  color: #404040;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    & {
      color: var(--grey-1-grayscale, #404040);
      font-family: Neometric;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 15.6px */
      letter-spacing: 0.12px;
    }
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  width: 100%;

  @media (max-width: 768px) {
    align-items: center;
  }
`

export const IconsAndNumbersWrapper = styled.div`
  width: 100%;
  margin-top: 5px;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 16px 0;
  gap: 24px;

  @media (max-width: 768px) {
    display: none;
  }
`

export const IconAndNumber = styled.div`
  display: flex;
  padding: 4px 8px 4px 4px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid var(--grey-4-grayscale, #f7f7f7);

  span {
    color: var(--black-brand-primary, #222);
    font-family: Neometric;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  strong {
    color: var(--grey-1-grayscale, #404040);
    font-family: Neometric;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`

export const TimeDescription = styled.div`
  display: none;

  @media (max-width: 1024px) {
    & {
      display: flex;
      padding: 6px 8px;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: var(--yellow-brand-primary, #27c7ff);
      color: var(--white-grayscale, #fff);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  @media (max-width: 768px) {
    & {
      color: var(--white-grayscale, #fff);
      leading-trim: both;
      text-edge: cap;
      font-family: Neometric;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`

export const ValuesWrapper = styled.div`
  gap: 8px;
  border-radius: 8px;
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 24px;

  @media (max-width: 1024px) {
    display: none;
  }
`

export const DistanceMobile = styled.div`
  ${({ realized }) => css`
    display: none;

    @media (max-width: 768px) {
      display: flex;
      align-items: center;

      a {
        display: flex;
        align-items: center;
        padding: 10px 8px;

        gap: 8px;
        border-radius: 8px;
        background: ${realized
          ? 'var(--grey-3-grayscale, #BFBFBF);'
          : 'var(--yellow-brand-primary, #27c7ff)'};
        cursor: ${realized && 'not-allowed'};

        color: var(--white-grayscale, #fff);
        font-family: Neometric;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%; /* 16.8px */
        letter-spacing: 0.14px;
      }
    }
  `}
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  gap: 15px;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`
export const ChevronButton = styled.button`
  display: flex;
  padding: 8.39px;
  align-items: flex-start;
  gap: 8.39px;
  border-radius: 31.464px;
  background: var(--grey-4-grayscale, #f7f7f7);
`
export const Id = styled.span`
  display: none;
  color: var(--grey-1-grayscale, #404040);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.1px;
  strong {
    color: var(--grey-1-grayscale, #404040);
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 12px */
    letter-spacing: 0.1px;
  }

  @media (max-width: 768px) {
    display: block;
  }
`
export const FlighstatBackground = styled.div`
  border-radius: 8px;
  border: 1px solid var(--yellow-brand-primary, #27c7ff);
  padding: 16px 24px;

  @media (max-width: 768px) {
    display: none;
  }
`

export const FlighstatWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`

export const FlighstatContent = styled.div`
  display: flex;
  gap: 8px;
`

export const FlighstatContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`
export const FlighstatTitle = styled.h1`
  color: var(--grey-2-grayscale, #808080);
  leading-trim: both;
  text-edge: cap;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 14.4px */
  letter-spacing: 0.36px;
  text-transform: uppercase;
`

export const FlighstatDescription = styled.span`
  color: var(--grey-1-grayscale, #404040);
  font-family: Neometric;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 21.6px */
  letter-spacing: 0.18px;
`
