import * as Styled from './styles'
import { ReactComponent as DriverImage } from './imgs/DriverImage.svg'
import { ReactComponent as CloseIcon } from './imgs/CloseIcon.svg'

export const RejectModal = ({ opened, onClose, onSuccess }) => {
  return (
    <>
      <Styled.ModalOverlay opened={opened} onClick={onClose} />
      <Styled.ModalContainer opened={opened}>
        <Styled.ModalHeader>
          <Styled.CloseButton>
            <CloseIcon onClick={onClose} />
          </Styled.CloseButton>
        </Styled.ModalHeader>
        <Styled.ModalContent>
          <DriverImage />
          <Styled.ModalTitle>
            Você tem certeza que deseja deletar essa conta?
          </Styled.ModalTitle>
        </Styled.ModalContent>
        <Styled.ModalButton onClick={onSuccess}>SIM</Styled.ModalButton>
      </Styled.ModalContainer>
    </>
  )
}
