import api from './api'

const fetchObj = {
  createOrderBump: (payload) => {
    const res = api.post('/order-bumps', payload)

    return res
  },

  updateOrderBump: (payload) => {
    const res = api.put('/order-bumps', payload)

    return res
  },

  getAllOrderBump: (payload) => {
    const res = api.get('/order-bumps', {
      params: { ...payload, deleted: false }
    })

    return res
  },

  getOrderBumpFindByPoints: (params) => {
    const res = api.get('/order-bumps/find-by-points', {
      params
    })

    return res
  },

  getOrderBumpById: (id) => {
    const res = api.get(`/order-bumps/${id}`)

    return res
  },

  removeOrderBumpById: (id) => api.delete(`/order-bumps/${id}`)
}
export default fetchObj
