import { useNavigate } from 'react-router-dom'
import { Group, Text, ActionIcon, Anchor, Checkbox } from '@mantine/core'
import { ChevronRight, Copy } from 'lucide-react'
import { ItemCounters } from './ItemCounters'
import { useClipboard } from '@mantine/hooks'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const formatCurrency = (currency) => {
  return Intl.NumberFormat('PT', {
    currency: 'EUR',
    style: 'currency'
  }).format(currency)
}

function formatDate(transferDate) {
  const data = new Date(transferDate)

  const opcoesFusoHorario = { timeZone: 'Europe/Lisbon' }

  data.setMinutes(
    data.getMinutes() +
      data.getTimezoneOffset() +
      (data.getTimezoneOffset() === 0 ? 0 : -60)
  )

  const dia = String(data.getDate()).padStart(2, '0')
  const mes = String(data.getMonth() + 1).padStart(2, '0')
  const ano = String(data.getFullYear()).slice(-2)
  const horas = String(
    data.toLocaleString('pt-PT', {
      ...opcoesFusoHorario,
      hour: 'numeric',
      hour12: false
    })
  ).padStart(2, '0')
  const minutos = String(data.getMinutes()).padStart(2, '0')

  return `${dia}/${mes}/${ano} - ${horas}:${minutos}`
}

export function ItemRow({ item, selected, onSelectRow }) {
  const navigate = useNavigate()
  const clipboard = useClipboard({ timeout: 500 })

  const { t } = useTranslation()

  return (
    <tr
      className={selected ? 'selected' : ''}
      style={{ cursor: 'pointer' }}
      onClick={onSelectRow}
    >
      <td>
        <Checkbox color="yellow" checked={selected} onChange={onSelectRow} />
      </td>
      <td
        style={{
          minWidth: 250,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 5
        }}
      >
        <Text fz="sm" fw={500}>
          {item.id.length > 8 ? item.id.slice(0, 8) + '...' : item.id}{' '}
          <Copy
            size="16"
            style={{ cursor: 'pointer' }}
            alt="Copy id"
            onClick={(e) => {
              e.stopPropagation()
              try {
                clipboard.copy(item.id)
                toast.success(t('toast.copiedID'))
              } catch {
                toast.error(t('errorCopiedID'))
              }
            }}
          />
        </Text>

        {item?.lowCost && <ActiveLabel />}
      </td>
      <td style={{ minWidth: 160 }}>
        <Text fz="sm">{formatDate(item.transferDate)}</Text>
      </td>
      <td style={{ minWidth: 400 }}>
        <Text fz="sm">
          {item?.startingPlace} → {item?.arrivalPlace}
        </Text>
      </td>
      <td style={{ minWidth: 180 }}>
        <Anchor component="button" size="sm">
          {item?.vehicle?.category?.name ?? '-'}
        </Anchor>
      </td>
      <td style={{ minWidth: 180 }}>
        <Anchor component="button" size="sm">
          {formatCurrency(item.totalPrice / 100)}
        </Anchor>
      </td>
      <td style={{ minWidth: 120 }}>
        <ItemCounters transfer={item} />
      </td>
      <td style={{ minWidth: 100 }}>
        <Group gap={0} justify="flex-end">
          <ActionIcon
            variant="subtle"
            color="gray"
            className="action-icon"
            onClick={() => navigate(`${item.id}`)}
            style={{ width: 30, height: 30 }}
          >
            <ChevronRight width={24} height={24} color="#27c7ff" />
          </ActionIcon>
        </Group>
      </td>
    </tr>
  )
}

function ActiveLabel() {
  return (
    <div
      style={{
        backgroundColor: '#48D9A4',
        color: '#fff',
        padding: '4px 8px',
        borderRadius: 4
      }}
    >
      Ativo
    </div>
  )
}
