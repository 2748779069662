import * as S from './styles'
import DriverImage from '../../../../assets/Personal-driver-easytransfer.webp'
import { useTranslation } from 'react-i18next'
import { ReactComponent as EasyTransferWriting } from '../../../../assets/EasyTransfer.svg'

const TRANSFERS_MADE = 23560
const THOUSANDS_OF_CLIENTS_SATISFIED = 32
const TONS_OF_CO2_EMISSIONS = 25
const MILLIONS_OF_KMS_TRAVELLED = 2

export const Stats = () => {
  const { t } = useTranslation()

  return (
    <S.Container>
      <EasyTransferWriting />
      <S.StatsContainer>
        <S.StatsColumn>
          <S.StatContainer>
            <S.Plus>
              <div />
              <div />
              <div />
              <div />
            </S.Plus>
            <S.StatTextContainer>
              <span>
                {TRANSFERS_MADE} {t('stats.transfers')}
              </span>
              <span>{t('stats.made')}</span>
            </S.StatTextContainer>
          </S.StatContainer>
          <S.StatContainer>
            <S.Plus>
              <div />
              <div />
              <div />
              <div />
            </S.Plus>
            <S.StatTextContainer>
              <span>
                {THOUSANDS_OF_CLIENTS_SATISFIED} {t('stats.thousand')}
              </span>
              <span>{t('stats.servedCustomers')}</span>
            </S.StatTextContainer>
          </S.StatContainer>
        </S.StatsColumn>
        <S.StatsColumn>
          <S.StatContainer>
            <S.Plus>
              <div />
              <div />
              <div />
              <div />
            </S.Plus>
            <S.StatTextContainer>
              <span>
                {TONS_OF_CO2_EMISSIONS} {t('stats.tons')}
              </span>
              <span>{t('stats.ofC02Saved')}</span>
            </S.StatTextContainer>
          </S.StatContainer>
          <S.StatContainer>
            <S.Plus>
              <div />
              <div />
              <div />
              <div />
            </S.Plus>
            <S.StatTextContainer>
              <span>
                {MILLIONS_OF_KMS_TRAVELLED} {t('stats.millions')}
              </span>
              <span>{t('stats.ofKmsTravelled')}</span>
            </S.StatTextContainer>
          </S.StatContainer>
        </S.StatsColumn>
      </S.StatsContainer>
      <S.DriverImageContainer>
        <div />
        <S.DriverImage src={DriverImage} alt="easytransferdriver" />
      </S.DriverImageContainer>
    </S.Container>
  )
}
