/* eslint-disable no-case-declarations */
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useTransferContext } from '../../../../../../contexts/TransferContext'
import debounce from '../../../../../../utils/debounce'
import useClickOutside from '../../../../../../hooks/useClickOutside'
import { Input } from '@mantine/core'
import { ReactComponent as Bus } from './imgs/bus-icon.svg'
import { ReactComponent as Plane } from './imgs/plane-icon.svg'
import * as Styled from './styles'
import { useTranslation } from 'react-i18next'

const MOCK = [
  'Ericeira, Portugal',
  'Erick, OK',
  'Erice, Free municipal consortium of Trapani, Italy',
  'Eric Hamber Secondary School, Willow Street, Vancouver, BC, Canada',
  'Erickson, MB, Canada'
]

const SUGGESTIONS = [
  {
    value: 'Aeroporto de Lisboa',
    code: 'LIS',
    type: 'airport'
  },
  {
    value: 'Ericeira, Portugal',
    type: 'location'
  },
  {
    value: 'Lisboa, Portugal',
    type: 'location'
  }
]

const ALLOWED_KEY_CODES = ['ArrowDown', 'ArrowUp', 'Enter', 'Escape']

export const SearchField = ({
  icon,
  label,
  placeholder,
  onChange,
  setIsAirport,
  initialValue,
  options: SUGGESTIONS
}) => {
  const [search, setSearch] = useState(initialValue)
  console.log(search, 'search')
  const [searchAutocomplete, setSearchAutocomplete] = useState([])
  const [open, setOpen] = useState(false)
  const [focusedIndex, setFocusedIndex] = useState(undefined)
  const [locationType, setLocationType] = useState(undefined)
  const domNode = useClickOutside(
    () => {
      setOpen(false)
      setFocusedIndex(undefined)
    },
    null,
    []
  )
  const { t } = useTranslation()
  const { clearTour } = useTransferContext()

  console.log(SUGGESTIONS, 'SUGGESTIONS')

  const onClear = useCallback(() => {
    clearTour()
  }, [clearTour])

  useEffect(() => {
    if (!setIsAirport) return
    if (locationType && locationType === 'airport') {
      setIsAirport(true)
      return
    }
    setIsAirport(false)
  }, [locationType, setIsAirport])

  // useEffect(() => {
  //   if (!search?.trim()) {
  //     setSearchAutocomplete([])
  //     return
  //   }
  //   debounce(async () => {
  //     const autocomplete = await autocompleteSearch(search)
  //     const predictions = autocomplete.predictions.map((sug) => sug.description)
  //     setSearchAutocomplete(predictions)
  //   }, 900)
  // }, [search])

  const normalizeString = (str) =>
    str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

  const handleVowels = (strArr) =>
    strArr.map((i) => {
      const vowels = ['a', 'e', 'i', 'o', 'u']
      const hasOnlyVowels = i.split('').every((c) => vowels.includes(c))
      if (hasOnlyVowels) {
        return normalizeString(i)
      }
      return normalizeString(i).replace(/[aeiou]/gi, '[a-zÀ-ÿ]')
    })

  const filteredSuggestions = !search?.trim()
    ? SUGGESTIONS
    : SUGGESTIONS.filter((sug) => {
        const normalizedSearch = handleVowels(search?.trim().split(' ')).join(
          ' '
        )
        const regex = new RegExp(normalizedSearch, 'gi')
        return !!sug.label.match(regex) || (sug.code && !!sug.code.match(regex))
      })

  const allSuggestions = useMemo(
    () => [...filteredSuggestions, ...searchAutocomplete],
    [filteredSuggestions, searchAutocomplete]
  )

  // useEffect(() => {
  //   if (!open || allSuggestions.length === 0) return
  //   const handler = (e) => {
  //     if (!ALLOWED_KEY_CODES.includes(e.code)) {
  //       if (focusedIndex !== undefined) {
  //         const value =
  //           allSuggestions[focusedIndex].value || allSuggestions[focusedIndex]
  //         setLocationType(
  //           allSuggestions[focusedIndex].type
  //             ? allSuggestions[focusedIndex].type
  //             : undefined
  //         )
  //         setSearch(value)
  //         onChange(value)
  //         setFocusedIndex(undefined)
  //       }
  //       return
  //     }
  //     switch (e.code) {
  //       case 'ArrowDown':
  //         setFocusedIndex((oldState) =>
  //           typeof oldState === 'number' && oldState < allSuggestions.length - 1
  //             ? oldState + 1
  //             : oldState === allSuggestions.length - 1
  //             ? undefined
  //             : 0
  //         )
  //         break
  //       case 'ArrowUp':
  //         setFocusedIndex((oldState) =>
  //           typeof oldState === 'number' && oldState > 0
  //             ? oldState - 1
  //             : oldState === undefined
  //             ? allSuggestions.length - 1
  //             : undefined
  //         )
  //         break
  //       case 'Enter':
  //         const value =
  //           focusedIndex !== undefined
  //             ? allSuggestions[focusedIndex].value ||
  //               allSuggestions[focusedIndex]
  //             : search

  //         setLocationType(
  //           focusedIndex !== undefined && allSuggestions[focusedIndex].type
  //             ? allSuggestions[focusedIndex].type
  //             : undefined
  //         )
  //         setSearch(value)
  //         focusedIndex !== undefined && onChange(value)
  //         setFocusedIndex(undefined)
  //         setOpen(false)
  //         break
  //       case 'Escape':
  //         setFocusedIndex(undefined)
  //         setOpen(false)
  //         break
  //       default:
  //         break
  //     }
  //   }
  //   document.addEventListener('keydown', handler)
  //   return () => document.removeEventListener('keydown', handler)
  // }, [
  //   open,
  //   focusedIndex,
  //   // autocompleteSearch,
  //   searchAutocomplete,
  //   allSuggestions
  // ])

  const highlightSearchQuery = (text, search) => {
    // MUITO PROVAVELMENTE VAI PRECISAR ARRUMAR ALGUMA COISA AQUI
    if (!search?.trim()) return text
    const normalizedSearch = handleVowels(search.split(' ')).join('|')
    const regex = new RegExp(normalizedSearch, 'gi')
    return text.replace(
      regex,
      (match) => `<span class='highlight'>${match}</span>`
    )
  }

  const airportsIndex = allSuggestions.findIndex(
    (sug) => sug.type && sug.type === 'airport'
  )
  const locationsIndex = allSuggestions.findIndex(
    (sug) => sug.type && sug.type === 'location'
  )
  const autocompleteIndex = allSuggestions.findIndex(
    (sug) => typeof sug === 'string'
  )

  useEffect(() => {
    if (typeof search === 'string' && search?.trim() === '') onClear()
    if (search !== initialValue) onClear()
  }, [search, onClear, initialValue])

  return (
    <Styled.OneTransfer>
      {typeof icon === 'string' ? (
        <img src={icon} alt="Ícone do input" />
      ) : (
        icon
      )}
      <Input.Wrapper id="input-demo" label={label} style={{ zIndex: 999 }}>
        <Input
          required
          id="input-demo"
          placeholder={placeholder}
          value={
            focusedIndex !== undefined
              ? allSuggestions[focusedIndex].value ||
                allSuggestions[focusedIndex]
              : search
          }
          // ref={originRef}
          onChange={(e) => {
            setSearch(e.target.value)
            setOpen(true)
          }}
          onFocus={() => setOpen(true)}
        />
      </Input.Wrapper>
      {open && allSuggestions.length > 0 && (
        <Styled.SuggestionsContainer ref={domNode}>
          <ul>
            <strong>{t('suggestionsTime')}</strong>
            {allSuggestions.map((sug, idx) => (
              <Fragment key={idx}>
                {/* {airportsIndex >= 0 && idx === airportsIndex && (
                  <strong>Aeroportos:</strong>
                )} */}
                {/* {locationsIndex > 0 && idx === locationsIndex && (
                  <div
                    style={{
                      height: '1.6rem',
                      minHeight: '1.6rem',
                      margin: '0.8rem 0'
                    }}
                  />
                )}
                {autocompleteIndex > 0 && idx === autocompleteIndex && (
                  <div
                    style={{
                      height: '1.6rem',
                      minHeight: '1.6rem',
                      margin: '0.8rem 0'
                    }}
                  />
                )} */}
                <li
                  onClick={() => {
                    setLocationType(sug.type ? sug.type : undefined)
                    setSearch(sug.label || sug)
                    onChange(sug || sug)
                    setOpen(false)
                  }}
                  className={idx === focusedIndex ? 'focused' : undefined}
                >
                  {sug.type && sug.type === 'airport' && <Plane />}
                  {sug.type && sug.type === 'location' && <Bus />}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: highlightSearchQuery(sug.label || sug, search)
                    }}
                  />
                </li>
              </Fragment>
            ))}
          </ul>
        </Styled.SuggestionsContainer>
      )}
    </Styled.OneTransfer>
  )
}
