import api from './api'

const fetchObj = {
  create: (payload) => {
    const res = api.post('/hostel', payload)

    return res
  },

  update: (payload) => {
    const res = api.put('/hostel', payload)

    return res
  },
  delete: (id) => {
    const res = api.delete(`/hostel/${id}`)

    return res
  },
  getById: (id) => {
    const res = api.get(`/hostel/open/${id}`)

    return res
  },
  getByIdAll: (id) => {
    const res = api.get(`/hostel/${id}`)

    return res
  },
  getAll: (params) => {
    const res = api.get('/hostel', { params })

    return res
  },
  status: (id, active) => {
    const res = api.patch(`/hostel/toggle-activation/${id}/${active}`)

    return res
  }
}

export default fetchObj
