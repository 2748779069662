import * as Styled from './styles'
import { ReactComponent as CloseIcon } from '../imgs/CloseIcon.svg'
import { Checkbox } from '@mantine/core'
import { forwardRef, useState } from 'react'
import { DatePickerInput } from '@mantine/dates'
import { CalendarDays } from 'lucide-react'

const today = new Date()
const yesterday = new Date()
const fourDaysAgo = new Date()
const weekAgo = new Date()
const monthAgo = new Date()

yesterday.setDate(new Date().getDate() - 1)
fourDaysAgo.setDate(new Date().getDate() - 4)
weekAgo.setDate(new Date().getDate() - 7)
monthAgo.setDate(new Date().getDate() - 30)

const filterOptions = [
  {
    label: 'Hoje',
    from: today,
    to: today
  },
  {
    label: 'Ontem',
    from: yesterday,
    to: yesterday
  },
  {
    label: 'Últimos 4 dias',
    from: fourDaysAgo,
    to: today
  },
  {
    label: 'Últimos 7 dias',
    from: weekAgo,
    to: today
  },
  {
    label: 'Últimos 30 dias',
    from: monthAgo,
    to: today
  },
  {
    label: 'Personalizado',
    from: today,
    to: today
  }
]

const Component = ({ onClose, onSuccess, clearFilter, initialFilter }, ref) => {
  const [selectedOption, setSelectedOption] = useState(initialFilter)

  return (
    <Styled.Container ref={ref}>
      <Styled.Header>
        <h2>Filtro</h2>
        <Styled.CloseButton>
          <CloseIcon
            onClick={() => {
              onClose()
            }}
          />
        </Styled.CloseButton>
      </Styled.Header>
      <Styled.Content>
        <strong>Status</strong>
        <ul>
          {filterOptions.map((opt) => (
            <li key={opt.label}>
              <Checkbox
                size="lg"
                checked={selectedOption?.label === opt.label}
                color="orange-primary"
                onChange={() => setSelectedOption(opt)}
                label={opt.label}
              />
            </li>
          ))}
        </ul>
        {selectedOption?.label === 'Personalizado' && (
          <DatePickerInput
            size="xl"
            type="range"
            placeholder="00/00/0000 - 00/00/0000"
            valueFormat="DD/MM/YYYY"
            icon={<CalendarDays color="#27c7ff" />}
            onChange={(value) =>
              setSelectedOption((oldState) => ({
                ...oldState,
                from: value[0],
                to: value[1] !== null ? value[1] : value[0]
              }))
            }
            defaultValue={[
              selectedOption?.from || null,
              selectedOption?.to || null
            ]}
          />
        )}
      </Styled.Content>
      <Styled.Button outlined onClick={clearFilter}>
        LIMPAR FILTRO
      </Styled.Button>
      <Styled.Button onClick={() => onSuccess(selectedOption)}>
        APLICAR FILTRO
      </Styled.Button>
    </Styled.Container>
  )
}

export const FilterPopup = forwardRef(Component)
