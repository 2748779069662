import React, { useEffect, useState } from 'react'

import { useAuth } from '../../../../contexts/useAuth'
import fetchBooking from '../../../../services/booking'

import * as Styled from './styles'

import {
  BookingOriginsChart,
  BookingsChart,
  Counters,
  DateFilter,
  EarningsChart,
  EarnsPerPeriodChart,
  FinishedTransfersChart
} from './components'

export function DashboardHome() {
  const [dateFilter, setDateFilter] = useState(null)

  return (
    <Styled.Container>
      <Styled.Header>
        <UserGreetings />
        <DateFilter onChange={setDateFilter} />
      </Styled.Header>

      <Styled.Row>
        <BookingsChart filter={dateFilter} />
        <BookingOriginsChart filter={dateFilter} />
        <EarningsChart filter={dateFilter} />
      </Styled.Row>

      <Styled.Row>
        <Counters filter={dateFilter} />
      </Styled.Row>

      <LongCharts filter={dateFilter} />
    </Styled.Container>
  )
}

function UserGreetings() {
  const { user } = useAuth()
  return (
    <Styled.TitleContainer>
      <Styled.WelcomeText>Olá, </Styled.WelcomeText>
      <Styled.WelcomeText style={{ fontWeight: 600 }}>
        {user?.name}
      </Styled.WelcomeText>
    </Styled.TitleContainer>
  )
}

function LongCharts({ filter }) {
  const [counters, setCounter] = useState(null)

  useEffect(() => {
    const year = filter
      ? filter?.startDate.getFullYear()
      : new Date().getFullYear()

    fetchBooking.getBookingYearlyCounters({ year }).then(({ status, data }) => {
      if (status === 200) {
        const doneBookings = data.map((item) => item?.doneBookings ?? 0)
        const earnings = data.map((item) => item?.earnings ?? 0)

        setCounter({ doneBookings, earnings })
        console.log({ doneBookings, earnings })
      }
    })
  }, [filter])

  return (
    <Styled.LongChartsContainer>
      <FinishedTransfersChart data={counters?.doneBookings} />
      <EarnsPerPeriodChart data={counters?.earnings} />
    </Styled.LongChartsContainer>
  )
}
