import { createGlobalStyle, css } from 'styled-components'

import Neometric from '../font/Neometric-Regular.otf'
import Neometric2 from '../font/Neometric Medium (Regular).otf'
import Neometric3 from '../font/Neometric Alt Extra Bold (Bold).otf'
import Neometric4 from '../font/NeometricAlt-ExtraBold.ttf'
import Roboto from '../font/Roboto-Bold.ttf'
import Roboto2 from '../font/Roboto-Medium.ttf'
import Roboto3 from '../font/Roboto-Regular.ttf'
import PoppinsLight from '../font/Poppins-Light.ttf'
import PoppinsRegular from '../font/Poppins-Regular.ttf'
import PoppinsMedium from '../font/Poppins-Medium.ttf'
import PoppinsSemiBold from '../font/Poppins-SemiBold.ttf'
import PoppinsBold from '../font/Poppins-Bold.ttf'
import PoppinsBlack from '../font/Poppins-Black.ttf'
import GothamThin from '../font/Gotham-Thin.woff'
import GothamXLight from '../font/Gotham-XLight.woff'
import GothamLight from '../font/Gotham-Light.woff'
import GothamBook from '../font/Gotham-Book.woff'
import GothamMedium from '../font/Gotham-Medium.woff'
import GothamBold from '../font/Gotham-Bold.woff'
import GothamBlack from '../font/Gotham-Black.woff'

const GlobalStyles = createGlobalStyle`

  @font-face {
    font-family: 'Neometric';
    src: url(${Neometric4}) format('opentype');
    font-weight: 800;
    font-display: swap;
  }

  @font-face {
    font-family: 'Neometric';
    src: url(${Neometric3}) format('opentype');
    font-weight: 600;
    font-display: swap;
  }

  @font-face {
    font-family: 'Neometric';
    src: url(${Neometric2}) format('opentype');
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: 'Neometric';
    src: url(${Neometric}) format('opentype');
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${Roboto}) format('opentype');
    font-weight: 700;
    font-display: swap;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${Roboto2}) format('opentype');
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: 'Roboto';
    src: url(${Roboto3}) format('opentype');
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
  font-family: 'Poppins';
  src: url(${PoppinsLight}) format('opentype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

  @font-face {
    font-family: 'Poppins';
    src: url(${PoppinsRegular}) format('opentype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url(${PoppinsMedium}) format('opentype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url(${PoppinsSemiBold}) format('opentype');
    font-weight: 600;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url(${PoppinsBold}) format('opentype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: url(${PoppinsBlack}) format('opentype');
    font-weight: 900;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url(${GothamThin}) format('woff');
    font-weight: 100;
    font-display: swap;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Gotham';
    src: url(${GothamXLight}) format('woff');
    font-weight: 200;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url(${GothamLight}) format('woff');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url(${GothamBook}) format('woff');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url(${GothamMedium}) format('woff');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url(${GothamBold}) format('woff');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: url(${GothamBlack}) format('woff');
    font-weight: 900;
    font-display: swap;
    font-style: normal;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Neometric' !important;
    scroll-behavior: smooth;

    body{
      overflow: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }

    &::-webkit-scrollbar {
      width: 0 !important;
      display: none;
    }

    a{
      text-decoration: none;
    }

    button{
      border: 0;
      cursor: pointer;
    }

    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  ${({ theme, removeBg }) => css`
    html {
      font-size: 62.5%;

      @media only screen and (max-width: 768px) {
        font-size: 58.5%;
      }
    }

    body {
      font-family: ${theme.font.family};
      /* font-size: ${theme.font.sizes.medium}; */
      ${!removeBg &&
      css`
        background-color: ${theme.colors.white};
      `};
    }

    .Toastify__toast-container {
      z-index: 9999999999999;
    }

    .Toastify__toast-body {
      > div:last-of-type {
        font-size: 1.4rem;
      }
    }
  `}
`

export default GlobalStyles
