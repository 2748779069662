import styled from 'styled-components'

export const Container = styled.div`
  width: 70%;
  margin: 60px auto;

  display: flex;
  justify-content: center;
  gap: 150px;

  transition: ease-in-out 300ms;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 5rem;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: 768px) {
    align-items: center;
  }
`

export const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 7rem;
  width: 50%;

  @media (max-width: 768px) {
    width: 90%;
  }
`

export const QuestionAndButtonWrapper = styled.div`
  width: 669px;
  padding: 0px 0px 0px 16px;
  height: 40px;
  border: 0.5px solid rgba(56, 40, 14, 0.3);
  border-radius: 6px;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Question = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #0d0d0d;

  @media only screen and (max-width: 768px) {
    font-size: 1.4rem;
  }
`
export const QuestionContentWrapper = styled.div`
  width: 669px;
  padding: 0px 0px 0px 16px;
  height: 40px;
  // border: 0.5px solid rgba(56, 40, 14, 0.3);
  border-radius: 6px;

  display: flex;
  // justify-content: space-between;
  align-items: center;
`

export const QuestionContent = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #0d0d0d;
`

export const Button = styled.button`
  cursor: pointer;
  color: #fff;
  background: #27c7ff;
  width: 50px;
  height: 50px;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const MainText = styled.p`
  color: #0d0d0d;
  font-weight: 700;
  font-size: 80px;
  line-height: 95px;

  @media only screen and (max-width: 768px) {
    color: var(--black-brand-primary, #0d0d0d);
    text-align: center;
    font-family: Neometric;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`

export const SubText = styled.p`
  color: #0d0d0d;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;

  @media only screen and (max-width: 768px) {
    font-size: 1.6rem;
  }
`
export const ChevronContainer = styled.div`
  padding: 5px;
`
