import * as Styled from './styles'
import { Card } from './components/Card'
import { useState, useMemo } from 'react'
import { Transfer } from '../../pages/Transfer'
import { Settings } from '../../pages/Settings'
import { useAuth } from '../../../../contexts/useAuth'
import { TransferAdm } from '../../pages/TransferAdm'

export const SidebarDashboard = ({
  cardVisible = true,
  setActivePage,
  activePage
}) => {
  const { ROLE } = useAuth()

  return (
    <Styled.Container>
      <Styled.CardContainer className={cardVisible ? 'visible' : 'hidden'}>
        <Card
          role={ROLE}
          onOptionClick={(option) => setActivePage(option)}
          activePage={activePage}
        />
      </Styled.CardContainer>
      <Styled.CardFixContainer>
        <Card
          role={ROLE}
          activePage={activePage}
          onOptionClick={(option) => setActivePage(option)}
        />
      </Styled.CardFixContainer>
    </Styled.Container>
  )
}
