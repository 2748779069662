import { Textarea } from '@mantine/core'

export const PaymentMessage = ({ message, setMessage }) => {
  return (
    <Textarea
      label="Mensagem"
      placeholder="Digite a sua mensagem"
      value={message}
      onChange={(e) => setMessage(e.target.value)}
      size="xl"
      minRows={4}
      styles={{
        label: {
          color: '#222',
          fontFamily: 'Neometric',
          fontSize: '1.4rem',
          fontWeight: 600,
          marginBottom: '0.4rem'
        },
        input: {
          borderColor: 'red',
          borderRadius: '0.8rem',
          border: '0.508px solid #BFBFBF',
          padding: '0.8rem',
          fontSize: '1.4rem',
          color: '#404040',
          fontFamily: 'Neometric',
          ':focus': {
            borderColor: '#27c7ff'
          }
        }
      }}
    />
  )
}
