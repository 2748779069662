import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 2px solid var(--grey-4-grayscale, #f7f7f7);
  padding: 16px;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    padding: 8px;
    border: 0;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  @media (max-width: 1300px) {
    & {
      gap: 15px;
    }
    .divider {
      margin-right: 15px;
    }
  }
  @media (max-width: 1024px) {
    & {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }
  }

  @media (max-width: 768px) {
    gap: 16px;
  }
`

export const TimeWrapper = styled.div`
  border: 1px solid #27c7ff;
  padding: 8px;
  border-radius: 8px;
  width: 90px;
  height: 99px;

  @media (max-width: 1024px) {
    & {
      display: none;
    }
  }
`

export const TimeAndStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`

export const StatusCard = styled.div`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--yellow-brand-primary, #27c7ff);
  color: var(--white-grayscale, #fff);
  font-family: Neometric;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 16.8px */
  letter-spacing: 0.14px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column-reverse;
`

export const RejectButton = styled.div`
  button {
    border-radius: 16px;
    padding: 16px, 24px, 16px, 32px;
    background-color: #f8294e;
    width: 200px;
    height: 56px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.01em;
  }
  button:hover {
    background-color: #f8294e;
  }

  @media (max-width: 1024px) {
    button {
      display: flex;
      padding: 16px 24px 16px 32px;
      justify-content: space-between;
      align-items: center;
    }
  }
`

export const AcceptedButton = styled.div`
  button {
    border-radius: 16px;
    padding: 16px, 24px, 16px, 32px;
    background-color: #48d9a4;
    width: 200px;
    height: 56px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.01em;
  }

  button:hover {
    background-color: #48d9a4;
  }
`

export const DayTitle = styled.p`
  font-family: Neometric;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #404040;
`

export const MonthTitle = styled.p`
  font-family: Neometric;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0px;
  color: #27c7ff;
`

export const YearTitle = styled.p`
  font-family: Neometric;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 8px;
  color: #404040;
`

export const TimeTitle = styled.p`
  font-family: Neometric;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0px;
  color: #27c7ff;
`

export const IdWrapper = styled.p`
  strong {
    font-family: Inter;
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.01em;
  }

  span {
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.01em;
  }
`

export const AddressWrapper = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1300px) {
    & {
      gap: 15px;
      flex-direction: column;
    }
  }
  @media (max-width: 768px) {
    & {
      gap: 15px;
    }
  }
`

export const DepartureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const DepartureTitle = styled.p`
  font-family: Neometric;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #27c7ff;
  display: flex;
  align-items: flex-end;
  gap: 5px;

  @media (max-width: 768px) {
    color: var(--yellow-3-support, #1D95BF);
    leading-trim: both;
    text-edge: cap;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 14.4px */
    letter-spacing: 0.36px;
    text-transform: uppercase;
  }
`

export const DirectionButton = styled.button`
  width: 54px;
  height: 28px;
  border-radius: 8px;
  background-color: #DFF7FF;
`

export const PriceTitle = styled.p`
  font-family: Neometric;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.01em;
  color: #404040;
  margin-bottom: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: max-content;

  @media (max-width: 1024px) {
    & {
      color: var(--grey-1-grayscale, #404040);
      leading-trim: both;
      text-edge: cap;
      font-family: Neometric;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 28.8px */
      letter-spacing: 0.24px;
    }
  }
`

export const PricesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 768px) {
    border-radius: 8px;
    border: 1px solid var(--yellow-brand-primary, #27c7ff);
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 40px;
    align-self: stretch;
    flex-direction: row;
    justify-content: space-between;
  }
`

export const PriceMobile = styled.p`
  font-family: Neometric;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.01em;
  color: #404040;
  margin-left: 30px;
  margin-bottom: auto;
  display: none;

  @media (max-width: 1024px) {
    & {
      display: block;
    }
  }
`

export const DepartureDescription = styled.p`
  font-family: Neometric;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.01em;
  color: #404040;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 768px) {
    & {
      color: var(--grey-1-grayscale, #404040);
      font-family: Neometric;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 15.6px */
      letter-spacing: 0.12px;
    }
  }
`

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  width: 100%;

  @media (max-width: 768px) {
    display: none;
  }
`

export const PaymentTitle = styled.h1`
  color: var(--grey-1-grayscale, #404040);
  text-align: center;
  font-family: Neometric;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 21.6px */
  letter-spacing: 0.18px;
  display: flex;
  align-items: center;
  gap: 10px;
`

export const PaymentType = styled.div`
  width: 73.134px;
  height: 50px;
  border-radius: 4.478px;
  border: 0.746px solid #27c7ff;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const PaymentStatus = styled.span`
  color: var(--green-payment-support, #48d9a4);
  text-align: center;
  font-family: Neometric;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 19.2px */
  letter-spacing: 0.16px;
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: var(--green-pastel, #d5f6ea);
`

export const PaymentHour = styled.span`
  color: var(--grey-2-grayscale, #808080);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */
  letter-spacing: 0.14px;
`

export const PaymentDetails = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`

export const PaymentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

export const PaymentInfoTitle = styled.h1`
  color: var(--yellow-3-support, #1D95BF);
  leading-trim: both;
  text-edge: cap;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 14.4px */
  letter-spacing: 0.36px;
  text-transform: uppercase;

  @media (max-width: 768px) {
    display: none;
  }
`

export const PaymentInfoDesc = styled.span`
  color: var(--grey-1-grayscale, #404040);
  font-family: Neometric;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const IconsAndNumbersWrapper = styled.div`
  width: 100%;
  margin-top: 5px;

  display: flex;
  align-items: center;
  padding: 16px 0;
  gap: 24px;
`

export const IconAndNumber = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    color: #a0a0a0;
  }
`

export const TimeDescription = styled.div`
  display: none;

  @media (max-width: 1024px) {
    & {
      display: flex;
      padding: 6px 8px;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background: var(--yellow-brand-primary, #27c7ff);
      color: var(--white-grayscale, #fff);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  @media (max-width: 768px) {
    & {
      color: var(--white-grayscale, #fff);
      leading-trim: both;
      text-edge: cap;
      font-family: Neometric;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`

export const ValuesWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid var(--yellow-brand-primary, #27c7ff);
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;

  @media (max-width: 768px) {
    border: 0;
    padding: 0;
    gap: 0;
  }
`

export const DistanceMobile = styled.div`
  font-family: Neometric;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0.01em;
  color: #404040;
  margin-bottom: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: max-content;
  display: none;

  @media (max-width: 1024px) {
    & {
      display: block;
      color: #27c7ff !important;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      letter-spacing: 0.16px;
      width: 100%;
    }
  }
`

export const CardContainer = styled.div`
  display: flex;
  width: 30%;
  flex-direction: column;
  gap: 15px;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
  }
`
export const ChevronButton = styled.button`
  display: flex;
  padding: 8.39px;
  align-items: flex-start;
  gap: 8.39px;
  border-radius: 31.464px;
  background: var(--grey-4-grayscale, #f7f7f7);
`

export const PaymentAccomplished = styled.button`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    height: 48px;
    padding: 16px 24px 16px 32px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 16px;
    background: var(--green-payment-support, #48d9a4);
    color: var(--white-grayscale, #fff);
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 16.8px */
    letter-spacing: 0.14px;
    text-transform: uppercase;
  }
`
